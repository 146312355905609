<template>
  <b-modal
    id="modal-term"
    size="lg"
    :hide-footer="true"
    body-class="p-0"
    centered
    header-class="p-0 d-block border-bottom-0"
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="mx-4 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-start">
          <h3 class="fs-24 fw-7 mt-1">
            {{
              isPrivacy
                ? "개인정보 수집 및 이용동의"
                : "민감정보 수집 및 이용동의"
            }}
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="mx-4">
      <!-- 테이블 -->
      <b-table-simple bordered>
        <b-thead head-variant="gray-6 border-top border-black border-2">
          <b-tr>
            <b-th
              v-for="head in fields"
              :key="head.text"
              :style="{ ...head.thStyle, background: '#eee' }"
              class="text-center py-3 px-1 align-middle"
              >{{ head.text }}</b-th
            >
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(row, i) in tBody" :key="`${row.purpose}-${i}`">
            <b-td
              v-html="col"
              v-for="(col, key) in row"
              :key="key"
              :rowspan="key === 'period' ? getRowLength(row) : 1"
              :class="[
                key === 'period'
                  ? 'text-decoration-underline fs-20 fw-7'
                  : 'fs-md-17',
                'align-middle text-center',
              ]"
            ></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <!-- 동의하기 -->
      <p class="mt-3 mb-4 fs-17 gray-4">
        위의 개인정보 수집 및 이용동의를 거부할 권리가 있습니다. 다만, 동의를
        거부할 경우 서비스를 이용하실 수 없습니다.
      </p>

      <div class="flex-center py-4">
        <b-btn
          variant="blue-2 rounded px-3 py-4 w-100 fs-20 fs-md-17 flex-center"
          :style="{ background: '#13a9ff' }"
          @click="handleClick"
        >
          <img
            src="@/assets/img/common/ic-term-check.svg"
            alt="check"
            class="mr-2"
          />
          모든 내용을 읽고 확인했습니다.</b-btn
        >
      </div>
    </div>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
export default {
  props: {
    isPrivacy: {
      type: Boolean,
      default: false,
    },
    consentKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fields: [
        {
          text: "수집 · 이용 목적",
          key: "purpose",
          thStyle: { maxWidth: "5rem" },
        },
        { text: "수집 · 이용 항목", key: "item" },
        {
          text: "보유 및 이용기간",
          key: "period",
          thStyle: { minWidth: "6rem" },
        },
      ],
    };
  },
  computed: {
    tBody() {
      return this.$t("consent")[this.consentKey][
        this.isPrivacy ? "privacy" : "sensitive"
      ];
    },
  },
  methods: {
    getRowLength(row) {
      return Object.keys(row).length;
    },
    handleClick() {
      this.$emit("agree", this.isPrivacy);
      this.$bvModal.hide("modal-term"); // bootstrap modal hide
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 576px) {
  #modal-term {
    .modal-dialog {
      max-width: 550px;
    }
  }
}
</style>
