<template>
  <b-container class="hc-container">
    <tab-swiper
      ref="hc-card-swiper"
      :items="items"
      :key="key"
      v-slot="{ tab }"
      :isNaviHide="true"
      :options="{
        spaceBetween: 18,
        slidesPerView: 3,
        breakpoints: {
          400: {
            slidesPerView: 1.2,
            spaceBetween: 10,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 2.4,
            spaceBetween: 18,
          },
          994: {
            slidesPerView: 2.5,
            spaceBetween: 18,
          },
        },
      }"
      :mobile="true"
      class="mb-4"
    >
      <hc-card
        :style="[
          ['riskCheck', 'cancer'].includes(cameledKey)
            ? { minHeight: calcHeight('19rem', '3vh', '1vw') }
            : { minHeight: calcHeight('10rem') },
        ]"
        :key="items.title"
        v-bind="{
          ...tab,
          key: tab.title,
          textCenter: false,
        }"
        @handleClick="handleSubmit(tab)"
      ></hc-card>
    </tab-swiper>
    <p v-if="key !== 'therapy'" class="p-4 rounded-10 fs-19 mt-5 d-flex flex-center" :style="{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)' }">
      <img 
        src="~@/assets/img/common/icon-mark.png"
        alt="notice"
        :style="{ width: '2rem', height: '2rem', marginRight: '1rem' }" />
      자가체크는 의사의 진료를 대신할 수 없습니다. 참고용으로 활용하시기
      바랍니다.
    </p>
  </b-container>
</template>

<script>
import ModalTerm from "@/components/common/modal/ModalTerm";
import HcCard from "@/components/common/HcCard";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    HcCard,
    ModalTerm,
  },
  computed: {
    items() {
      return this.$t(this.cameledKey);
    },
  },
  mounted() {
    this.pageViewTrack(this.title);
  },
  updated() {
    this.pageViewTrack(this.title);
  },
  methods: {
    handleSubmit(obj) {
      const { id, to = "check-survey", title } = obj;
      this.clickTrack(title, this.title);

      return this.$router.push({
        name: to,
        params: { id, type: this.type, key: this.key },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media all and (max-width: 768px) {
  p {
    margin-top: -0.8rem  !important;
  }
}
</style>
