<template>
  <div :style="{ background: '#f6fbf4' }" class="py-5">
    <b-container>
      <p class="fw-7 text-center mb-4 px-3 fs-25">
        {{ title }}
      </p>
      <div class="row flex-center">
        <tab-swiper
          :items="btnArr"
          :options="{
            // slidesOffsetBefore: 20,
            // slidesOffsetAfter: 20,
            spaceBetween: 20,
            breakpoints: {
              768: {
                slidesOffsetBefore: 20,
                slidesOffsetAfter: 20,
              },
              994: {
                spaceBetween: 15,
              },
            },
          }"
          v-slot="{ tab }"
          class="b-btn-wrap"
        >
          <b-btn
            variant="link w-100 green-btn-2 py-3 px-4 fs-20"
            @click="$emit('handleClick', tab.id)"
            :style="{ minWidth: calcHeight('120px') }"
          >
            {{ tab.title }}
          </b-btn>
        </tab-swiper>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "심리적 안정을 유도하는 ASMR를 들으러 가시겠어요?",
    },
    btnArr: {
      tpye: Array,
      default: () => ["심리적 안정을 유도하는 ASMR"],
    },
  },
  methods: {
    handleClick(id) {
      this.$emit("bannerClick", id);
    },
  },
};
</script>

<style lang="scss" scoped>
// 768px ~ up
@media (max-width: 768px) {
  .container {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
