export default {
  p: (
    title
  ) => `${title}(이하 ‘회사’라 함)는 개인정보 보호법을 비롯한 개인정보 관련 법령에 따라 이용자의 개인정보보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
  </br>
  </br>
  본 홈페이지의 「개인정보 처리방침」은 아래와 같은 내용을 담고 있습니다.
  </br>
  </br>
  1. 개인정보의 수집∙이용 목적 및 보유기간</br>
  2. 개인정보 파기</br>
  3. 개인정보의 제3자 제공</br>
  4. 개인정보의 위탁</br>
  5. 이용자의 권리·의무 및 그 행사방법</br>
  6. 개인정보 보호를 위한 기술 및 관리적 대책</br>
  7. 개인정보 자동 수집 장치의 사용 및 거부에 관한 사항</br>
  8. 게시글</br>
  9. 개인정보 보호 전담조직</br>
  10. 링크 사이트</br>
  11. 권익침해 구제방법</br>
  12. 개인정보 처리방침 변경</br>
  </br>`,

  title_1: {
    title: "1. 개인정보의 수집∙이용 목적 및 보유기간",
    text_1:
      "① 회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행하도록 하겠습니다.",
    text_2: "② 선택정보를 입력하지 않을 경우에도 서비스 이용제한은 없습니다.",
    text_3: "③ 회사는 만 14세 이상의 이용자에 한해서 서비스(회원가입 등)를 제공하는 것을 원칙으로 합니다.",
    text_4: "④ 회사는 서비스 이용 과정에서 자동으로 생성된 다음의 정보를 수집할 수 있습니다.",
    text_5: "- 서비스 이용기록, 방문일시, 쿠키, IP Address 등",
  },

  title_2: {
    title: "2. 개인정보의 파기",
    text_1: "① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.",
    text_2:
      "② 이용자로부터 동의받은 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 다른 회원의 개인정보와 분리하여 보관합니다.",
    text_3: "③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.",
    text_4: `<span class='pl-3'>1) 파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</span>`,
  },
  title_3: {
    title: "3. 개인정보의 제3자 제공",
    text_1: "① 이용자의 개인정보를 이용자의 사전 동의가 있거나 법령의 규정에 의거하지 않는 한 제3자에게 제공하지 않습니다.",
    text_2:
      '② 만일 "회사"가 제3자에게 "이용자"의 개인정보를 제공할 경우에는 개인정보를 제공받는 자, 이용목적, 항목, 보유 및 이용기간, 동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는 경우에는 그 불이익의 내용 등을 "이용자"에게 알리고 별도의 동의를 받습니다.',
    text_3: `③ 다음의 경우 예외로 합니다.
                <ul class="pl-4'>
                  <li>1) 법률에 특별한 규정이 있거나 법령상 의무준수를 위해 불가피하여 수집한 경우로서 그 수집 목적 범위 내에서 개인정보를 제공하는 경우</li>
                  <li>2) 급박한 생명·신체·재산상 이익을 위하여 필요한 경우</li>
                  <li>2) 정보통신서비스의 제공에 따른 요금정산을 위하여 필요하여 수집한 경우로서 그 수집 목적 범위 내에서 개인정보를 제공하는 경우</li>
                  <li>3) 다른 법률에 특별한 규정이 있어 수집한 경우로서 그 수집 목적 범위 내에서 개인정보를 제공하는 경우</li>
                </ul>
            `,
    text_4: "④ 회사는 아래와 같이 고객의 개인정보를 제3자에게 제공하고 있습니다.",
  },
  title_4: {
    title: "4. 개인정보처리의 위탁",
    text_1:
      "① 회사는 더 나은 서비스를 제공하기 위하여 이용자의 개인정보 처리를 외부전문 기관에 위탁할 수 있으며, 위탁하는 업무의 내용과 수탁자를 언제든지 쉽게 확인할 수 있도록 회사의 인터넷 홈페이지에 지속적으로 게재하여 공개합니다.",
    text_2: "② 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.",
    table: {
      columns: [
        { text: "수탁업체", style: { width: "250px" }, class: "bg-green-ligth" },
        { text: "위탁내용", style: { width: "250px" }, class: "bg-green-ligth" },
        { text: "위탁기간", style: { width: "250px" }, class: "bg-green-ligth" },
      ],
      row: [[{ text: "GC케어" }, { text: "헬스케어서비스 및 시스템 운영관리" }, { text: "위탁계약해지시" }]],
    },
    text_3:
      "③ 회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 안전성 확보 조치, 재위탁 제한, 수탁자에 대한 관리∙감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는 지를 감독하고 있습니다.",
  },
  title_5: {
    title: "5. 이용자의 권리·의무 및 그 행사방법",
    text_1:
      "① 이용자는 언제든지 등록되어 있는 자신의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보는 홈페이지에서 직접 열람하거나 정정할 수 있으며 개인정보보호책임자 또는 고객센터(1588-1001)에 전화로 열람∙정정∙처리정지∙삭제를 요청하시면 곧바로 조치하겠습니다.",
    text_2: "② 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.",
    text_3:
      "③ 이용자는 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 부정확한 정보 입력으로 말미암아 발생하는 사고의 책임은 이용자에게 있으며 타인의 정보를 무단으로 사용하는 등 허위정보를 입력하면 회원자격이 상실될 수 있습니다.",
    text_4:
      "④ 이용자는 개인정보를 보호받을 권리와 함께 자신을 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 지니고 있습니다. 비밀번호(Password)를 포함한 이용자의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보를 훼손할 때에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등에 의해 처벌받을 수 있습니다.",
    text_5: "⑤ 이용자는 “개인정보 보호법” 등 기타 정보보호 및 개인정보 관련 법률을 준수하여야 합니다.",
  },
  title_6: {
    title: "6. 개인정보 보호를 위한 기술 및 관리적 대책",
    text_1: `회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
              <ul class="pl-2">
                <li>1) 기술적 보호조치
                  <ul class="pl-2">
                    <li>• 개인정보처리시스템 등의 접근권한 관리 : 침입차단시스템을 이용하여 외부로부터의 무단접근을 통제하고 있습니다. 또한 개인정보처리시스템에 대한 접근권한 관리를 통하여 개인정보에 대한 접근을 통제하며, 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 3년간 보관하고 있습니다.</li>
                    <li>• 암호화 조치 : 고객의 개인정보는 전송 시 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 등)를 채택하고 있고, 저장 시에는 안전한 암호 알고리즘을 적용하여 관리하고 있습니다.</li>
                    <li>• 접속기록의 보관 및 위∙변조 방지 : 개인정보처리시스템에 접속한 기록을 최소 2년 이상 보관, 관리하고 있으며, 접속 기록이 위∙변조 및 도난, 분실되지 않도록 관리하고 있습니다.</li>
                  </ul>              
                </li>
                <li>
                  2) 관리적 보호조치
                  <ul class="pl-2">
                    <li>• 회사는 개인정보 취급 직원을 최소화하고 있으며 각 직원 별 고객정보 접근 권한을 달리하고 수시 보안교육을 통하여 본 방침의 준수를 강조하고 있습니다.</li>
                    <li>• 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.</li>
                    <li>• 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.</li>
                    <li>• 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 위조ㆍ변조, 훼손이 유발될 경우 즉각 정보주체에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</li>
                  </ul> 
                </li>
              </ul>
              `,
  },
  title_7: {
    title: "7. 개인정보 자동 수집 장치의 사용 및 거부에 관한 사항",
    text_1: `① 쿠키 수집
    <ul class="pl-2">
      <li>1) 쿠키의 정의 및 저장 방법 : 쿠키(Cookie)는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다. 쿠키의 경우 고객의 유ㆍ무선 단말기는 식별하지만 고객 개개인을 식별하지는 않습니다.</li>
      <li>2) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
      <li>3) 거부 설정 방법 : 다음의 방법을 통한 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.</li>
      <li>4) 쿠키는 브라우저의 종료 시나 로그아웃 시 만료됩니다.</li>
      <li>5) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
    </ul>`,
    text_2: `② IP Address 정보 수집
                <ul class="pl-2">
                  <li>1) IP Address는 인터넷 망 사업자가 인터넷에 접속하는 이용자의 PC 등 기기에 부여하는 온라인 주소정보를 나타냅니다.</li>
                  <li>2) 주문 시, IP Address를 수집합니다.</li>
                </ul>
              `,
    text_3: `③ 기기정보 수집
              <ul class="pl-2">
                <li>1) 기기의 구동을 위해 사용되는 S/W를 통해 확인가능한 정보를 일컫습니다. OS(Windows, MAC OS 등), 접속한 브라우저 종류(인터넷 익스플로러, 크롬 등) 등을 수집합니다.</li>
              </ul>
            `,
    text_4: `④ 서비스 이용기록 수집
              <ul class="pl-2">
                <li>1) 이용자의 로그인 이력 등을 수집합니다.</li>
              </ul>
            `,
  },
  title_8: {
    title: "8. 게시글",
    text_1: `회사는 이용자의 게시물을 소중하게 생각하며 위조ㆍ변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지 아니합니다.
              <ul class="pl-2">
                <li>1) 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글</li>
                <li>2) 동의 없는 타인의 신상공개 회사의 저작권, 제3자의 저작권 등 권리를 침해하는 내용, 기타 게시판 주제와 다른 내용의 게시물</li>
                <li>3) 회사는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는 타인의 신상 공개 시 특정부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다.</li>
                <li>4) 그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.</li>
              </ul>
              `,
  },
  title_9: {
    title: "9. 개인정보 보호 책임자 성명 및 연락처",
    text_1:
      "회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호 책임자를 지정하고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 개인정보관리 책임자에게 연락 주시기 바랍니다. 문의하신 사항에 대해서 신속하고 성실하게 답변해 드리겠습니다.",
    text_2: "- 개인정보 열람∙정정∙처리정지∙삭제 청구 접수∙처리 부서",
    text_3: "- 부서 : 고객센터",
    text_4: "- 대표전화 : 1588-1001",
    text_5: `- 인터넷홈페이지 <a href="www.kyobo.com">www.kyobo.com</a>`,
    table: {
      columns: [
        { text: "구   분", style: { width: "180px" }, class: "bg-green-ligth" },
        { text: "부서", style: { width: "180px" }, class: "bg-green-ligth" },
        { text: "성명", style: { width: "180px" }, class: "bg-green-ligth" },
        { text: "전화번호", style: { width: "180px" }, class: "bg-green-ligth" },
      ],
      row: [[{ text: "개인정보 보호책임자" }, { text: "정보보안담당(CPO)" }, { text: "조성근	" }, { text: "02-721-3968" }]],
    },
  },
  title_10: {
    title: "10. 링크 사이트",
    text_1:
      "가. 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 당사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.",
    text_2:
      "나. 회사의 홈페이지가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 당사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.",
  },
  title_11: {
    title: "11. 권익침해 구제방법",
    text_1: `이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 
    <ul class="pl-2">
      <li>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
        <ul class="pl-2">
          <li>- 소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
          <li>- 홈페이지 : privacy.kisa.or.kr</li>
          <li>- 전화 : (국번없이) 118</li>
          <li>- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층</li>
        </ul>
      </li>
      <li>▶ 개인정보 분쟁조정위원회
        <ul class="pl-2">
          <li>- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 </li>
          <li>- 홈페이지 : www.kopico.go.kr</li>
          <li>- 전화 : (국번없이) 1833-6972</li>
          <li>- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 12층</li>
        </ul>
      </li>
      <li>▶ 대검찰청 사이버수사과
        <ul class="pl-2">
          <li>- 홈페이지 : www.spo.go.kr/</li>
          <li>- 전화 : (국번없이)1301</li>
        </ul>
      </li>
      <li>▶ 경찰청 사이버수사국
        <ul class="pl-2">
          <li>- 홈페이지 : ecrm.police.go.kr</li>
          <li>- 전화 : (국번없이) 182</li>
        </ul>
      </li>
    </ul>
    `,
  },
  title_12: {
    title: "12. 개인정보 처리방침 변경",
    text_1: "① 이 개인정보 처리방침은 2022. 09. 19부터 적용됩니다.",
    text_2: "② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.",
  },
};
