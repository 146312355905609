var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"hc-container"},[_c('w-form',{scopedSlots:_vm._u([{key:"default",fn:function(ctx){return [_c('h3',{staticClass:"fs-30 mb-5 fw-7 text-center"},[_vm._v(_vm._s(_vm.itemObj.title))]),_vm._l((_vm.consent),function(val,key){return _c('b-row',{key:key,staticClass:"mb-3",attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"flex-between p-3 rounded-10",style:({
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)',
        }),attrs:{"cols":"11","md":"8","lg":"6"}},[_c('w-checkbox',{staticClass:"py-1 mr-2 ml-3",attrs:{"label":key === 'privacy'
              ? '개인정보 수집 및 이용동'
              : '민감정보 수집 및 이용동의 (필수)',"name":key,"required":true},model:{value:(_vm.isAgree[key]),callback:function ($$v) {_vm.$set(_vm.isAgree, key, $$v)},expression:"isAgree[key]"}},[_c('a',{staticClass:"fs-20"},[_vm._v(_vm._s(key === "privacy" ? "개인정보 수집 및 이용동의" : "민감정보 수집 및 이용동의 (필수)"))])]),_c('b-btn',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.handleModal(key)}}},[_c('div',{staticClass:"bg-img",style:({
              width: _vm.calcHeight('3rem'),
              paddingBottom: _vm.calcHeight('3rem'),
              backgroundImage: `url(${require('@/assets/img/common/ic-agree-zoom.svg')})`,
            })})])],1)],1)}),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"flex-center",attrs:{"cols":"12"}},[_c('b-btn',{staticClass:"btn-link blue-submit-btn mt-5 d-inline-block fs-20 fs-md-16",on:{"click":function($event){return _vm.handleSubmit(ctx)}}},[_vm._v("다음")])],1)],1)]}}])}),_c('modal-term',{attrs:{"isPrivacy":_vm.isPrivacy,"consentKey":_vm.id},on:{"agree":_vm.handelAgree}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }