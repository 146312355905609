<template>
  <div>
    <!-- pc -->
    <b-row
      align-v="center"
      class="mx-0 d-none d-md-flex border-bottom border-gray-5 py-3"
    >
      <b-col cols="12" sm="5">
        <span class="gray fw-7 fs-20">{{ title }}</span>
      </b-col>
      <b-col cols="12" sm="7" class="text-right">
        <div class="flex-end">
          <b-overlay
            v-if="loading"
            :show="loading"
            rounded
            spinner-small
            spinner-variant="green"
            opacity="0"
            z-index="5"
            class="d-flex"
            :style="{
              borderRadius: '50%',
              overflow: 'hidden',
              width: calcHeight('3rem'),
              height: calcHeight('3rem'),
            }"
          >
          </b-overlay>
          <div v-if="isTimeLine" class="flex-center">
            <span class="gray-5">{{ timeConvert(currentTime) }}</span>
            <div class="ml-3 d-flex">
              <input
                class="range"
                type="range"
                @input="handleInput"
                v-model="currentTime"
                :max="duration"
              />
            </div>
            <span class="gray fw-7 ml-3">{{ timeConvert(duration) }}</span>
          </div>
          <div class="ml-4 d-flex">
            <b-btn
              ref="handle-btn"
              @click="!isPlay ? play() : pause()"
              variant="link"
              class="bg-img"
              :style="{
                width: calcHeight('3rem'),
                paddingBottom: calcHeight('3rem'),
                backgroundImage: !isPlay
                  ? `url(${require('@/assets/img/common/ic-play-green.svg')})`
                  : `url(${require('@/assets/img/common/ic-stop-green.svg')})`,
              }"
            >
            </b-btn>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- // pc -->
    <!-- mobile -->
    <b-row
      align-v="center"
      class="mx-0 d-md-none border-bottom border-gray-5 py-3"
    >
      <b-col cols="10" class="">
        <div class="gray fw-7 fs-20 flex-start">
          {{ title }}
          <b-overlay
            v-if="loading"
            :show="loading"
            rounded
            spinner-small
            spinner-variant="green"
            opacity="0"
            z-index="5"
            class="mb-1"
            :style="{
              width: '2rem',
              height: '2rem',
            }"
          >
          </b-overlay>
        </div>
        <div v-if="isTimeLine" class="flex-start mt-2">
          <span class="gray-5">{{ timeConvert(currentTime) }}</span>
          <div class="ml-3 d-flex">
            <input
              class="range"
              type="range"
              @input="handleInput"
              v-model="currentTime"
              :max="duration"
            />
          </div>
          <span class="gray fw-7 ml-3">{{ timeConvert(duration) }}</span>
        </div>
      </b-col>
      <b-col cols="2" class="text-right">
        <b-btn
          ref="handle-btn"
          @click="!isPlay ? play() : pause()"
          variant="link"
          class="bg-img"
          :style="{
            width: calcHeight('3rem'),
            paddingBottom: calcHeight('3rem'),
            backgroundImage: !isPlay
              ? `url(${require('@/assets/img/common/ic-play-green.svg')})`
              : `url(${require('@/assets/img/common/ic-stop-green.svg')})`,
          }"
        >
        </b-btn>
      </b-col>
    </b-row>
    <!-- mobile -->
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    idx: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: true,
      isPlay: false,
      isTimeLine: false,
      audio: null,
      currentTime: 0,
      duration: 0,
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  methods: {
    play() {
      if (this.loading) return;
      this.$router
        .replace({ ...this.$route, query: { id: this.idx } })
        .catch(() => {});
      this.audio.play();
      this.isPlay = true;
      this.isTimeLine = true;
    },
    pause() {
      this.audio.pause();
      this.isPlay = false;
    },
    reset() {
      this.pause();
      this.audio.currentTime = 0;
      this.isTimeLine = false;
    },
    init() {
      if (!this.url) return;
      this.loading = true;
      this.audio = new Audio(this.url);

      this.audio.addEventListener("loadedmetadata", () => {
        this.audio.volume = 1;
        this.duration = this.audio.duration;
        this.loading = false;
      });
      this.audio.addEventListener("waiting", () => {
        this.loading = true;
      });
      this.audio.addEventListener("playing", () => {
        this.loading = false;
      });

      this.audio.addEventListener("pause", this.pause);

      this.audio.addEventListener("timeupdate", this.set);
      this.audio.addEventListener("ended", () => {
        this.audio.currentTime = 0;
        this.isPlay = false;
      });
    },
    set() {
      this.currentTime = this.audio.currentTime;
    },
    handleInput() {
      this.audio.currentTime = this.currentTime;
    },
    timeConvert(value) {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
      let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      return hours
        ? padStartTo2(hours) +
            ":" +
            padStartTo2(minutes) +
            ":" +
            padStartTo2(seconds)
        : padStartTo2(minutes) + ":" + padStartTo2(seconds); // Return is HH : MM : SS

      function padStartTo2(val) {
        return val.toString().padStart(2, 0);
      }
    },
  },
  watch: {
    id() {
      if (this.id === this.idx) return;
      this.reset();
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.pause();
    this.reset();
  },
};
</script>

<style lang="scss">
.range {
  width: calc(9rem - 0.5vh + 0.1vw);
  height: calc(1.5rem - 0.5vh + 0.1vw);
  -webkit-appearance: none;
  background: var(--gray-5);
  outline: none;
  border-radius: 50rem;
  overflow: hidden;
  cursor: pointer;
}
.range::-moz-range-thumb {
  -webkit-appearance: none;
  width: calc(1.5rem - 0.5vh + 0.1vw);
  height: calc(1.5rem - 0.5vh + 0.1vw);
  border-radius: 50%;
  border: 0;
  background: #fff !important;
  box-shadow: -15.5rem 0 0 15rem var(--green);
  cursor: pointer;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: calc(1.5rem - 0.5vh + 0.1vw);
  height: calc(1.5rem - 0.5vh + 0.1vw);
  border-radius: 50%;
  background: #fff !important;
  box-shadow: -15.5rem 0 0 15rem var(--green);
  cursor: pointer;
}

/* remove border */
input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}
</style>
