var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-member-woman-ship","size":"md","hide-footer":true,"body-class":"p-0","header-class":"p-0 d-block border-bottom-0","modal-class":"modal-member-ship","centered":"","scrollable":""},on:{"show":_vm.showCb},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('div',{staticClass:"px-5 py-4 flex-1-1-0"},[_c('b-btn',{class:[
          _vm.type === 'membership' ? 'active' : '',
          'tab-item gray-3 fs-20 fs-md-18 pb-2',
        ],attrs:{"variant":"link"},on:{"click":function($event){return _vm.clickTab('membership')}}},[_vm._v("멤버쉽 카드")]),_c('b-btn',{class:[
          _vm.type === 'monthly' ? 'active' : '',
          'tab-item gray-3 fs-20 fs-md-18 pb-2',
        ],style:({marginLeft: '15px'}),attrs:{"variant":"link"},on:{"click":function($event){return _vm.clickTab('monthly')}}},[_vm._v("먼슬리 씽")]),_c('b-btn',{style:({ marginRight: '-15px', float: 'right' }),attrs:{"variant":"link"},on:{"click":function($event){return close()}}},[_c('div',{staticClass:"bg-img",style:({
            backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
            width: '3rem',
            paddingBottom: '3rem',
          })})])],1)]}}])},[(_vm.type === 'membership')?_c('div',{staticClass:"px-5 pb-5"},[_c('div',{staticClass:"position-relative"},[(_vm.memberGradeStatus)?_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":`${require(`@/assets/img/${_vm.$i18n.locale}/${_vm.cardImg}`)}`,"alt":"member ship card"}}),_c('p',{staticClass:"text-right white",style:({
            position: 'absolute',
            bottom: '1.2rem',
            right: '1rem',
          })},[_c('span',[_vm._v(_vm._s(_vm.name))])])]):_c('b-skeleton-img',{staticClass:"rounded-10",attrs:{"no-aspect":"","height":"16.5rem"}})],1)]):_c('div',{staticClass:"px-4 px-md-5 pb-5"},[_c('p',{staticClass:"p-3 mb-4 rounded-10 border fs-18 text-center",style:({
        color: '#323232',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      })},[_vm._v(" 여성건강 다이어리&여성·건강 제품몰의 혜택을 받아보세요! ")]),_c('b-row',{staticClass:"mx-n2"},[_c('b-col',{staticClass:"text-center fs-20 py-1 px-2",attrs:{"cols":"6"}},[_c('span',[_vm._v("먼슬리씽"),_c('br'),_vm._v(" 가입초대코드")])])],1),_c('b-row',{staticClass:"mx-n2 mb-4"},[_c('b-col',{staticClass:"px-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.coupon),expression:"coupon"}],ref:"coupon",staticClass:"d-none",attrs:{"readonly":"","type":"text"},domProps:{"value":(_vm.coupon)},on:{"input":function($event){if($event.target.composing)return;_vm.coupon=$event.target.value}}}),_c('p',{staticClass:"theme-color-1 fs-24 rounded-10 border fw-7 text-center py-3"},[_vm._v(" "+_vm._s(_vm.coupon)+" ")])]),_c('b-col',{staticClass:"px-2"},[_c('b-btn',{staticClass:"d-block rounded-10 bg-theme-color-1 white fs-20 w-100 h-100 py-3",on:{"click":_vm.copyAddr}},[_vm._v("제휴번호 복사하기")])],1)],1),_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"fs-20"},[_vm._v("사용방법")])]),_c('b-col',{staticClass:"fs-20 fw-3 gray px-0"},[_c('hash-title',{attrs:{"isHash":false,"title":`<a href='https://monthlything.page.link/kblife/' class='fs-20 fw-3 gray' target='_blank' >https://monthlything.page.link/kblife</a>`}}),_c('span',[_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("회원 가입 시 초대코드 입력(먼슬리씽 1천원 적립금 자동지급) "),_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("프로필 "),_c('i',{staticClass:"icon-right-open-big gray-3"}),_vm._v("내적립금 ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }