<template>
  <div>
    <!-- 타이틀 -->
    <b-row class="py-4">
      <b-col cols="5" md="3">
        <b-skeleton
          :animation="null"
          width="100%"
          :height="calcHeight('3rem')"
        ></b-skeleton>
      </b-col>
    </b-row>
    <!-- //타이틀 -->
    <!-- 리스트 -->
    <b-row v-for="i in 4" :key="i" class="border-bottom border-gray-5 py-3">
      <b-col cols="5" sm="4">
        <b-skeleton
          :animation="null"
          width="60%"
          :height="calcHeight('2rem')"
        ></b-skeleton>
      </b-col>
      <b-col cols="7" sm="8">
        <b-skeleton
          :animation="null"
          width="100%"
          :height="calcHeight('2rem')"
        ></b-skeleton>
      </b-col>
    </b-row>
    <b-row class="border-bottom border-gray-5 py-3">
      <b-col cols="5" sm="4">
        <b-skeleton
          :animation="null"
          width="60%"
          :height="calcHeight('2rem')"
        ></b-skeleton>
      </b-col>
      <b-col cols="7" sm="8">
        <b-skeleton-img
          :animation="null"
          :height="calcHeight('20rem')"
        ></b-skeleton-img>
      </b-col>
    </b-row>
    <!-- //리스트 -->

    <!-- 버튼 -->
    <b-row align-h="center" class="py-5">
      <b-col cols="5" md="3">
        <b-skeleton
          :animation="null"
          width="100%"
          :height="calcHeight('3rem')"
        ></b-skeleton>
      </b-col>
    </b-row>
    <!-- //버튼 -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
