<template>
  <div id="app">
    <!-- init... -->
    <template v-if="loading">
      <b-overlay :show="loading" rounded="sm" :no-fade="true" z-index="20">
        <div class="vh-100 w-100"></div>
      </b-overlay>
    </template>
    <!-- // init... -->
    <template v-else>
      <router-view class="fs-18" />
      <modal-alert />
    </template>
    <b-modal id="system-check" :hide-footer="true" centered size="sm">
      <div class="mx-4 mx-md-5 mb-4 mb-md-5">
        <img src="@/assets/img/common/system-check.png" style="height:100%; width:100%"/>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["loading"]),
    type() {
      return this.$route.params.type;
    },
  },
  watch: {
    // type 변경되면 locale, 테마컬러 변경
    type(n) {
      this.$i18n.locale = n;
      this.setColor();
    },
  },
  methods: {
    // 테마 컬러 설정
    setColor() {
      const colors = Array.isArray(this.$t("color")) ? this.$t("color") : [];
      colors.forEach((color, i) => {
        document.documentElement.style.setProperty(`--theme-color-${i + 1}`, color);
      });
    },
    async init() {
      this.$i18n.locale = this.type;
      this.setColor();
    },
    addDaumScript() {
      const script = document.createElement("script");
      script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
      document.head.appendChild(script);
    },
  },
  created() {
    this.init();
    this.addDaumScript();
  },
  mounted() {
    // this.$bvModal.show("system-check");
  }
};
</script>
