<template>
  <b-container class="hc-container">
    <!-- head -->
    <div
      class="text-center py-5"
      :style="{ backgroundColor: '#fafafa', paddingTop: '5rem' }"
    >
      <div class="drak fs-24 flex-center mb-3">
        <span class="mr-2"
          ><img
            :style="{ width: '2rem' }"
            src="~@/assets/img/common/ic-info-circle.svg"
            alt="info"
        /></span>
        <p>상담 받고 싶은 내용을 작성해 주세요.</p>
      </div>
      <p class="fs-19 gray">
        <span class="px-2">&#183;</span>전문간호사, 영양사, 건강운동관리사 각
        분야 전문가가 상담해 드립니다.
      </p>
    </div>
    <!-- // head -->

    <!-- body -->
    <div :style="{ marginBottom: '10rem' }">
      <w-form v-model="valid" v-slot="{ invalid }" @submit="handlePost">
      <!-- 이메일 -->
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18">
        <b-col cols="4">이름 (필수)</b-col>
        <b-col cols="8">
          <hc-input-default
              :style="{ width: '16rem' }"
              v-model="input.name"
              name="name"
              rules="required"
              inputClass="fs-20 fs-md-18"
              :isErrorMessage="false"
              :styles="{ minWdith: '5rem' }"
              placeholder="이름을 입력해 주세요"
              @keyup.native="keyUpName"
            />
        </b-col>
      </b-row>
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18">
        <b-col cols="4">E-mail (필수)</b-col>
        <b-col cols="8">
          <div class="flex-start flex-wrap gap-1">
            <hc-input-default
              :style="{ width: '8rem' }"
              v-model="input.email1"
              name="email"
              rules="required"
              inputClass="fs-20 fs-md-18"
              :isErrorMessage="false"
              :styles="{ minWdith: '5rem' }"
            />
            <span>@</span>
            <hc-input-default
              :style="{ width: '8rem' }"
              v-model="input.email2"
              name="email"
              rules="required"
              inputClass="fs-20 fs-md-18"
              :readonly="emailType.value !== '직접입력'"
              :isErrorMessage="false"
              :styles="{ minWdith: '5rem' }"
              @keyup.native="keyUpEmail"
            />
            <hc-select-default
              @change="handleEmail"
              v-model="emailType"
              :items="emailOptions"
              :selected="'직접입력'"
              inputClass="fs-20 fs-md-18"
            ></hc-select-default>
          </div>
        </b-col>
      </b-row>
        <w-textarea
          class="online-consult-textarea mb-5"
          no-resize
          v-model="input.textarea"
          placeholder="내용을 입력하세요."
          rules="required"
        ></w-textarea>
        <!-- 동의하기 -->
        <div class="flex-center py-4">
          <w-checkbox
            :label="'개인정보 수집 및 이용동의 (필수)'"
            :name="'개인정보 수집 및 이용동의 (필수)'"
            v-model="isAgree"
            class="py-1 mr-2"
            rules="required"
          >
            <a class="fs-20 fs-md-18 " >개인정보 수집 및 이용동의 (필수)</a>
          </w-checkbox>
          <b-btn variant="link" @click="$bvModal.show('modal-service')">
            <div
              class="bg-img"
              :style="{
                width: calcHeight('3rem'),
                paddingBottom: calcHeight('3rem'),
                backgroundImage: `url(${require('@/assets/img/common/ic-agree-zoom.svg')})`,
              }"
            ></div>
          </b-btn>
        </div>
      <!-- 등록하기 -->
        <div class="flex-center">
          <b-btn
            variant="link dark rounded-pill fw-7 mr-2 fs-20 fs-md-16"
            @click="$router.push({ name: listsName }).catch(() => {})"
            :style="{
              padding: '1rem 2.5rem',
              backgroundColor: '#e0e2e5',
            }"
            >취소</b-btn
          >
          <b-btn
            variant="link blue-submit-btn fs-20 fs-md-16"
            type="submit"
            :disabled="invalid"
            >등록</b-btn
          >
        </div>
      </w-form>
    </div>
    <modal-service @agree="handleAgree"></modal-service>
    <b-modal id="bv-modal-example" 
      hide-footer 
      hide-header  
      size="md" 
      
      header-class="p-0 d-block border-bottom-0"
      centered>
      <div class="px-4 px-md-5 pb-5 d-block text-center">
        <h4>개인정보 수집 및 이용에 동의해 주세요.</h4>
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">닫기</b-button>
    </b-modal>
    <!-- // body -->
  </b-container>
</template>

<script>
import { showToast } from "@/plugins/custom-toast";
import ModalService from "@/components/kyobohealthcare/modal/ModalService"
export default 
{
  components: { ModalService },
  data() {
    return {
      valid: false,
      isAgree: false,
      input: {
        textarea: "",
        name: "",
        email1: "",
        email2: "",
      },
      emailType: "",
      emailOptions: [
        { value: "직접입력", text: "직접입력" },
        { value: "gmail.com", text: "gmail.com" },
        { value: "naver.com", text: "naver.com" },
        { value: "daum.net", text: "daum.net" },
        { value: "nate.com", text: "nate.com" },
      ],
    };
  },
  computed: {
    listsName() {
      const arr = this.$route.name.split("-");
      const filtered = arr.filter((el) => el !== "write");
      return filtered.join("-");
    },
  },
  methods: {
    async handlePost(e) {
      if (!this.isAgree) {
        this.$bvModal.show('bv-modal-example')
      } else {
        await this.write();
        this.$router
          .push({ name: this.listsName })
          .then(() => {
            showToast({ title: "글이 작성되었습니다." });
          })
          .catch(() => {});
      }
    },

    // TODO: 인서트했을때 커서중간에 숫자, 특수문자 삽입시 감별 해야됨.
    async write() {
      const { kyoboApi } = this.getApis();
      const body = {
        textName: this.input.name.slice(0, 10),
        textEmail: `${this.input.email1}@${this.input.email2}`,
        textContents: this.input.textarea,
      };
      await kyoboApi.onlineCounselling.online.write(body);
      this.input.textarea = "";
    },

    handleAgree(bool) {
      this.isAgree = bool;
    },

    handleEmail({ value }) {
      this.input.email2 = value === "직접입력" ? "" : value;
    },

    keyUpName(e) {
      e.target.value = this.convertName(e.target.value);
    },

    keyUpEmail(e) {
      e.target.value = this.convertEmail(e.target.value);
    },

    convertName(name) {
      let reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"0-9]/gi;

      return reg.test(name) ? name.replace(reg, "") : name
    },

    convertEmail(email) {
      let reg = /[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"0-9ㄱ-ㅎㅏ-ㅣ가-힣]/gi;

      return reg.test(email) ? email.replace(reg, "") : email;
    }
  },
};
</script>

<style lang="scss" scoped></style>
