<template>
  <div>
    <template v-if="hospitals.length">
      <!-- 전국 협력병원 (건강검진 예약대행) -->
      <b-row ref="compare-text" class="mt-5" align-h="center">
        <b-col>
          <div class="fs-20 fw-7 flex-start flex-wrap mb-3 mb-md-2">
            최대 3개의 관심 병원을 선택
            <span
              class="bg-img d-inline-block mx-2"
              :style="{
                width: calcHeight('1.5rem'),
                paddingBottom: calcHeight('1.5rem'),
                backgroundImage: `url(${require('@/assets/img/common/ic-checked-green.svg')})`,
              }"
            >
            </span>
            하고 비교해 보세요.
          </div>
          <b-btn variant="link fs-18 flex-start gray-11" @click="resetSelected">
            <img
              src="@/assets/img/common/ic-remove-check.svg"
              alt="선택해제하기"
              class="mr-2"
              :style="{
                width: calcHeight('1.5rem'),
              }"
            />
            <span class="border-bottom border-gray-11">
              선택 해제 하기
            </span>
          </b-btn>
        </b-col>
        <b-col cols="12" lg="3" class="text-center text-lg-right mt-4 mt-lg-0">
          <b-btn
            class="green-btn py-2 px-4 fs-18 fw-7"
            variant="link"
            @click="compareHos"
          >
            병원 비교하기
          </b-btn>
        </b-col>
      </b-row>
      <template v-for="(hos, idx) in hospitals">
        <div :id="`modal-tab-${idx}`" :key="idx">
          <!-- 타이틀 -->
          <div class="border-bottom border-3 border-black pt-4 pt-lg-5 pb-3">
            <hash-title :title="hos.region"></hash-title>
          </div>
          <!-- // 타이틀 -->

          <!-- 리스트 -->
          <ul class="mb-3">
            <li
              v-for="(row, _idx) in hos.items"
              :key="_idx"
              class="border-bottom border-gray-5"
            >
              <div class="flex-between align-items-start py-4 px-2">
                <div class="flex-start">
                  <b-checkbox
                    class="mr-3"
                    v-model="selectedCode[row.hpt_code]"
                    name="check-button"
                    button
                    button-variant="link"
                  >
                    <div class="flex-start align-items-start">
                      <img
                        class="mx-2 mx-sm-3 mt-1"
                        :src="
                          selectedCode[row.hpt_code]
                            ? `${require('@/assets/img/common/ic-checked-green.svg')}`
                            : `${require('@/assets/img/common/ic-check-box.svg')}`
                        "
                        alt="check box"
                        :style="{
                          width: calcHeight('1.5rem'),
                        }"
                      />
                      <div class="d-flex align-items-start flex-column">
                        <span class="fw-7 fs-20 black">{{ row.h_name }}</span>
                        <span class="gray-5 fs-18 fs-md-16 text-left"
                          >{{ row.h_adress1 }} {{ row.h_adress2 }}</span
                        >
                      </div>
                    </div>
                  </b-checkbox>
                </div>
                <div>
                  <b-btn
                    variant="link"
                    @click="
                      callDetail({
                        hsptl_sn: row.hsptl_sn,
                        hpt_code: row.hpt_code,
                      })
                    "
                    class="flex-center"
                  >
                    <img
                      :src="`${require('@/assets/img/common/ic-zoom-in.svg')}`"
                      alt="zoom"
                      :style="{
                        width: calcHeight('2.375rem'),
                      }"
                    />
                  </b-btn>
                </div>
              </div>
              <div
                class="p-3 p-sm-4 fs-18"
                :style="{ backgroundColor: '#ededed', color: '#707070' }"
              >
                <p>
                  {{ row.me_note ? row.me_note : "-" }}
                </p>
              </div>
            </li>
          </ul>
          <!-- // 리스트 -->
        </div>
      </template>
      <div style="height: 20vh"></div>
      <!-- // 전국 협력병원 (건강검진 예약대행) -->
    </template>
    <template v-else>
      <skeleton-modal-list></skeleton-modal-list>
    </template>
  </div>
</template>

<script>
import SkeletonModalList from "@/components/common/SkeletonModalList";
import { throttle } from "lodash";
import common from "@/mixins/common";
export default {
  mixins: [common],
  components: {
    SkeletonModalList,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedCode: {},
      hospitals: [],
    };
  },
  computed: {
    dividList() {
      return this.tabs.map((tab) => {
        const items = this.hospitals.filter((row) =>
          tab.keys.find((key) => key === row.MED_AREA_NUM)
        );
        return { region: tab.title, items };
      });
    },
    selectedCodeArr() {
      return Object.keys(this.selectedCode).reduce((arr, el) => {
        return this.selectedCode[el] ? [...arr, el] : arr;
      }, []);
    },
  },
  methods: {
    async getList() {
      const { kyoboApi } = this.getApis();

      const reulst = await Promise.all(
        this.tabs.map((tab, i) => kyoboApi.hospital.getList(i + 1))
      );
      const hospitals = reulst.map(({ data: { hospitals } }, i) => {
        const { title } = this.tabs.find((_val, _i) => i === _i);
        return { region: title, items: hospitals };
      });

      this.hospitals = hospitals;
      this.sendOffset();
    },
    sendOffset() {
      this.$nextTick(() => {
        const el = this.$refs["compare-text"];
        this.$emit("sendOffset", el?.offsetHeight ?? 0);
      });
    },
    compareHos() {
      if (this.selectedCodeArr.length > 3)
        return this.$root.$emit(
          "showModalAlert",
          "최대 3개의 병원이 비교 가능합니다."
        );
      if (this.selectedCodeArr.length <= 1)
        return this.$root.$emit(
          "showModalAlert",
          "비교하고 싶은 병원을 선택해 주세요.\n최대 3개의 병원 비교가 가능합니다."
        );
      this.$router.replace({
        ...this.$route,
        query: { codes: this.selectedCodeArr },
      });
      this.$emit("handleMode", "compare");
      this.clickTrack("병원비교하기", this.title);
    },
    resetSelected() {
      if (!Object.keys(this.selectedCode).length) return;
      this.selectedCode = {};
    },
    callDetail({ hsptl_sn, hpt_code }) {
      this.$router
        .replace({
          ...this.$route,
          query: { hsptl_sn, hpt_code, modalTab: "병원정보" },
        })
        .catch(() => {});
      this.$emit("handleMode", "detail");
    },
  },
  mounted() {
    window.addEventListener("resize", throttle(this.sendOffset, 200));
    this.getList();
  },
  beforeDestroy() {
    window.removeEventListener("resize", throttle(this.sendOffset, 200));
  },
};
</script>

<style lang="scss" scoped></style>
