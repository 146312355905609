<template>
    <div>케어콜 테스트</div>
</template>

<script>
export default {
    name: 'CareCall'
}
</script>

<style>

</style>