<template>
  <div
    class="mb-5 pb-2 w-100"
    :style="{ maxWidth: calcHeight('50rem'), margin: '0 auto' }"
  >
    <w-form @submit="search(q)">
      <!-- select -->
      <div class="pb-2 pb-md-3">
        <hc-select
          class="flex-center"
          v-model="selected"
          :lists="lists"
          inputClass="fs-30"
        >
          <p class="fs-28 fs-md-25">에서 검색하기</p>
        </hc-select>
      </div>
      <!-- select -->

      <!-- search input -->
      <hc-input-default
        v-model="q"
        class="mt-4"
        inputGroupClass="border border-theme-color-1 border-3 rounded-10 p-2 p-md-3"
        input-class="bg-transparent border-0 fs-24 fs-md-30 search-input"
        placeholder="search"
        name="search"
        rules="max:8"
      >
        <template #append>
          <b-btn
            v-show="q"
            variant="link"
            :disabled="!q"
            @click="q = ''"
            :style="{ width: calcHeight('2.4rem') }"
          >
            <img
              src="@/assets/img/common/ic-close.svg"
              alt="search"
              class="w-100"
            />
          </b-btn>
        </template>
      </hc-input-default>
      <!-- // search input -->
    </w-form>
  </div>
</template>

<script>
import HcInputDefault from "@/components/common/HcInputDefault";
import HcSelect from "@/components/common/HcSelect";
export default {
  props: {
    lists: {
      type: Array,
      default: () => [
        { value: "all", text: "전체" },
        { value: "title", text: "제목" },
        { value: "content", text: "내용" },
      ],
    },
  },
  components: {
    HcSelect,
    HcInputDefault,
  },
  data() {
    return {
      q: null,
      isFocus: false,
      selected: null,
    };
  },
  methods: {
    search(q) {
      if (!q) return;
      this.$emit("hanldeSearch", {
        searchString: q,
        searchType: this.selected.value,
      });
    },
  },
};
</script>

<style lang="scss">
.search-input {
  &::placeholder {
    text-indent: 100%;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("~@/assets/img/common/ic-search.svg");
  }
}
</style>
