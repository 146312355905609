<template>
  <b-container class="hc-container">
    <div v-if="items.length">
      <!-- 칼럼 -->
      <div class="mb-2 mb-md-4">
        <span class="fs-18 white px-3 py-1 rounded-pill bg-theme-color-1">{{
          surveyObj.title
        }}</span>
      </div>
      <!-- // 칼럼 -->

      <!-- 타이틀 -->
      <hash-title
        class="mb-3 mb-md-4"
        :title="'아래 질문에 답을 하고 결과를 확인'"
        :titleClass="'fw-7'"
        :isHash="false"
        :styles="{ fontSize: '1.2rem' }"
        ><span class="fw-7" :style="{ fontSize: '1.2rem' }"
          >해보세요</span
        ></hash-title
      >
      <!-- // 타이틀 -->
      <div>
        <survey-swiper
          :slideItems="visibleItems"
          @handleSubmit="handleSubmit"
        ></survey-swiper>
      </div>
    </div>
    <skeleton-check-survey v-else></skeleton-check-survey>
  </b-container>
</template>

<script>
import SurveySwiper from "@/components/common/SurveySwiper";
import QuestionBox from "@/components/common/QuestionBox";
import QuestionBoxUserInfo from "@/components/kyobohealthcare/QuestionBoxUserInfo";
import SkeletonCheckSurvey from "@/components/common/SkeletonCheckSurvey";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    SurveySwiper,
    QuestionBox,
    QuestionBoxUserInfo,
    SkeletonCheckSurvey,
  },
  data() {
    return {
      items: [],
      children: [],
    };
  },
  computed: {
    // 특정 조건에서 disabled 되는 문제들 제거하는 함수
    visibleItems() {
      // 지문에 해당하는 룰
      const ruleList = this.$t("rules")[this.id];

      // 룰 계산
      return this.items.filter((item) => {
        const currentRuleObj = ruleList?.find(
          (rule) => rule.current === item.number
        );

        if (!currentRuleObj) return true;
        const { rules } = currentRuleObj;

        const findParent = (parentNum) =>
          this.items.find((item) => parentNum === item.number);

        return rules.reduce(
          (bool, fn) => (bool ? true : fn(findParent)),
          false
        );
      });
    },
    // 일반 자가진단 포인트 계산
    totalPoint() {
      // 점수 계산 룰
      const pointRule = this.$t("pointRlues")?.[this.id];

      // 해당하는 룰
      const getAnswers = (parentNum) =>
        parentNum.map((num) => {
          const findItem = this.visibleItems.find(
            (item) => item.number === num
          );
          return findItem?.selectedAnswers ?? [];
        });

      const calcPointRule = () =>
        Array.isArray(pointRule)
          ? pointRule?.reduce((point, fn) => fn(getAnswers) + point, 0)
          : 0;

      const totalPointRule = calcPointRule();
      // 질문 결과 값 계산
      let point = 0;

      // 선택된 답변 value 총 합계
      this.visibleItems.forEach((item) => {
        point +=
          item.selectedAnswers?.reduce?.(
            (prev, curr) => (typeof curr !== "string" ? +prev : +prev + +curr),
            0
          ) ?? 0;
      });
      return point + totalPointRule;
    },
    surveyObj() {
      return this.$t(this.cameledKey).find((el) => el.id === this.id);
    },
  },
  methods: {
    init() {
      this.getList();
      if (this.$i18n.locale !== "kyoboJuniorCare") return;
      this.getChild();
    },
    // submit
    handleSubmit(useInfo) {
      // 건강위험도체크 결과보기
      if (this.id === "healthcheck") {
        this.$router.push({
          name: "check-result",
          params: { ...this.$route.params },
          query: { point: this.totalPoint, ...useInfo },
        });
        return;
      }
      // 피부노화도체크 결과보기
      if (this.id === "skin") {
        this.$router.push({
          name: "check-result",
          params: { ...this.$route.params },
          query: { ...this.getPonits() },
        });
        return;
      }
      // 헬스주니어케어 결과보기
      if (["kyobojuniorcare"].includes(this.$i18n.locale)) {
        this.$router.push({
          name: "check-result",
          params: { ...this.$route.params },
          query: { ...this.getPsyBody() },
        });
        return;
      }
      // 그외 결과보기
      this.$router.push({
        name: "check-result",
        params: { ...this.$route.params },
        query: { point: this.totalPoint },
      });
    },
    // 아동청소년 심리 & 학습 및 뇌건강 결과 바디
    getPsyBody() {
      const countNum = this.children.length === 1 ? 1 : 0;
      return this.visibleItems.reduce(
        (obj, item, i) => {
          if (item?.target === "joinserial") {
            const joinserial = item.selectedAnswers.filter((val) => val)[0];
            return { ...obj, joinserial };
          }
          const answer = item?.selectedAnswers?.filter((val) => val).join("|");
          return { ...obj, [`a${i + countNum}`]: answer };
        },
        {
          // 기본값
          joinserial: this.children[0].joinserial,
          m_code: this.id,
        }
      );
    },
    // 스킨 자가진단 포인트 계산
    getPonits() {
      return this.visibleItems.reduce(
        (obj, item) => ({
          point1: obj.point1 + (+item.selectedAnswers?.[0] || 0),
          point2: obj.point2 + (+item.selectedAnswers?.[1] || 0),
          point3: obj.point3 + (+item.selectedAnswers?.[2] || 0),
        }),
        {
          point1: 0,
          point2: 0,
          point3: 0,
        }
      );
    },
    // subqeustions 배열 flat
    flatitems(items) {
      return items.reduce(
        (arr, item) =>
          !item.subquestions?.length
            ? [...arr, item]
            : [...arr, ...item.subquestions],
        []
      );
    },
    // answers 배열 안에 value 값 string 으로 변경
    answerValueToStirng(items) {
      return items.map((item) => {
        item.answers.map((answer) => ({
          ...answer,
          value: (answer.value += ""),
        }));
        return item;
      });
    },
    // 건강위험도 체크 일때 고객정보 입력
    addUserInfoInput(items) {
      if (this.id !== "healthcheck") return items;
      const item = {
        endsaveanswer: "",
        answers: [],
        answersType: "input",
        examples: [],
        number: 11,
        questiontitle: "고객님의 정보를 입력해 주세요.",
        subquestions: [],
      };
      return [...items, item];
    },
    // 피부노화도체크면 다중선택 추가
    addCheckboxType(items) {
      if (!["skin"].includes(this.id)) return items;
      return items.map((item) => ({ ...item, answersType: "checkbox" }));
    },
    // 질문이나 답변에 이미지 있을 때 이미지 타입 추가
    addQuestionItem(items) {
      if (this.id !== "LK01002") return items;
      // questionImgs에서 이미지 가져오는 함수
      const setQuestionImg = (item) =>
        item.questionImgs.map(
          (questionItem, i) => questionItem[`questionImg${i + 1}`]
        );
      // answers에서 이미지 가져오는 함수
      const getAnswersImg = (item) =>
        item.answers.map((answerItem) => answerItem.answerimg);

      return items.map((item) => {
        // 질문에 이미지가 있는지 없는지
        const isAnswerImg = item.answers.find((item) => item.answerimg);
        let questionImgs = !isAnswerImg
          ? setQuestionImg(item)
          : getAnswersImg(item);
        // 빈 문자열 제거
        questionImgs = questionImgs.filter((questionItem) => questionItem);
        return { ...item, questionImgs };
      });
    },
    // 문진에 아이 선택 질문 삽입
    setChildInfo(items) {
      if (this.$i18n.locale !== "kyobojuniorcare" || this.children.length === 1)
        return items;

      const answers = this.children.map((val) => ({
        answertitle: val.contactname,
        value: val.joinserial,
      }));

      const newItem = {
        number: "0",
        target: "joinserial",
        questiontitle: "아이를 선택해 주세요.",
        answers,
      };
      return [newItem, ...items];
    },
    async getList() {
      const { kyoboApi } = this.getApis();
      // 교보주니어케어에서는 key 값을 가지고 api 구분
      const { data } = ["psy", "learningBrainHealth"].includes(this.cameledKey)
        ? await kyoboApi.check.psy.list(this.id)
        : await kyoboApi.check[this.id].list();

      this.$i18n.locale === "kyobojuniorcare" && (await this.getChild());

      const composedItems = this.compose(
        [
          this.flatitems,
          this.answerValueToStirng,
          this.addUserInfoInput,
          this.addCheckboxType,
          this.addQuestionItem,
          this.setChildInfo,
        ],
        data.questions
      );
      this.items = composedItems;
    },
    async getChild() {
      const { kyoboApi } = this.getApis();
      const {
        data: {
          info: { children },
        },
      } = await kyoboApi.program.common.info();
      this.children = children;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.answer-list {
  .active {
    border-color: #000 !important;
    color: #000 !important;
    i {
      border-color: #000 !important;
      color: #000 !important;
    }
  }
}
</style>
