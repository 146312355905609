import request from "@/utils/request";

export default {
  check: {
    growth: {
      // 우리아이 - 발달차트 - 개월 수 선택 후 응답목록 가져오기
      list: (month) =>
        request({
          method: "GET",
          url: `/kyobokidscare/services/growth/answers/${month}`,
        }),
      // 우리아이 - 발달차트 - 결괏값
      result: (data) =>
        request({
          method: "post",
          url: "/kyobokidscare/services/growth/answers/result",
          data,
          encryptionData: true,
        }),
      // 우리아이 성장차트 입력
      insert: (data) =>
        request({
          method: "post",
          url: "/kyobokidscare/services/growth/chart/insert",
          data,
          encryptionData: true,
        }),
    },
  },
  hospital: {
    getLists: () =>
      // 전국 협력병원 리스트
      request({
    method: "get",
    url: "/kyobohealthcare/services/hospital/list",
      }),
    // 헬스케어 - 전국 협력 병원(검진 우대 병원) 리스트
    getList: (sido_num) =>
      request({
        method: "get",
        url: `/kyobokidscare/services/hospital/list/${sido_num}`,
      }),
    // 헬스케어 - 병원(검진 우대 병원)정보 보기
    getInfo: (hsptl_sn) =>
      request({
        method: "get",
        url: `/kyobokidscare/services/hospital/info/${hsptl_sn}`,
      }),
    // 헬스케어 - 검진항목보기(검진 우대 병원
    getItem: (hpt_code) =>
      request({
        method: "get",
        url: `/kyobokidscare/services/hospital/info/item/${hpt_code}`,
      }),
    // 헬스케어 - 검진항목 비교(검진 우대 병원
    compare: (data) =>
      request({
        method: "post",
        url: "/kyobokidscare/services/hospital/info/compare",
        data,
      }),
  },
  info: {
    pregnancy: (cindex) =>
      // 건강한 임신 - 카테고리별 - 컨텐츠 정보
      request({
        method: "get",
        url: `/kyobokidscare/services/pregnancy/contents/${cindex}`,
      }),
    // 현명한 출산 - 카테고리별 - 컨텐츠 정보
    born: (cindex) =>
      request({
        method: "get",
        url: `/kyobokidscare/services/born/contents/${cindex}`,
      }),
    parenting: (cindex) =>
      request({
        method: "get",
        url: `kyobokidscare/services/happy/contents/${cindex}`,
      }),
    // 영상 콘텐츠 - 운동 동영상 컨텐츠 정보
    movie: ({ category, pageSize, page = 1 }) =>
      request({
        method: "get",
        url: `kyobokidscare/services/movie/${category}/${pageSize}/${page}`,
      }),
  },
  myPage: {
    mycall: {
      // 내 서비스 내역 - 전화상담
      list: (data) =>
        request({
          method: "post",
          url: "/kyobokidscare/services/mypage/call/list",
          data,
          encryptionData: true,
        }),
    },
    application: {
      // 내 서비스 내역 - 프로그램 신청 내역
      list: (data) =>
        request({
          method: "post",
          url: "/kyobokidscare/services/mypage/application/list",
          data,
          encryptionData: true,
        }),
      // 내 서비스 내역 - 프로그램 신청 해지
      delete: (data) =>
        request({
          method: "post",
          url: "/kyobokidscare/services/mypage/application/stop",
          data,
          encryptionData: true,
        }),
    },
  },
  news: {
    news: {
      // 건강 칼럼 리스트 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobokidscare/services/column/list",
          data,
        }),
      // 건강 칼럼 상세 불러오기
      detail: (report_key) =>
        request({
          method: "get",
          url: `/kyobokidscare/services/column/view/${report_key}`,
        }),
    },
    recentNews: {
      // 최신뉴스 리스트 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobokidscare/services/recentnews/list",
          data,
        }),
      detail: (news_key) =>
        request({
          method: "get",
          url: `/kyobokidscare/services/recentnews/view/${news_key}`,
        }),
    },
  },
  program: {
    common: {
      //신청자 정보 및 아이 정보 - 어린이 건강도서관/임신ㆍ출산ㆍ육아 집중관리/상담치료지원/방문치료지원/우리아이 성장차트/예방접종 스케줄
      info: () =>
        request({
          method: "post",
          url: "/kyobokidscare/services/common/info",
          encryptionData: true,
        }),
    },
    library: {
      apply: (data) =>
        //어린이 건강도서관 신청 하기
        request({
          method: "post",
          url: "/kyobokidscare/services/library/apply",
          data,
          encryptionData: true,
        }),
    },
    management: {
      apply: (data) =>
        //임신ㆍ출산ㆍ육아 집중관리 신청 하기
        request({
          method: "post",
          url: "/kyobokidscare/services/management/apply",
          data,
          encryptionData: true,
        }),
      save: (data) =>
        //임신ㆍ출산ㆍ육아 집중관리 문진 저장
        request({
          method: "post",
          url: "/kyobokidscare/services/munjin/save",
          data,
          encryptionData: true,
        }),
    },
    counseling: {
      // 상담치료지원 - 방문희망병원 정보
      hospitalInfo: () =>
        request({
          method: "get",
          url: "/kyobokidscare/services/helpmom/hospitalinfo",
        }),
      // 상담치료지원 - 진단명 카테고리1
      diseaseCategory1: () =>
        request({
          method: "get",
          url: "/kyobokidscare/services/helpmom/category/disease",
        }),
      // 진단명 카테고리2
      diseaseCategory2: (cate1key) =>
        request({
          method: "get",
          url: `/kyobokidscare/services/helpmom/category/disease/${cate1key}`,
        }),
      //상담치료지원 신청
      apply: (data) =>
        request({
          method: "post",
          url: `/kyobokidscare/services/helpmom/apply`,
          data,
          encryptionData: true,
        }),
      //방문치료지원 신청
      visitApply: (data) =>
        request({
          method: "post",
          url: `/kyobokidscare/services/helpmomvisit/apply`,
          data,
          encryptionData: true,
        }),
    },
    schdule: {
      childList: () =>
        // 예방접종 스케줄 - 등록된 자녀 리스트
        request({
          method: "post",
          url: "/kyobokidscare/services/growth/vaccine/list",
          encryptionData: true,
        }),
      childInsert: (data) =>
        // 예방접종 스케줄 - 자녀 등록하기
        request({
          method: "post",
          url: "/kyobokidscare/services/growth/vaccine/insert",
          data,
          encryptionData: true,
        }),
      childDelete: (idx) =>
        // 예방접종 스케줄 - 등록된 자녀 삭제
        request({
          method: "delete",
          url: `/kyobokidscare/services/growth/vaccine/${idx}`,
        }),
      getList: (idx) =>
        // 예방접종 스케줄 - 예방접종일 리스트
        request({
          method: "get",
          url: `/kyobokidscare/services/growth/vaccineschedule/${idx}`,
        }),
      insert: (data) =>
        // 예방접종 스케줄 - 예방접종일 등록하기
        request({
          method: "post",
          url: "/kyobokidscare/services/growth/vaccineschedule/insert",
          data,
        }),
    },
  },
};
