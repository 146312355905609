<template>
  <div>
    <!-- 프로그램 -->
    <template v-if="programItems.length">
      <tab-swiper
        :items="programItems"
        v-slot="{ tab }"
        :mobile="true"
        :isNaviHide="true"
        class="mb-4"
        :options="{
          slidesPerView: 3,
          spaceBetween: 18,
          breakpoints: {
            400: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            994: {
              slidesPerView: 2,
              spaceBetween: 18,
            },
          },
        }"
      >
        <hc-card
          :style="{ minHeight: calcHeight('18.5rem', '3vh') }"
          v-bind="{
            ...tab,
            key: tab.title,
            textCenter: true,
            btnText: '신청하기',
          }"
          @handleClick="handleApply(tab.id)"
        ></hc-card>
      </tab-swiper>
      <div>
        <p class="red fs-18">
          - 피부 프로그램, 스트레스 프로그램 중 선택 하실 수 있습니다.
        </p>
        <p class="red fs-18">
          - 건강증진 프로그램은 연 1회 한 번만 이용이 가능하며, 각 프로그램별로
          14~16주간 진행됩니다.
        </p>
        <p class="red fs-18">
          - 기타 궁금한 사항을 헬스콜센터(1588-7524)로 문의 바랍니다.
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import HcCard from "@/components/common/HcCard";
import HcCollapse from "@/components/common/HcCollapse";

export default {
  components: {
    HcCard,
    HcCollapse,
  },
  computed: {
    programItems() {
      return this.isArray(this.$t("programItems"));
    },
  },
  methods: {
    async handleApply(applicationCode) {
      const body = {
        applicationCode,
      };
      const { kyoboApi } = this.getApis();
      const apis = kyoboApi.program.healthCheck;
      const {
        data: { message },
      } = await apis.apply(body);

      return this.$root.$emit(
        "showModalAlert",
        `${message}<br/>상세 내용은 내 서비스 내역에서 확인할 수 있습니다.`
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
