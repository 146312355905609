<template>
  <b-container>
    <div class="bg-white d-block pt-3 pt-md-4">
      <tab-swiper
        :items="headerTab"
        class="tab"
        v-slot="{ tab, idx }"
        ref="header-tab-swiper"
        :options="{
          navigation: {
            nextEl: '.header-tab-next',
            prevEl: '.header-tab-prev',
          },
          ...setSpaceBetween,
          breakpoints,
          initialSlide: activeSlideIndex,
        }"
      >
        <b-btn
          variant="link"
          :class="[
            title === tab.text ? 'active' : '',
            'tab-item gray-3 fs-20 fs-md-18 pb-2',
          ]"
          :key="idx"
          @click.prevent="handleClickSlide(tab)"
        >
          {{ tab.text }}
        </b-btn>
      </tab-swiper>
    </div>
  </b-container>
</template>

<script>
import common from "@/mixins/common";
import tabScroll from "@/mixins/tabScroll";
export default {
  mixins: [common, tabScroll],
  data() {
    return {
      breakpoints: {
        576: {
          spaceBetween: 50,
        },
        768: {
          spaceBetween: 60,
        },
      },
    };
  },
  computed: {
    targetNav() {
      return this.meta.targetNav ? this.meta.targetNav : this.$route.name;
    },
    activeSlideIndex() {
      return this.headerTab.findIndex((el) => el.text === this.title);
    },
    setSpaceBetween() {
      if (this.headerTab.length >= 6) {
        return { spaceBetween: 75 };
      }
      if (this.headerTab.length > 4) {
        return { spaceBetween: 115 };
      }
      return { spaceBetween: 187 };
    },
  },
  watch: {
    title() {
      this.callTabBtnSlide();
    },
  },
  methods: {
    handleClickSlide({ name, key, id, type, query }) {
      this.$router
        .push({
          name,
          params: { key, id, type },
          query,
        })
        .catch(() => {});
    },
    async callTabBtnSlide() {
      await this.$nextTick();
      this.$refs["header-tab-swiper"].slideTo(this.activeSlideIndex, 500);
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) return;
      if (this.headerTab?.length >= 6) return;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      if (window.innerWidth > 768) return;
      if (this.headerTab?.length >= 6) return;
    });
  },
};
</script>

<style lang="scss" scoped></style>
