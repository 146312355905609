<template>
  <b-container class="hc-container">
    <w-form v-slot="ctx">
      <!-- 타이틀 -->
      <h3 class="fs-30 mb-5 fw-7 text-center">{{ itemObj.title }}</h3>
      <!-- // 타이틀 -->

      <!-- 동의하기 -->
      <b-row
        align-h="center"
        v-for="(val, key) in consent"
        :key="key"
        class="mb-3"
      >
        <b-col
          cols="11"
          md="8"
          lg="6"
          class="flex-between p-3 rounded-10"
          :style="{
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)',
          }"
        >
          <w-checkbox
            :label="
              key === 'privacy'
                ? '개인정보 수집 및 이용동'
                : '민감정보 수집 및 이용동의 (필수)'
            "
            :name="key"
            :required="true"
            v-model="isAgree[key]"
            class="py-1 mr-2 ml-3"
          >
            <a class="fs-20">{{
              key === "privacy"
                ? "개인정보 수집 및 이용동의"
                : "민감정보 수집 및 이용동의 (필수)"
            }}</a>
          </w-checkbox>
          <b-btn variant="link" @click="handleModal(key)">
            <div
              class="bg-img"
              :style="{
                width: calcHeight('3rem'),
                paddingBottom: calcHeight('3rem'),
                backgroundImage: `url(${require('@/assets/img/common/ic-agree-zoom.svg')})`,
              }"
            ></div>
          </b-btn>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" class="flex-center">
          <b-btn
            class="btn-link blue-submit-btn mt-5 d-inline-block fs-20 fs-md-16"
            @click="handleSubmit(ctx)"
            >다음</b-btn
          >
        </b-col>
      </b-row>
      <!-- 동의하기 -->
    </w-form>
    <modal-term
      @agree="handelAgree"
      :isPrivacy="isPrivacy"
      :consentKey="id"
    ></modal-term>
  </b-container>
</template>

<script>
import ModalTerm from "@/components/common/modal/ModalTerm";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    ModalTerm,
  },
  data() {
    return {
      isAgree: {
        privacy: false,
        sensitive: false,
      },
      // privacy, sensitive를 구분하는 불리언 값
      isPrivacy: false,
    };
  },
  computed: {
    itemObj() {
      return this.$t(this.cameledKey)?.find((item) => item.id === this.id);
    },
    consent() {
      return this.$t("consent")[this.id];
    },
  },
  methods: {
    handleModal(key) {
      this.isPrivacy = key === "privacy";
      this.$bvModal.show("modal-term");
    },
    handelAgree(bool) {
      bool ? (this.isAgree.privacy = true) : (this.isAgree.sensitive = true);
    },
    async handleSubmit({ validateWithInfo }) {
      const { isValid, fields, errors } = await validateWithInfo();
      const field = this.getTargetField(fields);

      if (!isValid) {
        return this.$root.$emit("showModalAlert", errors[field][0]);
      }
      this.$router.push({ name: "check-survey", key: this.key });
    },
    getTargetField(obj) {
      return Object.keys(obj).reduce(
        (feild, key) => (feild ? feild : obj[key].invalid && key),
        ""
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
