<template>
  <b-container class="hc-container">
    <!-- 텍스트 박스 -->
    <p class="border rounded-10 p-3 mb-5 fs-20 fs-md-18 gray">
      우리아이 발달차트는 현재 아이의 발달 상태를 확인할 수 있는 평가
      방법입니다. 아이에 따라 약간의 차이가 있을 수 있으며, 이 결과는 의사의
      진단을 대신할 수 없음을 알려 드립니다. 보다 자세한 사항은 병원을
      방문하시기 바랍니다.
    </p>
    <!-- // 텍스트 박스  -->
    <!-- 선택 박스 -->
    <h6 class="fs-29 flex-center flex-wrap">
      <span> 우리 아이는</span>
      <hc-select
        v-model="selected"
        :lists="options"
        :isHash="false"
        class="mx-2"
        inputClass="fs-29"
      ></hc-select
      ><span>이 되었습니다.</span>
    </h6>
    <!-- // 선택 박스 -->
    <!-- 버튼 -->
    <div class="flex-center py-5">
      <router-link
        :to="{
          ...$route,
          name: 'check-growth-survey',
          query: { month: selected.value },
        }"
        class="blue-submit-btn px-5 fs-20 fs-md-18"
        >발달 평가</router-link
      >
    </div>
    <!-- // 버튼 -->
  </b-container>
</template>

<script>
import HcSelect from "@/components/common/HcSelect";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    HcSelect,
  },
  data() {
    return {
      selected: { value: "1", text: "1개월" },
      options: [
        { value: "1", text: "1개월" },
        { value: "2", text: "2개월" },
        { value: "3", text: "3개월" },
        { value: "4", text: "4개월" },
        { value: "5", text: "5개월" },
        { value: "6", text: "6개월" },
        { value: "7", text: "7개월" },
        { value: "8", text: "8개월" },
        { value: "9", text: "9개월" },
        { value: "10", text: "10개월" },
        { value: "11", text: "11개월" },
        { value: "12", text: "12개월" },
        { value: "13", text: "13개월" },
        { value: "14", text: "14개월" },
        { value: "15", text: "15개월" },
        { value: "16", text: "16개월" },
        { value: "17", text: "17개월" },
        { value: "18", text: "18개월" },
        { value: "19", text: "19개월" },
        { value: "20", text: "20개월" },
        { value: "21", text: "21개월" },
        { value: "22", text: "22개월" },
        { value: "23", text: "23개월" },
        { value: "24", text: "24개월" },
        { value: "27", text: "27개월" },
        { value: "30", text: "30개월" },
        { value: "33", text: "33개월" },
        { value: "37", text: "37개월" },
        { value: "40", text: "40개월" },
        { value: "42", text: "42개월" },
        { value: "44", text: "44개월" },
        { value: "48", text: "48개월" },
      ],
    };
  },
  mounted() {
    this.pageViewTrack(this.title);
  }
};
</script>

<style lang="scss" scoped></style>
