// modules 폴더 안에 index.js

import camelCase from "lodash/camelCase";

// 현재 폴더안에 js 파일을 모두 찾는다.
// 하위 폴더는 없기 때문에 false로 설정.
const requireModule = require.context(".", false, /\.js$/);

const modules = {};

// 여기서 keys()는 Objecy.keys가 아닌 커스텀된 keys()로 추정이 된다.
requireModule.keys().forEach((filename) => {
  const isIndexJsFile = filename === "./index.js";
  // index.js는 추가되면 안되기 때문에 해당 로직 추가
  if (!isIndexJsFile) {
    // 카멜케이스로 치환
    const moduleName = camelCase(filename.replace(/(\.\/|\.js)/g, ""));
    // 네임스페이스를 true로 해주면, 스토어 파일에서 해주지 않아도 자동으로 설정된다.
    modules[moduleName] = { namespaced: true, ...requireModule(filename) };
  }
});

export default modules;
