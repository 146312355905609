import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import scrollBehavior from "./scrollBehavior";
import Layout from "@/views/Layout";
import KyoboHealthCare from "@/views/KyoboHealthCare";
import KyoboKidsCare from "@/views/KyoboKidsCare";
import CareCall from "@/views/CareCall";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: { name: "home", params: { type: "kyoboservices" } },
  },
  {
    path: "/:type",
    name: "type",
    component: Layout.Default,
    redirect: (to) => ({ ...to, name: "home" }),

    children: [
      // type = 사이트 (교보헬스케어, 교보우먼헬스케어, .....)
      // --- 홈
      {
        path: "home",
        name: "home",
        meta: { main: true },
        component: KyoboHealthCare.Home,
      },
      // --- 서비스 안내/신청
      {
        path: "service/:key",
        name: "service",
        meta: {
          targetGnb: "서비스 안내/신청",
        },
        component: KyoboHealthCare.Service.Service,
      },

      // --- 건강체크
      // 건강 체크
      {
        path: "check/:key/list",
        name: "check",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboHealthCare.Check.Check,
      },
      // 자가 문진 설문지
      {
        path: "check-survey/:key/:id",
        name: "check-survey",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboHealthCare.Check.CheckSurvey,
      },

      // 자가 문진 동의하기
      {
        path: "check-agree/:key/:id",
        name: "check-agree",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboHealthCare.Check.CheckAgree,
      },

      // 자가 문진 결과
      {
        path: "check-result/:key/:id",
        name: "check-result",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboHealthCare.Check.CheckResult,
      },

      // 힐링테라피 플레이 리스트
      {
        path: "check-therapy/:key/:id",
        name: "check-therapy",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboHealthCare.Check.TherapyList,
      },

      // 아이 성장 체크
      {
        path: "check-grow/:key",
        name: "check-grow",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboKidsCare.Check.GrowCheck,
      },
      // 아이 성장 체크 결과
      {
        path: "check-grow-insert/:key",
        name: "check-grow-insert",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboKidsCare.Check.GrowInsert,
      },
      // 아이 발달 체크
      {
        path: "check-growth/:key",
        name: "check-growth",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboKidsCare.Check.Growth,
      },
      // 아이 발달 설문지
      {
        path: "check-growth-survey/:key",
        name: "check-growth-survey",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboKidsCare.Check.GrowthSurvey,
      },
      // 아이 발달 체크 결과
      {
        path: "check-growth-result/:key",
        name: "check-growth-result",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboKidsCare.Check.GrowthResult,
      },
      // 예방접종 스케줄
      {
        path: "check-schedule/:key",
        name: "check-schedule",
        meta: {
          targetGnb: "건강체크",
        },
        component: KyoboKidsCare.Check.Schedule,
      },

      // --- 건강정보
      // 건강정보 리스트 - 헬스케어
      {
        path: "info/:key",
        name: "info",
        meta: {
          targetGnb: "건강정보",
        },
        component: KyoboHealthCare.Info.List,
      },
      // 기획기사, 최신뉴스
      {
        path: "info-news/:key",
        name: "info-news",
        meta: {
          targetGnb: "건강정보",
        },
        component: KyoboHealthCare.Info.News,
      },
      // 기획기사, 최신뉴스 디테일
      {
        path: "info-news/:key/detail/:id",
        name: "info-news-detail",
        meta: {
          targetGnb: "건강정보",
        },
        component: KyoboHealthCare.Info.NewsDetail,
      },
      // --- 온라인 상담

      // 온라인 상담
      {
        path: "consult/:key",
        name: "consult",
        meta: {
          targetGnb: "온라인 상담실",
        },
        component: KyoboHealthCare.Consulting.Consulting,
      },
      {
        path: "consult/:key/detail",
        name: "consult-detail",
        meta: {
          targetGnb: "온라인 상담실",
        },
        component: KyoboHealthCare.Consulting.ConsultingDetail,
      },
      {
        path: "consult/:key/write",
        name: "consult-write",
        meta: {
          targetGnb: "온라인 상담실",
        },
        component: KyoboHealthCare.Consulting.ConsultingWrite,
      },
      // --- 자주 찾는 질문
      {
        path: "ask/:key",
        name: "ask",
        meta: {
          targetGnb: "자주찾는 질문",
        },
        component: KyoboHealthCare.Ask.Ask,
      },
      // --- 내 서비스 내역
      {
        path: "mypage/:key",
        name: "mypage",
        meta: {
          targetGnb: "내 서비스 내역",
        },
        component: KyoboHealthCare.Mypage.Mypage,
      },
      // --- 케어콜
      {
        path: "carecall",
        name: "carecall",
        meta: {
          carecall: true,
        },
        component: CareCall.Home,
      }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

router.beforeEach((...agrs) => beforeEach(...agrs, router.app));

export default router;
