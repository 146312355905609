<template>
  <div>
    <!-- 스크롤 이벤트 있을 때 -->
    <template v-if="isScroll">
      <b-nav
        class="modal-tab-wrap border-bottom border-theme-color-1 px-4 px-md-5 d-block overflow-hidden"
        v-b-scrollspy="{
          offset: this.offset,
          element: `#${this.scrollspyId}___BV_modal_body_`,
          method: 'auto',
        }"
      >
        <tab-swiper
          ref="modal-tab-swiper"
          :items="items"
          class="tab"
          :options="{
            navigation: {
              nextEl: '.modal-tab-next',
              prevEl: '.modal-tab-prev',
            },
            spaceBetween: 85,
            breakpoints: {
              576: {
                spaceBetween: 50,
              },
              768: {
                spaceBetween: 60,
              },
            },
          }"
          v-slot="{ tab, idx }"
        >
          <b-nav-item
            :href="`#modal-tab-${idx}`"
            :key="idx"
            class="tab-item gray-3 fs-20 fs-md-18 pb-2"
            @click.prevent="tabBtnClickHandle(`#modal-tab-${idx}`, modalBody)"
          >
            {{ tab.title }}
          </b-nav-item>
        </tab-swiper>
      </b-nav>
    </template>

    <!-- 스크롤 이벤트 없을 때 -->
    <template v-else>
      <b-nav
        class="modal-tab-wrap border-bottom border-theme-color-1 px-4 px-md-5 d-block"
      >
        <tab-swiper
          ref="modal-tab-swiper"
          :items="items"
          class="tab"
          :options="{
            navigation: {
              nextEl: '.modal-tab-next',
              prevEl: '.modal-tab-prev',
            },
            spaceBetween: 85,
          }"
          v-slot="{ tab, idx }"
        >
          <li
            :class="[
              tagetTab === tab ? 'active' : '',
              'tab-item gray-3 fs-20 fs-md-18 pb-2',
            ]"
          >
            <a
              :key="idx"
              :href="`#modal-tab-${idx}`"
              @click.prevent="handleClick(tab)"
            >
              {{ tab }}
            </a>
          </li>
        </tab-swiper>
      </b-nav>
    </template>
  </div>
</template>

<script>
import tabScroll from "@/mixins/tabScroll.js";

export default {
  mixins: [tabScroll],
  props: {
    scrollspyId: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    offset: {
      type: Number,
      default: 20,
    },
    isScroll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tagetTab() {
      return this.$route.query.modalTab || this.items?.[0];
    },
    modalBody() {
      return document.querySelector(`#${this.scrollspyId}___BV_modal_body_`);
    },
  },
  methods: {
    handleClick(modalTab) {
      this.$router
        .replace({ ...this.$route, query: { ...this.$route.query, modalTab } })
        .catch(() => {});
    },
  },
};
</script>
