<template>
  <div>
    <div v-if="hosInfo" class="h-100">
      <!-- 병원 명, 주소, 전화번호 -->
      <div v-if="tagetTab === '병원정보'">
        <!-- 카카오 맵 -->
        <kakao-map
          :style="{ height: calcHeight('21rem') }"
          v-bind="{
            y: hosInfo.latitude,
            x: hosInfo.longitude,
            name: hosInfo.h_name,
            address,
          }"
        ></kakao-map>
        <!-- // 카카오 맵 -->
        <div
          class="px-4 px-md-5 py-3 flex-column flex-md-row flex-between align-items-start"
        >
          <div>
            <div class="fs-20 flex-start">
              <span>{{ hosInfo.h_name }}</span>
              <a
                :href="hosInfo.h_url"
                target="_blank"
                class="bg-img"
                :style="{
                  width: calcHeight('2rem'),
                  paddingBottom: calcHeight('2rem'),
                  backgroundImage: `url(${require('@/assets/img/common/ic-home.svg')})`,
                }"
              ></a>
            </div>
            <div class="flex-start">
              <input
                ref="address"
                class="d-none"
                readonly
                v-model="address"
                type="text"
              />
              <span class="gray-5 mt-1 fs-18">{{ address }}</span>
              <b-btn
                variant="link"
                @click="copyAddr"
                class="bg-img"
                :style="{
                  width: calcHeight('2rem'),
                  paddingBottom: calcHeight('2rem'),
                  backgroundImage: `url(${require('@/assets/img/common/ic-copy-mini.svg')})`,
                }"
              ></b-btn>
            </div>
          </div>
          <div>
            <p class="fs-22 fw-7 mt-1 blue-2">{{ hosInfo.tel }}</p>
          </div>
        </div>
      </div>
      <!-- //병원 명, 주소, 전화번호 -->
      <!-- 병원정보 -->
      <template v-if="tagetTab === '병원정보'">
        <section
          class="mx-4 mx-md-5 pb-5"
          :style="{ borderTop: '3px solid #000' }"
        >
          <div
            v-for="(row, key) in items"
            class="row border-bottom border-gray-5 py-3 m-0 fs-20"
            :key="key"
          >
            <div class="col-3 gray-5">{{ row.text }}</div>
            <div class="col-9  flex-start ">
              {{ getRowInfo(row) ? getRowInfo(row) : "-" }}
            </div>
          </div>
        </section>
      </template>
      <!-- // 병원정보 -->

      <template v-if="tagetTab === '검진항목'">
        <!-- 검진항목 -->
        <section class="px-4 px-md-5 pb-5">
          <b-container class="p-0">
            <template v-for="(row, i) in hosItems">
              <div
                v-if="row.des"
                class="row border-bottom border-gray-5 py-3 m-0 fs-20"
                :key="`${row.lcode}-${i}`"
              >
                <div class="col-3 gray-5">{{ row.sname }}</div>
                <div class="col-9 flex-start ">{{ row.des }}</div>
              </div>
            </template>
          </b-container>
        </section>
        <!-- // 검진항목 -->
      </template>
    </div>
    <skeleton-hos-map v-else :isList="true"></skeleton-hos-map>
  </div>
</template>

<script>
import KakaoMap from "@/components/common/modal/modalComponents/KakaoMap";
import SkeletonHosMap from "@/components/common/SkeletonHosMap";
import { showToast } from "@/plugins/custom-toast";

export default {
  components: {
    KakaoMap,
    SkeletonHosMap,
  },
  data() {
    return {
      tabIndex: 0,
      hosInfo: null,
      hosItems: null,
      items: [
        { text: "업무시간", key: "worktime" },
        { text: "검진시간", key: "test_time" },
        { text: "검진 전 준수사항", key: "test_before" },
        { text: "소개", key: "h_info" },
        { text: "교통편", key: "h_traffic" },
        { text: "주차정보", key: "h_park" },
      ],
    };
  },
  computed: {
    address() {
      return `${this.hosInfo.h_adress1} ${this.hosInfo.h_adress2}`;
    },
    tagetTab() {
      return this.$route.query.modalTab;
    },
    id() {
      return this.$route.query.hsptl_sn;
    },
    code() {
      return this.$route.query.hpt_code;
    },
  },
  watch: {
    tagetTab() {
      this.scrollTop();
    },
  },
  methods: {
    async copyAddr() {
      try {
        // ie or firefox
        if (!navigator.clipboard?.readText) {
          this.$refs.address.select();
          document.execCommand("copy");
          showToast({ title: "주소가 복사되었습니다." });
          return;
        }

        // 그외 브라우저
        await navigator.clipboard.writeText(this.address);
        showToast({ title: "주소가 복사되었습니다." });
      } catch (e) {
        showToast({
          title: "클립보드 복사 실패",
          variant: "warning",
        });
        console.log(e);
      }
    },
    async getInfo() {
      const { kyoboApi } = this.getApis();

      
      const [hosinfo, hosItems] = await Promise.all([
        kyoboApi.hospital.getInfo(this.id),
        kyoboApi.hospital.getItem(this.code),
      ]);
      this.hosInfo = hosinfo.data;
      this.hosItems = hosItems.data.hospitalItems;
    },
    getRowInfo(row) {
      return this.hosInfo?.[row.key];
    },
    scrollTop() {
      const body = document.getElementById(
        "modal-hospitals-compare___BV_modal_body_"
      );
      body.scrollTo({ left: 0, top: 0 });
    },
  },
  mounted() {
    this.scrollTop();
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped></style>
