export default {
  title_4: {
    title: "제3조 개인정보의 보유 및 이용기간",
    text_1:
      "회원이 (주)녹십자헬스케어에서 제공하는 서비스를 받는 한 회원의 개인 정보는 (주)녹십자헬스케어에서 계속 보유하며 서비스 제공을 위한 목적으로만 사용하게 됩니다. 단, 회원의 개인정보는 수집목적 또는 제공받은 목적이 달성되거나, 회원의 탈퇴요청이 있을 때 일정 절차를 거친 후, 해당 개인의 정보는 재생할 수 없는 기술적 방법에 의해 삭제 되며, 어떠한 용도로도 열람 또는 이용될 수 없도록 처리됩니다. 단, 전자상거래법 시행령 제6조제3항의 법률규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있는 경우에는 일정기간 보유합니다.",
    text_2: "<br>",
    text_3: "- 계약 또는 청약철회 등에 관한 기록 : 5년",
    text_4: "- 대금결제 및 재화등의 공급에 관한 기록 : 5년",
    text_5: "- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년",
    text_6: "제4조 개인정보의 제3자 제공에 관한 사항",
    text_7: "원칙적으로 (주)녹십자헬스케어는 회원의 수집된 개인 정보를 서비스와 무관한 타 기업, 기관 등",
    text_8: "제 3자에게 공개하지 않습니다. 다만, 다음의 경우에는 정보주체의 동의 후 개인정보를 제공할 수 있습니다.",
    text_9: "<br>",
    text_10: "단, 정보제공에 동의 하지 않는 경우 서비스를 이용이 제한이 있을 수 있습니다.",
    text_11: "<br>",
    text_12: "① 회사의 제휴병원 및 기타 제휴업체의 서비스를 사용하기 위한 개인정보의 전달(검진기록 확인, 상담, 예약등)",
    text_13: "② 멤버쉽카드 및 기타 서비스에 필요한 DM발송을 위한 DM업체에 정보제공 : DM업체명 :코리아DM ,제공되는 정보 : 이름,지점명",
    text_14:
      "③ (주)녹십자헬스케어의 이용약관을 위배하거나 (주)녹십자헬스케어 서비스를 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 위법행위를 한 사람 등에게 법적인 조치를 취하기 위해 개인 정보를 공개해야 한다고 판단되는 경우",
    text_15: "④ 법령의 규정에 의하거나, 수사상의 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구 있는 경우.",
    text_16: "⑤ 통계 작성, 학술연구, 시장 조사, 정보 제공 및 공지 안내 메일 발송의 경우로써 특정 개인을 식별할 수 없는 형태로 제공되는 경우",
  },
  title_8: {
    title: "제8조 회원의 개인정보 관리 책임자",
    text_1: "(주)녹십자헬스케어는 개인정보보호법 의거 관리책임자를 아래와 같이 지정합니다.",
    text_2: "- 개인정보 관리책임자 : 성윤주 상무",
    text_3: "- 담당부서 : 영업본부",
    text_4: "- 연락처 : 02-2040-9145",
    text_5: "정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원",
    text_6: "개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.",
    text_7: "이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.",
    text_8: "1. 개인정보분쟁조정위원회(www.kopico.go.kr) : 02-2100-2499",
    text_9: "2. 대검찰청 사이버범죄수사단 : 02-3480-3571 (http://www.spo.go.kr/)",
    text_10: "3. 경찰청 사이버안전국 : (국번없이) 182 (http://cyberbureau.police.go.kr/)",
    text_11: "<br>",
    text_12: "「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조",
    text_13: "(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여",
    text_14: "권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.",
    text_15: "<br>",
    text_16: "※ 행정심판에 대해 자세한 사항은 법제처 (http://www.moleg.go.kr) 홈페이지를 참고하시기 바랍니다.",
  },
};
