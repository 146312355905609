<template>
  <!-- 전국협력 병원 모달 -->
  <b-modal
    id="modal-hospitals-compare"
    size="xl"
    :hide-footer="true"
    @shown="shownCb"
    @hide="hideCb"
    body-class="p-0 vh-100"
    header-class="p-0 d-block border-bottom-0"
    scrollable
    centered
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-4 px-md-5 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-between">
          <b-btn
            v-if="mode !== 'list'"
            :style="{ marginLeft: '-15px' }"
            variant="link"
            @click="back"
            ><img
              src="~@/assets/img/common/ic-header-navigation.svg"
              alt="arrow"
          /></b-btn>
          <h3 v-if="mode === 'list'" class="fs-24 fw-7">
            전국 협력병원
          </h3>
          <h3 v-else-if="mode === 'compare'" class="fs-24 fw-7">
            병원 비교하기
          </h3>
          <h3 v-else class="fs-24 fw-7">
            지도보기
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->

        <!-- 모달 닫기 버튼 -->
        <b-btn variant="link" @click="close()">
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>

      <!-- 모달 텝 -->
      <modal-tab
        v-if="isShow && ['list', 'detail'].includes(mode)"
        :items="mode === 'detail' ? ['병원정보', '검진항목'] : tabs"
        :offset="offset"
        :isScroll="mode !== 'detail'"
        scrollspyId="modal-hospitals-compare"
      ></modal-tab>
      <!-- // 모달 텝 -->
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div id="modal-body" class="h-100" v-if="isShow">
      <hospitals-compare
        v-if="mode === 'list'"
        :tabs="tabs"
        class="px-4 px-md-5"
        @handleMode="handleMode"
        @sendOffset="getOffset"
      ></hospitals-compare>
      <compare-hos
        v-else-if="mode === 'compare'"
        class="px-4 px-md-5"
        @handleMode="handleMode"
      ></compare-hos>
      <detail v-else-if="mode === 'detail'" @handleMode="handleMode"></detail>
    </div>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
import CompareHos from "@/components/common/modal/modalComponents/CompareHos.vue";
import Detail from "@/components/common/modal/modalComponents/Detail.vue";
import ModalTab from "@/components/common/modal/modalComponents/ModalTab.vue";
import HospitalsCompare from "@/components/common/modal/modalComponents/HospitalsCompare.vue";

export default {
  components: { CompareHos, ModalTab, HospitalsCompare, Detail },
  data() {
    return {
      tabs: [
        { title: "서울", keys: ["02"] },
        { title: "인천/경기", keys: ["031", "032"] },
        { title: "대전/충청", keys: ["042", "041", "043", "044"] },
        { title: "부산/경상", keys: ["051", "052", "053", "054", "055"] },
        { title: "광주/전라", keys: ["061", "062", "063"] },
        { title: "강원/제주", keys: ["033", "064"] },
      ],
      loading: false,
      isShow: false,
      offset: 0,
      mode: "list",
    };
  },
  methods: {
    back() {
      this.mode = "list";
    },
    handleMode(mode) {
      this.mode = mode;
    },
    shownCb() {
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    hideCb() {
      setTimeout(() => {
        this.isShow = false;
        this.mode = "list";
      }, 300);
    },
    getOffset(offset) {
      this.offset = offset + 60;
    },
  },
};
</script>
