var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"hc-container"},[(_vm.result)?[_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"theme-btn bg-theme-color-1 px-4 py-2 mr-3 fs-16",style:({
          minWidth: '8rem',
        })},[_vm._v("성장 평가 결과")])]),_c('div',{staticClass:"mb-4 fs-30 fs-md-30 fs-md",domProps:{"innerHTML":_vm._s(_vm.result.title)}}),_vm._l((_vm.resultCard),function(card){return _c('div',{key:card,staticClass:"border rounded p-4 p-md-5 fs-18 mb-4"},[_c('h6',{staticClass:"flex-between fs-20 fs-md-28"},[_c('span',{staticClass:"fw-7"},[_vm._v(_vm._s(card))]),_c('span',[_c('em',{staticClass:"fs-20 fs-md-24 fw-7",domProps:{"innerHTML":_vm._s(card === '키' ? _vm.result.height : _vm.result.weight)}}),_vm._v(" cm")])]),_c('div',{staticClass:"my-4"},[_c('div',{staticClass:"bg-gray-6 rounded-pill position-relative p-2 my-4"},[_c('div',{staticClass:"flex-center white rounded-circle bg-blue fs-15",style:({
              width: _vm.calcHeight('3rem'),
              height: _vm.calcHeight('3rem'),
              position: 'absolute',
              left:
                card === '키'
                  ? `${_vm.result.icm_hra_pt}%`
                  : `${_vm.result.ikg_hra_pt}%`,
              top: '50%',
              transform: `translate(-${
                card === '키' ? _vm.result.icm_hra_pt : _vm.result.ikg_hra_pt
              }%, -50%)`,
            })},[_vm._v(" "+_vm._s(card === "키" ? _vm.result.icm_hra_pt : _vm.result.ikg_hra_pt)+"% ")])]),_c('div',{staticClass:"flex-between fs-20"},[_c('span',[_vm._v("작음")]),_vm._v(" "),_c('span',[_vm._v("적정")]),_c('span',[_vm._v("큼")])])]),_c('div',{staticClass:"fs-20 fs-md-24"},[_c('p',{staticClass:"fw-7 theme-color-1",domProps:{"innerHTML":_vm._s(
            card === '키' ? _vm.result.r_typ2_sum_txt1 : _vm.result.r_typ1_sum_txt1
          )}}),_c('p',{domProps:{"innerHTML":_vm._s(
            card === '키' ? _vm.result.r_typ2_sum_txt2 : _vm.result.r_typ1_sum_txt2
          )}})])])}),_c('div',{staticClass:"flex-center py-5"},[_c('router-link',{staticClass:"blue-submit-btn fs-20",attrs:{"to":{
          ..._vm.$route,
          name: 'check-grow',
        }}},[_vm._v("완료")])],1)]:_c('skeleton-grow-insert')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }