<template>
  <div>
    <b-skeleton :animation="null" width="20%" height="2rem"></b-skeleton>
    <b-row class="mx-n3 mb-5">
      <b-col v-for="i in 2" :key="i" class="px-3 py-2" cols="6" md="3">
        <b-skeleton :animation="null" width="100%" height="5rem"></b-skeleton>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mb-2">
        <b-skeleton :animation="null" width="60%" height="2.5rem"></b-skeleton>
      </b-col>
      <b-col cols="12" class="mb-4">
        <b-skeleton :animation="null" width="45%" height="2.5rem"></b-skeleton>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-skeleton :animation="null" width="30%" height="2.5rem"></b-skeleton>
      </b-col>
    </b-row>
    <div class="pt-5">
      <b-skeleton-img
        :animation="null"
        no-aspect
        height="10rem"
      ></b-skeleton-img>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
