<template>
  <!--  -->
  <b-modal
    :id="modalId"
    :hide-footer="true"
    size="lg"
    @show="showCb"
    @hide="hideCb"
    body-class="p-0"
    header-class="p-0 d-block border-bottom-0"
    scrollable
    centered
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-4 px-md-5 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-start">
          <h3 class="fs-24 fw-7 mt-1">
            아이 등록하기
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <w-form class="px-4 px-md-5" @submit="submit" v-slot="{ invalid }">
      <!-- 아이 정보 리스트 -->
      <div>
        <hash-title :title="'아이 정보'" :isHash="false"></hash-title>
        <b-row
          align-v="center"
          class="mx-0 border-bottom border-gray-5 py-3 fs-20"
        >
          <b-col cols="4">자녀 이름</b-col>
          <div class="col-8">
            <template v-if="childs">
              <div v-if="!childs.length" class="red">
                ※ 아이 성장 체크 서비스는 출산 자녀가 있는 경우 이용하실 수
                있습니다.
              </div>
              <div v-else-if="childs.length === 1">
                {{ childs[0].text }}
              </div>
              <hc-select-default
                v-else
                v-model="selectedChild"
                ref="hc-select-default"
                rules="required"
                inputClass="fs-20"
                :items="childs"
              ></hc-select-default>
            </template>
            <b-skeleton
              v-else
              :animation="null"
              width="7rem"
              height="1.5rem"
            ></b-skeleton>
          </div>
        </b-row>
        <!-- 생년월일 -->
        <b-row
          align-v="center"
          class="mx-0 border-bottom border-gray-5 py-3 fs-20"
        >
          <b-col cols="4">생년월일</b-col>
          <b-col cols="8">
            <b-btn
              variant="link"
              class="border-0 black"
              :style="{ width: '170px' }"
              @click="$bvModal.show('modal-date-input')"
            >
              <hc-input-default
                class="bg-white border rounded px-2 black"
                inputClass="border-0 fs-20"
                readonly
                name="date"
                rules="required"
                :style="{
                  maxWidth: '10rem',
                }"
                :errPosLeft="true"
                v-model="birth"
              >
                <template #append>
                  <div class="flex-center">
                    <i class="icon-calendar"></i>
                  </div> </template
              ></hc-input-default>
            </b-btn>
            <modal-date-picker
              :id="'modal-date-input'"
              v-model="birth"
            ></modal-date-picker>
          </b-col>
        </b-row>
      </div>
      <!-- 동의하기 -->
      <div class="flex-center py-4">
        <w-checkbox
          :label="'개인정보 수집 및 이용동의 (필수)'"
          :name="'개인정보 수집 및 이용동의 (필수)'"
          v-model="isAgree"
          class="py-1 mr-2"
        >
          <a class="fs-20 fs-md-18">개인정보 수집 및 이용동의 (필수)</a>
        </w-checkbox>
        <b-btn variant="link" @click="$bvModal.show('modal-term')">
          <div
            class="bg-img"
            :style="{
              width: calcHeight('3rem'),
              paddingBottom: calcHeight('3rem'),
              backgroundImage: `url(${require('@/assets/img/common/ic-agree-zoom.svg')})`,
            }"
          ></div>
        </b-btn>
      </div>
      <!-- 등록하기 -->
      <div class="flex-center pt-3 pb-5">
        <b-btn
          variant="link blue-submit-btn fs-20 fs-md-18 px-5"
          @click="submit"
          :disabled="invalid"
          >등록하기</b-btn
        >
      </div>
    </w-form>
    <!-- // 모달 바디 -->
    <modal-term
      @agree="isAgree = true"
      :isPrivacy="true"
      consentKey="schedule"
    ></modal-term>
  </b-modal>
</template>

<script>
import { showToast } from "@/plugins/custom-toast";
import ModalTerm from "@/components/common/modal/ModalTerm.vue";

export default {
  components: {
    ModalTerm,
  },
  data() {
    return {
      info: null,
      birth: null,
      selectedChild: null,
      isAgree: false,
      modalId: "modal-child-registration",
    };
  },
  computed: {
    // key 값 변경 후 출산전 아이 제거
    childs() {
      return this.info?.childs
        ?.map((val) => ({
          ...val,
          value: val.joinserial,
          text: val.contactname || "-",
        }))
        .filter((item) => item.bithday);
    },
  },
  methods: {
    async getInfo() {
      const { kyoboApi } = this.getApis();
      const {
        data: { info },
      } = await kyoboApi.program.common.info();
      this.info = info;
    },
    async insert() {
      const { kyoboApi } = this.getApis();

      const body = {
        babyName:
          this.childs.length === 1
            ? this.childs[0].text
            : this.selectedChild.text,
        birthday: this.birth,
      };

      const {
        data: { message },
      } = await kyoboApi.program.schdule.childInsert(body);
      return message;
    },
    handleDate(target, date) {
      this.input[target] = date;
    },
    async submit() {
      if (!this.isAgree)
        return this.$root.$emit(
          "showModalAlert",
          "개인정보 수집 및 이용에 동의해 주세요."
        );
      const message = await this.insert();
      showToast({ title: message });
      this.$emit("refresh");
      this.$bvModal.hide("modal-child-registration"); // bootstrap modal hide
    },
    reset() {
      this.birth = null;
      this.selectedChild = null;
      this.isAgree = false;
    },
    showCb() {
      this.getInfo();
    },
    hideCb() {
      this.reset();
    },
  },
};
</script>
