<template>
  <b-modal id="login-info"
    size="login"
    v-model="modalShow"
    :hide-footer="true"
    body-class="px-3 px-md-4 py-0 pb-3"
    header-class="px-3 px-md-4 border-bottom-0 d-block"
    scrollable
    centered
    >
    <template #modal-header="{ close }">
      <b-row align-v="center">
        <b-col>
          <h3 class="fs-24 fs-md-20 fw-7 mt-1" :class="serviceType()">서비스 이용을 위해 로그인이 필요합니다.</h3>
        </b-col>
      </b-row>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="modal-body fs-18">
      <div class="point" :class="serviceType()">
        <span>{{ `${resizeW >= 768 ? '우측' : '좌측'}상단` }}</span>
        <span class="triangle"></span>
        <div class="point-img flex-center blue fw-7 fs-18">
          <div
            class="bg-img mr-2"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-user.svg')})`,
              width: calcHeight('2rem'),
              paddingBottom: calcHeight('2rem'),
            }"
          ></div>
          <span class="d-block pr-2">로그인</span>
        </div>
      </div>
      <div class="info">
        <p>헬스케어서비스 대상 고객님들에 한하여 서비스 이용이 가능하며, 이를 위해 교보생명 홈페이지 로그인이 필요합니다.</p>
        <br />
        <p class="red">※ 대상 고객이 아닐 시 상품서비스의 전체 소개 화면이 보여집니다.</p>
      </div>
    </div>
    <button class="modal-close mt-2" :class="serviceType()" @click="closeModal()">닫기</button>
  </b-modal>
</template>

<script>
import store from "@/store/index";
export default {
  components: {
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  data() {
    return {
      modalShow: true,
      resizeW: window.innerWidth
    };
  },
  methods: {
    serviceType () {
      if (this.type === 'kyobowomancare') {
        return 'woman'
      } else if (this.type === 'kyobokidscare') {
        return 'kids'
      } else if (this.type === 'kyobojuniorcare') {
        return 'junior'
      } else if (this.type === 'kyoboseniorcare') {
        return 'senior'
      }
    },
    closeModal () {
      this.$bvModal.hide('login-info')
      store.commit("modal/set", ["loginState", false]);
    },
    handleResize(event) {
      this.resizeW = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
};
</script>
<style scoped lang="scss">
.modal .modal-login {
  width: 600px !important;
  max-width: 600px !important;
  h3 {
    border-bottom: 3px solid #4ca223;
    text-align: left; 
    padding: 1em 0 1.3em 0;
    &.woman {
      border-bottom: 3px solid #ffafd3 !important;
    }
    &.kids {
      border-bottom: 3px solid #5d5d5d !important;
    }
    &.junior {
      border-bottom: 3px solid #b0dfff !important;
    }
    &.senior {
      border-bottom: 3px solid #c2c4f5 !important;
    }
  }
  .point {
    text-align: center;
    color: #4ca223;
    font-weight: bold;
    font-size: 0.85em;
    &.woman {
      color: #ffafd3 !important;
      .triangle {
        border-top: 10px solid #ffafd3 !important;
      }
      .point-img {
        border: 6px solid #ffafd3 !important;
      }
    }
    &.kids {
      color: #5d5d5d !important;
      .triangle {
        border-top: 10px solid #5d5d5d !important;
      }
      .point-img {
        border: 6px solid #5d5d5d !important;
      }
    }
    &.junior {
      color: #b0dfff !important;
      .triangle {
        border-top: 10px solid #b0dfff !important;
      }
      .point-img {
        border: 6px solid #b0dfff !important;
      }
    }
    &.senior {
      color: #c2c4f5 !important;
      .triangle {
        border-top: 10px solid #c2c4f5 !important;
      }
      .point-img {
        border: 6px solid #c2c4f5 !important;
      }
    }
    .triangle {
      width: 0;
      height: 0;
      border-top: 10px solid #4ca223;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      display: block;
      margin: 0 auto;
    }
    .point-img {
      border: 6px solid #4ca223;
      border-radius: 2em;
      width: 120px;
      margin: 4px auto 1.5em auto;
    }
  }
  .info {
    padding: 2.3em 2em;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    letter-spacing: -0.75px;
    text-align: left;
  }
}
.modal-close {
  display: flex;
  width: 150px;
  height: 50px;
  margin: 0 auto 2em auto;
  border-radius: 8px;
  background: #4ca223;
  color: #fff;
  align-items: center;
  justify-content: center;
  &.woman {
    background: #ffafd3 !important;
  }  
  &.kids {
    background: #5d5d5d !important;
  }
  &.junior {
    background: #b0dfff !important;
  }
  &.senior {
    background: #c2c4f5 !important;
  }
}
</style>
