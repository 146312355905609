var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.programItems.length)?[_c('tab-swiper',{staticClass:"mb-4",attrs:{"items":_vm.programItems,"mobile":true,"isNaviHide":true,"options":{
        slidesPerView: 3,
        spaceBetween: 18,
        breakpoints: {
          400: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          994: {
            slidesPerView: 2,
            spaceBetween: 18,
          },
        },
      }},scopedSlots:_vm._u([{key:"default",fn:function({ tab }){return [_c('hc-card',_vm._b({style:({ minHeight: _vm.calcHeight('18.5rem', '3vh') }),on:{"handleClick":function($event){return _vm.handleApply(tab.id)}}},'hc-card',{
          ...tab,
          key: tab.title,
          textCenter: true,
          btnText: '신청하기',
        },false))]}}],null,false,577800292)}),_vm._m(0)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"red fs-18"},[_vm._v(" - 피부 프로그램, 스트레스 프로그램 중 선택 하실 수 있습니다. ")]),_c('p',{staticClass:"red fs-18"},[_vm._v(" - 건강증진 프로그램은 연 1회 한 번만 이용이 가능하며, 각 프로그램별로 14~16주간 진행됩니다. ")]),_c('p',{staticClass:"red fs-18"},[_vm._v(" - 기타 궁금한 사항을 헬스콜센터(1588-7524)로 문의 바랍니다. ")])])
}]

export { render, staticRenderFns }