export default {
  title_1: {
    title: "제1조 개인정보의 수집 및 이용목적",
    text_1:
      "(주)녹십자헬스케어는 회원의 개인정보를 수집하고 있습니다. (주)녹십자헬스케어는 수집된 개인정보를 통하여 회원께 개인별 맞춤 평생건강관리서비스, 건강상담, 건강증진프로그램, 건강의료정보 등 다양하고 전문화된 서비스를 제공합니다.",
    text_2:
      "수집된 개인정보는 이용목적 이외의 용도로 이용되지 않으며, 이용목적이 변경되는 경우에는 개인정보 보호법에 따라 별도의 동의를 받을 수 있습니다.",
  },
  title_2: {
    title: "제2조 수집하려는 개인정보의 항목",
    text_1:
      "(주)녹십자헬스케어는 회원으로 가입하실 때 서비스 제공을 위한 필수적인 정보들을 온라인상에서 입력 받고 있습니다. 회원 가입시에 받는 정보는 이름, 아이디,패스워드, 이메일 이며, 검진 및 진료예약등의 서비스를 위해서는 정보주체 동의 후 자택주소,전화번호등을 추가로 요청/수집 할 수 있습니다. 또한 온라인서비스를 위해 서비스 이용/접속기록 및 쿠키를 수집하고 있습니다.",
  },
  title_3: {
    title: "제3조 개인정보의 보유 및 이용기간",
    text_1:
      "회원이 (주)녹십자헬스케어에서 제공하는 서비스를 받는 한 회원의 개인 정보는 (주)녹십자헬스케어에서 계속 보유하며 서비스 제공을 위한 목적으로만 사용하게 됩니다. 단, 회원의 개인정보는 수집목적 또는 제공받은 목적이 달성되거나, 회원의 탈퇴요청이 있을 때 일정 절차를 거친 후, 해당 개인의 정보는 재생할 수 없는 기술적 방법에 의해 삭제 되며, 어떠한 용도로도 열람 또는 이용될 수 없도록 처리됩니다. 단, 전자상거래법 시행령 제6조제3항의 법률규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있는 경우에는 일정기간 보유합니다.",
    text_2: "- 계약 또는 청약철회 등에 관한 기록 : 5년",
    text_3: "- 대금결제 및 재화등의 공급에 관한 기록 : 5년",
    text_4: "- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년",
  },
  title_4: {
    title: "제4조 개인정보의 제3자 제공에 관한 사항",
    text_1:
      "원칙적으로 (주)녹십자헬스케어는 회원의 수집된 개인 정보를 서비스와 무관한 타 기업, 기관 등 제 3자에게 공개하지 않습니다. 다만, 다음의 경우에는 정보주체의 동의 후 개인정보를 제공할 수 있습니다.",
    text_2: "단, 정보제공에 동의 하지 않는 경우 서비스를 이용이 제한이 있을 수 있습니다.",
    text_3: "<br>",
    text_4: "① 회사의 제휴병원 및 기타 제휴업체의 서비스를 사용하기 위한 개인정보의 전달(검진기록 확인, 상담, 예약등)",
    text_5: "② 멤버쉽카드 및 기타 서비스에 필요한 DM발송을 위한 DM업체에 정보제공 : DM업체명 :코리아DM ,제공되는 정보 : 이름,지점명",
    text_6:
      "③ (주)녹십자헬스케어의 이용약관을 위배하거나 (주)녹십자헬스케어 서비스를 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 위법행위를 한 사람 등에게 법적인 조치를 취하기 위해 개인 정보를 공개해야 한다고 판단되는 경우",
    text_7: "④ 법령의 규정에 의하거나, 수사상의 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구 있는 경우.",
    text_8: "⑤ 통계 작성, 학술연구, 시장 조사, 정보 제공 및 공지 안내 메일 발송의 경우로써 특정 개인을 식별할 수 없는 형태로 제공되는 경우",
  },
  title_5: {
    title: "제5조 기술적, 관리적 대책",
    text_1:
      "(주)녹십자헬스케어는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 회원들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호알고리즘 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 기술적 보안수준을 갖추고 있습니다. (주)녹십자헬스케어의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보 보호정책의 준수를 항상 강조하고 있습니다.",
  },
  title_6: {
    title: "제6조 정보주체의 권리·의무 및 그 행사방법에 관한 사항",
    text_1: `회원은 언제든지 해당 홈페이지를 이용하여 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다. 가입해지는 회원 로그인 후 My page>회원정보수정>회원탈퇴를 선택하신 후 홈페이지의 안내사항에 따라 항목 선택 및 질문사항을 입력하고 "탈퇴신청"을 선택하시면, 입력하신 사항을 기초로 회원 본인여부를 확인한 후 처리합니다. 회사는 회원의 요청에 의해 해지 또는 삭제된 개인정보는 보유 및 이용기간에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`,
  },
  title_7: {
    title: "제7조 개인 아이디와 비밀번호 관리",
    text_1:
      "회원이 사용하고 계시는 ID와 비밀번호는 원칙적으로 회원만이 사용하도록 되어 있습니다. (주)녹십자헬스케어의 고의 또는 과실이 없는 경우에, 회원의 ID와 비밀번호 도용 또는 기타 타인의 사용으로 의해 발생된 문제에 대하여 (주)녹십자헬스케어는 책임지지 않습니다. 어떠한 경우에도 비밀번호는 타인에게 알려 주지 마시고 로그온 상태에서는 주위의 다른 사람에게 개인 정보가 유출되지 않도록 특별한 주의를 기울여 주시기 바랍니다. 타인의 개인정보를 도용하여 회원 가입 또는 구매가 확인되었을 경우에는 이용계약이 일방적으로 해지될 수 있으며, 주민등록법에 의해 3년 이하의 징역 또는 1천만원 이하의 벌금이 부과될 수 있습니다.",
    text_2:
      "1.홈페이지를 1년 동안 이용(미로그인) 하지 않은 경우 개인정보 보호를 위해 ID와 비밀번호를 파기 하며, 파기 30일전까지 파기에 대한 안내를 이메일을 통해 안내드립니다.",
    text_3: "- 보존 대상 개인정보 : 최근 접속일, 가입일, 파기일",
    text_4: "2.파기 이전에 홈페이지 로그인 할경우 최근 접속일 기준으로 파기에서 제외 됩니다.",
    text_5: "3.안내된 파기 예정 날짜와 실제 ID와 비밀번호를 파기되는 시간은 내부 작업 과정에서 약간의 차이가 발생할 수 있습니다.",
  },

  title_8: {
    title: "제8조 회원의 개인정보 관리 책임자",
    text_1: "(주)녹십자헬스케어는 개인정보보호법 의거 관리책임자를 아래와 같이 지정합니다.",
    text_2: "<br/>",
    text_3: `- 개인정보 관리책임자 : 정회진 부장`,
    text_4: `- 담당부서 : IT혁신본부`,
    text_5: `- 연락처 : 02-2100-9877`,
    text_6: `- 팩스번호 : 02-2040-9101`,
    text_7: `- 메일주소 : privacy@gchealthcare.com`,
    text_8: "<br/>",
    text_9: `- 개인정보 관리담당자 : 최중우 차장`,
    text_10: `- 담당부서 : SM팀`,
    text_11: `- 연락처 : 02-2100-9814`,
    text_12: "<br/>",
    text_13: `정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원`,
    text_14: `개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.`,
    text_15: `이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.`,
    text_16: `1. 개인정보분쟁조정위원회(www.kopico.go.kr) : 02-2100-2499`,
    text_17: `2. 대검찰청 사이버범죄수사단 : 02-3480-3571 (http://www.spo.go.kr/)`,
    text_18: `3. 경찰청 사이버안전국 : (국번없이) 182 (http://cyberbureau.police.go.kr/)`,
    text_19: "<br/>",
    text_20: `「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조`,
    text_21: `(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여`,
    text_22: `권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.`,
    text_23: "<br/>",
    text_24: `※ 행정심판에 대해 자세한 사항은 법제처 (http://www.moleg.go.kr) 홈페이지를 참고하시기 바랍니다.`,
  },
};
