<template>
  <div class="pt-4">
    <!-- 전국 협력병원 (병원진료예약) -->
    <template v-if="hospitals">
      <div v-for="(hos, idx) in dividList" :id="`modal-tab-${idx}`" :key="idx">
        <!-- 타이틀 -->
        <div class="border-bottom border-3 border-black pt-3 pt-md-5 pb-3">
          <hash-title :title="hos.region"></hash-title>
        </div>
        <!-- // 타이틀 -->

        <!-- 리스트 -->
        <ul class="mb-3">
          <li v-for="(row, idx) in hos.items" :key="idx" class="flex-between border-bottom border-gray-5 py-4 px-2">
            <div class="d-flex flex-column">
              <span class="fw-7 fs-20">{{ row.MED_HOS_NM }}</span>
              <span class="gray-5 fs-18 fs-md-16">{{ row.h_adress1 }} {{ row.MED_HOS_ADD }}</span>
            </div>
            <div v-if="!hideMap">
              <b-btn variant="link" @click="handleClick(row)">
                <img
                  src="~@/assets/img/common/ic-map.svg"
                  alt="map"
                  :style="{
                    width: calcHeight('2rem'),
                  }"
                />
              </b-btn>
            </div>
          </li>
        </ul>
        <!-- // 리스트 -->
      </div>
      <div style="height: 60vh"></div>
    </template>
    <!-- // 전국 협력병원 (병원진료예약) -->

    <!-- 스켈레톤 -->
    <skeleton-modal-list v-else></skeleton-modal-list>
    <!-- // 스켈레톤 -->
  </div>
</template>

<script>
import SkeletonModalList from "@/components/common/SkeletonModalList";

export default {
  components: {
    SkeletonModalList,
  },
  props: {
    hideMap: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hospitals: null,
    };
  },
  methods: {
    async getList() {
      const { kyoboApi } = this.getApis();
      const { data } = await kyoboApi.hospital.getLists();
      this.hospitals = data.hospitals;
    },
    handleClick({ x, y, MED_HOS_NM, MED_HOS_ADD }) {
      this.$emit("isMapChange");
      this.$router
        .replace({
          ...this.$route,
          query: { x, y, name: MED_HOS_NM, address: MED_HOS_ADD },
        })
        .catch(() => {});
    },
  },
  computed: {
    dividList() {
      return this.tabs.map((tab) => {
        const items = this.hospitals.filter((row) => tab.keys.find((key) => key === row.MED_AREA_NUM));
        return { region: tab.title, items };
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
