<template>
  <div>
    <b-row>
      <b-col cols="3" class="py-4">
        <b-skeleton :animation="null" width="100%" height="3rem"></b-skeleton>
      </b-col>
    </b-row>
    <b-row
      v-for="i in 3"
      :key="`${i}-1`"
      class="border-bottom border-gray-5 py-3"
    >
      <b-col cols="4">
        <b-skeleton :animation="null" width="60%" height="2.5rem"></b-skeleton>
      </b-col>
      <b-col cols="8">
        <b-skeleton :animation="null" width="100%" height="2.5rem"></b-skeleton>
      </b-col>
    </b-row>

    <b-row class="py-5">
      <b-col>
        <b-skeleton-img
          :animation="null"
          height="15rem"
          no-aspect
        ></b-skeleton-img>
      </b-col>
    </b-row>

    <b-row align-h="center" class="my-5">
      <b-col cols="3">
        <b-skeleton :animation="null" height="2.5rem"></b-skeleton>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
