<template>
  <b-btn
    variant="link"
    class="border-bottom border-gray-5 w-100 list"
    @click="$emit('handleClick', $event)"
  >
    <!-- pc -->
    <b-row align-v="center" class="mx-0 d-none d-md-flex py-3">
      <b-col cols="7" class="text-left">
        <div class="fs-18 gray text-truncate title">{{ title }}</div>
      </b-col>
      <b-col cols="5">
        <div class="fs-18 flex-end">
          <span class="gray-5">{{ formatedDate }}</span>
          <span
            :class="[
              isAnswer ? 'white rounded-pill bg-blue-2' : 'blue-2 rounded-pill',
              'ml-4 fs-16 py-2 px-3 border border-blue-2 border-3',
            ]"
          >
            {{ isAnswer ? "답변완료" : "답변대기" }}</span
          >
        </div>
      </b-col>
    </b-row>
    <!-- // pc -->

    <!-- mobile -->
    <b-row
      align-v="center"
      class="d-flex d-md-none mx-0"
      :style="{ height: '5.25rem' }"
    >
      <b-col cols="12" class="flex-between">
        <div class="text-left overflow-hidden flex-1-1-0">
          <div class="gray-5">{{ formatedDate }}</div>
          <div class="fs-20 gray text-truncate title">
            {{ title }}
          </div>
        </div>
        <span
          :class="[
            isAnswer ? 'white rounded-pill bg-blue-2' : 'blue-2 rounded-pill',
            'fs-16 fw-7 p-2 border border-blue-2 border-2',
          ]"
        >
          {{ isAnswer ? "답변완료" : "답변대기" }}</span
        >
      </b-col>
    </b-row>
    <!-- //mobile -->
  </b-btn>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    isAnswer: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "아랫배가 전체적으로 아플 때",
    },
    date: {
      type: String,
      default: "YYYY-MM-DD",
    },
  },
  computed: {
    formatedDate() {
      return dayjs(this.date).format("YYYY-MM-DD");
    },
  },
};
</script>
