<template>
  <!-- 병원 비교하기 -->
  <div class="overflow-auto">
    <p class="py-5 fs-16">
      선택검사: 같은 알파벳 내에서 검사항목 한 개를 선택하여 받으실 수 있습니다.
    </p>
    <div v-if="hospitalItems.length" :style="{ minWidth: '500px' }">
      <!-- head -->
      <b-row class="mx-0">
        <b-col
          class="px-0 flex-center py-3 fs-18"
          v-for="head in tHead"
          :key="head"
          :style="{
            backgroundColor: '#f8f8f8',
            borderBottom: '1px solid #eee',
            borderTop: '1px solid #eee',
          }"
        >
          {{ head }}
        </b-col>
      </b-row>
      <!-- // head -->

      <!-- body -->
      <b-row
        v-for="(body, i) in tBody"
        :key="i"
        class="mx-0"
        :style="{
          borderBottom: '1px solid #eee',
        }"
      >
        <b-col
          class="p-2 pl-3"
          :style="{
            borderRight: '1px solid #eee',
          }"
        >
          {{ body.sname }}
        </b-col>
        <b-col
          class="flex-center"
          v-for="(hos, i) in body.hosArr"
          :key="i"
          :style="{
            ...(body.hosArr.length !== i + 1 && {
              borderRight: '1px solid #eee',
            }),
          }"
        >
          <i
            :class="[hos.isYN === 'N' ? 'icon-cancel-2' : 'icon-circle-empty']"
          ></i>
        </b-col>
      </b-row>
      <!-- // body -->
    </div>

    <!-- 스켈러톤 -->
    <div v-else :style="{ minWidth: '500px' }">
      <b-row class="mx-0">
        <b-col
          class="px-0 flex-center py-3"
          v-for="i in $route.query.codes.length + 1"
          :style="{
            backgroundColor: '#f8f8f8',
            borderBottom: '1px solid #eee',
            borderTop: '1px solid #eee',
          }"
          :key="i"
        >
          <b-skeleton :animation="null" width="30%" height="2rem"></b-skeleton>
        </b-col>
      </b-row>

      <b-row
        v-for="i in 15"
        :key="i"
        class="mx-0"
        :style="{
          borderBottom: '1px solid #eee',
        }"
      >
        <b-col
          class="p-2 pl-3 flex-center"
          :style="{
            borderRight: '1px solid #eee',
          }"
        >
          <b-skeleton :animation="null" width="20%"></b-skeleton>
        </b-col>
        <b-col
          class="flex-center"
          v-for="(hos, i) in $route.query.codes"
          :key="i"
          :style="{
            ...($route.query.codes.length !== i + 1 && {
              borderRight: '1px solid #eee',
            }),
          }"
        >
          <b-skeleton :animation="null" width="20%"></b-skeleton>
        </b-col>
      </b-row>
    </div>
    <!-- // 스켈러톤 -->
  </div>
  <!-- // 병원 비교하기 -->
</template>

<script>
export default {
  data() {
    return {
      eitem: [],
      hospitalItems: [],
    };
  },
  computed: {
    codes() {
      return this.$route.query.codes?.join();
    },
    tHead() {
      const names = this.hospitalItems.map((el) => el.h_name);
      return ["A-0", ...names];
    },
    tBody() {
      return this.eitem.map((el) => {
        const hosArr = this.hospitalItems.map((_el) =>
          _el.scodes.find((__el) => __el.scode === el.scode)
        );
        return { ...el, hosArr };
      });
    },
  },
  methods: {
    async compareInfo() {
      const { kyoboApi } = this.getApis();
      const body = { hpt_codes: this.codes };
      const {
        data: { eitem, hospitalItems },
      } = await kyoboApi.hospital.compare(body);

      this.eitem = eitem;
      this.hospitalItems = hospitalItems;
    },
  },
  async mounted() {
    await this.compareInfo();
  },
};
</script>

<style lang="scss" scoped></style>
