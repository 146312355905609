<template>
  <div>
    <validation-provider
      ref="provider"
      :name="name"
      :rules="rules"
      class="position-relative d-block"
      v-slot="validationContext"
    >
      <b-input-group :class="inputGroupClass">
        <b-input
          class="px-2 py-1 d-inline-block"
          :name="name"
          v-bind="{
            ...$props,
            class: [
              inputClass,
              {
                'hide-icon': hideStateIcon,
              },
            ],
            style: { ...styles },
          }"
          @input="inputListener($event)"
          :value="value"
        >
        </b-input>
        <template v-for="(_, slot) in $slots">
          <slot :name="slot" />
        </template>
      </b-input-group>
      <!-- 모바일 에러 메세지 -->
      <p
        :class="[
          errPosLeft ? 'd-block d-md-none' : 'd-none',
          'red fs-20 fs-md-18 fw-3 w-100 text-nowrap mt-2',
        ]"
        v-if="isErrorMessage && rules && !getValidationState(validationContext)"
      >
        {{ validationContext.errors[0] }}
      </p>
      <!-- 피시 에러 메세지 -->
      <p
        :class="[
          errPosLeft ? 'd-none d-md-block' : 'mt-2',
          'red fs-20 fs-md-18 fw-3 position-absolute w-100 text-nowrap',
        ]"
        :style="{
          ...(errPosLeft && {
            top: '50%',
            left: '100%',
            transform: 'translateY(-50%)',
          }),
          ...(!errPosLeft && {
            top: '120%',
            left: '0',
            transform: 'translateY(-50%)',
          }),
        }"
        v-if="isErrorMessage && rules && !getValidationState(validationContext)"
      >
        {{ validationContext.errors[0] }}
      </p>
    </validation-provider>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, default: "text" },
    value: { default: "" },
    inputClass: { default: () => "", type: String },
    inputGroupClass: { default: () => "", type: String },
    placeholder: { type: String },
    name: { type: String },
    label: { type: String },
    rules: { type: [String, Object] },
    serverCheck: { default: false },
    readonly: { default: false, type: Boolean },
    disabled: { default: false },
    hideError: { default: false },
    hideStateIcon: { default: true },
    styles: { type: Object },
    isErrorMessage: { type: Boolean, default: true },
    errPosLeft: { type: Boolean, default: false },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    inputListener(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
  },
};
</script>

<style>
.form-control.hide-icon {
  background-image: none !important;
}
</style>
