<template>
  <div class="position-relative sub-slide">
    <div class="mb-3 flex-between align-items-end">
      <!-- 타이틀 -->
      <div>
        <h3 class="fw-7 fs-24 fs-md-22">{{ title }}</h3>
        <p class="fw-3 fs-22 fs-md-19 mt-1">{{ subTitle }}</p>
      </div>
      <!-- // 타이틀 -->

      <!-- 슬라이드 버튼 -->
      <div
        v-if="slides.length >= 1 && !['healthDementia'].includes($i18n.locale)"
        class="navi-wrap flex-center mb-2 d-none d-md-flex"
        :style="{
          zIndex: 30,
        }"
      >
        <b-btn variant="link" :class="prevEl" slot="button-prev">
          <img
            :src="`${require(`@/assets/img/${$i18n.locale}/ic-arrow.svg`)}`"
            alt="arrow"
            :style="{
              transform: 'rotate(0.5turn)',
            }"
          />
        </b-btn>
        <span class="px-3"
          ><img src="~@/assets/img/common/ic-gray-line.svg" alt="liner"
        /></span>
        <b-btn variant="link" :class="nextEl" slot="button-next">
          <img
            :src="`${require(`@/assets/img/${$i18n.locale}/ic-arrow.svg`)}`"
            alt="arrow"
          />
        </b-btn>
      </div>
      <!-- // 슬라이드 버튼 -->
    </div>
    <div class="sub-slide">
      <!-- 슬라이더 -->
      <swiper
        class="sub-slide position-relative swiper-mobile"
        :options="options"
      >
        <swiper-slide
          v-for="(slide, idx) in slides"
          :key="idx"
          :class="{ shadow }"
        >
          <slot :slide="slide" :idx="idx"> </slot>
        </swiper-slide>
      </swiper>
      <!-- // 슬라이더 -->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    prevEl() {
      return this.options.navigation.prevEl.split(".")[1];
    },
    nextEl() {
      return this.options.navigation.nextEl.split(".")[1];
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    slides: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      defulat: null,
    },
    shadow: {
      type: Boolean,
      defulat: false,
    },
  }
};
</script>
