// i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import data from "@/lib/data";

Vue.use(VueI18n);

const defaultType = "kbProducts";
export default new VueI18n({
  // default locale
  locale: defaultType,
  // To use when matched locals lack
  fallbackLocale: defaultType,
  // message
  silentTranslationWarn: true,
  messages: { ...data },
});
