<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="py-5">
        <b-skeleton :animation="null" width="50%" height="3rem"></b-skeleton>
      </b-col>
      <b-col cols="12">
        <b-skeleton-img
          :animation="null"
          width="100%"
          height="28rem"
        ></b-skeleton-img>
      </b-col>
    </b-row>
    <b-row class="py-2 border-bottom border-gray-5 row" v-for="i in 2" :key="i">
      <b-col cols="2">
        <b-skeleton :animation="null" width="100%" height="3rem"></b-skeleton>
      </b-col>
      <b-col cols="10">
        <b-skeleton :animation="null" width="30%" height="3rem"></b-skeleton>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
