<template>
  <b-container class="hc-container">
    <template v-if="report">
      <!-- 기획기사 -->
      <template v-if="isHealthNews">
        <!-- 제목 -->
        <h3 class="pb-4 border-bottom border-gray-5 mb-5 flex-start">
          <span
            v-if="splitedTitle(report.title).column"
            class="theme-btn px-2 mr-3 fs-18"
            :style="{
              minWidth: calcHeight('6rem'),
            }"
            >{{ splitedTitle(report.title).column }}</span
          >
          <span
            v-html="
              splitedTitle(report.title)
                ? splitedTitle(report.title).title
                : report.title
            "
            class="fs-20 fs-md-24 fw-7 gray"
          ></span>
        </h3>
        <!-- // 제목 -->

        <!-- // 내용 -->
        <div
          :style="{ backgroundColor: '#fafafa' }"
          class="p-2 p-md-4 mb-5 rounded-10"
        >
          <div class="mb-5">
            <div
              v-html="report.content"
              class="gray fs-19 news-detail-content"
            ></div>
          </div>
        </div>
        <!-- // 내용 -->
      </template>
      <!-- // 기획기사 -->
      <!-- 최신뉴스 -->
      <template v-else>
        <!-- 제목 -->
        <h3 class="fs-24 fw-7 gray pb-5 border-bottom border-gray-5">
          {{ report.title }}
        </h3>
        <!-- // 제목 -->

        <!-- 내용 -->
        <div class="py-5 flex-center">
          <div
            class="bg-img new-img position-relative overflow-hidden"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/news-detail.svg')})`,
              width: '50rem',
              paddingBottom: '28.125rem',
              borderRadius: '10px',
            }"
          >
            <div
              :style="{
                height: '28.125rem',
              }"
            >
              <a
                :href="getNewsUrl"
                target="_blank"
                variant="link"
                class="white-line-btn fs-20"
                :style="{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  zIndex: 5,
                  transform: 'translate(-50%, -50%)',
                }"
              >
                기사 자세히 보기
              </a>
              <div
                class="fs-18 white text-center py-4"
                :style="{
                  position: 'absolute',
                  left: '50%',
                  bottom: '3%',
                  zIndex: 5,
                  transform: 'translateX(-50%)',
                  width: '90%',
                }"
              >             
                <p class="text-break" v-if="isKidNews">출처: {{ report.content }}</p>
                <p class="text-break" v-else>출처: {{ report.origin }}</p>
                <p>기사발표일 : {{ regdate }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- // 내용 -->
      </template>
      <!-- // 최신뉴스 -->

      <!-- 목록보기 -->
      <div class="flex-center mb-5">
        <router-link
          class="blue-submit-btn px-5 fs-20 fs-md-16"
          :to="{ name: 'info-news', key }"
          >목록보기</router-link
        >
      </div>
      <!-- // 목록보기 -->

      <!-- 이전글, 다음글 -->
      <prev-next :btnObj="btnObj"></prev-next>
      <!-- // 이전글, 다음글 -->
    </template>

    <!-- 스켈레톤 -->
    <template v-else>
      <skeleton-list-detail></skeleton-list-detail>
    </template>
  </b-container>
</template>

<script>
import SkeletonListDetail from "@/components/common/SkeletonListDetail";
import PrevNext from "@/components/common/PrevNext";
import { formatedDate } from "@/utils/dayjs";

export default {
  components: {
    SkeletonListDetail,
    PrevNext,
  },
  data() {
    return {
      report: null,
      btnObj: { preReport: null, nextReport: null },
      isKidNews: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    key() {
      return this.$route.params.key;
    },
    isHealthNews() {
      return this.key === "health-news";
    },
    targetKey() {
      return this.isHealthNews ? "report_key" : "news_key";
    },
    getNewsUrl() {
      if (this.isHealthNews) return null;
      if (this.$i18n.locale === "kyobokidscare") return this.report?.origin;
      const matchedArr = this.report.content.match(
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
      );
      return matchedArr[0];
    },
    regdate() {
      return this.report?.anndate || formatedDate(this.report?.regdate);
    },
  },
  methods: {
    splitedTitle(str) {
      if (!this.isHealthNews) return { title: str, column: "" };
      const re = /\[(.*?)\]/gi;
      const isMatch = str.match(re);
      if (!isMatch) return { title: str, column: "" };

      const strArr = str.split(/\[(.*?)\]/gi);
      return { title: strArr[2], column: strArr[1] };
    },
    reset() {
      this.report = null;
      this.btnObj.preReport = null;
      this.btnObj.nextReport = null;
    },
    async getDetail() {
      const { kyoboApi } = this.getApis();

      const { data } = this.isHealthNews
        ? await kyoboApi.news.news.detail(this.id)
        : await kyoboApi.news.recentNews.detail(this.id);

      if (this.$i18n.locale !== "kyobokidscare") {
        data.report.content = this.removeAttributes(data.report.content, [
          "style",
        ])?.replace(/\/(?=https)/gim, "");
      } else {
        this.isKidNews = true;
      }
      
      this.report = data.report;
      this.btnObj.preReport = data.preReport;
      this.btnObj.nextReport = data.nextReport;
    },
  },
  watch: {
    id() {
      this.reset();
      this.getDetail();
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>

<style lang="scss">
.new-img {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
