var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[_c('validation-provider',{ref:"provider",attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-input-group',[_c('label',{staticClass:"flex-grow-1 mb-0"},[_c('span',{domProps:{"textContent":_vm._s(_vm.label)}}),_c('b-form-group',{staticClass:"m-0"},[_c('b-textarea',_vm._b({staticClass:"py-2 px-3",style:({ ..._vm.styles }),on:{"input":_vm.inputListener}},'b-textarea',{
              ..._vm.$attrs,
              ..._vm.$props,
              class: _vm.inputClass,
            },false)),_vm._l((_vm.$slots),function(_,slot){return [_vm._t(slot)]}),_vm._l((_vm.$scopedSlots),function(_,slot){return [_vm._t(slot,null,null,{
                state: _vm.rules && _vm.getValidationState(validationContext),
              })]})],2)],1)])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }