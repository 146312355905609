<template>
  <div>
    <b-row class="py-4">
      <b-col cols="5" md="3">
        <b-skeleton :animation="null" width="100%" height="2.5rem"></b-skeleton>
      </b-col>
    </b-row>
    <b-row class="py-4">
      <b-col cols="12">
        <b-skeleton :animation="null" width="70%" height="3rem"></b-skeleton>
      </b-col>
      <b-col cols="12">
        <b-skeleton :animation="null" width="30%" height="1.5rem"></b-skeleton>
      </b-col>
    </b-row>

    <!-- 카드박스 -->
    <b-row v-for="i in 2" :key="i" class="py-3">
      <b-col>
        <b-skeleton-img
          :animation="null"
          height="12rem"
          no-aspect
        ></b-skeleton-img>
      </b-col>
    </b-row>
    <!-- // 카드박스 -->

    <!-- 버튼 -->
    <b-row align-h="center" class="py-5">
      <b-col cols="5" md="3">
        <b-skeleton :animation="null" width="100%" height="3rem"></b-skeleton>
      </b-col>
    </b-row>
    <!-- //버튼 -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
