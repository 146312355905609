<template>
  <div>
    <swiper
      ref="mungin-swiper"
      :options="{
        allowTouchMove: false,
        spaceBetween: 100,
      }"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, i) in slideItems"
        :key="`${item.number}_${slideItems.length}`"
        :allow-touch-move="false"
      >
        <w-form v-slot="{ invalid }">
          <!-- 질문 -->
          <div>
            <h4
              v-if="item.questiontitle"
              class="fw-7 mb-2 mb-md-3 fs-24 flex-start align-items-start"
            >
              <span
                v-if="item.titleColumn"
                class="theme-btn fs-18 mr-2 py-2 px-3"
                >{{ item.titleColumn }}</span
              >
              <span v-else class="mr-1">Q.</span>
              {{
                !appendTtile
                  ? item.questiontitle
                  : appendTtile + item.questiontitle
              }}
            </h4>
          </div>
          <!-- // 질문 -->
          <!-- 복수문항 문구 -->
          <p v-if="item.answersType === 'checkbox'" class="red fs-18 mb-3">
            복수선택형 문항입니다.
          </p>
          <!-- //복수문항 문구 -->
          <!-- 설명란 -->
          <div
            v-if="item.examples && item.examples.length"
            class="mb-3 fs-20 fs-7 gray pl-3"
          >
            <p v-for="example in item.examples" :key="example.content">
              - {{ example.content }}
            </p>
          </div>
          <!-- // 설명란 -->
          <!-- 이전 문항 버튼 -->
          <div class="flex-start mb-3">
            <b-btn
              v-show="i"
              variant="link"
              class="gray-3 fs-20 fs-md-18 fw-7 flex-center"
              @click="slidePrev"
              :style="{ marginLeft: '-10px' }"
              slot="button-prev"
              ><img
                :style="{ width: '2rem' }"
                src="~@/assets/img/common/ic-gray-arrow.svg"
                alt="이전 문항"
              />이전 문항</b-btn
            >
          </div>
          <!-- //  이전 문항 버튼 -->
          <!-- 질문 그림 -->
          <b-row
            v-if="item.questionImgs"
            class="mx-n4 mx-sm-n5 mx-lg-n2 mb-4"
            align-h="center"
            :style="{
              minHeight: '14.5rem',
            }"
          >
            <b-col
              v-for="(img, i) in item.questionImgs"
              :key="`${img}-${i}`"
              :cols="item.questionImgs.length > 1 ? 6 : 12"
              :md="item.questionImgs.length > 1 ? 6 : 10"
              :lg="item.questionImgs.length > 1 ? 3 : 8"
              align-self="center"
              class="px-4 px-md-5 px-lg-2 py-2"
            >
              <div class="d-flex justify-content-center">
                <span
                  v-if="item.questionImgs.length > 1"
                  class="flex-center border border-black rounded-circle mr-2"
                  :style="{ width: '1.4rem', height: '1.4rem' }"
                >
                  {{ i + 1 }}
                </span>
                <img
                  :src="img"
                  :alt="`그림 ${i + 1}`"
                  :style="{
                    width: item.questionImgs.length > 1 ? '85%' : '100%',
                  }"
                />
              </div>
            </b-col>
          </b-row>
          <!-- 질문 -->
          <question-box
            v-if="item.questiontitle !== '고객님의 정보를 입력해 주세요.'"
            v-bind="{
              ...item,
              answersType: item.answersType || 'radio',
            }"
            @changeSlide="changeSlide"
            v-model="item.selectedAnswers"
          ></question-box>
          <!-- // 질문 -->

          <!-- 고객 정보 입력 -->
          <question-box-user-info
            v-else
            v-model="useInfo"
          ></question-box-user-info>
          <!-- 결과보기 -->
          <div class="flex-center">
            <b-btn
              v-if="i === slideItems.length - 1"
              variant="link"
              class="blue-submit-btn mr-2 px-5 fs-20 mt-5"
              @click="$emit('handleSubmit', useInfo)"
              :disabled="invalid || isEmpty"
              >결과보기</b-btn
            >
            <b-btn
              v-show="
                i !== slideItems.length - 1 &&
                  ['checkbox', 'input'].includes(slideItems[i].answersType)
              "
              variant="link"
              class="blue-submit-btn mr-2 px-5 fs-20 mt-5"
              @click="slideNext"
              :disabled="invalid || isEmpty"
              >다음 문항 보기</b-btn
            >
          </div>
          <!-- // 결과보기 -->
        </w-form>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { debounce } from "lodash";
import QuestionBox from "@/components/common/QuestionBox";
import QuestionBoxUserInfo from "@/components/kyobohealthcare/QuestionBoxUserInfo";

export default {
  components: {
    QuestionBox,
    QuestionBoxUserInfo,
    Swiper,
    SwiperSlide,
  },
  props: {
    titleColumn: {
      type: String,
      default: "",
    },
    appendTtile: {
      type: String,
      default: "",
    },
    slideItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
      useInfo: null,
    };
  },
  computed: {
    munJinSwiper() {
      return this.$refs["mungin-swiper"]?.$swiper;
    },
    isEmpty() {
      const items = this.slideItems[this.activeIndex];
      return (
        items.questiontitle !== "고객님의 정보를 입력해 주세요." &&
        !items.selectedAnswers?.filter((item) => item).length
      );
    },
  },

  methods: {
    async slidePrev() {
      await this.$nextTick();
      this.munJinSwiper.slidePrev(500);
    },
    async slideNext() {
      await this.$nextTick();
      this.munJinSwiper.slideNext(500);
    },
    // 질문 선택시 다음 슬라이드로 이동
    changeSlide: debounce(function() {
      this.munJinSwiper.slideNext(500);
    }, 650),

    // 슬라이드 변경시 active
    onSlideChange() {
      this.activeIndex = this.getActiveIndex();
      this.slideItems.forEach((val, i) => {
        if (i <= this.activeIndex) return;
        delete val.selectedAnswers;
      });
      this.$emit("onSlideChange", this.activeIndex);
    },
    // swiper active index 가져오기
    getActiveIndex() {
      return this.munJinSwiper?.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped></style>
