import Vue from "vue";
import WForm from "@/components/common/WForm.vue";
import WInput from "@/components/common/WInput.vue";
import WTextarea from "@/components/common/WTextarea.vue";
import WRadio from "@/components/common/WRadio.vue";
import WCheckbox from "@/components/common/WCheckbox.vue";
import TabSwiper from "@/components/common/TabSwiper.vue";
import TableSwiper from "@/components/common/TableSwiper.vue";
import HashTitle from "@/components/common/HashTitle.vue";
import HcSelectDefault from "@/components/common/HcSelectDefault";
import HcInputDefault from "@/components/common/HcInputDefault";
import ModalAlert from "@/components/common/modal/ModalAlert";
import ModalDatePicker from "@/components/common/modal/ModalDatePicker";
import store from "@/store/index";
import apis from "@/apis";
import { getCookie } from "@/utils/cookies";

Vue.component("hc-select-default", HcSelectDefault);
Vue.component("hc-input-default", HcInputDefault);
Vue.component("modal-alert", ModalAlert);
Vue.component("modal-date-picker", ModalDatePicker);
Vue.component("hash-title", HashTitle);
Vue.component("tab-swiper", TabSwiper);
Vue.component("table-swiper", TableSwiper);
Vue.component("w-form", WForm);
Vue.component("w-input", WInput);
Vue.component("w-textarea", WTextarea);
Vue.component("w-checkbox", WCheckbox);
Vue.component("w-radio", WRadio);

Vue.prototype.removeAttributes = (
  string,
  attributes = ["style", "class", "color"]
) => {
  return string.replace(
    new RegExp(`(${attributes.join("|")})=[\'||\"].*?[\'||\"]`, "gmi"),
    ""
  );
};
Vue.prototype.isArray = (arr) => (Array.isArray(arr) ? arr : []);

Vue.prototype.getApis = () => {
  const code = getCookie({ key: "code" });
  const types = store.getters["types/getTypes"];
  const kyoboApi = apis[types[code]];
  const { auth: authApi } = apis;

  return { kyoboApi, authApi };
};
Vue.prototype.compose = (fns = [], val) => fns.reduce((c, fn) => fn(c), val);

Vue.prototype.calcHeight = (val, vh = "0.5vh", vw = "0.5vw") =>
  `calc(${val} - ${vh} + ${vw})`;

Vue.prototype.calcLength = (str, max = 20) => {
  const strLength = str.length;
  // max 보다 스트링 길이가 짧으면 str 리턴
  if (strLength < max) return str;
  const newStr = `${str.slice(0, max)}...`;
  return newStr;
};
Vue.prototype.replaceBr = (str) => {
  return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
};
