export const state = {
  types: {
    // KA: 홈페이지가 존재하지 않기 때문에 로그인 없음
    //     알림톡으로만 진입하기때문에 header, footer display:none 조건처리 된 newkyobohealthcare 으로 설정
    //     앞으로 홈페이지는 없지만 알림톡 진입이 필요할 경우 TYPE = newkyobohealthcare 으로 설정
    KA: "newkyobohealthcare", //교보베이직
    KB: "kyobohealthcare", //교보헬스케어
    NK: "newkyobohealthcare", //교보new헬스케어
    KW: "kyobowomancare", //교보우먼케어
    KM: "kyobokidscare", //교보어린이헬스케어
    KR: "kyobojuniorcare", //교보주니어헬스케어
    NI: "kyoboseniorcare" //교보치매케어
  },
};
export const getters = {
  getTypes: (s) => s.types,
};
export const mutations = {};
export const actions = {};
