import { getCookie, setCookie, removeCookie } from "@/utils/cookies";

export const state = {
  code: null,
  loggedIn: false,
  loading: false,
};
export const mutations = {
  set(s, [k, v]) {
    s[k] = v;
  },
};
export const getters = {
  loggedIn(s) {
    return s.loggedIn;
  },
};
export const actions = {
  async login({ commit }, kyoboencryptionData) {
    // 토근 생성
    const { authApi } = this._vm.getApis();

    const {
      data: { code, token, encryptionData },
    } = await authApi.createToken({ kyoboencryptionData });

    // token, encryptionData, code, memeberData cookie에 저장
    setCookie({ key: "token", value: token });
    setCookie({ key: "encryptionData", value: encryptionData });
    setCookie({ key: "code", value: code });
    setCookie({ key: "memeberData", value: kyoboencryptionData });

    commit("set", ["loggedIn", true]);
    return code;
  },
  logout({ commit }) {
    // token, encryptionData, code, memeberData cookie에서 삭제
    removeCookie({ key: "token" });
    removeCookie({ key: "encryptionData" });
    removeCookie({ key: "code" });
    removeCookie({ key: "memeberData" });

    commit("set", ["user", null]);
    commit("set", ["loggedIn", false]);
  },

  async refreshToken() {
    // kyoboencryptionData 쿠기에서 가져오기
    const kyoboencryptionData = getCookie({ key: "memeberData" });
    const { authApi } = this._vm.getApis();

    // 토큰 재생성
    const {
      data: { code, token, encryptionData },
    } = await authApi.createToken({
      kyoboencryptionData,
    });

    // token, encryptionData, code cookie에 저장
    setCookie({ key: "token", value: token });
    setCookie({ key: "encryptionData", value: encryptionData });
    setCookie({ key: "code", value: code });

    return token;
  },
};
