<template>
  <div class="hc-select-default">
    <validation-provider ref="provider" :name="name" :rules="rules">
      <!-- rules 적용시킬 input -->
      <input v-show="0" type="text" :value="inputValue" />
      <b-dropdown
        ref="dropdown"
        variant="link"
        no-caret
        @show="isVisible = true"
        @hide="isVisible = false"
        menu-class="rounded-10 fs-20 fs-md-18"
        toggle-class="w-100"
      >
        <template #button-content variant="link">
          <div
            :class="dropdownClass"
            :style="{ ...styles }"
            class="text-decoration-none flex-start black"
          >
            <div
              class="bg-img"
              :style="{
                minWidth: '1rem',
                width: '1rem',
                paddingBottom: '1rem',
                backgroundImage: `url(${require('@/assets/img/common/ic-header-navigation.svg')})`,
                backgroundSize: '200%',
                transform: 'rotate(270deg)',
              }"
            ></div>
            <div
              :class="[
                inputClass,
                'position-relative  pl-2 text-left text-truncate',
              ]"
            >
              <hash-title
                v-if="hashTitle"
                :title="inputText"
                :isHash="false"
                :titleClass="inputClass"
              ></hash-title>
              <span v-else>{{ inputText }}</span>
            </div>
          </div>
        </template>
        <!-- 그룹 있을 때 -->
        <template v-if="isGroup">
          <b-dropdown-group
            v-for="row in items"
            :key="row.value"
            :id="row.type"
            :header="row.type"
            header-classes="black fs-20"
          >
            <b-dropdown-item-button
              v-for="(_row, i) in row.items"
              :key="`${_row.value}-${i}`"
              :class="{ 'pb-4': row.items.length === i + 1 }"
              @click="handleChange(_row)"
            >
              {{ _row.text }}
            </b-dropdown-item-button>
          </b-dropdown-group>
        </template>
        <!-- 그룹 없을 때 -->
        <template v-else>
          <b-dropdown-item-button
            v-for="(row, idx) in items"
            variant="link"
            :value="row.value"
            :key="idx"
            @click="handleChange(row)"
            >{{ row.text }}</b-dropdown-item-button
          >
        </template>
      </b-dropdown>
      <slot />
    </validation-provider>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "" },
    rules: { type: [String, Object] },
    isGroup: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [{ value: "", text: "" }],
    },
    styles: {
      type: Object,
      default: null,
    },
    dropdownClass: {
      type: String,
      defulat: "",
    },
    value: {
      default: null,
    },
    name: {
      tpye: String,
      default: "",
    },
    inputClass: { default: () => "", type: String },
    selected: {
      type: String,
      default: "",
    },
    hashTitle: {
      type: Boolean,
      defulat: false,
    },
  },
  computed: {
    inputText() {
      return !this.value ? this.label || "선택" : this.value?.text;
    },
    inputValue() {
      return this.value?.text;
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    handleChange(row) {
      this.$emit("input", row);
      this.$emit("change", row);
    },
    setSelected() {
      if (!this.selected) return;
      const item = this.items.find((item) => item.value === this.selected);
      this.$emit("input", item);
      this.$emit("change", item);
    },
    hide() {
      if (!this.isVisible) return;
      this.$refs.dropdown.hide(true);
    },
  },
  watch: {
    items() {
      this.$emit("input", "");
      this.$emit("change", "");
    },
  },
  mounted() {
    this.setSelected();
  },
  beforeDestroy() {},
};
</script>

<style lang="scss">
.hc-select-default {
  .dropdown-menu {
    left: 0 !important;
    min-width: auto !important;
    max-height: 200px !important;
    z-index: 30;
    overflow-y: scroll;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: inherit !important;
    text-decoration: none;
    background-color: inherit !important;
  }
}
</style>
