<template>
  <b-btn
    variant="link"
    class="border-bottom border-gray-5 w-100 list text-left"
    @click="$emit('handleClick', $event)"
  >
    <b-row class="mx-0 py-3" align-v="center">
      <b-col cols="12" class="flex-start">
        <span
          class="d-inline-block blue-2 border border-blue-2 border-2 rounded-pill py-2 px-2 px-md-3 fs-16 text-center mr-2"
          :style="{
            minWidth: calcHeight('6.6rem'),
          }"
        >
          {{ column }}
        </span>
        <span class="gray text-truncate flex-1-1-0 fs-20 fs-md-18">{{
          calcLength(title, 37)
        }}</span>
      </b-col>
    </b-row>
  </b-btn>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    column: {
      type: String,
      default: "이비인후과",
    },
    title: {
      type: String,
      default:
        "아랫배가 전체적으로 아플 때아랫배가 전체적으로 아플 때아랫배가 전체적으로 아플 때아랫배가 전체적으로 아플 때아랫배가 전체적으로 아플 때아랫배가 전체적으로 아플 때아랫배가 전체적으로 아플 때",
    },
  },
};
</script>
