<template>
  <div>
    <table-swiper
      ref="table-swiper"
      :options="{
        slidesPerView: 1,
        allowTouchMove: true,
        slidesPerView: 0.4,
        breakpoints: {
          400: {
            slidesPerView: 0.15,
          },
          720: {
            slidesPerView: 0.2,
          },
          992: {
            slidesPerView: 0.3,
          },
        },
        navigation: {
          nextEl: '.table-next',
          prevEl: '.table-prev',
        },
      }"
      isPopover
    >
      <template v-slot:content>
        <b-table-simple
          bordered
          class="bg-white"
          :style="{ whiteSpace: 'pre-wrap' }"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="head in fields"
                :key="head.key"
                class="bg-gray-4 white fs-20 fs-md-18"
                :style="{ ...head.thStyle }"
                :colspan="head.colspan"
                >{{ head.label }}</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(row, i) in mappedTable" :key="i">
              <b-td
                v-for="(td, key) in row"
                :key="`b-td-${key}`"
                :rowspan="td && td.rowspan"
                :colspan="td && td.colspan"
                :class="[
                  {
                    'bg-gray-10': key === 'target',
                  },
                  key === 'division' ? 'bg-gray-4 white text-center' : '',
                  'align-middle fs-20 fs-md-18 p-2',
                ]"
                :style="{
                  ...(td &&
                    td.degree && {
                      backgroundColor: 'var(--green-4)',
                    }),
                  ...(td &&
                    td.bgColor === '#FFAFD5' && { backgroundColor: 'pink' }),
                  ...(td && td.tdStyle),
                  ...(!isExplanation && { cursor: 'pointer' }),
                }"
                @click="handleClick(td && td.colName)"
              >
                <span v-if="td && td.label">{{ td.label }}</span>
                <span v-else-if="td && td.degree"
                  >{{ td.degree }}<br />{{ td.dayText }}</span
                >
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <!-- 설명 -->
        <div v-if="isExplanation">
          <ul class="ml-4">
            <li>읽어보세요</li>
            <li>
              <span class="green">국가필수예방접종</span> 국가가 권장하는
              예방접종(국가는 감염병의 예방 및 관리에 관한 법률을 통해 예방접종
              대상 감염병과 예방접종의 실시기준 및 방법을 정하고, 국민과
              의료제공자에게 이를 준수토록 하고 있음)
            </li>
            <li>
              <span class="green">기타예방접종</span> 국가필수예방접종 이외 민간
              의료기관에서 접종 가능한 예방접종
            </li>
            <li>기초접종 단기간 내에 방어면역 형성을 위해 시행하는 접종</li>
            <li>
              추가접종 기초접종 후 형성된 방어면역을 장기간 유지하기 위해
              기초접종 후 일정기간이 지난 다음 추가로 시행하는 접종
            </li>
          </ul>
        </div>
        <!-- // 설명 -->
      </template>
    </table-swiper>
    <modal-vaccination-registration
      :idx="selectedIdx"
      :colName="selectedColName"
      @refresh="refresh"
    ></modal-vaccination-registration>
  </div>
</template>

<script>
import ModalVaccinationRegistration from "@/components/kyobokidscare/modal/ModalVaccinationRegistration";

export default {
  components: {
    ModalVaccinationRegistration,
  },
  props: {
    isExplanation: {
      type: Boolean,
      default: false,
    },
    selectedIdx: {
      type: Number,
      default: 0,
    },
    schedule: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedColName: null,
      table: [
        {
          division: {
            rowspan: 13,
            label: "필수예방접종",
            tdStyle: { width: "50px" },
          },
          target: { label: "결핵" },
          "0개월": { degree: "1차", key: "A00" },
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "B형간염" },
          "0개월": { degree: "1차", key: "A10" },
          "1개월": { degree: "2차", key: "A11" },
          "2개월": null,
          "4개월": null,
          "6개월": { degree: "3차", key: "A12" },
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: null,
          만12세: null,
        },
        {
          target: {
            label: "디프테리아\n파상풍 \n백일해",
            rowspan: 2,
          },
          "0개월": null,
          "1개월": null,
          "2개월": { degree: "1차", key: "A21" },
          "4개월": { degree: "2차", key: "A22" },
          "6개월": { degree: "3차", key: "A23" },
          "12개월": null,
          "15개월": { degree: "추4차", key: "A24", colspan: 2 },
          "24개월": null,
          "36개월": null,
          만4세: { degree: "추5차", key: "A25", colspan: 2 },
          만11세: null,
          만12세: null,
        },
        {
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: { degree: "추6차", key: "A26", colspan: 2 },
        },
        {
          target: { label: "폴리오" },
          "0개월": null,
          "1개월": null,
          "2개월": { degree: "1차", key: "A31" },
          "4개월": { degree: "2차", key: "A32" },
          "6개월": { degree: "3차", key: "A33" },
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: { degree: "추4차", key: "A34", colspan: 2 },
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "디프테리아\n파상풍\n백일해\n폴리오" },
          "0개월": null,
          "1개월": null,
          "2개월": { degree: "1차", key: "A41" },
          "4개월": { degree: "2차", key: "A42" },
          "6개월": { degree: "3차", key: "A43" },
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: { degree: "추4차", key: "A44", colspan: 2 },
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "홍역\n유행성\n이하선염" },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": { degree: "1차", key: "A51", colspan: 2 },
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: { degree: "2차", key: "A52", colspan: 2 },
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "수두" },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": { degree: "1회", key: "A61", colspan: 2 },
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "일본뇌염" },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": { degree: "1 ~ 2차", key: "A71", colspan: 4 },
          "36개월": { degree: "3차", key: "A72" },
          만4세: null,
          만6세: { degree: "추4차", key: "A73" },
          만11세: null,
          만12세: { degree: "추5차", key: "A74" },
        },
        {
          target: { label: "인플루엔자" },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": { degree: "우선접종권장 대상자", key: "A81", colspan: 10 },
        },
        {
          target: { label: "장티푸스", rowspan: 2 },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: { degree: "고위험군에 한하여 접종", colspan: 4 },
        },
        {
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": { degree: "고위험군에 한하여 접종", colspan: 6 },
        },
        {
          target: { label: "신증후군출혈열" },
          "0개월": { degree: "고위험군에 한하여 접종", colspan: 14 },
        },
        {
          division: {
            rowspan: 9,
            label: "기타예방접종",
            tdStyle: { width: "50px" },
          },
          target: { label: "결핵" },
          "0개월": { degree: "1회", key: "A91" },
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "일본 뇌염" },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": { degree: "1차", key: "A101", colspan: 4 },
          "36개월": { degree: "2차 1차 접종 후", key: "A102" },
          만4세: null,
          만6세: { degree: "추3차", key: "A103" },
          만11세: null,
          만12세: null,
        },

        {
          target: { label: "B형 헤모필루스 인플루엔자\n뇌 수막염" },
          "0개월": null,
          "1개월": null,
          "2개월": { degree: "1차", key: "A201" },
          "4개월": { degree: "2차", key: "A202" },
          "6개월": { degree: "3차", key: "A203" },
          "12개월": { degree: "추4차", key: "A204", colspan: 2 },
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "A형간염" },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": { degree: "1 ~ 2차", key: "A301", colspan: 5 },
          만4세: null,
          만6세: null,
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "페구균", rowspan: 2 },
          "0개월": null,
          "1개월": null,
          "2개월": { degree: "1차", key: "A401" },
          "4개월": { degree: "2차", key: "A402" },
          "6개월": { degree: "3차", key: "A403" },
          "12개월": { degree: "추4차", key: "A404", colspan: 2 },
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: null,
          만12세: null,
        },
        {
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": { degree: "고위험군에 한하여 접종", colspan: 6 },
        },
        {
          target: { label: "디프테리아\n파상풍\n백일해" },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: { degree: "추6차", key: "A501", colspan: 2 },
        },
        {
          target: { label: "로타바이러스(로타텍)" },
          "0개월": null,
          "1개월": { degree: "1차", key: "A601" },
          "2개월": { degree: "2차", key: "A602" },
          "4개월": { degree: "3차", key: "A603" },
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: null,
          만12세: null,
        },
        {
          target: { label: "인유두종바이러스" },
          "0개월": null,
          "1개월": null,
          "2개월": null,
          "4개월": null,
          "6개월": null,
          "12개월": null,
          "15개월": null,
          "18개월": null,
          "24개월": null,
          "36개월": null,
          만4세: null,
          만6세: null,
          만11세: { degree: "1 ~ 3차", key: "A701", colspan: 2 },
        },
      ],
      fields: [
        {
          key: "대상 전염병",
          label: "대상전염병",
          thStyle: { minWidth: "220px" },
          colspan: 2,
        },
        { key: "0개월", label: "0개월", thStyle: { minWidth: "80px" } },
        { key: "1개월", label: "1개월", thStyle: { minWidth: "80px" } },
        { key: "2개월", label: "2개월", thStyle: { minWidth: "80px" } },
        { key: "4개월", label: "4개월", thStyle: { minWidth: "80px" } },
        {
          key: "6개월",
          label: "6개월",
          thStyle: { minWidth: "80px" },
        },
        { key: "12개월", label: "12개월", thStyle: { minWidth: "80px" } },
        { key: "15개월", label: "15개월", thStyle: { minWidth: "80px" } },
        { key: "18개월", label: "18개월", thStyle: { minWidth: "80px" } },
        { key: "24개월", label: "24개월", thStyle: { minWidth: "80px" } },
        { key: "36개월", label: "36개월", thStyle: { minWidth: "80px" } },
        { key: "만4세", label: "만4세", thStyle: { minWidth: "80px" } },
        { key: "만6세", label: "만6세", thStyle: { minWidth: "80px" } },
        { key: "만11세", label: "만11세", thStyle: { minWidth: "80px" } },
        { key: "만12세", label: "만12세", thStyle: { minWidth: "80px" } },
      ],
    };
  },
  methods: {
    handleClick(colName) {
      if (!colName) return;
      this.selectedColName = colName;
      this.$bvModal.show("modal-vaccination-registration");
    },
    refresh() {
      this.$emit("refresh");
    },
  },
  computed: {
    mappedTable() {
      if (!this.schedule) return this.table;
      return this.table.map((row) => {
        const objKeys = Object.keys(row);
        return objKeys.reduce((obj, objKey) => {
          const col = row[objKey];
          if (!col) return { ...obj, [objKey]: null };
          if (!col.key) return { ...obj, [objKey]: row[objKey] };
          const findedSchedule = this.schedule.find(
            (val) => val.colName === col.key
          );
          return { ...obj, [objKey]: { ...row[objKey], ...findedSchedule } };
        }, {});
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
