<template>
  <div class="d-flex flex-column min-vh-100">
    <global-header @setOffset="setOffset" v-if="!carecall"></global-header>
    <router-view class="content" :style="{ paddingTop: `${offset}px`, minHeight: '70vh' }"></router-view>
    <global-footer class="flex-grow-1" v-if="!['kyoboservices'].includes($i18n.locale)"></global-footer>
  </div>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader";
import GlobalFooter from "@/components/GlobalFooter";

export default {
  metaInfo() {
    return {
      title: this.targetMetaInfo.title,
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "theme-color", content: "#ffffff" },
        { property: "og:type", content: "website" , hid: 'og:type'},
        { property: "og:image", content: `${window.location.origin}/${this.targetMetaInfo.url}`, hid: 'og:image' },
        { property: "og:title", content: this.targetMetaInfo.title, hid: 'og:title' },
        { property: "og:description", content: this.targetMetaInfo.Description, hid: 'og:description' },
        { property: "og:url", content: this.targetMetaInfo.base_url, hid: 'og:url' },
      ],
    };
  },
  components: {
    GlobalHeader,
    GlobalFooter,
  },
  data() {
    return {
      offset: 0,
      metaInfoObj: {
        kyoboservices: {
          title: "상품부가서비스",
          type: "상품부가서비스 소개",
          Description: "교보생명의 보험상품에 가입하신 분들에게 제공해드리는 서비스입니다.",
          url: "ki_img.png",
          base_url: "https://kyoboadditional.com/",
        },
        kyobohealthcare: {
          title: "교보헬스케어서비스",
          type: "교보헬스케어서비스",
          Description: "통합 건강관리서비스입니다.",
          url: "kb_img.png",
          base_url: "https://kyoboadditional.com/",
        },
        newkyobohealthcare: {
          title: "교보new헬스케어서비스",
          type: "교보new헬스케어서비스",
          Description: "통합 건강관리서비스입니다.",
          url: "kb_img.png",
          base_url: "https://kyoboadditional.com/",
        },
        kyobowomancare: {
          title: "교보우먼헬스케어서비스",
          type: "교보우먼헬스케어서비스",
          Description: "여성 건강관리서비스입니다.",
          url: "kw_img.png",
          base_url: "https://kyoboadditional.com/",
        },
        kyobokidscare: {
          title: "교보어린이헬스케어서비스",
          type: "교보어린이헬스케어서비스",
          Description: "영유아 건강관리서비스입니다.",
          url: "km_img.png",
          base_url: "https://kyoboadditional.com/",
        },
        kyobojuniorcare: {
          title: "교보주니어헬스케어서비스",
          type: "교보주니어헬스케어서비스",
          Description: "영유아 ∙ 청소년 건강관리서비스입니다.",
          url: "kr_img.png",
          base_url: "https://kyoboadditional.com/",
        },
        kyoboseniorcare: { title: "교보치매케어서비스", type: "교보치매케어서비스", Description: "치매관리 서비스입니다.", url: "ni_img.png", base_url: "https://kyoboadditional.com/", },
      },
      carecall: false,
    };
  },
  computed: {
    targetMetaInfo() {
      return this.metaInfoObj?.[this.$route.params.type] ?? "상품부가서비스 소개";
    },
  },
  created() {
    if(this.$route.meta?.carecall) {
      this.carecall = true;
    }

    this.$gtag.set({
      "send_page_view": false,
    })
  },
  methods: {
    setOffset(offset) {
      this.offset = offset;
    },
  },
};
</script>
