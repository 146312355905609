<template>
  <b-modal
    id="modal-pet-care"
    size="md"
    :hide-footer="true"
    body-class="p-0"
    header-class="p-0 d-block border-bottom-0"
    modal-class="modal-pet-care"
    centered
    scrollable
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-4 px-md-5 py-4 flex-between">
        <h3 class="fs-24 fw-7 mt-1">
          펫케어제휴서비스
        </h3>
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="px-4 px-md-5 pb-5">
      <p
        class="p-3 mb-4 rounded-10 border fs-18 text-center"
        :style="{
          color: '#323232',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        }"
      >
        우리의 소중한 가족 반려동물을 위한 모든 혜택을 펫케어카드를 통해
        받아보세요!!
      </p>
      <b-row class="mx-n2">
        <b-col cols="6" class="text-center fs-20 py-1 px-2"
          ><span>펫케어 제휴번호</span></b-col
        >
      </b-row>
      <b-row class="mx-n2 mb-4">
        <b-col class="px-2">
          <input
            ref="coupon"
            class="d-none"
            readonly
            v-model="coupon"
            type="text"
          />
          <p
            class="theme-color-1 fs-24 rounded-10 border fw-7 text-center py-3"
          >
            {{ coupon }}
          </p>
        </b-col>
        <b-col class="px-2">
          <b-btn
            class="d-block rounded-10 bg-theme-color-1 white fs-20 w-100 h-100 py-3"
            @click="copyAddr"
            >제휴번호 복사하기</b-btn
          >
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <b-col cols="12" class="px-0">
          <h4 class="fs-20">사용방법</h4>
        </b-col>
        <b-col class="fs-20 fw-3 gray px-0">
          <hash-title
            :isHash="false"
            :title="
              `<a href='https://www.petcarecard.co.kr/' class='fs-20 fw-3 gray' target='_blank' >https://www.petcarecard.co.kr/</a>`
            "
          >
          </hash-title>
          <span>
            <i class="icon-right-open-big gray-3"></i>제휴사할인
            <i class="icon-right-open-big gray-3"></i>멤버쉽 회원 가입
            <i class="icon-right-open-big gray-3"></i>마이페이지
            <i class="icon-right-open-big gray-3"></i>반려동물 정보 입력
            <i class="icon-right-open-big gray-3"></i>쿠폰 사용
            <i class="icon-right-open-big gray-3"></i>할인 정보 확인
          </span>
        </b-col>
      </b-row>
    </div>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
import { showToast } from "@/plugins/custom-toast";

export default {
  data() {
    return {
      coupon: 18381502,
    };
  },
  methods: {
    async copyAddr() {
      try {
        // ie or firefox
        if (!navigator.clipboard?.readText) {
          this.$refs.coupon?.select();
          document.execCommand("copy");
          showToast({ title: "쿠폰번호가 복사되었습니다." });
          return;
        }

        // 그외 브라우저
        await navigator.clipboard.writeText(this.coupon);
        showToast({ title: "쿠폰번호가 복사되었습니다." });
      } catch (e) {
        showToast({
          title: "클립보드 복사 실패",
          variant: "warning",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.modal-pet-care {
  .modal-dialog {
    max-width: 500px;
  }
}
</style>
