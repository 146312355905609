<template>
  <b-container :style="{ marginBottom: '10rem', marginTop: '5rem' }">
    <template v-if="items">
      <!-- 칼럼 -->
      <div class="mb-4">
        <span class="fs-18 px-3 py-1 theme-btn bg-theme-color-1"
          >아이 발달 체크</span
        >
      </div>
      <!-- // 칼럼 -->
      <!-- 타이틀 -->
      <h4 class="mb-5">
        <hash-title
          :title="`우리아이 개월 수 : ${month}개월`"
          :titleClass="'fs-30 fw-7'"
          :isHash="false"
        ></hash-title>
      </h4>
      <!-- // 타이틀 -->
      <div>
        <survey-swiper
          :slideItems="items"
          @handleSubmit="handleSubmit"
        ></survey-swiper>
      </div>
    </template>
    <skeleton-check-survey v-else></skeleton-check-survey>
  </b-container>
</template>

<script>
import SurveySwiper from "@/components/common/SurveySwiper";
import HcDateSelectBox from "@/components/common/HcDateSelectBox";
import QuestionBox from "@/components/common/QuestionBox";
import SkeletonCheckSurvey from "@/components/common/SkeletonCheckSurvey";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    HcDateSelectBox,
    QuestionBox,
    SurveySwiper,
    SkeletonCheckSurvey,
  },
  data() {
    return {
      items: null,
      answersKeys: {
        전체운동: "gubunAL",
        미세운동: "gubunMT",
        감각기능: "gubunSS",
        언어기능: "gubunLG",
        "사회/인지기능": "gubunSC",
      },
    };
  },
  computed: {
    surveyObj() {
      return this.$t(this.cameledKey).find((val) => val.id === "growth");
    },
    month() {
      return this.$route.query.month;
    },
    getBody() {
      return this.items.reduce((obj, item) => {
        const { selectedAnswers = [] } = item;
        const remvoeNullValue = selectedAnswers.filter((val) => val).join("|");
        const value = { [item.target]: remvoeNullValue };
        return { ...obj, ...value };
      }, {});
    },
  },
  methods: {
    mapItems(items) {
      const listArr = Object.keys(items);

      return listArr.reduce((arr, item, i) => {
        const targetItems = items[item];
        const answers = targetItems.map((targetItem, i) => {
          return {
            answertitle: targetItem.con,
            value: targetItem.val,
          };
        });
        const target = this.answersKeys[item];
        const question = {
          number: `${i + 1}`,
          questiontitle: "지금 우리 아이는?",
          target,
          titleColumn: item,
          answersType: "checkbox",
          answers,
        };
        return [...arr, question];
      }, []);
    },
    async getList() {
      const { kyoboApi } = this.getApis();

      const {
        data: { list },
      } = await kyoboApi.check.growth.list(this.month);
      const mappedList = this.mapItems(list);
      this.items = mappedList;
    },
    async handleSubmit() {
      const body = {
        month: this.month,
        ...this.getBody,
      };
      this.$router.push({
        name: "check-growth-result",
        query: { ...body },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.answer-list {
  .active {
    border-color: #000 !important;
    color: #000 !important;
    i {
      border-color: #000 !important;
      color: #000 !important;
    }
  }
}
</style>
