import request from "@/utils/request";

export default {
  hospital: {
    getLists: () =>
    // 전국 협력병원 리스트
    request({
      method: "get",
      url: "/kyobohealthcare/services/hospital/list",
    }),
    // 헬스케어 - 전국 협력 병원(검진 우대 병원) 리스트
    getList: (sido_num) =>
      request({
        method: "get",
        url: `/kyoboseniorcare/services/hospital/list/${sido_num}`,
      }),
    // 헬스케어 - 병원(검진 우대 병원)정보 보기
    getInfo: (hsptl_sn) =>
      request({
        method: "get",
        url: `/kyoboseniorcare/services/hospital/info/${hsptl_sn}`,
      }),
    // 헬스케어 - 검진항목보기(검진 우대 병원
    getItem: (hpt_code) =>
      request({
        method: "get",
        url: `/kyoboseniorcare/services/hospital/info/item/${hpt_code}`,
      }),
    // 헬스케어 - 검진항목 비교(검진 우대 병원
    compare: (data) =>
      request({
        method: "post",
        url: "/kyoboseniorcare/services/hospital/info/compare",
        data,
      }),
  },
  news: {
    // 건강정보 리스트 불러오기
    news: {
      list: (data) =>
        request({
          method: "post",
          url: `/kyoboseniorcare/services/healthinfo/list`,
          data,
        }),
      // 건강 칼럼 상세 불러오기
      detail: (report_key) =>
        request({
          method: "get",
          url: `/kyoboseniorcare/services/healthinfo/view/${report_key}
          `,
        }),
    },
  },
};
