<template>
  <!--  푸터 바텀 -->
  <div :class="isTalk? 'display-none' : ''" :style="{ backgroundColor: '#f2f2f2' }">
    <b-container
      class="pb-4"
      :style="{
        transition: '0.8s',
      }"
    >
      <div class="row">
        <div class="col py-5">
          <div class="flex-left flex-md-column align-items-md-start fs-20 fs-md-16">
            <b-btn
              v-if="type !== 'kyoboservices'"
              class="fw-7 mb-3 mr-4 mr-md-0 black"
              :style="{ backgroundColor: '#f2f2f2' }"
              @click="$bvModal.show('modal-policy')"
            >
              개인정보처리방침
            </b-btn>
            <p class="mb-3">
              콜센터
              <a class="d-md-none" :href="`tel:${$t('call')}`">{{ $t("call") }}</a>
              <span class="d-none d-md-inline-block">
                {{ $t("call") }}
              </span>
            </p>
          </div>

          <ul>
            <li v-for="(f, idx) in footerBottomTexts" :key="idx" class="gray-2 text-lfet text-md-left list-unstyled">
              {{ f }}
            </li>
          </ul>
        </div>
      </div>
    </b-container>
    <modal-policy></modal-policy>
  </div>
  <!-- // 푸터 바텀  -->
</template>

<script>
import ModalPolicy from "@/components/common/modal/ModalPolicy";
import { getCookie } from "@/utils/cookies";

export default {
  components: {
    ModalPolicy,
  },
  data() {
    return {
      footerBottomTexts: [
        "주소: (03154) 서울시 종로구 종로 1 (종로1가)",
        "교보생명보험주식회사 대표이사 : 신창재, 사업자등록번호 :102-81-11097",
        "Copyright ⓒ GC Care. All Rights Reserved.",
      ],
      isTalk: getCookie({ key: "talk" })
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
};
</script>
<style lang="scss" scoped>
.display-none {
  display: none !important;
}
</style>
