import request from "@/utils/request";

export default {
  memberShip: {
    get: () =>
      request({
        method: "post",
        url: "/kyobohealthcare/services/member/grade",
        encryptionData: true,
      }),
  },
  check: {
    healthcheck: {
      // 건강위험도체크 문진 목록
      list: () =>
        request({
          method: "get",
          url: "/kyobohealthcare/services/healthcheck/question",
        }),
      // 건강위험도체크 내역 불러오기
      result: (data) =>
        request({
          method: "post",
          url: `/kyobohealthcare/services/healthcheck/question/input`,
          encryptionData: true,
          data,
        }),
    },
    healthStress: {
      // 스트레스지수 자가체크 문진 목록
      list: () =>
        request({
          method: "get",
          url: "/kyobohealthcare/services/healthcheck/stress/question",
        }),
      // 스트레스지수 자가체크 내역 불러오기
      result: (point) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/healthcheck/stress/${point}`,
        }),
    },
    personaltype: {
      // 심리 자가체크 : 성격유형
      list: () =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/personaltype`,
        }),
      // 심리 자가체크 : 성격유형 결괏값
      result: (point) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/personaltype/${point}`,
        }),
    },
    skin: {
      // 피부 프로그램 - 피부 노화도 자가 테스트
      list: () =>
        request({
          method: "get",
          url: "/kyobohealthcare/services/healthcheck/skin/question",
        }),
      // 피부 노화도 자가 테스트 결과
      result: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/healthcheck/skin/result",
          data,
        }),
    },
    stress: {
      // 심리 자가체크 : 스트레스
      list: () =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/stress`,
        }),
      // 심리 자가체크 : 스트레스 결괏값
      result: (point) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/stress/${point}`,
        }),
    },
    depression: {
      // 심리 자가체크 : 우울증
      list: () =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/depression`,
        }),
      // 심리 자가체크 : 우울증 결괏값
      result: (point) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/depression/${point}`,
        }),
    },
    anxiety: {
      // 심리 자가체크 : 불안
      list: () =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/anxiety`,
        }),
      // 심리 자가체크 : 불안 결괏값
      result: (point) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/anxiety/${point}`,
        }),
    },
    anger: {
      // 심리 자가체크 : 분노조절장애
      list: () =>
        request({
          method: "get",
          url: "/kyobohealthcare/services/psy/anger",
        }),
      // 심리 자가체크 : 분노조절장애 결괏값
      result: (point) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/anger/${point}`,
        }),
    },
    panic: {
      list: () =>
        // 심리 자가체크 : 공황장애
        request({
          method: "get",
          url: "/kyobohealthcare/services/psy/panic",
        }),
      result: (point) =>
        // 심리 자가체크 : 공황장애 결괏값
        request({
          method: "get",
          url: `/kyobohealthcare/services/psy/panic/${point}`,
        }),
    },
  },
  hospital: {
    getLists: () =>
      // 전국 협력병원 리스트
      request({
        method: "get",
        url: "/kyobohealthcare/services/hospital/list",
      }),
    getList: (sido_num) =>
      // 건강검진 예약대행 - 전국 협력병원 리스트
      request({
        method: "get",
        url: `/kyobohealthcare/services/hospital/list/${sido_num}`,
      }),
    getInfo: (hsptl_sn) =>
      // 건강검진 예약대행 - 병원정보 보기
      request({
        method: "get",
        url: `/kyobohealthcare/services/hospital/info/${hsptl_sn}`,
      }),
    getItem: (hpt_code) =>
      // 건강검진 예약대행 - 검진항목 보기
      request({
        method: "get",
        url: `/kyobohealthcare/services/hospital/info/item/${hpt_code}`,
      }),
    compare: (data) =>
      // 건강검진 예약대행 - 검진항목 비교
      request({
        method: "post",
        url: "/kyobohealthcare/services/hospital/info/compare",
        data,
      }),
  },
  info: {
    // 생활 목록 불러오기
    lifecare: {
      list: (category_key) =>
        request({
          mehtod: "get",
          url: `/kyobohealthcare/services/health/lifecare/${category_key}`,
        }),
    },
    // 치아 목록 불러오기
    toothcare: {
      list: (category_key) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/health/toothcare/${category_key}`,
        }),
    },
    // 응급처치 목록 불러오기
    emergencycare: {
      list: (l_code, m_code = "KB0601001") =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/health/emergencycare/${l_code}/${m_code}`,
        }),
    },
  },
  myPage: {
    mycall: {
      // 이용내역 전화상담 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/mycall/list",
          encryptionData: true,
          data,
        }),
      // 이용내역 전화상담 상세 불러오기
      detail: (seq) =>
        request({
          method: "post",
          url: `/kyobohealthcare/services/mycall/view/${seq}`,
          encryptionData: true,
        }),
    },
    ngnursing: {
      // 방문간호 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/ngnursingservice/list",
          encryptionData: true,
          data,
        }),
    },
    examinSchedule: {
      // 진료예약내역 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/examinschedule/list",
          encryptionData: true,
          data,
        }),
    },
    healthcheck: {
      // 건강위험도체크 내역 불러오기 - 목록
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/healthcheck/list",
          encryptionData: true,
          data,
        }),
      // 건강위험도체크 내역 불러오기 - 상세
      detail: (hra_sn) =>
        request({
          method: "post",
          url: `/kyobohealthcare/services/healthcheck/view/${hra_sn}`,
          encryptionData: true,
        }),
    },
    healthprogram: {
      // 건강증진 프로그램 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/healthprogram/list",
          encryptionData: true,
          data,
        }),
    },
  },
  news: {
    news: {
      // 기획기사 리스트 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/healthnews/list",
          data,
        }),
      detail: (report_key) =>
        // 기획기사 상세 불러오기
        request({
          method: "get",
          url: `/kyobohealthcare/services/healthnews/view/${report_key}`,
        }),
    },
    recentNews: {
      // 최신뉴스 리스트 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/recentnews/list",
          data,
        }),
      // 최신뉴스 상세 불러오기

      detail: (news_key) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/recentnews/view/${news_key}`,
        }),
    },
  },
  onlineCounselling: {
    online: {
      // 온라인 상담실 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/onlinecounselling/list",
          data,
        }),
      // 온라인 상담실 상세 불러오기
      detail: ({ coun_key, replay_key }) =>
        request({
          method: "get",
          url: replay_key
            ? `/kyobohealthcare/services/onlinecounselling/view/${coun_key}/${replay_key}`
            : `/kyobohealthcare/services/onlinecounselling/view/${coun_key}`,
        }),
      // 온라인 상담실 상담글 작성
      write: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/onlinecounselling/create",
          encryptionData: true,
          data,
        }),
    },
    best: {
      // Best 건강상담 불러오기::::::
      list: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/bestcounselling/list",
          data,
        }),
      // Best 건강상담 상세 불러오기::::::
      detail: (cn_sn) =>
        request({
          method: "get",
          url: `/kyobohealthcare/services/bestcounselling/view/${cn_sn}`,
        }),
    },
  },
  program: {
    healthCheck: {
      apply: (data) =>
        request({
          method: "post",
          url: "/kyobohealthcare/services/healthcheck/program/apply",
          encryptionData: true,
          data,
        }),
    },
  },
  therapy: {
    // 힐링테라피 <마음건강에 도움이 되는 음악> 불러오기
    music: () =>
      request({
        method: "get",
        url: "/kyobohealthcare/services/healing/music/list",
      }),
    asmr: () =>
      // 힐링테라피 <심리적인 안정을 유도하는 ASMR> 불러오기
      request({
        method: "get",
        url: "/kyobohealthcare/services/healing/asmr/list",
      }),
  },
};
