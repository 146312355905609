<template>
  <div>
    <b-row class="mx-0 border-top border-blue border-3 bg-green-ligth py-3">
      <div class="col flex-center" v-for="_i in 5" :key="_i">
        <b-skeleton :animation="null" width="50%" height="1.5rem"></b-skeleton>
      </div>
    </b-row>
    <b-row
      v-for="i in 5"
      :key="i"
      class="mx-0 border-bottom border-gray-5 py-3"
    >
      <b-col v-for="i in 5" :key="i">
        <b-skeleton :animation="null" width="100%" height="1.5rem"></b-skeleton>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
