<template>
  <b-container :class="subTab ? 'hc-tab-container mt-4' : 'hc-container'">
    <div v-if="childItems" class="flex-center flex-wrap pb-3">
      <hc-select
        v-model="selected"
        :lists="childItems"
        inputClass="fs-24 fs-sm-29 fw-7"
      ></hc-select>
      <p class="fs-24 fs-sm-29">{{ selectTitle }}</p>
    </div>
    <template v-if="category">
      <!-- select form 있을 때 -->
      <div v-if="childItems" role="tablist">
        <div class="border-bottom border-theme-color-1 py-3 flex-between">
          <hash-title
            v-if="selected"
            :title="selected.text"
            titleClass="fs-24 fs-sm-24 fw-7"
          ></hash-title>
          <span class="mr-3 fs-18 fs-md-16">총 {{ totalCount }}건</span>
        </div>
        <hc-collapse
          v-for="row in category"
          v-bind="{
            ...row,
          }"
          :key="row.idx"
          :accordion="`accordion-${row.title}`"
          class="border-bottom border-gray-5"
        ></hc-collapse>
      </div>
      <!-- // select form 있을 때 -->

      <!-- 비디오 url 있을 때 -->
      <div v-else-if="isMovie" role="tablist">
        <div class="border-bottom border-theme-color-1 py-3 flex-between">
          <hash-title
            :title="itemObj.text"
            titleClass="fs-24 fs-sm-24 fw-7"
          ></hash-title>
          <span class="mr-3 fs-18 fs-md-16">총 {{ category.length }}건</span>
        </div>
        <div>
          <hc-collapse
            v-for="(row, i) in category"
            :key="i"
            v-bind="{
              ...row,
              idx: i,
              isVideo: true,
              accordion: `accordion-${row.C_Name}`,
            }"
            class="border-bottom border-gray-5"
          ></hc-collapse>
        </div>
      </div>
      <!-- // 비디오 url 있을 때 -->

      <!-- 기본 -->
      <div v-else role="tablist">
        <div class="border-bottom border-theme-color-1 py-3 flex-between">
          <hash-title
            :title="itemObj ? itemObj.text : title"
            titleClass="fs-24 fs-sm-24 fw-7"
          ></hash-title>
          <span class="mr-3 fs-18 fs-md-16">총 {{ totalCount }}건</span>
        </div>
        <div>
          <hc-collapse
            v-for="row in category"
            v-bind="{
              ...row,
            }"
            :key="row.idx"
            :accordion="`accordion-${row.title}`"
            class="border-bottom border-gray-5"
            :contentClass="'hc-cms-wrap'"
          ></hc-collapse>
        </div>
      </div>
      <!-- // 기본 -->
    </template>
    <skeleton-modal-list v-else></skeleton-modal-list>
  </b-container>
</template>

<script>
import SkeletonModalList from "@/components/common/SkeletonModalList";
import HcCollapse from "@/components/common/HcCollapse";
import common from "@/mixins/common";
import HcSelect from "@/components/common/HcSelect";

export default {
  mixins: [common],
  components: {
    SkeletonModalList,
    HcCollapse,
    HcSelect,
  },
  data() {
    return {
      selected: null,
      totalCount: 0,
      category: null,
      categoryKey: {
        psychology: "KO01",
        femaleCancer: "KO02",
        femaleDisease: "KO02",
        vaccination: "KO02",
        nutritionManagement: "KO03",
        teeth: "KB08",
      },
      nowTitle: "",
    };
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    tab() {
      return this.$route.query.tab;
    },
    isMovie() {
      return this.key === "movie";
    },
    itemObj() {
      return this.subTab?.items.find((item) => item.value === this.tab);
    },
    childItems() {
      if (!this.itemObj) return;
      const { childItems = null } = this.itemObj;
      return childItems;
    },
    xCode() {
      return this.categoryKey[this.key];
    },
    selectTitle() {
      if (this.$i18n.locale === "kyobohealthcare" || this.$i18n.locale === "newkyobohealthcare") return "어떻게 해야 하나요?";
      if (["kyobojuniorcare", "kyobokidscare"]) return "가(이) 궁금하세요?";
      return "";
    },
    compareTitle() {
      return this.nowTitle == this.title;
    }
  },
  methods: {
    async getList() {
      const { kyoboApi } = this.getApis();
      this.category = null;
      // 교보헬스케어
      if (["kyobohealthcare"].includes(this.$i18n.locale)) {
        const { data } = !this.childItems
          ? await kyoboApi.info[this.key].list(this.tab)
          : await kyoboApi.info[this.key].list(this.tab, this.selected.value);

        const category = this.setKeyValue(data.category[0]);
        this.category = category;
        this.totalCount = data.totalCnt;
        return;
      }
      // 교보new헬스케어
      if (["newkyobohealthcare"].includes(this.$i18n.locale)) {
        const { data } = !this.childItems
          ? await kyoboApi.info[this.key].list(this.tab)
          : await kyoboApi.info[this.key].list(this.tab, this.selected.value);

        const category = this.setKeyValue(data.category[0]);
        this.category = category;
        this.totalCount = data.totalCnt;
        return;
      }
      // 교보우먼헬스케어
      if (["kyobowomancare"].includes(this.$i18n.locale)) {
        const {
          data: { list_index },
        } = await kyoboApi.info.list(this.xCode, this.tab);
        
        this.category = this.compose(
          [
            this.setKeyValue,
            this.removeEmptyContent,
            this.removeImgWidth,
            this.dividCancerCategory,
          ],
          list_index[0]
        );

        const category = category;

        this.totalCount = this.category.length;
        return;
      }
      // 교보어린이헬스케어
      if (["kyobokidscare"]) {
        // 비디오 일때
        if (this.isMovie) {
          const page = this.itemObj.page;
          const body = {
            category: this.tab,
            pageSize: page,
          };
          const { data } = await kyoboApi.info[this.key](body);
          this.category = data.contents;
          this.totalCount = this.category.length;

          return;
        }
        // 예비맘 일때
        const targetItem = this.getCIndexs();
        const data = await Promise.all(
          targetItem?.map(async (item) => {
            if (!item.value)
              return {
                title: item.text,
                idx: item.value,
              };
            const { data } = await kyoboApi.info[this.key](item.value);
            return {
              title: item.text,
              content: data.contents,
              idx: item.value,
            };
          })
        );
        this.category = data;
        this.totalCount = this.category.length;
      }
    },
    // 각각의 데이터 키값을 정리
    setKeyValue(items) {
      return items?.map((item) => ({
        ...item,
        idx: item.C_Index ? item.C_Index : item.c_index,
        content: item.C_Contents ? item.C_Contents : item.c_contents,
        title: item.C_Name ? item.C_Name : item.c_name,
      }));
    },
    // 우먼헬스케어 -> 여성암에서 카테고리별로 구분해주는 함수
    dividCancerCategory(items) {
      if (this.key !== "femaleCancer") return items;
      return items?.filter((val) => val.l_code === this.tab);
    },
    // img tag의 width 값 제거(ex width=300 )
    removeImgWidth(items) {
      const regex = /width=[^\s+|\s+$]*/gi;
      return items?.map((item) => ({
        ...item,
        content: item.content.replace(regex, "width='100%'"),
      }));
    },
    // 여성예방접종에서 contents 없는 것들 제거
    removeEmptyContent(items) {
      return items?.filter((item) => item.content);
    },
    // 교보어린이케어 -> c_index 값 가져오기
    getCIndexs() {
      const isChild = this.childItems.find((item) => item.value);
      if (isChild) return this.childItems;
      return this.childItems?.find((item) => item.key === this.selected?.value)
        ?.items;
    },
    handleClick(e) {
      this.selected = { value: e.target.value, text: e.target.innerText };
    },
    setTitle(title) {
      this.nowTitle = title;
    },
  },
  watch: {
    selected() {
      this.getList();
    },
    tab() {
      if (this.childItems) return;
      this.getList();
    },
  },
  mounted() {
    if(!this.compareTitle) {
      this.pageViewTrack(this.title);
      this.setTitle(this.title);
    }
    this.getList();
  },
  updated() {
    if(!this.compareTitle) {
      this.pageViewTrack(this.title);
      this.setTitle(this.title);
    }
  }
};
</script>

<style lang="scss" scoped></style>
