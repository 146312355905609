<template>
  <b-container class="sub-tab-wrap bg-white">
    <!-- sub tab type = scroll -->
    <template v-if="scroll">
      <b-list-group
        class="px-0 d-block position-relative"
        v-b-scrollspy="{ offset }"
      >
        <tab-swiper
          ref="sub-tab-swiper"
          :items="items"
          :isNaviHide="true"
          :options="{
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 20,
            spaceBetween: 20,
            navigation: {
              nextEl: '.sub-tab-next',
              prevEl: '.sub-tab-prev',
            },
          }"
          class="tab"
          v-slot="{ tab, idx }"
        >
          <b-nav-item
            :class="[tagetTab === idx ? 'active' : '', 'sub-tab-item w-100 gray-4 text-center rounded-pill bg-gray-6 fs-20 fs-md-18']"
            :href="`#sub-tab-${idx}`"
            :key="idx"
            @click.prevent="tabBtnClickHandle(`#sub-tab-${idx}`)"
          >
            {{ tab }}
          </b-nav-item>
        </tab-swiper>
      </b-list-group>
    </template>
    <!-- // sub tab type = scroll -->

    <!-- sub tab type = click -->
    <template v-else>
      <b-nav class="px-0">
        <tab-swiper
          ref="sub-tab-swiper"
          :items="items"
          :isNaviHide="true"
          :options="{
            spaceBetween: 10,
            navigation: {
              nextEl: '.sub-tab-next',
              prevEl: '.sub-tab-prev',
            },
          }"
          class="tab"
          v-slot="{ tab, idx }"
        >
          <b-nav-item
            :class="[
              tagetTab === tab.value ? 'active' : '',
              'sub-tab-item w-100 gray-4 text-center rounded-pill bg-gray-6 fs-20 fs-md-18',
            ]"
            :key="idx"
            :to="{ ...$route, query: { tab: tab.value } }"
          >
            {{ typeof tab == "object" ? tab.text : tab }}
          </b-nav-item>
        </tab-swiper>
      </b-nav>
    </template>
    <!-- sub tab type = click -->
  </b-container>
</template>

<script>
import tabScroll from "@/mixins/tabScroll.js";
import common from "@/mixins/common.js";
export default {
  mixins: [tabScroll, common],
  props: {
    offset: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      window,
    };
  },
  computed: {
    tagetTab() {
      return this.$route.query.tab === undefined ? 0 : this.$route.query.tab; // undefined
    },
    items() {
      return this.subTab?.items;
    },
    scroll() {
      return this.subTab?.scroll;
    },
    small() {
      return this.subTab?.small;
    },
    hash() {
      return this.$route.hash;
    },
  },
  watch: {
    tagetTab(n) {
      const text = this.items.filter((item) => n === item.value)[0].text;
      this.clickTrack(text, this.title);

      if (this.scroll) return;
      const findIdx = this.items.findIndex((item) => n === item.value);
      this.callTabBtnSlide(["#sub-tab", findIdx]);
    },
  },
  methods: {
    scrollInit() {
      if (!this.hash) return;
      this.tabBtnClickHandle(this.hash);
    },
  },
  mounted() {
    console.log('this.subTab: ', this.subTab)
    this.scrollInit();
  },
};
</script>

<style lang="scss" scoped>
// 768px ~ up
@media (max-width: 768px) {
  .container {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
