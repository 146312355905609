<template>
  <div>
    <!-- PC -->
    <b-container v-if="meta.main">
      <div class="row d-none d-md-flex py-3">
        <div v-for="(info, idx) in items" :key="idx" class="col-4 py-5">
          <!-- 타이틀 -->
          <h3 class="fs-18 fw-7 mb-3">
            <router-link
              :to="{ name: info.name, params: info.params }"
              class="flex-start"
              @click.native="listClick(info)"
            >
              <!-- plus 아이콘 -->
              <div
                v-if="idx === items.length - 1"
                class="bg-img mr-2"
                :style="{
                  width: '1rem',
                  paddingBottom: '1rem',
                  backgroundSize: '200%',
                  backgroundImage: `url(${require(`@/assets/img/${$i18n.locale}/ic-plus.svg`)})`,
                }"
              ></div>
              <!-- // plus 아이콘 -->

              {{ info.title }}</router-link
            >
          </h3>
          <!-- // 타이틀 -->
          <!-- 리스트 -->
          <ul v-if="!loading">
            <li
              v-for="(row, i) in info.lists"
              :key="i"
              class="list col-8 fw-3 text-truncate px-0 mb-2 gray-2"
            >
              <router-link
                :to="{
                  name: 'consult-detail',
                  params: info.params,
                  query: getQuery(row, info.title),
                }"
                @click.native="listClick(info)"
              >
                - {{ row.title }}
              </router-link>
            </li>
          </ul>
          <!-- // 리스트 -->
          <b-row v-else-if="idx !== items.length - 1">
            <b-col v-for="i in 3" :key="i" cols="12">
              <b-skeleton
                :animation="null"
                width="80%"
                height="1.2rem"
                class="mb-3"
              ></b-skeleton>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <!-- // PC -->

    <!-- MOBILE -->
    <b-container
      v-if="meta.main"
      class="bg-white d-md-none px-0"
      :style="{ borderBottom: '2px solid #b2b2b2' }"
    >
      <b-row class="d-flex d-md-none text-center mx-0 ">
        <b-col class="col">
          <router-link
            class="btn btn-link fs-20 fw-7 black py-4 py-sm-5"
            :to="{ name: 'consult', params: { key: 'online' } }"
            >전문가 상담실</router-link
          >
        </b-col>
        <b-col class="col border-left border-gray-3">
          <router-link
            class="btn btn-link fs-20 fw-7 black py-4 py-sm-5"
            :to="{ name: 'ask', params: { key: 'ask' } }"
            >자주 찾는 질문</router-link
          >
        </b-col>
      </b-row>
    </b-container>
    <!-- //MOBILE -->
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  mixins: [common],
  data() {
    return {
      loading: false,
      items: [
        {
          title: "온라인 상담실",
          name: "consult",
          params: { key: "online" },
        },
        {
          title: "Best 건강상담",
          name: "consult",
          params: { key: "best" },
        },
        {
          title: "자주 찾는 질문",
          name: "ask",
          params: { key: "ask" },
        },
      ],
    };
  },
  methods: {
    async getList() {
      this.loading = true;
      const { kyoboApi } = this.getApis();

      const body = {
        page: 1,
        pageSize: 3,
        searchType: "all",
        searchString: "",
      };
      const [online, best] = await Promise.all([
        kyoboApi.onlineCounselling.online.list(body),
        kyoboApi.onlineCounselling.best.list(body),
      ]);
      this.items[0].lists = online.data?.rptList || [];
      this.items[1].lists = best.data?.rptList || [];
      this.loading = false;
    },
    getQuery(obj, title) {
      return title !== "Best 건강상담"
        ? { coun_key: obj.coun_key, replay_key: obj.replay_key }
        : { cn_sn: obj.cn_sn };
    },
    listClick(obj) {
      console.log(obj);
      this.clickTrack(obj.title, obj.title);
    }
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.list {
  &:hover {
    color: var(--blue-2) !important;
  }
}
</style>
