<template>
  <div class="position-relative w-100 table-slide">
    <!-- 스크롤 안내 문구 -->
    <slot v-if="isPopover" name="popover">
      <img
        :class="[
          { 'popover-visiable': isAllowTouchMove && popoverVisiable },
          'position-absolute table-popover',
        ]"
        src="@/assets/img/common/ic-table-popover.svg"
        alt="스크롤 안내 문구"
        :style="{
          top: '-4rem',
          right: 0,
          zIndex: 10,
          width: calcHeight('7rem'),
        }"
      />
    </slot>
    <!-- // 스크롤 안내 문구 -->
    <!-- 테이블 -->
    <swiper
      ref="table-swiper"
      :options="setOption"
      :style="{ whitespace: 'nowrap' }"
      :class="{ 'mt-5': isAllowTouchMove }"
      @touchEnd="touchEnd"
      @touchStart="touchStart"
    >
      <swiper-slide :style="{ ...styles }">
        <slot name="content"> </slot>
      </swiper-slide>
    </swiper>
    <!-- 테이블 -->

    <!-- 네비게이션 -->
    <template v-if="isNavi">
      <b-btn
        v-for="btn in ['next', 'prev']"
        :key="
          btn === 'next'
            ? `${setOption.spaceBetween}-prev-btn`
            : `${setOption.spaceBetween}-next-btn`
        "
        variant="link"
        :class="[
          btn === 'next' ? nextEl : prevEl,
          `table-${btn}-el d-flex flex-column position-absolute h-85`,
        ]"
        slot="button-next"
      >
      </b-btn>
    </template>
    <!-- // 네이게이션 -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { debounce } from "lodash";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    styles: {
      type: Object,
      default: () => ({ width: "auto" }),
    },
    isPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // default option
      swiperOption: {
        spaceBetween: 30,
        speed: 500,
        slidesPerView: "auto",
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: true,
        centerInsufficientSlides: true,
      },
      popoverVisiable: true,
      isAllowTouchMove: false,
    };
  },
  computed: {
    tableSwiper() {
      return this.$refs["table-swiper"]?.$swiper;
    },
    setOption() {
      return { ...this.swiperOption, ...this.options };
    },
    prevEl() {
      return this.options?.navigation?.prevEl.split(".")[1];
    },
    nextEl() {
      return this.options?.navigation?.nextEl.split(".")[1];
    },
    isNavi() {
      return !!this.options?.navigation;
    },
  },
  methods: {
    touchEnd($event) {
      this.$emit("touchEnd", $event);
    },
    touchStart($event) {
      if (this.isPopover) this.popoverVisiable = false;
      this.$emit("touchStart", $event);
    },
    async slideNext() {
      await this.$nextTick();
      this.tableSwiper.slideNext();
    },
    async slidePrev() {
      await this.$nextTick();
      this.tableSwiper.slidePrev();
    },
    handleClick(e) {
      this.$emit("handleClick", e);
    },
    async slideTo(i, ms) {
      await this.$nextTick();
      await this.tableSwiper.slideTo(i, ms);
    },
    setAllowTouchMove() {
      if (!this.tableSwiper) return;
      this.isAllowTouchMove = this.tableSwiper.allowTouchMove;
    },
  },
  mounted() {
    this.setAllowTouchMove();
    window.addEventListener(
      "resize",
      debounce(() => {
        this.setAllowTouchMove();
      }, 200)
    );
  },
  beforeMount() {
    window.removeEventListener(
      "resize",
      debounce(() => {
        this.setAllowTouchMove();
      }, 200)
    );
  },
};
</script>

<style lang="scss" scoped>
.table-slide {
  padding-left: 0;
  padding-right: 0;
  .swiper-container {
    .swiper-slide {
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
  .table-prev-el {
    right: 100%;
    box-shadow: 0.0625rem 0 0.3125rem 0.25rem #fff !important;
  }
  .table-next-el {
    left: 100%;
    box-shadow: -0.0625rem 0 0.3125rem 0.25rem #fff !important;
  }

  .table-prev-el,
  .table-next-el {
    transform: translateX(0);
    height: 100%;
    width: 1px;
    top: 0;
    z-index: 20;
    transition: opacity 0.2s;
    opacity: 1;
    background-color: #fff;
    .hide {
      display: none;
    }
  }
  .swiper-button-disabled {
    opacity: 0;
  }
}
</style>
