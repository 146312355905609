import request from "@/utils/request";

export default {
  memberShip: {
    get: () =>
      request({
        method: "post",
        url: "/kyobowomancare/services/member/grade",
        encryptionData: true,
      }),
  },
  check: {
    breast: {
      list: () =>
        request({
          method: "get",
          url: "/kyobowomancare/services/diagnosis/cancer/breast",
        }),
      result: (point) =>
        request({
          method: "get",
          url: `/kyobowomancare/services/diagnosis/cancer/breast/${point}`,
        }),
    },
    ovarian: {
      list: () =>
        request({
          method: "get",
          url: "/kyobowomancare/services/diagnosis/cancer/ovarian",
        }),
      result: (point) =>
        request({
          method: "get",
          url: `/kyobowomancare/services/diagnosis/cancer/ovarian/${point}`,
        }),
    },
    cervical: {
      list: () =>
        request({
          method: "get",
          url: "/kyobowomancare/services/diagnosis/cancer/cervical",
        }),
      result: (point) =>
        request({
          method: "get",
          url: `/kyobowomancare/services/diagnosis/cancer/cervical/${point}`,
        }),
    },
    diet: {
      list: () =>
        // 다이어트 : 다이어트 자가체크
        request({
          method: "get",
          url: "/kyobowomancare/services/diagnosis/diet",
        }),
      result: (point) =>
        // 다이어트 : 다이어트 자가체크 결괏값
        request({
          method: "get",
          url: `/kyobowomancare/services/diagnosis/diet/${point}`,
        }),
    },
    personaltype: {
      list: () =>
        // 심리 자가체크 : 성격유형
        request({
          method: "get",
          url: "/kyobowomancare/services/psy/personaltype",
        }),
      result: (point) =>
        // 심리 자가체크 : 성격유형 결괏값
        request({
          method: "get",
          url: `/kyobowomancare/services/psy/personaltype/${point}`,
        }),
    },
    stress: {
      list: () =>
        // 심리 자가체크 : 성격유형
        request({
          method: "get",
          url: "/kyobowomancare/services/psy/stress",
        }),
      result: (point) =>
        // 심리 자가체크 : 성격유형 결괏값
        request({
          method: "get",
          url: `/kyobowomancare/services/psy/stress/${point}`,
        }),
    },
    depression: {
      list: () =>
        // 심리 자가체크 : 우울증
        request({
          method: "get",
          url: "/kyobowomancare/services/psy/depression",
        }),
      result: (point) =>
        // 심리 자가체크 : 우울증 결괏값
        request({
          method: "get",
          url: `/kyobowomancare/services/psy/depression/${point}`,
        }),
    },
    anxiety: {
      list: () =>
        // 심리 자가체크 : 불안
        request({
          method: "get",
          url: "/kyobowomancare/services/psy/anxiety",
        }),
      result: (point) =>
        // 심리 자가체크 : 불안 결괏값
        request({
          method: "get",
          url: `/kyobowomancare/services/psy/anxiety/${point}`,
        }),
    },
    anger: {
      // 심리 자가체크 : 분노조절장애::::::
      list: () =>
        request({
          method: "get",
          url: "/kyobowomancare/services/psy/anger",
        }),
      result: (point) =>
        // 심리 자가체크 : 분노조절장애 결괏값::::::
        request({
          method: "get",
          url: `/kyobowomancare/services/psy/anger/${point}`,
        }),
    },
    panic: {
      // 심리 자가체크 : 공황장애::::::
      list: () =>
        request({
          method: "get",
          url: "/kyobowomancare/services/psy/panic",
        }),
      result: (point) => {
        // 심리 자가체크 : 공황장애 결괏값::::::
        request({
          method: "get",
          url: `/kyobowomancare/services/psy/panic/${point}`,
        });
      },
    },
  },
  info: {
    // 건강정보
    list: (x_code, l_code) =>
      request({
        method: "get",
        url: `/kyobowomancare/services/health/info/list/${x_code}/${l_code}`,
      }),
  },
  news: {
    news: {
      // 기획기사 리스트 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobowomancare/services/health/columnnews/list",
          data,
        }),
      // 기획기사 상세 불러오기
      detail: (report_key) =>
        request({
          method: "get",
          url: `/kyobowomancare/services/health/columnnews/details/${report_key}`,
        }),
    },
    recentNews: {
      // 최신뉴스 리스트 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobowomancare/services/health/recentnews/list",
          data,
          // 최신뉴스 상세 불러오기
        }),
      detail: (news_key) =>
        request({
          method: "get",
          url: `/kyobowomancare/services/health/recentnews/details/${news_key}`,
        }),
    },
  },
  therapy: {
    // 힐링테라피 <마음건강에 도움이 되는 음악> 불러오기
    music: () =>
      request({
        method: "get",
        url: "/kyobowomancare/services/healing/music/list",
      }),
    asmr: () =>
      // 힐링테라피 <심리적인 안정을 유도하는 ASMR> 불러오기
      request({
        method: "get",
        url: `/kyobowomancare/services/healing/asmr/list`,
      }),
  },
  myPage: {
    mycall: {
      // 전화 상담 내역 - 목록
      list: (data) =>
        request({
          method: "post",
          url: "/kyobowomancare/services/mypage/call/list",
          encryptionData: true,
          data,
        }),
      detail: (data) =>
        request({
          method: "post",
          url: "/kyobowomancare/services/mypage/call/details",
          encryptionData: true,
          data,
        }),
    },
  },
  hospital: {
    getLists: () =>
    // 전국 협력병원 리스트
    request({
      method: "get",
      url: "/kyobohealthcare/services/hospital/list",
    }),
    // 헬스케어 - 전국 협력 병원(검진 우대 병원) 리스트
    getList: (sido_num) =>
      request({
        method: "get",
        url: `/kyobowomancare/services/hospital/list/${sido_num}`,
      }),
    // 헬스케어 - 병원(검진 우대 병원)정보 보기
    getInfo: (hsptl_sn) =>
      request({
        method: "get",
        url: `/kyobowomancare/services/hospital/info/${hsptl_sn}`,
      }),
    // 헬스케어 - 검진항목보기(검진 우대 병원
    getItem: (hpt_code) =>
      request({
        method: "get",
        url: `/kyobowomancare/services/hospital/info/item/${hpt_code}`,
      }),
    // 헬스케어 - 검진항목 비교(검진 우대 병원
    compare: (data) =>
      request({
        method: "post",
        url: "/kyobowomancare/services/hospital/info/compare",
        data,
      }),
  }
};
