var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative w-100 tab-slide",class:{ isNaviHide: _vm.isNaviHide }},[_c('swiper',{key:_vm.setOption.spaceBetween,ref:"tab-swiper",class:{ 'swiper-mobile': _vm.mobile },style:({ whitespace: 'nowrap' }),attrs:{"options":_vm.setOption},on:{"touchEnd":function($event){return _vm.$emit('touchEnd', $event)},"touchStart":function($event){return _vm.$emit('touchStart', $event)}}},_vm._l((_vm.items),function(tab,idx){return _c('swiper-slide',{key:idx,style:({ ..._vm.styles })},[_vm._t("default",null,{"tab":tab,"idx":idx})],2)}),1),(_vm.isNavi)?_vm._l((['next', 'prev']),function(btn){return _c('b-btn',{key:btn === 'next'
          ? `${_vm.setOption.spaceBetween}-prev-btn`
          : `${_vm.setOption.spaceBetween}-next-btn`,class:[
        btn === 'next' ? _vm.nextEl : _vm.prevEl,
        `tab-${btn}-el d-flex flex-column position-absolute h-85`,
      ],attrs:{"slot":"button-next","variant":"link"},slot:"button-next"},[(!_vm.isNaviHide)?_c('i',{class:[
          btn === 'next' ? 'icon-right-open-big' : 'icon-left-open-big',
          'gray',
        ]}):_vm._e()])}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }