var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"position-relative"},[_c('table-swiper',{ref:"table-swiper",attrs:{"options":{
        navigation: {
          nextEl: '.table-next',
          prevEl: '.table-prev',
        },
      },"isPopover":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('table',{staticClass:"mb-4 border-bottom border-top border-blue border-2 px-0 fs-16"},[_c('thead',{staticClass:"fs-20 fs-md-18 fw-7 border-bottom border-blue border-2 mx-0"},_vm._l((_vm.tableObj.columns),function(th,i){return _c('th',{key:`${th.text}-${i}`,class:`${th.class} text-center p-2`,style:(th.style),attrs:{"colspan":th.colspan}},[_vm._v(" "+_vm._s(th.text)+" ")])}),0),_c('tbody',_vm._l((_vm.tableObj.row),function(tr,_i){return _c('tr',{key:_i,staticClass:"fs-16",style:({
                borderBottom: '1px solid  rgba(0, 40, 101, 0.1)',
              }),attrs:{"colspan":tr.colspan}},_vm._l((tr),function(td){return _c('td',{key:td.text,class:`${td.class} p-3`,style:({ textAlign: 'center' }),attrs:{"rowspan":td.rowspan,"colspan":td.colspan}},[_vm._v(" "+_vm._s(td.text)+" "),(td.button)?_c('b-btn',{attrs:{"variant":"link blue-btn py-2 px-4 fs-18 fs-md-16 mt-2"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.show('modal-hospitals-policy')}}},[_vm._v(" 전국협력병원 ")]):_vm._e()],1)}),0)}),0)])]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }