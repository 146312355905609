import axios from "axios";
import { showToast } from "@/plugins/custom-toast";
import { getCookie } from "@/utils/cookies";
import store from "@/store";

const instance = axios.create({
  // 실
  baseURL: "https://kyobo.gcapib.com/api",
  // 스테이징
  // baseURL: "https://kbtapi.gcstapi.co.kr/api",
  timeout: 10000,
});

instance.interceptors.request.use(
  (config) => {
    const token = getCookie({ key: "token" });
    const encryptionData = getCookie({ key: "encryptionData" });

    // Token 있으면 header에 추가
    if (token) {
      config.headers.Authorization = token;
    }

    // EcryptionData 있으면 header에 추가

    if (config.encryptionData && encryptionData) {
      config?.encryptionData && delete config.encryptionData;
      config.data = { ...config.data, encryptionData };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    // const { success } = response.data;
    return response?.data || response;
  },

  async (error) => {
    const { code = null, message = null } = error?.response?.data?.status;
    // 토큰 만료 시
    if ([419].includes(code) && !error.config.retry) {
      // 토근 재발급
      error.config.retry = true;
      await store.dispatch("auth/refreshToken");

      error.config.baseURL = "/";
      return instance(error.config);
    }
    showToast({
      title: `${code}: ${message}`,
      variant: "danger",
    });

    return Promise.reject(error);
  }
);

export default instance;
