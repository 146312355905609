<template>
  <div :class="{ isNaviHide }" class="position-relative w-100 tab-slide">
    <swiper
      :key="setOption.spaceBetween"
      ref="tab-swiper"
      :options="setOption"
      :style="{ whitespace: 'nowrap' }"
      :class="{ 'swiper-mobile': mobile }"
      @touchEnd="$emit('touchEnd', $event)"
      @touchStart="$emit('touchStart', $event)"
    >
      <swiper-slide
        v-for="(tab, idx) in items"
        :key="idx"
        :style="{ ...styles }"
      >
        <slot :tab="tab" :idx="idx"></slot>
      </swiper-slide>
    </swiper>
    <!-- 네비게이션 -->
    <template v-if="isNavi">
      <b-btn
        v-for="btn in ['next', 'prev']"
        :key="
          btn === 'next'
            ? `${setOption.spaceBetween}-prev-btn`
            : `${setOption.spaceBetween}-next-btn`
        "
        variant="link"
        :class="[
          btn === 'next' ? nextEl : prevEl,
          `tab-${btn}-el d-flex flex-column position-absolute h-85`,
        ]"
        slot="button-next"
      >
        <i
          v-if="!isNaviHide"
          :class="[
            btn === 'next' ? 'icon-right-open-big' : 'icon-left-open-big',
            'gray',
          ]"
        ></i>
      </b-btn>
    </template>
    <!-- // 네이게이션 -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: null,
    },
    styles: {
      type: Object,
      default: () => ({ width: "auto" }),
    },
    isNaviHide: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // default option
      swiperOption: {
        spaceBetween: 30,
        speed: 500,
        slidesPerView: "auto",
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: true,
        centerInsufficientSlides: true,
      },
    };
  },
  computed: {
    tabSwiper() {
      return this.$refs["tab-swiper"]?.$swiper;
    },
    setOption() {
      return { ...this.swiperOption, ...this.options };
    },
    prevEl() {
      return this.options?.navigation?.prevEl.split(".")[1];
    },
    nextEl() {
      return this.options?.navigation?.nextEl.split(".")[1];
    },
    isNavi() {
      return !!this.options?.navigation;
    },
  },
  methods: {
    async slideNext() {
      await this.$nextTick();
      this.tabSwiper.slideNext();
    },
    async slidePrev() {
      await this.$nextTick();
      this.tabSwiper.slidePrev();
    },
    handleClick(e) {
      this.$emit("handleClick", e);
    },
    async slideTo(i, ms) {
      await this.$nextTick();
      this.tabSwiper.slideTo(i, ms);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-slide {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  .swiper-container {
    .swiper-slide {
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
  .tab-prev-el {
    right: 100%;
    transform: translateX(1.25rem);
    box-shadow: 0.0625rem 0 0.3125rem 0.25rem #fff !important;
  }
  .tab-next-el {
    left: 100%;
    transform: translateX(-1.25rem);
    box-shadow: -0.0625rem 0 0.3125rem 0.25rem #fff !important;
  }

  .tab-prev-el,
  .tab-next-el {
    top: 0;
    z-index: 20;
    transition: opacity 0.2s;
    opacity: 1;
    background-color: #fff;
    .hide {
      display: none;
    }
  }
  .swiper-button-disabled {
    opacity: 0;
  }
}

.tab-slide.isNaviHide {
  padding-left: 0;
  padding-right: 0;
  .tab-prev-el {
    transform: translateX(0);
    height: 100%;
    width: 1px;
  }
  .tab-next-el {
    transform: translateX(0);
    height: 100%;
    width: 1px;
  }
}
</style>
