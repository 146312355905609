<template>
  <ValidationObserver
    class="d-flex flex-column flex-grow-1"
    ref="observer"
    v-slot="ctx"
  >
    <b-form
      class="d-flex flex-column flex-grow-1"
      @submit.prevent="submit(ctx.validate, $event)"
    >
      <slot v-bind="ctx" />
      <slot name="submit" :invalid="ctx.invalid" />
    </b-form>
  </ValidationObserver>
</template>

<script>
export default {
  name: "w-form",
  methods: {
    async submit(validate, e) {
      const valid = await validate();
      if (valid) {
        this.$emit("submit", e);
      }
    },
  },
};
</script>
