var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-term","size":"lg","hide-footer":true,"body-class":"p-0","centered":"","header-class":"p-0 d-block border-bottom-0"},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('div',{staticClass:"mx-4 py-4 flex-between"},[_c('div',{staticClass:"flex-start"},[_c('h3',{staticClass:"fs-24 fw-7 mt-1"},[_vm._v(" "+_vm._s(_vm.isPrivacy ? "개인정보 수집 및 이용동의" : "민감정보 수집 및 이용동의")+" ")])]),_c('b-btn',{style:({ marginRight: '-15px' }),attrs:{"variant":"link"},on:{"click":function($event){return close()}}},[_c('div',{staticClass:"bg-img",style:({
            backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
            width: '3rem',
            paddingBottom: '3rem',
          })})])],1)]}}])},[_c('div',{staticClass:"mx-4"},[_c('b-table-simple',{attrs:{"bordered":""}},[_c('b-thead',{attrs:{"head-variant":"gray-6 border-top border-black border-2"}},[_c('b-tr',_vm._l((_vm.fields),function(head){return _c('b-th',{key:head.text,staticClass:"text-center py-3 px-1 align-middle",style:({ ...head.thStyle, background: '#eee' })},[_vm._v(_vm._s(head.text))])}),1)],1),_c('b-tbody',_vm._l((_vm.tBody),function(row,i){return _c('b-tr',{key:`${row.purpose}-${i}`},_vm._l((row),function(col,key){return _c('b-td',{key:key,class:[
              key === 'period'
                ? 'text-decoration-underline fs-20 fw-7'
                : 'fs-md-17',
              'align-middle text-center',
            ],attrs:{"rowspan":key === 'period' ? _vm.getRowLength(row) : 1},domProps:{"innerHTML":_vm._s(col)}})}),1)}),1)],1),_c('p',{staticClass:"mt-3 mb-4 fs-17 gray-4"},[_vm._v(" 위의 개인정보 수집 및 이용동의를 거부할 권리가 있습니다. 다만, 동의를 거부할 경우 서비스를 이용하실 수 없습니다. ")]),_c('div',{staticClass:"flex-center py-4"},[_c('b-btn',{style:({ background: '#13a9ff' }),attrs:{"variant":"blue-2 rounded px-3 py-4 w-100 fs-20 fs-md-17 flex-center"},on:{"click":_vm.handleClick}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/common/ic-term-check.svg"),"alt":"check"}}),_vm._v(" 모든 내용을 읽고 확인했습니다.")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }