<template>
  <!-- 전국협력 병원 모달 -->
  <b-modal
    id="modal-member-woman-ship"
    size="md"
    :hide-footer="true"
    body-class="p-0"
    header-class="p-0 d-block border-bottom-0"
    modal-class="modal-member-ship"
    centered
    scrollable
    @show="showCb"
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-5 py-4 flex-1-1-0">
        
        <b-btn variant="link" :class="[
            type === 'membership' ? 'active' : '',
            'tab-item gray-3 fs-20 fs-md-18 pb-2',
          ]" @click="clickTab('membership')">멤버쉽 카드</b-btn>
        <b-btn variant="link" :class="[
            type === 'monthly' ? 'active' : '',
            'tab-item gray-3 fs-20 fs-md-18 pb-2',
          ]" :style="{marginLeft: '15px'}" @click="clickTab('monthly')">먼슬리 씽</b-btn>
        <!-- <h3 class="fs-24 fw-7 mt-1">
          멤버쉽 카드
        </h3>
        <h3 class="fs-24 fw-7 mt-1">
          먼슬리씽
        </h3> -->
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px', float: 'right' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="px-5 pb-5" v-if="type === 'membership'">
      <div class="position-relative">
        <div v-if="memberGradeStatus">
          <img
            class="w-100"
            :src="`${require(`@/assets/img/${$i18n.locale}/${cardImg}`)}`"
            alt="member ship card"
          />
          <p
            class="text-right white"
            :style="{
              position: 'absolute',
              bottom: '1.2rem',
              right: '1rem',
            }"
          >
            <span>{{ name }}</span
            >
            <!-- <br />
            <span>{{ code }}</span> -->
          </p>
        </div>
        <b-skeleton-img
          v-else
          no-aspect
          height="16.5rem"
          class="rounded-10"
        ></b-skeleton-img>
      </div>
    </div>
    <div class="px-4 px-md-5 pb-5" v-else>
      <p
        class="p-3 mb-4 rounded-10 border fs-18 text-center"
        :style="{
          color: '#323232',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        }"
      >
        여성건강 다이어리&여성·건강 제품몰의 혜택을 받아보세요!
      </p>
      <b-row class="mx-n2">
        <b-col cols="6" class="text-center fs-20 py-1 px-2"
          ><span>먼슬리씽<br/> 가입초대코드</span></b-col
        >
      </b-row>
      <b-row class="mx-n2 mb-4">
        <b-col class="px-2">
          <input
            ref="coupon"
            class="d-none"
            readonly
            v-model="coupon"
            type="text"
          />
          <p
            class="theme-color-1 fs-24 rounded-10 border fw-7 text-center py-3"
          >
            {{ coupon }}
          </p>
        </b-col>
        <b-col class="px-2">
          <b-btn
            class="d-block rounded-10 bg-theme-color-1 white fs-20 w-100 h-100 py-3"
            @click="copyAddr"
            >제휴번호 복사하기</b-btn
          >
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <b-col cols="12" class="px-0">
          <h4 class="fs-20">사용방법</h4>
        </b-col>
        <b-col class="fs-20 fw-3 gray px-0">
          <hash-title
            :isHash="false"
            :title="
              `<a href='https://monthlything.page.link/kblife/' class='fs-20 fw-3 gray' target='_blank' >https://monthlything.page.link/kblife</a>`
            "
          >
          </hash-title>
          <span>
            <i class="icon-right-open-big gray-3"></i>회원 가입 시 초대코드 입력(먼슬리씽 1천원 적립금 자동지급)
            <i class="icon-right-open-big gray-3"></i>프로필
            <i class="icon-right-open-big gray-3"></i>내적립금
          </span>
        </b-col>
      </b-row>
    </div>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
import { showToast } from "@/plugins/custom-toast";
export default {
  data() {
    return {
      memberGradeStatus: "",
      name: "",
      code: "",
      type: "membership",
      coupon: "KBLIFE23",
    };
  },
  computed: {
    cardImg() {
      switch (this.memberGradeStatus) {
        case "C":
          return "mcard-general.png";
        case "B":
          return "mcard-premier.png";
        case "A":
          return "mcard-prestige.png";
        default:
          return "mcard-general.png";
      }
    },
  },
  methods: {
    async getInfo() {
      const { kyoboApi } = this.getApis();
      const {
        data: { memberInfo },
      } = await kyoboApi.memberShip.get();
      const { MEMNAME, MEMCODE, memberGradeStatus } = memberInfo[0];
      this.memberGradeStatus = memberGradeStatus;
      this.name = MEMNAME;
      this.code = MEMCODE;
    },
    showCb() {
      this.getInfo();
    },
    clickTab(type) {
      this.type = type;
    },
    async copyAddr() {
      try {
        // ie or firefox
        if (!navigator.clipboard?.readText) {
          this.$refs.coupon?.select();
          document.execCommand("copy");
          showToast({ title: "쿠폰번호가 복사되었습니다." });
          return;
        }

        // 그외 브라우저
        await navigator.clipboard.writeText(this.coupon);
        showToast({ title: "쿠폰번호가 복사되었습니다." });
      } catch (e) {
        showToast({
          title: "클립보드 복사 실패",
          variant: "warning",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.modal-member-ship {
  .modal-dialog {
    max-width: 500px;
  }
}
</style>
