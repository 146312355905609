<template>
  <b-container class="hc-container">
    <template v-if="result">
      <!-- 카테고리 -->
      <div class="mb-3">
        <span
          class="theme-btn bg-theme-color-1 px-4 py-2 mr-3 fs-16"
          :style="{
            minWidth: '8rem',
          }"
          >성장 평가 결과</span
        >
      </div>
      <!-- 카테고리 -->
      <!-- 타이틀 -->
      <div class="mb-4 fs-30 fs-md-30 fs-md" v-html="result.title"></div>
      <!-- // 타이틀 -->

      <!-- 아이정보카드 -->
      <div
        v-for="card in resultCard"
        :key="card"
        class="border rounded p-4 p-md-5 fs-18 mb-4"
      >
        <h6 class="flex-between fs-20 fs-md-28">
          <span class="fw-7">{{ card }}</span>
          <span
            ><em
              class="fs-20 fs-md-24 fw-7"
              v-html="card === '키' ? result.height : result.weight"
            ></em>
            cm</span
          >
        </h6>
        <!-- 프로그래스 바 -->
        <div class="my-4">
          <div class=" bg-gray-6 rounded-pill position-relative p-2 my-4">
            <!--  동그라미 -->
            <div
              class="flex-center white rounded-circle bg-blue fs-15"
              :style="{
                width: calcHeight('3rem'),
                height: calcHeight('3rem'),
                position: 'absolute',
                left:
                  card === '키'
                    ? `${result.icm_hra_pt}%`
                    : `${result.ikg_hra_pt}%`,
                top: '50%',
                transform: `translate(-${
                  card === '키' ? result.icm_hra_pt : result.ikg_hra_pt
                }%, -50%)`,
              }"
            >
              {{ card === "키" ? result.icm_hra_pt : result.ikg_hra_pt }}%
            </div>
            <!-- // 동그라미 -->
          </div>
          <div class="flex-between fs-20">
            <span>작음</span> <span>적정</span><span>큼</span>
          </div>
        </div>
        <div class="fs-20 fs-md-24">
          <p
            class="fw-7 theme-color-1"
            v-html="
              card === '키' ? result.r_typ2_sum_txt1 : result.r_typ1_sum_txt1
            "
          ></p>
          <p
            v-html="
              card === '키' ? result.r_typ2_sum_txt2 : result.r_typ1_sum_txt2
            "
          ></p>
        </div>
      </div>
      <!-- // 아이정보카드 -->

      <!-- 버튼 -->
      <div class="flex-center py-5">
        <router-link
          class="blue-submit-btn fs-20"
          :to="{
            ...$route,
            name: 'check-grow',
          }"
          >완료</router-link
        >
      </div>
      <!-- // 버튼 -->
    </template>
    <skeleton-grow-insert v-else></skeleton-grow-insert>
  </b-container>
</template>

<script>
import SkeletonGrowInsert from "@/components/common/SkeletonGrowInsert";

export default {
  components: {
    SkeletonGrowInsert,
  },
  data() {
    return {
      result: null,
      resultCard: ["키", "몸무게"],
    };
  },
  computed: {
    gorwBody() {
      return this.$route.query;
    },
  },
  methods: {
    replaceTag(str) {
      return str.replace(
        /<strong/gi,
        "<strong class='hash-title position-relative'"
      );
    },

    async insert() {
      const { kyoboApi } = this.getApis();
      const {
        data: { result },
      } = await kyoboApi.check.growth.insert(this.gorwBody);
      result.r_typ2_sum_txt2 = this.replaceTag(result.r_typ2_sum_txt2);
      result.r_typ1_sum_txt2 = this.replaceTag(result.r_typ1_sum_txt2);
      this.result = result;
    },
  },
  mounted() {
    this.insert();
  },
};
</script>
<style lang="scss" scoped>
.hc-container::v-deep h2 {
  font-size: calc(1.5rem - 0.5vh + 0.2vw) !important;
  @media (min-width: 768px) {
    font-size: calc(2rem - 0.5vh + 0.2vw) !important;
  }
}
.hc-container::v-deep .con-inner {
  font-size: calc(1.25rem - 0.5vh + 0.2vw) !important;
  @media (min-width: 768px) {
    font-size: calc(1.5rem - 0.5vh + 0.2vw) !important;
  }
}
</style>
