<template>
  <div>
    <b-container :class="subTab ? 'hc-tab-container' : 'hc-container'">
      <div
        v-for="(title, i) in subTab.items"
        :key="title"
        :style="{
          ...(subTab.items.length !== i + 1 && { paddingBottom: '5rem' }),
        }"
        :id="`sub-tab-${i}`"
        class="pt-4"
      >
        <div>
          <div class="border-bottom border-green py-3">
            <hash-title :title="title"></hash-title>
          </div>
          <template v-if="items">
            <hc-list-play
              ref="play-list"
              v-for="(item, _i) in items[`music0${i + 1}`]"
              :key="_i"
              :title="item.INFO_SUBJECT"
              :idx="item.INFO_SUBJECT"
              :url="item.INFO_TITLE_URL"
            ></hc-list-play>
          </template>
          <template v-else>
            <skeleton-list></skeleton-list>
          </template>
        </div>
      </div>
    </b-container>
    <!-- banner -->
    <sub-banner
      :title="bannerTitle"
      :btnArr="btnArr"
      width="30%"
      @handleClick="handleClick"
    ></sub-banner>
    <!-- // banner -->
  </div>
</template>

<script>
import SubTab from "@/components/common/SubTab";
import HcListPlay from "@/components/common/HcListPlay";
import SubBanner from "@/components/common/SubBanner";
import HcCard from "@/components/common/HcCard";
import SkeletonList from "@/components/common/SkeletonList";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    SubTab,
    HcListPlay,
    SubBanner,
    HcCard,
    SkeletonList,
  },
  data() {
    return {
      items: null,

      scrollNum: 2,
      maxScrollNum: 5,
    };
  },
  computed: {
    isMusic() {
      return this.id === "music";
    },
    bannerItems() {
      return this.$t(this.key);
    },
    bannerTitle() {
      return this.id === "music"
        ? "심리적 안정을 유도하는 ASMR를 들으러 가시겠어요?"
        : "마음건강에 도움이 되는 음악을 들으러 가시겠어요?";
    },
    btnArr() {
      const filteredItems = this.bannerItems.filter((el) => el.id !== this.id);
      return filteredItems.map((el) => ({
        ...el,
        title: el.title.replace("<br/>", ""),
      }));
    },
  },
  watch: {
    isMusic() {
      this.getList();
    },
  },
  methods: {
    handleClick(id) {
      this.items = null;
      this.$router
        .push({ name: "check-therapy", params: { id, key: this.key } })
        .catch((e) => {});
      window.scrollTo({ top: 0, left: 0 });
    },
    async getList() {
      const { kyoboApi } = this.getApis();
      const { data } = await kyoboApi.therapy[this.id]();
      this.items = data;
    },
  },
  mounted() {
    this.getList();
    window.scrollTo({ top: 0, left: 0 });
  },
};
</script>

<style lang="scss"></style>
