<template>
  <div>
    <!-- // table caption -->
    <div class="position-relative">
      <table-swiper
        ref="table-swiper"
        :options="{
          navigation: {
            nextEl: '.table-next',
            prevEl: '.table-prev',
          },
        }"
        isPopover
      >
        <template v-slot:content>
          <table class="mb-4 border-bottom border-top border-blue border-2 px-0 fs-16">
            <!-- 테이블 헤더 -->
            <thead class="fs-20 fs-md-18 fw-7 border-bottom border-blue border-2 mx-0">
              <th
                v-for="(th, i) in tableObj.columns"
                :key="`${th.text}-${i}`"
                :class="`${th.class} text-center p-2`"
                :style="th.style"
                :colspan="th.colspan"
              >
                {{ th.text }}
              </th>
            </thead>
            <!-- 테이블 바디 -->
            <tbody>
              <tr
                v-for="(tr, _i) in tableObj.row"
                :key="_i"
                class="fs-16"
                :colspan="tr.colspan"
                :style="{
                  borderBottom: '1px solid  rgba(0, 40, 101, 0.1)',
                }"
              >
                <td
                  v-for="td in tr"
                  :key="td.text"
                  :rowspan="td.rowspan"
                  :colspan="td.colspan"
                  :class="`${td.class} p-3`"
                  :style="{ textAlign: 'center' }"
                >
                  {{ td.text }}
                  <b-btn
                    v-if="td.button"
                    variant="link blue-btn py-2 px-4 fs-18 fs-md-16 mt-2"
                    @click.prevent="$bvModal.show('modal-hospitals-policy')"
                  >
                    전국협력병원
                  </b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </table-swiper>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    tableObj: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
