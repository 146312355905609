var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.hosInfo)?_c('div',{staticClass:"h-100"},[(_vm.tagetTab === '병원정보')?_c('div',[_c('kakao-map',_vm._b({style:({ height: _vm.calcHeight('21rem') })},'kakao-map',{
          y: _vm.hosInfo.latitude,
          x: _vm.hosInfo.longitude,
          name: _vm.hosInfo.h_name,
          address: _vm.address,
        },false)),_c('div',{staticClass:"px-4 px-md-5 py-3 flex-column flex-md-row flex-between align-items-start"},[_c('div',[_c('div',{staticClass:"fs-20 flex-start"},[_c('span',[_vm._v(_vm._s(_vm.hosInfo.h_name))]),_c('a',{staticClass:"bg-img",style:({
                width: _vm.calcHeight('2rem'),
                paddingBottom: _vm.calcHeight('2rem'),
                backgroundImage: `url(${require('@/assets/img/common/ic-home.svg')})`,
              }),attrs:{"href":_vm.hosInfo.h_url,"target":"_blank"}})]),_c('div',{staticClass:"flex-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],ref:"address",staticClass:"d-none",attrs:{"readonly":"","type":"text"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing)return;_vm.address=$event.target.value}}}),_c('span',{staticClass:"gray-5 mt-1 fs-18"},[_vm._v(_vm._s(_vm.address))]),_c('b-btn',{staticClass:"bg-img",style:({
                width: _vm.calcHeight('2rem'),
                paddingBottom: _vm.calcHeight('2rem'),
                backgroundImage: `url(${require('@/assets/img/common/ic-copy-mini.svg')})`,
              }),attrs:{"variant":"link"},on:{"click":_vm.copyAddr}})],1)]),_c('div',[_c('p',{staticClass:"fs-22 fw-7 mt-1 blue-2"},[_vm._v(_vm._s(_vm.hosInfo.tel))])])])],1):_vm._e(),(_vm.tagetTab === '병원정보')?[_c('section',{staticClass:"mx-4 mx-md-5 pb-5",style:({ borderTop: '3px solid #000' })},_vm._l((_vm.items),function(row,key){return _c('div',{key:key,staticClass:"row border-bottom border-gray-5 py-3 m-0 fs-20"},[_c('div',{staticClass:"col-3 gray-5"},[_vm._v(_vm._s(row.text))]),_c('div',{staticClass:"col-9 flex-start"},[_vm._v(" "+_vm._s(_vm.getRowInfo(row) ? _vm.getRowInfo(row) : "-")+" ")])])}),0)]:_vm._e(),(_vm.tagetTab === '검진항목')?[_c('section',{staticClass:"px-4 px-md-5 pb-5"},[_c('b-container',{staticClass:"p-0"},[_vm._l((_vm.hosItems),function(row,i){return [(row.des)?_c('div',{key:`${row.lcode}-${i}`,staticClass:"row border-bottom border-gray-5 py-3 m-0 fs-20"},[_c('div',{staticClass:"col-3 gray-5"},[_vm._v(_vm._s(row.sname))]),_c('div',{staticClass:"col-9 flex-start"},[_vm._v(_vm._s(row.des))])]):_vm._e()]})],2)],1)]:_vm._e()],2):_c('skeleton-hos-map',{attrs:{"isList":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }