<template>
  <div class="main-slide position-relative mb-5 mb-md-0">
    <!-- 슬라이드 -->
    <swiper
      class="white position-relative"
      ref="swiper"
      :options="swiperOption"
    >
      <swiper-slide v-for="(s, idx) in slide" :key="idx">
        <div class="d-flex w-100 h-100">
          <b-container class="flex-center position-relative px-0">
            <b-row class="text-center">
              <b-col
                cols="12"
                class="white fw-7 fs-30 fs-md-38 mb-2"
                data-swiper-parallax="-1000"
              >
                {{ s.title }}
              </b-col>
              <b-col
                cols="12"
                v-for="t in s.subTitle"
                :key="t"
                class="white fs-22 fs-md-19 fw-md-3"
                data-swiper-parallax="-800"
                v-html="t"
              >
              </b-col>
              <b-col class="mt-3 mt-md-4" data-swiper-parallax="-600" >
                <router-link
                  :to="{ name: s.to, hash: s.hash, params: { key: s.key } }"
                  @click.native="detailClick(s.title)"
                >
                  <img
                    class="main-slide-btn"
                    :src="
                      `${require(`@/assets/img/${$i18n.locale}/ic-main-slide-btn.svg`)}`
                    "
                    alt="자세히 보기"
                    :style="{
                      width: calcHeight('10rem'),
                    }"
                  />
                </router-link>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div
          class="position-absolute w-100 h-100 d-none d-md-block"
          :style="{
            backgroundImage: `url(${require(`@/assets/img/${
              $i18n.locale
            }/visual-${idx + 1}.jpg`)})`,
            zIndex: '-2',
            top: 0,
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
        ></div>
        <div
          class="position-absolute w-100 h-100 d-block d-md-none"
          :style="{
            backgroundImage: `url(${require(`@/assets/img/${
              $i18n.locale
            }/visual-${idx + 1}-mobile.jpg`)})`,
            zIndex: '-2',
            top: 0,
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }"
        ></div>
        <div class="swiper-background"></div>
      </swiper-slide>
    </swiper>
    <!-- // 슬라이드 -->
    <!-- 멤버쉽 카드 -->
    <b-container class="position-relative">
      <b-btn
        v-if="card"
        variant="link position-absolute member-ship-btn"
        :style="{
          right: 0,
          top: '-3.5rem',
          zIndex: 10,
        }"
        data-swiper-parallax="-1000"
        @click.prevent="showMemberShip($i18n.locale)"
      >
        <img
          :src="
            `${require(`@/assets/img/${$i18n.locale}/ic-member-ship-btn.svg`)}`
          "
          :style="{ width: calcHeight('12rem', '4vh') }"
          alt="멤버쉽 카드 보기"
        />
      </b-btn>
    </b-container>
    <!-- // 멤버쉽 카드 -->
    <!-- 페이지네비게이션 -->
    <div class="swiper-slide-wrap flex-center py-4">
      <div class="main-swiper-pagination flex-center" slot="pagination"></div>
      <div class="control-wrap ml-2 d-flex">
        <b-btn variant="link" class="mr-2" @click="handleAuto">
          <span
            class="bg-img d-none d-md-block"
            :style="{
              width: '3rem',
              paddingBottom: '3rem',
              ...(!autoplay && {
                backgroundImage: `url(${require('@/assets/img/common/ic-play.svg')})`,
              }),
              ...(autoplay && {
                backgroundImage: `url(${require('@/assets/img/common/ic-stop.svg')})`,
              }),
            }"
          >
          </span>
        </b-btn>
      </div>
    </div>
    <!-- // 페이지네비게이션 -->
    <template v-if="card">
      <modal-pet-care v-if="['kyobojuniorcare'].includes($i18n.locale)"></modal-pet-care>
      <modal-woman-member-ship v-if="['kyobowomancare'].includes($i18n.locale)"></modal-woman-member-ship>    
      <modal-member-ship v-else></modal-member-ship> 
    </template>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ModalMemberShip from "@/components/kyobohealthcare/modal/ModalMemberShip";
import ModalPetCare from "@/components/kyobojuniorcare/modal/ModalPetCare";
import ModalWomanMemberShip from "@/components/kyobohealthcare/modal/ModalWomanMemberShip"
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: { Swiper, SwiperSlide, ModalMemberShip, ModalPetCare, ModalWomanMemberShip },
  props: {
    slide: {
      type: Array,
      default: () => [],
    },
    card: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // swiper 옵션
      autoplay: true,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 800,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        parallax: true,
        pagination: { el: ".main-swiper-pagination", clickable: true },
      },
    };
  },
  methods: {
    handleAuto() {
      this.autoplay = !this.autoplay;
      const { $swiper } = this.$refs.swiper;
      this.autoplay ? $swiper.autoplay.start() : $swiper.autoplay.stop();
    },
    detailClick(title) {
      this.clickTrack(title, "배너");
    },
    showMemberShip(type) {
      if (['kyobojuniorcare'].includes(type)) {
        this.$bvModal.show('modal-pet-care');
        this.clickTrack("펫케어 제휴서비스", "메인");
      } else if(['kyobowomancare'].includes(type)) {
        this.$bvModal.show('modal-member-woman-ship');
        this.clickTrack("멤버쉽 카드", "메인");
      } else {
        this.$bvModal.show('modal-member-ship')
        this.clickTrack("멤버쉽 카드", "메인");
      }
    }
  },
};
</script>
