<template>
  <div>
    <!-- title -->
    <div>
      <b-btn
        variant="link"
        v-b-toggle="`accordion-${idx}`"
        class="flex-between w-100 gray fw-7 py-3 px-2 text-left fs-20 fs-md-18"
        role="tab"
        >{{ title }}
        <span>
          <i
            :class="[
              isShow ? 'icon-minus' : 'icon-plus',
              'theme-color-1 fs-30',
            ]"
          ></i> </span
      ></b-btn>
    </div>
    <!-- // title -->

    <!-- content -->
    <!-- 비디오 아닐때  -->
    <b-collapse
      v-if="!isVideo"
      :id="`accordion-${idx}`"
      :accordion="`my-accordion`"
      :visible="setVisible"
      role="tabpanel"
      @input="input"
      class="border-top border-gray-5"
    >
      <div class="p-4">
        <p
          v-if="title !== '예방접종 스케줄(표)'"
          v-html="manufactureContent"
          :class="contentClass"
        ></p>
        <schedule-table v-else :isExplanation="true"></schedule-table>
      </div>
    </b-collapse>

    <!-- 비디오 일때  -->
    <b-collapse
      v-else
      :id="`accordion-${idx}`"
      :accordion="`my-accordion`"
      :visible="setVisible"
      role="tabpanel"
      @input="input"
      v-slot="{ visible }"
    >
      <div
        :style="{
          height: '40vw',
          maxHeight: '500px',
        }"
        class="position-relative"
      >
        <b-overlay :show="loading" rounded="sm" class="w-100 h-100">
          <iframe
            v-if="visible"
            :src="movieLink"
            frameborder="0"
            width="100%"
            height="100%"
            class="position-absolute py-2"
            :style="{
              left: 0,
              right: 0,
            }"
            allowfullscreen="allowfullscreen"
            @load="test"
          ></iframe>
        </b-overlay>
      </div>
    </b-collapse>
    <!-- // content -->
  </div>
</template>

<script>
import ScheduleTable from "@/components/kyobokidscare/ScheduleTable";
export default {
  components: {
    ScheduleTable,
  },
  props: {
    title: {
      type: String,
      default: "-",
    },
    content: {
      type: String,
      default: "-",
    },
    movieLink: {
      type: String,
      default: "",
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
    setVisible: {
      type: Boolean,
      default: false,
    },
    accordion: {
      type: String,
      default: "my-accordion",
    },
    idx: {
      type: Number,
      default: 0,
    },
    contentClass: {
      type: String,
      default: "fs-20 gray fw-3",
    },
  },
  data() {
    return {
      loading: true,
      isShow: false,
    };
  },
  computed: {
    manufactureContent() {
      return this.$route.path === "/kyobowomancare/info/psychology" ? this.content.replaceAll("/upfolder/images/", "https://kyoboadditional.com/subwomanhealth/upfolder/images/") : this.content;
    }
  },
  methods: {
    test() {
      this.loading = false;
    },
    input(v) {
      this.isShow = v;
    },
  },
};
</script>
