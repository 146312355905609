export default {
  p: (title) =>
    `<(주)녹십자헬스케어>는 '${title}('https://www.kyobohealthcare.com')'를 운영함에 있어 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)및 개인정보보호법에 따라 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
  회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
  </br>
  </br>
  본 방침은 2017년 12월 4일부터 시행됩니다.

  </br>
  </br>
  (주)녹십자헬스케어는 "개인정보보호 관련 법령" 에 따라 아래와 같이 개인정보보호지침을 준수하고 있습니다.</br>
  - 개인정보의 수집 및 이용 목적</br>
  - 수집하려는 개인정보의 항목</br>
  - 개인정보의 보유 및 이용기간</br>
  - 개인정보의 제3자 제공에 관한 사항</br>
  - 기술적, 관리적 대책</br>
  - 정보주체의 권리·의무 및 그 행사방법에 관한 사항</br>
  - 개인 아이디와 비밀번호 관리</br>
  - 회원의 개인정보 관리 책임자</br>
  </br>`,
};
