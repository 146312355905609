var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[_c('validation-provider',{ref:"provider",staticClass:"flex-grow-1",attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('div',{class:{
        'border-danger':
          _vm.rules && _vm.getValidationState(validationContext) === false,
      }},[(_vm.label)?_c('label',{staticClass:"flex-grow-1 mb-3"},[_c('span',{class:{
            'text-danger':
              _vm.rules && _vm.getValidationState(validationContext) === false,
          },domProps:{"textContent":_vm._s(_vm.label)}})]):_vm._e(),_c('b-input-group',{staticClass:"align-items-center",scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(){return [_vm._t(slot,null,null,{
              state: _vm.rules && _vm.getValidationState(validationContext),
            })]},proxy:true}})],null,true)},[_c('b-input',_vm._b({staticClass:"px-0",attrs:{"value":_vm.value},on:{"input":_vm.inputListener}},'b-input',{
            ..._vm.$props,
            class: [
              _vm.inputClass,
              {
                'hide-icon': _vm.hideStateIcon,
              },
            ],
            state: _vm.rules && _vm.getValidationState(validationContext),
          },false)),(!_vm.hideError)?_c('b-form-invalid-feedback',{staticClass:"position-absolute",style:({ top: 'calc(100% + 3px)' })},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]):_vm._e()],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }