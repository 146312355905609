// 쿠키 키 값들
const cookieKeys = {
  token: "KYOBO-TOKEN",
  encryptionData: "KYOBO-DATA",
  code: "KYOBO-CODE",
  memeberData: "KYOBO-MEMNER-DATA",
  talk: "TALK"
};

export const getCookie = ({ key }) => sessionStorage.getItem(cookieKeys[key]);
export const setCookie = ({ key, value }) => sessionStorage.setItem(cookieKeys[key], value);
export const removeCookie = ({ key }) => sessionStorage.removeItem(cookieKeys[key]);
