<template>
  <!--  -->
  <b-modal
    :id="id"
    size="xl"
    :hide-footer="true"
    @hide="hideCb"
    body-class="p-0"
    header-class="p-0 d-block border-bottom-0"
    modal-class="mw-100"
    scrollable
    centered
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-4 px-md-5 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-start">
          <b-btn v-if="isModeSurvey" variant="link" class="fs-20" @click="back"
            ><i class="icon-left-open-big"></i
          ></b-btn>
          <h3 class="fs-24 fw-7 mt-1">
            {{
              isModeSurvey
                ? `${body.selectedProgram.text} 초기 문진`
                : "집중관리 프로그램"
            }}
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="px-4 px-md-5">
      <management
        v-if="!isModeSurvey"
        @onSubmit="onSubmit"
        :modalId="id"
      ></management>
      <management-survey
        v-else
        v-bind="{
          ...body,
        }"
        :modalId="id"
      ></management-survey>
    </div>
  </b-modal>
</template>

<script>
import Management from "@/components/kyobokidscare/modal/modalComponents/Management";
import ManagementSurvey from "@/components/kyobokidscare/modal/modalComponents/ManagementSurvey";

export default {
  components: {
    ManagementSurvey,
    Management,
  },
  data() {
    return {
      id: "modal-management",
      isModeSurvey: false,
      body: null,
    };
  },
  methods: {
    openCb() {},
    onSubmit(body) {
      this.body = body;
      this.isModeSurvey = true;
    },
    reset() {
      this.isModeSurvey = false;
      this.selectedChlidren = null;
      this.body = null;
    },
    back() {
      this.isModeSurvey = false;
    },
    hideCb() {
      this.reset();
    },
  },
};
</script>
