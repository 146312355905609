<template>
  <b-btn
    variant="link d-block w-100 border-bottom border-gray-5"
    @click="handleClick"
  >
    <!-- pc -->
    <b-row align-v="center" class="mx-0 d-none d-md-flex px-1 py-4">
      <b-col cols="9" md="10" class="flex-start">
        <span
          v-if="column"
          class="theme-btn py-2 px-3 fs-16 mr-3"
          :style="{ minWidth: calcHeight('110px') }"
          >{{ column }}</span
        >
        <span v-html="title" class="fs-18 gray text-truncate"></span>
      </b-col>
      <b-col cols="3" md="2" class="flex-end">
        <span class="gray-5">{{ date }}</span>
      </b-col>
    </b-row>
    <!-- mobile -->
    <b-row align-v="center" class="d-flex d-md-none mx-0 px-2 py-3">
      <b-col cols="12" class="flex-start px-0">
        <span
          v-if="column"
          class="theme-btn p-2 fs-20 mr-3"
          :style="{ minWidth: calcHeight('6rem'), whiteSpace: 'nowrap' }"
          >{{ column }}</span
        >
        <div class="text-left overflow-hidden">
          <div class="gray-5">{{ date }}</div>
          <div v-html="title" class="fs-20 gray text-truncate"></div>
        </div>
      </b-col>
    </b-row>

    <!-- // mobile -->
  </b-btn>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    column: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "-",
    },
    date: {
      type: String,
      default: "YYYY-MM-DD",
    },
  },
  methods: {
    handleClick() {
      this.$emit("handleClick", this.id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
