<template>
  <b-container class="hc-container">
    <!-- 검색 -->
    <div class="flex-center flex-column mb-4">
      <hc-search-form @hanldeSearch="hanldeSearch"></hc-search-form>
    </div>
    <!-- // 검색 -->

    <!-- 게시물 -->
    <template v-if="items.length">
      <div class="border-bottom border-theme-color-1 py-3">
        <span class="mr-3 fs-18 fs-md-16 gray"
          >총 <em class="">{{ totalCount }}</em
          >건의 게시글이 있습니다.</span
        >
      </div>
      <div>
        <hc-news-list
          v-for="row in items"
          @handleClick="handleClick"
          :key="isHealthNews ? row.report_key : row.news_key"
          v-bind="{
            date: row.viewdate || formatedDate(row.regdate),
            id: isHealthNews ? row.report_key : row.news_key,
            title: splitedTitle(row.title).title,
            column: splitedTitle(row.title).column,
          }"
        ></hc-news-list>
      </div>
      <!-- 페이지 이동 버튼 -->
      <hc-pagination
        v-if="totalPage > 1"
        :pageNum="totalPage"
        class="d-none d-md-flex"
      ></hc-pagination>
      <div
        v-if="totalCount > pageSize"
        class="d-felx d-md-none flex-center pt-4"
      >
        <b-btn
          variant="link fs-20 border-bottom border-blue blue"
          @click="handleBtnClick"
          >더 보기</b-btn
        >
      </div>
      <!-- // 페이지 이동 버튼 -->
    </template>
    <!-- // 게시물 -->

    <!-- 게시물 없을 때 -->
    <!-- <template v-else-if="!loading && searchString"> -->
    <template v-else-if="this.totalCount === 0">
      <div class="border-bottom border-green py-3">
        <span class="mr-3 fs-18 fs-md-16 gray"
          >총 <em class="">{{ totalCount }}</em
          >건의 게시글이 있습니다.</span
        >
      </div>
      <p class="py-5 text-center">검색된 내용이 없습니다.</p>
    </template>
    <!-- // 게시물 없을 때 -->

    <!-- 스켈레톤 -->
    <template v-else>
      <div class="border-bottom border-green py-3">
        <b-skeleton :animation="null" width="30%" height="2rem"></b-skeleton>
      </div>
      <div>
        <skeleton-list></skeleton-list>
      </div>
    </template>
    <!-- // 스켈레톤 -->
  </b-container>
</template>

<script>
import HcNewsList from "@/components/common/HcNewsList";
import HcPagination from "@/components/common/HcPagination";
import HcSearchForm from "@/components/common/HcSearchForm";
import SkeletonList from "@/components/common/SkeletonList";
import { formatedDate } from "@/utils/dayjs";
import common from "@/mixins/common.js";

export default {
  mixins: [common],
  components: {
    HcNewsList,
    HcPagination,
    HcSearchForm,
    SkeletonList,
  },
  data() {
    return {
      loading: false,
      totalCount: 15,
      items: [],
      pageSize: 15,
      nowTitle: "",
    };
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    isHealthNews() {
      return this.key === "health-news";
    },
    page() {
      return !this.$route.query.page ? 1 : Number(this.$route.query.page);
    },
    totalPage() {
      return Math.floor(
        this.totalCount / this.pageSize +
          (this.totalCount % this.pageSize > 0 ? 1 : 0)
      );
    },
    searchType() {
      return !this.$route.query.searchType
        ? "all"
        : this.$route.query.searchType;
    },
    searchString() {
      return this.$route.query.searchString;
    },
    compareTitle() {
      return this.nowTitle == this.title;
    },
  },
  methods: {
    formatedDate,
    handleBtnClick() {
      this.pageSize += 5;
      this.getList(1, this.pageSize, this.searchType, this.searchString);
    },
    handleClick(id) {
      this.$router
        .push({
          name: "info-news-detail",
          params: { ...this.$route.params, id },
        })
        .catch(() => {});
    },
    hanldeSearch({ searchString, searchType }) {
      this.$router.push({
        ...this.$rotue,
        query: { searchString, searchType, page: 1 },
      });
      this.getList(this.page, this.pageSize, searchType, searchString);
    },
    splitedTitle(str) {
      console.log('str: ', str)
      if (!this.isHealthNews) return { title: str, column: "" };
      const re = /\[(.*?)\]/gi;
      const isMatch = str.match(re);
      if (!isMatch) return { title: str, column: "" };

      const strArr = str.split(/\[(.*?)\]/gi);
      return { title: strArr[2], column: strArr[1] };
    },
    async getList(
      page = 1,
      pageSize = 15,
      searchType = "all",
      searchString = ""
    ) {
      this.loading = true;
      this.items = [];
      this.totalCount = 0;
      const body = {
        page: page + "",
        pageSize: pageSize + "",
        searchType,
        searchString,
      };
      const { kyoboApi } = this.getApis();

      const { data } = this.isHealthNews
        ? await kyoboApi.news.news.list(body)
        : await kyoboApi.news.recentNews.list(body);

      if (
        [
          "kyobohealthcare",
          "newkyobohealthcare",
          "kyobokidscare",
          "kyoboseniorcare",
          "kyobojuniorcare"
        ].includes(this.$i18n.locale)
      ) {
        this.totalCount = Number(data.totalCnt);
        this.items = data.rptList;
        this.loading = false;
      }
      if (this.$i18n.locale === "kyobowomancare") {
        this.totalCount = Number(data.totalCnt);
        this.items = this.isHealthNews ? data.colNewsList : data.recentNewsList;
        this.loading = false;
      }
      console.log('items: ', this.items)
      console.log('totalCnt: ', this.totalCount)
    },
    setTitie(title) {
      this.nowTitle = title;
    }
  },
  watch: {
    $route() {
      this.getList(
        this.page,
        this.pageSize,
        this.searchType,
        this.searchString
      );
    },
  },
  mounted() {
    if (!this.compareTitle) {
      this.pageViewTrack(this.title);
      this.setTitie(this.title);
    }
    this.getList(this.page, this.pageSize, this.searchType, this.searchString);
  },
  updated() {
    if (!this.compareTitle) {
      this.pageViewTrack(this.title);
      this.setTitie(this.title);
    }
  }
};
</script>

<style lang="scss" scoped></style>
