var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":_vm.id,"size":"xl","hide-footer":true,"body-class":"p-0","header-class":"p-0 d-block border-bottom-0","modal-class":"mw-100","scrollable":"","centered":""},on:{"hide":_vm.hideCb},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('div',{staticClass:"px-4 px-md-5 py-4 flex-between"},[_c('div',{staticClass:"flex-start"},[(_vm.isModeSurvey)?_c('b-btn',{staticClass:"fs-20",attrs:{"variant":"link"},on:{"click":_vm.back}},[_c('i',{staticClass:"icon-left-open-big"})]):_vm._e(),_c('h3',{staticClass:"fs-24 fw-7 mt-1"},[_vm._v(" "+_vm._s(_vm.isModeSurvey ? `${_vm.body.selectedProgram.text} 초기 문진` : "집중관리 프로그램")+" ")])],1),_c('b-btn',{style:({ marginRight: '-15px' }),attrs:{"variant":"link"},on:{"click":function($event){return close()}}},[_c('div',{staticClass:"bg-img",style:({
            backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
            width: '3rem',
            paddingBottom: '3rem',
          })})])],1)]}}])},[_c('div',{staticClass:"px-4 px-md-5"},[(!_vm.isModeSurvey)?_c('management',{attrs:{"modalId":_vm.id},on:{"onSubmit":_vm.onSubmit}}):_c('management-survey',_vm._b({attrs:{"modalId":_vm.id}},'management-survey',{
        ..._vm.body,
      },false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }