import dayjs from "dayjs";

export const formatedDate = (d) => d && dayjs(d).format("YYYY-MM-DD");
export const getYears = () => {
  const year = dayjs().year();
  const startYear = year - 50;
  return Array.from({ length: 100 }, (v, i) => i).map((val, i) => ({
    text: `${startYear + i + 1}`,
    value: `${startYear + i + 1}`,
  }));
};

export const getDates = () => {
  const endOfDate = dayjs()
    .endOf("month")
    .date();
  return Array.from({ length: endOfDate }, (v, i) => i).map((val, i) => ({
    text: `${i + 1}`,
    value: `${i + 1}`,
  }));
};
