import policy from "@/lib/policy";

const type = "kyobokidscare";
export default {
  color: ["#ebd160", "#3667cd", "#ffe46c1a"],
  // logo
  logo: "교보어린이헬스케어서비스",
  call: "1588-4733",
  // gnb
  gnb: [
    {
      id: "service",
      text: "서비스 안내/신청",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "check",
      text: "성장/발달 체크",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "info",
      text: "임신/출산/육아 정보",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "mypage",
      text: "내 서비스 내역",
      isHeaderTab: true,
      isChildren: true,
    },
  ],
  // headerTabs
  headerTabs: {
    service: [
      {
        text: "어린이 건강도서관",
        name: "service",
        key: "library",
        type,
      },
      {
        text: "임신 · 출산 · 육아 집중 관리",
        name: "service",
        key: "care",
        type,
      },
      {
        text: "상담치료지원",
        name: "service",
        key: "counseling",
        type,
      },
      {
        text: "방문치료지원",
        name: "service",
        key: "treatment",
        type,
      },
      {
        text: "가족건강지원",
        name: "service",
        key: "family",
        type,
      },
    ],
    check: [
      {
        text: "아이 성장 체크",
        name: "check-grow",
        key: "grow",
        type,
      },
      {
        text: "아이 발달 체크",
        name: "check-growth",
        key: "growth",
        type,
      },
      {
        text: "예방접종 스케줄",
        name: "check-schedule",
        key: "schedule",
        type,
      },
    ],
    info: [
      {
        text: "건강한 임신",
        name: "info",
        key: "pregnancy",
        query: { tab: "pregnancy-0" },
        type,
      },
      {
        text: "현명한 출산",
        name: "info",
        key: "born",
        query: { tab: "born-0" },
        type,
      },
      {
        text: "행복한 육아",
        name: "info",
        key: "parenting",
        query: { tab: "parenting-0" },
        type,
      },
      {
        text: "영상 콘텐츠",
        name: "info",
        key: "movie",
        query: { tab: "exercise" },
        type,
      },
      {
        text: "기획기사",
        name: "info-news",
        key: "health-news",
        type,
      },
      {
        text: "최신뉴스",
        name: "info-news",
        key: "recent-news",
        type,
      },
    ],
    mypage: [
      {
        text: "전화 상담",
        name: "mypage",
        key: "mycall",
        fields: [
          { label: "회차", key: "number" },
          { label: "제목", key: "title" },
          { label: "작성날짜", key: "date" },
        ],
        type,
      },
      {
        text: "프로그램 신청 내역",
        name: "mypage",
        key: "application",
        fields: [
          { label: "신청 횟수", key: "number" },
          { label: "서비스 명", key: "title" },
          { label: "신청일", key: "startdate" },
          { label: "진행여부", key: "servicestatus" },
          { label: "서비스 중단", key: "stop" },
        ],
        type,
      },
    ],
  },

  // subTab
  subTabs: [
    {
      scroll: false,
      key: "pregnancy",
      items: [
        {
          text: "예비맘 교실",
          value: "pregnancy-0",
          childItems: [
            { text: "임신을 위한 건강상태 점검하기", value: 6065 },
            { text: "계획임신", value: 6066 },
            { text: "임신의 징후", value: 6067 },
            { text: "임신 테스트", value: 6068 },
          ],
        },
        {
          text: "임신40주 출석체크",
          value: "pregnancy-1",
          childItems: [
            {
              text: "엄마의 변화",
              items: [
                { text: "1~4주|1개월", value: 6072 },
                { text: "5~8주|2개월", value: 6075 },
                { text: "9~12주|3개월", value: 6078 },
                { text: "13~16주|4개월", value: 6081 },
                { text: "17~20주|5개월", value: 6084 },
                { text: "21~24주|6개월", value: 6087 },
                { text: "25~28주|7개월", value: 6090 },
                { text: "29~32주|8개월", value: 6093 },
                { text: "33~36주|9개월", value: 6096 },
                { text: "37~40주|10개월", value: 6099 },
              ],
            },
            {
              text: "태아 성장 발달",
              items: [
                { text: "1~4주|1개월", value: 6073 },
                { text: "5~8주|2개월", value: 6076 },
                { text: "9~12주|3개월", value: 6079 },
                { text: "13~16주|4개월", value: 6082 },
                { text: "17~20주|5개월", value: 6085 },
                { text: "21~24주|6개월", value: 6088 },
                { text: "25~28주|7개월", value: 6091 },
                { text: "29~32주|8개월", value: 6094 },
                { text: "33~36주|9개월", value: 6097 },
                { text: "37~40주|10개월", value: 6100 },
              ],
            },
            {
              text: "체크리스트",
              items: [
                { text: "1~4주|1개월", value: 6074 },
                { text: "5~8주|2개월", value: 6077 },
                { text: "9~12주|3개월", value: 6080 },
                { text: "13~16주|4개월", value: 6083 },
                { text: "17~20주|5개월", value: 6086 },
                { text: "21~24주|6개월", value: 6089 },
                { text: "25~28주|7개월", value: 6092 },
                { text: "29~32주|8개월", value: 6095 },
                { text: "33~36주|9개월", value: 6098 },
                { text: "37~40주|10개월", value: 6101 },
              ],
            },
          ],
        },
        {
          text: "순산준비 교실",
          value: "pregnancy-2",
          childItems: [
            {
              text: "입덧",
              items: [
                { text: "입덧은 병일까?", value: 6102 },
                { text: "입덧에 대처하는 방법", value: 6103 },
              ],
            },
            {
              text: "불편한 증상들",
              items: [
                { text: "임신선과 튼살", value: 6104 },
                { text: "허리 통증", value: 6105 },
                { text: "다리에 쥐가 남", value: 6106 },
                { text: "빈뇨", value: 6107 },
                { text: "변비", value: 6108 },
                { text: "빈혈", value: 6109 },
                { text: "병원에 가야 하는 이상증상", value: 6110 },
              ],
            },
            {
              text: "예비맘의 성생활",
              items: [
                { text: "안전한 체위", value: 6111 },
                { text: "꼭 알아두세요!", value: 6112 },
                { text: "성관계를 제한해야 하는 경우", value: 6113 },
              ],
            },
          ],
        },
        {
          text: "임신트러블 교실",
          value: "pregnancy-3",
          childItems: [
            {
              text: "유산",
              items: [
                { text: "유산이란", value: 6114 },
                { text: "유산의 징후와 대처법", value: 6115 },
              ],
            },
            {
              text: "조산 VS 지연임신",
              items: [
                { text: "조산의 원인", value: 6116 },
                { text: "조산에 대처하는 방법", value: 6117 },
                { text: "예정일을 넘긴 아기", value: 6118 },
              ],
            },
            {
              text: "주의해야 할 임신 트러블",
              items: [
                { text: "임신성 고혈압(임신중독증)", value: 6119 },
                { text: "전치태반", value: 6120 },
                { text: "태반조기박리", value: 6121 },
                { text: "양수과다증과 양수과소증", value: 6122 },
                { text: "조기파수", value: 6123 },
                { text: "자궁경관무력증", value: 6124 },
              ],
            },
          ],
        },
      ],
    },
    {
      scroll: false,
      key: "born",
      items: [
        {
          text: "출산교실",
          value: "born-0",
          childItems: [
            {
              text: "출산 알아보기",
              items: [
                { text: "이슬과 양막파열이란?", value: 6125 },
                { text: "진통에 대처하는 방법", value: 6126 },
                { text: "올바른 힘주기", value: 6127 },
                { text: "분만 중의 돌발상황", value: 6128 },
                { text: "회음절개란?", value: 6129 },
              ],
            },
            {
              text: "출산의 여러방법",
              items: [
                { text: "자연 분만", value: 6130 },
                { text: "라마즈 분만", value: 6131 },
                { text: "무통 분만", value: 6132 },
                { text: "제왕절개 분만", value: 6133 },
                { text: "가족분만", value: 6134 },
              ],
            },
            {
              text: "출산과정 알아보기",
              items: [
                { text: "제 1기 개구기", value: 6135 },
                { text: "제 2기 만출기", value: 6136 },
                { text: "제 3기 후산기", value: 6137 },
              ],
            },
          ],
        },
        {
          text: "산후관리 교실",
          value: "born-1",
          childItems: [
            {
              text: "출산 직후의 관리",
              items: [
                { text: "자연분만을 한 경우", value: 6138 },
                { text: "제왕절개를 한 경우", value: 6139 },
                { text: "훗배앓이와 회음통증", value: 6140 },
                { text: "산모의 첫 소변과 대변", value: 6141 },
                { text: "젖몸살에 대처하는 방법", value: 6142 },
              ],
            },
            {
              text: "8주간의 산후조리",
              items: [
                { text: "산후조리 1주", value: 6143 },
                { text: "산후조리 2주", value: 6144 },
                { text: "산후조리 3주", value: 6145 },
                { text: "산후조리 4주", value: 6146 },
                { text: "산후조리 5~8주", value: 6147 },
              ],
            },
          ],
        },
      ],
    },
    {
      scroll: false,
      key: "parenting",
      items: [
        {
          text: "모유수유교실",
          value: "parenting-0",
          childItems: [
            {
              text: "모유수유 알아보기",
              items: [
                { text: "모유수유의 장점", value: 6148 },
                { text: "모유수유에 필요한 물품", value: 6149 },
                { text: "모유수유를 할 수 없는 경우", value: 6150 },
              ],
            },
            {
              text: "실전! 모유수유",
              items: [
                { text: "유방관리방법", value: 6151 },
                { text: "모유수유이렇게 시작하세요", value: 6152 },
                { text: "초유먹이기", value: 6153 },
              ],
            },
          ],
        },
        {
          text: "이유식 교실",
          value: "parenting-1",
          childItems: [
            {
              text: "이유식 알아보기",
              items: [
                { text: "이유식이란?", value: 6154 },
                { text: "이유식이 중요한 이유", value: 6155 },
                { text: "이유식의 모든 것(Q/ A) ", value: 6156 },
              ],
            },
            {
              text: "실전! 이유식 하기",
              items: [
                { text: "초기 이유식", value: 6157 },
                { text: "중기 이유식", value: 6158 },
                { text: "후기 이유식 및 이유식 완료기", value: 6159 },
              ],
            },
          ],
        },
        {
          text: "아기 발달 출석체크",
          value: "parenting-2",
          childItems: [
            {
              text: "아기의 발달특징",
              items: [
                { text: "생후 1개월의 아기", value: 6160 },
                { text: "생후 2개월의 아기", value: 6161 },
                { text: "생후 3개월의 아기", value: 6162 },
                { text: "생후 4개월의 아기", value: 6163 },
                { text: "생후 5개월의 아기", value: 6164 },
                { text: "생후 6개월의 아기", value: 6165 },
                { text: "생후 7개월의 아기", value: 6166 },
                { text: "생후 8개월의 아기", value: 6167 },
                { text: "생후 9개월의 아기", value: 6168 },
                { text: "생후 10개월의 아기", value: 6169 },
                { text: "생후 11개월의 아기", value: 6170 },
                { text: "생후 12개월의 아기", value: 6171 },
              ],
            },
            {
              text: "영아의 발달특징",
              items: [
                { text: "14개월 아기의 발달특징", value: 6172 },
                { text: "18개월 아기의 발달특징", value: 6173 },
                { text: "24개월 아기의 발달특징", value: 6174 },
                { text: "36개월 아기의 발달특징", value: 6175 },
                { text: "4세 아동의 발달특징", value: 6176 },
                { text: "5세 아동의 발달특징", value: 6177 },
                { text: "6세 아동의 발달특징", value: 6178 },
              ],
            },
          ],
        },
        {
          text: "신생아 교실",
          value: "parenting-3",
          childItems: [
            {
              text: "예방접종",
              items: [
                {
                  text: "예방접종 스케줄(표)",
                },
                {
                  text: "일반적인 예방접종 주의 사항 이것만은 알아두자!",
                  value: 6180,
                },
                { text: "MMR 예방접종 시기 및 주의 사항", value: 6181 },
                { text: "DTaP 예방접종 시기 및 주의 사항", value: 6182 },
                { text: "B형 간염 예방접종 시기 및 주의 사항", value: 6183 },
                { text: "BCG 예방접종 시기 및 주의 사항", value: 6184 },
                { text: "수두 예방접종 시기 및 주의 사항", value: 6185 },
                { text: "일본뇌염 예방접종 방법 및 주의 사항", value: 6186 },
                { text: "독감예방접종 및 주의사항", value: 6187 },
                { text: "소아마비예방접종 시기 및 주의 사항", value: 6188 },
              ],
            },
            {
              text: "실전! 아기 돌보기",
              items: [
                { text: "대소변 가리기", value: 6189 },
                { text: "트림 시키기", value: 6190 },
                { text: "목욕시키기", value: 6191 },
                { text: "기저귀 갈아주기", value: 6192 },
                { text: "체온재기", value: 6193 },
              ],
            },
          ],
        },
        {
          text: "아기질병 교실",
          value: "parenting-4",
          childItems: [
            {
              text: "아기질병",
              items: [
                { text: "감기", value: 6194 },
                { text: "설사", value: 6195 },
                { text: "아토피", value: 6196 },
                { text: "열성경련", value: 6197 },
                { text: "구토", value: 6198 },
                { text: "땀띠", value: 6199 },
                { text: "기저귀 발진", value: 6200 },
                { text: "장중첩증", value: 6201 },
              ],
            },
            {
              text: "유행성 질환",
              items: [
                { text: "수두", value: 6202 },
                { text: "일본뇌염", value: 6203 },
                { text: "풍진", value: 6204 },
                { text: "성홍열", value: 6205 },
                { text: "볼거리", value: 6206 },
                { text: "수족구병", value: 6207 },
              ],
            },
          ],
        },
        {
          text: "튼튼한 치아 건강",
          value: "parenting-5",
          childItems: [
            {
              text: "아이 치아 관리",
              items: [
                { text: "아이의 치아 상태 체크하기", value: 7341 },
                { text: "아이의 첫 치아, 유치 바로 알기", value: 7342 },
                { text: "아이의 구강습관", value: 7343 },
                { text: "수유와 치아건강", value: 7344 },
                { text: "치아발달과 이유식", value: 7345 },
                { text: "아이 연령별 치아발달", value: 7347 },
                {
                  text: "이가 나기 시작한 우리 아이, 구강검진 시기는?",
                  value: 7348,
                },
                { text: "충치 예방하기", value: 7349 },
                { text: "알아두면 유용한 외상 시 응급처치법", value: 7367 },
              ],
            },
            {
              text: "시기별 치아관리",
              items: [
                { text: "0~6개월: 이가 아직 안 났을 때", value: 7351 },
                { text: "6~15개월: 앞니가 나기 시작했을 때", value: 7352 },
                {
                  text: "15~24개월: 첫째 어금니와 송곳니가 나기 시작했을 때",
                  value: 7353,
                },
                {
                  text: "24~36개월: 둘째 어금니가 나기 시작했을 때",
                  value: 7354,
                },
                { text: "만 3~6세: 유치열기, 치아 관리방법은?", value: 7355 },
                {
                  text: "만 6-12세: 유치는 빠지고 영구치가 올라오는 혼합치열기, 치아 관리 방법은?",
                  value: 7356,
                },
                {
                  text: "만 13세 이후: 평생 사용할 영구치 관리법",
                  value: 7357,
                },
              ],
            },
            {
              text: "치과 질환",
              items: [
                { text: "충치 진단과 치료", value: 7358 },
                { text: "영구치 교환을 방해하는 치아 맹출장애", value: 7359 },
                { text: "올바른 구강근 습관들이기", value: 7360 },
                { text: "교정 치료, 언제 시작해야 할까?", value: 7361 },
              ],
            },
          ],
        },
        {
          text: "반짝반짝 눈 건강",
          value: "parenting-6",
          childItems: [
            {
              text: "아이 눈 건강",
              items: [
                { text: "아이 눈 건강 체크, 시력발달 가이드", value: 7362 },
                { text: "어린이 안과 질환", value: 7363 },
                { text: "눈을 건강하게 하는 관리 요령", value: 7364 },
                { text: "눈에 좋은 음식 vs 해로운 음식", value: 7365 },
                {
                  text: "시력을 보호하고 피로를 풀어주는 하루 10분 눈 운동",
                  value: 7366,
                },
                { text: "알아두면 유용한 외상 시 응급처치법", value: 7350 },
              ],
            },
            {
              text: "아이 눈 궁금증",
              items: [
                {
                  text: "아이가 어떤 증상을 보이면 안질환을 의심해야 하나요?",
                  value: 7368,
                },
                { text: "아이가 눈을 자주 찡그려요.", value: 7369 },
                { text: "자주 눈이 가렵다는 아이, 왜 그럴까요?", value: 7370 },
                {
                  text: "고개가 기울어 진 거 같아요, 사시일까요?",
                  value: 7371,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      scroll: false,
      key: "movie",
      items: [
        { text: "운동 동영상", value: "exercise", page: 12 },
        { text: "육아 동영상", value: "baby", page: 11 },
        { text: "미디어 백과", value: "media", page: 14 },
        { text: "건강 이유식", value: "food", page: 12 },
      ],
    },
    {
      scroll: true,
      key: "music",
      items: ["스트레스", "기분전환", "우울", "불면증", "행복"],
    },
    {
      scroll: true,
      key: "psychological",
      items: ["숙면", "숲속", "일상 속", "비 오는 날", "바다"],
    },
  ],

  // 내 서비스 내역
  myServices: [
    {
      title: "전화 상담",
      name: "mypage",
      params: { key: "mycall", type },
    },
    {
      title: "프로그램 신청 내역",
      name: "mypage",
      params: { key: "application", type },
    },
  ],

  // --- home
  visual: [
    {
      title: "어린이 건강도서관",
      subTitle: ["우리아이 치아 · 눈 건강의 모든 것!", "한 곳에서 찾는 건강&육아 정보"],
      to: "service",
      key: "library",
    },
    {
      title: "임신 · 출산 · 육아 집중 관리",
      subTitle: [`엄마와 아이에게 맞춘<br class="d-md-none"/> 시기별 · 테마별 17가지 프로그램`],
      to: "service",
      key: "care",
      hash: "#sub-tab-1",
    },
    {
      title: "상담치료지원",
      subTitle: [`전화 한 통화로<br class="d-md-none"/> 사소한 궁금증 해결부터 병원예약까지`],
      to: "service",
      key: "counseling",
      hash: "#sub-tab-2",
    },
    {
      title: "방문치료지원",
      subTitle: [`미숙아 저체중아는 물론<br class="d-md-none"/> 영유아 주요질환까지 전담간호사의 방문상담`],
      to: "service",
      key: "treatment",
      hash: "#sub-tab-3",
    },
    {
      title: "가족건강지원",
      subTitle: [`우리 가족의 심리안정부터<br class="d-md-none"/> 건강증진까지 도와드립니다.`],
      to: "service",
      key: "family",
    },
  ],
  healthCheck: {
    title: "성장/발달 체크",
    subTitle: "우리 아이가 정상적으로 잘 성장하고 있는지 확인해 보세요.",
    items: [
      {
        text: "아이 성장 체크",
        name: "check-grow",
        color: "#cfe2f6",
        key: "grow",
      },
      {
        text: "아이 발달 체크",
        color: "#c2f1d5",
        name: "check-growth",
        key: "growth",
      },
      {
        text: "예방접종 스케줄",
        color: "#ddf1c2",
        name: "check-schedule",
        key: "schedule",
      },
    ],
  },

  healthInfo: {
    title: "임신/출산/육아 정보",
    subTitle: "엄마 상태에 따른 다양한 정보를 받아보세요.",
    items: [
      {
        text: "건강한 임신",
        name: "info",
        key: "pregnancy",
        query: { tab: "pregnancy-0" },
      },
      {
        text: "현명한 출산",
        name: "info",
        key: "born",
        query: { tab: "born-0" },
      },
      {
        text: "행복한 육아",
        name: "info",
        key: "parenting",
        query: { tab: "parenting-0" },
      },
      {
        text: "영상 콘텐츠",
        name: "info",
        key: "movie",
        query: { tab: "exercise" },
      },
      {
        text: "기획기사",
        name: "info-news",
        key: "health-news",
      },
      {
        text: "최신뉴스",
        name: "info-news",
        key: "recent-news",
      },
    ],
  },

  // --- 서비스 안내
  library: [
    {
      title: "어린이 건강도서관",
      subTitle: "우리아이 치아 · 눈 건강의 모든 것! 한 곳에서 찾는 건강&육아 정보",
      contents: [
        {
          title: "반짝반짝 눈 건강 / 튼튼이 치아건강",
          texts: ["아이 눈 · 치아 건강을 위한 전문 콘텐츠 제공"],
        },
        {
          title: "건강&육아 정보",
          texts: [
            "- 회원전용 홈페이지를 통해 출산 및 육아 관련 정보 제공",
            "- 참여 후 결과에 따라 담당 간호사 정기 코칭 콜 및 개별맞춤 E-Mail 제공",
            "- 다양한 콘텐츠 제공 : 이유식 레시피, 가족 여행, 육아박람회, 아이 교육 등",
          ],
        },
        {
          title: "건강 알리미&태담 서비스 (알림톡)",
          texts: [
            "- 주1회 : 임신주수별 신체변화 등 산모 맞춤 정보 및 태아에게 들려주면 좋은 태담 테마 제공",
            "- 월1회 : 출산 후 육아법. 예방접종, 아이의 성장발달 등 맞춤 정보 제공",
          ],
          button: { text: "신청하러 가기", modalId: "modal-health-reminder" },
        },
      ],
    },
  ],
  care: [
    {
      title: "임신 · 출산 · 육아 집중 관리",
      subTitle: "엄마와 아이에게 맞춘 시기별 · 테마별 17가지 프로그램",
      contents: [
        {
          title: "집중관리 프로그램",
          texts: [
            "For Mom",
            "엄마를 위한 예비맘과 출산맘 8가지 프로그램",
            "- 예비맘(필수체크! 예비맘의 건강체크 등) ",
            "- 출산맘(건강한 모유수유 바로 알기 등)",
            "<br/>",
            "For baby",
            "아이를 위한 건강과 성장 8가지 프로그램",
            "- 똑똑건강(유행성 질환 대처하기 등) ",
            "- 쑥쑥성장(초보 육아 따라하기 등) ",
            "<br/>",
            "For Mom & baby",
            "엄마와 아이가 함께하는 임산부 홈트레이닝 프로그램",
            "- 틈틈 스트레칭, 건강한 D라인 만들기 등 태아와 임산부 건강관리를 위한 운동 프로그램 제공(동영상)",
          ],
          button: { text: "신청하러 가기", modalId: "modal-management" },
        },
        {
          texts: [
            "출산 지원 컨설팅",
            "- 임신 기간 동안 생활습관 개선, 임신트러블 등 정보 제공 및 1:1 컨설팅",
            "- 이상소견에 대한 전문의료진 상담 및 영양, 운동 등 건강관리 상담",
          ],
        },
      ],
    },
  ],
  counseling: [
    {
      title: "상담치료지원",
      subTitle: "전화 한 통화로 사소한 궁금증 해결부터 병원예약까지",
      contents: [
        {
          title: "건강상담",
          texts: ["간호사 상담접수 후 산부인과 · 소아과 · 치과 · 안과 등 13개과 진료과목 전문의 · 영양사 · 운동처방사 전화 상담"],
        },
        {
          title: "병원 ·  의료진 안내 ",
          texts: ["질병 진료 및 치료를 위한 병원 · 의료진 안내 및 진료 예약대행"],
          button: { text: "신청하러 가기", modalId: "modal-counseling" },
        },
      ],
    },
  ],
  treatment: [
    {
      title: "방문치료지원",
      subTitle: "미숙아 저체중아는 물론 영유아 주요질환까지 전담간호사의 방문상담",
      contents: [
        {
          title: "미숙아, 저체중아 방문지원",
          texts: [
            "미숙아, 저체중아 출생 시 신생아 전담간호사 방문하여 아이 돌봄 교육 제공(2회)",
            "※ 미숙아 : 임신 주수 37주 미만 & 출생체중 2.5kg 이하 / 저체중아 : 출생체중 2.5kg 이하",
          ],
        },

        {
          buttonTitle: {
            title: "중대질환 방문지원",
            buttonTitle: "기본서비스",
            buttonType: 'default'
          },
          texts: [
            "아이의 중대질환 발생 시 전담간호사 지정, 방문상담 교육 등의 방문지원(최초 진단 시 최대 4회, 단 소아당뇨는 최대 2회)",
            "※ 중대질환 : 암/소아당뇨/뇌종양/악성림프종/소아뇌졸중/소아마비/만성 소아 신부전증",
          ],
          button: { text: "신청하러 가기", modalId: "modal-counseling" },
        },
        {
          buttonTitle: {
            title: "3가지 中 2회 한도 內 이용(소아당뇨는 이용불가)",
            buttonTitle: "선택서비스",
            buttonType: ''
          },
          texts: [
            "중대질환(암, 뇌종양, 악성림프종, 소아뇌졸중, 소아마비, 만성신부전증) 진단 후 아래 3가지 서비스 중에서 서비스 이용 횟수 합산 최대 2회까지 이용가능 ",
            "※ 헬스홈케어서비스의 경우 다른 서비스 먼저 이용 시 서비스 이용이 제한될 수 있음"
          ],
        },
        {
          title: '헬스홈케어서비스(거실, 방1곳, 욕실1곳)',
          texts: [
            "아이의 중대질환 발생 시 생활공간(주거환경) 살균 소독 및 청소 대행 서비스 제공",
            "※ 서비스 신청 시 선택 서비스 합산 횟수에서 2회 차감"
          ],
        },
        {
          title: '차량 에스코트(편도 1회)',
          texts: [
            "치료 목적의 병원 이동 또는 퇴원 시 목적지까지 차량 지원",
            "※ 입·퇴원 1일 전 신청 필요(동일권역 50km 이내 이동)"
          ],
        },
        {
          title: '가사도우미 지원(8시간/1회)',
          texts: [
            "아이의 중대질환 치료 시 가사도우미 지원",
            "※ 방문 희망일 최소 3일 전 신청"
          ],
        }
      ],
    },
  ],
  family: [
    {
      title: "가족건강지원",
      subTitle: "우리 가족의 심리안정부터 건강증진까지 도와드립니다.",
      contents: [
        {
          title: "심리상담",
          texts: [
            "임신, 출산, 육아 과정에서 겪을 수 있는 다양한 스트레스와 고충에 대해 전문 심리상담사와 전화상담 제공",
            "<br/>",
            "For Mom",
            "임신 후 우울증 / 산후 우울증",
            "양육 스트레스 / 수면장애 등",
            "<br/>",
            "For baby",
            " - 아동 ADHD / 틱장애",
            " - 학습 · 발달장애 / 아이 성장교육 등",
            "<br/>",
            "※ 대면상담 필요 시 국내 유명 심리상담센터와 연계, 우대혜택 제공 (고객실비 부담)",
            "※ 어린이헬스콜센터(1588-4733)를 통해서 신청이 가능합니다.",
          ],
        },
        {
          title: "가족 건강상담",
          texts: [
            "가족 건강상담 및 제휴협력을 통한 건강검진 우대(고객실비 부담)",
            "맞춤형 건감검진 패키지 설계 안내※ 여성 : 자궁경부암 및 유방암, 갑상선 검사 등 / 남성 : 전립선암, 폐검사 등",
            "- 건강검진 후 유소견에 대해 가족 건강 컨설팅 및 건강 교육자료 제공",
          ],
          button: {
            text: "전국 협력병원",
            modalId: "modal-hospitals-compare",
          },
        },
        {
          tite: "맞춤형 식단 컨설팅",
          texts: [
            "- 임신 중 식습관 개선/영양관리 위한 전문영양사 상담 및 맞춤 식단 컨설팅",
            "- 산모 상황별 영약관리, 임신성 당뇨 등으로 치료 병행 시 식이관리 방법 등 다양한 상황에 따른 맞춤 영양 컨설팅 (3일~1주일 분량의 식단표 제공) ",
          ],
        },
      ],
    },
  ],

  // --- 건강체크
  growth: [
    {
      id: "growth",
      category: "아이 발달 체크",
    },
  ],

  // 약관
  consent: {
    library: {
      privacy: [
        {
          purpose: "건강 알리미&태담 서비스",
          item: "이름, 자녀이름, 휴대폰번호, 자녀생일",
          period: "위탁종료 시 또는 실효 후 5년 경과시",
        },
      ],
    },
    care: {
      privacy: [
        {
          purpose: "초보엄마 육아관리 서비스",
          item: "자녀성함, 휴대폰번호, 이메일, 선택한 프로그램",
          period: "위탁종료 시 또는 실효 후 5년 경과시",
        },
      ],
    },
    counseling: {
      privacy: [
        {
          purpose: "상담치료 지원서비스",
          item: "자녀성함, 성함, 휴대폰번호 또는 전화번호, 신청내용, 방문희망병원, 주소, 이메일, 진단명, 방문희망일",
          period: "위탁종료 시 또는 실효 후 5년 경과시",
        },
      ],
      sensitive: [
        {
          purpose: "상담치료 지원서비스",
          item: "진단명",
          period: "위탁종료 시 또는 실효 후 5년 경과시",
        },
      ],
    },
    treatment: {
      privacy: [
        {
          purpose: "방문치료지원서비스",
          item: "자녀성함, 성함, 휴대폰번호 또는 전화번호, 신청내용, 방문희망병원, 주소, 이메일, 진단명, 방문희망일",
          period: "위탁종료 시 또는 실효 후 5년 경과시",
        },
      ],
      sensitive: [
        {
          purpose: "방문치료지원서비스",
          item: "진단명",
          period: "위탁종료 시 또는 실효 후 5년 경과시",
        },
      ],
    },
    schedule: {
      privacy: [
        {
          purpose: "예방접종 스케줄 관리",
          item: "자녀(이름, 생년월일, 예방접종일)",
          period: "위탁종료 시 또는 실효 후 5년 경과시",
        },
      ],
    },
    grow: {
      privacy: [
        {
          purpose: "우리아이 성장차트 관리",
          item: "자녀성함, 키, 몸무게, 측정일, 아이성장 평가 결과",
          period: "위탁종료 시 또는 실효 후 5년 경과시",
        },
      ],
    },
  },

  // 프로그램 코드
  questionsOptions: [
    {
      type: "예비맘",
      items: [
        { value: "KM0501001", text: "임신부터 출산까지 바로 알기" },
        { value: "KM0501002", text: "필수체크! 예비맘의 건강체크" },
        { value: "KM0501003", text: "예비맘 건강, 이렇게 관리하자" },
        { value: "KM0501004", text: "아름다운 D라인" },
      ],
    },
    {
      type: "출산맘",
      items: [
        { value: "KM0502001", text: "출산 후 S라인 만들기" },
        { value: "KM0502002", text: "출산맘 건강, 이렇게 관리하자" },
        { value: "KM0502003", text: "건강한 모유수유 바로 알기" },
        { value: "KM0502004", text: "마음 속 건강부터 다스리자" },
      ],
    },
    {
      type: "똑똑한 건강",
      items: [
        { value: "KM0503001", text: "알아두면 좋은 시기별 건강관리" },
        { value: "KM0503002", text: "우리아이 피부관리 알아두기" },
        { value: "KM0503005", text: "유행성 질환 대처하기" },
        { value: "KM0503006", text: "아이를 위해 특화시킨 안과, 치과" },
      ],
    },
    {
      type: "쑥쑥 성장",
      items: [
        { value: "KM0504001", text: "초보 육아 따라하기" },
        { value: "KM0504002", text: "상황별 아이 마음 헤아리기" },
        { value: "KM0504003", text: "부모와 함께하는 오감놀이" },
        { value: "KM0504004", text: "우리아이 건강 밥상 준비하기" },
      ],
    },
    {
      type: "임산부 홈트레이닝",
      items: [
        {
          value: "HC07A001",
          text: "태아와 임산부 건강관리를 위한 운동",
        },
      ],
    },
  ],
  // 프로그램 신청시 초기 문진 룰
  rules: {
    KM0501001: [
      {
        current: "1-1",
        rules: [(findItemCb, parentNum = "1") => findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "5-1",
        rules: [(findItemCb, parentNum = "5") => !findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "9-1",
        rules: [(findItemCb, parentNum = "9") => !findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
    ],
    KM0501002: [
      {
        current: "2",
        rules: [(findItemCb, parentNum = "1") => !findItemCb(parentNum).selectedAnswers?.includes("25")],
      },
      {
        current: "2-1",
        rules: [
          (findItemCb, parentNum = "1") => !findItemCb(parentNum).selectedAnswers?.includes("25"),
          (findItemCb, parentNum = "2") => !findItemCb(parentNum).selectedAnswers?.includes("1"),
        ],
      },
      {
        current: "3",
        rules: [
          (findItemCb, parentNum = "1") => !findItemCb(parentNum).selectedAnswers?.includes("25"),
          (findItemCb, parentNum = "2") => !findItemCb(parentNum).selectedAnswers?.includes("1"),
        ],
      },
      {
        current: "4",
        rules: [(findItemCb, parentNum = "1") => !findItemCb(parentNum).selectedAnswers?.includes("25")],
      },
      {
        current: "5",
        rules: [
          (findItemCb, parentNum = "1") => !findItemCb(parentNum).selectedAnswers?.includes("25"),
          (findItemCb, parentNum = "4") => !findItemCb(parentNum).selectedAnswers?.includes("0"),
        ],
      },
      {
        current: "8-1",
        rules: [(findItemCb, parentNum = "8") => !findItemCb(parentNum).selectedAnswers?.includes("2")],
      },
      {
        current: "9-1",
        rules: [(findItemCb, parentNum = "9") => !findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "10-1",
        rules: [(findItemCb, parentNum = "10") => !findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
    ],
    KM0501003: [
      {
        current: "2",
        rules: [(findItemCb, parentNum = "1") => findItemCb(parentNum).selectedAnswers?.includes("4")],
      },
      {
        current: "3",
        rules: [(findItemCb, parentNum = "1") => findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "10",
        rules: [(findItemCb, parentNum = "9") => findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
    ],
    KM0501004: [
      {
        current: "8",
        rules: [(findItemCb, parentNum = "7") => findItemCb(parentNum).selectedAnswers?.includes("r")],
      },
      {
        current: "9",
        rules: [(findItemCb, parentNum = "7") => !findItemCb(parentNum).selectedAnswers?.includes("r")],
      },
      {
        current: "10",
        rules: [
          (findItemCb, parentNum = "7") => !findItemCb(parentNum).selectedAnswers?.includes("r"),
          (findItemCb, parentNum = "8") => findItemCb(parentNum).selectedAnswers?.includes("g"),
        ],
      },
      {
        current: "11",
        rules: [
          (findItemCb, parentNum = "8") => !findItemCb(parentNum).selectedAnswers?.includes("r"),
          (findItemCb, parentNum = "8") => findItemCb(parentNum).selectedAnswers?.includes("g"),
        ],
      },
      {
        current: "12",
        rules: [(findItemCb, parentNum = "8") => !findItemCb(parentNum).selectedAnswers?.includes("r")],
      },
    ],
    KM0502001: [
      {
        current: "8",
        rules: [(findItemCb, parentNum = "4") => findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "9",
        rules: [(findItemCb, parentNum = "4") => findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "10",
        rules: [(findItemCb, parentNum = "4") => findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "11",
        rules: [(findItemCb, parentNum = "4") => !findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "12",
        rules: [(findItemCb, parentNum = "4") => !findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "13",
        rules: [(findItemCb, parentNum = "4") => !findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "14",
        rules: [(findItemCb, parentNum = "4") => !findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
      {
        current: "15",
        rules: [(findItemCb, parentNum = "4") => !findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
    ],
    KM0502003: [
      {
        current: "3",
        rules: [(findItemCb, parentNum = "2") => findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
      {
        current: "4",
        rules: [(findItemCb, parentNum = "2") => !findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
      {
        current: "7",
        rules: [(findItemCb, parentNum = "6") => !findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
      {
        current: "9",
        rules: [(findItemCb, parentNum = "8") => findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
      {
        current: "13",
        rules: [(findItemCb, parentNum = "12") => findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
      {
        current: "15",
        rules: [(findItemCb, parentNum = "14") => findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
      {
        current: "16",
        rules: [(findItemCb, parentNum = "14") => !findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
    ],
    KM0503001: [
      {
        current: "3",
        rules: [(findItemCb, parentNum = "2") => findItemCb(parentNum).selectedAnswers?.includes("1")],
      },
    ],
    KM0503002: [
      {
        current: "3",
        rules: [(findItemCb, parentNum = "2") => findItemCb(parentNum).selectedAnswers?.includes("-1")],
      },
      {
        current: "8",
        rules: [(findItemCb, parentNum = "7") => findItemCb(parentNum).selectedAnswers?.includes("0")],
      },
      {
        current: "17",
        rules: [(findItemCb, parentNum = "16") => findItemCb(parentNum).selectedAnswers?.includes("3")],
      },
    ],
  },
  // 프로그램 신청시 초기문진의 타이틀 룰
  titleRules: {
    KM0501001: [
      {
        current: "11-1",
        parent: "11",
        rule: ({ selectedAnswers, answers }) => {
          const selectedValue = selectedAnswers.filter((val) => val);
          return answers.find((answer) => answer.value === selectedValue[0])?.answertitle;
        },
      },
    ],
  },

  // 프로그램 신청시 초기 문진
  questionsObj: {
    KM0501001: {
      title: "임신부터 출산까지 바로 알기",
      questions: [
        {
          number: "1",
          target: "a1",
          questiontitle: "현재 몇째 아이의 출산예정인가요",
          answers: [
            { answertitle: "첫째", value: "0" },
            { answertitle: "둘째 이상", value: "1" },
          ],
        },
        {
          number: "1-1",
          target: "a2",
          questiontitle: "이전에 어떤 출산의 경험이 있으신가요?",
          answers: [
            { answertitle: "긴급 출산", value: "0" },
            { answertitle: "유산", value: "1" },
            { answertitle: "재왕절개", value: "2" },
            { answertitle: "정상분만", value: "3" },
          ],
        },
        {
          number: "2",
          questiontitle: "현재 임신 몇 주 이신가요?",
          answersType: "selectbox",
          target: "a3",
          answers: (() => {
            return Array.from({ length: 40 }, (v, i) => i).map((val, i) => ({
              answertitle: `${i + 1}주`,
              value: i,
            }));
          })(),
        },
        {
          number: "2-1",
          target: "a4",
          questiontitle: "현재 입덧은 어떠하신가요?",
          answersType: "checkbox",
          answers: [
            { answertitle: "전혀 느끼지 못한다.", value: "0" },
            { answertitle: "약간 느끼나 못 먹을 정도는 아니다.", value: "1" },
            { answertitle: "심하나 억지로 먹고 있다.", value: "2" },
            { answertitle: "전혀 먹지 못하고 있다.", value: "3" },
          ],
        },
        {
          number: "3",
          target: "a5",
          questiontitle: "출산 예정일은 언제인가요?",
          answersType: "input",
          answers: [
            {
              answertitle: "출생일",
              inputType: "date",
              name: "date",
            },
          ],
        },
        {
          number: "4",
          target: "a6",
          questiontitle: "고객님의 나이는 어느 구간에 있습니까?",
          answers: [
            {
              answertitle: "만35세 미만",
              value: "0",
            },
            {
              answertitle: "만35세 이상",
              value: "A",
            },
          ],
        },
        {
          number: "5",
          target: "a7",
          questiontitle: "병원에서 양수검사를 받으셨거나 받을 계획인가요?",
          answers: [
            {
              answertitle: "예",
              value: "0",
            },
            {
              answertitle: "아니요",
              value: "1",
            },
          ],
        },

        {
          number: "5-1",
          target: "a8",
          questiontitle: "양수검사에 대한 두려움이나 걱정이 무엇인가요?",
          answers: [
            {
              answertitle: "기형아라는 판단이 나올까봐",
              value: "1",
            },
            {
              answertitle: "아기가 잘못 될까봐",
              value: "2",
            },
            {
              answertitle: "검사비용에 대한 부담",
              value: "3",
            },
            {
              answertitle: "없다.",
              value: "4",
            },
          ],
        },
        {
          number: "6",
          target: "a9",
          questiontitle: "임신 전 키/몸무게를 입력하세요.",
          answersType: "input",
          answers: [
            {
              answertitle: "키",
              name: "height",
              inputType: "number",
              text: "cm",
            },
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
        },
        {
          number: "6-1",
          target: "a10",
          questiontitle: "현재 임신 후 몇 kg가 증가하셨나요?",
          answersType: "input",
          answers: [
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
        },
        {
          number: "7",
          target: "a11",
          questiontitle: "현재 병원 산전관리에서 이상소견을 들은바 있습니까?",
          answers: [
            {
              answertitle: "단백뇨",
              value: "1",
            },
            {
              answertitle: "임신성 당뇨",
              value: "2",
            },
            {
              answertitle: "임신성 고혈압",
              value: "3",
            },
            {
              answertitle: "기타(자간전증 등)",
              value: "4",
            },
            {
              answertitle: "없음",
              value: "5",
            },
          ],
        },
        {
          number: "8",
          target: "a12",
          questiontitle: "임신 전과 건강한 임신 유지를 위해 주사 맞는 예방접종은 무엇인가요?",
          answers: [
            {
              answertitle: "없다",
              value: "0",
            },
            {
              answertitle: "풍진(임신 전)",
              value: "1",
            },
            {
              answertitle: "독감(인플루엔자) 예방접종",
              value: "2",
            },
            {
              answertitle: "기타",
              value: "0",
            },
          ],
        },
        {
          number: "9",
          target: "a13",
          questiontitle: "충분한 숙면을 취하고 계시나요?",
          answers: [
            {
              answertitle: "예",
              value: "0",
            },
            {
              answertitle: "아니요",
              value: "1",
            },
          ],
        },
        {
          number: "9-1",
          target: "a14",
          questiontitle: "숙면을 취하지 못하는 이유는 무엇인가요?",
          answers: [
            {
              answertitle: "배가 나와 수면 자세가 불편해서",
              value: "1",
            },
            {
              answertitle: "스트레스",
              value: "2",
            },
            {
              answertitle: "출산에 대한 불안으로 자꾸 꿈을 꾸어서",
              value: "3",
            },
            {
              answertitle: "기타",
              value: "0",
            },
          ],
        },
        {
          number: "10",
          target: "a15",
          answersType: "checkbox",
          questiontitle: "평소 생활습관은 어떻습니까?",
          answers: [
            {
              answertitle: "외식을 자주(주 3회 이상) 하는 편이다.",
              value: "o",
            },
            {
              answertitle: "야채를 자주(주 3회 이상) 먹는다.",
              value: "ㄷ",
            },
            {
              answertitle: "고기와 육류를 즐겨 먹는다.",
              value: "ㄹ",
            },
            {
              answertitle: "커피를 하루 2잔 이상 먹는다.",
              value: "ㅈ",
            },
            {
              answertitle: "해당사항없음",
              value: "0",
            },
          ],
        },
        {
          number: "11",
          target: "a16",
          questiontitle: "임신기간 중 옆에서 주로 도와주실 분은 누구인가요?",
          answers: [
            {
              answertitle: "남편",
              value: "1",
            },
            {
              answertitle: "시어머니/친정 어머니",
              value: "2",
            },
            {
              answertitle: "민간 고용의 형태",
              value: "3",
            },
          ],
        },
        {
          number: "11-1",
          target: "a17",
          questiontitle: "께서 옆에서 편안하게 잘 도와주나요?",
          answers: [
            {
              answertitle: "예",
              value: "0",
            },
            {
              answertitle: "아니요",
              value: "1",
            },
          ],
        },
        {
          number: "12",
          target: "a18",
          questiontitle: "건강한 출산을 위해 하고 계신 운동이 있나요?",
          answers: [
            {
              answertitle: "(가볍게)걷기",
              value: "1",
            },
            {
              answertitle: "(산부인과, 보건소 등)요가나 가벼운 체조",
              value: "2",
            },
            {
              answertitle: "기타",
              value: "3",
            },
            {
              answertitle: "없다",
              value: "4",
            },
          ],
        },
        {
          number: "13",
          target: "a19",
          questiontitle: "건강한 출산을 위하여 남편과 하시고 계신 공부가 있나요?",
          answers: [
            {
              answertitle: "라마즈 호흡법",
              value: "1",
            },
            {
              answertitle: "출산 전 과정에 대한 공부",
              value: "2",
            },
            {
              answertitle: "태교 등 기타",
              value: "3",
            },
            {
              answertitle: "없다",
              value: "4",
            },
          ],
        },
        {
          number: "14",
          target: "a20",
          questiontitle: "건강한 출산에 대하여 본인이 현재 알고 있는 지식 상태를 1부터 5까지라고 표시한다면 몇점에다 표시해야 할까요?",
          answers: [
            {
              answertitle: "1",
              value: "1",
            },
            {
              answertitle: "2",
              value: "2",
            },
            {
              answertitle: "3",
              value: "3",
            },
            {
              answertitle: "4",
              value: "4",
            },
            {
              answertitle: "5",
              value: "5",
            },
          ],
        },
      ],
    },
    KM0501002: {
      title: "필수체크! 예비맘의 건강체크",
      questions: [
        {
          number: "1",
          questiontitle: "현재 임신한 상태에 대해 체크해 주세요.",
          answers: [
            {
              answertitle: "18세 에서 34세 미만의 초산 혹은 경산의 임신상태",
              value: "5",
            },
            {
              answertitle: "17세 이하이거나 35세 이상의 초산 혹은 경산의 임신상태",
              value: "15",
            },
            { answertitle: "35세 이상이면서 초산부 상태", value: "25" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "이전에 출산한 경험이 있나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a2",
        },
        {
          number: "2-1",
          questiontitle: "지난번 과거 출생아 몸무게는 어떻게 되나요?",
          answers: [
            { answertitle: "2.5kg 이하", value: "1" },
            { answertitle: "4.5kg 이상", value: "2" },
            { answertitle: "2.5kg 초과 4.5kg 미만", value: "3" },
          ],
          target: "a3",
        },
        {
          number: "3",
          questiontitle: "다음의 고위험 임신 및 출산의 경험이 과거에 있으신가요?",
          answersType: "checkbox",
          answers: [
            { answertitle: "임신중독 및 자간증", value: "1" },
            { answertitle: "제왕절개 수술", value: "2" },
            { answertitle: "선천성 기형아 분만경험", value: "3" },
            { answertitle: "질 출혈 경험", value: "4" },
            { answertitle: "난산 경험", value: "5" },
            { answertitle: "주산기 사망", value: "6" },
            { answertitle: "없음", value: "7" },
          ],
          examples: [
            {
              content: "주산기 사망이란? 임신 8개월 이후의 사산과 출산 1주 미만의 조기 신생아 사망",
            },
            {
              content: "난산이란? 비정상 진통으로 분만 과정에 이상이 생겨 모체나 태아에 여러 장애가 생기는 경우",
            },
            {
              content:
                "자간증이란? 정상 혈압을 갖고 있던 여성이 임신 20주 이후에 고혈압을 보이면서 단백뇨가 배출되는 경우를 말하며 임신중독증이라고도 일컬음)",
            },
          ],
          target: "a4",
        },
        {
          number: "4",
          questiontitle: "자연유산의 경험이 있으신가요?",
          answers: [
            { answertitle: "자연유산 3회 이상 경험", value: "15" },
            { answertitle: "자연유산 1~2회 경험", value: "10" },
            { answertitle: "없음", value: "0" },
          ],
          target: "a5",
        },
        {
          number: "5",
          questiontitle: "임신 횟수는 어떻게 되시나요?",
          answers: [
            { answertitle: "유산 or 사산 포함 1~4회", value: "5" },
            { answertitle: "유산 or 사산 포함 5회 이상", value: "10" },
            { answertitle: "없음", value: "0" },
          ],
          target: "a6",
        },
        {
          number: "6",
          questiontitle: "임신 전 고혈압이나 심혈관질환 진단을 받은 적이 있나요?",
          answers: [
            { answertitle: "예", value: "24" },
            { answertitle: "아니오", value: "0" },
          ],
          target: "a7",
        },
        {
          number: "7",
          questiontitle: "어머님이나 할머니가 임신성 고혈압이었다는 얘기를 들어본 적이 있나요?",
          answers: [
            { answertitle: "예", value: "14" },
            { answertitle: "아니오", value: "0" },
          ],
          target: "a8",
        },
        {
          number: "8",
          questiontitle: "임신 전 담배를 피우셨나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "2" },
          ],
          target: "a9",
        },
        {
          number: "8-1",
          questiontitle: "임신과 함께 담배를 끊으셨나요?",
          answers: [
            { answertitle: "예", value: "11" },
            {
              answertitle: "아니오",
              value: "23",
            },
          ],
          target: "a10",
        },
        {
          number: "9",
          questiontitle: "임신 전 알코올 섭취를 즐겨 하셨나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a11",
        },
        {
          number: "9-1",
          questiontitle: "임신과 함께 알코올 섭취를 끊으셨나요?",
          answers: [
            { answertitle: "예", value: "10" },
            { answertitle: "아니오", value: "26" },
          ],
          target: "a12",
        },
        {
          number: "10",
          questiontitle: "임신 중 커피 등 카페인 포함 음료를 하루 4잔 이상 드시나요?",
          answers: [
            { answertitle: "예", value: "17" },
            { answertitle: "아니오", value: "0" },
          ],
          target: "a13",
        },
        {
          number: "10-1",
          questiontitle:
            "커피 등 카페인 음료를 4잔 이하 드시지만 1잔을 마실 때라도 커피원액(또는 원두커피 등)을 내려 드시거나 박카스류의 음료수를 드시나요?",
          answers: [
            { answertitle: "예", value: "15" },
            { answertitle: "아니오", value: "4" },
          ],
          target: "a14",
        },
      ],
    },
    KM0501003: {
      title: "예비맘 건강, 이렇게 관리하자",
      questions: [
        {
          number: "1",
          questiontitle: "임신 전 예방 접종을 받으신 것이 있다면 모두 체크하시기 바랍니다.",
          answersType: "checkbox",
          answers: [
            { answertitle: "MMR (홍역, 볼거리, 풍진)", value: "1" },
            { answertitle: "성인용 TD백신 (파상풍, 디프테리아)", value: "2" },
            { answertitle: "수두", value: "3" },
            {
              answertitle: "B형 간염",
              value: "4",
            },
            { answertitle: "인플루엔자", value: "8" },
            { answertitle: "모름", value: -"1" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "B형 간염 예방접종은 몇번 받으셨나요?",
          target: "a11",
          answers: [
            { answertitle: "1회", value: "1", name: "a11" },
            { answertitle: "2회", value: "2", name: "a11" },
            { answertitle: "3회", value: "3", name: "a11" },
            { answertitle: "항체존재", value: "4", name: "a11" },
          ],
        },
        {
          number: "3",
          questiontitle: "예방 접종 후 4주 간 피임을 하셨습니까?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a2",
        },
        {
          number: "4",
          questiontitle: "피로감을 자주 느끼시고, 의욕이 없으시나요?",
          answers: [
            { answertitle: "그렇지 않다", value: "0" },
            { answertitle: "가끔 그렇다", value: "1" },
            { answertitle: "자주 그렇다", value: "3" },
          ],
          target: "a3",
        },
        {
          number: "6",
          questiontitle: "잠을 잘 자지 못하고, 스트레스를 자주 받으시나요?",
          answers: [
            { answertitle: "그렇지 않다.", value: "0" },
            { answertitle: "가끔 그렇다", value: "1" },
            { answertitle: "자주 그렇다", value: "3" },
          ],
          target: "a4",
        },
        {
          number: "7",
          questiontitle: "규칙적으로 운동(스트레칭 포함)을 하시나요?",
          answers: [
            { answertitle: "거의 매일 30분 이상 한다.", value: "0" },
            { answertitle: "일주일에 1~2번 30분 이상 한다. ", value: "1" },
            { answertitle: "거의 하지 않는다.", value: "3" },
          ],
          target: "a5",
        },
        {
          number: "8",
          questiontitle: "즐겨 하는 식생활은 어떠한가요?(해당항목은 모두 표시해주세요)",
          answersType: "checkbox",
          answers: [
            { answertitle: "세끼를 규칙적으로 식사한다.", value: "1" },
            {
              answertitle: "육류, 생선, 달걀 등으로 된 음식을 매일 두 끼 이상 먹는다.",
              value: "2",
            },
            { answertitle: "채소, 과일을 매일 섭취한다.", value: "3" },
            {
              answertitle: "우유나 유제품(요구르트, 요플레 등)을 매일 먹는다.",
              value: "4",
            },
          ],
          target: "a6",
        },
        {
          number: "9",
          questiontitle: "최근 치과 진료를 받으신 적이 있나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "5" },
          ],
          target: "a7",
        },
        {
          number: "10",
          questiontitle: "최근 치과 진료는 언제 받으셨나요?",
          answers: [
            { answertitle: "3개월 이내", value: "0" },
            { answertitle: "6개월 이내", value: "1" },
            { answertitle: "1년 이상", value: "2" },
          ],
          target: "a8",
        },
        {
          number: "11",
          questiontitle: "생선회, 덜 익은 고기 등 조리하지 않은 날 음식을 임신 중에 드신 적이 있으시나요?",
          answers: [
            { answertitle: "예", value: "3" },
            { answertitle: "아니오", value: "0" },
          ],
          target: "a9",
        },
        {
          number: "12",
          questiontitle: "현재 애완동물을 기르고 계시나요?",
          answers: [
            { answertitle: "예", value: "3" },
            { answertitle: "아니오", value: "0" },
          ],
          target: "a10",
        },
      ],
    },
    KM0501004: {
      title: "아름다운 D라인",
      questions: [
        {
          number: "1",
          questiontitle: "고객님의 연령대가 어떻게 되시나요?",
          answersType: "checkbox",
          answers: [
            { answertitle: "20세 미만", value: "20" },
            { answertitle: "20~29세", value: "29" },
            { answertitle: "30~34세", value: "34" },
            { answertitle: "35~39세", value: "39" },
            { answertitle: "40세 이상", value: "40" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "고객님의 임신 전 키와 몸무게는 어떻게 되시나요?",
          answersType: "input",
          answers: [
            {
              answertitle: "키",
              name: "height",
              inputType: "number",
              text: "cm",
            },
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "고객님의 임신 기간이 얼마나 되셨나요?",
          answers: [
            { answertitle: "1기(~14주)", value: "1" },
            { answertitle: "2기(15~26주)", value: "2" },
            { answertitle: "3기(27~40주)", value: "3" },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "고객님의 현재 몸무게는 어떻게 되시나요?",
          answersType: "input",
          answers: [
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "임신 전 지속적으로 운동을 하신 경험이 있습니까?",
          answers: [
            { answertitle: "없다.", value: "r" },
            { answertitle: "가끔 운동을 했다.", value: "y" },
            { answertitle: "있다.", value: "g" },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "아래 항목 중 현재 해당되시는 곳에 모두 체크하세요.(해당사항이 없으시면 체크하지 마세요)",
          answersType: "checkbox",
          answers: [
            { answertitle: "심장질환 ", value: "심장질환" },
            { answertitle: "폐쇄성 폐질환 ", value: "폐쇄성 폐질환" },
            {
              answertitle: "자궁경관무력증 및 경관봉합술 및 그로 인한 경관봉합술",
              value: "자궁경관무력증",
            },
            {
              answertitle: "조산 위험의 다태임신",
              value: "조산 위험의 다태임신",
            },
            {
              answertitle: "임신 2~3기의 지속적인 출혈 ",
              value: "임신 2~3기의 지속적인 출혈",
            },
            {
              answertitle: "임신 26주 후의 전치태반",
              value: "임신 26주 후의 전치태반",
            },
            { answertitle: "임신 중 태동불안", value: "임신 중 태동불안" },
            { answertitle: "자궁막 파열", value: "자궁막 파열" },
            {
              answertitle: "자간전증/임신성 고혈압(임신 중독증)",
              value: "자간전증/임신성 고혈압(임신 중독증)",
            },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "현재 일주일에 운동을 얼마나 자주 하시나요?",
          answers: [
            { answertitle: "거의 하지 않는다.", value: "r" },
            { answertitle: "2~3회", value: "y" },
            { answertitle: "거의 매일", value: "g" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "앞으로 운동을 하실 계획이 있으신가요?",
          answers: [
            { answertitle: "없다.", value: "r" },
            { answertitle: "있다.", value: "g" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "평소 운동은 어떤 강도로 하시나요?",
          answers: [
            {
              answertitle: "저강도 운동(전혀 힘들지 않다. 가볍다.)",
              value: "y",
            },
            { answertitle: "중강도 운동(보통이다. 다소 힘들다.)", value: "g" },
            { answertitle: "고강도 운동(힘들다. 매우 힘들다.)", value: "r" },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "평소 어떤 형태의 운동을 하시나요?",
          answersType: "checkbox",
          answers: [
            { answertitle: "산책", value: "산책" },
            { answertitle: "임산부 체조", value: "임산부체조" },
            { answertitle: "스트레칭 ", value: "스트레칭" },
            { answertitle: "요가", value: "요가" },
            { answertitle: "수중 에어로빅 ", value: "수중에어로빅" },
            { answertitle: "계단오르기 ", value: "계단오르기" },
            { answertitle: "수영 ", value: "수영" },
            { answertitle: "맨손근력운동", value: "맨손근력운동" },
            { answertitle: "조깅 ", value: "조깅" },
            { answertitle: "줄넘기 ", value: "줄넘기" },
            { answertitle: "에어로빅 수업 참가", value: "에어로빅 수업 참가" },
            { answertitle: "농구 ", value: "농구" },
            { answertitle: "축구", value: "축구" },
            { answertitle: "산악자전거 ", value: "산악자전거" },
            { answertitle: "격렬한 라켓스포츠", value: "격렬한 라켓스포츠" },
            { answertitle: "기계체조 ", value: "기계체조" },
            { answertitle: "승마 ", value: "승마" },
            { answertitle: "스키 ", value: "스키" },
            { answertitle: "스쿠버다이빙 ", value: "스쿠버다이빙" },
            { answertitle: "스카이다이빙 ", value: "스카이다이빙" },
            { answertitle: "기타", value: "기타" },
          ],
          target: "a10",
        },
        {
          number: "11",
          questiontitle: "계획한 1회 운동시간은 얼마나 되나요?",
          answers: [
            { answertitle: "30분 미만", value: "y" },
            { answertitle: "30~60분", value: "g" },
            { answertitle: "60분 이상", value: "r" },
          ],
          target: "a11",
        },
        {
          number: "12",
          questiontitle: "운동 중 다음과 같은 증상이 있으면 모두 체크해주십시오.(해당사항이 없으시면 체크하지 마세요)",
          answersType: "checkbox",
          answers: [
            { answertitle: "질내 출혈", value: "1" },
            { answertitle: "호흡곤란", value: "2" },
            { answertitle: "현기증", value: "3" },
            { answertitle: "두통", value: "4" },
            {
              answertitle: "근력 약화(근육량이 감소하여 힘을 못씀)",
              value: "5",
            },
            { answertitle: "장딴지 통증 또는 부종", value: "6" },
            { answertitle: "조산", value: "7" },
            { answertitle: "감소된 태아의 움직임", value: "8" },
            { answertitle: "양수 누출", value: "9" },
          ],
          target: "a12",
        },
        {
          number: "13",
          questiontitle: "평소 규칙적인 식사를 하시나요?",
          answers: [
            { answertitle: "그렇지 않다. (과식이나 폭식)", value: "r" },
            { answertitle: "가끔 그렇다.", value: "y" },
            { answertitle: "그렇다.", value: "g" },
          ],
          target: "a13",
        },
        {
          number: "14",
          questiontitle:
            "평소 간편식과 균형식 중 어떤 식사를 더 자주 하시나요? (간편식 : 밥과 한가지 반찬 또는 김밥, 샌드위치, 햄버거, 라면 등) (균형식 : 밥과 국, 반찬 2가지 이상의 골고루 갖추어진 식사)",
          answers: [
            { answertitle: "간편식", value: "r" },
            { answertitle: "간편식과 균형식의 빈도가 반반이다.", value: "y" },
            { answertitle: "균형식", value: "g" },
          ],
          target: "a14",
        },
        {
          number: "15",
          questiontitle: "임신 전과 비교 시 식사량은 어떠신가요?",
          answers: [
            { answertitle: "줄었다", value: "r" },
            { answertitle: "똑같다.", value: "y" },
            { answertitle: "늘었다.", value: "g" },
          ],
          target: "a15",
        },
      ],
    },
    KM0502001: {
      title: "출산 후 S라인 만들기",
      questions: [
        {
          number: "1",
          questiontitle: "고객님의 연령대가 어떻게 되시나요?",
          answers: [
            { answertitle: "20세 미만", value: "1" },
            { answertitle: "20~29세", value: "2" },
            { answertitle: "30~39세", value: "3" },
            { answertitle: "40세 이상", value: "4" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "임신 전 체중과 키?",
          answersType: "input",
          answers: [
            {
              answertitle: "키",
              name: "height",
              inputType: "number",
              text: "cm",
            },
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "만삭 시 체중?",
          answersType: "input",
          answers: [
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "출산 후 현재까지 기간?",
          answers: [
            { answertitle: "6주 미만", value: "1" },
            { answertitle: "6주~6개월", value: "2" },
            { answertitle: "6개월~1년", value: "3" },
            { answertitle: "1년 이상", value: "4" },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "현재 체중?",
          answersType: "input",
          answers: [
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "제왕절개를 통한 분만을 하셨나요?",
          answers: [
            { answertitle: "그렇다", value: "1" },
            { answertitle: "아니다", value: "0" },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "현재 모유 수유 중입니까?",
          answers: [
            { answertitle: "그렇다", value: "1" },
            { answertitle: "아니다", value: "0" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "골반저근운동(케겔운동)에 참여하고 있습니까?",
          answers: [
            { answertitle: "그렇다", value: "1" },
            { answertitle: "아니다", value: "0" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "스트레칭이나 체조에 참여하고 있습니까?",
          answers: [
            { answertitle: "그렇다", value: "1" },
            { answertitle: "아니다", value: "0" },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "걷기 운동을 하고 있습니까?",
          answers: [
            { answertitle: "그렇다", value: "1" },
            { answertitle: "아니다", value: "0" },
          ],
          target: "a10",
        },
        {
          number: "11",
          questiontitle: "현재 운동에 참여 하고 있습니까?",
          answers: [
            { answertitle: "그렇다", value: "1" },
            { answertitle: "아니다", value: "0" },
          ],
          target: "a11",
        },
        {
          number: "12",
          questiontitle: "현재 참여하고 계시는 운동프로그램은 무엇입니까?(중복 선택 가능)",
          answersType: "checkbox",
          answers: [
            { answertitle: "걷기, 자전거", value: "1" },
            { answertitle: "계단오르기", value: "2" },
            { answertitle: "스트레칭, 체조", value: "3" },
            { answertitle: "근력운동(맨손, 밴드 등)", value: "4" },
            { answertitle: "수영, 아쿠아로빅", value: "5" },
            { answertitle: "달리기, 줄넘기", value: "6" },
            { answertitle: "기타", value: "7" },
          ],
          target: "a12",
        },
        {
          number: "13",
          questiontitle: "운동 참여 시 운동강도는 어떻게 하십니까?",
          answers: [
            { answertitle: "전혀 힘들지 않다", value: "1" },
            { answertitle: "약하다", value: "2" },
            { answertitle: "중간이다", value: "3" },
            { answertitle: "다소 힘들다", value: "4" },
            { answertitle: "힘들다", value: "5" },
            { answertitle: "매우 힘들다", value: "6" },
          ],
          target: "a13",
        },
        {
          number: "14",
          questiontitle: "1회 운동 시 얼마나 오래 하십니까?",
          answers: [
            { answertitle: "20분 미만", value: "1" },
            { answertitle: "20~40분", value: "2" },
            { answertitle: "40~60분", value: "3" },
            { answertitle: "60분 이상", value: "4" },
          ],
          target: "a14",
        },
        {
          number: "15",
          questiontitle: "일주일에 얼마나 자주 운동하십니까?",
          answers: [
            { answertitle: "1일 이하", value: "1" },
            { answertitle: "2~3일", value: "2" },
            { answertitle: "3~5일", value: "3" },
            { answertitle: "거의 매일", value: "4" },
          ],
          target: "a15",
        },
        {
          number: "16",
          questiontitle: "출산 후 식사량에 변화가 있습니까?",
          answers: [
            { answertitle: "줄었다", value: "1" },
            { answertitle: "똑같다", value: "2" },
            { answertitle: "늘었다", value: "3" },
          ],
          target: "a16",
        },
        {
          number: "17",
          questiontitle: "출산 후 간편식과 균형식 중 어떤 식사를 자주 하시나요?",
          example: [
            { content: "(간편식:식사대용식, 김밥, 샌드위치, 햄버거, 라면 등)" },
            {
              content: "(균형식 : 밥과 국, 반찬 2가지 이상의 골고루 갖추어진 식사)",
            },
          ],
          answers: [
            { answertitle: "간편식", value: "1" },
            { answertitle: "간편식과 균형식의 빈도가 반반이다. ", value: "2" },
            { answertitle: "균형식", value: "3" },
          ],
          target: "a17",
        },
        {
          number: "18",
          questiontitle: "출산 후 특별히 드시는 보양식이 있습니까?",
          answers: [
            { answertitle: "있다", value: "1" },
            { answertitle: "없다", value: "0" },
          ],
          target: "a18",
        },
      ],
    },
    KM0502002: {
      title: "출산맘 건강, 이렇게 관리하자",
      questions: [
        {
          number: "1",
          questiontitle: "고객님의 나이는 어떻게 되시나요?",
          answersType: "input",
          answers: [
            {
              answertitle: "나이",
              name: "age",
              inputType: "number",
              text: "세",
            },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "아기를 출산한지 얼마나 되셨나요?",
          answers: [
            { answertitle: "6개월 미만", value: "5" },
            { answertitle: "6개월 이상", value: "3" },
            { answertitle: "1년 이상", value: "1" },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "현재 나의 두피 상태는 어떠한가요?",
          answers: [
            { answertitle: "기름기가 흐른다", value: "a" },
            { answertitle: "비듬이 많고 가렵다", value: "b" },
            { answertitle: "각질이 일어나고 건조하다", value: "c" },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "현재 나의 모발 상태는 어떠한가요?",
          answers: [
            { answertitle: "건강하고 탄력 있다", value: "d" },
            { answertitle: "푸석거리고 갈라진다", value: "e" },
            { answertitle: "끈적이고 떡이 진다", value: "f" },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "머리를 감을 때 한 움큼 이상 빠지시는 것 같습니까?",
          answers: [
            { answertitle: "예", value: "5" },
            { answertitle: "아니오", value: "0" },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "머리를 감을 때 해당하는 습관에 대해 체크해주세요.",
          answersType: "checkbox",
          answers: [
            { answertitle: "샴푸 전 빗질 없이 물을 적신다", value: "1" },
            { answertitle: "두피를 손톱으로 긁으면서 거품을 낸다", value: "2" },
            { answertitle: "샴푸의 거품은 1회만 한다", value: "3" },
            { answertitle: "헹구는 횟수는 1회만 한다", value: "4" },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "출산 후 예전의 몸매를 회복하기 위해 다이어트 중 이시나요?",
          answers: [
            { answertitle: "예", value: "5" },
            { answertitle: "아니오", value: "0" },
            { answertitle: "다이어트를 계획하고 있다.", value: "3" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "육아로 인하여 생활이 불규칙하고 스트레스를 많이 받으십니까?",
          answers: [
            { answertitle: "예 ", value: "5" },
            { answertitle: "아니오", value: "0" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "피부 관리를 위해 일주일에 1시간 이상 투자한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "매일 규칙적으로 식사한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "11",
          questiontitle: "하루에 3~4시간 이상 햇빛에 노출된다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "12",
          questiontitle: "매일 수시로 자외선 차단제를 바른다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "13",
          questiontitle: "내 피부 타입에 맞는 세안제를 사용한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "14",
          questiontitle: "아침저녁 반드시 세안한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "15",
          questiontitle: "날마다 화장한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "16",
          questiontitle: "육류보다는 야채를 좋아한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "17",
          questiontitle: "하루에 8시간 이상 숙면을 취한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "18",
          questiontitle: "일주일에 3시간 이상 운동한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "19",
          questiontitle: "담배를 피운다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "20",
          questiontitle: "일주일에 3회 이상 술을 마신다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "21",
          questiontitle: "변비가 있다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "22",
          questiontitle: "하루에 3잔 이상 커피를 마신다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "23",
          questiontitle: "피부에 문제가 생기면 즉시 피부과 전문의에게 진찰받는다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "24",
          questiontitle: "노화 방지를 위한 기능성 화장품을 사용한다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "25",
          questiontitle: "스트레스를 많이 받는다",
          answers: [
            { answertitle: "예 ", value: "-1" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a9",
        },
        {
          number: "26",
          questiontitle: "피부관리 외에 관심 있는 뷰티 케어는 어떤 것이 있나요?",
          answersType: "checkbox",
          answers: [
            { answertitle: "리프팅(탄력) 케어", value: "1" },
            { answertitle: "안티에이징 케어", value: "2" },
            { answertitle: "바스트(가슴) 케어", value: "3" },
          ],
          target: "a10",
        },
      ],
    },
    KM0502003: {
      title: "건강한 모유수유 바로 알기",
      questions: [
        {
          number: "1",
          questiontitle: "현재 출산 후 얼마나 지났나요?",
          answers: [
            { answertitle: "1주 이내", value: "1" },
            { answertitle: "1주이상 4주 미만", value: "2" },
            { answertitle: "4주 이상~6주 미만", value: "3" },
            { answertitle: "6주 이상~8주 미만", value: "4" },
            { answertitle: "8주 이상~16주 미만", value: "5" },
            { answertitle: "16주 이상", value: "6" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "현재 모유수유를 하시고 계시나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "40" },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "현재 어떠한 형태의 모유수유를 하시고 계신가요?",
          answers: [
            { answertitle: "100% 모유수유(완모)", value: "20" },
            { answertitle: "분유와 혼합수유", value: "30" },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "모유수유를 시작해 성공하시고 싶으신가요?",
          answers: [
            { answertitle: "예", value: "40" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "현재 어머님의 사회적 역할은 어떠하신가요?",
          answers: [
            { answertitle: "취업모", value: "1" },
            { answertitle: "주부", value: "2" },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "출산 후 1주일에 모유 수유율은 얼마나 되었나요?",
          answers: [
            { answertitle: "100%모유수유(완모)", value: "1" },
            { answertitle: "80% 정도", value: "20" },
            { answertitle: "50% 정도", value: "30" },
            { answertitle: "50% 미만", value: "40" },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "출산 후 1주일에 100%가 모유로만 수유할 수 없었던 이유는 무엇인가요?",
          answers: [
            { answertitle: "젖량부족", value: "1" },
            { answertitle: "유두거부(유두혼동)", value: "2" },
            { answertitle: "병원 치료 등으로 먹일 수 없어서", value: "3" },
            { answertitle: "피곤해서", value: "4" },
            { answertitle: "기타", value: "5" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "직후 1일째 젖병수유를 하셨나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "20" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "출산 첫날 젖병수유를 하셨나요?",
          answers: [
            {
              answertitle: "되도록 모유수유를 하려 했다(기억에 2회 미만이다.)",
              value: "30",
            },
            {
              answertitle: "힘든때는 젖병수유를 했다(기억에 3회 이상이다.)",
              value: "40",
            },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "모자동실이었나요?",
          answers: [
            { answertitle: "예", value: "20" },
            { answertitle: "아니오", value: "30" },
          ],
          target: "a10",
        },
        {
          number: "11",
          questiontitle: "출산 전 모유수유와 관련한 학습을 실행하셨나요?",
          answers: [
            { answertitle: "예", value: "20" },
            { answertitle: "아니오", value: "40" },
          ],
          target: "a11",
        },
        {
          number: "12",
          questiontitle: "정상분만 이외의 출산이나 합병증으로 입원기간이 길어지셨나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "20" },
          ],
          target: "a12",
        },
        {
          number: "13",
          questiontitle: "입원기간이 길어진 이유는 무엇인가요?",
          answers: [
            { answertitle: "제왕절개", value: "40" },
            { answertitle: "출산 후 합병증", value: "40" },
            { answertitle: "기타", value: "40" },
          ],
          target: "a13",
        },
        {
          number: "14",
          questiontitle: "현재 모유수유에 대해 불편감을 느끼고 계시나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a14",
        },
        {
          number: "15",
          questiontitle: "불편을 느끼신다면 어떤 종류의 불편을 느끼시나요?(중복체크 가능)",
          answersType: "checkbox",
          answers: [
            { answertitle: "유두아픔", value: "a" },
            { answertitle: "젖량 부족", value: "b" },
            { answertitle: "아기의 잦은 수유요구", value: "c" },
            { answertitle: "젖 먹이는데 너무 긴 시간의 요구", value: "d" },
            { answertitle: "젖 계속 흐름", value: "e" },
            { answertitle: "모유 수유 자체가 너무 힘들다", value: "f" },
            { answertitle: "유두의 모양 (함몰 유두 등)", value: "g" },
            { answertitle: "밤중수유", value: "h" },
            { answertitle: "기타", value: "i" },
          ],
          target: "a15",
        },
        {
          number: "16",
          questiontitle: "모유수유 동안 엄마와 아기 모두 만족감을 느끼시나요?",
          answers: [
            { answertitle: "예", value: "A" },
            { answertitle: "아니오", value: "B" },
          ],
          target: "a16",
        },
        {
          number: "17",
          questiontitle: "현재 충분한 양의 모유가 나오고 있나요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a17",
        },
        {
          number: "18",
          questiontitle: "모유수유는 언제까지 계획하고 계시나요?",
          answers: [
            { answertitle: "6개월 미만", value: "6" },
            { answertitle: "6개월~1년 미만", value: "601" },
            { answertitle: "1년 이상~2년 미만", value: "102" },
            { answertitle: "2년 이상(먹일 수 있을 때까지)", value: "2" },
          ],
          target: "a18",
        },
        {
          number: "19",
          questiontitle: "아기가 모유수유를 할 때 한번에 빠는 시간은 어느 정도 인가요?",
          answers: [
            { answertitle: "10분 미만", value: "1" },
            { answertitle: "10분 이상~20분 미만", value: "2" },
            { answertitle: "20분 이상", value: "3" },
          ],
          target: "a19",
        },
        {
          number: "20",
          questiontitle: "현재 모유수유 간격은 어떻게 되시나요?",
          answers: [
            { answertitle: "1시간 정도", value: "1" },
            { answertitle: "2시간 정도", value: "2" },
            { answertitle: "3시간 정도", value: "3" },
          ],
          target: "a20",
        },
        {
          number: "21",
          questiontitle: "수유로 인한 스트레스가 어느 정도인가요?",
          answers: [
            { answertitle: "별로없다.", value: "1" },
            { answertitle: "보통이다.", value: "2" },
            { answertitle: "심각하다.", value: "3" },
          ],
          target: "a21",
        },
        {
          number: "22",
          questiontitle: "하루 수면시간은 충분한가요?",
          answers: [
            { answertitle: "예", value: "0" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a22",
        },
        {
          number: "23",
          questiontitle: "출생시 아기의 체중과 현재 체중 입력하기",
          answersType: "input",
          answers: [
            {
              label: "출생시 아기의 체중",
              inputType: "number",
              answertitle: "kg",
              name: "weight",
            },
            {
              label: "현재 체중",
              inputType: "number",
              answertitle: "kg",
              name: "weight ",
            },
          ],
          target: "a23",
        },
      ],
    },
    KM0502004: {
      title: "마음 속 건강부터 다스리자",
      questions: [
        {
          number: "1",
          target: "a1",
          questiontitle: "웃을 수 있고 매사를 재미있게 생각하는 긍정석 사고를 했다.",
          answers: [
            {
              answertitle: "매우 그렇다",
              value: "0",
            },
            {
              answertitle: "약간 그렇다",
              value: "1",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "2",
            },
            { answertitle: "그렇지 않다.", value: "3" },
          ],
        },
        {
          number: "2",
          target: "a2",
          questiontitle: "출산과 함께 모든 것이 잘 될 것이라고 기대했다",
          answers: [
            {
              answertitle: "매우 그렇다",
              value: "0",
            },
            {
              answertitle: "약간 그렇다",
              value: "1",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "2",
            },
            { answertitle: "그렇지 않다.", value: "3" },
          ],
        },
        {
          number: "3",
          target: "a3",
          questiontitle: "어떤 일이 필요이상 잘못될 때면 나 자신을 필요이상 탓했다.",
          answers: [
            {
              answertitle: "전혀 그렇지 않다.",
              value: "0",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "1",
            },
            { answertitle: "약간 그렇다", value: "2" },
            { answertitle: "매우 그렇다", value: "3" },
          ],
        },
        {
          number: "4",
          target: "a4",
          questiontitle: "별다른 이유없이 불안하고 걱정스러웠다.",
          answers: [
            {
              answertitle: "전혀 그렇지 않다.",
              value: "0",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "1",
            },
            { answertitle: "약간 그렇다", value: "2" },
            { answertitle: "매우 그렇다", value: "3" },
          ],
        },
        {
          number: "5",
          target: "a5",
          questiontitle: "별다른 이유없이 두려움이나 공포감을 느낀적이 있었다.",
          answers: [
            {
              answertitle: "전혀 그렇지 않다.",
              value: "0",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "1",
            },
            { answertitle: "약간 그렇다", value: "2" },
            { answertitle: "매우 그렇다", value: "3" },
          ],
        },
        {
          number: "6",
          target: "a6",
          questiontitle: "여러가지 일들이 힘겹게 느껴졌었다.",
          answers: [
            {
              answertitle: "전혀 그렇지 않다.",
              value: "0",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "1",
            },
            { answertitle: "약간 그렇다", value: "2" },
            { answertitle: "매우 그렇다", value: "3" },
          ],
        },
        {
          number: "7",
          target: "a7",
          questiontitle: "너무 불행해서 잠을 이룰 수가 없었다.",
          answers: [
            {
              answertitle: "전혀 그렇지 않다.",
              value: "0",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "1",
            },
            { answertitle: "약간 그렇다", value: "2" },
            { answertitle: "매우 그렇다", value: "3" },
          ],
        },
        {
          number: "8",
          target: "a8",
          questiontitle: "슬프거나 불행하다는 느낌을 받는다.",
          answers: [
            {
              answertitle: "전혀 그렇지 않다.",
              value: "0",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "1",
            },
            { answertitle: "약간 그렇다", value: "2" },
            { answertitle: "매우 그렇다", value: "3" },
          ],
        },
        {
          number: "9",
          target: "a9",
          questiontitle: "너무 불행해서 계속 울어왔다.",
          answers: [
            {
              answertitle: "전혀 그렇지 않다.",
              value: "0",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "1",
            },
            { answertitle: "약간 그렇다", value: "2" },
            { answertitle: "매우 그렇다", value: "3" },
          ],
        },
        {
          number: "10",
          target: "a10",
          questiontitle: "스스로를 자해하고 모든 것을 포기하고픈 생각이 떠올랐다.",
          answers: [
            {
              answertitle: "전혀 그렇지 않다.",
              value: "0",
            },
            {
              answertitle: "거의 그렇지 않다",
              value: "1",
            },
            { answertitle: "약간 그렇다", value: "2" },
            { answertitle: "매우 그렇다", value: "3" },
          ],
        },
      ],
    },
    KM0503001: {
      title: "알아두면 좋은 시기별 건강관리",
      questions: [
        {
          number: "1",
          questiontitle: "성별",
          target: "a29",
          answers: [
            { answertitle: "남자", value: "M" },
            { answertitle: "여자", value: "F" },
          ],
        },
        {
          number: "2",
          questiontitle: "조산유무",
          target: "a30",
          answers: [
            { answertitle: "그렇다", value: "1" },
            { answertitle: "아니다", value: "0" },
          ],
        },
        {
          number: "3",
          questiontitle: "조산 시 부족한 태내주수를 적어주세요.",
          target: "a31",
          answersType: "input",
          answers: [
            {
              answertitle: "주",
              inputType: "number",
            },
          ],
        },
        {
          number: "4",
          questiontitle: "출생일",
          target: "a32",
          answersType: "input",
          answers: [
            {
              answertitle: "출생일",
              inputType: "date",
              name: "date",
            },
          ],
        },
        {
          number: "5",
          questiontitle: "출생시 체중을 입력해주세요.",
          target: "a33",
          answersType: "input",
          answers: [
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
        },
        {
          number: "6",
          questiontitle: "현재 체중을 입력해주세요.",
          target: "a35",
          answersType: "input",
          answers: [
            {
              answertitle: "몸무게",
              name: "weight",
              inputType: "number",
              text: "kg",
            },
          ],
        },
        {
          number: "7",
          questiontitle: "출생시 신장을 입력해주세요.",
          target: "a35",
          answersType: "input",
          answers: [
            {
              answertitle: "키",
              name: "height",
              inputType: "number",
              text: "cm",
            },
          ],
        },
        {
          number: "8",
          questiontitle: "현재시 신장을 입력해주세요.",
          target: "a36",
          answersType: "input",
          answers: [
            {
              answertitle: "키",
              name: "height",
              inputType: "number",
              text: "cm",
            },
          ],
        },
      ],
    },
    KM0503002: {
      title: "우리아이 피부관리 알아두기",
      questions: [
        {
          number: "1",
          questiontitle: "자녀가 지금까지 가려운 피부발진(‘태열’ 또는 ‘아토피 피부염’)으로 진단을 받거나 치료를 받은 적이 있으십니까?",
          answers: [
            { answertitle: "있다", value: "5" },
            { answertitle: "없다", value: "0" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "부모님 중 아토피피부염을 포함한 알레르기질환으로 진단을 받거나 치료를 받으신 분이 있으십니까?",
          answers: [
            { answertitle: "있다", value: "-1" },
            { answertitle: "없다", value: "0" },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "부모님 중 어느 분이 해당하십니까?",
          answers: [
            { answertitle: "엄마 혹은 아빠", value: "3" },
            { answertitle: "모두", value: "5" },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "집 안에 있는 것 들을 모두 표시하시기 바랍니다",
          answersType: "checkbox",
          answers: [
            { answertitle: "강아지 혹은 고양이", value: "1" },
            { answertitle: "개미, 바퀴벌레", value: "2" },
            { answertitle: "기타 애완동물", value: "3" },
            { answertitle: "카페트", value: "4" },
            { answertitle: "커튼", value: "5" },
            { answertitle: "천 소파", value: "6" },
            { answertitle: "침대", value: "7" },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "집 안의 실내 환경은 어디에 해당하십니까?",
          answers: [
            { answertitle: "볕이 잘 든다 ", value: "0" },
            { answertitle: "볕이 잘 들지 않는다", value: "1" },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "목욕을 얼마나 자주 시키십니까?",
          answers: [
            { answertitle: "적정습도 유지(40~60%)", value: "0" },
            { answertitle: "건조하거나 습기가 많다", value: "1" },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "목욕을 할 때 비누(바디샴푸 등 목욕제 포함)를 사용하십니까?",
          answers: [
            { answertitle: "먼지가 거의 없다", value: "0" },
            { answertitle: "먼지가 많다", value: "1" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "비누목욕을 얼마나 자주 하십니까?",
          answers: [
            { answertitle: "일주일에 2~3번", value: "2" },
            { answertitle: "하루에 1번", value: "0" },
            { answertitle: "하루에 2번 이상", value: "1" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "목욕 후 보습제(바디로션, 크림 등)를 바르십니까?",
          answers: [
            { answertitle: "한다", value: "-1" },
            { answertitle: "안 한다", value: "2" },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "손발톱은 항상 짧게 매근하고 부드럽게 다듬어 주십니까?",
          answers: [
            { answertitle: "매일", value: "5" },
            { answertitle: "일주일에 2~3회", value: "0" },
          ],
          target: "a10",
        },
        {
          number: "11",
          questiontitle: "운동을 한 후 혹은 여름철 과도한 발한으로 흘린 땀을 바로 닦아 내주십니까?",
          answers: [
            { answertitle: "반드시 바른다", value: "0" },
            { answertitle: "안 바르거나 불규칙적이다", value: "3" },
          ],
          target: "a11",
        },
        {
          number: "12",
          questiontitle: "주로 순면 소재의 옷을 입힙니까(겨울 코트 착용 제외)?",
          answers: [
            { answertitle: "그렇다", value: "0" },
            { answertitle: "그렇지 않다", value: "3" },
          ],
          target: "a12",
        },
        {
          number: "13",
          questiontitle: "출생 후 4개월 이상 모유수유를 하셨습니까?",
          answers: [
            { answertitle: "그렇다", value: "0" },
            { answertitle: "그렇지 않다", value: "3" },
          ],
          target: "a13",
        },
        {
          number: "14",
          questiontitle: "아토피피부염 예방 또는 증상 완화를 위해 음식을 제한한 적이 있습니까?",
          answers: [
            { answertitle: "그렇다", value: "0" },
            { answertitle: "그렇지 않다", value: "4" },
          ],
          target: "a14",
        },
        {
          number: "15",
          questiontitle: "우유, 계란, 땅콩과 견과류 식품에서 알레르기 반응을 일으킨 적이 있습니까?",
          answers: [
            { answertitle: "했다", value: "0" },
            { answertitle: "안 했다", value: "3" },
          ],
          target: "a15",
        },
        {
          number: "16",
          questiontitle: "지난 일년 동안 스테로이드 연고나 크림을 자녀에게 사용하신 적이 있습니까?",
          answers: [
            { answertitle: "있다", value: "3" },
            { answertitle: "없다", value: "0" },
          ],
          target: "a16",
        },
        {
          number: "17",
          questiontitle: "스테로이드 연고나 크림을 의사 처방대로 자녀에게 사용하셨습니까?",
          answers: [
            { answertitle: "있다", value: "4" },
            { answertitle: "없다", value: "0" },
          ],
          target: "a17",
        },
        {
          number: "18",
          questiontitle: "붉은 반점이나 갈라지고 긁힌 피부, 진물 등의 피부 소견으로 또래들과 어울리거나 사람들 앞에 나서는 것을 꺼려하나요?",
          answers: [
            { answertitle: "있다", value: "-1" },
            { answertitle: "없다", value: "2" },
          ],
          target: "a18",
        },
      ],
    },
    KM0503005: {
      title: "유행성 질환 대처하기",
      questions: [
        {
          number: "1",
          questiontitle: "예방접종은 아무 때나 병원 가서 맞을 수 있다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "요즘 유행하는 질병이 아니면 예방접종을 안 해도 된다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "예방접종 후 곧바로 집으로 가도 된다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "예방접종은 한 번 맞으면 모든 질병을 예방할 수 있다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "모든 예방접종은 한 번씩만 맞으면 다시 맞지 않아도 된다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "독감 예방접종을 하면 독감에 걸리지 않는다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "예방접종을 통해서 우리 몸에 들어온 백신은 질병을 무찌를 수 있는 항체를 만들어준다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "개인병원의 예방접종 백신과 보건소 백신은 효과가 다르다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "식중독은 물과 공기를 통해서 걸리게 되는 질병이다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "식중독은 종류에는 세균성 감염만 있다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a10",
        },
        {
          number: "11",
          questiontitle: "병에 걸리지 않게 해주는 올바른 손씻기가 있다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a11",
        },
        {
          number: "12",
          questiontitle:
            "밖에서 놀다가 들어와서 손을 씻지 않고 음식을 손으로 먹어도 병균은 우리 손에만 남아 있으므로 우리 몸속으로는 들어오지 못한다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a12",
        },
        {
          number: "13",
          questiontitle: "아이가 전염성 강한 질환에 걸렸다면 다 나았다는 의사 소견을 받기 전까지 놀이방, 어린이집에 보내지 않는다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a13",
        },
        {
          number: "14",
          questiontitle: "환절기나 겨울철에 실내 공기 환기는 오전 9시 이전이나 오후 5시 이후가 좋다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a14",
        },
        {
          number: "15",
          questiontitle: "아이와 있을 때는 어른이 적당하다고 생각하는 것보다 2℃가량 실내온도를 높인다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a15",
        },
        {
          number: "16",
          questiontitle: "아이가 과도한 땀, 구토, 설사, 또는 장염 증세 등으로 탈수가 걱정될 때는 체내 흡수가 빠른 스포츠 이온음료를 마시게 한다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a16",
        },
        {
          number: "17",
          questiontitle: "면역력과 장 건강은 무관하다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a17",
        },
        {
          number: "18",
          questiontitle: "열이 날 때 무조건 옷을 벗긴다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a18",
        },
        {
          number: "19",
          questiontitle: "아이가 열이 날 때 얼음 팩을 해주는 게 효과가 좋다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a19",
        },
        {
          number: "20",
          questiontitle: "먹다 남은 해열제는 냉장 보관한다?",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a20",
        },
      ],
    },
    KM0503006: {
      title: "아이를 위해 특화시킨 안과, 치과",
      questions: [
        {
          number: "1",
          questiontitle: "생후 6개월이 되면 완벽히 두 눈의 초점을 맞출 수 있다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "모빌은 형광등 아래에 달아주면 된다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "고개를 기울이거나 얼굴을 옆으로 돌려서 보면 눈 이상을 의심해봐야한다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "만 3세부터는 1년마다 한 번씩 안과 검진을 받는 것이 바람직하다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "약시는 자라면서 나아질 수 있다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "안경을 쓰면 눈이 더 나빠진다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "사시는 눈 운동을 통해 치료할 수 없다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "비타민A는 눈 건강에 도움이 된다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "눈에 이물질이 들어갔을 때는 손으로 비벼서 빼낸다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "눈곱이 자주 끼면 집에서 안약을 넣어준다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a10",
        },
        {
          number: "11",
          questiontitle: "산모의 구강건강이 태아의 구강건강과 연관이 있다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a11",
        },
        {
          number: "12",
          questiontitle: "치아가 나오기 전에는 따로 구강관리가 필요 없다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a12",
        },
        {
          number: "13",
          questiontitle: "칫솔질은 앞니와 어금니가 모두 나왔을 때 해야한다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a13",
        },
        {
          number: "14",
          questiontitle: "치과는 36개월 이후부터 가는 것이 좋다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a14",
        },
        {
          number: "15",
          questiontitle: "노리개 젖꼭지는 돌이 지나고 사용해도 괜찮다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a15",
        },
        {
          number: "16",
          questiontitle: "충치는 전염된다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a16",
        },
        {
          number: "17",
          questiontitle: "영유아 구강검진은 만6세(71개월)전에 비용부담 없이 3회 받을 수 있다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a17",
        },
        {
          number: "18",
          questiontitle: "밥을 입안에 오래 물고 있으면 충치가 생길 수 있다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a18",
        },
        {
          number: "19",
          questiontitle: "충치가 심해서 유치를 뺐어도 영구치가 나올것이므로 그냥 두어도 괜찮다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a19",
        },
        {
          number: "20",
          questiontitle: "영구치가 유치보다 노란빛을 띠는 것은 정상이다",
          answers: [
            { answertitle: "YES", value: "5" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a20",
        },
      ],
    },
    KM0504001: {
      title: "초보 육아 따라하기",
      questions: [
        {
          number: "1",
          questiontitle: "신생아에게 물을 먹여도 된다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "아기 두상을 예쁘게 하기 위해 엎어 재워도 된다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "젖만 물리면 아기가 자는데, 깨워야 한다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "모유 수유 중인 산모의 기력 회복을 위해 한약을 먹어도 된다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "가까운 거리도 반드시 카시트에 앉혀서 이동한다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "눕히면 깨서 우는 아이는 계속 안아서 재운다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "잠을 안 자려고 하면 지칠 때까지 계속 놀게 한다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "저녁에 너무 일찍 자면 아침에 너무 일찍 일어난다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "밤에 자다가 깨서 울면 즉시 안아줘야 한다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "매일 목욕을 시켜야 한다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a10",
        },
        {
          number: "11",
          questiontitle: "보행기를 사용하면 걸음마를 일찍 배운다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a11",
        },
        {
          number: "12",
          questiontitle: "아기가 변기를 거부하더라도 시작한 배변 훈련에 성공하기 위해 변기에 앉혀야 한다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a12",
        },
        {
          number: "13",
          questiontitle: "두 돌 이전의 아기에게는 TV를 아예 보여주지 않는 것이 좋다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a13",
        },
        {
          number: "14",
          questiontitle: "식사 도중 놀기 위해 자리를 뜨면 바로 밥상을 치우는 것이 좋다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a14",
        },
        {
          number: "15",
          questiontitle: "낯가림을 극복하기 위해 낯선 사람만 있는 장소에 아이를 데려가 훈련을 시키는 것이 좋다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a15",
        },
        {
          number: "16",
          questiontitle: "특정 음식을 거부하면 아기가 잘 먹는 음식만 주는 것이 좋다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a16",
        },
        {
          number: "17",
          questiontitle: "아기가 모르는 것을 물어보면 솔직하게 모른다고 답하는 것이 좋다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a17",
        },
        {
          number: "18",
          questiontitle: "아기가 사물이나 특정 행동에 집착을 보일 때 단호하게 야단쳐서 고쳐줘야 한다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a18",
        },
        {
          number: "19",
          questiontitle: "친구가 장난감을 가져갔을 때 참으면 양보를 잘한다고 칭찬해준다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a19",
        },
        {
          number: "20",
          questiontitle: "친구 장난감도 전부 자기 것이라며 울면 똑같은 장난감을 사줘서 달래준다",
          answers: [
            { answertitle: "YES", value: "0" },
            { answertitle: "NO", value: "5" },
          ],
          target: "a20",
        },
      ],
    },
    KM0504002: {
      title: "상황별 아이 마음 헤아리기",
      questions: [
        {
          number: "1",
          questiontitle: "성별",
          target: "a36",
          answers: [
            { answertitle: "남자", value: "M" },
            { answertitle: "여자", value: "F" },
          ],
        },
        {
          number: "2",
          questiontitle: "조산유무",
          target: "a37",
          answers: [
            { answertitle: "그렇다", value: "1" },
            { answertitle: "아니다", value: "0" },
          ],
        },
        {
          number: "3",
          questiontitle: "조산 시 부족한 태내주수를 적어주세요.",
          target: "a38",
          answersType: "input",
          answers: [
            {
              answertitle: "주",
              inputType: "number",
            },
          ],
        },
        {
          number: "4",
          questiontitle: "출생일",
          target: "a39",
          answersType: "input",
          answers: [
            {
              answertitle: "출생일",
              inputType: "date",
              name: "date",
            },
          ],
        },
      ],
    },
    KM0504003: {
      title: "부모와 함께하는 오감놀이",
      questions: [
        {
          number: "1",
          questiontitle: "젖(밥)을 잘 먹나요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "목욕하는 것을 즐기나요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a2",
        },
        {
          number: "3",
          questiontitle: "젖(밥) 먹는 간격이 일정한가요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a3",
        },
        {
          number: "4",
          questiontitle: "수면 시간이 규칙적인가요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a4",
        },
        {
          number: "5",
          questiontitle: "배변이 규칙적인가요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a5",
        },
        {
          number: "6",
          questiontitle: "새로운 장난감이나 음식을 접할 때 거부하지 않고 탐색하나요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a6",
        },
        {
          number: "7",
          questiontitle: "낯선 곳에 갔을 때 심하게 울지 않고 쉽게 적응하나요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a7",
        },
        {
          number: "8",
          questiontitle: "싫거나 좋은 감정을 온화하게 표현하나요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a8",
        },
        {
          number: "9",
          questiontitle: "얼러주면 금세 웃고 반응하나요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a9",
        },
        {
          number: "10",
          questiontitle: "기분이 보통 좋은 편인가요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a10",
        },
        {
          number: "11",
          questiontitle: "소리나 상황 등 주변 자극이 있을 때 하던 일에 계속 집중하나요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a11",
        },
        {
          number: "12",
          questiontitle: "한 가지 놀잇감을 가지고 노는 시간이 긴 편인가요?",
          answers: [
            { answertitle: "YES", value: "1" },
            { answertitle: "NO", value: "0" },
          ],
          target: "a12",
        },
      ],
    },
    KM0504004: {
      title: "우리아이 건강 밥상 준비하기",
      questions: [
        {
          number: "1",
          questiontitle: "우리 아이가 편식을 하는 음식이 있나요?",
          answers: [
            { answertitle: "그렇다", value: "0" },
            { answertitle: "아니오", value: "1" },
          ],
          target: "a1",
        },
        {
          number: "2",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "곡류 및 감자류(잡곡밥, 옥수수)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a21",
        },
        {
          number: "3",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "육류(소고기, 돼지고기, 닭고기)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a22",
        },
        {
          number: "4",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "어패류(생선, 굴, 조개, 새우)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a22",
        },
        {
          number: "5",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "뼈째 먹는 생선(멸치, 뱅어포)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a23",
        },
        {
          number: "6",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "난류 및 두류(달걀, 두부, 콩)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a24",
        },
        {
          number: "7",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "채소류(시금치, 오이, 당근 등)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a25",
        },
        {
          number: "8",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "해조류(김, 미역, 다시마)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a26",
        },
        {
          number: "9",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "김치류(배추김치, 깍두기 등)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a27",
        },
        {
          number: "10",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "과일류(사과, 배, 수박 등)" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a28",
        },
        {
          number: "11",
          questiontitle: "어떤 식품군에 대해서 편식을 하는 경향이 있는지 체크해 주세요.",
          examples: [{ content: "우유 및 유제품" }],
          answers: [
            { answertitle: "전혀 먹지 않는다.", value: "1" },
            { answertitle: "어른이 먹어주면 먹는다.", value: "2" },
            { answertitle: "말로 타이르면 먹는다.", value: "3" },
            { answertitle: "스스로 잘 먹는다.", value: "4" },
          ],
          target: "a29",
        },
        {
          number: "12",
          questiontitle: "우리 아이가 밥을 먹을 때 어떠한 편식의 경향을 보이는 지 체크해주세요.",
          answers: [
            {
              answertitle: "또래와 비교했을 때 정상적으로 자라고 있지만 또래보다 더 크기를 원한다",
              value: "ㄱ",
            },
            {
              answertitle: "다른 것에 호기심이 많아 먹는 데에는 관심이 없다",
              value: "ㄴ",
            },
            {
              answertitle: "식사 시간에 가족과 함께 식사를 하는 경우가 많지 않다",
              value: "ㄷ",
            },
            {
              answertitle: "특정 음식의 맛, 냄새, 감촉, 모양 등에 예민하게 반응하는 음식이 있다",
              value: "ㄹ",
            },
            {
              answertitle: "음식을 먹다가 두려운 경험이 있었던 적이 있다",
              value: "ㅁ",
            },
            {
              answertitle:
                "의학적인 문제로(예: 위, 신장질환, 간질, 암 등) 1개월 이상 음식 섭취가 저하되었다.(단순 감기, 급성 설사 등 경증 질환 제외)",
              value: "ㅂ",
            },
          ],
          target: "a3",
        },
        {
          number: "13",
          questiontitle: "싫어하는 음식을 먹도록 하면 고개 돌리기, 입을 다물고 벌리지 않기, 식기를 밀어내는 등의 행동을 보이나요?",
          answers: [
            {
              answertitle: "매우 심하다",
              value: "4",
            },
            {
              answertitle: "심하다",
              value: "3",
            },
            {
              answertitle: "보통이다",
              value: "2",
            },
            {
              answertitle: "전혀 그렇지 않다",
              value: "1",
            },
          ],
          target: "a4",
        },
        {
          number: "14",
          questiontitle: "싫어하는 음식을 입에 넣어주더라도 씹지 않고 그냥 삼켜버리거나 내뱉거나 토해버리는 등의 행동을 보이나요?",
          answers: [
            {
              answertitle: "매우 심하다",
              value: "4",
            },
            {
              answertitle: "심하다",
              value: "3",
            },
            {
              answertitle: "보통이다",
              value: "2",
            },
            {
              answertitle: "전혀 그렇지 않다",
              value: "1",
            },
          ],
          target: "a5",
        },
        {
          number: "15",
          questiontitle: "음식을 먹는 것에 흥미를 느끼지 못하고, 한 두 입만 먹나요?",
          answers: [
            {
              answertitle: "매우 심하다",
              value: "4",
            },
            {
              answertitle: "심하다",
              value: "3",
            },
            {
              answertitle: "보통이다",
              value: "2",
            },
            {
              answertitle: "전혀 그렇지 않다",
              value: "1",
            },
          ],
          target: "a6",
        },
        {
          number: "16",
          questiontitle: "부모님 중에 편식을 하는 분이 계시나요?",
          answers: [
            {
              answertitle: "매우 심하다",
              value: "1",
            },
            {
              answertitle: "심하다",
              value: "2",
            },
            {
              answertitle: "보통이다",
              value: "3",
            },
            {
              answertitle: "전혀 그렇지 않다",
              value: "4",
            },
          ],
          target: "a7",
        },
        {
          number: "17",
          questiontitle: "편식을 하고 있다면 원인은 어디에 있을까요?",
          answers: [
            { answertitle: "먹어본 경험이 없어서 ", value: "1" },
            { answertitle: "입맛에 맞지 않아서", value: "2" },
            {
              answertitle: "그 음식을 먹고 배탈이 난 적이 있어서",
              value: "3",
            },
            { answertitle: "모양이 보기 싫어서", value: "4" },
            { answertitle: "좋아하는 것만 먹고 싶어서", value: "5" },
          ],
          target: "a8",
        },
      ],
    },
    HC07A001: { title: "태아와 임산부 건강관리를 위한 운동", questions: [] },
  },

  policy: {
    "depth-0": {
      p: policy.depth0.p("교보생명 어린이헬스케어서비스"),
      row: [
        {
          title: policy.depth0.title_1.title,
          contents: [
            {
              text: policy.depth0.title_1.text_1,
            },
            {
              table: {
                columns: [
                  { text: "구분" },
                  { text: "수집 · 이용 목적" },
                  { style: { minWidth: "600px" }, text: "개인정보 수집 · 이용 항목" },
                  { text: "보유기간" },
                ],
                row: [
                  [
                    { text: "공통(콜센터)", rowspan: "5", class: "bg-green-ligth" },
                    { text: "건강상담", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별, 전화번호 또는 휴대전화번호, 상담정보, 가족관계", class: "text-left" },
                    { text: "위탁계약 종료시 또는 실효후 5년경과시", rowspan: "5" },
                  ],
                  [
                    { text: "진료예약대행 및 사후관리", class: "bg-green-ligth" },
                    {
                      text: "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 진료의뢰서 또는 기타 의무기록 사본, 진단명, 소견내용",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "진료동행서비스", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 상병명, 병기 및 질병진행정도, 치료방법 및 종류, 치료 부작용 여부, 치료 경과(재발 및 전이여부)",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약대행 및 관리", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] (회원) 이름, 생년월일, 성별, 휴대폰번호, 검진희망일, 검진희망기관, 주소, 관심질환, 병력, 가족력, 수술이력여부, 복용약",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약 만족도 조사", class: "bg-green-ligth" },
                    {
                      text: "[필수] 휴대전화번호, 검진받은 기관, 조사내용",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "WEB서비스", rowspan: "7", class: "bg-green-ligth" },
                    { text: "건강 알리미&태담 서비스", class: "bg-green-ligth" },
                    {
                      text: "[필수] 회원(이름, 휴대폰번호), 자녀(이름, 생년월일), 특이사항",
                      class: "text-left",
                    },
                    { text: "위탁종료시 또는 실효후 5년경과시", rowspan: "7" },
                  ],
                  [
                    { text: "임신,출산,육아집중관리서비스", class: "bg-green-ligth" },
                    {
                      text: "[필수] 자녀이름, 휴대폰번호, 이메일, 선택한 프로그램",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "방문치료 지원서비스", class: "text-left", class: "bg-green-ligth" },
                    {
                      text: "[필수] 회원(이름, 휴대폰번호, 주소, 이메일), 자녀이름, 방문희망병원, 진단명, 방문희망일",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "우리아이 성장차트 관리", class: "text-left", class: "bg-green-ligth" },
                    {
                      text: "[필수] 자녀(이름 키, 몸무게, 측정일, 아이성장 평가 결과)",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "예방접종 스케줄 관리", class: "text-left", class: "bg-green-ligth" },
                    {
                      text: "[필수] 자녀(이름, 생년월일, 예방접종일)",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "예방접종 스케줄 아이등록하기", class: "text-left", class: "bg-green-ligth" },
                    {
                      text: "[필수] 자녀(이름, 생년월일)",
                      class: "text-left",
                    },
                  ],
                ],
              },
            },
            { text: policy.depth0.title_1.text_2 },
            { text: policy.depth0.title_1.text_3 },
            { text: policy.depth0.title_1.text_4 },
            { text: policy.depth0.title_1.text_5 },
          ],
        },
        {
          title: policy.depth0.title_2.title,
          contents: [
            { text: policy.depth0.title_2.text_1 },
            { text: policy.depth0.title_2.text_2 },
            { text: policy.depth0.title_2.text_3 },
            { text: policy.depth0.title_2.text_4 },
            { text: policy.depth0.title_2.text_5 },
          ],
        },
        {
          title: policy.depth0.title_3.title,
          contents: [
            { text: policy.depth0.title_3.text_1 },
            { text: policy.depth0.title_3.text_2 },
            { text: policy.depth0.title_3.text_3 },
            { text: policy.depth0.title_3.text_4 },
            {
              table: {
                columns: [
                  { style: { width: "180px" }, text: "제공받는 자", class: "bg-green-ligth" },
                  { style: { width: "180px" }, text: "제공받는 목적", class: "bg-green-ligth" },
                  { style: { width: "400px" }, text: "제공하는 개인정보의 항목", class: "bg-green-ligth" },
                  { style: { width: "180px" }, text: "보유 및 이용기간", class: "bg-green-ligth" },
                ],
                row: [
                  [
                    { text: "희망 검진예약 기관", button: true },
                    { text: "건강검진 예약 서비스 이용, 검진 전 약품 배송" },
                    { text: "가입기업명, 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 예약일, 검진과목, 주소, 가족관계, 이메일, 검진요청사항	" },
                    { text: "이용 목적 달성 또는 보유기간 경과 시" },
                  ],
                ],
              },
            },
          ],
        },
        {
          title: policy.depth0.title_4.title,
          contents: [
            { text: policy.depth0.title_4.text_1 },
            { text: policy.depth0.title_4.text_2 },
            { table: policy.depth0.title_4.table },
            { text: policy.depth0.title_4.text_3 },
          ],
        },
        {
          title: policy.depth0.title_5.title,
          contents: [
            { text: policy.depth0.title_5.text_1 },
            { text: policy.depth0.title_5.text_2 },
            { text: policy.depth0.title_5.text_3 },
            { text: policy.depth0.title_5.text_4 },
            { text: policy.depth0.title_5.text_5 },
          ],
        },
        {
          title: policy.depth0.title_6.title,
          contents: [{ text: policy.depth0.title_6.text_1 }],
        },
        {
          title: policy.depth0.title_7.title,
          contents: [
            { text: policy.depth0.title_7.text_1 },
            { text: policy.depth0.title_7.text_2 },
            { text: policy.depth0.title_7.text_3 },
            { text: policy.depth0.title_7.text_4 },
          ],
        },
        {
          title: policy.depth0.title_8.title,
          contents: [{ text: policy.depth0.title_8.text_1 }],
        },
        {
          title: policy.depth0.title_9.title,
          contents: [
            { text: policy.depth0.title_9.text_1 },
            { table: policy.depth0.title_9.table },
            { text: policy.depth0.title_9.text_2 },
            { text: policy.depth0.title_9.text_3 },
            { text: policy.depth0.title_9.text_4 },
            { text: policy.depth0.title_9.text_5 },
          ],
        },
        {
          title: policy.depth0.title_10.title,
          contents: [{ text: policy.depth0.title_10.text_1 }, { text: policy.depth0.title_10.text_1 }],
        },
        {
          title: policy.depth0.title_11.title,
          contents: [{ text: policy.depth0.title_11.text_1 }],
        },
        {
          title: policy.depth0.title_12.title,
          contents: [{ text: policy.depth0.title_12.text_1 }, { text: policy.depth0.title_12.text_2 }],
        },
      ],
    },
    "depth-1": {
      p: policy.depth0.p("교보생명 어린이헬스케어서비스"),
      row: [
        {
          title: policy.depth0.title_1.title,
          contents: [
            { text: policy.depth0.title_1.text_1 },
            {
              table: {
                columns: [
                  { style: { minWidth: "180px" }, text: "제공받는 자" },
                  { style: { minWidth: "180px" }, text: "제공받는 목적" },
                  { text: "제공하는 개인정보의 항목" },
                  { style: { minWidth: "180px" }, text: "보유 및 이용기간" },
                ],
                row: [
                  [
                    { text: "WEB/APP서비스", rowspan: "7", class: "bg-green-ligth" },
                    { text: "본인 확인", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별, 내외국인여부, 휴대폰번호<br>[선택] 없음	", class: "text-left" },
                    { text: "확인 즉시 파기" },
                  ],
                  [
                    { text: "어린이 건강도서관 신청", class: "bg-green-ligth" },
                    {
                      text: "[필수] 자녀이름, 이름, 휴대폰번호, 자녀생일(출산예정일)<br>[선택] 없음",
                      class: "text-left",
                    },
                    { text: "위탁종료시 또는 실효후 5년경과 시", rowspan: "6" },
                  ],
                  [
                    { text: "임신.출산/육아집중관리신청", class: "bg-green-ligth" },
                    {
                      text: "[필수] 자녀이름, 휴대폰번호, 이메일, 선택한 프로그램<br>[선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "상담치료신청", class: "bg-green-ligth" },
                    {
                      text: "[필수] 자녀이름, 이름, 휴대폰번호, 전화번호, 방문희망병원, 주소, 이메일, 진단명, 방문희망일<br>[선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "방문 치료지원", class: "bg-green-ligth" },
                    {
                      text: "[필수] 자녀이름, 이름, 휴대폰번호, 전화번호, 방문희망병원, 주소, 이메일, 진단명, 방문희망일<br>[선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "우리아이 성장차트관리", class: "bg-green-ligth" },
                    { text: "[필수] 자녀성함, 키, 몸무게, 측정일, 아이성장 평가결과<br>[선택] 없음", class: "text-left" },
                  ],
                  [
                    { text: "예방접종스케줄 확인", class: "bg-green-ligth" },
                    { text: "[필수] 자녀이름, 생년월일, 예방접종일<br>[선택] 없음", class: "text-left" },
                  ],
                ],
              },
            },
            { text: policy.depth0.title_1.text_2 },
            { text: policy.depth0.title_1.text_3 },
            { text: policy.depth0.title_1.text_4 },
            { text: policy.depth0.title_1.text_5 },
          ],
        },
        {
          title: policy.depth0.title_2.title,
          contents: [
            { text: policy.depth0.title_2.text_1 },
            { text: policy.depth0.title_2.text_2 },
            { text: policy.depth0.title_2.text_3 },
            { text: policy.depth0.title_2.text_4 },
          ],
        },
        {
          title: policy.depth0.title_3.title,
          contents: [
            { text: policy.depth0.title_3.text_1 },
            { text: policy.depth0.title_3.text_2 },
            { text: policy.depth0.title_3.text_3 },
            { text: policy.depth0.title_3.text_4 },
            {
              table: {
                columns: [
                  { style: { minWidth: "180px" }, text: "제공받는 자", class: "bg-green-ligth" },
                  { style: { minWidth: "180px" }, text: "제공받는 목적", class: "bg-green-ligth" },
                  { text: "제공하는 개인정보의 항목", class: "bg-green-ligth" },
                  { style: { minWidth: "180px" }, text: "보유 및 이용기간", class: "bg-green-ligth" },
                ],
                row: [
                  [
                    { text: "희망 검진예약 기관" },
                    { text: "건강검진 예약 서비스 이용" },
                    { text: "가입기업명, 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 예약일, 검진과목, 주소, 가족관계, 이메일, 검진요청사항" },
                    { text: "이용 목적 달성 또는 보유기간 경과 시" },
                  ],
                ],
              },
            },
          ],
        },
        {
          title: policy.depth0.title_4.title,
          contents: [
            { text: policy.depth0.title_4.text_1 },
            { text: policy.depth0.title_4.text_2 },
            {
              table: policy.depth0.title_4.table,
            },
            { text: policy.depth0.title_4.text_3 },
          ],
        },
        {
          title: policy.depth0.title_5.title,
          contents: [
            { text: policy.depth1.title_5.text_1 },
            { text: policy.depth1.title_5.text_2 },
            { text: policy.depth1.title_5.text_3 },
            { text: policy.depth1.title_5.text_4 },
            { text: policy.depth1.title_5.text_5 },
            { text: policy.depth1.title_5.text_6 },
          ],
        },
        {
          title: policy.depth0.title_6.title,
          contents: [{ text: policy.depth0.title_6.text_1 }],
        },
        {
          title: policy.depth0.title_7.title,
          contents: [
            { text: policy.depth0.title_7.text_1 },
            { text: policy.depth0.title_7.text_2 },
            { text: policy.depth0.title_7.text_3 },
            { text: policy.depth0.title_7.text_4 },
          ],
        },
        {
          title: policy.depth0.title_8.title,
          contents: [
            { text: policy.depth0.title_8.text_1 },
            { text: policy.depth0.title_8.text_2 },
            { text: policy.depth0.title_8.text_3 },
            { text: policy.depth0.title_8.text_4 },
          ],
        },
        {
          title: policy.depth0.title_9.title,
          contents: [{ text: policy.depth0.title_9.text_1 }, { table: policy.depth0.title_9.table }],
        },
        {
          title: policy.depth1.title_10.title,
          contents: [{ text: policy.depth1.title_10.text_1 }, { table: policy.depth1.title_10.table }],
        },
        {
          title: policy.depth1.title_11.title,
          contents: [{ text: policy.depth1.title_11.text_1 }, { table: policy.depth1.title_11.table }],
        },
        {
          title: policy.depth1.title_12.title,
          contents: [{ text: policy.depth0.title_10.text_1 }, { text: policy.depth0.title_10.text_2 }],
        },
        {
          title: policy.depth1.title_13.title,
          contents: [{ text: policy.depth0.title_11.text_1 }],
        },
        {
          title: policy.depth1.title_13.title,
          contents: [
            { text: policy.depth0.title_11.text_1 },
            { text: policy.depth0.title_11.text_2 },
            { text: policy.depth0.title_11.text_3 },
            { text: policy.depth0.title_11.text_4 },
            { text: policy.depth0.title_11.text_5 },
            { text: policy.depth0.title_11.text_6 },
          ],
        },
        {
          title: "13. 개인정보 처리방침 변경",
          contents: [
            { text: "① 이 개인정보 처리방침은 2022. 01. 07부터 적용됩니다." },
            { text: "② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다." },
          ],
        },
      ],
    },
    "depth-2": {
      p: policy.depth1.p("교보어린이헬스케어사이트", "2019년 1월 30일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth2.title_4.title,
          contents: [
            { text: policy.depth2.title_4.text_1 },
            { text: policy.depth2.title_4.text_2 },
            { text: policy.depth2.title_4.text_3 },
            { text: policy.depth2.title_4.text_4 },
            { text: policy.depth2.title_4.text_5 },
            { text: policy.depth2.title_4.text_6 },
            { text: policy.depth2.title_4.text_7 },
            { text: policy.depth2.title_4.text_8 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth2.title_8.title,
          contents: [
            { text: policy.depth2.title_8.text_1 },
            { text: policy.depth2.title_8.text_2 },
            { text: policy.depth2.title_8.text_3 },
            { text: policy.depth2.title_8.text_4 },
            { text: policy.depth2.title_8.text_5 },
            { text: policy.depth2.title_8.text_6 },
            { text: policy.depth2.title_8.text_7 },
            { text: policy.depth2.title_8.text_8 },
            { text: policy.depth2.title_8.text_9 },
            { text: policy.depth2.title_8.text_10 },
            { text: policy.depth2.title_8.text_11 },
            { text: policy.depth2.title_8.text_12 },
            { text: policy.depth2.title_8.text_13 },
            { text: policy.depth2.title_8.text_14 },
            { text: policy.depth2.title_8.text_15 },
            { text: policy.depth2.title_8.text_16 },
            { text: policy.depth2.title_8.text_17 },
            { text: policy.depth2.title_8.text_18 },
            { text: policy.depth2.title_8.text_19 },
            { text: policy.depth2.title_8.text_20 },
            { text: policy.depth2.title_8.text_21 },
            { text: policy.depth2.title_8.text_22 },
            { text: policy.depth2.title_8.text_23 },
            { text: policy.depth2.title_8.text_24 },
          ],
        },
      ],
    },
    "depth-3": {
      p: policy.depth3.p("교보어린이헬스케어사이트", "2017년 12월 4일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth2.title_4.title,
          contents: [
            { text: policy.depth2.title_4.text_1 },
            { text: policy.depth2.title_4.text_2 },
            { text: policy.depth2.title_4.text_3 },
            { text: policy.depth2.title_4.text_4 },
            { text: policy.depth2.title_4.text_5 },
            { text: policy.depth2.title_4.text_6 },
            { text: policy.depth2.title_4.text_7 },
            { text: policy.depth2.title_4.text_8 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth2.title_8.title,
          contents: [
            { text: policy.depth2.title_8.text_1 },
            { text: policy.depth2.title_8.text_2 },
            { text: policy.depth2.title_8.text_3 },
            { text: policy.depth2.title_8.text_4 },
            { text: policy.depth2.title_8.text_5 },
            { text: policy.depth2.title_8.text_6 },
            { text: policy.depth2.title_8.text_7 },
            { text: policy.depth2.title_8.text_8 },
            { text: policy.depth2.title_8.text_9 },
            { text: policy.depth2.title_8.text_10 },
            { text: policy.depth2.title_8.text_11 },
            { text: policy.depth2.title_8.text_12 },
            { text: policy.depth2.title_8.text_13 },
            { text: policy.depth2.title_8.text_14 },
            { text: policy.depth2.title_8.text_15 },
            { text: policy.depth2.title_8.text_16 },
            { text: policy.depth2.title_8.text_17 },
            { text: policy.depth2.title_8.text_18 },
            { text: policy.depth2.title_8.text_19 },
            { text: policy.depth2.title_8.text_20 },
            { text: policy.depth2.title_8.text_21 },
            { text: policy.depth2.title_8.text_22 },
            { text: policy.depth2.title_8.text_23 },
            { text: policy.depth2.title_8.text_24 },
          ],
        },
      ],
    },
    "depth-4": {
      p: policy.depth1.p("교보어린이헬스케어사이트", "2017년 8월 25일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth4.title_4.title,
          contents: [
            { text: policy.depth4.title_4.text_1 },
            { text: policy.depth4.title_4.text_2 },
            { text: policy.depth4.title_4.text_3 },
            { text: policy.depth4.title_4.text_4 },
            { text: policy.depth4.title_4.text_5 },
            { text: policy.depth4.title_4.text_6 },
            { text: policy.depth4.title_4.text_7 },
            { text: policy.depth4.title_4.text_8 },
            { text: policy.depth4.title_4.text_9 },
            { text: policy.depth4.title_4.text_10 },
            { text: policy.depth4.title_4.text_11 },
            { text: policy.depth4.title_4.text_12 },
            { text: policy.depth4.title_4.text_13 },
            { text: policy.depth4.title_4.text_14 },
            { text: policy.depth4.title_4.text_15 },
            { text: policy.depth4.title_4.text_16 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth4.title_8.title,
          contents: [
            { text: policy.depth4.title_8.text_1 },
            { text: policy.depth4.title_8.text_2 },
            { text: policy.depth4.title_8.text_3 },
            { text: policy.depth4.title_8.text_4 },
            { text: policy.depth4.title_8.text_5 },
            { text: policy.depth4.title_8.text_6 },
            { text: policy.depth4.title_8.text_7 },
            { text: policy.depth4.title_8.text_8 },
            { text: policy.depth4.title_8.text_9 },
            { text: policy.depth4.title_8.text_10 },
            { text: policy.depth4.title_8.text_11 },
            { text: policy.depth4.title_8.text_12 },
            { text: policy.depth4.title_8.text_13 },
            { text: policy.depth4.title_8.text_14 },
            { text: policy.depth4.title_8.text_15 },
            { text: policy.depth4.title_8.text_16 },
          ],
        },
      ],
    },
  },
};
