<template>
  <b-container class="hc-container">
    <hc-search-form
      @hanldeSearch="hanldeSearch"
      :lists="lists"
    ></hc-search-form>
    <template v-if="items.length">
      <!-- list -->
      <div class="py-3 border-bottom border-green  flex-between">
        <span class="gray fs-18 fs-md-16"
          >총 {{ totalCount }}건의 게시글이 있습니다.</span
        >
      </div>
      <div v-if="key === 'online'">
        <!-- 온라인 컨설팅 리스트 -->
        <hc-list
          v-for="row in items"
          :key="row.coun_key"
          :type="true"
          :isAnswer="!!row.replay_key"
          :answer="true"
          :title="row.title"
          :date="row.regdate"
          @handleClick="
            handleClick({ replay_key: row.replay_key, coun_key: row.coun_key })
          "
        ></hc-list>
        <!-- // 온라인 컨설팅 리스트 -->
      </div>
      <div v-else>
        <hc-best-list
          v-for="row in items"
          :key="row.coun_key"
          :title="row.title"
          :column="row.cnslt_nm"
          @handleClick="handleClick(row)"
        ></hc-best-list>
      </div>

      <div v-if="key === 'online'" class="flex-center mt-5">
        <router-link
          :to="{ name: `${$route.name}-write`, params: { ...$route.params } }"
          class="blue-submit-btn fs-20 fs-md-16 px-5"
          >상담글 작성</router-link
        >
      </div>
      <!-- 페이지 이동 버튼 -->
      <hc-pagination
        v-if="totalPage > 1"
        :pageNum="totalPage"
        class="d-none d-md-flex"
      ></hc-pagination>
      <div
        v-if="totalCount > pageSize"
        class="d-felx d-md-none flex-center pt-4"
      >
        <b-btn
          variant="link fs-20 border-bottom border-blue blue"
          @click="handleBtnClick"
          >더 보기</b-btn
        >
      </div>
      <!-- // 페이지 이동 버튼 -->
      <!-- // list -->
    </template>
    <template v-else-if="!loading && searchString">
      <div class="border-bottom border-green py-3">
        <span class="mr-3 fs-18  fs-md-16 gray"
          >총 <em class="">{{ totalCount }}</em
          >건의 게시글이 있습니다.</span
        >
      </div>
      <p class="py-5 text-center">검색된 내용이 없습니다.</p>
    </template>
    <template v-else>
      <div class="border-bottom border-green py-3">
        <b-skeleton :animation="null" width="30%" height="2rem"></b-skeleton>
      </div>
      <div>
        <skeleton-list></skeleton-list>
      </div>
    </template>
  </b-container>
</template>

<script>
import HcList from "@/components/common/HcList";
import HcBestList from "@/components/common/HcBestList";
import HcPagination from "@/components/common/HcPagination";
import HcSearchForm from "@/components/common/HcSearchForm";
import SkeletonList from "@/components/common/SkeletonList";
import common from "@/mixins/common.js";

export default {
  mixins: [common],
  components: {
    HcList,
    HcBestList,
    HcPagination,
    HcSearchForm,
    SkeletonList,
  },
  data() {
    return {
      loading: false,
      items: [],
      totalCount: 0,
      pageSize: 15,
      nowTitle: "",
    };
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    page() {
      return !this.$route.query.page ? 1 : Number(this.$route.query.page);
    },
    totalPage() {
      return Math.floor(
        this.totalCount / this.pageSize +
          (this.totalCount % this.pageSize > 0 ? 1 : 0)
      );
    },
    searchType() {
      return this.$route.query.searchType;
    },
    searchString() {
      return !this.$route.query.searchString
        ? ""
        : this.$route.query.searchString;
    },
    lists() {
      const defaultLists = [
        { value: "all", text: "전체" },
        { value: "title", text: "제목" },
        { value: "content", text: "내용" },
      ];
      const BestLists = [
        { value: "all", text: "전체" },
        { value: "keyword", text: "증상별" },
        { value: "cnsltnm", text: "진료과목별" },
      ];
      return this.key === "online" ? defaultLists : BestLists;
    },
    compareTitle() {
      return this.nowTitle === this.title;
    }
  },
  methods: {
    handleBtnClick() {
      this.pageSize += 5;
      this.getList(1, this.pageSize, this.searchType, this.searchString);
    },
    handleClick({ coun_key, replay_key, cn_sn }) {
      this.$router.push({
        name: "consult-detail",
        params: { key: this.key },
        query: {
          coun_key,
          replay_key,
          cn_sn,
        },
      });
    },
    hanldeSearch({ searchString, searchType }) {
      this.$router.push({
        ...this.$rotue,
        query: { searchString, searchType, page: "1" },
      });
      this.getList(this.page, this.pageSize, searchType, searchString);
    },
    async getList(
      page = 1,
      pageSize = 15,
      searchType = "all",
      searchString = ""
    ) {
      const { kyoboApi } = this.getApis();

      this.loading = true;
      this.items = [];
      this.totalCount = 0;

      const body = {
        page,
        pageSize,
        searchType,
        searchString,
      };
      const { data } = await kyoboApi.onlineCounselling[this.key].list(body);

      this.totalCount = data?.totalCnt || 0;
      this.items = data?.rptList || [];
      this.loading = false;
    },
    setTitle() {
      this.nowTitle = this.title
    }
  },
  watch: {
    $route() {
      this.getList(
        this.page,
        this.pageSize,
        this.searchType,
        this.searchString
      );
    },
  },
  mounted() {
    if (!this.compareTitle) {
      this.pageViewTrack(this.title);
      this.setTitle();
    }
    
    this.getList(this.page, this.pageSize, this.searchType, this.searchString);
  },
  updated() {
    if (!this.compareTitle) {
      this.pageViewTrack(this.title);
      this.setTitle();
    }
  }
};
</script>

<style lang="scss" scoped></style>
