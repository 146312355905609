export const state = {
  loginState: false
};

export const mutations = {
  set(s, [k, v]) {
    s[k] = v;
  },
};

export const getters = {
  getLoginState: (s) => s.loginState,
};

export const actions = {};