<template>
  <div class="hc-date-select">
    <b-dropdown
      class="rounded-10 flex-center border border-2 border-green-2"
      variant="link"
      toggle-class="text-decoration-none black"
      menu-class="rounded-10"
      no-caret
      @hide="isShow = false"
      @show="isShow = true"
    >
      <template #button-content variant="link">
        <div class="select-box h-100 flex-between">
          <div
            class="bg-img"
            :style="[
              isShow
                ? {
                    transform: 'rotate(90deg)',
                  }
                : {
                    transform: 'rotate(270deg)',
                  },
              {
                backgroundImage: `url(${require('@/assets/img/common/ic-gray-arrow.svg')})`,
                width: '3rem',
                paddingBottom: '3rem',
              },
            ]"
          ></div>
          <span v-if="selected" class="fs-18 fw-7 gray">
            {{ selected }}
          </span>
          <span class="fs-18 fw-7 gray mr-3">{{ name }}</span>
        </div>
      </template>

      <b-dropdown-item-button
        v-for="(row, idx) in items"
        variant="link"
        :value="row.value"
        :key="idx"
        @click="handleClick"
        class="fs-18"
        >{{ row }}</b-dropdown-item-button
      >
    </b-dropdown>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "년",
    },
    endNum: {
      type: Number,
      default: 12,
    },
    startNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isShow: false,
      selected: null || "",
    };
  },
  computed: {
    items() {
      const total = this.endNum - this.startNum + 1;
      return [...Array(total).keys()].map((i) => i + this.startNum);
    },
  },
  methods: {
    handleClick(e) {
      this.selected = e.target.innerText;
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style lang="scss">
.hc-date-select {
  .dropdown-toggle {
    height: 3.563rem;
  }
  .dropdown-menu {
    top: 5px !important;
    min-width: auto !important;
    width: 100%;
    max-height: 200px !important;
    overflow-y: scroll;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: inherit !important;
    text-decoration: none;
    background-color: inherit !important;
  }
}
</style>
