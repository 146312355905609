<template>
  <b-container class="hc-container">
    <!-- 아이 등록하기 -->
    <b-row class="mx-0 border rounded-10 p-3 mb-5" align-v="center">
      <b-col cols="12" sm="9" class="p-0">
        <span class="fs-20"
          >아이를 등록하고 예방접종 스케줄을 확인해보세요.</span
        >
      </b-col>
      <b-col
        cols="12"
        sm="3"
        class="text-center text-md-right mt-2 mt-sm-0 p-0"
      >
        <b-btn
          variant="link"
          class="blue-submit-btn py-3 px-4 fs-20 fs-md-18"
          @click="$bvModal.show('modal-child-registration')"
          >아이 등록하기</b-btn
        >
      </b-col>
    </b-row>
    <!-- // 아이 등록하기 -->
    <template v-if="!loading">
      <!-- 아이 선택 -->
      <div>
        <hash-title title="아이 선택" class="mb-3"></hash-title>

        <div v-if="childs.length" class="my-children mb-5">
          <b-row class="mx-n2 answer-list">
            <b-col
              class="px-2 py-2"
              cols="6"
              md="3"
              v-for="row in childs"
              :key="row.name"
            >
              <b-btn
                variant="link"
                :class="[
                  { active: row.idx === selected.idx },
                  'd-block w-100 p-3 rounded-10 text-left gray-5 child-box',
                ]"
                :style="{
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                }"
                @click="handleCardClick(row)"
              >
                <div class="flex-between">
                  <span>{{ row.babyname }}</span>
                  <b-btn
                    variant="link"
                    :class="[
                      { active: row.idx === selected.idx },
                      'bg-white d-block',
                    ]"
                    @click="handleDelete(row.idx)"
                  >
                    <i
                      :class="[
                        row.idx === selected.idx ? 'block' : 'gray-5',
                        'icon-cancel-1 d-block fs-20 mx-0',
                      ]"
                    ></i>
                  </b-btn>
                </div>
                <div>{{ formatedDate(row.birthDay) }}</div>
              </b-btn>
            </b-col>
          </b-row>
        </div>
        <p v-else class="py-3">
          등록된 아이가 없습니다.
        </p>
      </div>
      <!-- // 아이 선택 -->

      <!-- 예방접종 스케줄 -->
      <div v-if="selected">
        <h6 class="fs-24 fw-7 py-3">
          <span>{{ selected && selected.babyname }}</span> 님의 예방접종
          스케줄입니다.
        </h6>

        <p class="red pb-5 fs-18">
          ※ 해당 회차를 클릭하시면 실제 예방접종일을 입력하실 수 있습니다.
        </p>

        <b-row class="mx-0 py-3">
          <b-col
            v-for="col in ['제시 접종일', '실제 접종일']"
            :key="col"
            cols="12"
            sm="3"
            class="mb-2 mb-sm-0"
          >
            <div class="flex-start">
              <span
                :class="[
                  col === '제시 접종일' ? 'bg-green-4' : 'bg-pink',
                  'bg-img d-inline-block mr-2 rounded',
                ]"
                :style="{
                  width: '4rem',
                  paddingBottom: '1.5rem',
                }"
              ></span>
              <span>제시 접종일</span>
            </div>
          </b-col>
        </b-row>
        <schedule-table
          :schedule="schedule"
          :selectedIdx="selected && selected.idx"
          @refresh="init"
        ></schedule-table>
      </div>
    </template>
    <skeleton-check-schedule v-else></skeleton-check-schedule>
    <!-- // 예방접종 스케줄 -->
    <modal-child-registration @refresh="init"></modal-child-registration>
  </b-container>
</template>

<script>
import SkeletonCheckSchedule from "@/components/common/SkeletonCheckSchedule";
import ScheduleTable from "@/components/kyobokidscare/ScheduleTable";
import ModalChildRegistration from "@/components/kyobokidscare/modal/ModalChildRegistration";
import { showToast } from "@/plugins/custom-toast";
import { formatedDate } from "@/utils/dayjs";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    SkeletonCheckSchedule,
    ModalChildRegistration,
    ScheduleTable,
  },
  data() {
    return {
      selected: null,
      childs: [],
      loading: false,
      schedule: null,
    };
  },
  computed: {
    apis() {
      const { kyoboApi } = this.getApis();
      return kyoboApi.program.schdule;
    },
  },
  methods: {
    async init() {
      this.loading = true;
      const childLength = await this.getChildList();
      childLength && (await this.getSchdule(this.selected.idx));
      this.loading = false;
    },
    async deleteChild(idx) {
      const {
        data: { message },
      } = await this.apis.childDelete(idx);

      showToast({ title: message });
    },
    async getChildList() {
      const { data } = await this.apis.childList();
      this.childs = data.list;
      this.selected = data.list?.[0] ?? null;
      return data.list.length;
    },
    async getSchdule(idx) {
      const {
        data: { schedule },
      } = await this.apis.getList(idx);

      this.schedule = schedule;
    },
    handleCardClick(row) {
      if (this.selected.idx === row.idx) return;
      this.loading = true;
      this.selected = row;
      this.getSchdule(row.idx);
      this.loading = false;
    },
    handleDelete(idx) {
      this.$root.$emit(
        "showModalAlert",
        "아이정보를 삭제하시겠습니까?",
        true,
        // If Ok's Button clicked, callBack function call
        () => {
          this.handleDeleteCb(idx);
        }
      );
    },
    async handleDeleteCb(idx) {
      await this.deleteChild(idx);
      this.init();
    },
    formatedDate,
  },
  mounted() {
    this.pageViewTrack(this.title);
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.my-children {
  .active {
    border-color: #000 !important;
    color: #000 !important;
  }
}
.child-box {
  transition: transform 0.8s, box-shadow 0.8s;
  &:hover {
    transform: translateY(-10px);
  }
}
</style>
