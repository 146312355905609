var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',{staticClass:"gray fs-20 fw-7 mb-3"},[_vm._v(" 나의 생년월일은? ")]),_c('div',{staticClass:"flex-start gap-1 mb-5"},_vm._l((_vm.selects),function(select,i){return _c('div',{key:i},[_c('hc-select-default',_vm._b({attrs:{"rules":"required","styles":{ minWidth: _vm.calcHeight('6rem') },"dropdownClass":_vm.data[select.target]
            ? 'border border-green rounded-10 p-3 w-100 fs-20'
            : 'border border-black rounded-10 p-3 w-100 fs-20'},on:{"change":function($event){return _vm.$emit('input', _vm.getValueObj)}},model:{value:(_vm.data[select.target]),callback:function ($$v) {_vm.$set(_vm.data, select.target, $$v)},expression:"data[select.target]"}},'hc-select-default',{ ...select },false))],1)}),0),_c('div',{staticClass:"flex-start gap-3"},_vm._l((_vm.inputs),function(input,i){return _c('div',{key:i},[_c('h5',{staticClass:"gray fs-20 fw-7 mb-3"},[_vm._v(" "+_vm._s(input.questiontitle)+" ")]),_c('div',{staticClass:"my-3 flex-start"},[_c('hc-input-default',{staticClass:"black",attrs:{"type":"number","rules":"max:3|required","name":input.target,"styles":{
            maxWidth: _vm.calcHeight('6rem'),
            height: _vm.calcHeight('3.5rem'),
          },"inputClass":_vm.data[input.target]
              ? 'border-green rounded-10 p-3 fs-20'
              : 'border-black rounded-10 p-3 fs-20'},on:{"change":function($event){return _vm.$emit('input', _vm.getValueObj)}},model:{value:(_vm.data[input.target]),callback:function ($$v) {_vm.$set(_vm.data, input.target, $$v)},expression:"data[input.target]"}}),_c('span',{staticClass:"ml-3 fs-20"},[_vm._v(_vm._s(input.label))])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }