import Vue from "vue";

import { BVToastPlugin } from "bootstrap-vue";

Vue.use(BVToastPlugin);
const _vm = new Vue();

export const showToast = ({ title, variant = "link" }) => {
  const h = _vm.$createElement;
  // Create a ID with a incremented count

  // Create the custom close button
  const $toast = h(
    "div",
    {
      class: "py-2 gray border-0",
    },
    title
  );

  // Create the toast
  _vm.$bvToast.toast([$toast], {
    toaster: "b-toaster-top-center",
    solid: true,
    noCloseButton: true,
    autoHideDelay: 2000,
    toastClass: "shadow-md border-0",
    variant,
  });
};
