<template>
  <b-modal
    :id="modalId"
    size="xl"
    :hide-footer="true"
    @hide="hideCb"
    @show="showCb"
    body-class="p-0"
    header-class="p-0 d-block border-bottom-0"
    scrollable
    centered
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-4 px-md-5 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <h3 class="flex-start fs-24 fw-7 mt-1">
          <span v-if="['treatment', 'health-care'].includes(key)"
            >방문치료지원 신청</span
          >
          <span v-else>상담치료지원 신청</span>
        </h3>
        <!-- // 모달 헤더 타이틀 -->
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <w-form
      v-if="info"
      v-slot="{ invalid }"
      @submit="submit($event)"
      class="px-4 px-md-5"
    >
      <!-- 신청자 정보 리스트 -->
      <h6 class="flex-start flex-wrap fs-20 mb-3">
        <span>
          받아보고싶은 프로그램은
        </span>
        <span class="mx-2">
          <hc-select-default
            v-model="input.program"
            rules="required"
            :items="serviceOptions"
            :hashTitle="true"
            inputClass="fs-20 fw-7"
          ></hc-select-default>
        </span>
        <span>
          입니다.
        </span>
      </h6>
      <hash-title :title="'신청자 정보'" :isHash="false"></hash-title>
      <b-row
        align-v="center"
        class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18"
      >
        <b-col cols="4">자녀이름</b-col>
        <b-col cols="8" v-if="childs && childs.length === 1">
          {{ childs[0].text }}
        </b-col>
        <b-col cols="8" v-else>
          <hc-select-default
            v-model="input.children"
            :items="childs"
            rules="required"
            inputClass="fs-20 fs-md-18"
          ></hc-select-default>
        </b-col>
      </b-row>
      <!-- 회원이름 -->
      <b-row
        align-v="center"
        class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18"
      >
        <b-col cols="4">회원 이름</b-col>
        <b-col cols="8">
          {{ info ? info.memname : "-" }}
        </b-col>
      </b-row>
      <!-- // 회원이름 -->
      <!-- 휴대전화 -->
      <b-row
        align-v="center"
        class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18"
      >
        <b-col cols="4">휴대전화</b-col>
        <b-col cols="8">
          {{ info ? info.memhp : "-" }}
        </b-col>
      </b-row>
      <!-- // 휴대전화 -->
      <!-- 방문희망병원 -->
      <b-row
        align-v="center"
        class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18"
      >
        <b-col cols="4">방문희망병원</b-col>
        <b-col cols="8">
          <hc-select-default
            v-model="input.hosKey"
            :items="mappedHospitalInfo"
            rules="required"
            inputClass="fs-20 fs-md-18"
          ></hc-select-default>
        </b-col>
      </b-row>
      <!-- // 방문희망병원 -->

      <!-- 주소 -->
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18">
        <b-col cols="4">주소</b-col>
        <b-col cols="8">
          <div class="flex-start gap-1 mb-2">
            <hc-input-default
              :style="{ width: '5rem' }"
              v-model="input.postnum"
              inputClass="fs-20 fs-md-18"
              readonly
            />
            <b-btn
              variant="link"
              class="green-btn-2 px-3 py-2 fs-18 fs-md-20"
              @click="$bvModal.show('address-modal')"
              >주소찾기</b-btn
            >
          </div>
          <div class="mb-2">
            <hc-input-default
              v-model="input.addr1"
              :isErrorMessage="false"
              rules="required"
              inputClass="fs-20 fs-md-18"
              readonly
            />
          </div>
          <div>
            <hc-input-default
              v-model="input.addr2"
              rules="required"
              inputClass="fs-20 fs-md-18"
              :isErrorMessage="false"
              placeholder="나머지 주소를 입력해주세요."
            />
          </div>
        </b-col>
      </b-row>
      <!-- 이메일 -->
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18">
        <b-col cols="4">E-mail</b-col>
        <b-col cols="8">
          <div class="flex-start flex-wrap gap-1">
            <hc-input-default
              :style="{ width: '8rem' }"
              v-model="input.email1"
              name="email"
              rules="required"
              inputClass="fs-20 fs-md-18"
              :isErrorMessage="false"
              :styles="{ minWdith: '5rem' }"
            />
            <span>@</span>
            <hc-input-default
              :style="{ width: '8rem' }"
              v-model="input.email2"
              name="email"
              rules="required"
              inputClass="fs-20 fs-md-18"
              :readonly="emailType.value !== '직접입력'"
              :isErrorMessage="false"
              :styles="{ minWdith: '5rem' }"
            />
            <hc-select-default
              @change="handleEmail"
              v-model="emailType"
              :items="emailOptions"
              :selected="'직접입력'"
              inputClass="fs-20 fs-md-18"
            ></hc-select-default>
          </div>
        </b-col>
      </b-row>
      <!-- 진단 명 -->
      <b-row
        align-v="center"
        class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18"
      >
        <b-col cols="4">진단명</b-col>
        <b-col cols="8">
          <div class="flex-start">
            <hc-select-default
              v-model="input.disease1"
              :items="mappedDiseaseInfo1"
              @change="getDisease2"
              rules="required"
              inputClass="fs-20 fs-md-18"
              class="mr-3"
            ></hc-select-default>

            <hc-select-default
              v-if="input.disease1"
              v-model="input.disease2"
              rules="required"
              :items="mappedDiseaseInfo2"
              inputClass="fs-20 fs-md-18"
            ></hc-select-default>
          </div>
        </b-col>
      </b-row>
      <!-- 방문 희망일 -->
      <b-row
        align-v="center"
        class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18"
      >
        <b-col cols="4">방문 희망일</b-col>
        <b-col cols="8">
          <b-btn
            variant="link"
            class="border rounded flex-between px-2 black"
            @click="$bvModal.show('modal-date-input')"
          >
            <hc-input-default
              inputClass="border-0 fs-20 fs-md-18"
              rules="required"
              readonly
              :style="{
                maxWidth: '10rem',
              }"
              :value="input.hopeday"
              :isErrorMessage="false"
            />
            <i class="icon-calendar"></i>
          </b-btn>
          <modal-date-picker
            :id="'modal-date-input'"
            v-model="input.hopeday"
          ></modal-date-picker>
        </b-col>
      </b-row>
      <!-- 특이사항 -->
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18">
        <b-col cols="12" md="4">비고(특이사항)</b-col>
        <b-col cols="12" md="8">
          <w-textarea
            no-resize
            v-model="input.reason"
            inputClass="fs-20 fs-md-18"
            placeholder="내용을 입력하세요."
            class="mt-3 mt-md-0"
            :styles="{
              minHeight: '300px',
            }"
          ></w-textarea>
        </b-col>
      </b-row>
      <div class="red fs-18 py-3">
        <p>
          ※ 홈페이지 및 어린이헬스콜센터 (1588-4733)를 통해서 신청이 가능합니다.
        </p>
      </div>

      <!-- 동의하기 -->
      <div v-for="(val, key) in consent" :key="key" class="flex-center">
        <w-checkbox
          :label="
            key === 'privacy'
              ? '개인정보 수집 및 이용동의 (필수)'
              : '민감정보 수집 및 이용동의 (필수)'
          "
          :name="
            key === 'privacy'
              ? '개인정보 수집 및 이용동의 (필수)'
              : '민감정보 수집 및 이용동의 (필수)'
          "
          v-model="consent[key]"
          class="mr-2"
        >
          <a class="fs-20 fs-md-18">{{
            key === "privacy"
              ? "개인정보 수집 및 이용동의 (필수)"
              : "민감정보 수집 및 이용동의 (필수)"
          }}</a>
        </w-checkbox>
        <b-btn variant="link" @click="handleModal(key)">
          <div
            class="bg-img"
            :style="{
              width: calcHeight('3rem'),
              paddingBottom: calcHeight('3rem'),
              backgroundImage: `url(${require('@/assets/img/common/ic-agree-zoom.svg')})`,
            }"
          ></div>
        </b-btn>
      </div>
      <!-- // 동의하기 -->
      <!-- 신청하기 -->
      <div class="flex-center pb-5 pt-4">
        <b-btn
          variant="link blue-submit-btn px-5 fs-20"
          @click="submit($event)"
          :disabled="invalid"
          >신청하기</b-btn
        >
      </div>
      <!-- // 신청하기 -->
    </w-form>
    <skeleton-form v-else class="px-4 px-md-5"></skeleton-form>

    <!-- // 모달 바디 -->
    <modal-term
      @agree="handleAgree"
      :isPrivacy="isPrivacy"
      :consentKey="
        ['treatment', 'health-care'].includes(key) ? 'treatment' : 'counseling'
      "
    ></modal-term>
    <modal-address @sendAddr="setAddr"></modal-address>
  </b-modal>
</template>

<script>
import skeletonForm from "@/components/common/SkeletonForm";
import ModalAddress from "@/components/common/modal/ModalAddress";
import ModalTerm from "@/components/common/modal/ModalTerm";
import { showToast } from "@/plugins/custom-toast";

export default {
  components: {
    skeletonForm,
    ModalTerm,
    ModalAddress,
  },
  data() {
    return {
      modalId: "modal-counseling",
      info: null,
      hospitalInfo: null,
      isPrivacy: true,
      diseaseInfos1: null,
      diseaseInfos2: null,
      consent: {
        privacy: false,
        sensitive: false,
      },
      input: {
        children: null,
        program: null,
        hosKey: "",
        disease1: "",
        disease2: "",
        email1: "",
        email2: "",
        reason: "",
        postnum: "",
        addr1: "",
        addr2: "",
        hopeday: null,
      },
      emailType: "",
      serviceOptions: [
        { value: null, text: "선택" },
        { value: "입원시 간호", text: "입원시 간호" },
        { value: "퇴원시간호", text: "퇴원시간호" },
        { value: "방문교육 및 관리", text: "방문교육 및 관리" },
        {
          value: "미숙아, 저체중아 방문간호",
          text: "미숙아, 저체중아 방문간호",
        },
      ],
      emailOptions: [
        { value: "직접입력", text: "직접입력" },
        { value: "gmail.com", text: "gmail.com" },
        { value: "naver.com", text: "naver.com" },
        { value: "daum.net", text: "daum.net" },
        { value: "nate.com", text: "nate.com" },
      ],
    };
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    disease1() {
      return this.input.disease1;
    },
    childs() {
      const key = this.$i18n.locale === "kyobokidscare" ? "childs" : "children";
      return this.info?.[key]?.map((val) => ({
        value: val.joinserial ? val.joinserial : "-",
        text: val.contactname ? val.contactname : "-",
      }));
    },
    apis() {
      const {
        kyoboApi: { program },
      } = this.getApis();
      return program;
    },
    mappedHospitalInfo() {
      return this.hospitalInfo?.map((val) => ({
        value: val ? val.hosKey : "-",
        text: val ? val.name : "-",
      }));
    },
    mappedDiseaseInfo1() {
      return this.diseaseInfos1?.map((val) => ({
        value: val.cate1key,
        text: val.title,
      }));
    },
    mappedDiseaseInfo2() {
      return this.diseaseInfos2?.map((val) => ({
        value: val.cate2key,
        text: val.title,
      }));
    },
  },
  methods: {
    init() {
      Promise.all([this.getInfo(), this.getHospitalInfo(), this.getDisease()]);
    },
    async getInfo() {
      const { data } = await this.apis.common.info();
      this.info = data.info;
    },
    async getHospitalInfo() {
      const { data } = await this.apis.counseling.hospitalInfo();
      this.hospitalInfo = data.infos;
    },
    async getDisease() {
      this.diseaseInfos2 = null;
      const { data } = await this.apis.counseling.diseaseCategory1();
      this.diseaseInfos1 = data.infos;
    },
    async getDisease2() {
      const cate1key = this.input.disease1.value;
      if (!cate1key) return;
      const { data } = await this.apis.counseling.diseaseCategory2(cate1key);
      this.diseaseInfos2 = data.infos;
    },
    handleEmail({ value }) {
      this.input.email2 = value === "직접입력" ? "" : value;
    },
    handleModal(key) {
      this.isPrivacy = key === "privacy";
      this.$bvModal.show("modal-term");
    },
    handleAgree(isPrivacy) {
      isPrivacy
        ? (this.consent.privacy = true)
        : (this.consent.sensitive = true);
    },
    setAddr({ postCode, addr }) {
      this.input.postnum = postCode;
      this.input.addr1 = addr;
    },
    async submit() {
      if (!this.consent.privacy)
        return this.$root.$emit(
          "showModalAlert",
          "개인정보 수집 및 이용에 동의해 주세요."
        );
      if (!this.consent.sensitive)
        return this.$root.$emit(
          "showModalAlert",
          "민감정보 수집 및 이용에 동의해 주세요."
        );

      const body = {
        ...this.input,
        contactname:
          this.childs.length === 1
            ? this.childs[0].text
            : this.input.children.text,
        joinserial:
          this.childs.length === 1
            ? this.childs[0].value
            : this.input.children.value,
        apply: this.input.program.value,
        cate2key: this.input.disease2.value + "",
        ...this.getMobile(),
      };
      delete body.program;
      delete body.children;
      delete body.disease1;
      delete body.disease2;
      delete body.hosKey;
      const {
        data: { message },
      } = ["treatment", "health-care"].includes(this.key)
        ? await this.apis.counseling.visitApply(body)
        : await this.apis.counseling.apply(body);
      showToast({ title: message });
      this.$bvModal.hide("modal-counseling"); // bootstrap modal hide
    },
    getTargetField(obj) {
      return Object.keys(obj).reduce(
        (feild, key) => (feild ? feild : obj[key].invalid && key),
        ""
      );
    },
    showCb() {
      this.init();
    },
    reset() {
      this.consent = {
        privacy: false,
        sensitive: false,
      };
      this.input = {
        children: null,
        program: null,
        hosKey: "",
        disease1: "",
        disease2: "",
        email1: "",
        email2: "",
        reason: "",
        postnum: "",
        addr1: "",
        addr2: "",
        hopeday: null,
      };
    },
    hideCb() {
      this.reset();
    },
    getMobile() {
      const [mobile1, mobile2, mobile3] = this.info.memhp
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
        .split("-");
      return {
        mobile1,
        mobile2,
        mobile3,
        phone1: mobile1,
        phone2: mobile2,
        phone3: mobile3,
      };
    },
  },
};
</script>
