<template>
  <!--  -->
  <b-modal
    id="modal-vaccination-registration"
    :hide-footer="true"
    @hide="hideCb"
    body-class="p-0"
    header-class="p-0 d-block border-bottom-0"
    scrollable
    centered
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-4 px-md-5 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-start">
          <h3 class="fs-24 fw-7 mt-1">
            예방접종일 입력
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <w-form @submit="submit" v-slot="{ invalid }" class="px-4 px-md-5">
      <div>
        <h6 class="fs-20 mb-4">
          #{질환명}, #{접종차수} 접종일을 입력해 주세요.
        </h6>
        <!-- 생년월일 -->
        <b-btn
          variant="link"
          class="border-0"
          @click="$bvModal.show('modal-date-input')"
        >
          <hc-input-default
            class="bg-white border rounded px-2 black"
            inputClass="border-0"
            readonly
            rules="required"
            name="date"
            :style="{
              maxWidth: '9rem',
            }"
            v-model="vaccineDay"
          >
            <template #append>
              <div class="flex-center">
                <i class="icon-calendar"></i>
              </div>
            </template>
          </hc-input-default>
        </b-btn>
        <modal-date-picker
          :id="'modal-date-input'"
          v-model="vaccineDay"
        ></modal-date-picker>
      </div>
      <!-- 저장하기 -->
      <div class="flex-center pt-3 pb-5">
        <b-btn
          variant="link blue-submit-btn fs-20 fs-md-18 px-5"
          @click="submit"
          :disabled="invalid"
          >저장하기</b-btn
        >
      </div>
    </w-form>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
import { showToast } from "@/plugins/custom-toast";
export default {
  props: {
    idx: {
      type: Number,
      default: 0,
    },
    colName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      vaccineDay: null,
      isAgree: false,
    };
  },
  methods: {
    async insert() {
      const body = {
        idx: this.idx + "",
        colName: this.colName,
        vaccineDay: this.vaccineDay,
      };
      const { kyoboApi } = this.getApis();
      const {
        data: { message },
      } = await kyoboApi.program.schdule.insert(body);
      return message;
    },
    async submit() {
      if (!this.vaccineDay)
        return this.$root.$emit(
          "showModalAlert",
          "예방접종일을 입력해 주세요."
        );
      const meesage = await this.insert();

      showToast({ title: meesage });
      this.$emit("refresh");
      this.$bvModal.hide("modal-vaccination-registration"); // bootstrap modal hide
    },
    reset() {
      this.vaccineDay = null;
      this.isAgree = false;
    },
    hideCb() {
      this.reset();
    },
  },
};
</script>
