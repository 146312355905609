<template>
  <!-- 전국협력 병원 모달 -->
  <b-modal
    id="modal-member-ship"
    size="md"
    :hide-footer="true"
    body-class="p-0"
    header-class="p-0 d-block border-bottom-0"
    modal-class="modal-member-ship"
    centered
    scrollable
    @show="showCb"
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-5 py-4 flex-between">
        <h3 class="fs-24 fw-7 mt-1">
          멤버쉽 카드
        </h3>
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="px-5 pb-5">
      <div class="position-relative">
        <div v-if="memberGradeStatus">
          <img
            class="w-100"
            :src="`${require(`@/assets/img/${$i18n.locale}/${cardImg}`)}`"
            alt="member ship card"
          />
          <p
            class="text-right white"
            :style="{
              position: 'absolute',
              bottom: '1.2rem',
              right: '1rem',
            }"
          >
            <span>{{ name }}</span
            >
            <!-- <br />
            <span>{{ code }}</span> -->
          </p>
        </div>
        <b-skeleton-img
          v-else
          no-aspect
          height="16.5rem"
          class="rounded-10"
        ></b-skeleton-img>
      </div>
    </div>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      memberGradeStatus: "",
      name: "",
      code: "",
    };
  },
  computed: {
    cardImg() {
      switch (this.memberGradeStatus) {
        case "C":
          return "mcard-general.png";
        case "B":
          return "mcard-premier.png";
        case "A":
          return "mcard-prestige.png";
        default:
          return "mcard-general.png";
      }
    },
  },
  methods: {
    async getInfo() {
      const { kyoboApi } = this.getApis();
      const {
        data: { memberInfo },
      } = await kyoboApi.memberShip.get();
      const { MEMNAME, MEMCODE, memberGradeStatus } = memberInfo[0];
      this.memberGradeStatus = memberGradeStatus;
      this.name = MEMNAME;
      this.code = MEMCODE;
    },
    showCb() {
      this.getInfo();
    },
  },
};
</script>
<style lang="scss">
.modal-member-ship {
  .modal-dialog {
    max-width: 500px;
  }
}
</style>
