<template>
  <b-container class="hc-container">
    <mypage-table
      v-if="!loading"
      :fields="fields"
      :items="items"
      @refresh="getList"
    ></mypage-table>
    <skeleton-table v-else></skeleton-table>
    <!-- 페이지 네비게이션 -->
    <hc-pagination
      v-if="totalPage > 1"
      :pageNum="totalPage"
      class="d-none d-md-flex"
    ></hc-pagination>

    <div v-if="totalCount > pageSize" class="d-flex d-md-none flex-center pt-4">
      <b-btn
        variant="link fs-20 border-bottom border-blue blue"
        @click="handleBtnClick"
        >더 보기</b-btn
      >
    </div>
  </b-container>
</template>

<script>
import MypageTable from "@/components/common/MypageTable";
import SkeletonTable from "@/components/common/SkeletonTable";
import common from "@/mixins/common";
import HcPagination from "@/components/common/HcPagination";

export default {
  mixins: [common],
  components: {
    SkeletonTable,
    HcPagination,
    MypageTable,
  },
  data() {
    return {
      loading: false,
      totalCount: 3,
      items: [],
      pageSize: 15,
    };
  },
  computed: {
    page() {
      return !this.$route.query.page ? 1 : +this.$route.query.page;
    },
    totalPage() {
      return Math.floor(
        this.totalCount / this.pageSize +
          (this.totalCount % this.pageSize > 0 ? 1 : 0)
      );
    },
    tab() {
      return this.$route.query.tab;
    },
    fields() {
      // subtab 있을 때
      if (this.subTab) {
        return this.subTab?.items.find((tab) => tab.value === this.tab)?.fields;
      }
      // subtab 없을 때
      return this.headerTab.find((tab) => tab.key === this.key)?.fields;
    },
  },
  watch: {
    page() {
      this.getList(
        this.page,
        this.pageSize,
        this.searchType,
        this.searchString
      );
    },
    fields() {
      this.getList(
        this.page,
        this.pageSize,
        this.searchType,
        this.searchString
      );
    },
  },
  methods: {
    handleBtnClick() {
      this.pageSize += 5;
      this.getList(1, this.pageSize, this.searchType, this.searchString);
    },
    removeSearch(body) {
      // 어린이, 주니어헬스케어일때 searchType, searchString 제거 및 타입 변경
      if (
        !["kyobokidscare", "kyobojuniorcare", "kyobowomancare"].includes(
          this.$i18n.locale
        )
      )
        return body;
      delete body.searchType;
      delete body.searchString;
      body.page += "";
      body.pageSize += "";
      return body;
    },
    addMCode(body) {
      // 주니어헬스케어일때 body에 m_code 값 추가
      if (!["kyobojuniorcare"].includes(this.$i18n.locale)) return body;
      body.m_code = this.tab;
      return body;
    },

    async getList(
      page = 1,
      pageSize = 15,
      searchType = "all",
      searchString = ""
    ) {
      const targetKey = this.key;
      this.loading = true;
      this.items = [];
      this.totalCount = 0;
      const { kyoboApi } = this.getApis();
      const apisKey = this.cameledKey === "use" ? this.tab : this.key;

      const apis = kyoboApi.myPage[apisKey.split("-")[0]];
      const body = {
        page,
        pageSize,
        searchType,
        searchString,
      };

      const newBody = this.compose([this.removeSearch, this.addMCode], body);

      const { data } = await apis.list(newBody);
      // 이전의 get 요청이 남아 있을 경우
      if (targetKey !== this.key) return;
      this.items = data?.rptList ? data?.rptList : data?.results;
      this.totalCount = data?.totalCnt;
      this.loading = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped></style>
