<template>
  <div
    ref="hc-card"
    class="link black w-100 rounded-10 flex-between flex-column hc-card p-4"
    :style="{
      backgroundColor,
    }"
  >
    <div
      :class="[
        textCenter ? 'align-items-center' : 'align-items-start',
        'd-flex flex-column w-100',
      ]"
    >
      <div class="mb-3">
        <span
          class="d-inline-block bg-white rounded-pill fs-16 fs-md-18 py-2 px-3"
          >{{ category }}</span
        >
      </div>
      <h6
        v-html="title"
        :class="[textCenter ? 'text-center' : '', 'mb-2 fw-7 fs-24 fs-md-24']"
        :style="{ whiteSpace: 'pre' }"
      ></h6>
      <p v-if="text" :class="[textCenter ? 'text-center' : 'text-left', 'fs-19 fs-md-18']">
        {{ text }}
      </p>
    </div>

    <div class="flex-center w-100" :style="{ marginTop: calcHeight('0.5rem') }">
      <b-btn
        @click="$emit('handleClick', id)"
        class="white fw-7 py-3 rounded-10 w-100 fs-20 fs-md-16"
        :style="{ backgroundColor: btnColor }"
        >{{ btnText }}</b-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "#cfe2f6",
    },
    btnColor: {
      type: String,
      default: "#92c7ff",
    },
    category: {
      type: String,
      default: "자가체크",
    },
    title: {
      type: String,
      default: "건강위험도 체크",
    },
    text: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "체크하러 가기",
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },
  computed: {
    hcCardRef() {
      return this.$refs["hc-card"];
    },
  },
  mounted() {
    this.hcCardRef.addEventListener("mouseenter", () =>
      this.$emit("mouseenter")
    );
  },
  beforeDestroy() {
    this.hcCardRef.removeEventListener("mouseenter", () =>
      this.$emit("mouseenter")
    );
  },
};
</script>
