<template>
  <b-sidebar
    id="side-bar-menu"
    backdrop-variant="dark"
    backdrop
    right
    no-header
  >
    <template #default="{ hide }">
      <ul class="px-4 py-0 pb-4 mt-2 list-unstyled" role="tablist">
        <!-- head -->
        <li class="flex-between py-3 border-bottom border-gray-7">
          <div
            :class="[
              {
                'd-none d-md-block': ![
                  'kyoboservices',
                  'kyoboseniorcare',
                ].includes($i18n.locale),
              },
              'fs-24 fs-md-20 fw-7 theme-color-1',
            ]"
          >
            {{ $t("logo") }}
          </div>
          <router-link
            v-if="!['kyoboservices', 'kyoboseniorcare'].includes($i18n.locale)"
            :to="{ ...$t('myServices')[0] }"
            class="theme-btn white px-4 bg-theme-color-1 d-flex d-md-none flex-start blue fw-7"
          >
            <span class="fs-18">내 서비스 내역</span>
          </router-link>
          <b-btn variant="link" @click="hide">
            <div
              class="bg-img"
              :style="{
                width: '3rem',
                paddingBottom: '3rem',
                backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              }"
            ></div>
          </b-btn>
        </li>
        <!-- // head -->

        <!-- list -->
        <li v-for="(m, idx) in sideMenu" :key="idx">
          <!-- If children is true -->
          <template v-if="m.isChildren">
            <!-- depth 1-->
            <b-btn
              role="tab"
              variant="link"
              class="flex-between py-3 border-bottom border-gray-7 rounded-0 w-100"
              v-b-toggle="`side-menu-accordion-${idx}`"
            >
              <span class="blue fw-7 fs-22 fs-md-18">{{ m.text }}</span>
              <i
                :class="[
                  menu_visible[idx] ? 'icon-minus' : 'icon-plus',
                  'theme-color-1 fs-30',
                ]"
              ></i>
            </b-btn>
            <!-- // depth 1 -->

            <!--  depth 2 -->
            <b-collapse
              :id="`side-menu-accordion-${idx}`"
              accordion="side-menu-accordion"
              role="tabpanel"
              @input="handleInput($event, idx)"
            >
              <ul class="bg-theme-color-3 list-unstyled">
                <template v-for="(tab, _idx) in headerTabs[m.id]">
                  <li
                    :key="_idx"
                    class="p-3 border-bottom border-gray-7 rounded-0"
                  >
                    <b-btn
                      variant="link"
                      @click="handleSubMenuClick(tab, hide)"
                      class="flex-between dark w-100 text-left fs-19 fs-md-16"
                    >
                      <span v-html="regexSpacing(tab.text)"></span>
                      <i class="icon-right-open-big fs-20 gray"></i>
                    </b-btn>
                  </li>
                </template>
              </ul>
            </b-collapse>
            <!--  // depth 2 -->
          </template>
          <!-- If isDepth is false -->
          <template v-else>
            <b-btn
              role="tab"
              variant="link"
              class="flex-between py-3 border-bottom border-gray-7 rounded-0 w-100"
              @click="handleSubMenuClick(m)"
            >
              <span class="blue fw-7 fs-22 fs-md-18">{{ m.text }}</span>
              <div
                class="bg-img"
                :style="{
                  width: '3rem',
                  paddingBottom: '3rem',
                  transform: 'rotate(180deg)',
                  backgroundImage: `url(${require('@/assets/img/common/ic-gray-arrow.svg')})`,
                }"
              ></div>
            </b-btn>
          </template>
        </li>

        <!-- // list -->
      </ul>
    </template>
  </b-sidebar>
</template>

<script>
import common from "@/mixins/common";
export default {
  mixins: [common],
  data() {
    return {
      menu_visible: [false, false, false],
    };
  },
  methods: {
    regexSpacing(text) {
      return text.replace(/&/gi, "&<br/>");
    },
    handleInput(e, idx) {
      this.menu_visible.splice(idx, 1, e);
    },
    handleSubMenuClick({ name, key, id, type, query }, hide = null) {
      if (hide) hide();
      this.$router
        .push({ name, params: { key, id, type }, query })
        .catch(() => {});
    },
  },
};
</script>
