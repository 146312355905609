import { debounce } from "lodash";

export default {
  methods: {
    // tab btn 이벤트 리스너
    onTabBtnSlide(target) {
      console.log('** target: ', target)
      const targetArr = target.split(/-(?=\d)/g);
      this.callTabBtnSlide(targetArr);
    },

    // active 된 tab btn으로 스크롤
    async callTabBtnSlide([href, idx]) {
      console.log('???')
      await this.$nextTick();
      const swiperId = href.substring(1);
      this.$refs[`${swiperId}-swiper`]?.slideTo(idx, 500);
    },

    // element 기준으로 scroll
    tabBtnClickHandle: debounce(function(hash, element = window) {
      this.$router.push({ ...this.$route, hash }).catch(() => {});
      const targetElHeight = document.querySelector(hash)?.offsetTop;
      const offset = this.getHeights(targetElHeight);
      element.scrollTo({
        left: 0,
        top: element === window ? targetElHeight - offset : targetElHeight,
        behavior: "smooth",
      });
    }, 100),

    getHeights(targetElHeight) {
      // header (알림톡으로 진입할 경우 header 없음)
      const headerHeight = document.getElementById("header") === null ? 0 : document.getElementById("header")?.offsetHeight;
      // head room
      const headRoomHeight = document.getElementById("head-room")?.offsetHeight;
      // sub tab
      const subTabHeight = document.getElementById("sub-tab-section")
        ?.offsetHeight;

      // 현재 포지션
      const position = window.scrollY;

      // y 값 리턴
      return position + headerHeight + headRoomHeight < targetElHeight
        ? headerHeight + subTabHeight
        : headerHeight + headRoomHeight;
    },
  },
  mounted() {
    this.$root.$on("bv::scrollspy::activate", this.onTabBtnSlide);
  },
  beforeDestroy() {
    this.$root.$off("bv::scrollspy::activate", this.onActivate);
  },
};
