import i18n from "@/plugins/i18";
import "@babel/polyfill";
import "swiper/dist/css/swiper.css";
import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import "./plugins/bootstrap-vue";
import "./plugins/custom-toast";
import "./plugins/validate";
import "./plugins/vCalendar";
import "./plugins/weblab";
import router from "./router";
import store from "./store";
import "./styles/index.scss";
import VueGtag from "vue-gtag";

Vue.use(VueMeta);
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG_KEY
  }
})
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
