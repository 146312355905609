import { throttle } from "lodash";

export default {
  data() {
    return {
      lastPosition: 0,
      offset: 0,
      time: 100,
      isOnPined: true,
    };
  },
  methods: {
    headRoom() {
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentPosition < 0) {
        return;
      }
      if (Math.abs(currentPosition - this.lastPosition) < 60) {
        return;
      }
      this.isOnPined = currentPosition < this.lastPosition;
      this.lastPosition = currentPosition;
    },
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
  },
  watch: {
    key() {
      this.lastPosition = 0;
      this.isOnPined = true;
    },
  },
  mounted() {
    window.addEventListener("scroll", throttle(this.headRoom, this.time));
  },
  beforeDestroy() {
    window.removeEventListener("scroll", throttle(this.headRoom, this.time));
  },
};
