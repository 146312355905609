<template>
  <div>
    <w-form v-if="info" @submit="submit" v-slot="{ invalid }">
      <!-- 신청자 정보 리스트 -->
      <div class="mb-5">
        <hash-title :title="'신청자 정보'" :isHash="false"></hash-title>
        <b-row
          align-v="center"
          class="mx-0 border-bottom border-gray-5 py-3 fs-18 fs-sm-20"
        >
          <b-col cols="4">자녀 이름</b-col>
          <div class="col-8">
            <template v-if="childs">
              <div v-if="!childs.length" class="red">
                ※ 아이 성장 체크 서비스는 출산 자녀가 있는 경우 이용하실 수
                있습니다.
              </div>
              <div v-else-if="childs.length === 1">
                {{ childs[0].text }}
              </div>
              <hc-select-default
                v-else
                v-model="selectedChild"
                rules="required"
                inputClass="fs-20"
                :items="childs"
              ></hc-select-default>
            </template>
            <b-skeleton
              v-else
              :animation="null"
              width="7rem"
              height="1.5rem"
            ></b-skeleton>
          </div>
        </b-row>
        <b-row
          align-v="center"
          class="mx-0 border-bottom border-gray-5 py-3 fs-18 fs-sm-20"
        >
          <b-col cols="4">휴대전화 번호</b-col>
          <b-col cols="8">
            {{ info.memhp || "-" }}
          </b-col>
        </b-row>
        <b-row
          align-v="center"
          class="mx-0 border-bottom border-gray-5 py-3 fs-18 fs-sm-20"
        >
          <b-col cols="4">E-mail</b-col>
          <b-col cols="8">
            {{ info.mememail || "-" }}
          </b-col>
        </b-row>
      </div>

      <!-- 프로그램 선택 -->
      <div>
        <hash-title :title="'프로그램 선택'" :isHash="false"></hash-title>
        <h6 class="flex-start flex-wrap fs-20">
          <span>
            받아보고싶은 프로그램은
          </span>
          <span class="mx-2">
            <hc-select-default
              v-model="selectedProgram"
              :items="options"
              :isGroup="true"
              inputClass="fs-20 fw-7"
              :hashTitle="true"
              rules="required"
            ></hc-select-default>
          </span>
          <span>
            입니다.
          </span>
        </h6>
        <h6 class="fs-20 mb-3">
          아래 신청하기 버튼을 눌러 집중관리 프로그램을 받아보세요.
        </h6>
        <div class="red fs-18">
          <p class="mb-1">
            ※ 프로그램 중복 신청이 불가능합니다. 선택하신 프로그램이 종료된 후
            다른 프로그램 선택이 가능합니다.
          </p>
          <p>
            ※ 홈페이지 및 어린이헬스콜센터 (1588-4733)를 통해서 신청이
            가능합니다.
          </p>
        </div>
      </div>
      <!-- 동의하기 -->
      <div class="flex-center py-4">
        <w-checkbox
          :label="'개인정보 수집 및 이용동의 (필수)'"
          :name="'개인정보 수집 및 이용동의 (필수)'"
          v-model="privacy"
          class="py-1 mr-2"
        >
          <a class="fs-20 fs-md-18">개인정보 수집 및 이용동의 (필수)</a>
        </w-checkbox>
        <b-btn variant="link" @click="$bvModal.show('modal-term')">
          <div
            class="bg-img"
            :style="{
              width: calcHeight('3rem'),
              paddingBottom: calcHeight('3rem'),
              backgroundImage: `url(${require('@/assets/img/common/ic-agree-zoom.svg')})`,
            }"
          ></div>
        </b-btn>
      </div>
      <!-- 신청하기 -->
      <div class="flex-center pb-5">
        <b-btn
          variant="link blue-submit-btn fs-20"
          :disabled="invalid"
          @click="submit"
          >신청하기</b-btn
        >
      </div>
      <!-- // 모달 바디 -->
      <modal-term
        @agree="privacy = true"
        v-bind="{ ...modalTermProps }"
      ></modal-term>
    </w-form>
    <skeleton-managment v-else></skeleton-managment>
  </div>
</template>

<script>
import SkeletonManagment from "@/components/common/SkeletonManagment";
import ModalTerm from "@/components/common/modal/ModalTerm";

export default {
  components: {
    ModalTerm,
    SkeletonManagment,
  },
  props: {
    modalId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalTermProps: {
        isPrivacy: true,
        consentKey: "care",
      },
      privacy: false,
      selectedChlidren: null,
      selectedProgram: null,
      selectedChild: null,
      info: null,
    };
  },
  computed: {
    childs() {
      const key = this.$i18n.locale === "kyobokidscare" ? "childs" : "children";
      return this.info?.[key]?.map((val) => ({
        value: val.joinserial,
        text: val.contactname || "-",
      }));
    },
    options() {
      return this.isArray(this.$t("questionsOptions"));
    },
    apis() {
      const { kyoboApi } = this.getApis();
      return kyoboApi.program.management;
    },
  },
  methods: {
    async getInfo() {
      const { kyoboApi } = this.getApis();

      const {
        data: { info },
      } = await kyoboApi.program.common.info();
      this.info = info;
    },
    submit() {
      if (!this.privacy)
        return this.$root.$emit(
          "showModalAlert",
          "개인정보 수집 및 이용에 동의해 주세요."
        );
      // 임산부 홈트레이닝 선택시 문진 없이 바로 프로그램 신청
      if (this.selectedProgram.value === "HC07A001") {
        this.onApply();
        return;
      }
      // 그외 프로그램들은 문진 작성 후 프로그램 신청
      const body = {
        joinserial:
          this.childs.length === 1
            ? this.childs[0].value
            : this.selectedChild.value,
        mcode: this.selectedProgram.value,
        selectedProgram: this.selectedProgram,
      };
      this.$emit("onSubmit", body);
    },
    async onApply() {
      const applyBody = {
        joinserial:
          this.childs.length === 1
            ? this.childs[0].value
            : this.selectedChild.value,
        memhp: this.info.memhp,
        mcode: this.selectedProgram.value,
      };
      const {
        data: { sseq, message },
      } = await this.apis.apply(applyBody);

      this.$root.$emit("showModalAlert", message);

      message !== "이미 진행 중인 서비스가 존재합니다." &&
        this.$bvModal.hide(this.modalId);
      return;
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped></style>
