<template>
  <div>
    <b-container class="hc-container">
      <!-- 결과 -->
      <div>
        <div v-if="results">
          <!-- 일반 자가진단 결과 -->
          <survey-result-head
            v-if="id !== 'LK01002'"
            v-bind="{
              ...results,
            }"
          ></survey-result-head>
          <!-- // 일반 자가진단 결과 -->
          <!-- 주니어헬스케어에서 그림있는 자가진단 결과  -->
          <div v-else>
            <b-row
              v-for="(row, i) in results"
              :key="`${row.QUE_TITLE}-${i}`"
              class="mb-5"
              align-v="stretch"
            >
              <b-col cols="4" md="6">
                <span class="fs-20 fw-7">{{ i + 1 }}번 문항</span>
              </b-col>
              <b-col
                cols="8"
                md="6"
                class="d-flex justify-content-end align-items-end align-items-md-start flex-column flex-md-row "
              >
                <span>선택 답변 : </span>
                <span v-if="row.ANS_IMGYN === 'N'" class="ml-1">
                  {{ row.ANS_TITLE }}
                </span>
                <img
                  v-else
                  :src="row.ANS_IMG"
                  :alt="`답변그림 ${i + 1}`"
                  class="w-75 m-1"
                />
              </b-col>
              <b-col class="mt-3">
                <div
                  class="border border p-3 rounded-10 text-center fs-20 fw-7 gray-2"
                  :style="{
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  }"
                >
                  <span>{{ row.ANS_RESULT_COMMENT }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- // 주니어헬스케어에서 그림있는 자가진단 결과  -->
          <!--  아코디언 메뉴 -->
          <div v-if="id === 'healthcheck'" class="mb-5 border-top border-green">
            <hc-collapse
              v-for="(row, i) in $t('resultContents')"
              v-bind="{ ...row }"
              :idx="i"
              :key="row.title"
              class="border-bottom border-gray-5"
            ></hc-collapse>
          </div>
          <!-- 아코디언 메뉴 -->
          <!-- 프로그램 신청 -->
          <heatlch-check v-if="['healthStress', 'skin'].includes(id)">
          </heatlch-check>
        </div>
        <skeleton-check-result v-else></skeleton-check-result>
      </div>
      <!-- 결과 -->
    </b-container>
    <!-- banner -->
    <sub-banner
      v-if="btnArr.length"
      :title="`다른 ${targetRouteInfo.text}도 받아 보시겠어요?`"
      :btnArr="btnArr"
      width="12rem"
      @handleClick="handleClick"
    ></sub-banner>
    <!-- // banner -->
  </div>
</template>

<script>
import HcCollapse from "@/components/common/HcCollapse";
import SurveyResultHead from "@/components/common/SurveyResultHead.vue";
import SubBanner from "@/components/common/SubBanner";
import SkeletonCheckResult from "@/components/common/SkeletonCheckResult";
import HeatlchCheck from "@/components/kyobohealthcare/HealthCheck";
import common from "@/mixins/common";
export default {
  mixins: [common],
  components: {
    HeatlchCheck,
    SkeletonCheckResult,
    SurveyResultHead,
    SubBanner,
    HcCollapse,
  },
  data() {
    return {
      results: null,
    };
  },
  computed: {
    checkItems() {
      return this.$t(this.cameledKey);
    },
    btnArr() {
      return this.checkItems.filter((el) => el.id !== this.id);
    },
    point() {
      return this.$route.query.point + "";
    },
    skinPoint() {
      return {
        point1: this.$route.query.point1,
        point2: this.$route.query.point2,
        point3: this.$route.query.point3,
      };
    },
    userInfo() {
      return {
        birthMonth: this.$route.query.birthMonth,
        birthYear: this.$route.query.birthYear,
        birthDay: this.$route.query.birthDay,
        height: this.$route.query.height,
        weight: this.$route.query.weight,
      };
    },
  },
  methods: {
    async getResult() {
      const { kyoboApi } = this.getApis();
      const apisKey = ["kyobojuniorcare"].includes(this.$i18n.locale)
        ? "psy"
        : this.id;
      const apis = kyoboApi.check[apisKey];
      // 건강위험도체크
      if (this.id === "healthcheck") {
        const body = {
          point: this.point,
          ...this.userInfo,
        };
        const { data } = await apis.result(body);
        const regex = /(입니다)+\./;
        const test = this.removeAttributes(data.comment, ["color"]);
        const dividedData = test.split(regex);
        this.results = {
          title: dividedData[0] + dividedData[1],
          content: dividedData[2],
        };
        return;
      }
      // 피부 노화도 자가진단
      if (this.id === "skin") {
        const { data } = await apis.result(this.skinPoint);
        const dividedData = data.resultMessage.split(/<br\/?>/);
        this.results = {
          title: dividedData[0],
          content: dividedData[1],
        };
        return;
      }
      // 아동청소년 심리체크 결과
      if (this.$i18n.locale === "kyobojuniorcare") {
        const body = this.$route.query;
        const {
          data: { queryResult },
        } =
          // 아동청소년 심리체크 - 상황심리일때
          this.id === "LK01002"
            ? await apis.situationResult(body)
            : await apis.result(body);

        if (this.id === "LK01002") {
          this.results = {
            ...queryResult,
          };
          return;
        } else {
          this.results = {
            title: `${queryResult.R_KIND} ${queryResult.R_STAGE}`,
            content: this.replaceBr(queryResult.R_COMMENT),
            cardTitle: queryResult.G_TITLE,
            cardContent: this.replaceBr(queryResult.G_COMMENT),
          };
          return;
        }
      }
      const { data } = await apis.result(this.point);
      this.results = data.results[0];
    },
    handleClick(id) {
      ["healthcheck", "breast", "ovarian", "cervical"].includes(id)
        ? this.$router.push({
            name: "check-agree",
            params: { id, type: this.type, key: this.key },
          })
        : this.$router.push({
            name: "check-survey",
            params: { id, type: this.type, key: this.key },
            query: { page: 1 },
          });
    },
  },
  mounted() {
    this.getResult();
  },
};
</script>
