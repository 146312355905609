<template>
  <div class="flex-center py-4">
    <b-pagination-nav
      v-model="currentPage"
      :number-of-pages="pageNum"
      class="mt-4"
      last-number
      first-number
      :link-gen="linkGen"
    >
      <!-- prev btn -->
      <template #prev-text
        ><span
          class="bg-img"
          :style="{
            transform: 'rotate(180deg)',
            backgroundImage: `url(${require('@/assets/img/common/ic-pagi-arrow.svg')})`,
          }"
        ></span
      ></template>
      <!-- next btn -->
      <template #next-text
        ><span
          class="bg-img"
          :style="{
            backgroundImage: `url(${require('@/assets/img/common/ic-pagi-arrow.svg')})`,
          }"
        ></span
      ></template>
      <!-- next btn -->

      <!-- page btn -->
      <template #page="{ page, active }">
        <span v-if="active" class="fs-15 fw-7">{{ page }}</span>
        <span v-else class="fs-15 fw-7" :style="{ color: '#767676' }">{{
          page
        }}</span>
      </template>
      <!-- // page btn -->
    </b-pagination-nav>
  </div>
</template>

<script>
export default {
  props: {
    pageNum: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    activePage() {
      return this.$route.query.page;
    },
  },
  methods: {
    linkGen(pageNum) {
      return {
        name: this.$route.name,
        query: { ...this.$route.query, page: pageNum },
      };
    },
  },
  watch: {
    activePage(n) {
      this.currentPage = n;
    },
  },
  mounted() {
    if (!this.activePage) return;
    this.currentPage = this.activePage;
  },
};
</script>
