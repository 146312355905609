import policy from "@/lib/policy";

const type = "kyobowomancare";
export default {
  color: ["#e982b1", "#3667cd", "#ff8ec11a"],
  // logo
  logo: "교보우먼헬스케어서비스",
  call: "1588-7523",
  // call: "1577-7881",

  // gnb
  gnb: [
    {
      id: "service",
      text: "서비스 안내/신청",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "check",
      text: "건강체크",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "info",
      text: "건강정보",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "mypage",
      text: "내 서비스 내역",
      isHeaderTab: true,
    },
  ],
  headerTabs: {
    service: [
      {
        text: "서비스 소개",
        name: "service",
        key: "info",
        type,
      },
      {
        text: "토닥토닥 마음케어",
        name: "service",
        key: "mind",
        type,
      },
      {
        text: "플래티넘우먼헬스케어서비스",
        name: "service",
        key: "premium",
        type,
      },
      {
        text: "우먼헬스케어서비스 플러스",
        name: "service",
        key: "plus",
        type,
      },
    ],
    check: [
      {
        text: "여성암 위험도",
        name: "check",
        type,
        key: "cancer",
      },
      { text: "다이어트", name: "check", type, key: "diet" },
      {
        text: "심리 자가체크",
        name: "check",
        type,
        key: "self-check",
      },
      {
        text: "힐링테라피",
        name: "check",
        key: "therapy",
        type,
      },
    ],
    info: [
      {
        text: "생애주기 심리",
        name: "info",
        key: "psychology",
        query: { tab: "KO0101" },
        type,
      },
      {
        text: "여성암",
        name: "info",
        key: "femaleCancer",
        query: { tab: "KO0201" },
        type,
      },
      {
        text: "여성중대질환",
        name: "info",
        key: "femaleDisease",
        query: { tab: "KO0204" },
        type,
      },
      {
        text: "여성예방접종",
        name: "info",
        key: "vaccination",
        query: { tab: "KO0213" },
        type,
      },
      {
        text: "생애주기 영양관리",
        name: "info",
        key: "nutritionManagement",
        query: { tab: "KO0301" },
        type,
      },
      {
        text: "치아",
        name: "info",
        key: "teeth",
        query: { tab: "KB0801" },
        type,
      },
      {
        text: "기획기사",
        name: "info-news",
        key: "health-news",
        type,
      },
      {
        text: "최신뉴스",
        name: "info-news",
        key: "recent-news",
        type,
      },
    ],
    mypage: [
      {
        text: "전화상담 내역",
        name: "mypage",
        key: "mycall",
        fields: [
          { label: "회차", key: "number" },
          { label: "제목", key: "ASK" },
          { label: "작성날짜", key: "REGDATE" },
          { label: "자세히보기", key: "detail" },
        ],
        type,
      },
    ],
  },
  // sub tab
  subTabs: [
    {
      key: "info",
      scroll: true,
      items: ["평상시 건강관리", "가족 건강관리", "일반 치료지원", "중대질환 치료지원"],
    },
    {
      key: "premium",
      scroll: true,
      items: ["건강증진 프로그램", "차량 에스코트", "방문 심리상담", "해외 의료지원", "가사도우미 지원"],
    },
    {
      key: "psychology",
      scroll: false,
      items: [
        { value: "KO0101", text: "학업/진학 스트레스" },
        { value: "KO0102", text: "취업/생활 스트레스" },
        { value: "KO0103", text: "산전/후 우울증" },
        { value: "KO0104", text: "갱년기/노년기 심리" },
      ],
    },
    {
      key: "femaleCancer",
      scroll: false,
      items: [
        { value: "KO0201", text: "유방암" },
        { value: "KO0202", text: "난소암" },
        { value: "KO0203", text: "자궁경부암" },
      ],
    },
    {
      key: "teeth",
      scroll: false,
      items: [
        { value: "KB0801", text: "평상 시 치아관리" },
        { value: "KB0802", text: "주요 치과질환" },
        { value: "KB0803", text: "주요 치과치료" },
      ],
    },
    {
      scroll: true,
      small: true,
      key: "music",
      items: ["스트레스", "기분전환", "우울", "불면증", "행복"],
    },
    {
      scroll: true,
      small: true,
      key: "asmr",
      items: ["숙면", "숲속", "일상 속", "비 오는 날", "바다"],
    },
  ],
  // 내 서비스 내역
  myServices: [
    {
      title: "전화상담 내역",
      name: "mypage",
      params: { key: "mycall", type },
    },
  ],

  // 자가진단 룰
  rules: {
    // current = rule이 적용되는 문제, parentNum = 조건이 되는 문제
    breast: [
      {
        current: 2,
        rules: [(findItemCb, parentNum = 1) => !findItemCb(parentNum)?.selectedAnswers?.includes("0")],
      },
      {
        current: 4,
        rules: [(findItemCb, parentNum = 3) => !findItemCb(parentNum)?.selectedAnswers?.includes("0")],
      },
      {
        current: 6,
        rules: [(findItemCb, parentNum = 5) => !findItemCb(parentNum)?.selectedAnswers?.includes("0")],
      },
      {
        current: 13,
        rules: [(findItemCb, parentNum = 14) => !findItemCb(parentNum)?.selectedAnswers?.includes("0")],
      },
    ],
    ovarian: [
      {
        current: 3,
        rules: [
          (findItemCb, parentNum = 1) => !findItemCb(parentNum)?.selectedAnswers?.includes("0"),
          (findItemCb, parentNum = 2) => !findItemCb(parentNum)?.selectedAnswers?.includes("0"),
        ],
      },
    ],
    cervical: [
      {
        current: 3,
        rules: [(findItemCb, parentNum = 2) => !findItemCb(parentNum)?.selectedAnswers?.includes("5")],
      },
    ],
  },

  pointRlues: {
    // current rule 사용하지 않아서 제거
    // answers = rule이 적용되는 답변 배열, parentNum = 조건이 되는 문제
    breast: [
      (getAnswersCb, parentNum = [1, 3]) => {
        const terms = getAnswersCb(parentNum).filter((val) => val.includes("0"));
        return terms.length === 2 ? 0 : 5;
      },
    ],
    ovarian: [
      (getAnswersCb, parentNum = [1, 3]) => {
        const terms = getAnswersCb(parentNum).filter((val) => val.includes("0"));
        return terms.length === 2 ? 0 : 5;
      },
    ],
    cervical: [
      (getAnswersCb, parentNum = [5, 6]) => {
        const terms = getAnswersCb(parentNum).filter((val) => val.includes("3"));
        return terms.length ? 5 : 0;
      },
    ],
  },

  // home
  visual: [
    {
      title: "평상시 건강관리",
      subTitle: ["건강은 건강할 때 관리해야 한다고 합니다.", "건강을 잃지 않도록 도와드립니다."],
      to: "service",
      key: "info",
    },
    {
      title: "가족 건강관리",
      subTitle: ["가족이 건강해야 고객님이 행복합니다.", "가정의 건강설계를 지원합니다."],
      to: "service",
      key: "info",
      hash: "#sub-tab-1",
    },
    {
      title: "일반 치료지원",
      subTitle: ["중대질환 이전 단계 질환은 관리가 중요합니다.", "고객님의 빠른 회복을 도와드립니다."],
      to: "service",
      key: "info",
      hash: "#sub-tab-2",
    },
    {
      title: "중대질환 치료지원",
      subTitle: ["진단부터 치료하는 전 과정에서", "최적의 진료를 받을 수 있도록 지원합니다."],
      to: "service",
      key: "info",
      hash: "#sub-tab-3",
    },
    {
      title: "토닥토닥 마음케어",
      subTitle: [`심리전화상담과 제휴심리상담센터가<br class="d-md-none"/> 마음건강 회복을 지원합니다.`],
      to: "service",
      key: "mind",
    },
    {
      title: "플래티넘우먼헬스케어서비스",
      subTitle: ["고객님의 건강상태를 정확히 파악한 후 체계적이고", "효율적인 건강 프로그램을 제공해 드립니다."],
      to: "service",
      key: "premium",
    },
  ],
  healthCheck: {
    title: "건강체크",
    subTitle: "온라인 건강체크로 내 건강상태를 체크해 보세요.",
    items: [
      {
        text: "여성암 위험도",
        name: "check",
        color: "#cfe2f6",
        key: "cancer",
      },
      {
        text: "다이어트",
        name: "check",
        color: "#efe1f8",
        key: "diet",
      },
      {
        text: "심리자가체크",
        name: "check",
        color: "#c2f1d5",
        key: "self-check",
      },

      { text: "힐링테라피", color: "#ddf1c2", name: "check", key: "therapy" },
    ],
  },
  healthInfo: {
    title: "건강정보",
    subTitle: "다양한 건강정보를 확인하고 건강한 삶을 지켜보세요.",
    items: [
      {
        text: "생애주기 심리",
        name: "info",
        key: "psychology",
        query: { tab: "KO0101" },
      },
      {
        text: "여성암",
        name: "info",
        key: "femaleCancer",
        query: { tab: "KO0201" },
      },
      {
        text: "여성중대질환",
        name: "info",
        key: "femaleDisease",
        query: { tab: "KO0204" },
      },
      {
        text: "여성예방접종",
        name: "info",
        key: "vaccination",
        query: { tab: "KO0213" },
      },
      {
        text: "생애주기 영양관리",
        name: "info",
        key: "nutritionManagement",
        query: { tab: "KO0301" },
      },
      {
        text: "치아",
        name: "info",
        key: "teeth",
        query: { tab: "KB0801" },
      },
      {
        text: "기획기사",
        name: "info-news",
        key: "health-news",
      },
      {
        text: "최신뉴스",
        name: "info-news",
        key: "recent-news",
      },
    ],
  },
  // --- 서비스 안내
  info: [
    {
      title: "평상시 건강관리",
      subTitle: "건강은 건강할 때 관리해야 한다고 합니다. 건강을 잃지 않도록 도와드립니다.",
      contents: [
        {
          title: "건강상담",
          texts: ["교보우먼헬스콜센터를 통해 간호사 상담 접수 후 내과, 외과, 산부인과, 치과 등 14개과 진료과목 전문의전화상담제공"],
        },
        {
          title: "건강정보 제공(홈페이지, LMS, E-mail)",
          texts: ["주요질환 및 여성특정질환(손목터널증후군/하지정맥류/성대결절)에 대한 건강정보, 건강칼럼 제공"],
        },
        {
          title: "치아 관련 건강 콘텐츠 제공",
          texts: ["- 평상시 치아관리, 주요 치과질환 등 치아관련 전문 콘텐츠 제공", "- 필요시 치과 전문의 전화상담 제공"],
        },
        {
          title: "주요 질환 예방 프로그램",
          texts: [
            "주요 질환 생활습관 개선 콘텐츠 제공(4주)",
            "※ 주요 질환 범위 : 대사증후군, 통증수반질환, 심뇌혈관 질환 중 선택",
            "※ 심뇌혈관 질환 신청 시 심화 예방 프로그램 추가 제공(4주)",
          ],
        },
        {
          title: "간호사복약상담",
          texts: ["- 약학정보원 등 디지털 라이브러리 기반의 간호사 1:1 복약 지도 및 상담 제공", "- 복약 정보, 복약 시 주의사항 제공"],
        },
        {
          title: "고혈압관리/비만·영양 관리 프로그램",
          texts: ["- 고혈압 요인 및 합병증 예방 가이드, 운동·영양 가이드 제공", "- 만성질환 예방 관리를 위한 비만 치료/영양 가이드 등 제공"],
        },
        {
          title: "안과/치과 주요질환정보 프로그램",
          texts: ["주요 안과/치과 질환 안내, 노인성 안과/치과 질환 예방 및 관리를 위한 가이드 제공"],
        },
        {
          title: "임신 / 출산 정보알림",
          texts: [
            "- 주 1회 : 임신 주수별 산모의 신체변화 및 출산 전 준비사항 등 맞춤정보 제공",
            "- 월 1회 : 출산 이후 육아법, 예방접종, 아이의 성장발달 등 맞춤 정보 제공",
          ],
        },
        {
          title: "출산 맞춤 관리",
          texts: [
            "출산 전 · 후 테마별 맞춤관리 프로그램 제공",
            "※ 임신/출산 정보알림, 출산 맞춤관리는 임산부보장특약 선택 시 특약기간(1년)동안 제공",
          ],
        },
      ],
    },
    {
      title: "가족 건강관리",
      subTitle: "가족이 건강해야 고객님이 행복합니다. 가정의 건강설계를 지원합니다.",
      contents: [
        {
          title: "미숙아 방문간호 컨설팅 ",
          texts: ["미숙아 출생 시 신생아 전문간호사 방문 상담 및 교육 제공(퇴원 후 한달)", "※ 미숙아 : 임신 주수 37주 미만&출생체중 2.5KG이하"],
        },

        {
          title: "가족건강검진 안내 (우대)",
          texts: ["- 가족 건강상담 및 제휴협력을 통한 건강검진 우대(고객 실비부담)", "- 맞춤형 건강검진 설계 및 검진항목 안내"],
          button: { text: "전국 협력병원", modalId: "modal-hospitals-compare" },
        },
        {
          title: "암 정보 제공 ",
          texts: ["암 진단 시, 가족 건강 및 지원을 위해 특화된 암 정보 및 식이, 운동법 등 관련 정보 제공"],
        },
        {
          title: "맞춤형 식단 컨설팅",
          texts: [
            "다이어트 / 가족 건강 / 질환별 맞줌형 식단 설계 제공",
            '-고객 서비스 요청 <i class="icon-right"></i> 전화상담을 통한 고객 분석 <i class="icon-right"></i> 전문 임상영양사 식단 설계 <i class="icon-right"></i> 필요 시 컨설팅 결과 피드백',
          ],
        },
        {
          title: "여성특화 건강검진 안내 (우대) ",
          texts: ["- 산전 검진, 여성주요암(유방암, 갑상선암, 자궁암) 검진설계 우대", "- 자궁경부암, A/B형 간염, 인플루엔자 등 주요 예방 시기 알림"],
        },
      ],
    },
    {
      title: "일반 치료지원",
      subTitle: "중대 질환 이전 단계 질환은 관리가 중요합니다. 고객님의 빠른 회복을 도와드립니다.",
      contents: [
        {
          title: "병원·의료진 안내 및 예약대행",
          texts: ["질병 진료 및 치료를 위한 병원·의료진 안내 및 진료 예약대행 서비스 제공"],
        },
        {
          title: "여성질환 전담간호사 1:1 코칭콜",
          texts: [
            "- 여성질환 및 갱년기 진단 시 개인별 건강상태에 따른 전담간호사 코칭콜 제공",
            "- 개인별 맞춤 건강정보 제공 및 상담, 해당질환 교육 등",
            "※ 1:1 코칭콜 해당질환 : 여성질환(자궁경부이형성증, 자궁근종, 자궁내막증, 골반염, 난소낭종 등) 및 갱년기 질환, CI추가보장관련특약[암/뇌/심장 및 장기] 및 합병증보장특약의 해당질환",
          ],
        },
        {
          title: "간병인 매칭",
          texts: ["간병인 필요 시 제휴업체를 통한 간병인 안내 서비스 제공 (간병인 이용료는 고객 부담)"],
        },
        {
          title: "당뇨 집중관리 14주 프로그램",
          texts: ["혈당관리, 합병증 예방 콘텐츠 & 간호사 1: 1 당뇨관리 상담 제공"],
        },
        {
          title: "인공관절수술 자가 재활 프로그램",
          texts: ["인공관절치환수술 후 자가 재활관리 및 운동처방 맞춤 프로그램 제공"],
        },
        {
          title: "여성특화 자가진단",
          texts: ["유방암, 골다공증, 하지정맥류, 요실금, 갱년기 건강, 갱년기 우울증, 치매 자가진단 및 체크리스트 제공"],
        },
        {
          title: "여성암 위험도 체크",
          texts: ["유방암, 자궁암, 난소암 위험도 체크 제공"],
        },
        {
          title: "여성 심리테라피",
          texts: [
            `<span class="fs-18 fw-7">STEP1.평상 시</span>`,
            "- 여성 전문 심리 칼럼 및 자가진단 콘텐츠 제공",
            "- 심리적 안정감을 주는 힐링 콘텐츠 제공(동영상/음원)",
            "<br/>",
            `<span class="fs-18 fw-7">STEP2.필요 시 - 토닥토닥 심리프로그램(4주)</span>`,
            "- 전화 심리상담 제공",
            "- 제휴 심리상담센터(대면/화상) 안내 및 우대(고객 실비 부담)",
          ],
        },
      ],
    },
    {
      title: "중대질환 치료지원",
      subtitle: "중대질환에 걸리면 어떻게 해야 할지 당황하게 됩니다. 진단부터 치료하는 전 과정에서 최적의 진료를 받을 수 있도록 지원합니다.",
      contents: [
        {
          title: "간호사 병원동반 / 질병관리교육",
          texts: [
            "- 중대질환 치료(수술, 입.퇴원 등) 시 전문간호사 병원동반",
            "- 중대질환 전문간호사 방문교육 서비스 제공",
            "- 중대질환별 건강상담, 건강정보, 심리상담 제공",
            "※ 중대질환 범위 : 암, 심장.뇌혈관질환, 고혈압성질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌.척수수막염), 루게릭병(근위축성측삭경화증)",
          ],
        },
        {
          title: "암 재발 예방",
          texts: ["암 치료 후 지속적 관리 제공", "- 전담간호사 정기적 안부 / 상담 콜", "- 병원 방문 시 간호사동반 방문서비스 제공(필요시)"],
        },
        {
          title: "여성중대질환 방문지원",
          texts: ["- 간호사 병원동행", "- 질병 관리교육 : 해당질환 건강상담, 건강정보 제공", "※ 해당질환 : 중증루프스신염, 중증재생불량성 빈혈"],
        },
      ],
    },
  ],
  mind: [
    {
      title: "토닥토닥 마음케어",
      contents: [
        {
          title: "심리전화상담",
          texts: ["도움이 필요할 땐 언제든지 연락주세요. 전문 간호사와 심리상담사가 마음건강 회복을 지원합니다."],
        },
        {
          title: "제휴심리상담센터(우대)",
          texts: [
            "혼자서 힘들어하지 마세요. 마음이 아프고, 힘들때는 심리전문가와 함께 마음건강을 지키는 것이 중요합니다.",
            "- 전국 어디서나, 제휴 심리센터 네트워크 구축 (제주도 포함)",
            "- 제휴 네트워크를 통해 심리서비스 이용 시 우대 혜택 제공",
            "<br/>",
            "<span class='fs-20 fw-7'>주요 프로그램</span>",
            "- 영유아 상담, 발달검사, 놀이평가, 소아우울증, ADHD, 놀이치료, 미술치료 등",
            "- 개인, 부부, 가족 상담 및 치료",
            "- 암 환자 심리상담, 외상 후 스트레스, 중독 상담 등",
            "- 성인 개인, 심리안정, 우울증, 분노조절, 감정코칭 프로그램 제공",
            "<br/>",
            "※ 센터마다 제공되는 프로그램이 다를 수 있습니다.",
            "※ 교보우먼케어서비스(1588-7523)를 통해서 신청이 가능합니다.",
          ],
        },
      ],
    },
  ],
  premium: [
    {
      title: "건강증진 프로그램",
      subTitle: "현재 고객님의 건강상태를 정확히 파악한 후 체계적이고 효율적인 건강 프로그램을 제공해 드립니다.",
      contents: [
        {
          title: "관리 프로그램 (연 1회한)",
          texts: ["- 피부관리 · 금연 · 금주 · 스트레스 · 갱년기 · 혈압 · 혈당 · 고지혈 · 간기능 · 비만 · 빈혈 · 산후관리"],
        },
      ],
    },
    {
      title: "차량 에스코트",
      subTitle: "전담간호사의 편안한 에스코트 및 병원 간 이송지원으로 고객님과 가족의 걱정을 해소해 드립니다.",
      contents: [
        {
          title: "입, 퇴원 병원동반",
          texts: ["중대질환 수술을 위한 입, 퇴원 시 전문 간호사 및 차량 지원", "※ 입 , 퇴원 1일 전 신청 필요 (서울권 이동 한정)"],
        },
        {
          title: "병원 간 이송",
          texts: [
            "중대질환 입원치료를 위한 타 의료기관 이송 시 차량 지원",
            "※ 응급 상황, 도서 지역 서비스 이용 불가 / 이송 2일 전 신청 (1인 연 1회한)",
            "※ 중대질환 범위 : 암, 심장 · 뇌혈관질환, 고혈압성질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌 · 척수수막염), 루게릭병(근위축성측삭경화증)",
          ],
        },
      ],
    },
    {
      title: "방문 심리상담",
      subTitle: "심리상담전문가 방문을 통해 중대질환의 심리적 회복까지 도와드립니다.",
      contents: [
        {
          checkedTtiles: ["부정적 감정 감소 및 정서 안정을 위한 심리상담", "고객 치료 병원 또는 제휴 심리상담센터에서 진행"],
          texts: ["※ 병원 / 심리상담센터 방문 1일 전 신청", "※ 중대질환 발생 고객 본인 / 고객 직계 가족 1인 중 선택(연 1회)"],
        },
      ],
    },
    {
      title: "해외 의료지원",
      subTitle: "해외에서 상해나 질병으로 위급한 상황 발생 시 신속하면서 편안하게 대처할 수 있도록 도와드립니다.",
      contents: [
        {
          checkedTtiles: [
            "국가별 예방접종 및 유의사항 상담",
            "현지 병원 안내 및 예약",
            "365일 24시간 Hot Line 운영 및 의료통역 지원",
            "간호사 파견 및 긴급이송 지원(고객 실비부담)",
          ],
        },
      ],
    },
    {
      title: "가사도우미 지원",
      subTitle: "중대질환으로 장기간 입원 시 가사도우미를 통해 집안일을 도와드립니다.",
      contents: [
        {
          texts: [
            `중대질환으로 7일 이상 입원 시 <i class="icon-right"></i>  방문전담간호사(헬스플래너)가 대리신청 <i class="icon-right"></i>  가사도우미 파견(년 1회 한하여 / 1일 8시간)`,
          ],
        },
      ],
    },
  ],
  plus: [
    {
      title: "우먼헬스케어서비스 플러스",
      subTitle: "기존 교보우먼헬스케어서비스 중대질환 치료지원 서비스 중 중대질환 수술을 위한 입퇴원 시 중대질환별* 횟수 한도 내 선택 서비스(간호사 병원동행 및 방문교육 또는 헬스홈케어서비스) 제공",
      contents: [
        {
          texts: [
            "※ 헬스홈케어서비스(최대 3회) 또는 간호사 병원동행 및 방문교육 중 택 1",
            "※ 해당질환 :  암/심장질환/뇌혈관질환/고혈압성질환/당뇨병으로 인한 합병증 ,원발성 폐동맥 고혈압, 다발경화증, 중증 세균성 수막염(뇌수막염, 척수수막염), 루게릭병(근위축성측삭경화증)"
          ],
        },
        {
          title: "간호사 병원동행 및 방문교육",
          texts: [
            "서비스 이용절차 : 01. 교보우먼케어서비스 콜센터(1588-7523) -> 02. 상담 -> 03. 입퇴원 시 동행 및 교육 진행 -> 04. 퇴원 후 건강 정보 제공",
            "- 진료 동행 및 입퇴원 수속 에스코트",
            "- 질병치료법 및 예후 설명",
            "- 진료 시 의료진과의 원활한 의사소통 도움",
            "- 치료 시(항암 및 방사선 등) 교육",
            "- 복용 약물 교육",
            "- 식이요법 관리",
            "- 질병 교육자료 무료 제공",
            "- 정서적 안정 지원",
          ]
        },
        {
          title: "헬스홈케어서비스",
          texts: [
            "서비스 이용절차 : 01. 교보우먼케어서비스 콜센터(1588-7523) -> 02. 상담 -> 03. 프로터치 해피콜 -> 04. 청소 진행 및 고객 검수",
            "- 청소, 공간 방역, 매트리스 살균 등 주거환경 클리닝(선택 시 최대 3회 한도로 이용 가능)"
          ]
        },
        {
          title: "여성 건강 다이어리 & 맞춤 건강 제품",
          texts: [
            "- 먼슬리싱 APP을 통한 생리/배란주기 기록 및 분석",
            "- 여성 건강 제품 추천 및 맞춤 배송",
          ]
        },
        {
          title: "식단 리셋 프로그램",
          texts: [
            "- 암별 식단관리프로그램(항암/수술/방사선/치료종료)",
            "- 암 환자 전용 식습관 분석 및 추천 레시피",
          ]
        },
        {
          title: "식습관 데이터 기반 영양관리서비스",
          texts: [
            "※ 오픈이노베이션 「UT인프라」 제휴",
            "- 질환별 식습관 분석",
            "- 건강관리를 위한 식습관 컨설팅",
            " &nbsp;&nbsp;&nbsp;⇒ 제휴 APP을 통해 제공"
          ]
        },
        {
          title: "케어식단 제공 및 영양 컨설팅",
          texts: [
            "- 중대질환으로 입원 후 퇴원 시 건강회복을 위한 건강식 자택 배달서비스 제공",
            "- 케어식단 영양 컨설팅"
          ]
        }
      ]
    }
  ],

  //  --- 건강체크
  // 암
  cancer: [
    {
      id: "breast",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "자가체크",
      title: "유방암 자가체크",
      text: "규칙적인 자가검진과 함께 유방암 조기 발견을 위한 정기검진이 꼭 필요합니다.",
      to: "check-agree",
    },
    {
      id: "ovarian",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "자가체크",
      title: "난소암 위험도 체크",
      text: "나의 상태를 체크하고 난소암의 위험성에 대비해 보세요.",
      to: "check-agree",
    },
    {
      id: "cervical",
      backgroundColor: "#ddf1c2",
      btnColor: "#a8c482",
      category: "자가체크",
      title: "자궁경부암 위험도 체크",
      text: "조기발견이 가장 중요합니다. 30대 이상의 여성은 1년에 한번 건강검진을 권장합니다.",
      to: "check-agree",
    },
  ],
  // 다이어트
  diet: [
    {
      id: "diet",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "자가체크",
      title: "다이어트",
      text: "다이어트를 위한 나의 행동습관을 체크해 보세요.",
    },
  ],
  // 심리자가체크
  selfCheck: [
    {
      id: "personaltype",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "자가체크",
      title: "성격유형",
      textCenter: true,
    },
    {
      id: "stress",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "자가체크",
      title: "스트레스",
      textCenter: true,
    },
    {
      id: "depression",
      backgroundColor: "#ddf1c2",
      btnColor: "#a8c482",
      category: "자가체크",
      title: "우울감",
      textCenter: true,
    },
    {
      id: "anxiety",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "자가체크",
      title: "불안",
      textCenter: true,
    },
    {
      id: "anger",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "자가체크",
      title: "분노조절장애",
      textCenter: true,
    },
    {
      id: "panic",
      backgroundColor: "#ddf1c2",
      btnColor: "#a8c482",
      category: "자가체크",
      title: "공황",
      textCenter: true,
    },
  ],
  therapy: [
    {
      id: "music",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "음악감상",
      title: "마음건강에 도움이 되는<br/>음악",
      btnText: "바로가기",
      textCenter: true,
      to: "check-therapy",
    },
    {
      id: "asmr",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "음악감상",
      title: "심리적 안정을 유도하는<br/>ASMR",
      btnText: "바로가기",
      textCenter: true,
      to: "check-therapy",
    },
  ],
  // ---  자가진단 문진

  consent: {
    breast: {
      sensitive: [
        {
          purpose: "유방암 자가체크",
          item:
            "가족/친인척 유방암/난소암/대장암, 자궁내막암 진단여부, 나이, 흡연 여부, 첫 생리 나이, 첫 출산 나이, 폐경 이후 호로몬 대체요법 여부 및 기간, 체크결과",
          period: "결과 확인 후<br/> 즉시 파기",
        },
      ],
    },
    ovarian: {
      sensitive: [
        {
          purpose: "난소암 위험도 체크",
          item:
            "가족/친인척 난소암/유방암, 자궁내막암 진단여부, 나이, 첫 생리나이, 폐경 나이, 불임진단 여부, 불임약 복용 여부, 피임약 복용기간, 체크결과",
          period: "결과 확인 후<br/> 즉시 파기",
        },
      ],
    },
    cervical: {
      sensitive: [
        {
          purpose: "자궁경부암 위험도 체크",
          item: "나이, 성생활 여부, 성병치료 여부, 인강유두종 바이러스 여부, 자궁암 검사 비정상 여부, 첫 생리 나이, 흡연 여부, 피임 여부",
          period: "결과 확인 후<br/> 즉시 파기",
        },
      ],
    },
  },
  // 개인정보 처리방침
  policy: {
    "depth-0": {
      p: policy.depth0.p("교보생명 우먼헬스케어서비스"),
      row: [
        {
          title: policy.depth0.title_1.title,
          contents: [
            {
              text: policy.depth0.title_1.text_1,
            },
            {
              table: {
                columns: [
                  { text: "구분" },
                  { text: "수집 · 이용 목적" },
                  { style: { minWidth: "600px" }, text: "개인정보 수집 · 이용 항목" },
                  { text: "보유기간" },
                ],
                row: [
                  [
                    { text: "공통(콜센터)", rowspan: "5", class: "bg-green-ligth" },
                    { text: "건강상담", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별, 전화번호 또는 휴대전화번호, 상담정보, 가족관계", class: "text-left" },
                    { text: "위탁계약 종료시 또는 실효후 5년경과시", rowspan: "5" },
                  ],
                  [
                    { text: "진료예약대행 및 사후관리", class: "bg-green-ligth" },
                    {
                      text: "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 진료의뢰서 또는 기타 의무기록 사본, 진단명, 소견내용",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "진료동행서비스", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 상병명, 병기 및 질병진행정도, 치료방법 및 종류, 치료 부작용 여부, 치료 경과(재발 및 전이여부)",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약대행 및 관리", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] (회원) 이름, 생년월일, 성별, 휴대폰번호, 검진희망일, 검진희망기관, 주소, 관심질환, 병력, 가족력, 수술이력여부, 복용약",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약 만족도 조사", class: "bg-green-ligth" },
                    {
                      text: "[필수] 휴대전화번호, 검진받은 기관, 조사내용",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "WEB서비스", rowspan: "3", class: "bg-green-ligth" },
                    { text: "여성암위험도_유방암체크서비스", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 가족/친인척 유방암/난소암/대장암, 자궁내막암여부, 나이, 흡연여부, 첫생리나이, 첫출산나이, 폐경이후 호로몬대체요법여부 및 기간, 체크결과",
                      class: "text-left",
                    },
                    { text: "확인즉시파 결과 확인즉시 파기", rowspan: "3" },
                  ],
                  [
                    { text: "여성암위험도난소암 위험도 체크", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 가족/친인척 난소암/유방암, 자궁내막암 진단여부, 나이, 첫생리나이, 폐경나이, 불임진단여부, 불임약 복용여부, 피임약 복용기간, 체크결과",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "자궁경부암 위험도 체크", class: "text-left", class: "bg-green-ligth" },
                    {
                      text: "[필수] 나이, 성생활여부, 성병치료여부, 인강유두종 바이러스 여부, 자궁암검사 비정상여부, 첫생리나이, 흡연여부 ,피임여부",
                      class: "text-left",
                    },
                  ],
                ],
              },
            },
            { text: policy.depth0.title_1.text_2 },
            { text: policy.depth0.title_1.text_3 },
            { text: policy.depth0.title_1.text_4 },
            { text: policy.depth0.title_1.text_5 },
          ],
        },
        {
          title: policy.depth0.title_2.title,
          contents: [
            { text: policy.depth0.title_2.text_1 },
            { text: policy.depth0.title_2.text_2 },
            { text: policy.depth0.title_2.text_3 },
            { text: policy.depth0.title_2.text_4 },
            { text: policy.depth0.title_2.text_5 },
          ],
        },
        {
          title: policy.depth0.title_3.title,
          contents: [
            { text: policy.depth0.title_3.text_1 },
            { text: policy.depth0.title_3.text_2 },
            { text: policy.depth0.title_3.text_3 },
            { text: policy.depth0.title_3.text_4 },
            {
              table: {
                columns: [
                  { style: { width: "180px" }, text: "제공받는 자", class: "bg-green-ligth" },
                  { style: { width: "180px" }, text: "제공받는 목적", class: "bg-green-ligth" },
                  { style: { width: "400px" }, text: "제공하는 개인정보의 항목", class: "bg-green-ligth" },
                  { style: { width: "180px" }, text: "보유 및 이용기간", class: "bg-green-ligth" },
                ],
                row: [
                  [
                    { text: "희망 검진예약 기관", button: true },
                    { text: "건강검진 예약 서비스 이용, 검진 전 약품 배송" },
                    { text: "가입기업명, 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 예약일, 검진과목, 주소, 가족관계, 이메일, 검진요청사항	" },
                    { text: "이용 목적 달성 또는 보유기간 경과 시" },
                  ],
                ],
              },
            },
          ],
        },
        {
          title: policy.depth0.title_4.title,
          contents: [
            { text: policy.depth0.title_4.text_1 },
            { text: policy.depth0.title_4.text_2 },
            { table: policy.depth0.title_4.table },
            { text: policy.depth0.title_4.text_3 },
          ],
        },
        {
          title: policy.depth0.title_5.title,
          contents: [
            { text: policy.depth0.title_5.text_1 },
            { text: policy.depth0.title_5.text_2 },
            { text: policy.depth0.title_5.text_3 },
            { text: policy.depth0.title_5.text_4 },
            { text: policy.depth0.title_5.text_5 },
          ],
        },
        {
          title: policy.depth0.title_6.title,
          contents: [{ text: policy.depth0.title_6.text_1 }],
        },
        {
          title: policy.depth0.title_7.title,
          contents: [
            { text: policy.depth0.title_7.text_1 },
            { text: policy.depth0.title_7.text_2 },
            { text: policy.depth0.title_7.text_3 },
            { text: policy.depth0.title_7.text_4 },
          ],
        },
        {
          title: policy.depth0.title_8.title,
          contents: [{ text: policy.depth0.title_8.text_1 }],
        },
        {
          title: policy.depth0.title_9.title,
          contents: [
            { text: policy.depth0.title_9.text_1 },
            { table: policy.depth0.title_9.table },
            { text: policy.depth0.title_9.text_2 },
            { text: policy.depth0.title_9.text_3 },
            { text: policy.depth0.title_9.text_4 },
            { text: policy.depth0.title_9.text_5 },
          ],
        },
        {
          title: policy.depth0.title_10.title,
          contents: [{ text: policy.depth0.title_10.text_1 }, { text: policy.depth0.title_10.text_1 }],
        },
        {
          title: policy.depth0.title_11.title,
          contents: [{ text: policy.depth0.title_11.text_1 }],
        },
        {
          title: policy.depth0.title_12.title,
          contents: [{ text: policy.depth0.title_12.text_1 }, { text: policy.depth0.title_12.text_2 }],
        },
      ],
    },
    "depth-1": {
      p: policy.depth0.p("교보생명 우먼헬스케어서비스"),
      row: [
        {
          title: policy.depth0.title_1.title,
          contents: [
            { text: policy.depth0.title_1.text_1 },
            {
              table: {
                columns: [
                  { style: { minWidth: "180px" }, text: "제공받는 자" },
                  { style: { minWidth: "180px" }, text: "제공받는 목적" },
                  { text: "제공하는 개인정보의 항목" },
                  { style: { minWidth: "180px" }, text: "보유 및 이용기간" },
                ],
                row: [
                  [
                    { text: "공통(콜센터)", rowspan: "5", class: "bg-green-ligth" },
                    { text: "건강상담", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별, 전화번호 또는 휴대전화번호, 상담정보, 가족관계<br>[선택] 없음", class: "text-left" },
                    { text: "위탁계약 종료시 또는 실효후 5년경과시", rowspan: "5" },
                  ],
                  [
                    { text: "진료예약대행 및 사후관리", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 진료의뢰서 또는 기타 의무기록 사본, 진단명, 소견내용<br>[선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "진료동행서비스", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 상병명, 병기 및 질병진행정도, 치료방법 및 종류, 치료 부작용 여부, 치료 경과(재발 및 전이여부)<br>[선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약대행 및 관리", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] (회원) 이름, 생년월일, 성별, 휴대폰번호, 검진희망일, 검진희망기관, 주소, 관심질환, 병력, 가족력, 수술이력여부, 복용약 [선택]없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약 만족도 조사", class: "bg-green-ligth" },
                    {
                      text: "[필수] 휴대전화번호, 검진받은 기관, 조사내용 [선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "WEB서비스", rowspan: "4", class: "bg-green-ligth" },
                    { text: "본인 확인", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별 , 내외국인여부, 휴대폰번호 [선택] 없음", class: "text-left" },
                    { text: "확인즉시파기", rowspan: "2" },
                  ],
                  [
                    { text: "ID/PW찾기", class: "bg-green-ligth" },
                    { text: "[필수] 이름, ID<br>[선택] 없음", class: "text-left" },
                  ],
                  [
                    { text: "회원가입 및 관리", class: "bg-green-ligth" },
                    { text: "[필수] 아이디, 비밀번호, 이름, 생년월일, 성별, 내외국인여부<br>[선택] 없음", class: "text-left" },
                    { text: "회원탈퇴 시 또는 법정 의무보유기간까지" },
                  ],
                  [
                    { text: "APP서비스", rowspan: "3", class: "bg-green-ligth" },
                    { text: "본인확인" },
                    { text: "[필수]이름,생년월일,내외국인여부,휴대폰번호<br>[선택]없음" },
                    { text: "확인즉시파기" },
                  ],
                  [
                    { text: "회원가입 및 관리", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별, 내외국인여부, 휴대폰번호<br>[선택] 없음", class: "text-left" },
                    { text: "회원탈퇴 시 또는 법정 의무보유기간까지", rowspan: "2" },
                  ],
                  [
                    { text: "위치발생 시 현재위치 발송", class: "bg-green-ligth" },
                    { text: "[필수] 휴대폰번호, 위치정보<br>[선택] 없음", class: "text-left" },
                  ],
                  [
                    { text: "복용약 알림설정", class: "bg-green-ligth" },
                    { text: "[필수] 복용약 [선택] 없음", class: "text-left" },
                    { text: "본인알림 설정 삭제시" },
                  ],
                ],
              },
            },
            { text: policy.depth0.title_1.text_2 },
            { text: policy.depth0.title_1.text_3 },
            { text: policy.depth0.title_1.text_4 },
            { text: policy.depth0.title_1.text_5 },
          ],
        },
        {
          title: policy.depth0.title_2.title,
          contents: [
            { text: policy.depth0.title_2.text_1 },
            { text: policy.depth0.title_2.text_2 },
            { text: policy.depth0.title_2.text_3 },
            { text: policy.depth0.title_2.text_4 },
          ],
        },
        {
          title: policy.depth0.title_3.title,
          contents: [
            { text: policy.depth0.title_3.text_1 },
            { text: policy.depth0.title_3.text_2 },
            { text: policy.depth0.title_3.text_3 },
            { text: policy.depth0.title_3.text_4 },
            {
              table: {
                columns: [
                  { style: { minWidth: "180px" }, text: "제공받는 자", class: "bg-green-ligth" },
                  { style: { minWidth: "180px" }, text: "제공받는 목적", class: "bg-green-ligth" },
                  { text: "제공하는 개인정보의 항목", class: "bg-green-ligth" },
                  { style: { minWidth: "180px" }, text: "보유 및 이용기간", class: "bg-green-ligth" },
                ],
                row: [
                  [
                    { text: "희망 검진예약 기관" },
                    { text: "건강검진 예약 서비스 이용" },
                    { text: "가입기업명, 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 예약일, 검진과목, 주소, 가족관계, 이메일, 검진요청사항" },
                    { text: "이용 목적 달성 또는 보유기간 경과 시" },
                  ],
                ],
              },
            },
          ],
        },
        {
          title: policy.depth0.title_4.title,
          contents: [
            { text: policy.depth0.title_4.text_1 },
            { text: policy.depth0.title_4.text_2 },
            {
              table: policy.depth0.title_4.table,
            },
            { text: policy.depth0.title_4.text_3 },
          ],
        },
        {
          title: policy.depth0.title_5.title,
          contents: [
            { text: policy.depth1.title_5.text_1 },
            { text: policy.depth1.title_5.text_2 },
            { text: policy.depth1.title_5.text_3 },
            { text: policy.depth1.title_5.text_4 },
            { text: policy.depth1.title_5.text_5 },
            { text: policy.depth1.title_5.text_6 },
          ],
        },
        {
          title: policy.depth0.title_6.title,
          contents: [{ text: policy.depth0.title_6.text_1 }],
        },
        {
          title: policy.depth0.title_7.title,
          contents: [
            { text: policy.depth0.title_7.text_1 },
            { text: policy.depth0.title_7.text_2 },
            { text: policy.depth0.title_7.text_3 },
            { text: policy.depth0.title_7.text_4 },
          ],
        },
        {
          title: policy.depth0.title_8.title,
          contents: [
            { text: policy.depth0.title_8.text_1 },
            { text: policy.depth0.title_8.text_2 },
            { text: policy.depth0.title_8.text_3 },
            { text: policy.depth0.title_8.text_4 },
          ],
        },
        {
          title: policy.depth0.title_9.title,
          contents: [{ text: policy.depth0.title_9.text_1 }, { table: policy.depth0.title_9.table }],
        },
        {
          title: policy.depth1.title_10.title,
          contents: [{ text: policy.depth1.title_10.text_1 }, { table: policy.depth1.title_10.table }],
        },
        {
          title: policy.depth1.title_11.title,
          contents: [{ text: policy.depth1.title_11.text_1 }, { table: policy.depth1.title_11.table }],
        },
        {
          title: policy.depth1.title_12.title,
          contents: [{ text: policy.depth0.title_10.text_1 }, { text: policy.depth0.title_10.text_2 }],
        },
        {
          title: policy.depth1.title_13.title,
          contents: [{ text: policy.depth0.title_11.text_1 }],
        },
        {
          title: policy.depth1.title_13.title,
          contents: [
            { text: policy.depth0.title_11.text_1 },
            { text: policy.depth0.title_11.text_2 },
            { text: policy.depth0.title_11.text_3 },
            { text: policy.depth0.title_11.text_4 },
            { text: policy.depth0.title_11.text_5 },
            { text: policy.depth0.title_11.text_6 },
          ],
        },
        {
          title: "14. 개인정보 처리방침 변경",
          contents: [
            { text: "① 이 개인정보 처리방침은 2021. 08. 00부터 적용됩니다." },
            { text: "② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다." },
          ],
        },
      ],
    },
    "depth-2": {
      p: policy.depth1.p("교보우먼케어사이트", "2019년 1월 30일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth2.title_4.title,
          contents: [
            { text: policy.depth2.title_4.text_1 },
            { text: policy.depth2.title_4.text_2 },
            { text: policy.depth2.title_4.text_3 },
            { text: policy.depth2.title_4.text_4 },
            { text: policy.depth2.title_4.text_5 },
            { text: policy.depth2.title_4.text_6 },
            { text: policy.depth2.title_4.text_7 },
            { text: policy.depth2.title_4.text_8 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth2.title_8.title,
          contents: [
            { text: policy.depth2.title_8.text_1 },
            { text: policy.depth2.title_8.text_2 },
            { text: policy.depth2.title_8.text_3 },
            { text: policy.depth2.title_8.text_4 },
            { text: policy.depth2.title_8.text_5 },
            { text: policy.depth2.title_8.text_6 },
            { text: policy.depth2.title_8.text_7 },
            { text: policy.depth2.title_8.text_8 },
            { text: policy.depth2.title_8.text_9 },
            { text: policy.depth2.title_8.text_10 },
            { text: policy.depth2.title_8.text_11 },
            { text: policy.depth2.title_8.text_12 },
            { text: policy.depth2.title_8.text_13 },
            { text: policy.depth2.title_8.text_14 },
            { text: policy.depth2.title_8.text_15 },
            { text: policy.depth2.title_8.text_16 },
            { text: policy.depth2.title_8.text_17 },
            { text: policy.depth2.title_8.text_18 },
            { text: policy.depth2.title_8.text_19 },
            { text: policy.depth2.title_8.text_20 },
            { text: policy.depth2.title_8.text_21 },
            { text: policy.depth2.title_8.text_22 },
            { text: policy.depth2.title_8.text_23 },
            { text: policy.depth2.title_8.text_24 },
          ],
        },
      ],
    },
    "depth-3": {
      p: policy.depth3.p("교보우먼케어사이트", "2017년 8월 25일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth2.title_4.title,
          contents: [
            { text: policy.depth2.title_4.text_1 },
            { text: policy.depth2.title_4.text_2 },
            { text: policy.depth2.title_4.text_3 },
            { text: policy.depth2.title_4.text_4 },
            { text: policy.depth2.title_4.text_5 },
            { text: policy.depth2.title_4.text_6 },
            { text: policy.depth2.title_4.text_7 },
            { text: policy.depth2.title_4.text_8 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth2.title_8.title,
          contents: [
            { text: policy.depth2.title_8.text_1 },
            { text: policy.depth2.title_8.text_2 },
            { text: policy.depth2.title_8.text_3 },
            { text: policy.depth2.title_8.text_4 },
            { text: policy.depth2.title_8.text_5 },
            { text: policy.depth2.title_8.text_6 },
            { text: policy.depth2.title_8.text_7 },
            { text: policy.depth2.title_8.text_8 },
            { text: policy.depth2.title_8.text_9 },
            { text: policy.depth2.title_8.text_10 },
            { text: policy.depth2.title_8.text_11 },
            { text: policy.depth2.title_8.text_12 },
            { text: policy.depth2.title_8.text_13 },
            { text: policy.depth2.title_8.text_14 },
            { text: policy.depth2.title_8.text_15 },
            { text: policy.depth2.title_8.text_16 },
            { text: policy.depth2.title_8.text_17 },
            { text: policy.depth2.title_8.text_18 },
            { text: policy.depth2.title_8.text_19 },
            { text: policy.depth2.title_8.text_20 },
            { text: policy.depth2.title_8.text_21 },
            { text: policy.depth2.title_8.text_22 },
            { text: policy.depth2.title_8.text_23 },
            { text: policy.depth2.title_8.text_24 },
          ],
        },
      ],
    },
    "depth-4": {
      p: policy.depth1.p("교보우먼케어사이트", "2017년 12월 4일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth4.title_4.title,
          contents: [
            { text: policy.depth4.title_4.text_1 },
            { text: policy.depth4.title_4.text_2 },
            { text: policy.depth4.title_4.text_3 },
            { text: policy.depth4.title_4.text_4 },
            { text: policy.depth4.title_4.text_5 },
            { text: policy.depth4.title_4.text_6 },
            { text: policy.depth4.title_4.text_7 },
            { text: policy.depth4.title_4.text_8 },
            { text: policy.depth4.title_4.text_9 },
            { text: policy.depth4.title_4.text_10 },
            { text: policy.depth4.title_4.text_11 },
            { text: policy.depth4.title_4.text_12 },
            { text: policy.depth4.title_4.text_13 },
            { text: policy.depth4.title_4.text_14 },
            { text: policy.depth4.title_4.text_15 },
            { text: policy.depth4.title_4.text_16 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth4.title_8.title,
          contents: [
            { text: policy.depth4.title_8.text_1 },
            { text: policy.depth4.title_8.text_2 },
            { text: policy.depth4.title_8.text_3 },
            { text: policy.depth4.title_8.text_4 },
            { text: policy.depth4.title_8.text_5 },
            { text: policy.depth4.title_8.text_6 },
            { text: policy.depth4.title_8.text_7 },
            { text: policy.depth4.title_8.text_8 },
            { text: policy.depth4.title_8.text_9 },
            { text: policy.depth4.title_8.text_10 },
            { text: policy.depth4.title_8.text_11 },
            { text: policy.depth4.title_8.text_12 },
            { text: policy.depth4.title_8.text_13 },
            { text: policy.depth4.title_8.text_14 },
            { text: policy.depth4.title_8.text_15 },
            { text: policy.depth4.title_8.text_16 },
          ],
        },
      ],
    },
  },
};
