<template>
  <!-- head -->
  <div>
    <div class="mb-4">
      <b-skeleton :animation="null" width="20%" height="1.5rem"></b-skeleton>
    </div>
    <h4 class="mb-4">
      <b-skeleton :animation="null" width="60%" height="4rem"></b-skeleton>
    </h4>
    <div class="mb-4">
      <b-skeleton :animation="null" width="100%" height="20rem"></b-skeleton>
    </div>
  </div>
  <!-- // head -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
