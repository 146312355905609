<template>
  <b-container :class="subTab ? 'hc-tab-container' : 'hc-container'">
    <div v-for="(s, idx) in items" :key="s.title" :id="`sub-tab-${idx}`" :class="{ 'pt-4 pt-md-5': subTab, 'pb-5': items.length !== idx + 1 }">
      <!-- head -->
      <b-row align-v="center" :class="[{ 'border-bottom border-theme-color-1 pb-2': s.subTitle }, 'mx-0']">
        <b-col cols="12" :md="s.button ? 8 : 12" class="px-0">
          <hash-title v-if="s.title" class="mb-3" :title="s.title"></hash-title>
          <p v-if="s.subTitle" class="gray fs-20 fs-md-18" v-html="s.subTitle"></p>
        </b-col>
        <b-col v-if="s.button" cols="12" md="4" class="text-left text-md-right pt-3 pt-md-0 px-0 py-3">
          <b-btn variant="link" class="blue-btn py-2 px-4 fs-20 fs-md-18" @click.prevent="handleModal(s.button)">
            {{ s.button.text }}
          </b-btn>
        </b-col>
      </b-row>
      <!-- head -->

      <!-- body -->
      <div>
        <ul>
          <li v-for="c in s.contents" :key="c.title" class="mt-4 pt-3">
            <!-- 바디 타이틀 -->
            <template v-if="c.buttonTitle">
              <div style="display: flex; align-items: center;" class="mb-2">
                <div
                  class="rounded-10 mt-2 fs-20 fs-md-18"
                  :style="{
                    border: `2px solid ${c.buttonTitle.buttonType == 'default' ? 'rgb(238, 238, 238)' : '#007bff'}`,
                    width: 'fit-content',
                  }"
                  style="padding: 0.5rem 1rem"
                >
                  <p :style="{ color: `${c.buttonTitle.buttonType == 'default' ? '' : '#007bff'}`}"
                    style="white-space: nowrap;"
                  >{{ c.buttonTitle.buttonTitle }}</p>
                </div>
                <h6 v-html="c.buttonTitle.title" class="fs-20 fw-7 fs-md-18 mb-1 mt-2" style="margin-left: 0.5em;"></h6>
              </div>
            </template>
            <template v-else>
              <h6 v-if="c.title" v-html="c.title" class="fs-20 fw-7 fs-md-18 mb-1"></h6>
            </template>
            <template v-if="c.checkedTtiles">
              <h6 v-for="chTitle in c.checkedTtiles" :key="chTitle" class="fs-20 fw-7 fs-md-18 mb-2"><i class="icon-ok"></i>{{ chTitle }}</h6>
            </template>
            <!-- // 바디 타이틀 -->

            <!-- 텍스트 -->
            <div v-if="c.texts">
              <p v-for="(t, i) in c.texts" :key="`${t}-${i}`" :class="[checkContentType(t) ? 'red' : 'gray', 'fs-20 fs-md-18']" v-html="t"></p>
            </div>
            <!-- // 텍스트 -->

            <!-- 신청하기 버튼 -->
            <div v-if="c.button" class="py-3">
              <b-btn variant="link" class="blue-btn py-2 px-4 fs-20 fs-md-18" @click.prevent="handleModal(c.button)">
                {{ c.button.text }}
              </b-btn>
            </div>
            <!-- 박스 텍스트-->
            <div
              v-if="c.boxTexts"
              class="p-3 p-md-4 rounded-10 mt-2 fs-20 fs-md-18"
              :style="{
                border: '2px solid #eee',
              }"
            >
              <p v-for="b in c.boxTexts" :key="b">{{ b }}</p>
            </div>
            <!-- // 박스 텍스트 -->

            <!-- 리스트 -->
            <template v-if="c.lists">
              <b-row>
                <b-col v-for="list in c.lists" :key="list.row" class="mx-0" cols="12" md="6">
                  <div v-for="row in list" :key="row" class="fs-20 fs-md-18">
                    {{ row }}
                  </div></b-col
                >
              </b-row>
            </template>
            <!-- // 리스트 -->

            <!-- 테이블 -->
            <service-table v-if="c.table" :tableObj="c.table"> </service-table>
            <!-- // 테이블 -->
          </li>
        </ul>
      </div>
      <!-- // body -->
    </div>
    <!-- 서브 페이지 모달 -->
    <modal-hospitals></modal-hospitals>
    <modal-hospitals-compare></modal-hospitals-compare>
    <modal-kb-products :itemKey="modalItemIndex"></modal-kb-products>
    <modal-health-reminder></modal-health-reminder>
    <modal-management></modal-management>
    <modal-counseling></modal-counseling>
    <!-- // 서브 페이지 모달 -->
  </b-container>
</template>

<script>
import ModalHospitals from "@/components/common/modal/ModalHospitals";
import ModalHospitalsCompare from "@/components/common/modal/ModalHospitalsCompare";
import ModalKbProducts from "@/components/kyoboservices/modal/ModalKbProducts";
import ModalHealthReminder from "@/components/kyobokidscare/modal/ModalHealthReminder";
import ModalManagement from "@/components/kyobokidscare/modal/ModalManagement";
import ModalCounseling from "@/components/kyobokidscare/modal/ModalCounseling";
import ServiceTable from "@/components/common/ServiceTable";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    ModalHospitalsCompare,
    ModalHospitals,
    ModalKbProducts,
    ModalHealthReminder,
    ModalManagement,
    ModalCounseling,
    ServiceTable,
  },
  data() {
    return {
      modalItemIndex: "",
      nowTitle: "",
    };
  },
  computed: {
    items() {
      return this.$t(this.cameledKey);
    },
    compareTitle() {
      return this.title == this.nowTitle;
    }
  },
  mounted() {
    this.pageViewTrack(this.title);
    this.setTitle(this.title);
  },
  updated() {
    if(!this.compareTitle) {
      this.pageViewTrack(this.title);
      this.setTitle(this.title);
    }
  },
  methods: {
    checkContentType(p) {
      return /^※/g.test(p);
    },
    handleModal(obj) {
      const { modalId, index, text } = obj;

      this.modalItemIndex = index;
      this.$bvModal.show(modalId);
      this.clickTrack(text, this.title);
    },
    setTitle(title) {
      this.nowTitle = title;
    },
  },
};
</script>

<style lang="scss" scoped></style>
