a<template>
  <!--  -->
  <b-modal
    id="modal-kb-products"
    :hide-footer="true"
    body-class="px-3 px-md-4 py-0 pb-3"
    header-class="px-3 px-md-4 border-bottom-0 d-block"
    scrollable
    centered
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <b-row align-v="center">
        <b-col cols="6">
          <h3 class="fs-24 fs-md-20 fw-7 mt-1">
            꼭 읽어보세요!
          </h3>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-btn variant="link" @click="close()">
            <div
              class="bg-img"
              :style="{
                backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
                width: '3rem',
                paddingBottom: '3rem',
              }"
            ></div>
          </b-btn>
        </b-col>
      </b-row>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <ul v-for="(t, i) in targetItems" :key="i" class="d-flex">
      <li
        v-html="t"
        class="fs-20 fs-md-18 mb-3"
        :style="{ listStyle: 'inside' }"
      ></li>
    </ul>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
export default {
  props: {
    itemKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: {
        newHealth: {
          info: [
            "교보New헬스케어서비스는 교보생명이 정한 서비스 부가기준*을 충족하고, 서비스신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자(주된 보험대상자)에게 제공합니다. (보험유지건에 한함)",
            "아래 부가기준에 따라 서비스 제공(일부 대상상품에 따라 제공기준 상이) - 교보New헬스케어서비스 15년형 : GI종신보험 주계약 5천만원 이상 7천만원 미만 가입시 - 교보New헬스케어서비스 : 종신보험 주계약 1억원 이상 가입 시, GI종신보험 주계약 7천만원 이상 가입 시 - New헬스케어서비스 프리미어형 : 주계약 2억원 이상 가입 시 ※ 2024. 4월 기준 / 상품별 세부기준은 담당FP 또는 담당FP지점 문의",
            "교보New헬스케어서비스는 회원(주피보험자)나이 만 70세까지 제공합니다. (단, 만 50세 이후 가입고객의 경우 최대 20년까지 제공)",
            "교보New헬스케어서비스 15년형은 보험계약일부터 15년 동안 제공되며 서비스 기간(15년) 내 주요질병 진단 시, 진단 후 5년 기본제공됩니다.",
            "서비스 세부내용은 가입시 제공하는 「제공신청서 및 동의서와 서비스 주요 내용 설명서」를 참고하시기 바랍니다.",
            "교보New헬스케어서비스는 회원 전용 홈페이지(www.kyobohealthcare.com), 헬스콜센터(1588-7524)를 통하여 제공합니다.",
            "교보New헬스케어서비스는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
            "보험계약 체결 전 상품설명서와 약관을 읽어보시기 바랍니다.",
            "보험상품을 가입할 때 보험계약의 기본사항(보험상품명, 납입기간, 피보험자 등)을 반드시 확인하시기 바랍니다.",
            "교보생명은 보험상품에 대해 충분히 설명할 의무가 있으며 가입에 앞서 이에 대한 충분한 설명을 받으시기 바랍니다."
          ],
          platinum: [
            "교보프리미어New헬스케어서비스는 교보생명이 정한 서비스 부가기준*을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자(주된 보험대상자)에게 제공합니다.(보험유지건에 한함) *종신/CI/GI보험 주계약 2억원 이상 가입시 제공",
            "교보프리미어New헬스케어서비스는 회원 전용 홈페이지(https://kyoboadditional.com), 헬스콜센터(1588-7524)를 통하여 제공합니다.",
            "교보프리미어New헬스케어서비스II는 회원(주피보험자)나이 만 70세까지 제공합니다. (단, 2018.12.31 이후 가입건 中 만 50세 이후 가입고객의 경우 최대 20년까지 제공)",
            "교보프리미어New헬스케어서비스는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
            "보험계약체결 전 상품설명서와 약관을 읽어보시기 바랍니다.",
            "보험상품을 가입할 때 보험계약의 기본사항(보험상품명, 납입기간, 피보험자 등)을 반드시 확인하시기 바랍니다.",
            "교보생명은 보험상품에 대해 충분히 설명할 의무가 있으며 가입에 앞서 이에 대한 충분한 설명을 받으시기 바랍니다.",
            "법령 및 내부통제기준에 따른 광고 관련 절차를 준수하였습니다.",
          ],
        },
        health: {
          info: [
            "교보헬스케어서비스는 교보생명이 정한 서비스 부가기준*을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자(주된 보험대상자)에게 제공합니다.(보험유지건에 한함)<br/>*종신보험 주계약 1억원 이상, CI·GI보험 주계약 7천만원 이상 가입[(무)실속있는체증형종신보험(보증비용부과형), (무)교보실속있는간편가입체증형종신보험(보증비용부과형)의 경우 주계약 7천만원 이상 가입](2021.6월 기준/상품별 세부기준은 담당FP 또는 담당 FP지점 문의)",
            "교보헬스케어서비스는 회원 전용 홈페이지(https://kyoboadditional.com/), 교보헬스케어서비스 콜센터(1588-7524)를 통하여 제공합니다.",
            "교보헬스케어서비스II는 회원(주피보험자)나이 만 70세까지 제공합니다.(단, 2018.12.31이후 가입건 中 만 50세 이후 가입고객의 경우 최대 20년까지 제공)",
            "교보헬스케어서비스는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
            "보험계약체결 전 상품설명서와 약관을 읽어보시기 바랍니다.",
            "보험상품을 가입할 때 보험계약의 기본사항(보험상품명, 납입기간, 피보험자 등)을 반드시 확인하시기 바랍니다.",
            "교보생명은 보험상품에 대해 충분히 설명할 의무가 있으며 가입에 앞서 이에 대한 충분한 설명을 받으시기 바랍니다.",
            "법령 및 내부통제기준에 따른 광고 관련 절차를 준수하였습니다.",
          ],
          platinum: [
            "교보프리미어헬스케어서비스는 교보생명이 정한 서비스 부가기준*을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자(주된 보험대상자)에게 제공합니다.(보험유지건에 한함)*종신/CI/GI보험 주계약 2억원 이상 가입시 제공",
            "교보프리미어헬스케어서비스는 회원 전용 홈페이지(https://kyoboadditional.com/), 교보헬스케어서비스 콜센터(1588-7524)를 통하여 제공합니다.",
            "교보프리미어헬스케어서비스II는 회원(주피보험자)나이 만 70세까지 제공합니다.(단, 2018.12.31 이후 가입건 中 만 50세 이후 가입고객의 경우 최대 20년까지 제공)",
            "교보프리미어헬스케어서비스는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
            "보험계약체결 전 상품설명서와 약관을 읽어보시기 바랍니다.",
            "보험상품을 가입할 때 보험계약의 기본사항(보험상품명, 납입기간, 피보험자 등)을 반드시 확인하시기 바랍니다.",
            "교보생명은 보험상품에 대해 충분히 설명할 의무가 있으며 가입에 앞서 이에 대한 충분한 설명을 받으시기 바랍니다.",
            "법령 및 내부통제기준에 따른 광고 관련 절차를 준수하였습니다.",
          ],
        },
        woman: {
          info: [
            "교보우먼헬스케어서비스는 교보생명이 정한 서비스 부가기준*을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자(주된 보험대상자)에게 제공합니다.(보험유지건에 한함)*여성CI보험 주계약 7천만원 이상 가입(2021.6월 기준/상품별 세부기준은 담당FP 또는 담당 FP지점 문의)",
            "교보우먼헬스케어서비스는 회원 전용 홈페이지(https://kyoboadditional.com/), 교보우먼헬스케어서비스 콜센터(1588-7523)를 통하여 제공합니다.",
            "교보우먼헬스케어서비스II는 회원(주피보험자)나이 만 70세까지 제공합니다.(단, 2018.12.31이후 가입건 中 만 50세 이후 가입고객의 경우 최대 20년까지 제공)",
            "교보우먼헬스케어서비스는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
            "보험계약체결 전 상품설명서와 약관을 읽어보시기 바랍니다.",
            "보험상품을 가입할 때 보험계약의 기본사항(보험상품명, 납입기간, 피보험자 등)을 반드시 확인하시기 바랍니다.",
            "교보생명은 보험상품에 대해 충분히 설명할 의무가 있으며 가입에 앞서 이에 대한 충분한 설명을 받으시기 바랍니다.",
            "법령 및 내부통제기준에 따른 광고 관련 절차를 준수하였습니다.",
          ],
          platinum: [
            "교보플래티넘우먼헬스케어서비스는 교보생명이 정한 서비스 부가기준*을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자(주된 보험대상자)에게 제공합니다.(보험유지건에 한함)*여성CI보험 주계약 2억원 이상 가입",
            "교보플래티넘우먼헬스케어서비스는 회원 전용 홈페이지(https://kyoboadditional.com/), 교보우먼헬스케어서비스 콜센터(1588-7523)를 통하여 제공합니다.",
            "교보플래티넘우먼헬스케어서비스II는 회원(주피보험자)나이 만 70세까지 제공합니다.(단, 2018.12.31이후 가입건 中 만 50세 이후 가입고객의 경우 최대 20년까지 제공)",
            "교보플래티넘우먼헬스케어서비스는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
            "보험계약체결 전 상품설명서와 약관을 읽어보시기 바랍니다.",
            "보험상품을 가입할 때 보험계약의 기본사항(보험상품명, 납입기간, 피보험자 등)을 반드시 확인하시기 바랍니다.",
            "교보생명은 보험상품에 대해 충분히 설명할 의무가 있으며 가입에 앞서 이에 대한 충분한 설명을 받으시기 바랍니다.",
            "법령 및 내부통제기준에 따른 광고 관련 절차를 준수하였습니다.",
          ],
        },
        children: [
          "[교보어린이헬스케어서비스]는 교보생명이 정한 서비스 부가기준을 충족하고,서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주회원(가입자녀), 종회원(계약자)에게 제공합니다.(보험유지건에 한함)",
          "[교보어린이헬스케어서비스]는 회원 전용 홈페이지(https://kyoboadditional.com/), 교보어린이헬스콜센터(1588-4733)를 통하여 제공합니다.",
          "[교보어린이헬스케어서비스]는 가입자녀의 만나이 0 ~ 5세까지 제공됩니다.단, 가입 당시 자녀의 만나이가 5세인 경우 교보어린이헬스케어서비스 제공기간이 1년 미만이므로교보에듀케어서비스READ가 제공됩니다.",
          "향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
        ],
        junior: [
          "교보주니어헬스케어서비스는 교보생명이 정한 서비스 부가기준을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주회원(가입자녀),종회원(계약자)에게 제공합니다. (보험유지건에 한함)",
          "교보주니어헬스케어서비스는 회원 전용 홈페이지, 모바일 Web, 교보주니어헬스케어콜센터(1811-7524)를 통해 제공합니다.",
          "교보주니어헬스케어서비스는 가입자녀의 만나이 0~18세까지 제공됩니다.",
          "향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
        ],
        dementia: [
          `교보치매케어서비스는 교보생명이 정한 서비스 부가기준을 충족하고 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자에게 제공합니다. (보험유지건에 한함)`,
          `교보치매케어서비스는 가입하신 보험계약일로부터 20년간 제공되며, 20년 후부터는 고객이 비용을 부담하는 "치매케어멤버쉽서비스(제휴형)"을 1년 단위로 신청하여 이용할 수 있습니다.(고객부담)`,
          `교보치매케어서비스의 평상시 치매예방서비스는 보험계약일로부터 제공하며, 단계별 치매케어서비스는 가입하신 보험상품의 치매보장개시일(2년)이후 치매보험금 지급사유 발생시 제공합니다.`,
          `교보치매케어서비스는 회원 전용 홈페이지(https://kyoboadditional.com/), 치매케어콜센터(1811-7002)를 통하여 제공합니다.`,
          `교보치매케어서비스는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.`,
          `이 서비스에 대해 보다 자세한 내용을 알기 원하시면 교보생명 컨설턴트에게 문의해 주시기 바랍니다.`,
        ],
        coach: [
          "교보건강코칭서비스는 교보생명이 정한 서비스 부가기준을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자(주된 보험대상자)에게 보험계약일로부터 3년 동안 제공합니다.(보험유지건에 한함)",
          "교보건강코칭서비스는 회원 전용 어플리케이션(교보건강코칭), 카카오톡(교보건강코칭), 건강코칭콜센터(1661-0664)를 통하여 제공합니다.",
          "교보건강코칭서비스는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
          "이 서비스에 대해 보다 자세한 내용을 알기 원하시면 교보생명 컨설턴트에게 문의해 주시기 바랍니다.",
        ],
        basic: [
          "교보헬스케어서비스BasicⅡ는 교보생명이 정한 서비스 부가기준을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주피보험자(주된 보험대상자)에게 제공합니다.(보험유지건에 한함)",
          "교보헬스케어서비스BasicⅡ는 어플리케이션(교보생명 상품부가서비스 APP), 교보헬스케어서비스BasicⅡ 콜센터(1811-7536)를 통하여 제공합니다.",
          "교보헬스케어서비스BasicⅡ는 보험계약일부터 20년간 제공합니다.",
          "교보헬스케어서비스BasicⅡ는 향후 제공여건 변화에 따라 서비스 내용 및 제공방법, 제휴회사가 변경되거나 중지될 수 있습니다.",
        ],
        education: [
          "교보에듀케어서비스는 교보생명이 정한 서비스 부가기준을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출한 주회원(가입자녀),종회원(계약자)에게 제공합니다. (보험유지건에 한함)",
          "교보에듀케어서비스는 회원 전용 홈페이지(www.kyoboeducare.com), 어플리케이션(교보에듀케어), 에듀케어콜센터(1588-1078)를 통해 제공합니다.",
          "교보에듀케어서비스는 ㈜교보문고와 제휴하여 제공하며, 제휴업체와의 협약종료 등으로 인하여 향후 서비스가 중단되거나 서비스 내용 또는 제공업체가 변경될 수 있습니다.",
          "이 서비스에 대해 보다 자세한 내용을 알기 원하시면 교보생명 컨설턴트에게 문의해주시기 바랍니다.",
        ],
        silver: [
          "교보실버케어서비스플러스는 교보생명이 정한 서비스 부가기준을 충족하고, 서비스 신청서(개인정보 수집·이용·제공 동의서)제출한 주피보험자(주된 보험대상자)에게 제공합니다.(보험유지건에 한함)",
          "케어시설 이용 시 필요한 비용은 고객께서 부담하며, 케어매니저 방문교육에서 직접 간병을 해드리지는 않습니다.",
          "교보실버케어서비스플러스는 회원 전용 홈페이지나 실버케어스테이션(02-732-3790)를 통하여 제공합니다.",
          "교보실버케어서비스플러스는 향후 서비스 퀄리티 향상 및 제공여건 변화에 따라 서비스 내용 및 제공방법이 변경되거나 중지될 수 있습니다.",
          "별도의 회원가입 신청이나 회원카드 발급은 없습니다.",
          "이 서비스에 대해 보다 자세한 내용을 알기 원하시면 교보생명 컨설턴트에게 문의해 주시기 바랍니다.",
        ],
        cancer: [
          "교보암케어서비스는 교보생명이 정한 서비스 부가기준*을 충족하고 서비스 신청서(개인정보 수집ㆍ이용ㆍ제공 동의서)를 제출한 주피보험자(주된보험자)에게 제공합니다.(보험유지건에 한함)",
          "*(무)교보암케어보험 (서비스선택형)가입(2021.11월 기준)",
          "교보암케어서비스(기본제공형)는 무상서비스로 보험계약일로부터 보장유지기간까지 제공합니다.",
          "교보암케어서비스(서비스선택형)는 고객 의사에 따라 선택하여 이용할 수 있는 유상서비스로 보험금 지급부터 2년 이내에 각각 서비스를 보험금 이내에서 선택하여 이용 가능합니다.",
          "교보암케어서비스(서비스선택형)의 소요비용은 고객이 직접 해당 서비스업체에 지불하며, 교보생명에서는 고객님의 서비스선택형 서비스와 관련된 어떠한 비용도 지급하지 않습니다.",
          "교보암케어서비스(서비스선택형)의 서비스 접수 및 진행시에 발생하는 서비스제공 책임과 의무는 해당 서비스업체에 있습니다.",
          "교보암케어서비스는 교보생명의 제휴회사가 제공하며 교보암케어서비스 콜센터(1544-1658)를 통해 제공합니다.",
          "교보암케어서비스(서비스선택형) 이용료는 교보생명 홈페이지를 통해 확인할 수 있으며, 매년 변경될 수 있습니다.",
          "향 후 대내외 의료환경 및 제공 환경 변화로 인해 향후 서비스 항목 및 내용이 변경되거나 중지 될 수 있습니다.",
        ],
      },
    };
  },
  computed: {
    targetItems() {
      return this.itemKey
        ? this.items[this.key][this.itemKey]
        : this.items[this.key];
    },
    key() {
      return this.$route.params.key;
    },
  },
};
</script>
