<template>
  <div class="my-3">
    <div v-if="visibleItems.length">
      <!-- 칼럼 -->
      <div class="mb-4">
        <span class="fs-18 white px-3 py-1 rounded-pill bg-theme-color-1">{{
          selectedProgram.text
        }}</span>
      </div>
      <!-- // 칼럼 -->
      <!-- 타이틀 -->
      <hash-title
        class="mb-3 mb-md-4"
        :title="'아래 질문에 답을 하고 결과를 확인'"
        :titleClass="'fw-7'"
        :isHash="false"
        :styles="{ fontSize: '1.2rem' }"
        ><span class="fw-7" :style="{ fontSize: '1.2rem' }"
          >해보세요</span
        ></hash-title
      >
      <!-- // 타이틀 -->
      <div>
        <survey-swiper
          :slideItems="visibleItems"
          :appendTtile="appendTtile"
          @handleSubmit="handleSubmit"
          @onSlideChange="onSlideChange"
        ></survey-swiper>
      </div>
    </div>
    <skeleton-check-survey v-else></skeleton-check-survey>
  </div>
</template>

<script>
import SurveySwiper from "@/components/common/SurveySwiper";
import QuestionBox from "@/components/common/QuestionBox";
import SkeletonCheckSurvey from "@/components/common/SkeletonCheckSurvey";
import { showToast } from "@/plugins/custom-toast";

export default {
  props: {
    selectedProgram: {
      type: Object,
      default: null,
    },
    joinserial: {
      type: String,
      default: null,
    },
    mcode: {
      type: String,
      default: null,
    },
    modalId: {
      type: String,
      default: "",
    },
  },
  components: {
    QuestionBox,
    SkeletonCheckSurvey,
    SurveySwiper,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    modalBody() {
      return document.querySelector(`#${this.modalId}___BV_modal_body_`);
    },
    // 특정 조건에서 disabled 되는 문제들 제거하는 함수
    visibleItems() {
      // 프로그램에 해당하는 지문
      const items =
        this.selectedProgram &&
        this.$t("questionsObj")[this.selectedProgram.value].questions;

      if (!Array.isArray(items)) return [];

      // 지문에 해당하는 룰
      const ruleList = this.$t("rules")[this.selectedProgram.value];

      // 룰 계산
      return items.filter((item) => {
        const currentRuleObj = ruleList?.find(
          (rule) => rule.current === item.number
        );

        if (!currentRuleObj) return true;
        const { rules } = currentRuleObj;

        const findParent = (parentNum) =>
          items.find((item) => parentNum === item.number);

        return rules.reduce((bool, fn) => bool && fn(findParent), true);
      });
    },
    // 특정 답변의 결과가 제목에 포함될 경우
    appendTtile() {
      const item = this.visibleItems[this.activeIndex];
      const rules = this.$t("titleRules")[this.selectedProgram.value];
      const currentRule = rules?.find((rule) => rule.current === item.number);
      if (!currentRule) return "";
      const ParentItems = this.visibleItems.find(
        (item) => item.number === currentRule.parent
      );
      return currentRule?.rule(ParentItems) ?? "";
    },
    munjinBody() {
      return this.visibleItems?.reduce((obj, item) => {
        const answers =
          ["checkbox"].includes(item.answersType) || !item.answersType
            ? item.selectedAnswers.filter((val) => !!val)
            : item.selectedAnswers;

        if (["selectbox"].includes(item.answersType)) {
          return {
            ...obj,
            [item.target]: answers.map((val) => val.value).join("|"),
          };
        }
        return { ...obj, [item.target]: answers.join("|") };
      }, {});
    },
    apis() {
      const { kyoboApi } = this.getApis();
      return kyoboApi.program.management;
    },
  },
  methods: {
    scrollTop() {
      this.modalBody.scrollTo(0, 0);
    },
    async onSave(sseq) {
      const munjinBody = {
        sseq,
        joinserial: this.joinserial,
        ...this.munjinBody,
      };
      const {
        data: { message },
      } = await this.apis.save(munjinBody);
      return message;
    },
    async onApply() {
      const applyBody = {
        joinserial: this.joinserial,
        mcode: this.mcode,
      };
      const {
        data: { sseq, message },
      } = await this.apis.apply(applyBody);
      return { sseq, message };
    },

    async handleSubmit() {
      const { sseq, message: applyMessage } = await this.onApply();

      // 진행중인 서비스가 존재하는 경우
      if (applyMessage === "이미 진행 중인 서비스가 존재합니다.") {
        this.$bvModal.hide(this.modalId);
        this.$root.$emit("showModalAlert", applyMessage);
        return;
      }

      const saveMessage = await this.onSave(sseq);
      this.$bvModal.hide(this.modalId);
      this.$root.$emit("showModalAlert", saveMessage);
    },
    onSlideChange(activeIndex) {
      this.activeIndex = activeIndex;
    },
  },
  mounted() {
    this.scrollTop();
  },
  beforeDestroy() {
    this.visibleItems.forEach((item) => delete item.selectedAnswers);
  },
};
</script>
