var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.tableObj.caption)?_c('h6',{staticClass:"fs-20 fs-md-18 fw-7 py-4"},[_vm._v(" "+_vm._s(_vm.tableObj.caption)+" ")]):_vm._e(),_c('div',{staticClass:"position-relative"},[_c('table-swiper',{ref:"table-swiper",attrs:{"options":{
        slidesPerView: 1,
        allowTouchMove: false,
        breakpoints: {
          400: {
            allowTouchMove: true,
            slidesPerView: 0.5,
          },
          720: {
            allowTouchMove: true,
            slidesPerView: 0.6,
          },
        },
        navigation: {
          nextEl: '.table-next',
          prevEl: '.table-prev',
        },
      },"isPopover":""},scopedSlots:_vm._u([{key:"popover",fn:function(){return undefined},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"mb-4 border-bottom border-top border-blue border-2 px-0 fs-16 w-100"},[_c('b-row',{staticClass:"fs-20 fs-md-18 fw-7 border-bottom border-blue border-2 mx-0"},_vm._l((_vm.tableObj.columns),function(th,idx){return _c('b-col',{key:th.text,staticClass:"text-center flex-center flex-column p-2 p-md-3",attrs:{"cols":_vm.tableObj.cols.th[idx]},domProps:{"innerHTML":_vm._s(th.text)}})}),1),_vm._l((_vm.tableObj.rows),function(list,idx){return _c('b-row',{key:`${list.title}-${idx}`,staticClass:"list mx-0 fs-20 fs-md-16",style:({
              borderBottom: '1px solid  rgba(0, 40, 101, 0.1)',
            })},_vm._l((list),function(col,_idx){return _c('b-col',{key:col.text,class:[
                {
                  'bg-green-ligth': !_idx,
                },

                `p-2 p-md-3 ${_vm.tableObj.class.td[_idx]}`,
              ],attrs:{"cols":_vm.tableObj.cols.td[_idx]}},[_vm._v(" "+_vm._s(col.text)+" ")])}),1)})],2)]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }