import { render, staticRenderFns } from "./ConsultingDetail.vue?vue&type=template&id=7a9152f5&scoped=true"
import script from "./ConsultingDetail.vue?vue&type=script&lang=js"
export * from "./ConsultingDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a9152f5",
  null
  
)

export default component.exports