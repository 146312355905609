<template>
  <!-- 이전글, 다음글 -->

  <b-row class="mx-0 border-top border-gray-5">
    <b-col cols="12" v-for="(value, i) in orderedBtnArr" :key="value.coun_key">
      <b-btn
        v-if="value[report_key]"
        variant="link black w-100 text-left"
        @click="handleClick(value)"
      >
        <b-row class="fs-18 py-4 border-bottom border-gray-5">
          <b-col
            cols="12"
            md="2"
            class="fw-7 text-md-center py-1 fs-20 fs-md-18"
            >{{ !i ? "이전글" : "다음글" }}
          </b-col>
          <b-col
            cols="12"
            md="10"
            class="gray text-truncate py-1 fs-20 fs-md-18"
            v-html="value.title"
          >
          </b-col>
        </b-row>
      </b-btn>
    </b-col>
  </b-row>
  <!-- // 이전글, 다음글 -->
</template>

<script>
export default {
  props: {
    btnObj: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    report_key() {
      if (this.key === "recent-news") return "news_key";
      if (this.key === "online") return "coun_key";
      return "report_key";
    },
    orderedBtnArr() {
      const { nextReport, preReport } = this.btnObj;
      return [preReport, nextReport];
    },
  },
  methods: {
    handleClick(value) {
      if (this.key === "online") {
        this.$router.push({
          ...this.$route,
          query: {
            coun_key: value.coun_key,
            replay_key: value.replay_key,
          },
        });
        return;
      }
      if (["health-news"].includes(this.key)) {
        this.$router.push({
          ...this.$route,
          params: {
            ...this.$route.params,
            id: value.report_key,
          },
        });
        return;
      }
      if (["recent-news"].includes(this.key)) {
        this.$router.push({
          ...this.$route,
          params: {
            ...this.$route.params,
            id: value.news_key,
          },
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
