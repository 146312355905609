<template>
  <div class="h-100 position-relative">
    <kakao-map v-bind="{ x, y, name, address }" class="h-100"></kakao-map>
    <div
      class="row w-100 flex-center mx-0 position-absolute bg-white "
      :style="{ bottom: 0, zIndex: 1 }"
    >
      <div class="px-4 px-md-4 py-4 flex-between col">
        <div>
          <div class="fs-20 fs-md-24 fw-7">{{ name }}</div>
          <input
            ref="address"
            class="d-none"
            readonly
            v-model="address"
            type="text"
          />
          <span class="gray-5 mt-1">{{ address }}</span>
        </div>
        <div class="flex-center">
          <b-btn
            variant="link"
            @click="copyAddr"
            class="bg-img"
            :style="{
              width: '3rem',
              paddingBottom: '3rem',
              backgroundImage: `url(${require('@/assets/img/common/ic-copy-mini.svg')})`,
            }"
          >
          </b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonHosMap from "@/components/common/SkeletonHosMap";
import KakaoMap from "@/components/common/modal/modalComponents/KakaoMap";
import { showToast } from "@/plugins/custom-toast";

export default {
  components: {
    KakaoMap,
    SkeletonHosMap,
  },
  computed: {
    x() {
      return this.$route.query.x + "";
    },
    y() {
      return this.$route.query.y + "";
    },
    name() {
      return this.$route.query.name;
    },
    address() {
      return this.$route.query.address;
    },
  },
  methods: {
    async copyAddr() {
      try {
        // ie or firefox
        if (!navigator.clipboard?.readText) {
          this.$refs.address.select();
          document.execCommand("copy");
          showToast({ title: "주소가 복사되었습니다." });
          return;
        }

        // 그외 브라우저

        await navigator.clipboard.writeText(this.address);
        showToast({ title: "주소가 복사되었습니다." });
      } catch (e) {
        showToast({
          title: "클립보드 복사 실패",
          variant: "warning",
        });
        console.log(e);
      }
    },
  },
};
</script>
