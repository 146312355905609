var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-policy","size":"lg","hide-footer":true,"body-class":"p-0","centered":"","header-class":"p-0 d-block border-bottom-0","scrollable":"","no-close-on-backdrop":""},on:{"hide":function($event){_vm.target = 'depth-0'}},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('div',{staticClass:"mx-4 mx-md-5 py-4 flex-between"},[_c('div',{staticClass:"flex-start"},[(_vm.target !== 'depth-0')?_c('b-btn',{style:({ marginLeft: '-15px' }),attrs:{"variant":"link"},on:{"click":function($event){_vm.target = 'depth-0';
            _vm.modalBody.scrollTo(0, 0);}}},[_c('img',{attrs:{"src":require("@/assets/img/common/ic-header-navigation.svg"),"alt":"arrow"}})]):_vm._e(),_c('h3',{staticClass:"fs-24 fw-7"},[_vm._v(" 개인정보처리방침 ")])],1),_c('b-btn',{style:({ marginRight: '-15px' }),attrs:{"variant":"link"},on:{"click":function($event){return close()}}},[_c('div',{staticClass:"bg-img",style:({
            backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
            width: '3rem',
            paddingBottom: '3rem',
          })})])],1)]}}])},[_c('div',{staticClass:"mx-4 mx-md-5 mb-4 mb-md-5"},[_c('p',{staticClass:"gray fs-20 fs-md-18",domProps:{"innerHTML":_vm._s(_vm.targetPolicy.p)}}),_c('ul',[_vm._l((_vm.targetPolicy.row),function(row,i){return _c('li',{key:`${row.title}-${i}`,staticClass:"mb-4"},[_c('p',{staticClass:"fs-20 fw-7 mb-1"},[_vm._v(_vm._s(row.title))]),(row.contents)?_c('ul',_vm._l((row.contents),function(_row,_i){return _c('li',{key:`child-${_i}`,staticClass:"fs-20 fs-md-18"},[(_row.text)?_c('div',{domProps:{"innerHTML":_vm._s(_row.text)}}):(_row.table)?[_c('policy-table',{attrs:{"tableObj":_row.table}})]:_vm._e()],2)}),0):_vm._e()])}),(_vm.target === 'depth-0')?[_c('li',[_c('b-btn',{style:({'text-align': 'left'}),attrs:{"variant":"link"},on:{"click":function($event){_vm.target = 'depth-1';
              _vm.modalBody.scrollTo(0, 0);}}},[_vm._v("[이전 개인정보처리방침 보기 (2022. 01. 07 ~ 2022. 09. 18버전)]")])],1),_c('li',[_c('b-btn',{style:({'text-align': 'left'}),attrs:{"variant":"link"},on:{"click":function($event){_vm.target = 'depth-2';
              _vm.modalBody.scrollTo(0, 0);}}},[_vm._v(_vm._s(!["kyoboseniorcare"].includes(_vm.$i18n.locale) ? "[이전 개인정보처리방침 보기 (2019. 01.30 ~ 2022.01.06버전)]" : "[이전 개인정보처리방침 보기 (2019. 05.01 ~ 2022.01.06 버전)]"))])],1),(!['kyoboseniorcare'].includes(_vm.$i18n.locale))?_c('li',[_c('b-btn',{style:({'text-align': 'left'}),attrs:{"variant":"link"},on:{"click":function($event){_vm.target = 'depth-3';
              _vm.modalBody.scrollTo(0, 0);}}},[_vm._v(_vm._s(!["kyobojuniorcare"].includes(_vm.$i18n.locale) ? "[이전 개인정보처리방침 보기 (2017. 12. 04 ~ 2019. 01. 29버전)]" : "[이전 개인정보처리방침 보기 (2019. 08. 07 ~ 2019. 01. 29버전)]"))])],1):_vm._e(),(!['kyobojuniorcare', 'kyoboseniorcare'].includes(_vm.$i18n.locale))?_c('li',[_c('b-btn',{style:({'text-align': 'left'}),attrs:{"variant":"link"},on:{"click":function($event){_vm.target = 'depth-4';
              _vm.modalBody.scrollTo(0, 0);}}},[_vm._v("[이전 개인정보처리방침 보기 (2017. 08. 25~2017. 12. 03버전)]")])],1):_vm._e()]:_vm._e()],2)]),_c('modal-hospitals',{attrs:{"modalId":'modal-hospitals-policy',"hideMap":true,"privacy":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }