<template>
  <b-modal
    :id="id"
    hide-header
    hide-footer
    centered
    modal-class="modal-alert"
    body-class="py-3 px-4 px-md-5"
  >
    <template #default="{ hide }">
      <template v-if="isAlert">
        <h5 class="text-center my-5 fs-20" v-html="message"></h5>
        <div
          class="w-100 overflow-hidden"
          :style="{
            borderRadius: '1rem',
          }"
        >
          <b-btn
            variant="gray"
            class="p-4 text-center w-50 rounded-0"
            @click="hide"
            >아니요</b-btn
          >
          <b-btn
            variant="blue-submit"
            class="p-4 text-center w-50 rounded-0"
            @click="confirm"
            >예</b-btn
          >
        </div>
      </template>
      <template v-else>
        <h5 class="text-center my-5 fs-20" v-html="message"></h5>
        <div class="flex-center">
          <b-btn
            variant="link blue-submit-btn text-center px-5 fs-18"
            @click="hide"
            >확인</b-btn
          >
        </div>
      </template>
    </template>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      id: "modal-alert",
      message: "",
      isAlert: false,
      cb: () => {},
    };
  },
  methods: {
    confirm() {
      this.$bvModal.hide(this.id); // bootstrap modal hide
      this.cb();
      // this.reset();
    },
    reset() {
      this.message = "";
      this.isAlert = false;
      this.cb = () => {};
    },
  },
  created() {
    // Listen for an event
    this.$root.$on(
      "showModalAlert",
      (message = "알림", isAlert = false, cb = () => {}) => {
        this.message = message;
        this.isAlert = isAlert;
        this.cb = cb;
        this.$bvModal.show("modal-alert");
      }
    );
  },
};
</script>
