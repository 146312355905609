<template>
  <div class="position-relative">
    <table-swiper
      ref="table-swiper"
      :items="Array.of(1)"
      :options="{
        slidesPerView: 1,
        allowTouchMove: false,
        breakpoints: {
          400: {
            allowTouchMove: true,
            slidesPerView: fields.length >= 5 ? 0.5 : 0.8,
          },
          720: {
            allowTouchMove: fields.length <= 3 ? false : true,
            slidesPerView:
              fields.length >= 5 ? 0.6 : fields.length <= 3 ? 1 : 0.8,
          },
          992: {
            allowTouchMove: fields.length >= 5 ? true : false,
            slidesPerView: fields.length >= 5 ? 0.8 : 1,
          },
        },
        navigation: {
          nextEl: '.table-next',
          prevEl: '.table-prev',
        },
      }"
      isPopover
      @touchStart="isPopover = false"
      @touchEnd="isPopover = true"
    >
      <template v-slot:content>
        <b-table
          :fields="mapedFields"
          :items="mapedItems"
          table-class="text-center"
          thead-class="border-top border-blue border-3 bg-green-ligth"
          tbody-class="border-bottom border-blue-light"
          class="border-0"
          show-empty
          emptyText="이력이 존재하지 않습니다."
        >
          <!-- 자세히 보기 버튼 -->
          <template #cell(detail)="row">
            <b-btn
              variant="link blue-submit-btn px-3 py-2 fs-20 fs-md-18 fw-4"
              @click="handleModal(row)"
            >
              자세히 보기
            </b-btn>
          </template>
          <!-- // 자세히 보기 버튼 -->

          <template #cell(ASK)="row"> {{ calcLength(row.item.ASK) }} </template>
          <!-- 진행여부 -->
          <template #cell(servicestatus)="row">
            <span
              variant="link"
              class="blue-submit-btn rounded-pill px-3 py-2 fs-20 fs-md-18 fw-4"
            >
              {{ row.value }}
            </span>
          </template>
          <!-- // 진행여부 -->

          <!-- 중단하기 -->
          <template #cell(stop)="row">
            <b-btn
              v-if="row.item.servicestatus === '진행중'"
              variant="link blue-submit-btn px-3 py-2 fs-20 fs-md-18 fw-4"
              @click="handleStop(row.item)"
            >
              중단하기
            </b-btn>
          </template>
          <!-- // 중단하기 -->
          <template #cell()="row">
            <span>{{ row.value || "-" }}</span>
          </template>
          <!-- items 없을 때 -->
          <template #empty="scope">
            <div class="flex-center flex-column  py-5">
              <span class="mb-4">
                <img
                  src="~@/assets/img/common/ic-empty-img.svg"
                  alt="empty-img"
                  :style="{
                    width: calcHeight('4.5rem'),
                  }"
              /></span>
              <p class="fs-19">{{ scope.emptyText }}</p>
            </div>
          </template>
          <!-- // items 없을 때 -->
        </b-table>
      </template>
    </table-swiper>
    <modal-my-page :body="body" :date="targetDate"></modal-my-page>
  </div>
</template>

<script>
import ModalMyPage from "@/components/kyobohealthcare/modal/ModalMyPage";
import { formatedDate } from "@/utils/dayjs";
import { showToast } from "@/plugins/custom-toast";

export default {
  components: {
    ModalMyPage,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      body: null,
      targetDate: null,
      isPopover: true,
    };
  },
  computed: {
    maxWidth() {
      if (this.$route.query.tab === "ngnursing" || this.fields.length < 5)
        return { minWidth: "500px" };
      if (this.fields.length >= 5) return { minWidth: "800px" };
    },
    mapedFields() {
      return this.fields.map((el) => ({
        ...el,
        thClass: "border-0 py-3 align-middle",
        tdClass: "py-3 align-middle",
      }));
    },
    mapedItems() {
      return this.items?.map((item) => ({
        ...item,
        ...(item.regdate && { regdate: formatedDate(item.regdate) }),
        ...(item.resrv_day && { resrv_day: formatedDate(item.resrv_day) }),
        ...(item.startdate && { startdate: formatedDate(item.startdate) }),
        ...(item.date && { date: formatedDate(item.date) }),
        ...(item.REGDATE && { REGDATE: formatedDate(item.REGDATE) }),
      }));
    },
  },
  methods: {
    handleModal({ item }) {
      this.body = this.setBody(item);
      this.targetDate = item.usedate || item.regdate;
      this.$bvModal.show("modal-my-page");
    },

    async handleStop({ joinserial, sseq, c_code }) {
      const { kyoboApi } = this.getApis();
      const body = {
        joinserial,
        sseq,
        c_code,
      };

      const {
        data: { message },
      } = await kyoboApi.myPage.application.delete(body);
      showToast({ title: message });
      this.$emit("refresh");
    },
    setBody(item) {
      return {
        ...(item.seq && { seq: item.seq }),
        ...(item.hra_sn && { hra_sn: item.hra_sn }),
        ...(item.m_cdoe && { m_code: item.m_cdoe }),
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
