<template>
  <b-container class="hc-container">
    <template v-if="items">
      <span class="fs-18  px-3 py-1 theme-btn bg-theme-color-1 mb-4"
        >아이 발달 체크</span
      >
      <div class="mb-4 fs-24 fs-sm-30 fw-7">
        <hash-title
          :titleClass="'fs-24 fs-sm-30 fw-7'"
          :title="`${$route.query.month}개월 된 우리 아이 `"
          :isHash="false"
        ></hash-title>
        <span> 발달 상태 결과</span>
      </div>
      <!-- 검사 결과 -->
      <div>
        <div
          v-for="row in items.result"
          :key="row.gubun"
          class="flex-start border-bottom py-3"
        >
          <span
            :style="{
              minWidth: calcHeight('7.2rem'),
            }"
            class="theme-btn py-2 px-3 fs-16 mr-3 "
            >{{ row.gubun }}</span
          >
          <div class="fs-18 fs-sm-20">{{ row.text }}</div>
        </div>
      </div>
      <!-- // 검사 결과 -->
      <p class="py-5">
        {{ items.endTitle }}
      </p>

      <div class="flex-center">
        <router-link
          class="blue-submit-btn fs-20"
          :to="{ name: 'check-growth', key: 'growth' }"
          >완료</router-link
        >
      </div>
    </template>
    <skeleton-check-result v-else></skeleton-check-result>
  </b-container>
</template>

<script>
import SkeletonCheckResult from "@/components/common/SkeletonCheckResult";

export default {
  components: {
    SkeletonCheckResult,
  },
  data() {
    return {
      items: null,
    };
  },
  computed: {
    body() {
      return this.$rotue.query;
    },
  },
  methods: {
    async getResult() {
      const { kyoboApi } = this.getApis();
      const query = this.$route.query;
      const { data } = await kyoboApi.check.growth.result(query);
      this.items = data;
    },
  },
  mounted() {
    this.getResult();
  },
};
</script>
