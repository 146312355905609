const type = "kyoboservices";

export default {
  color: ["#4ca223", "#3667cd"],
  call: "1588-7524",
  // logo
  logo: "교보생명 상품부가서비스",
  // gnb
  gnb: [
    {
      id: "service",
      text: "교보New헬스케어서비스",
      name: "service",
      key: "newHealth",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      id: "service",
      text: "교보헬스케어서비스",
      name: "service",
      key: "health",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      id: "service",
      text: "교보우먼헬스케어서비스",
      name: "service",
      key: "woman",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      id: "service",
      text: "교보건강코칭서비스",
      name: "service",
      key: "coach",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      id: "service",
      text: "교보헬스케어서비스 Basic",
      name: "service",
      key: "basic",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      id: "service",
      text: "교보어린이헬스케어서비스",
      name: "service",
      key: "children",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      id: "service",
      text: "교보주니어헬스케어서비스",
      name: "service",
      key: "junior",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      text: "교보에듀케어서비스",
      id: "service",
      name: "service",
      key: "education",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      id: "service",
      text: "교보치매케어서비스",
      name: "service",
      key: "dementia",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      text: "교보실버케어서비스플러스",
      id: "service",
      name: "service",
      key: "silver",
      type,
      isMenu: true,
      isheaderTab: true,
    },
    {
      text: "교보암케어서비스",
      id: "service",
      name: "service",
      key: "cancer",
      type,
      isMenu: true,
      isheaderTab: true,
    },
  ],
  headerTabs: {
    service: [
      {
        text: "교보New헬스케어서비스",
        name: "service",
        key: "newHealth",
        isMenu: true,
        type,
      },
      {
        text: "교보헬스케어서비스",
        name: "service",
        key: "health",
        isMenu: true,
        type,
      },
      {
        text: "교보우먼헬스케어서비스",
        name: "service",
        key: "woman",
        isMenu: true,
        type,
      },
      {
        text: "교보건강코칭서비스",
        name: "service",
        key: "coach",
        isMenu: true,
        type,
      },
      {
        text: "교보헬스케어서비스 Basic",
        name: "service",
        key: "basic",
        isMenu: true,
        type,
      },
      {
        text: "교보어린이헬스케어서비스",
        name: "service",
        key: "children",
        isMenu: true,
        type,
      },
      {
        text: "교보주니어헬스케어서비스",
        name: "service",
        key: "junior",
        isMenu: true,
        type,
      },
      {
        text: "교보에듀케어서비스",
        name: "service",
        key: "education",
        isMenu: true,
        type,
      },
      {
        text: "교보치매케어서비스",
        name: "service",
        key: "dementia",
        isMenu: true,
        type,
      },
      {
        text: "교보실버케어서비스플러스",
        name: "service",
        key: "silver",
        isMenu: true,
        type,
      },
      {
        text: "교보암케어서비스",
        name: "service",
        key: "cancer",
        isMenu: true,
        type,
      },
    ],
  },
  // sub tab
  subTabs: [
    {
      key: "newHealth",
      scroll: true,
      items: [
        "이용방법",
        "평상시 건강관리",
        "가족 건강관리",
        "일반 치료지원",
        "중대질환 치료지원",
        "New헬스케어서비스 프리미어",
        // "New헬스케어서비스 플러스",
        "New헬스케어서비스 프레스티지"
      ],
    },
    {
      key: "health",
      scroll: true,
      items: [
        "이용방법",
        "평상시 건강관리",
        "가족 건강관리",
        "일반 치료지원",
        "중대질환 치료지원",
        "교보프리미어헬스케어서비스",
        "헬스케어서비스 플러스",
        "프레스티지헬스케어서비스"
      ],
    },
    {
      key: "woman",
      scroll: true,
      items: [
        "이용방법",
        "평상시 건강관리",
        "가족 건강관리",
        "일반 치료지원",
        "중대질환 치료지원",
        "교보플래티넘 우먼헬스케어서비스",
        "우먼헬스케어서비스 플러스",
      ],
    },
    {
      key: "children",
      scroll: true,
      items: [
        "이용방법",
        "어린이 건강도서관",
        "상담 치료지원",
        "방문 치료지원",
        "임신 · 출산 · 육아 집중 관리",
        "가족 건강지원",
      ],
    },
    {
      scroll: true,
      key: "junior",
      items: ["이용방법", "스텝케어", "헬스케어", "글로벌케어", "알지케어"],
    },
    {
      key: "coach",
      scroll: true,
      items: [
        "이용방법",
        "매일매일 챌린지",
        "푸드렌즈",
        "5! 마이코칭",
        "체크체크 마이헬스",
        "건강도서관",
        "건강코치 1:1 상담",
      ],
    },
    {
      key: "basic",
      scroll: true,
      items: [
        "이용방법",
        "질병 예방 건강관리 프로그램",
        "평상시 건강관리",
        "일반 치료지원",
        "3대 질병 치료지원",
      ],
    },
    {
      key: "education",
      scroll: true,
      items: [
        "이용방법",
        "양육마스터",
        "디지털도서관",
        "글로벌멘토링",
        "현장학습",
      ],
    },
    {
      key: "dementia",
      scroll: true,
      items: [
        "이용방법",
        "평상시 치매예방",
        "경도 치매케어 CDR1",
        "중등도 치매케어 CDR2",
        "중증 치매케어 CDR3",
      ],
    },
    {
      key: "silver",
      scroll: true,
      items: [
        "이용방법",
        "연금 개시 전 암치료 지원",
        "연금 개시 후 노화방지 프로그램",
        "연금개 개시 후 간병 상담",
        "연금 개시 후 케어매니저 방문교육",
      ],
    },
    {
      key: "cancer",
      scroll: true,
      items: ["이용방법", "기본제공형", "서비스 선택형"],
    },
  ],
  // home
  service: [
    {
      title: "교보New헬스케어서비스",
      text:
        "평상시 일상 속 건강케어부터 큰 병에 걸렸을 때 주요질병 돌봄케어까지 지속적인 건강관리 프로그램을 제공하는 통합건강관리서비스입니다.",
      name: "service",
      params: { key: "newHealth", type: "newHealthCare" },
      bgColor: "#e6e6e6",
    },
    {
      title: "교보헬스케어서비스",
      text:
        "보장으로 한번, 헬스케어서비스로 또 한번! 차별화된 서비스로 두배의 만족을 드리며 고객님의 건강하고 행복한 삶을 지원하겠습니다.",
      name: "service",
      params: { key: "health", type: "healthCare" },
      bgColor: "#c9e6bd",
    },
    {
      title: "교보우먼헬스케어서비스",
      text:
        "앞으로의 여행(女幸)을 위해 잊지말고 챙기세요 女여자의 幸행복이 시작됩니다.",
      name: "service",
      params: { key: "woman", type: "healthWoman" },
      bgColor: "#f6d7e5",
    },
    {
      title: "교보건강코칭서비스",
      text:
        "모바일 App을 활용한 스마트건강관리로 생활습관을 개선하고, 건강증진까지 도와드리는 디지털 건강증진 서비스입니다.",
      name: "service",
      params: { key: "coach", type: "healthCoach" },
      bgColor: "#d5e4f8",
    },
    {
      title: "교보헬스케어서비스 Basic",
      text:
        "평상시 질병예방부터 발병시 질병관리까지 꼭 필요한 건강관리 프로그램을 제공하는 기본건강관리서비스입니다.",
      name: "service",
      params: { key: "basic", type: "healthBasic" },
      bgColor: "#ffddbf",
    },
    {
      title: "교보어린이헬스케어서비스",
      text:
        "개별 상담과 맞춤형 정보제공으로 우리아이의 건강과 육아를 도와주는 영유아(0~5세) 건강관리서비스입니다.",
      name: "service",
      params: { key: "children", type: "healthChildren" },
      bgColor: "#fff1b4",
    },
    {
      title: "교보주니어헬스케어서비스",
      text:
        "성장 단계별 건강관리와 해외(여행, 유학 등) 의료지원까지 헬스케어 전문가가 도와드리는 영유아 ~ 청소년 (0~18세) 건강관리서비스 입니다.",
      name: "service",
      params: { key: "junior", type: "healthJunior" },
      bgColor: "#cde2f0",
    },
    {
      title: "교보에듀케어서비스",
      text:
        "자녀의 꿈과 재능을 찾아주고 올바른 인성과 바람직한 리더쉽 & 팔로워십을 키워주는 교육지원 서비스입니다.",
      name: "service",
      params: { key: "education", type: "healthEducation" },
      bgColor: "#ddf1c2",
    },
    {
      title: "교보치매케어서비스",
      text:
        "치매예방에서부터 치매케어까지 치매환자와 가족을 지원하는 치매관리 서비스입니다.",
      name: "service",
      params: { key: "dementia", type: "healthDementia" },
      bgColor: "#cdceed",
    },
    {
      title: "교보실버케어서비스플러스",
      text:
        "노후건강컨설팅, 암치료 지원, 장기간병상담 등 든든한 노후를 준비할 수 있도록 지원하는 시니어 건강관리 서비스입니다.",
      name: "service",
      params: { key: "silver", type: "healthSilver" },
      bgColor: "#d6d7d9",
    },
    {
      title: "교보암케어서비스",
      text:
        "진단부터 치료하는 전 과정에서 최적의 진료를 받을 수 있도록 도와드리는 암케어 서비스입니다.",
      name: "service",
      params: { key: "cancer", type: "healthCancer" },
      bgColor: "#c7f1f1",
    },
  ],
  // 상품부가서비스들
  newHealth: [ //교보New헬스케어서비스
    {
      title: "이용방법",
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      button: {
        text: "꼭 읽어보세요!",
        modalId: "modal-kb-products",
        index: "info",
      },
      contents: [
        {
          title:
            "가족 생활보장상품(종신/CI보험)에 가입한 고객에게 서비스를 제공해 드립니다.",
          texts: [
            "교보New헬스케어서비스는 상품별로 정한 일정 금액이상을 가입한 고객에게 제공되며 가입 금액에 따라",
            "헬스케어서비스와 New헬스케어서비스 프리미어, New헬스케어서비스 프레스티지로 나뉘어 서비스가 제공됩니다.",
            "해당서비스 신청서(개인[신용]정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤",
            "서비스 이용이 가능하십니다.",
            "가입 기준 상품 및 금액은 당사 컨설턴트 또는 콜센터(1588-7524)에 문의 주시기 바랍니다."
          ],
        },
      ],
    },
    {
      title: "평상시 건강관리",
      subTitle:
        "건강은 건강할 때 관리해야 한다고 합니다. 건강을 잃지 않도록 도와드립니다.",
      contents: [
        {
          title: "건강상담",
          texts: [
            "헬스콜센터(1588-7524)를 통해 간호사 상담 접수 후 내과, 외과, 산부인과",
            "치과 등14개과 진료과목 전문의 전화상담 제공",
          ],
        },
        {
          title: "건강정보 제공",
          texts: [
            "주요질환 및 특정질환(손목터널증후군/하지정맥류/성대결절)에 대한 건강정보, 건강칼럼 제공)",
          ],
        },
        {
          title: "주요 질환 예방 프로그램",
          texts: [
            "주요 질환 생활습관 개선 콘텐츠 제공(4주)",
            "※ 주요 질환 범위 : 대사증후군, 통증수반질환, 심뇌혈관 질환 중 선택",
            "※ 심뇌혈관 질환 신청 시 심화 예방 프로그램 추가 제공(4주)",
          ],
        },
        {
          title: "간호사복약상담",
          texts: [
            "약학정보원 등 디지털 라이브러리 기반의 간호사 1:1 복약 지도 및 상담 제공",
            "복약 정보, 복약 시 주의사항 제공",
          ],
        },
        {
          title: "고혈압관리/비만·영양 관리 프로그램",
          texts: [
            "고혈압 요인 및 합병증 예방 가이드, 운동·영양 가이드 제공",
            "만성질환 예방 관리를 위한 비만 치료/영양 가이드 등 제공",
          ],
        },
        {
          title: "안과/치과 주요질환정보 프로그램",
          texts: [
            "주요 안과/치과 질환 안내, 노인성 안과/치과 질환 예방 및 관리를 위한 가이드 제공",
          ],
        },
      ],
    },
    {
      title: "가족 건강관리",
      subTitle:
        "가족이 건강해야 고객님이 행복합니다. 가정의 건강설계를 지원합니다.",
      contents: [
        {
          title: "미숙아 방문간호 컨설팅",
          texts: [
            "미숙아 출생 시 신생아 전문간호사 방문상담 및 교육 제공(퇴원 후 한달)",
            "※ 미숙아 : 임신주수 37주 미만 & 출생체중 2.5kg 이하",
          ],
        },
        {
          title: "건강검진 안내(우대)",
          texts: [
            "가족 건강상담 및 제휴협력을 통한 건강검진 우대(고객 실비부담)",
            "맞춤형 건강검진 설계 및 검진항목 안내",
          ],
        },
        {
          title: "암 정보 제공",
          texts: [
            "암 진단 시 가족 건강 및 지원을 위해 특화된 암 정보 및 식이, 운동법 등 관련 정보 제공",
          ],
        },
      ],
    },
    {
      title: "일반 치료지원",
      subTitle:
        "중대질환 이전 단계 질환은 관리가 중요합니다. 고객님의 빠른 회복을 도와드립니다.",
      contents: [
        {
          title: "병원·의료진 안내 및 예약대행",
          texts: ["질병 진료 및 치료를 위한 병원·의료진 안내 및 진료 예약대행"],
        },
        {
          title: "1 : 1 코칭콜",
          texts: [
            "- 전문간호사 1 : 1 질병 코칭콜 제공",
            "- 질환관리, 운동, 영양, 복약알림 등 맞춤교육 제공",
            "※ 1:1 코칭콜 해당범위 : CI추가보장관련특약 [암/뇌/심장 및 장기] 및 합병증보장특약의 해당질환",
          ],
        },
        {
          title: "간병인 매칭",
          texts: [
            "간병인 필요시 제휴업체를 통한 간병인 안내",
            "(간병인 이용료는 고객 부담)",
          ],
        },
        {
          title: "토닥토닥 마음케어",
          texts: [
            '<span class="fs-18 fw-7">STEP1. 평상 시</span>',
            "- 심리전문칼럼",
            "- 심리자가진단",
            "- 힐링테라피",
            "<br/>",
            '<span class="fs-18 fw-7">STEP2. 필요 시</span>',
            "- 토닥토닥 심리프로그램(4주)",
            "- 전화 심리상담 제공",
            "- 제휴 심리상담센터(대면/화상) 안내 및 우대(고객부담)",
          ],
        },
        {
          title: "당뇨 집중관리 14주 프로그램- 간호사 1:1 당뇨관리 상담 제공",
          texts: [
            "- 간호사 1:1 당뇨관리 상담 제공",
            "- 생활습관 체크, 당뇨 위험요인 스크리닝",
            "- 혈당관리, 합병증 예방 관련 콘텐츠 제공",
          ],
        },
        {
          title: "인공관절수술 자가 재활 프로그램",
          texts: [
            "인공관절치환수술 후 자가 재활관리 및 운동처방 맞춤 프로그램 제공",
          ],
        },
        {
          title: "자가재활관리(4주)",
          texts: [
            "-자가 재활관리 가이드",
            "-수술 후 유의사항 및 관리수칙 안내",
            "-수술 부위별 재활 운동 영상 제공",
          ],
        },
        {
          title: "운동처방 맞춤 컨설팅(4주)",
          texts: [
            "운동처방사 심층 상담 & 맞춤 컨설팅",
            "신화 운동처방 미션 수행 및 확인 관리",
          ],
        },
      ],
    },
    {
      title: "중대질환 치료지원",
      subTitle:
        "진단부터 치료하는 전 과정에서 최적의 진료를 받을 수 있도록 지원합니다.",
      contents: [
        {
          title: "간호사 병원동반 / 질병관리교육",
          texts: [
            "- 중대질환 치료(수술, 입 · 퇴원 등) 시 전문간호사 병원동반",
            "- 중대질환 전문간호사 방문교육 서비스",
            "- 중대질환별 건강상담, 건강정보, 심리상담 제공",
            "※ 중대질환 범위 : 암, 심장 . 뇌혈관 질환, 고혈압성 질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌, 척수수막염), 루게릭병 (근위축성측삭경화증)",
          ],
        },
        {
          title: "암 재발 예방",
          texts: [
            "- 암 치료 후 지속적 관리 제공",
            "- 전담간호사 정기적 안부 / 상담 콜",
            "- 병원 방문 시 간호사 동반 방문서비스 제공(필요시)",
          ],
        },
      ],
    },
    {
      title: "New헬스케어서비스 프리미어",
      button: {
        text: "꼭 읽어보세요!",
        modalId: "modal-kb-products",
        index: "platinum",
      },

      contents: [
        {
          title: "차량 에스코트",
          texts: [
            "전문간호사의 편안한 에스코트 및 병원간 이송지원으로 고객님과 가족의 걱정을 해소해 드립니다.",
            "- 입·퇴원 병원 동반 : 중대질환 수술을 위한 입·퇴원 시 전문 간호사 및 차량 지원",
            "※ 입·퇴원 1일 전 신청 필요(서울권 이동 한정)",
            "- 병원 간 이송 : 중대질환 입원치료를 위한 타 의료기관 이송 시 차량 지원",
            "※ 응급 상황, 도서 지역 서비스 이용 불가 / 이송 2일 전 신청(1인 연1회한) ",
          ],
          boxTexts: [
            "중대질환 범위 : 암, 심장 . 뇌혈관 질환, 고혈압성 질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌, 척수수막염), 루게릭병 (근위축성측삭경화증)",
          ],
        },
        {
          title:
            "방문 심리상담",
          texts: [
            "심리상담전문가 방문을 통해 중대질환의 심리적 회복까지 도와드립니다.",
            "- 부정적 감정 감소 및 정서 안정을 위한 심리상담",
            "- 고객 치료 병원 또는 제휴 심리상담센터에서 진행 ",
            "※ 병원 / 심리상담센터 방문 1일 전 신청",
            "※ 중대질환 발생 고객 본인 / 고객 직계 가족 1인 중 선택 (연 1회)",
          ],
        },
        {
          title: "해외 의료지원",
          texts: [
            "해외 의료지원해외에서 상해나 질병으로 위급한 상황 발생 시 신속하면서 편안하게 대처할 수 있도록 도와드립니다. ",
            "- 국가별 예방접종 및 유의사항 상담",
            "- 현지병원 안내 및 예약",
            "- 365일 24시간 Hot Line 운영 및 의료통역지원",
            "- 간호사 파견 및 긴급이송 지원(고객 실비부담)",
          ],
        },
      ],
    },
    // {
    //   title: "New헬스케어서비스 플러스",
    //   subTitle:
    //     "기존 교보New헬스케어서비스 중대질환 치료지원 서비스 중 중대질환 수술을 위한 입퇴원 시 중대질환별* 횟수 한도 내 선택 서비스(간호사 병원동행 및 방문교육 또는 홈클리닝 서비스) 제공",
    //   contents: [
    //     {
    //       texts: [
    //         "※ 홈클리닝 서비스(최대 3회) 또는 간호사 병원동행 및 방문교육 중 택 1",
    //         "<br/>",
    //         "※ 해당질환 :  암/심장질환/뇌혈관질환/고혈압성질환/당뇨병으로 인한 합병증 ,원발성 폐동맥 고혈압, 다발경화증, 중증 세균성 수막염(뇌수막염, 척수수막염), 루게릭병(근위축성측삭경화증)",
    //       ],
    //     },
    //     {
    //       title: "간호사 병원동행 및 방문교육",
    //       texts: [
    //         `서비스 이용절차 : 01. 교보생명 헬스콜센터(1588-7524) <i class="icon-right"></i> 02. 상담 <i class="icon-right"></i> 03. 입퇴원 시 동행 및 교육 진행 <i class="icon-right"></i> 04. 퇴원 후 건강 정보 제공`,
    //         "<br/>",
    //         "- 진료 동행 및 입퇴원 수속 에스코트",
    //         "- 질병치료법 및 예후 설명",
    //         "- 진료 시 의료진과의 원활한 의사소통 도움",
    //         "- 치료 시(항암 및 방사선 등) 교육",
    //         "- 복용 약물 교육",
    //         "- 식이요법 관리 ",
    //         "- 질병 교육자료 무료 제공",
    //         "- 정서적 안정 지원 ",
    //       ],
    //     },
    //     {
    //       title: "헬스홈케어서비스",
    //       texts: [
    //         `서비스 이용절차 : 01. 교보생명 헬스콜센터(1588-7524) <i class="icon-right"></i> 02. 상담 <i class="icon-right"></i> 03. 프로터치 해피콜 <i class="icon-right"></i> 04. 청소 진행 및 고객 검수`,
    //         "<br/>",
    //         "- 청소, 공간 방역, 매트리스 살균 등 주거환경 클리닝(선택 시 최대 3회 한도로 이용 가능)",
    //       ],
    //     },
    //     {
    //       title: "식습관 데이터 기반 영양관리서비스",
    //       texts: [
    //         "※ 오픈이노베이션 「UT인프라」 제휴",
    //         "- 질환별 식습관 분석",
    //         "- 건강관리를 위한 식습관 컨설팅",
    //         " &nbsp;&nbsp;&nbsp;⇒ 제휴 APP을 통해 제공"
    //       ]
    //     },
    //     {
    //       title: "케어식단 제공 및 영양 컨설팅",
    //       texts: [
    //         "- 중대질환으로 입원 후 퇴원 시 건강회복을 위한 건강식 자택 배달서비스 제공",
    //         "- 케어식단 영양 컨설팅"
    //       ]
    //     }
    //   ],
    // },
    {
      title: "New헬스케어서비스 프레스티지",
      subTitle: " ",
      contents: [
        {
          texts: [
            "※ 가족 올케어 건강관리의 가족 범위 : 주피보험자(회원)의 부, 모, 배우자, 자녀",
            "※ 중대질환 범위 : 암, 심장 · 뇌혈관질환, 고혈압성 질환, 당뇨합병증, 원발성 폐동맥 고혈압, 다발경화증, 중증세균성수막염(뇌 · 척수수막염), 루게릭병(근위축성측삭경화증)",
          ],
        },
        {
          title: "차량 에스코트",
          texts: [
            "기존 프리미어고객 대상 중대질환 진단 받았을 경우, 대형병원 입퇴원 시 제공되던 차량 에스코트를 가족의 건강검진 우대예약 시 병원 왕복 이동을 차량 에스코트 해드리는 서비스로 확대 제공해 드립니다.",
            "<br/>",
            "- 본인 및 가족(부모, 배우자, 자녀에 한함)의 건강검진 우대예약 시 검진일 왕복 차량 에스코트 무상 지원",
            "- 주피보험자 1인당 서비스기간 내 최초 1회 (탑승자 수는 최대 3명)",
            "- 지방의 경우, 검진병원으로부터 50km 반경의 이동을 지원",
          ],
        },
        {
          title: "영양 컨설팅",
          texts: [
            "중대질환 치료 후 면역강화가 필요한 고객을 위해 영양 컨설팅과 맞춤 건기식을 제공해 드립니다.",
            "<br/>",
            `<span class="fs-18 fw-7">영양 컨설팅</span>`,
            "성별, 나이, 건강상태(질환 유무, 과거력)를 고려한 면역 강화 목적에 따라 필요한 영양소가 무엇이고, 그 영양소를 많이 함유하고 있는 음식과 건강기능식품 추천",
            "<br/>",
            `<span class="fs-18 fw-7">건기식 제공</span>`,
            "영양 컨설팅에서 추천하는 건강기능식품 배송 (단, 주치의의 건강기능식품 섭취 가능하다는 소견이 있을 시 제공 가능)",
          ],
        },
        {
          title: "중대질환 치료지원",
          texts: [
            "기존 프리미어고객 대상 중대질환 진단 받았을 경우, 대형병원 입퇴원 시 제공되던 차량 에스코트를 가족의 건강검진 우대예약 시 병원 왕복 이동을 차량 에스코트 해드리는 서비스로 확대 제공해 드립니다.",
            "<br/>",
            "- 가족의 중대질환 진단 시에도 간호사 방문상담 신청 이용 가능",
            "- 본인 외 가족(부모,배우자,자녀에 한함)의 이용 시 주피보험자 1인당 서비스기간 내 최대 5회",
            "- 전문 간호사가 가족의 중대질환 진료에도 대형병원에 동행하여 질병관리 교육과 관련 서비스를 제공",
            "&nbsp;&nbsp;(단, 의료진과의 직접 진료 시 동행하며, 단독 검사와 항암 치료는 해당하지 않음)"
          ],
        },
      ],
    },
  ],
  health: [
    {
      title: "이용방법",
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      button: {
        text: "꼭 읽어보세요!",
        modalId: "modal-kb-products",
        index: "info",
      },
      contents: [
        {
          title:
            "가족 생활보장상품(종신/GI보험)에 가입한 고객에게 서비스를 제공해 드립니다.",
          texts: [
            "교보헬스케어서비스는 상품별로 정한 일정 금액이상을 가입한 고객에게 제공되며 가입 금액에",
            "따라 헬스케어서비스와 프리미어헬스케어서비스로 나뉘어 서비스가 제공됩니다.",
            "해당서비스 신청서(개인[신용]정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하십니다. ",
            "가입 기준 상품 및 금액은 당사 컨설턴트 또는 콜센터(1588-7524)에 문의 주시기 바랍니다.",
          ],
        },
      ],
    },
    {
      title: "평상시 건강관리",
      subTitle:
        "건강은 건강할 때 관리해야 한다고 합니다. 건강을 잃지 않도록 도와드립니다.",
      contents: [
        {
          title: "건강상담",
          texts: [
            "교보헬스케어콜센터(1588-7524)를 통해 간호사 상담 접수 후 내과, 외과, 산부인과",
            "치과 등14개과 진료과목 전문의 전화상담 제공",
          ],
        },
        {
          title: "건강정보 제공",
          texts: [
            "주요질환 및 특정질환(손목터널증후군/하지정맥류/성대결절)에 대한 건강정보, 건강칼럼 제공)",
          ],
        },
        {
          title: "주요 질환 예방 프로그램",
          texts: [
            "주요 질환 생활습관 개선 콘텐츠 제공(4주)",
            "※ 주요 질환 범위 : 대사증후군, 통증수반질환, 심뇌혈관 질환 중 선택",
            "※ 심뇌혈관 질환 신청 시 심화 예방 프로그램 추가 제공(4주)",
          ],
        },
        {
          title: "간호사복약상담",
          texts: [
            "약학정보원 등 디지털 라이브러리 기반의 간호사 1:1 복약 지도 및 상담 제공",
            "복약 정보, 복약 시 주의사항 제공",
          ],
        },
        {
          title: "고혈압관리/비만·영양 관리 프로그램",
          texts: [
            "고혈압 요인 및 합병증 예방 가이드, 운동·영양 가이드 제공",
            "만성질환 예방 관리를 위한 비만 치료/영양 가이드 등 제공",
          ],
        },
        {
          title: "안과/치과 주요질환정보 프로그램",
          texts: [
            "주요 안과/치과 질환 안내, 노인성 안과/치과 질환 예방 및 관리를 위한 가이드 제공",
          ],
        },
      ],
    },
    {
      title: "가족 건강관리",
      subTitle:
        "가족이 건강해야 고객님이 행복합니다. 가정의 건강설계를 지원합니다.",
      contents: [
        {
          title: "미숙아 방문간호 컨설팅",
          texts: [
            "미숙아 출생 시 신생아 전문간호사 방문상담 및 교육 제공(퇴원 후 한달)",
            "※ 미숙아 : 임신주수 37주 미만 & 출생체중 2.5kg 이하",
          ],
        },
        {
          title: "건강검진 안내(우대)",
          texts: [
            "가족 건강상담 및 제휴협력을 통한 건강검진 우대(고객 실비부담)",
            "맞춤형 건강검진 설계 및 검진항목 안내",
          ],
        },
        {
          title: "암 정보 제공",
          texts: [
            "암 진단 시 가족 건강 및 지원을 위해 특화된 암 정보 및 식이, 운동법 등 관련 정보 제공",
          ],
        },
      ],
    },
    {
      title: "일반 치료지원",
      subTitle:
        "중대질환 이전 단계 질환은 관리가 중요합니다. 고객님의 빠른 회복을 도와드립니다.",
      contents: [
        {
          title: "병원·의료진 안내 및 예약대행",
          texts: ["질병 진료 및 치료를 위한 병원·의료진 안내 및 진료 예약대행"],
        },
        {
          title: "1 : 1 코칭콜",
          texts: [
            "- 전문간호사 1 : 1 질병 코칭콜 제공",
            "- 질환관리, 운동, 영양, 복약알림 등 맞춤교육 제공",
            "※ 1:1 코칭콜 해당범위 : CI추가보장관련특약 [암/뇌/심장 및 장기] 및 합병증보장특약의 해당질환",
          ],
        },
        {
          title: "간병인 매칭",
          texts: [
            "간병인 필요시 제휴업체를 통한 간병인 안내",
            "(간병인 이용료는 고객 부담)",
          ],
        },
        {
          title: "토닥토닥 마음케어",
          texts: [
            '<span class="fs-18 fw-7">STEP1. 평상 시</span>',
            "- 심리전문칼럼",
            "- 심리자가진단",
            "- 힐링테라피",
            "<br/>",
            '<span class="fs-18 fw-7">STEP2. 필요 시</span>',
            "- 토닥토닥 심리프로그램(4주)",
            "- 전화 심리상담 제공",
            "- 제휴 심리상담센터(대면/화상) 안내 및 우대(고객부담)",
          ],
        },
        {
          title: "당뇨 집중관리 14주 프로그램- 간호사 1:1 당뇨관리 상담 제공",
          texts: [
            "- 간호사 1:1 당뇨관리 상담 제공",
            "- 생활습관 체크, 당뇨 위험요인 스크리닝",
            "- 혈당관리, 합병증 예방 관련 콘텐츠 제공",
          ],
        },
        {
          title: "인공관절수술 자가 재활 프로그램",
          texts: [
            "인공관절치환수술 후 자가 재활관리 및 운동처방 맞춤 프로그램 제공",
          ],
        },
        {
          title: "자가재활관리(4주)",
          texts: [
            "-자가 재활관리 가이드",
            "-수술 후 유의사항 및 관리수칙 안내",
            "-수술 부위별 재활 운동 영상 제공",
          ],
        },
        {
          title: "운동처방 맞춤 컨설팅(4주)",
          texts: [
            "운동처방사 심층 상담 & 맞춤 컨설팅",
            "신화 운동처방 미션 수행 및 확인 관리",
          ],
        },
      ],
    },
    {
      title: "중대질환 치료지원",
      subTitle:
        "진단부터 치료하는 전 과정에서 최적의 진료를 받을 수 있도록 지원합니다.",
      contents: [
        {
          title: "간호사 병원동반 / 질병관리교육",
          texts: [
            "- 중대질환 치료(수술, 입 · 퇴원 등) 시 전문간호사 병원동반",
            "- 중대질환 전문간호사 방문교육 서비스",
            "- 중대질환별 건강상담, 건강정보, 심리상담 제공",
            "※ 중대질환 범위 : 암, 심장 . 뇌혈관 질환, 고혈압성 질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌, 척수수막염), 루게릭병 (근위축성측삭경화증)",
          ],
        },
        {
          title: "암 재발 예방",
          texts: [
            "- 암 치료 후 지속적 관리 제공",
            "- 전담간호사 정기적 안부 / 상담 콜",
            "- 병원 방문 시 간호사 동반 방문서비스 제공(필요시)",
          ],
        },
      ],
    },
    {
      title: "교보프리미어헬스케어서비스",
      button: {
        text: "꼭 읽어보세요!",
        modalId: "modal-kb-products",
        index: "platinum",
      },

      contents: [
        {
          title: "차량 에스코트",
          texts: [
            "전문간호사의 편안한 에스코트 및 병원간 이송지원으로 고객님과 가족의 걱정을 해소해 드립니다.",
            "- 입·퇴원 병원 동반 : 중대질환 수술을 위한 입·퇴원 시 전문 간호사 및 차량 지원",
            "※ 입·퇴원 1일 전 신청 필요(서울권 이동 한정)",
            "- 병원 간 이송 : 중대질환 입원치료를 위한 타 의료기관 이송 시 차량 지원",
            "※ 응급 상황, 도서 지역 서비스 이용 불가 / 이송 2일 전 신청(1인 연1회한) ",
          ],
          boxTexts: [
            "중대질환 범위 : 암, 심장 . 뇌혈관 질환, 고혈압성 질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌, 척수수막염), 루게릭병 (근위축성측삭경화증)",
          ],
        },
        {
          title:
            "방문 심리상담심리상담전문가 방문을 통해 중대질환의 심리적 회복까지 도와드립니다.",
          texts: [
            "- 부정적 감정 감소 및 정서 안정을 위한 심리상담",
            "고객 치료 병원 또는 제휴 심리상담센터에서 진행 ",
            "※ 병원 / 심리상담센터 방문 1일 전 신청",
            "※ 중대질환 발생 고객 본인 / 고객 직계 가족 1인 중 선택 (연 1회)",
          ],
        },
        {
          title: "해외 의료지원",
          texts: [
            "해외 의료지원해외에서 상해나 질병으로 위급한 상황 발생 시 신속하면서 편안하게 대처할 수 있도록 도와드립니다. ",
            "- 국가별 예방접종 및 유의사항 상담",
            "- 현지병원 안내 및 예약",
            "- 365일 24시간 Hot Line 운영 및 의료통역지원",
            "- 간호사 파견 및 긴급이송 지원(고객 실비부담)",
          ],
        },
      ],
    },
    {
      title: "헬스케어서비스 플러스",
      subTitle:
        "기존 교보헬스케어서비스 중대질환 치료지원 서비스 중 중대질환 수술을 위한 입퇴원 시 중대질환별* 횟수 한도 내 선택 서비스(간호사 병원동행 및 방문교육 또는 홈클리닝 서비스) 제공",
      contents: [
        {
          texts: [
            "※ 홈클리닝 서비스(최대 3회) 또는 간호사 병원동행 및 방문교육 중 택 1",
            "<br/>",
            "※ 해당질환 :  암/심장질환/뇌혈관질환/고혈압성질환/당뇨병으로 인한 합병증 ,원발성 폐동맥 고혈압, 다발경화증, 중증 세균성 수막염(뇌수막염, 척수수막염), 루게릭병(근위축성측삭경화증)",
          ],
        },
        {
          title: "간호사 병원동행 및 방문교육",
          texts: [
            `서비스 이용절차 : 01. 교보생명 헬스콜센터(1588-7524) <i class="icon-right"></i> 02. 상담 <i class="icon-right"></i> 03. 입퇴원 시 동행 및 교육 진행 <i class="icon-right"></i> 04. 퇴원 후 건강 정보 제공`,
            "<br/>",
            "- 진료 동행 및 입퇴원 수속 에스코트",
            "- 질병치료법 및 예후 설명",
            "- 진료 시 의료진과의 원활한 의사소통 도움",
            "- 치료 시(항암 및 방사선 등) 교육",
            "- 복용 약물 교육",
            "- 식이요법 관리 ",
            "- 질병 교육자료 무료 제공",
            "- 정서적 안정 지원 ",
          ],
        },
        {
          title: "헬스홈케어서비스",
          texts: [
            `서비스 이용절차 : 01. 교보생명 헬스콜센터(1588-7524) <i class="icon-right"></i> 02. 상담 <i class="icon-right"></i> 03. 프로터치 해피콜 <i class="icon-right"></i> 04. 청소 진행 및 고객 검수`,
            "<br/>",
            "- 청소, 공간 방역, 매트리스 살균 등 주거환경 클리닝(선택 시 최대 3회 한도로 이용 가능)",
          ],
        },
        {
          title: "식습관 데이터 기반 영양관리서비스",
          texts: [
            "※ 오픈이노베이션 「UT인프라」 제휴",
            "- 질환별 식습관 분석",
            "- 건강관리를 위한 식습관 컨설팅",
            " &nbsp;&nbsp;&nbsp;⇒ 제휴 APP을 통해 제공"
          ]
        },
        {
          title: "케어식단 제공 및 영양 컨설팅",
          texts: [
            "- 중대질환으로 입원 후 퇴원 시 건강회복을 위한 건강식 자택 배달서비스 제공",
            "- 케어식단 영양 컨설팅"
          ]
        }
      ],
    },
    {
      title: "프레스티지헬스케어서비스",
      subTitle: " ",
      contents: [
        {
          texts: [
            "※ 가족 올케어 건강관리의 가족 범위 : 주피보험자(회원)의 부, 모, 배우자, 자녀",
            "※ 중대질환 범위 : 암, 심장 · 뇌혈관질환, 고혈압성 질환, 당뇨합병증, 원발성 폐동맥 고혈압, 다발경화증, 중증세균성수막염(뇌 · 척수수막염), 루게릭병(근위축성측삭경화증)",
          ],
        },
        {
          title: "차량 에스코트",
          texts: [
            "기존 프리미어고객 대상 중대질환 진단 받았을 경우, 대형병원 입퇴원 시 제공되던 차량 에스코트를 가족의 건강검진 우대예약 시 병원 왕복 이동을 차량 에스코트 해드리는 서비스로 확대 제공해 드립니다.",
            "<br/>",
            "- 본인 및 가족(부모, 배우자, 자녀에 한함)의 건강검진 우대예약 시 검진일 왕복 차량 에스코트 무상 지원",
            "- 주피보험자 1인당 서비스기간 내 최초 1회 (탑승자 수는 최대 3명)",
            "- 지방의 경우, 검진병원으로부터 50km 반경의 이동을 지원",
          ],
        },
        {
          title: "영양 컨설팅",
          texts: [
            "중대질환 치료 후 면역강화가 필요한 고객을 위해 영양 컨설팅과 맞춤 건기식을 제공해 드립니다.",
            "<br/>",
            `<span class="fs-18 fw-7">영양 컨설팅</span>`,
            "성별, 나이, 건강상태(질환 유무, 과거력)를 고려한 면역 강화 목적에 따라 필요한 영양소가 무엇이고, 그 영양소를 많이 함유하고 있는 음식과 건강기능식품 추천",
            "<br/>",
            `<span class="fs-18 fw-7">건기식 제공</span>`,
            "영양 컨설팅에서 추천하는 건강기능식품 배송 (단, 주치의의 건강기능식품 섭취 가능하다는 소견이 있을 시 제공 가능)",
          ],
        },
        {
          title: "중대질환 치료지원",
          texts: [
            "기존 프리미어고객 대상 중대질환 진단 받았을 경우, 대형병원 입퇴원 시 제공되던 차량 에스코트를 가족의 건강검진 우대예약 시 병원 왕복 이동을 차량 에스코트 해드리는 서비스로 확대 제공해 드립니다.",
            "<br/>",
            "- 가족의 중대질환 진단 시에도 간호사 방문상담 신청 이용 가능",
            "- 본인 외 가족(부모,배우자,자녀에 한함)의 이용 시 주피보험자 1인당 서비스기간 내 최대 5회",
            "- 전문 간호사가 가족의 중대질환 진료에도 대형병원에 동행하여 질병관리 교육과 관련 서비스를 제공",
            "&nbsp;&nbsp;(단, 의료진과의 직접 진료 시 동행하며, 단독 검사와 항암 치료는 해당하지 않음)"
          ],
        },
      ],
    },
  ],
  woman: [
    {
      title: "이용방법",
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      button: {
        text: "꼭 읽어보세요!",
        modalId: "modal-kb-products",
        index: "info",
      },
      contents: [
        {
          title:
            "가족 생활보장상품(여성GI보험)에 가입한 고객에게 서비스를 제공해 드립니다.",
          texts: [
            "교보우먼헬스케어서비스는 상품별로 정한 일정 금액이상을 가입한 고객에게 제공되며 가입 금액에 따라우먼헬스케어서비스와 플래티넘우먼헬스케어서비스로 나뉘어 서비스가 제공됩니다.",
            "해당서비스 신청서(개인[신용]정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하십니다.",
            "가입 기준 상품 및 금액은 당사 컨설턴트 또는 콜센터에 문의 주시기 바랍니다. ",
          ],
        },
      ],
    },
    {
      title: "평상시 건강관리",
      subTitle:
        "건강은 건강할 때 관리해야 한다고 합니다. 건강을 잃지 않도록 도와드립니다.",
      contents: [
        {
          title: "건강상담",
          texts: [
            "교보우먼헬스케어콜센터를 통해 간호사 상담 접수 후 내과, 외과, 산부인과, 치과 등14개과 진료과목 전문의 전화상담 제공",
          ],
        },
        {
          title: "건강정보 제공 (홈페이지, LMS, e-mail)",
          texts: [
            "주요 질환, 치아, 여성특정질환(손목터널증후군/하지정맥류/성대결절)에 대한 건강정보, 건강칼럼 제공",
          ],
        },
        {
          title: "고혈압 관리/ 비만·영양 관리 프로그램",
          texts: [
            "- 고혈압 요인 및 합병증 예방 가이드, 혈압관리를 위한 운동·영양 가이드 제공",
            "- 만성질환 예방 관리를 위한 비만 치료 / 체중감량 전략 / 영양 가이드 등 제공",
          ],
        },
        {
          title: "안과/ 치과 주요질환 정보 프로그램",
          texts: [
            "주요 안과 / 치과 질환 안내, 노인성 안과 / 치과 질환 예방 및 관리를 위한 가이드 제공",
          ],
        },
        {
          title: "간호사 복약 상담",
          texts: [
            "복약 정보 및 주의사항 등 간호사 1: 1 복약 지도 및 상담 제공",
          ],
        },
        {
          title: "당뇨 특화 모바일 웹진 제공",
          texts: [
            "- 예방, 관리, 치료 등 당뇨 관련 전문 콘텐츠 제공",
            "- 필요시 내과, 가정의학과 전문의 전화상담 제공",
          ],
        },
        {
          title: "주요 질환 예방 프로그램",
          texts: [
            "주요 질환 생활습관 개선 콘텐츠 제공(4주)",
            "※ 주요 질환 범위 : 대사증후군, 통증수반질환, 심 · 뇌혈관 질환 중 선택",
            "※ 심 · 뇌혈관 질환 신청 시 심화 예방프로그램 추가 제공(4주)",
          ],
        },
        {
          title: `<span class="d-block border-bottom-green py-3 m-0 fs-default fw-4 gray-1">여성을 위한 건강관리</span>`,
        },
        {
          title: "임신/출산 정보알림",
          texts: [
            "- 주 1회 : 임신 주수별 산모의 신체변화 및 출산 전 분비사항 등 맞춤정보 제공",
            "- 월 1회 : 출산 이후 육아법, 예방접종, 아이의 성장발달 등 맞춤 정보 제공 ",
          ],
        },
        {
          title: "출산 맞춤관리",
          texts: [
            "- 출산 전, 후 테마별 맞춤관리 프로 그램 제공",
            "※ 임신/출산 정보알림, 출산 맞춤관리는 임산부보장특약 선택 시 특약기간(1년)동안 제공",
          ],
        },
      ],
    },
    {
      title: "가족 건강관리",
      subTitle:
        "가족이 건강해야 고객님이 행복합니다. 가정의 건강설계를 지원합니다.",
      contents: [
        {
          title: "미숙아 방문간호 컨설팅 ",
          texts: [
            "미숙아 출생 시 신생아 전문간호사 방문 상담 및 교육 제공(퇴원 후 한달)",
            "※ 미숙아 : 임신 주수 37주 미만&출생체중 2.5KG이하",
          ],
        },

        {
          title: "가족건강검진 안내 (우대)",
          texts: [
            "- 가족 건강상담 및 제휴협력을 통한 건강검진 우대(고객 실비부담)",
            "- 맞춤형 건강검진 설계 및 검진항목 안내",
          ],
        },
        {
          title: "암 정보 제공 ",
          texts: [
            "암 진단 시, 가족 건강 및 지원을 위해 특화된 암 정보 및 식이, 운동법 등 관련 정보 제공",
          ],
        },
        {
          title: `<span class="d-block border-bottom-green py-3 m-0 fs-default fw-4 gray-1">여성을 위한 맞춤형 식단 컨설팅</span>`,
        },
        {
          title: "다이어트 / 가족 건강 / 질환별 맞춤형 식단 설계 제공",
          texts: [
            "고객 서비스 요청 > 전화상담을 통한 고객 분석 > 전문 임상영양사 식단 설계 > 피료 시 컨설팅 결과 피드백",
          ],
        },
        {
          title: "여성특화 건강검진 안내 (우대) ",
          texts: [
            "- 산전 검진, 여성주요암(유방암, 갑상선암, 자궁암) 검진설계 우대",
            "- 자궁경부암, A/B형 간염, 인플루엔자 등 주요 예방 시기 알림",
          ],
        },
      ],
    },
    {
      title: "일반 치료지원",
      subTitle:
        "중대 질환 이전 단계 질환은 관리가 중요합니다. 고객님의 빠른 회복을 도와드립니다.",
      contents: [
        {
          title: "병원 · 의료진 안내 및 예약대행",
          texts: [
            "질병 진료 및 치료를 위한 병원 · 의료진 안내 및 진료 예약대행",
          ],
        },
        {
          title: "여성질환 전담간호사 1:1 코칭콜",
          texts: [
            "- 여성질환 및 갱년기 진단 시 개인별 건강상태에 따른 전담간호사 코칭콜 제공",
            "- 개인별 맞춤 건강정보 제공 및 상담, 해당질환 교육 등",
            "※ 1:1 코칭콜 해당질환 : 여성질환(자궁경부이형성증, 자궁근종, 자궁내막증, 골반염, 난소낭종 등) 및 갱년기 질환, CI추가보장관련특약[암/뇌/심장 및 장기] 및 합병증보장특약의 해당질환",
          ],
        },
        {
          title: "간병인 매칭",
          texts: [
            "간병인 필요시 제휴업체를 통한 간병인 안내",
            "간병인 이용료는 고객 부담",
          ],
        },
        {
          title: `<span class="d-block border-bottom-green py-3 m-0 fs-default fw-4 gray-1">여성을 위한 일반 치료지원</span>`,
        },
        {
          title: "여성특화 자가진단",
          texts: [
            "유방암, 골다공증, 하지정맥류, 요실금, 갱년기 건강, 갱년기 우울증, 치매 자가진단 및 체크리스트 제공",
          ],
        },
        {
          title: "여성암 위험도 체크",
          texts: ["유방암, 자궁암, 난소암 위험도 체크 제공"],
        },
        {
          title: "토닥토닥 마음케어",
          texts: [
            `<span class="fs-18 fw-7">STEP1.평상 시</span>`,
            "- 심리전문칼럼",
            "- 심리자가진단",
            "- 힐링테라피",
            "<br/>",
            `<span class="fs-18 fw-7">STEP2.필요 시 - 토닥토닥 심리프로그램(4주)</span>`,
            "- 전화 심리상담 제공",
            "- 제휴 심리상담센터(대면 / 화상) 안내 및 우대(고객부담)",
          ],
        },
        {
          title: "당뇨 집중관리 14주 프로그램",
          texts: [
            "- 간호사 1:1 당뇨관리 상담 제공",
            "- 생활습관 체크, 당뇨 위험요인 스크리닝",
            "- 혈당관리, 합병증 예방 관련 콘텐츠 제공 ",
          ],
        },
        {
          title: "인공관절수술 자가 재활 프로그램",
          texts: [
            "인공관절치환수술 후 자가 재활관리 및 운동처방 맞춤 프로그램 제공",
          ],
        },
      ],
    },
    {
      title: "중대질환 치료지원",
      subtitle:
        "중대질환에 걸리면 어떻게 해야 할지 당황하게 됩니다. 진단부터 치료하는 전 과정에서 최적의 진료를 받을 수 있도록 지원합니다.",
      contents: [
        {
          title: "간호사 병원동반 / 질병관리교육",
          texts: [
            "- 중대질환 치료(수술, 입.퇴원 등) 시 전문간호사 병원동반",
            "- 중대질환 전문간호사 방문교육 서비스 제공",
            "- 중대질환별 건강상담, 건강정보, 심리상담 제공",
            "※ 중대질환 범위 : 암, 심장.뇌혈관질환, 고혈압성질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌.척수수막염), 루게릭병(근위축성측삭경화증)",
          ],
        },
        {
          title: "암 재발 예방",
          texts: [
            "암 치료 후 지속적 관리 제공",
            "전담간호사 정기적 안부 / 상담 콜",
            "병원 방문 시 간호사동반 방문서비스 제공(필요시)",
          ],
        },
        {
          title: `<span class="d-block border-bottom-green py-3 m-0 fs-default fw-4 gray-1">여성중대질환 방문지원</span>`,
        },
        {
          title: "간호사 병원동반",
          texts: [
            `서비스 상담 및 신청(1588-7523) <i class="icon-right"></i> 전문간호사 배정 및 방문 <i class="icon-right"></i> 병원동반 방문교육`,
          ],
        },
        {
          title: "질병관리교육",
          texts: [
            "해당질환 건강상담, 건강정보 제공",
            "※ 해당질환 : 중증루프스신염, 중증재생불량성 빈혈",
          ],
        },
      ],
    },
    {
      title: "교보플래티넘 우먼헬스케어서비스",
      button: {
        text: "꼭 읽어보세요!",
        modalId: "modal-kb-products",
        index: "platinum",
      },
      contents: [
        {
          title: "건강증진 프로그램",
          texts: [
            "현재 고객님의 건강상태를 정확히 파악한 후 체계적이고 효율적인 건강 프로그램을 제공해 드립니다. ",
            "- 관리 프로그램 (연 1회한) : 피부관리 / 산후관리 / 스트레스 / 갱년기 / 비만 / 혈압 / 혈당 / 고지혈 / 빈혈 / 간기능 / 금연 / 금주",
          ],
        },
        {
          title: "차량 에스코트",
          texts: [
            "전문간호사의 편안한 에스코트 및 병원간 이송지원으로 고객님과 가족의 걱정을 해소해 드립니다.",
            "- 입 · 퇴원 병원 동반 : 중대질환 수술을 위한 입 · 퇴원 시 전문 간호사 및 차량 지원",
            "※ 차량 에스코트 서비스는 서울권 내 이동에 한해 제공",
            "- 병원간 이송 : 중대질환 입원치료를 위한 타 의료기관 이송 시 차량 지원",
            "※ 응급 상황, 도서 지역 서비스 이용 불가 / 이송 2일 전 신청 (1인 연 1회한)",
            "※ 중대질환 범위 : 암, 심장 · 뇌혈관질환, 고혈압성질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌 · 척수수막염), 루게릭병(근위축성측상경화증)",
          ],
        },
        {
          title: "방문 심리상담",
          texts: [
            "심리상담전문가 방문을 통해 중대질환의 심리적 회복까지 도와드립니다.",
            "- 부정적 감정 감소 및 정서 안정을 위한 심리상담",
            "- 고객 치료 병원 또는 제휴 심리상담센터에서 진행",
            "※ 병원 / 심리상담센터 방문 1일 전 신청",
            "※ 중대질환 발생 고객 본인 / 고객 직계 가족 1인 중 선택 (연 1회)",
          ],
        },
        {
          title: "해외 의료지원",
          texts: [
            "해외에서 상해나 질병으로 위급한 상황 발생 시 신속하면서 편안하게 대처할 수 있도록 도와드립니다.",
            "- 국가별 예방접종, 유의사항 상담 및 현지 병원 안내, 예약",
            "- 365일 24시간 Hot Line 운영 및 의료통역 지원",
            "- 간호사 파견 및 긴급이송 지원(고객 실비부담)",
          ],
        },
        {
          title: "가사도우미 지원",
          texts: [
            "중대질환으로 장기간 입원 시 가사도우미를 통해 집안일을 도와드립니다.",
            `- 중대질환으로 7일 이상 입원 시 <i class="icon-right"></i> 방문전담간호사(헬스플래너)가 대리신청 <i class="icon-right"></i> 가사도우미 파견 (연 1회 / 1일 8시간)`,
          ],
        },
      ],
    },
    {
      title: "우먼헬스케어서비스 플러스",
      subTitle: "기존 교보우먼헬스케어서비스 중대질환 치료지원 서비스 중 중대질환 수술을 위한 입퇴원 시 중대질환별* 횟수 한도 내 선택 서비스(간호사 병원동행 및 방문교육 또는 헬스홈케어서비스) 제공",
      contents: [
        {
          texts: [
            "※ 헬스홈케어서비스(최대 3회) 또는 간호사 병원동행 및 방문교육 중 택 1",
            "※ 해당질환 :  암/심장질환/뇌혈관질환/고혈압성질환/당뇨병으로 인한 합병증 ,원발성 폐동맥 고혈압, 다발경화증, 중증 세균성 수막염(뇌수막염, 척수수막염), 루게릭병(근위축성측삭경화증)"
          ],
        },
        {
          title: "간호사 병원동행 및 방문교육",
          texts: [
            "서비스 이용절차 : 01. 교보우먼케어서비스 콜센터(1588-7523) -> 02. 상담 -> 03. 입퇴원 시 동행 및 교육 진행 -> 04. 퇴원 후 건강 정보 제공",
            "- 진료 동행 및 입퇴원 수속 에스코트",
            "- 질병치료법 및 예후 설명",
            "- 진료 시 의료진과의 원활한 의사소통 도움",
            "- 치료 시(항암 및 방사선 등) 교육",
            "- 복용 약물 교육",
            "- 식이요법 관리",
            "- 질병 교육자료 무료 제공",
            "- 정서적 안정 지원",
          ]
        },
        {
          title: "헬스홈케어서비스",
          texts: [
            "서비스 이용절차 : 01. 교보우먼케어서비스 콜센터(1588-7523) -> 02. 상담 -> 03. 프로터치 해피콜 -> 04. 청소 진행 및 고객 검수",
            "- 청소, 공간 방역, 매트리스 살균 등 주거환경 클리닝(선택 시 최대 3회 한도로 이용 가능)"
          ]
        },
        {
          title: "여성 건강 다이어리 & 맞춤 건강 제품",
          texts: [
            "- 먼슬리싱 APP을 통한 생리/배란주기 기록 및 분석",
            "- 여성 건강 제품 추천 및 맞춤 배송",
          ]
        },
        {
          title: "식단 리셋 프로그램",
          texts: [
            "- 암별 식단관리프로그램(항암/수술/방사선/치료종료)",
            "- 암 환자 전용 식습관 분석 및 추천 레시피",
          ]
        },
        {
          title: "식습관 데이터 기반 영양관리서비스",
          texts: [
            "※ 오픈이노베이션 「UT인프라」 제휴",
            "- 질환별 식습관 분석",
            "- 건강관리를 위한 식습관 컨설팅",
            " &nbsp;&nbsp;&nbsp;⇒ 제휴 APP을 통해 제공"
          ]
        },
        {
          title: "케어식단 제공 및 영양 컨설팅",
          texts: [
            "- 중대질환으로 입원 후 퇴원 시 건강회복을 위한 건강식 자택 배달서비스 제공",
            "- 케어식단 영양 컨설팅"
          ]
        }
      ]
    }
  ],
  children: [
    {
      title: "이용방법",
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      button: { text: "꼭 읽어보세요!", modalId: "modal-kb-products" },
      contents: [
        {
          title: "어린이보험상품에 가입한 고객에게 서비스를 제공해 드립니다.",
          texts: [
            "교보어린이헬스케어서비스는 어린이보험상품에 가입한 고객에게 제공되며, 해당서비스 신청서",
            "(개인정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하십니다",
            ".교보어린이헬스케어서비스의 부가기준 등 상세한 내용은 당사 컨설턴트 또는 콜센터에 문의 주시기 바랍니다.",
          ],
        },
      ],
    },
    {
      title: "어린이 건강도서관",
      subTitle: "임신/출산/육아/생활 등 꼭 필요한 정보를 찾아 드립니다.",
      contents: [
        {
          title: "반짝반짝 눈 건강 / 튼튼이 치아건강",
          texts: ["아이 눈·치아 건강을 위한 전문 콘텐츠 제공"],
        },
        {
          title: "건강&육아정보",
          texts: [
            "건강동영상·이유식 레시피·아이교육 등 출산 및 육아 콘텐츠 제공",
          ],
        },
        {
          title: "건강 알리미(LMS)",
          texts: [
            "- 주 1회 : 산모의 신체변화/출산 전 준비사항 등 맞춤 정보 제공",
            "- 월 1회 : 아이 성장발달/출산 후 육아법 등 맞춤 정보 제공",
          ],
        },
      ],
    },
    {
      title: "상담 치료지원",
      subTitle: "사소한 궁금증 해결부터 병원 예약까지 안내해 드립니다.",
      contents: [
        {
          title: "건강상담",
          texts: [
            "간호사 상담접수 후 산부인과·소아과·치과·안과 등 13개 진료과목",
            "전문의·영양사·운동처방사 전화상담",
          ],
        },
        {
          title: "병원·의료진 안내",
          texts: ["질병 진료 및 치료를 위한 병원·의료진 안내 및 진료 예약대행"],
        },
      ],
    },
    {
      title: "방문 치료지원",
      subTitle:
        "미숙아, 저체중아, 영유아 중대질환 시 전문간호사가 치료지원 및 질병교육을 도와드립니다.",
      contents: [
        {
          title: "미숙아, 저체중아 방문지원",
          texts: [
            "미숙아, 저체중아 출산 시 신생아 전문간호사 방문상담 및 교육제공(2회)",
            "※ 미숙아 : 임신 주수 37주 미만 & 출생체중 2.5kg 이하 / 저체중아 : 출생체중 2.5kg 이하",
          ],
        },
        {
          buttonTitle: {
            title: "중대질환 방문지원",
            buttonTitle: "기본서비스",
            buttonType: 'default'
          },
          texts: [
            "아이의 중대질환 발생 시 전문 간호사 방문상담 및 교육제공",
            "(최초진단 시 최대 4회, 단 소아당뇨는 최대 2회)",
            "※ 중대질환 : 암/소아당뇨/뇌종양/악성림프종/소아뇌졸중/소아마비/만성신부전증",
          ],
        },
        {
          buttonTitle: {
            title: "3가지 中 2회 한도 內 이용(소아당뇨는 이용불가)",
            buttonTitle: "선택서비스",
            buttonType: ''
          },
          texts: [
            "중대질환(암, 뇌종양, 악성림프종, 소아뇌졸중, 소아마비, 만성신부전증) 진단 후 아래 3가지 서비스 중에서 서비스 이용 횟수 합산 최대 2회까지 이용가능 ",
            "※ 헬스홈케어서비스의 경우 다른 서비스 먼저 이용 시 서비스 이용이 제한될 수 있음"
          ],
        },
        {
          title: '헬스홈케어서비스(거실, 방1곳, 욕실1곳)',
          texts: [
            "아이의 중대질환 발생 시 생활공간(주거환경) 살균 소독 및 청소 대행 서비스 제공",
            "※ 서비스 신청 시 선택 서비스 합산 횟수에서 2회 차감"
          ],
        },
        {
          title: '차량 에스코트(편도 1회)',
          texts: [
            "치료 목적의 병원 이동 또는 퇴원 시 목적지까지 차량 지원",
            "※ 입·퇴원 1일 전 신청 필요(동일권역 50km 이내 이동)"
          ],
        },
        {
          title: '가사도우미 지원(8시간/1회)',
          texts: [
            "아이의 중대질환 치료 시 가사도우미 지원",
            "※ 방문 희망일 최소 3일 전 신청"
          ],
        }
      ],
    },
    {
      title: "임신 · 출산 · 육아 집중 관리",
      subTitle:
        "시기별 · 테마별 프로그램을 통해 엄마와 아이를 맞춤 관리해 드립니다.",
      contents: [
        {
          title: "집중관리 프로그램",
          texts: [
            "For Mom",
            "엄마를 위한 예비맘과 출산맘 8가지 프로그램",
            "- 예비맘(필수체크! 예비맘의 건강체크 등) ",
            "- 출산맘(건강한 모유수유 바로 알기 등)",
            "<br/>",
            "For baby",
            "아이를 위한 건강과 성장 8가지 프로그램",
            "- 똑똑건강(유행성 질환 대처하기 등) ",
            "- 쑥쑥성장(초보 육아 따라하기 등)",
            "<br/>",
            "For Mom & baby",
            "엄마와 아이가 함께하는 임산부 홈트레이닝 프로그램",
            "- 틈틈 스트레칭, 건강한 D라인 만들기 등 태아와 임산부 건강관리를 위한 운동 프로그램 제공(동영상)",
            "<br/>",
            "출산 지원 컨설팅",
            "- 임신 기간 동안 생활습관 개선, 임신트러블 등 정보 제공 및 1:1 컨설팅",
            "- 이상소견에 대한 전문의료진 상담 및 영양, 운동 등 건강관리 상담",
          ],
        },
      ],
    },
    {
      title: "가족 건강지원",
      subTitle: "우리 가족의 심리안정부터 건강증진까지 도와 드립니다.",
      contents: [
        {
          title: "심리상담 ",
          texts: [
            "임신·출산·육아 스트레스 관련 전문 심리상담사 전화 상담",
            "- For mom : 임신 후 우울감/ 산 후 우울감/ 양 육 스트레스/ 수 면장애 등",
            "- For baby : 아동 ADH D /학습발달장 애 /틱장 애 /아이성장교육 등",
          ],
        },
        {
          title: "가족 건강증진 지원",
          texts: [
            "- 가족 건강상담 & 맞춤형 건강 식단 설계",
            "- 건강검진(우대), 건강기능식품 안내(우대)",
            "- 제대혈 보관 안내(우대)",
          ],
        },
      ],
    },
  ],
  junior: [
    {
      title: "이용방법",
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      button: { text: "꼭 읽어보세요!", modalId: "modal-kb-products" },
      contents: [
        {
          texts: [
            "교보주니어헬스케어서비스는 미리보는(무)교보변액교육보험상품에 가입한 고객에게 제공되며 해당 서비스신청서(개인정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하십니다.",
            "가입 기준 상품 및 금액은 당사 컨설턴트에게 문의 주시기 바랍니다.",
          ],
        },
      ],
    },
    {
      title: "스텝케어",
      subTitle: "시기별 건강&성장관리로 아이의 올바른 성장을 도와드립니다.",
      contents: [
        {
          title: "영유아 육아관리 (0~5세)",
          texts: [
            "엄마와 자녀를 위한 시기별 · 테마별 16가지 집중관리 프로그램 (양육 / 건강 /성장)",
          ],
        },
        {
          title: "성장관리 (6~18세) ",
          texts: [
            "- 건강칼럼 및 건강정보 제공",
            "- 성장관리 클리닉 안내, 발달센터 우대(고객실비부담)",
          ],
        },
        {
          title: "심리관리 (6~18세) ",
          texts: [
            "- 심리 콘텐츠 제공",
            "- 심리상담센터 심리검사 및 상담우대 (고객실비부담)",
          ],
        },
      ],
    },
    {
      title: "헬스케어",
      subTitle:
        "아이 건강에 대한 사소한 궁금증 해결부터 병원예약, 방문 상담까지 제공합니다.",
      contents: [
        {
          title: "전문의료진 건강상담",
          texts: [
            "간호사 상담접수 후 전문의, 간호사, 영양사, 심리상담사, 운동처방사 등 각 분야별 전문가의 전화상담",
          ],
        },
        {
          title: "병원·의료진 안내 및 예약",
          texts: ["질병진료 및 치료를 위한 병원·의료진 안내 및 진료 예약 대행"],
        },
        {
          title: "건강검진 우대 및 예약",
          texts: [
            "- 제휴협력을 통한 건강검진 우대(고객실비부담)",
            "- 맞춤형 건강검진 설계 및 검진항목 안내",
            "- 건강검진 결과 상 담",
          ],
        },
        {
          title: "방문 치료지원",
          texts: [
            "중대질환 방문지원중대질환 발생시 전문간호사 방문상담 및 질병교육 (최대 4회, 소아당뇨는 최대 2회)",
            "※중대질환 : 암, 뇌종양, 악성림프종, 소아뇌졸중, 소아마비, 만성신부전증, 소아당뇨",
          ],
        },
      ],
    },
    {
      title: "글로벌케어",
      subTitle:
        "해외에서 상해나 질병으로 위급한 상황 발생시 신속하면서 편안하게 대처할 수 있도록 도와드립니다.",
      contents: [
        {
          title:
            "현지병원 안내 및 예약- 출국 전 국가별 예방접종 및 유의사항 상담",
          texts: [
            "- 상해나 질병으로 의료상황 발생시 현지병원, 진료절차 안내 및 진료예약 대행",
            "※ 서비스 대상 자녀 및 동반 부모까지 이용 가능",
          ],
        },
        {
          title: "언어지원 및 의료서류 번역 ",
          texts: [
            "- 현지 의료진과 실시간 의료통역",
            "- 해당 국가 의료서류 번역지원",
          ],
        },
      ],
    },
    {
      title: "알지케어",
      subTitle:
        "활동적이고 도전적인 &#x3b1;(알파)세대 및 Z세대를 위한 맞춤케어",
      contents: [
        {
          title: "청소년 안과 예방 · 관리 프로그램",
          texts: ["청소년 안과 질환 예방 및 관리를 위한 가이드 제공"],
        },
        {
          title: "청소년 영양 & 운동 관리 프로그램",
          texts: ["청소년기 영양/운동 관리를 위한 가이드 제공"],
        },
        {
          title: "학업능력 향상 프로그램",
          texts: ["집중력, 학습욕구, 두뇌건강에 대한 자가진단/상담 및 피드백"],
        },
        {
          title: "심리 안정 이너피스 프로그램",
          texts: [
            "마인드 코칭, 숙면관리, 스트레스 관리, 학습/교우/가족 상담 등 서비스 제공",
            "※ 이너피스(Inner peace) : 마음의 평화",
          ],
        },
      ],
    },
  ],
  dementia: [
    {
      title: "이용방법",
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      button: { text: "꼭 읽어보세요!", modalId: "modal-kb-products" },
      contents: [
        {
          texts: [
            "교보치매케어서비스는 해당보험상품에 가입한 고객에게 제공되며 해당 서비스 신청서(개인정보 수집 · 이용 · 제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하십니다. 가입 기준 상품 및 금액은 당사 컨설턴트에게 문의 주시기 바랍니다.",
          ],
        },
      ],
    },
    {
      title: "평상시 치매예방",
      subTitle:
        "평상시 건강관리부터 연령별 치매예방관리로 치매걱정 없이 건강하게",
      contents: [
        {
          title: "디지털 치매예방프로그램",
          texts: [
            "- 인지 능력 및 암기 능력 향상 콘텐츠 제공: 뇌 훈련 퀴즈, 명언 암기 등",
            "- 건강 식단 및 음원 콘텐츠제 공 : 뇌 건강 레시피, 힐링 음악 등",
            "*프로그램 신청 고객에게 모바일로 콘텐츠를 발송하고 필요시 간호사, 전문의 등 전문가 상담 진행",
          ],
        },
        {
          title: "치매예방 건강관리",
          texts: [
            "- 건강정보 제공 : 시니어 건강, 운동, 영양 등 치매예방을 위한 건강콘텐츠 제공",
            "- 건강검진 우대 : 제휴협력을 통한 건강검진 우대 (고객부담)",
          ],
        },
        {
          title: "건강상담&심리상담",
          texts: [
            "- 건강상담: 간호사 상담접수 후 신경과, 정신건강의학과, 내과 등 14개과 전문의 전화상담",
            "- 심리상담 : 우울, 불안 등 심리상담사와 전화심리상담, 심리상담센터 심리검사 및 상담 우대 (고객부담)",
          ],
        },

        {
          title: "병 원·의료진 안내 및 예약대행",
          texts: [
            "질병진료 및 치료를 위한 병원 · 의료진 안내 및 진료 예약대행",
          ],
        },

        {
          title: "요양병원·시설 찾기",
          texts: [
            "- 희망지역, 건강 상태, 테마 별(양/한방, 혈액투석 등) 요양병원·시설 찾기 제공",
            "- 고객의 상황에 맞는 맞춤형 요양병원·시설(최대 3곳)견적 비교 제",
          ],
        },
        {
          title: "치매안심체크&안심콜",
          texts: [
            "치매안심체크 : 연령구간별 맞춤 치매관리 지원 (연 1회)",
            "※ 치매자가문진 (LMS > 치매상담간호사 전화상담 > 필요시 의료기관 연계 지원)",
            "치매안심콜 : 65세 이상 고령층 대상 안부콜 제공 (연 1회)",
          ],
        },
      ],
    },
    {
      title: "경도 치매케어 CDR1",
      subTitle: "믿을 수 있는 전문가의 도움으로 치매 초기 진단에도 든든하게",
      contents: [
        {
          title: "치매케어 플래너",
          texts: [
            "- 치매환자에게 필요한 치매치료 및 관리방법 설계",
            "- 치료 · 재활과정에서 필요한 의료 및 요양기관 지원 상담",
            "- 제휴협력을 통해 요양보호사 · 간병인 제휴업체 연계(고객부담)",
          ],
        },
        {
          title: "간병인 지원 또는 간호사 병원동반",
          texts: [
            "경도 치매진단 후 상급종합병원 진료, 상해/ 질병으로 인해 상급종합병원 입원시 간병인 지원(최대 5일, 1회) 또는 경도 이상 치매진단 후 상급종합병원 진료 시 간호사 병원동반(2회) 중 선택 제공",
            "※ 간병인 지원의 경우, 3영업일 전 신청",
            "(중등도 이상 치매의 경우 간병인 지원이 제한될 수 있음)",
            "※ 간호사 병원동반의 경우, 치매관련 질환(신경과 · 정신과)으로 진료 시 제공",
          ],
        },
        {
          title: "보호자 심리케어",
          texts: [
            "- 보호자 전화 심리상담 제공(4회)",
            "- 심리상담사 가족 방문상담(1회)",
            "※ 단, 방문장소는 관련시설 및 의료기관",
          ],
        },

        {
          title: "화상심리상담(2회) or 위치추적기 지원",
          texts: [
            "경도 이상 치매진단 후 화상심리상담(2회) 또는 실내외 위치 추적기(배회감지기)지원 중 선택 제공",
            "※ 위치추적기 월 사용료는 고객부담",
          ],
        },
      ],
    },
    {
      title: "중등도 치매케어 CDR2",
      subTitle: "치매치료를 위한 의료기관 이동시에도 신속하고 편안하게",
      contents: [
        {
          title: "치매케어 플래너",
          texts: [
            "- 치매환자에게 필요한 치매치료 및 관리방법 설계",
            "- 치료 · 재활과정에서 필요한 의료 및 요양기관 지원 상담",
            "- 제휴협력을 통해 요양보호사 · 간병인 제휴업체 연계 (고객부담)",
          ],
        },
        {
          title: "차량 에스코트",
          texts: [
            "상급종합병원 입원 또는 퇴원시 차량 에스코트 지원 (1회)",
            "※ 단, 보호자 동반 必 / 50km까지 무상제공, 초과 비용은 고객부담",
          ],
        },
        {
          title: "화상심리상담(3회) or 병원 간 이송",
          texts: [
            "중등도 치매 진단 후 화상심리상담(3회) 또는 병원 간 환자이송 발생시 앰뷸런스 지원(1회) 중 선택 제공",
            "※ 단, 보호자 동반 必 / 50km까지 무상제공, 초과 비용은 고객부담",
          ],
        },
        {
          title: "요양기관 입소지원 상담",
          texts: ["요양기관 안내 및 기관 이용시 필요사항 체크 등 상담 지원 "],
        },
      ],
    },
    {
      title: "중증 치매케어 CDR3",
      subTitle: "중증 치매진단에도 가족간병 지원을 통해 언제 어디서나 걱정없게",
      contents: [
        {
          title: "가사도우미 지원",
          texts: [
            "중증치매로 장기간 입원시 가사지원서비스 (연 1회/4시간)",
            "※ 중증치매 진단 후 상급종합병원]}, 7일 이상 입원시 → 가사도우미 파견",
          ],
        },
        {
          title: "화상심리상담(4회) or 치매환자 24시 모니터링 지원",
          texts: [
            "중증치매 진단 후 화상심리상담(4회) 또는 중증치매환자 모니터링을 위한 CCTV 필요시 제휴업체 연계 및 설치 지원 중 선택 제공",
            "※ CCTV 설치 후 월 사용료는 고객부담",
          ],
        },
      ],
    },
  ],

  coach: [
    {
      title: "이용방법",
      button: { text: "꼭 읽어보세요!", modalId: "modal-kb-products" },
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      contents: [
        {
          texts: [
            "- 모바일 App에서 활동량 측정 등 다양한 교보건강코칭서비스를 이용할 수 있습니다.",
            "- 콜센터(1661-0664)를 통해서도 서비스 이용 가능합니다.",
            "- (무)교보미리미리여성CI보험, (무)교보미리미리CI보험, NEW(무)교보미리미리여성CI보험, NEW(무)교보미리미리CI보험, (무)교보 실속있는 건강플러스종신보험, (무)교보실속있는NEW건강플러스종신보험, (무)교보내생애맞춤건강보험(2018.4월 1일 이후 가입자) 상품에 가입한 고객에게 서비스를 제공해 드립니다.",
            "- 교보건강코칭서비스는 상품별로 정한 일정 금액이상을 가입한 고객에게 제공됩니다.",
            "- 해당서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하십니다.",
            "- 가입 기준 상품 및 금액은 당사 컨설턴트 또는 콜센터에 문의 주시기 바랍니다.",
          ],
        },
      ],
    },
    {
      title: "매일매일 챌린지",
      subTitle:
        "건강을 유지할 수있게 권장 활동량을 설정 해드리고 목표 달성 시 다양한 혜택을 제공해 드립니다.",
      contents: [
        {
          title: "걷기 챌린지",
          texts: [
            "- 매일매일 출석체크 & 걷기 목표 도전",
            "걸음수/칼로리 소모량 등 나의 운동 현황 분석",
            "목표 달성 시 경품 응모",
          ],
        },
        {
          title: "이벤트 챌린지",
          texts: [
            "- 건강상식 OX, 객관식 퀴즈, 숨은 음식 찾기 등",
            "다양한 이벤트 챌린지 제공",
            "목표 달성 시 경품 응모",
          ],
        },
      ],
    },
    {
      title: "푸드렌즈",
      subTitle:
        "사진촬영으로 간편하게 식사를 기록하고 1:1 식단 컨설팅까지 해드립니다.",
      contents: [
        {
          title: "AI카메라 기반 식단 관리 ",
          texts: [
            "- 인공지능 사진 인식 기술을 통한 음식/칼로리 자동 분석",
            "- 일자별/식사별 칼로리&영양 관리",
          ],
        },

        { title: "1:1식단컬설팅", texts: ["- 전문영양사를 통한 1: 1컨설팅"] },
      ],
    },
    {
      title: "5! 마이코칭",
      subTitle:
        "건강나이를 낮추주는 전문가의 건강코칭프로그램을 통해 고객님의 건강을 증진시켜드립니다.",
      contents: [
        {
          title: "12주 건강코칭프로그램 ",
          texts: [
            "운동/영양/스트레스/절주/금연 등 5대 프로그램 ",
            "웹툰/동영상/레시피 등 건강증진 콘텐츠",
            "프로그램 완료 시 축하선물 증정",
            "나만의 건강코치와 1:1채팅상담 제공",
          ],
        },
        {
          title: "건강나이",
          texts: [
            "- 건강나이 측정",
            "- 건강위험도 평가",
            "※ 5대 중증질환 위험도, 5대 암 위험도",
          ],
        },
      ],
    },
    {
      title: "체크체크 마이헬스",
      subTitle:
        "건강검진 결과를 알기 쉽게 이해할 수 있도록 풀이해 주고 검진결과를 바탕으로 의학생체나이를 측정해 드립니다.",
      contents: [
        {
          title: "건강검진 결과 분석",
          texts: [
            "- 건강검진 결과 입력 시, 건강 리포트 제공※ 서비스 기간 내 2회",
            "- 신체 장기나이 및 비만 체형 나이 분석",
            "- 맞춤 처방 및 실천가이드 제공",
          ],
        },
        {
          title: "전문가 관리 상담",
          texts: ["- 전문의료진을 통한 분석 상담", "- 건강생활습관 상담"],
        },
      ],
    },
    {
      title: "건강도서관",
      subTitle:
        "건강에 대한 궁금증을 쉽게 찾으실 수 있도록 전문 건강정보를 한 곳에 모아 제공해 드립니다.",
      contents: [
        {
          title: "건강 정보",
          texts: [
            "생활건강관리 방법 및 중대질병 정보 제공",
            "운동동영상/영양레시피 등 다양한 콘텐츠",
          ],
        },

        { title: "건강웹진", texts: ["- 모바일 건강웹진 제공(매월 1회)"] },
      ],
    },
    {
      title: "건강코치 1:1 상담",
      subTitle: "건강관리 전문가와 언제, 어디서나 쉽게 1:1로 상담해 드립니다.",
      contents: [
        {
          title: "체팅/전화상담",
          texts: [
            "- 전문의료진(간호사), 운동처방사, 영양사, 심리상담사와 1:1상담",
            "- 사진전송이 가능한 스마트한 상담",
          ],
        },
        {
          title: "카카오톡 플러스친구 ",
          texts: [
            "- 건강웹진 발송(매월 1회)",
            "- 서비스 및 이벤트 안내",
            "- App 사용 매뉴얼 안내",
          ],
        },
      ],
    },
  ],
  basic: [
    {
      title: "이용방법",
      button: { text: "꼭 읽어보세요!", modalId: "modal-kb-products" },
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      contents: [
        {
          texts: [
            "- 어플리케이션 교보생명상품부가서비스 App을 구글플레이/앱스토어에서 다운 받아 이용 하실 수 있습니다.",
            "- 콜센터(1811-7536)를 통해서도 서비스 이용 가능합니다.",
            "- 교보헬스케어서비스BasicⅡ는 건강보장보험에 가입한 고객에게 서비스를 제공해드립니다.",
            "- 해당 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하시며 서비스 부가기준 등 상세한 내용은 당사 컨설턴트에게 문의 주시기 바랍니다.",
          ],
        },
      ],
    },
    {
      title: "질병 예방 건강관리 프로그램",
      subTitle:
        "질병건강 가이드! 주요 질병별 건강관리 프로그램으로 예방&관리도 걱정없게",
      contents: [
        {
          title: "고혈압 관리 프로그램",
          texts: [
            "- 고혈압 요인 및 합병증 예방 가이드",
            "- 혈압관리를 위한 운동·영양 가이드 제공",
          ],
        },
        {
          title: "비만·영양관리 프로그램",
          texts: [
            "- 만성질환 예방 / 관리를 위한 비만 치료 안내",
            "- 체중감량 전략/다이어트 운동법·영양 가이드 제공",
          ],
        },

        {
          title: "안과 / 치과 주요질환 정보 프로그램",
          texts: [
            "- 주요 안과/치과 질환 소개 및 원인, 증상, 관리법 안내- 노인성 안과질환·치과질환 예방/관리를 위한 가이드 제공",
          ],
        },

        {
          title: "당뇨 예방프로그램",
          texts: [
            "- 혈당관리, 합병증 예방 콘텐츠 & 1:1 당뇨관리 상담 제공- 생활습관 체크, 당뇨 위험요인 스크리닝",
          ],
        },

        {
          title: "심·뇌혈관 질환 자가관리 프로그램",
          texts: ["심·뇌혈관 질환 생활습관 개선 콘텐츠 제공"],
        },

        {
          title: "암 정보제공건강",
          texts: [
            "및 치료 지원을 위해 특화된 암 질병 정보, 식이 및 운동법 등 관련 정보 제공",
          ],
        },
      ],
    },
    {
      title: "평상시 건강관리",
      subTitle:
        "분야별 건강 전문가의 도움으로 평상시 건강관리도 든든하게 도와드립니다.",
      contents: [
        {
          title: "전문의료진 건강상담",
          texts: [
            "- 간호사 상담 접수 후 내과, 산부인과 등 14개과 진료과목 전문의, 영양사, 심리상담사 등 각 분야별 전문가의 전화상담 제공",
            "- 전문의 : 내과, 산부인과 등 14개 진료과목 전문의 전화상담 제공",
            "- 간호사 : 임상경험이 풍부한 간호사가 건강관련 전화상담 제공",
            "- 심리상담사 : 스트레스, 우울 등 심리단계별 전화상담 제공",
            "- 건강운동관리사 : 맞춤형 운동관리(운동코칭/지도 등) 전화상담 제공",
            "- 영양사 : 각종 질환 등 고객 건강에 다른 식이/영양 상담 제공",
          ],
        },
        {
          title: "건강검진 우대 및 예약",
          texts: [
            "- 맞춤형 건강검진 설계 및 검진 항목 안내",
            "- 제휴협력을 통한 검진 예약 대행 및 검진 우대(고객 부담)",
            "- 건강검진 결과 상담 제공",
          ],
        },
        {
          title: "간호사 복약 상담",
          texts: [
            "- 약학정보원 등 디지털 라이브러리 기반의 간호사 1:1 복약 지도 및 상담 제공",
            "- 복약 정보, 복약 시 주의사항 제공",
          ],
        },
      ],
    },
    {
      title: "일반 치료지원",
      subtitle:
        "고객님의 건강상태에 따른 맞춤형 치료지원! 가장 적합한 의료진을 찾아 진료 예약대행을 도와드립니다.",
      contents: [
        {
          title: "병원·의료진 안내",
          texts: [
            "- 질병 종류별, 상황별 질병관련 기본 교육 및 치료방법 등 전문의 전화상담 제공",
            "- 질병 발생 시 적절한 치료를 받을 수 있도록 해당 질병별 치료 병원 및 의료진 안내",
          ],
        },
        {
          title: "진료예약 대행",
          texts: [
            "적절한 시기에 적합한 치료를 받기 위한 전국 3차병원 진료 예약 대행",
          ],
        },
        {
          title: "간병인 매칭",
          texts: [
            "간병인 필요시 제휴업체를 통한 간병인 안내 서비스 제공(간병인 이용료는 고객 부담)",
          ],
        },
      ],
    },
    {
      title: "3대 질병 치료지원",
      subTitle: "큰 병도 이겨낼 수 있도록 필요에 따라 선택하는 치료지원",
      contents: [
        {
          boxTexts: [
            "중대질환 범위 : 암, 심장 . 뇌혈관 질환, 고혈압성 질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌, 척수수막염), 루게릭병 (근위축성측삭경화증)",
          ],
        },
        {
          title: "선택1) 간호사 병원 동반 및 질병관리 교육(1회)",
          texts: [
            "3대 질병 치료(수술, 입·퇴원 등)시 전문간호사 병원 동반 및 질병 관리 교육 제공",
          ],
        },
        {
          title: "선택2) 환자케어 식단(총 24끼 주 6끼X4주)",
          texts: [
            "3대 질병 치료를 위한 입원 후 퇴원 시 자택으로 회복 식단※ 저당식단(혈당조절), 장수식단(면역강화), 혼합식단(저당·장수 식단)중 선택",
          ],
        },
        {
          title: "선택3) 주거환경 면역케어(1회)",
          texts: [
            "3대 질병 치료를 위한 입원 후 퇴원 시 자택 주거환경 면역케어(매트리스 살균/공간 방역살균)",
          ],
        },
      ],
    },
  ],
  education: [
    {
      title: "이용방법",
      button: { text: "꼭 읽어보세요!", modalId: "modal-kb-products" },
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      contents: [
        {
          texts: [
            "- 모바일 App을 구글플레이/앱스토어에서 다운 받아 이용 하실 수 있습니다.",
            "- www.kyoboeducare.com에 접속하셔서 이용 하실 수 있습니다.",
            "- 콜센터(1588-1078)를 통해서도 서비스 이용 가능합니다.",
            "- 교보에듀케어서비스Ⅱ는 미리보는 (무)교보변액교육보험상품에 가입한 고객에게 제공되며, 교보에듀케어서비스 KEDS, READ, KEDSⅡ, 1318은 어린이보험상품에 가입한 고객에게 제공됩니다. ",
            "- 해당 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하시며 서비스 부가기준 등 상세한 내용은 당사 컨설턴트에게 문의 주시기 바랍니다. ",
          ],
        },
      ],
    },
    {
      title: "양육마스터",
      subtitle:
        "자녀의 꿈과 재능을 찾아내고 키워주는! 현명한 부모를 위한 자녀양육 길라잡이",
      contents: [
        {
          title: "부모 · 자녀 진단 검사",
          texts: [
            "부모 자신을 진단하거나, 자녀의 행동을 관찰하고 진단하는 다양한 발달진단 검사 제공",
            "※ 유아 성격유형 진단 · 부모와 자녀 친밀감 검사 등",
          ],
        },

        {
          title: "양육칼럼",
          texts: ["유아 · 아동 자녀 양육과 관련된 다양한 테마별 전문가 조언"],
        },
        {
          title: "상담 FAQ",
          texts: ["다양한 주제에 대한 전문가의 상담사례 소개"],
        },
      ],
    },
    {
      title: "디지털도서관",
      subTitle:
        "내 손안의 교보문고! 언제 어디서든 eBook과 함께하는 즐거운 책읽기",
      contents: [
        {
          title: "교보문고 eBook",
          texts: [
            "아이들의 사고력과 창의력을 키워주는 전자책 1, 000권 제공(모바일 · 태블릿 · PC)",
          ],
        },

        {
          title: "독서 길라잡이",
          texts: [
            "- 키위맘 : 교보문고와 어린이 전문가들이 선정한 추천도서 소개",
            "- 북칼럼 : 아동문학가들이 직접 책을 추천하고 활용법까지 조언하는 독서지원 칼럼",
            "- 북트레일러 : 화제의 도서를 다양한 방식으로 소개하는 멀티미디어 콘텐츠",
            "- 영상CAST : 인문, 예술, 자기계발 등의 도서를 기반으로 제작한 지식문화 영상 콘텐츠",
          ],
        },
      ],
    },
    {
      title: "글로벌멘토링",
      subTitle: "더 넓은 세상 속으로! 청소년의 단계별 성장지원",
      contents: [
        {
          title: "청소년 유학가이드",
          texts: [
            "- 세계문화탐방 : 청소년의 눈높이에서 소개하는 세계 여러나라의 역사와 문화 콘텐츠",
            "- 세계유학정보 : 국가별 유학준비를 위한 다양한 영역별 정보",
          ],
        },

        {
          title: "청소년 성장칼럼",
          texts: ["청소년 성장 · 양육과 관련된 다양한 테마별 전문가 조언"],
        },

        {
          title: "청소년 인문학 길잡이",
          texts: [
            "- 명사칼럼 : 명사들이 들려주는 청소년을 위한 지혜로운 조언",
            "- 고전탐색 : 인문학자들이 들려주는 청소년을 위한 고전작품 소개",
          ],
        },
        {
          title: "청소년 워크",
          texts: [
            "리더십 스쿨, 문화체험 등 다양한 영역의 체험 프로그램(추첨 선발)",
          ],
        },

        {
          title: "청소년 특강",
          texts: ["저자 및 명사와 함께하는 청소년을 위한 강연회(추첨 선발)"],
        },
      ],
    },
    {
      title: "현장학습",
      subTitle: "더 넓은 세상 속으로! 청소년의 단계별 성장지원",
      contents: [
        {
          title: "STEAM 문화체험",
          texts: [
            "사고력과 창의력을 키워주는 다양한 현장 체험학습(추첨 선발)",
            `※ STEAM이란?
    Science, Technology, Engineering, Arts, Mathematics의 융합형 교육 프로그램`,
          ],
        },
        {
          title: "가족여행",
          texts: [
            "역사 · 경제 · 문화 · 힐링 등 다양한 테마와 함께 전국의 명소를 찾아가는 가족여행(추첨 선발)",
          ],
        },
        {
          title: "어린이 해외문화캠프",
          texts: [
            "세계역사학습, 소비문화체험 등 조별 미션을 수행하는 해외캠프를 통해 협동심과 성취감을 향상(추첨 선발)",
          ],
        },
        {
          title: "청소년 워크숍리더십",
          texts: ["스쿨, 문화체험 등 다양한 영역의 체험 프로그램(추첨 선발)"],
        },
        {
          title: "청소년 특강",
          texts: ["저자 및 명사와 함께하는 청소년을 위한 강연회(추첨 선발)"],
        },
      ],
    },
  ],
  silver: [
    {
      title: "이용방법",
      button: { text: "꼭 읽어보세요!", modalId: "modal-kb-products" },
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      contents: [
        {
          texts: [
            "- 노후생활보장(연금보험)상품에 가입한 고객에게 서비스를 제공해 드립니다.",
            "- 교보실버케어서비스플러스는 상품별로 정한 일정 금액이상을 가입한 고객에게 제공되며, 해당서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하십니다. ",
            "- 교보실버케어서비스플러스의 부가기준 등 상세한 내용은 당사 컨설턴트 또는 콜센터에 문의 주시기 바랍니다. ",
          ],
        },
      ],
    },
    {
      title: "연금 개시 전 암치료 지원",
      subTitle:
        "암 발생시 유명병원 안내 · 병원진료 · 명의(名義) 정보 안내와 직접 간호사가 방문하여 회복교육을 해드립니다.(가입 1년 후부터 연금개시 전까지 가능)",
      contents: [
        {
          texts: [
            "- 유명병원 및 의료진 안내",
            "- 병원 예약",
            "- 종양전문간호사 방문 교육(총 3회) - 항암치료, 식이요법, 회복교육 등",
          ],
        },
      ],
    },
    {
      title: "연금 개시 후 노화방지 프로그램",
      subTitle:
        "서울대학교병원이 개발, 감수한 위험평가프로그램을 통해 노인성 질환 예방상담을 제공해 드립니다.",
      contents: [
        {
          texts: [
            "- 건강위험/질병위험/신체활동 및 신체기능 평가/인지ㆍ감정 평가 등",
            "- 연령에 따른 맞춤형 평가/개인별 건강증진, 질병예방 권장안 제공",
            "- 당사 실버케어스테이션에서 프로그램 및 컨설팅 제공(고객내방)",
          ],
        },
        {
          title: "노화방지프로그램이란?",
          texts: [
            "서울대학교병원(가정의학과 조비룡 교수)에서 개발ㆍ감수한 위험평가프로그램을 통한 컨설팅서비스로 검사 또는 검진은 아닙니다. ",
          ],
        },
      ],
    },
    {
      title: "연금 개시 후 간병상담",
      subTitle:
        "장기간병상태 발생시 가족에게 악화방지 및 회복에 필요한 1:1 맞춤형 전화/인터넷 상담과 각종 정보를 제공해 드립니다.",
      contents: [
        {
          texts: [
            "- 노인장기요양시설 안내",
            "- 일상생활 간병상담/의사소통 및 심리상담, 운동 및 재활상담/간병정보 제공",
            "- 전국 케어전문시설 안내 ",
            "- 제휴 케어전문시설 안내 ",
          ],
        },
      ],
    },
    {
      title: "연금 개시 후 케어매니저 방문교육",
      subTitle:
        "전문 케어매니저(간호사 또는 사회복지사)가 직접 방문하여 개인별 맞춤형 케어플랜을 제공해 드립니다. (장기간병상태 발생 후 최대 3회 제공해 드립니다.)",
      contents: [
        {
          texts: [
            "- 고객의 건강상태, 주거·가족 환경에 맞는 간병방법, 운동요법, 간병기기 사용법 등에 대한 교육 및 자료 제공",
            "- 간병대상자와의 의사소통 방법 및 가족에 대한 정서적 상담",
            "- 간병관련 기관 및 간병용품 안내",
          ],
        },
        {
          title: "케어매니저 방문교육 신청방법",
          texts: [
            `<span class="fs-18 fw-7">신청대상</span>`,
            "중증치매 및 일상생활장해로 해당분야 전문의의 진단을 받은 자로 계약일로부터 일상생활장해는 90일, 중증치매는 만 2년이 경과한 자",
            "<br/>",
            `<span class="fs-18 fw-7">신청절차</span>`,
            "- 실버케어스테이션으로 전화 02-732-3790 또는 인터넷 (www.kyobosilvercare.com) 신청",
            "- 자세한 내용은 실버케어스테이션으로 문의바랍니다.",
          ],
        },
      ],
    },
  ],
  cancer: [
    {
      title: "이용방법",
      button: { text: "꼭 읽어보세요!", modalId: "modal-kb-products" },
      subTitle: "어떻게 해야 서비스를 받을 수 있나요?",
      contents: [
        {
          texts: [
            "- 교보 암케어서비스 콜센터(1588-1658)를 통해서 서비스 이용 가능합니다.",
            "- 교보암케어서비스(기본제공형)는 교보생명이 정한 서비스 신청서(개인정보 수집·이용·제공 동의서)를 제출하여 서비스 대상자임을 확인 받으신 뒤 서비스 이용이 가능하십니다.(보험유지건에 한함) ",
            "- 교보암케어서비스(서비스선택형)는 고객 의사에 따라 선택하여 이용 할 수 있는 유상서비스로 보험금 지급부터 2년 이내에 각각 서비스를 보험금 이내에서 선택하여 비용지불 후 이용 가능합니다. ",
          ],
        },
      ],
    },
    {
      title: "기본제공형",
      subTitle: "서비스 동의 고객에게 무상 제공 서비스",
      contents: [
        {
          table: {
            // caption: "진료과별 상담 내용안내",
            columns: [{ text: "서비스 항목" }, { text: "서비스내용" }],
            cols: {
              th: [3, 9],
              td: [3, 9],
            },
            class: {
              td: ["flex-center", ""],
            },
            rows: [
              [
                { text: "암 상담/예약 서비스" },
                {
                  text:
                    "암 발생시 간호사, 전문의 등 전문가와의 상담으로 암 치료 병원/의료진 안내를 제공해 드립니다.",
                },
              ],
            ],
          },
        },
      ],
    },
    {
      title: "서비스 선택형",
      subTitle:
        "고객의 필요에 따라 보험금 이내에서 고객이 직접 서비스를 선택하고 이용료를 지불하는 서비스",
      notice:
        "아래  명시된 이용료는 2022년12월31일까지 적용되는 가격이며 대내외 의료환경 및 제공환경 변화에 따라 인상 또는 인하 될 수 있습니다.",
      contents: [
        {
          table: {
            columns: [
              { text: "서비스 항목" },
              { text: "서비스내용" },
              {
                text: `이용료(고객부담)<br/><span class="red fs-15">*2023년12월 제공 기준</span>`,
              },
            ],
            cols: {
              th: [3, 6, 3],
              td: [3, 6, 3],
            },
            class: {
              td: ["flex-center", "", "flex-center"],
            },
            rows: [
              [
                { text: "암 상담/예약 서비스" },
                {
                  text:
                    "암 발생시 간호사, 전문의 등 전문가와의 상담으로 암 치료 병원/의료진 안내를 제공해 드립니다.",
                },
                { text: "15만원(1년)" },
              ],
              [
                { text: "간호사병원동반서비스" },
                {
                  text:
                    "암 전문 간호사의 밀착 병원진료 동반 (진료, 검사 및 입·퇴원 등 병원내 수속 에스코트, 항암/방사선 등 치료교육, 식이요법, 복약안내 등)",
                },
                { text: "25만원(1회)" },
              ],
              [
                { text: "암극복심리상담서비스" },
                {
                  text:
                    "암 환자의 심리적 불안 해소, 정서적 안정, 회복을 도와드리는 화상심리상담 (상담센터 방문 없이 화상으로 상담 진행)",
                },
                { text: "10만원(화상 50분)" },
              ],
              [
                { text: "간병인지원서비스" },
                {
                  text:
                    "암 치료 중 병원에서의 간병이 필요할 때 ‘간병인 매칭부터 최적의 간병까지’ 원스톱 간병지원 (연속된 5일 이상 간병을 기본으로 함)",
                },
                { text: "13만원(1일)" },
              ],
              [
                { text: "암식단케어서비스" },
                {
                  text:
                    "수술 후 회복 단계, 항암, 방사선 치료 등에서 암 환자를 위한 영양적으로 엄선된 적합한 식단 제공 (고객 주소지로 배송, 12식을 기본으로 함)",
                },
                { text: "9만원(12식)" },
              ],
              [
                { text: "암특화종합건강검진서비스" },
                {
                  text:
                    "암표지자검사(암 가족력 점검)와 상위 레벨의 건강검진을 결합한 종합건강검진 (암 환자 가족 대상의 건강검진 서비스)",
                },
                { text: "60만원(1회)" },
              ],
              [
                { text: "가사도우미서비스" },
                {
                  text:
                    "암 치료 및 회복 과정을 지원하는 가사지원 (가사도우미 파견, 1회 4시간을 기본으로 함)",
                },
                { text: "5만원(4시간)" },
              ],
            ],
          },
        },
        {
          texts: [
            "※ 상기 서비스는 대내외 의료 환경 및 제공 환경의 변화에 따라 서비스 항목 및 내용, 서비스 이용 가격(고객 구매비용)이 변경될 수 있음",
          ],
        },
      ],
    },
  ],
};
