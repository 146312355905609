<template>
  <!-- 전국협력 병원 모달 -->
  <b-modal
    :id="modalId"
    size="xl"
    :hide-footer="true"
    @shown="shownCb"
    @hide="hideCb"
    body-class="p-0 vh-100"
    header-class="p-0 d-block border-bottom-0"
    scrollable
    centered
    no-close-on-backdrop
  >
    <!-- 모달 헤더 -->
    <template #modal-header>
      <div class="px-4 px-md-5 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-start">
          <b-btn
            v-if="privacy"
            :style="{ marginLeft: '-15px' }"
            variant="link"
            @click="close"
            ><img src="~@/assets/img/common/ic-header-navigation.svg" alt="arrow"
          /></b-btn>
          <b-btn v-if="isMap" :style="{ marginLeft: '-15px' }" variant="link" @click="back"
            ><img src="~@/assets/img/common/ic-header-navigation.svg" alt="arrow"
          /></b-btn>
          <h3 v-if="!isMap" class="fs-24 fw-7">
            전국 협력병원
          </h3>
          <h3 v-else class="fs-24 fw-7">
            지도보기
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->

        <!-- 모달 닫기 버튼 -->
        <b-btn variant="link" @click="$bvModal.hide(modalId)" :style="{ marginRight: '-15px' }">
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: calcHeight('3rem'),
              paddingBottom: calcHeight('3rem'),
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>

      <!-- 모달 텝 -->
      <template v-if="isShow && !isMap">
        <modal-tab :items="tabs" :offset="30" :isScroll="true" :scrollspyId="modalId"></modal-tab>
      </template>
      <!-- // 모달 텝 -->
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div id="modal-body" class="h-100">
      <hospitals v-if="!isMap" :tabs="tabs" class="px-4 px-md-5" @isMapChange="isMap = true" :hideMap="hideMap"></hospitals>
      <hospital-detail v-else></hospital-detail>
    </div>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
import ModalTab from "@/components/common/modal/modalComponents/ModalTab";
import Hospitals from "@/components/common/modal/modalComponents/Hospitals";
import HospitalDetail from "@/components/common/modal/modalComponents/HospitalDetail";
export default {
  components: { ModalTab, Hospitals, HospitalDetail },
  props: {
    modalId: {
      type: String,
      default: "modal-hospitals",
    },
    hideMap: {
      type: Boolean,
      default: false,
    },
    privacy: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      tabs: [
        { title: "서울", keys: ["02"] },
        { title: "인천/경기", keys: ["031", "032"] },
        { title: "대전/충청", keys: ["042", "041", "043", "044"] },
        { title: "부산/경상", keys: ["051", "052", "053", "054", "055"] },
        { title: "광주/전라", keys: ["061", "062", "063"] },
        { title: "강원/제주", keys: ["033", "064"] },
      ],
      isShow: false,
      isMap: false,
    };
  },
  computed: {},
  methods: {
    back() {
      this.isMap = false;
    },
    shownCb() {
      this.isShow = true;
    },
    hideCb() {
      this.isShow = false;
      this.isMap = false;
      this.$router.replace({ ...this.$route, query: {} }).catch(() => {});
    },
    close() {
      this.$bvModal.hide('modal-hospitals-policy');
    }
  },
};
</script>
