<template>
  <div class="hc-select">
    <b-dropdown
      variant="link"
      toggle-class="text-decoration-none black flex-center"
      menu-class="rounded-10 fs-20 fs-md-16"
      no-caret
      @hide="hanldeHide"
      @show="hanldeShow"
    >
      <template #button-content variant="link">
        <hash-title
          title-class="pl-1 fw-7 mr-2"
          :isHash="isHash"
          :title="selected.text"
          :titleClass="inputClass"
        >
        </hash-title>
        <span
          ><img
            src="~@/assets/img/common/ic-header-navigation.svg"
            alt="arrow"
            :style="{ transform: 'rotate(270deg)', width: '2.5rem' }"
        /></span>
      </template>
      <b-dropdown-item-button
        v-for="(row, idx) in lists"
        variant="link"
        :value="row.value"
        :key="idx"
        @click="handleClick(idx, row)"
        >{{ row.text }}</b-dropdown-item-button
      >
    </b-dropdown>
    <slot />
  </div>
</template>

<script>
import common from "@/mixins/common.js";
export default {
  mixins: [common],
  props: {
    lists: {
      type: Array,
      default: () => [{ value: "", text: "" }],
    },
    isHash: {
      type: Boolean,
      default: true,
    },
    inputClass: { default: () => "fs-40", type: String },
  },
  data() {
    return {
      isShow: false,
      selected: this.lists[0],
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  watch: {
    lists() {
      this.selected = this.lists[0];
      this.$emit("input", this.selected);
    },
  },
  methods: {
    handleClick(idx, row) {
      const query = this.query;
      const subTitle = this.subTab.items.filter((tab) => tab.value === query.tab)[0].text;
      
      this.clickTrack(row.text, subTitle);
      this.selected = this.lists[idx];
      this.$emit("input", this.selected);
    },
    hanldeShow() {
      this.isShow = true;
    },
    hanldeHide() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss">
.hc-select {
  .dropdown-menu {
    left: -5% !important;
    min-width: auto !important;
    max-height: 200px !important;
    padding-right: 2rem !important;
    overflow-y: scroll;
    z-index: 10;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: inherit !important;
    text-decoration: none;
    background-color: inherit !important;
  }
}
</style>
