<template>
  <b-container>
    <b-row>
      <b-col>
        <b-skeleton-img></b-skeleton-img>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col cols="9">
        <b-skeleton :animation="null" width="70%" height="2.25rem"></b-skeleton>
        <b-skeleton :animation="null" width="40%" height="1.1rem"></b-skeleton>
      </b-col>
      <b-col cols="3" class="d-flex justify-content-end">
        <b-skeleton :animation="null" width="3rem" height="3rem"></b-skeleton>
      </b-col>
    </b-row>
    <template v-if="isList">
      <b-row v-for="i in 5" :key="i">
        <b-col cols="3">
          <b-skeleton :animation="null" height="2.25rem"></b-skeleton>
        </b-col>
        <b-col>
          <b-skeleton :animation="null" height="10rem"></b-skeleton>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
export default {
  props: {
    isList: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
