<template>
  <div
    :style="{
      position: 'sticky',
      left: 0,
      top: 0,
      zIndex: 35,
    }"
  >
    <!-- 헤더 -->
    <section
      class="bg-white vw-99 position-relative"
      :class="isTalk ? 'display-none' : ''"
      id="header"
      :style="{
        zIndex: 35,
      }"
    >
      <b-container
        :style="{
          transition: '0.8s',
        }"
      >
        <div class="py-3 pt-md-4 pb-md-3">
          <!-- pc -->
          <div class="d-none d-md-flex flex-between align-items-end ">
            <!-- 로고 -->
            <div class="dflex flex-start">
              <h1>
                <router-link :to="{ name: 'home', params: { type } }" class="fs-24 fw-7 btn btn-link d-flex align-items-end theme-color-1">
                  <span
                    class="bg-img img-contain"
                    :style="{
                      backgroundImage: `url(${require('@/assets/img/common/logo.svg')}`,
                      width: calcHeight('6.25rem'),
                      paddingBottom: calcHeight('3.125rem'),
                      marginBottom: '0.375rem',
                    }"
                  >
                  </span>
                  <span class="flex-grow-1">{{ $t("logo") }}</span>
                </router-link>
              </h1>
            </div>
            <!-- 로고 -->

            <!-- 네비게이션 -->
            <div class="flex-end align-items-end" :style="{ marginBottom: '2px' }">
              <!-- 로그인 -->
              <template v-if="!loggedIn">
                <b-btn variant="link" class="mr-4 flex-center blue fw-7 fs-15" @click="login">
                  <div
                    class="bg-img mr-2"
                    :style="{
                      backgroundImage: `url(${require('@/assets/img/common/ic-user.svg')})`,
                      width: calcHeight('1.875rem'),
                      paddingBottom: calcHeight('1.875rem'),
                    }"
                  ></div>
                  <span class="d-block">로그인</span>
                </b-btn>
              </template>
              <!-- // 로그인 -->

              <!-- 로그아웃 -->
              <template v-else>
                <b-btn variant="link" class="mr-4 flex-center blue fw-7 fs-15" @click="logout">
                  <div
                    class="bg-img mr-2"
                    :style="{
                      backgroundImage: `url(${require('@/assets/img/common/ic-logout.svg')})`,
                      width: calcHeight('1.875rem'),
                      paddingBottom: calcHeight('1.875rem'),
                    }"
                  ></div>
                  <span class="d-block">로그아웃</span>
                </b-btn>
              </template>
              <!-- // 로그아웃 -->

              <!-- 내 서비스 내역 -->
              <b-dropdown
                v-if="typeof $t('myServices') === 'object' && loggedIn"
                no-caret
                variant="link"
                toggle-class="mr-4 flex-center d-flex blue fw-7 reset-btn"
                menu-class="fs-16"
              >
                <template #button-content>
                  <div
                    class="bg-img mr-2"
                    :style="{
                      backgroundImage: `url(${require('@/assets/img/common/ic-book-open.svg')})`,
                      width: calcHeight('1.875rem'),
                      paddingBottom: calcHeight('1.875rem'),
                    }"
                  ></div>
                  <span class="fs-15">내 서비스 내역</span>
                </template>
                <b-dropdown-item v-for="row in $t('myServices')" :key="row.title" :to="{ name: row.name, params: row.params, query: row.query }">{{
                  row.title
                }}</b-dropdown-item>
              </b-dropdown>
              <!-- // 내 서비스 내역 -->

              <!-- 사이드바 메뉴 버튼 -->
              <b-btn variant="link" class="flex-center blue fw-7 fs-15" v-b-toggle.side-bar-menu>
                <div
                  class="bg-img mr-2"
                  :style="{
                    backgroundImage: `url(${require('@/assets/img/common/ic-menu.svg')})`,
                    width: calcHeight('1.875rem'),
                    paddingBottom: calcHeight('1.875rem'),
                  }"
                ></div>
                <span class="d-block">메뉴</span>
              </b-btn>
              <!-- // 사이드바 메뉴 버튼 -->
            </div>
            <!-- // 네비게이션 -->
          </div>
          <!-- // pc -->

          <!-- mobile -->
          <div class="d-flex d-md-none flex-between">
            <!-- 로그인 -->
            <b-btn variant="link" class="flex-center blue fw-7 fs-15" @click="!loggedIn ? login() : logout()">
              <div
                class="bg-img"
                :style="{
                  ...(!loggedIn && {
                    backgroundImage: `url(${require('@/assets/img/common/ic-user.svg')})`,
                  }),
                  ...(loggedIn && {
                    backgroundImage: `url(${require('@/assets/img/common/ic-logout.svg')})`,
                  }),

                  width: '1.875rem',
                  paddingBottom: '1.875rem',
                }"
              ></div>
            </b-btn>
            <!-- // 로그인 -->

            <!-- 로고 -->
            <div class="dflex flex-start">
              <h1>
                <router-link :to="{ name: 'home', params: { type } }" class="fs-24 fw-7 btn btn-link d-flex align-items-end theme-color-1">
                  <span class="flex-grow-1">{{ $t("logo") }}</span>
                </router-link>
              </h1>
            </div>
            <!-- 로고 -->

            <!-- 사이드바 메뉴 버튼 -->
            <b-btn variant="link" class="flex-center blue fw-7 fs-15" v-b-toggle.side-bar-menu>
              <div
                class="bg-img"
                :style="{
                  backgroundImage: `url(${require('@/assets/img/common/ic-menu.svg')})`,
                  width: calcHeight('1.875rem'),
                  paddingBottom: calcHeight('1.875rem'),
                }"
              ></div>
            </b-btn>
            <!-- // 사이드바 메뉴 버튼 -->
          </div>
        </div>
      </b-container>
      <div class="position-relative border-bottom-linear"></div>
    </section>
    <!-- // 헤더 -->
    <section
      id="head-room"
      class="w-100 position-absolute"
      :style="{
        left: 0,
        ...(headerHeight && { top: `${headerHeight}px` }),
        transition: 'transform 0.5s',
        ...(!isOnPined && {
          transform: `translateY(calc(-100% + ${subTabSectionHeight}px)`,
        }),
      }"
    >
      <section>
        <!-- 헤더 텝 -->
        <section v-if="headerTab && headerTab.length !== 1" class="position-relative overflow-hidden bg-white">
          <header-tab></header-tab>
        </section>
        <!-- //헤더 텝 -->

        <!-- 배너 -->
        <section
          v-if="title"
          class="text-center white bg-img flex-center position-relative"
          :style="{
            backgroundImage: `url(${require('@/assets/img/common/banner.png')}`,
            paddingBottom: calcHeight('7rem'),
          }"
        >
          <div
            class="cover w-100 h-100 position-absolute"
            :style="{
              top: 0,
              left: 0,
              zIndex: 1,
              opacity: '0.7',
              backgroundColor: '#012965',
            }"
          ></div>
          <div
            class="w-100 h-100 flex-center position-absolute"
            :style="{
              top: 0,
              left: 0,
              zIndex: 2,
            }"
          >
            <h3 class="fs-24 fw-7">
              {{ title }}
            </h3>
          </div>
        </section>
        <!-- //배너 -->
      </section>

      <!-- 서브 텝 -->
      <section v-if="subTab" id="sub-tab-section" class="shadow bg-white">
        <h4 v-if="$route.name === 'check-therapy'" class="fw-7 fs-24 fs-md-30 text-center pt-4 pt-md-5">
          <span v-if="id === 'music'">마음건강에 도움이 되는 음악</span>
          <span v-else>심리적 안정을 유도하는 ASMR</span>
        </h4>
        <sub-tab :style="{ paddingTop: calcHeight('2.2rem') }" :offset="gbHeight"></sub-tab>
      </section>
      <!-- // 서브 텝 -->
    </section>
    <!-- 사이드바 -->
    <side-menu></side-menu>
    <!-- //사이드바 -->
  </div>
</template>

<script>
import SideMenu from "@/components/common/SideMenu";
import HeaderTab from "@/components/common/HeaderTab";
import SubTab from "@/components/common/SubTab";
import { throttle } from "lodash";
import common from "@/mixins/common";
import headRoom from "@/mixins/headRoom";
import { mapState } from "vuex";
import { showToast } from "@/plugins/custom-toast";
import { getCookie } from "@/utils/cookies";

export default {
  mixins: [common, headRoom],
  components: {
    SideMenu,
    HeaderTab,
    SubTab,
  },
  data() {
    return {
      headRoomHeight: null,
      headerHeight: null,
      subTabSectionHeight: null,
      isTalk: getCookie({ key: "talk" })
    };
  },
  computed: {
    ...mapState("auth", ["loggedIn"]),
    // 총 헤더 높이
    gbHeight() {
      if (!this.headRoomHeight && !this.headerHeight) return 0;
      return this.headRoomHeight + this.headerHeight + 40;
    },
  },
  watch: {
    // 라우터 이동하면 offset init
    $route() {
      this.offsetInit();
    },
  },
  methods: {
    async login() {
      window.location.href = "https://www.kyobo.com";
    },
    logout() {
      // Show a alert modal
      this.$root.$emit(
        "showModalAlert",
        "로그아웃하시겠습니까?",
        true,
        // If Ok's Button clicked, callBack function call
        this.logoutCb
      );
    },
    logoutCb() {
      try {
        this.$store.dispatch("auth/logout");
        showToast({ title: "로그아웃 되었습니다." });

        this.$router.push({ name: "home", params: { type: "kyoboservices" } }).catch((e) => {});
      } catch (err) {
        showToast({
          title: "관리자에게 문의 해주세요.",
          variant: "warning",
        });
      }
    },
    offsetInit() {
      this.$nextTick(() => {
        this.headRoomHeight = document.getElementById("head-room")?.offsetHeight;
        this.headerHeight = document.getElementById("header")?.offsetHeight - 3;
        this.subTabSectionHeight = document.getElementById("sub-tab-section")?.offsetHeight || 0;
        this.$emit("setOffset", this.headRoomHeight);
      });
    },
  },
  mounted() {
    this.offsetInit();
    // 사이즈 변경시 offset init
    window.addEventListener("resize", throttle(this.offsetInit, 500));
  },
  beforeDestroy() {
    // Remove eventlistener
    window.removeEventListener("resize", throttle(this.offsetInit, 500));
  },
};
</script>
<style lang="scss" scoped>
.display-none {
  display: none !important;
}
</style>
