<template>
  <div class="d-inline-block">
    <h4
      :class="[titleClass, 'position-relative d-inline-flex hash-title']"
      :style="{
        ...styles,
        zIndex: 2,
      }"
    >
      <span v-if="isHash">#</span>
      <span v-html="title"></span>
    </h4>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    isHash: {
      type: Boolean,
      default: true,
    },
    titleClass: {
      type: String,
      default: "fs-22 fw-7",
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.hash-title {
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 40%;
    background: var(--theme-color-1);
    z-index: -1;
    opacity: 0.3;
  }
}
</style>
