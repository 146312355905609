<template>
  <b-modal
    id="modal-policy"
    size="lg"
    :hide-footer="true"
    body-class="p-0"
    centered
    header-class="p-0 d-block border-bottom-0"
    scrollable
    @hide="target = 'depth-0'"
    no-close-on-backdrop
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="mx-4 mx-md-5 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-start">
          <b-btn
            v-if="target !== 'depth-0'"
            :style="{ marginLeft: '-15px' }"
            variant="link"
            @click="
              target = 'depth-0';
              modalBody.scrollTo(0, 0);
            "
            ><img src="~@/assets/img/common/ic-header-navigation.svg" alt="arrow"
          /></b-btn>
          <h3 class="fs-24 fw-7">
            개인정보처리방침
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->
        <!-- 모달 닫기 버튼 -->
        <b-btn variant="link" @click="close()" :style="{ marginRight: '-15px' }">
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="mx-4 mx-md-5 mb-4 mb-md-5">
      <p class="gray fs-20 fs-md-18" v-html="targetPolicy.p"></p>
      <ul>
        <!-- 첫번째 뎁스 -->
        <li v-for="(row, i) in targetPolicy.row" :key="`${row.title}-${i}`" class="mb-4">
          <p class="fs-20 fw-7 mb-1">{{ row.title }}</p>
          <!-- 두번째 뎁스 -->
          <ul v-if="row.contents">
            <li v-for="(_row, _i) in row.contents" :key="`child-${_i}`" class="fs-20 fs-md-18">
              <!-- 일반 텍스트 일때 -->
              <div v-if="_row.text" v-html="_row.text"></div>
              <!-- 테이블 일떄 -->
              <template v-else-if="_row.table">
                <policy-table :tableObj="_row.table"></policy-table>
              </template>
            </li>
          </ul>
        </li>
        <template v-if="target === 'depth-0'">
          <li>
            <b-btn
              variant="link"
              @click="
                target = 'depth-1';
                modalBody.scrollTo(0, 0);
              "
              :style="{'text-align': 'left'}"
              >[이전 개인정보처리방침 보기 (2022. 01. 07 ~ 2022. 09. 18버전)]</b-btn
            >
          </li>
          <li>
            <b-btn
              variant="link"
              @click="
                target = 'depth-2';
                modalBody.scrollTo(0, 0);
              "
              :style="{'text-align': 'left'}"
              >{{
                !["kyoboseniorcare"].includes($i18n.locale)
                  ? "[이전 개인정보처리방침 보기 (2019. 01.30 ~ 2022.01.06버전)]"
                  : "[이전 개인정보처리방침 보기 (2019. 05.01 ~ 2022.01.06 버전)]"
              }}</b-btn
            >
          </li>
          <li v-if="!['kyoboseniorcare'].includes($i18n.locale)">
            <b-btn
              variant="link"
              @click="
                target = 'depth-3';
                modalBody.scrollTo(0, 0);
              "
              :style="{'text-align': 'left'}"
              >{{
                !["kyobojuniorcare"].includes($i18n.locale)
                  ? "[이전 개인정보처리방침 보기 (2017. 12. 04 ~ 2019. 01. 29버전)]"
                  : "[이전 개인정보처리방침 보기 (2019. 08. 07 ~ 2019. 01. 29버전)]"
              }}</b-btn
            >
          </li>
          <li v-if="!['kyobojuniorcare', 'kyoboseniorcare'].includes($i18n.locale)">
            <b-btn
              variant="link"
              @click="
                target = 'depth-4';
                modalBody.scrollTo(0, 0);
              "
              :style="{'text-align': 'left'}"
              >[이전 개인정보처리방침 보기 (2017. 08. 25~2017. 12. 03버전)]</b-btn
            >
          </li>
        </template>
      </ul>
    </div>
    <modal-hospitals :modalId="'modal-hospitals-policy'" :hideMap="true" :privacy="true"></modal-hospitals>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
import PolicyTable from "@/components/common/PolicyTable";
import ModalHospitals from "@/components/common/modal/ModalHospitals";

export default {
  components: {
    PolicyTable,
    ModalHospitals,
  },
  computed: {
    targetPolicy() {
      return this.$t("policy")[this.target];
    },
    modalBody() {
      return document.querySelector(`#modal-policy___BV_modal_body_`);
    },
  },
  data() {
    return {
      target: "depth-0",
    };
  },
};
</script>
