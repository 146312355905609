<template>
  <b-modal id="address-modal" hide-footer @shown="showCb" centered>
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-2 flex-between w-100">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-start">
          <h3 class="fs-24 fw-7 mt-1">
            주소 검색
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!-- 주소 찾기 -->
    <div id="wrap" :style="{ width: 'auto', height: '40vh' }"></div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      elementWrap: null,
      addrInfo: {
        addr: "",
        postCode: "",
      },
    };
  },
  methods: {
    showCb() {
      this.elementWrap = document.getElementById("wrap");
      this.sample3_execDaumPostcode();
    },
    sample3_execDaumPostcode() {
      // 현재 scroll 위치를 저장해놓는다.
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new daum.Postcode({
        oncomplete: (data) => {
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let addr = ""; // 주소 변수
          let extraAddr = ""; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          data.userSelectedType === "R"
            ? // 사용자가 도로명 주소를 선택했을 경우
              (addr = data.roadAddress)
            : // 사용자가 지번 주소를 선택했을 경우(J)
              (addr = data.jibunAddress);

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          this.addrInfo.postCode = data.zonecode;
          this.addrInfo.addr = addr + extraAddr;

          this.$emit("sendAddr", this.addrInfo);
          this.$bvModal.hide("address-modal");

          this.addrInfo = {
            addr: "",
            postCode: "",
          };

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },
        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        width: "100%",
        height: "100%",
      }).embed(this.elementWrap);

      // iframe을 넣은 element를 보이게 한다.
      this.elementWrap.style.display = "block";
    },
  },
};
</script>
