import policy from "@/lib/policy";

const type = "kyoboseniorcare";
export default {
  color: ["#999bdf", "#3667cd", "#a9abf11a"],
  // logo
  logo: "교보치매케어서비스",
  call: "1811-7002",
  // gnb
  gnb: [
    {
      id: "service",
      text: "서비스 안내/신청",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "info",
      text: "건강정보",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
  ],
  // headerTabs

  headerTabs: {
    service: [
      {
        text: "평상시 치매예방",
        name: "service",
        key: "prevention",
        type,
      },
      {
        text: "경도 치매케어",
        name: "service",
        key: "mild-care",
        type,
      },
      {
        text: "중등도 치매케어",
        name: "service",
        key: "moderate-care",
        type,
      },
      {
        text: "중증 치매케어",
        name: "service",
        key: "severe-care",
        type,
      },
    ],
    info: [
      {
        text: "기획기사",
        name: "info-news",
        key: "health-news",
        type,
      },
    ],
  },

  // --- home
  visual: [
    {
      title: "평상시 치매예방",
      subTitle: [`평상시 건강관리부터 연령별 치매예방관리로<br class="d-md-none"/> 치매걱정 없이 건강하게`],
      to: "service",
      key: "prevention",
    },
    {
      title: "경도 치매케어 CDR1",
      subTitle: [`믿을 수 있는 전문가의 도움으로<br class="d-md-none"/> 치매 초기 진단에도 든든하게`],
      to: "service",
      key: "mild-care",
      hash: "#sub-tab-1",
    },
    {
      title: "중등도 치매케어 CDR2",
      subTitle: [`치매치료를 위한 의료기관 이동시에도<br class="d-md-none"/> 신속하고 편안하게`],
      to: "service",
      key: "moderate-care",
      hash: "#sub-tab-2",
    },
    {
      title: "중증 치매케어 CDR3",
      subTitle: [`중증 치매진단에도 가족간병 지원을 통해<br class="d-md-none"/> 언제 어디서나 걱정없게`],
      to: "service",
      key: "severe-care",
      hash: "#sub-tab-3",
    },
  ],
  healthInfo: {
    title: "건강정보",
    subTitle: "다양한 건강정보를 확인하고 건강한 삶을 지켜보세요.",
    items: [
      {
        text: "기획기사",
        name: "info-news",
        key: "health-news",
      },
    ],
  },
  prevention: [
    {
      title: "평상시 치매예방",
      subTitle: "평상시 건강관리부터 연령별 치매예방관리로 치매걱정 없이 건강하게",
      contents: [
        {
          title: "건강생활 영양컨설팅",
          texts: [
            "전문 영양사가 무엇을 어떻게 먹을지에 대한 자세한 상담 제공",
            "<br/>",
            "- 현재 건강상태와 영양습관에 대한 파악 후 맞춤 영양소와 음식 추천",
            "- 원하실 경우 건강기능식품 추천 상담 진행 ",
          ],
        },

        {
          title: "간병인 매칭",
          texts: [
            "간병인 필요 시 제휴업체를 통해 간병인 안내서비스 제공",
            "<br/>",
            "- 지역/병원 별 문의 가능한 간병업체 정보를 제공",
            "- 간병인 구인 시 주의사항, 간병업무 범위 등의 설명 상담",
          ],
        },
        {
          title: "AI스피커 지원 or 위치추적기 지원 or 화상심리상담 (3종 중 택1)",
          texts: [
            "경도치매 (CDR 1) 진단 시 도움이 되는 기기 지원 혹은 서비스를 통하여 적절한 조력 제공",
            "<br/>",
            " - 실내외 위치 추적기 지원 (월 사용료 개인부담)",
            " - 화상심리상담(2회) : 본인 또는 가족",
            " - AI스피커 지원 (월사용료/통신료 개인부담)",
            "<br/>",
            "※ 제공 기기는 통신환경 및 제품 수급 문제, 서비스 제공환경 등에 따라 재선정 및 조정될 수 있음",
            "※ 단계별 치매케어서비스는 가입하신 보험상품의 치매보장개시일(2년) 이후, 치매보험금 지급사유 발생 시 제공",
          ],
        },
        {
          title: "전문 의료진 건강상담",
          texts: [
            "사소한 궁금증부터 건강에 대한 고민이 생겼을 때,",
            "<br/>",
            " - 간호사 상담접수 후 신경과, 정신건강의학과, 내과 등 14개과 전문의 전화상담",
            " - 임상경험이 풍부한 간호사, 임상영양사, 건강운동관리사 건강상담",
          ],
        },
        {
          title: "심리상담",
          texts: [" - 우울, 불안 등 전문 심리상담사와 전화상담", " - 전국 심리케어센터 제휴를 통한 심리검사 및 상담치료 우대(고객부담)"],
        },
        {
          title: "건강검진 우대",
          texts: [
            "아프기 전에 미리 건강을 체크할 수 있도록, 건강검진 우대 및 예약 대행",
            "<br/>",
            " - 전국 100여개 건강검진 네트워크를 통한 건강검진 안내 및 우대가 제공(고객부담)",
            " - 개인별 맞춤 건강검진 설계 ",
            " - 건강검진 결과 제공 시 결과 상담 가능",
            " - 이상징후 발견 시 병원안내 및 진료예약 대행",
          ],
          button: { text: "전국 협력병원", modalId: "modal-hospitals-compare" },
        },
        {
          title: "병원안내 & 진료예약",
          texts: [
            "질병이 의심될 때, 치료가 필요할 때 병원 및 의료진 안내, 진료예약 제공",
            "<br/>",
            " - 증상에 따른 상담 및 진료과 안내",
            " - 질병진료 및 치료를 위한 병원•의료진 안내",
            " - 진료 예약대행",
          ],
        },
        {
          title: "치매안심체크",
          texts: [
            "- 치매 예방 및 건강체크를 위한 문진 발송(LMS, 연 1회)",
            "- 치매상담간호사가 전화상담을 통해 치매 및 건강 위험요인을 스크리닝(문진 응답 시)",
            "- 필요시 의료기관 안내 및 예약대행",
          ],
        },
        {
          title: "치매안심콜",
          texts: [
            " - 65세 이상 고령층 대상 안부콜 제공(연 1회)",
            " - 치매상담간호사의 정기 안부콜을 통한 치매위험 체크 및 지속적인 건강케어",
            " - 이상 징후 발견 시 병원안내 및 진료 연계",
          ],
        },
      ],
    },
  ],
  mildCare: [
    {
      title: "경도 치매케어 CDR1",
      subTitle: "믿을 수 있는 전문가의 도움으로 치매 초기 진단에도 든든하게",
      contents: [
        {
          title: "치매케어 플래너",
          texts: [
            "치매 진단을 받고, 걱정될 때 믿을 수 있는 전문가가 도와드립니다.",
            "<br/>",
            "- 치매환자에게 필요한 치매치료 및 관리방법 설계",
            "- 치료 · 재활과정에서 필요한 의료 및 요양기관 지원 상담",
            "- 제휴협력을 통한 요양보호사 · 간병인 제휴업체 연계(고객부담)",
          ],
        },
        {
          title: "간병인 지원 or 간호사 병원동반",
          texts: [
            "간병인 지원",
            "보호자의 피로도를 줄이고, 환자의 빠른 회복을 지원합니다.",
            "<br/>",

            "- 경도 치매 진단 후, 치매치료 또는 상해/질병으로 인해 상급종합병원 입원 시 간병인 지원(최대 5일, 1회)",
            "- 고객의 정확한 상태 및 요구도를 정확히 파악하여 적합한 간병인을 선별하여 제공",
            "※ 3영업일 전 신청(중등도 이상 치매의 경우 간병인 지원이 제한될 수 있음)",
            "<br/>",

            "간호사 병원동반",
            "치매 진단을 받고, 걱정될 때 믿을 수 있는 전문가가 도와드립니다.",
            "<br/>",

            "- 경도 이상 치매진단 후 상급종합병원 진료 시 간호사 병원 동반 서비스 제공(2회)",
            "- 진료 시 동반하여 치료과정 전반의 이해를 돕고, 정서적 지지 및 지원",
            "※ 치매관련 질환(신경과·정신과)으로 진료 시 제공",
          ],
        },
        {
          title: "보호자 심리케어",
          texts: [
            "치매환자의 가족 및 보호자의 정서적 안정을 위한 심리케어를 도와드립니다.",
            "<br/>",

            "- 보호자의 심리적 불안, 우울, 스트레스 완화를 위한 전화 심리상담 제공(4회)",
            "- 심리상담사의 가족 방문상담(1회)",
            "※단, 방문장소는 관련시설 및 의료기관에 한함",
          ],
        },

        {
          title: "위치추적기(배회감지기) 지원",
          texts: [
            "치매 환자 실종 사고 방지를 위해 위치추적기(배회감지기)를 지원해드립니다.",
            "<br/>",

            "- 경도 이상 치매진단 후 실•내외 위치 추적기(배회감지기) 지원",
            "※ 위치추적기 월 사용료는 고객부담",
          ],
        },
      ],
    },
  ],
  moderateCare: [
    {
      title: "중등도 치매케어 CDR2",
      subTitle: "치매치료를 위한 의료기관 이동시에도 신속하고 편안하게",
      contents: [
        {
          title: "차량 에스코트",
          texts: [
            "치매 진단 후 큰 병원에 입원하거나 퇴원할 때 편안하게 이동할 수 있도록 도와 드립니다.",
            "<br/>",

            "- 중등도 치매 진단 후 상급종합병원 입원 또는 퇴원시 차량에스코트 지원",
            "※ 단, 보호자 동반 필수 / 50km까지 무상제공, 초과 비용은 고객부담",
            "※ 응급상황, 도서 · 산간지역 서비스 이용 불가 / 최소 2일 전 신청",
          ],
        },

        {
          title: "병원 간 이송",
          texts: [
            "치매 진단 후 입원 치료 중 다른 병원으로 이송이 필요할 때 안전하게 이동할 수 있도록 도와 드립니다.",
            "<br/>",

            "- 중등도 치매 진단 후 병원 간 환자이송 발생시 앰뷸런스 지원(1회)",
            "※ 단, 보호자 동반 필수 / 50km까지 무상제공, 초과 비용은 고객부담",
            "※ 응급상황, 도서 · 산간지역 서비스 이용 불가 / 최소 2일 전 신청",
          ],
        },

        {
          title: "요양기관 입소지원 상담",
          texts: [
            "중등도 치매 진단 후 요양기관 입소지원을 도와드립니다.",
            "<br/>",

            "- 요양기관 안내 상담 지원",
            "- 기관 이용 시 필요사항 안내 및 체크",
          ],
        },
      ],
    },
  ],
  severeCare: [
    {
      title: "중증 치매케어 CDR3",
      subTitle: "중증 치매진단에도 가족간병 지원을 통해 언제 어디서나 걱정없게",
      contents: [
        {
          title: "가사도우미 지원",
          texts: [
            "중증 치매로 장기간 입원 시 가사지원서비스를 통해 간병을 도와드립니다.",
            "<br/>",

            "- 중증 치매로 장기간 입원 시 가사지원 서비스 제공(연 1회 / 4시간)",
            "※ 중증 치매 진단 후 상급종합병원 7일 이상 입원 시 제공",
          ],
        },

        {
          title: "치매환자 24시 모니터링 지원",
          texts: [
            "중증 치매환자 재가요양 시 가정 내 CCTV 설치를 고려 중이시라면, 설치를 지원해 드립니다.",
            "<br/>",

            "- 중증 치매환자 모니터링을 위한 CCTV 필요시 제휴업체 연계 및 설치지원",
            "※ CCTV설치 후 월 사용료는 고객부담",
          ],
        },
      ],
    },
  ],
  policy: {
    "depth-0": {
      p: policy.depth0.p("교보생명 치매케어서비스"),
      row: [
        {
          title: policy.depth0.title_1.title,
          contents: [
            {
              text: policy.depth0.title_1.text_1,
            },
            {
              table: {
                columns: [
                  { text: "구분" },
                  { text: "수집 · 이용 목적" },
                  { style: { minWidth: "600px" }, text: "개인정보 수집 · 이용 항목" },
                  { text: "보유기간" },
                ],
                row: [
                  [
                    { text: "공통(콜센터)", rowspan: "8", class: "bg-green-ligth" },
                    { text: "건강상담", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별, 전화번호 또는 휴대전화번호, 상담정보, 가족관계", class: "text-left" },
                    { text: "위탁계약 종료시 또는 실효후 5년경과시", rowspan: "5" },
                  ],
                  [
                    { text: "진료예약대행 및 사후관리", class: "bg-green-ligth" },
                    {
                      text: "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 진료의뢰서 또는 기타 의무기록 사본, 진단명, 소견내용",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "진료동행서비스", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 상병명, 병기 및 질병진행정도, 치료방법 및 종류, 치료 부작용 여부, 치료 경과(재발 및 전이여부)",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약대행 및 관리", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] (회원) 이름, 생년월일, 성별, 휴대폰번호, 검진희망일, 검진희망기관, 주소, 관심질환, 병력, 가족력, 수술이력여부, 복용약",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약 만족도 조사", class: "bg-green-ligth" },
                    {
                      text: "[필수] 휴대전화번호, 검진받은 기관, 조사내용",
                      class: "text-left",
                    },
                  ],
                ],
              },
            },
            { text: policy.depth0.title_1.text_2 },
            { text: policy.depth0.title_1.text_3 },
            { text: policy.depth0.title_1.text_4 },
            { text: policy.depth0.title_1.text_5 },
          ],
        },
        {
          title: policy.depth0.title_2.title,
          contents: [
            { text: policy.depth0.title_2.text_1 },
            { text: policy.depth0.title_2.text_2 },
            { text: policy.depth0.title_2.text_3 },
            { text: policy.depth0.title_2.text_4 },
            { text: policy.depth0.title_2.text_5 },
          ],
        },
        {
          title: policy.depth0.title_3.title,
          contents: [
            { text: policy.depth0.title_3.text_1 },
            { text: policy.depth0.title_3.text_2 },
            { text: policy.depth0.title_3.text_3 },
            { text: policy.depth0.title_3.text_4 },
            {
              table: {
                columns: [
                  { style: { width: "180px" }, text: "제공받는 자", class: "bg-green-ligth" },
                  { style: { width: "180px" }, text: "제공받는 목적", class: "bg-green-ligth" },
                  { style: { width: "400px" }, text: "제공하는 개인정보의 항목", class: "bg-green-ligth" },
                  { style: { width: "180px" }, text: "보유 및 이용기간", class: "bg-green-ligth" },
                ],
                row: [
                  [
                    { text: "희망 검진예약 기관", button: true },
                    { text: "건강검진 예약 서비스 이용, 검진 전 약품 배송" },
                    { text: "가입기업명, 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 예약일, 검진과목, 주소, 가족관계, 이메일, 검진요청사항	" },
                    { text: "이용 목적 달성 또는 보유기간 경과 시" },
                  ],
                ],
              },
            },
          ],
        },
        {
          title: policy.depth0.title_4.title,
          contents: [
            { text: policy.depth0.title_4.text_1 },
            { text: policy.depth0.title_4.text_2 },
            { table: policy.depth0.title_4.table },
            { text: policy.depth0.title_4.text_3 },
          ],
        },
        {
          title: policy.depth0.title_5.title,
          contents: [
            { text: policy.depth0.title_5.text_1 },
            { text: policy.depth0.title_5.text_2 },
            { text: policy.depth0.title_5.text_3 },
            { text: policy.depth0.title_5.text_4 },
            { text: policy.depth0.title_5.text_5 },
          ],
        },
        {
          title: policy.depth0.title_6.title,
          contents: [{ text: policy.depth0.title_6.text_1 }],
        },
        {
          title: policy.depth0.title_7.title,
          contents: [
            { text: policy.depth0.title_7.text_1 },
            { text: policy.depth0.title_7.text_2 },
            { text: policy.depth0.title_7.text_3 },
            { text: policy.depth0.title_7.text_4 },
          ],
        },
        {
          title: policy.depth0.title_8.title,
          contents: [{ text: policy.depth0.title_8.text_1 }],
        },
        {
          title: policy.depth0.title_9.title,
          contents: [
            { text: policy.depth0.title_9.text_1 },
            { table: policy.depth0.title_9.table },
            { text: policy.depth0.title_9.text_2 },
            { text: policy.depth0.title_9.text_3 },
            { text: policy.depth0.title_9.text_4 },
            { text: policy.depth0.title_9.text_5 },
          ],
        },
        {
          title: policy.depth0.title_10.title,
          contents: [{ text: policy.depth0.title_10.text_1 }, { text: policy.depth0.title_10.text_1 }],
        },
        {
          title: policy.depth0.title_11.title,
          contents: [{ text: policy.depth0.title_11.text_1 }],
        },
        {
          title: policy.depth0.title_12.title,
          contents: [{ text: policy.depth0.title_12.text_1 }, { text: policy.depth0.title_12.text_2 }],
        },
      ],
    },
    "depth-1": {
      p: policy.depth0.p("교보생명 치매케어서비스", "2019년 5월 1일"),
      row: [
        {
          title: policy.depth0.title_1.title,
          contents: [
            { text: policy.depth0.title_1.text_1 },
            {
              table: {
                columns: [
                  { style: { minWidth: "180px" }, text: "제공받는 자" },
                  { style: { minWidth: "180px" }, text: "제공받는 목적" },
                  { text: "제공하는 개인정보의 항목" },
                  { style: { minWidth: "180px" }, text: "보유 및 이용기간" },
                ],
                row: [
                  [
                    { text: "공통(콜센터)", rowspan: "2", class: "bg-green-ligth" },
                    { text: "건강상담", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별, 전화번호 또는 휴대전화번호, 상담정보, 가족관계<br>[선택] 없음", class: "text-left" },
                    { text: "위탁계약 종료시 또는 실효후 5년경과시", rowspan: "2" },
                  ],
                  [
                    { text: "진료예약대행 및 사후관리", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 진료의뢰서 또는 기타 의무기록 사본, 진단명, 소견내용<br>[선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "구분", rowspan: "4", class: "bg-green-ligth", rowspan: "4" },
                    { text: "수집 · 이용 목적", class: "bg-green-ligth" },
                    { text: "개인정보 수집 · 이용 항목", class: "text-left" },
                    { text: "보유기간", rowspan: "4" },
                  ],
                  [
                    { text: "진료동행서비스", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 상병명, 병기 및 질병진행정도, 치료방법 및 종류, 치료 부작용 여부, 치료 경과(재발 및 전이여부)<br>[선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약대행 및 관리", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] (회원) 이름, 생년월일, 성별, 휴대폰번호, 검진희망일, 검진희망기관, 주소, 관심질환, 병력, 가족력, 수술이력여부, 복용약<br>[선택]없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약 만족도 조사", class: "bg-green-ligth" },
                    {
                      text: "[필수] 휴대전화번호, 검진받은 기관, 조사내용<br>[선택] 없음",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "WEB/APP서비스", class: "bg-green-ligth", rowspan: "2" },
                    {
                      text: "보험사 고객 확인",
                      class: "bg-green-ligth",
                    },
                    {
                      text: "[필수] 생년월일, 내외국인, 성별, 이름, 핸드폰번호<br>[선택] 없음",
                      class: "text-left",
                    },
                    { text: "본인 확인 즉시 파기" },
                  ],
                  [
                    { text: "회원가입 및 관리	", class: "bg-green-ligth" },
                    {
                      text: "[필수] 생년월일, 내외국인, 성별, 이름, 핸드폰번호<br>[선택] 없음",
                      class: "text-left",
                    },
                    { text: "회원탈퇴 시 또는 법정 보유기간까지" },
                  ],
                ],
              },
            },
            { text: policy.depth0.title_1.text_2 },
            { text: policy.depth0.title_1.text_3 },
            { text: policy.depth0.title_1.text_4 },
            { text: policy.depth0.title_1.text_5 },
          ],
        },
        {
          title: policy.depth0.title_2.title,
          contents: [
            { text: policy.depth0.title_2.text_1 },
            { text: policy.depth0.title_2.text_2 },
            { text: policy.depth0.title_2.text_3 },
            { text: policy.depth0.title_2.text_4 },
          ],
        },
        {
          title: policy.depth0.title_3.title,
          contents: [
            { text: policy.depth0.title_3.text_1 },
            { text: policy.depth0.title_3.text_2 },
            { text: policy.depth0.title_3.text_3 },
            { text: policy.depth0.title_3.text_4 },
            {
              table: {
                columns: [
                  { style: { minWidth: "180px" }, text: "제공받는 자", class: "bg-green-ligth" },
                  { style: { minWidth: "180px" }, text: "제공받는 목적", class: "bg-green-ligth" },
                  { text: "제공하는 개인정보의 항목", class: "bg-green-ligth" },
                  { style: { minWidth: "180px" }, text: "보유 및 이용기간", class: "bg-green-ligth" },
                ],
                row: [
                  [
                    { text: "희망 검진예약 기관" },
                    { text: "건강검진 예약 서비스 이용" },
                    { text: "가입기업명, 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 예약일, 검진과목, 주소, 가족관계, 이메일, 검진요청사항" },
                    { text: "이용 목적 달성 또는 보유기간 경과 시" },
                  ],
                ],
              },
            },
          ],
        },
        {
          title: policy.depth0.title_4.title,
          contents: [
            { text: policy.depth0.title_4.text_1 },
            { text: policy.depth0.title_4.text_2 },
            {
              table: policy.depth0.title_4.table,
            },
            { text: policy.depth0.title_4.text_3 },
          ],
        },
        {
          title: policy.depth0.title_5.title,
          contents: [
            { text: policy.depth1.title_5.text_1 },
            { text: policy.depth1.title_5.text_2 },
            { text: policy.depth1.title_5.text_3 },
            { text: policy.depth1.title_5.text_4 },
            { text: policy.depth1.title_5.text_5 },
            { text: policy.depth1.title_5.text_6 },
          ],
        },
        {
          title: policy.depth0.title_6.title,
          contents: [{ text: policy.depth0.title_6.text_1 }],
        },
        {
          title: policy.depth0.title_7.title,
          contents: [
            { text: policy.depth0.title_7.text_1 },
            { text: policy.depth0.title_7.text_2 },
            { text: policy.depth0.title_7.text_3 },
            { text: policy.depth0.title_7.text_4 },
          ],
        },
        {
          title: policy.depth0.title_8.title,
          contents: [
            { text: policy.depth0.title_8.text_1 },
            { text: policy.depth0.title_8.text_2 },
            { text: policy.depth0.title_8.text_3 },
            { text: policy.depth0.title_8.text_4 },
          ],
        },
        {
          title: policy.depth0.title_9.title,
          contents: [{ text: policy.depth0.title_9.text_1 }, { table: policy.depth0.title_9.table }],
        },
        {
          title: policy.depth1.title_10.title,
          contents: [{ text: policy.depth1.title_10.text_1 }, { table: policy.depth1.title_10.table }],
        },
        {
          title: policy.depth1.title_11.title,
          contents: [{ text: policy.depth1.title_11.text_1 }, { table: policy.depth1.title_11.table }],
        },
        {
          title: policy.depth1.title_12.title,
          contents: [{ text: "① 이 개인정보 처리방침은 2021. 08. 00부터 적용됩니다." }, { text: policy.depth0.title_10.text_2 }],
        },
        {
          title: policy.depth1.title_13.title,
          contents: [{ text: policy.depth0.title_11.text_1 }],
        },
        {
          title: policy.depth1.title_13.title,
          contents: [
            { text: policy.depth0.title_11.text_1 },
            { text: policy.depth0.title_11.text_2 },
            { text: policy.depth0.title_11.text_3 },
            { text: policy.depth0.title_11.text_4 },
            { text: policy.depth0.title_11.text_5 },
            { text: policy.depth0.title_11.text_6 },
          ],
        },
        {
          title: "14. 개인정보 처리방침 변경",
          contents: [
            { text: "① 이 개인정보 처리방침은 2021. 08. 00부터 적용됩니다." },
            { text: "② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다." },
          ],
        },
      ],
    },
    "depth-2": {
      p: policy.depth1.p("교보치매케어사이트", "2019년 5월 1일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth2.title_4.title,
          contents: [
            { text: policy.depth2.title_4.text_1 },
            { text: policy.depth2.title_4.text_2 },
            { text: policy.depth2.title_4.text_3 },
            { text: policy.depth2.title_4.text_4 },
            { text: policy.depth2.title_4.text_5 },
            { text: policy.depth2.title_4.text_6 },
            { text: policy.depth2.title_4.text_7 },
            { text: policy.depth2.title_4.text_8 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth2.title_8.title,
          contents: [
            { text: policy.depth2.title_8.text_1 },
            { text: policy.depth2.title_8.text_2 },
            { text: policy.depth2.title_8.text_3 },
            { text: policy.depth2.title_8.text_4 },
            { text: policy.depth2.title_8.text_5 },
            { text: policy.depth2.title_8.text_6 },
            { text: policy.depth2.title_8.text_7 },
            { text: policy.depth2.title_8.text_8 },
            { text: policy.depth2.title_8.text_9 },
            { text: policy.depth2.title_8.text_10 },
            { text: policy.depth2.title_8.text_11 },
            { text: policy.depth2.title_8.text_12 },
            { text: policy.depth2.title_8.text_13 },
            { text: policy.depth2.title_8.text_14 },
            { text: policy.depth2.title_8.text_15 },
            { text: policy.depth2.title_8.text_16 },
            { text: policy.depth2.title_8.text_17 },
            { text: policy.depth2.title_8.text_18 },
            { text: policy.depth2.title_8.text_19 },
            { text: policy.depth2.title_8.text_20 },
            { text: policy.depth2.title_8.text_21 },
            { text: policy.depth2.title_8.text_22 },
            { text: policy.depth2.title_8.text_23 },
            { text: policy.depth2.title_8.text_24 },
          ],
        },
      ],
    },
  },
};
