var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"hc-container"},[_c('tab-swiper',{key:_vm.key,ref:"hc-card-swiper",staticClass:"mb-4",attrs:{"items":_vm.items,"isNaviHide":true,"options":{
      spaceBetween: 18,
      slidesPerView: 3,
      breakpoints: {
        400: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2.4,
          spaceBetween: 18,
        },
        994: {
          slidesPerView: 2.5,
          spaceBetween: 18,
        },
      },
    },"mobile":true},scopedSlots:_vm._u([{key:"default",fn:function({ tab }){return [_c('hc-card',_vm._b({key:_vm.items.title,style:([
        ['riskCheck', 'cancer'].includes(_vm.cameledKey)
          ? { minHeight: _vm.calcHeight('19rem', '3vh', '1vw') }
          : { minHeight: _vm.calcHeight('10rem') },
      ]),on:{"handleClick":function($event){return _vm.handleSubmit(tab)}}},'hc-card',{
        ...tab,
        key: tab.title,
        textCenter: false,
      },false))]}}])}),(_vm.key !== 'therapy')?_c('p',{staticClass:"p-4 rounded-10 fs-19 mt-5 d-flex flex-center",style:({ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)' })},[_c('img',{style:({ width: '2rem', height: '2rem', marginRight: '1rem' }),attrs:{"src":require("@/assets/img/common/icon-mark.png"),"alt":"notice"}}),_vm._v(" 자가체크는 의사의 진료를 대신할 수 없습니다. 참고용으로 활용하시기 바랍니다. ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }