var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-btn',{staticClass:"border-bottom border-gray-5 w-100 list",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$emit('handleClick', $event)}}},[_c('b-row',{staticClass:"mx-0 d-none d-md-flex py-3",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"7"}},[_c('div',{staticClass:"fs-18 gray text-truncate title"},[_vm._v(_vm._s(_vm.title))])]),_c('b-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"fs-18 flex-end"},[_c('span',{staticClass:"gray-5"},[_vm._v(_vm._s(_vm.formatedDate))]),_c('span',{class:[
            _vm.isAnswer ? 'white rounded-pill bg-blue-2' : 'blue-2 rounded-pill',
            'ml-4 fs-16 py-2 px-3 border border-blue-2 border-3',
          ]},[_vm._v(" "+_vm._s(_vm.isAnswer ? "답변완료" : "답변대기"))])])])],1),_c('b-row',{staticClass:"d-flex d-md-none mx-0",style:({ height: '5.25rem' }),attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"flex-between",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-left overflow-hidden flex-1-1-0"},[_c('div',{staticClass:"gray-5"},[_vm._v(_vm._s(_vm.formatedDate))]),_c('div',{staticClass:"fs-20 gray text-truncate title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('span',{class:[
          _vm.isAnswer ? 'white rounded-pill bg-blue-2' : 'blue-2 rounded-pill',
          'fs-16 fw-7 p-2 border border-blue-2 border-2',
        ]},[_vm._v(" "+_vm._s(_vm.isAnswer ? "답변완료" : "답변대기"))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }