import request from "@/utils/request";
export default {
  // 유저 토큰 생성
  createToken: (data) =>
    request({
      url: "/auth/token",
      method: "post",
      data,
    }),
  // 테스트 요청
  getMe: (user_id = "9999999999999") =>
    request({
      url: `/test/me/${user_id}`,
      method: "get",
    }),
  // 자동 로그인 토큰 생성
  autoToken: (data) => {
    request({
      url: "/auth/auto",
      method: "post",
      data
    })
  }
};
