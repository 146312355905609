<template>
  <!-- head -->
  <div>
    <p class="gray-5 fs-20 mb-3">{{ date }}</p>
    <div class="mb-4">
      <span class="fs-18 theme-btn bg-theme-color-1 px-3 py-1">{{
        category
      }}</span>
    </div>
    <h4 class="mb-4 fw-7 fs-26" v-html="title"></h4>
    <div class="mb-5">
      <p class="mb-4 gray fs-20" v-html="content"></p>
      <!-- <p class="red fs-18">
        {{
          notice ||
            "※ 자가체크는 의사의 진료를 대신할 수 없습니다. 참고용으로 활용하시기 바랍니다."
        }}
      </p> -->
      <p class="p-4 rounded-10 fs-19 mt-5 d-flex flex-center" :style="{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)' }">
        <img 
          src="~@/assets/img/common/icon-mark.png"
          alt="notice"
          :style="{ width: '2rem', height: '2rem', marginRight: '1rem' }" />
        자가체크는 의사의 진료를 대신할 수 없습니다. 참고용으로 활용하시기
        바랍니다.
      </p>
    </div>
    <div
      v-if="cardTitle && cardContent"
      class="border border-gray-5 p-4 rounded-10 shadow-sm"
    >
      <p>{{ cardTitle }}</p>
      <p v-html="cardContent"></p>
    </div>
  </div>
  <!-- // head -->
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "자가체크 결과",
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    notice: {
      type: String,
      default: "",
    },
    cardTitle: {
      type: String,
      default: "",
    },
    cardContent: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
