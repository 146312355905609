<template>
  <div>
    <h5 class="gray fs-20 fw-7 mb-3">
      나의 생년월일은?
    </h5>
    <div class="flex-start gap-1 mb-5">
      <div v-for="(select, i) in selects" :key="i">
        <hc-select-default
          v-bind="{ ...select }"
          rules="required"
          v-model="data[select.target]"
          :styles="{ minWidth: calcHeight('6rem') }"
          :dropdownClass="
            data[select.target]
              ? 'border border-green rounded-10 p-3 w-100 fs-20'
              : 'border border-black rounded-10 p-3 w-100 fs-20'
          "
          @change="$emit('input', getValueObj)"
        ></hc-select-default>
      </div>
    </div>
    <div class="flex-start gap-3">
      <div v-for="(input, i) in inputs" :key="i">
        <h5 class="gray fs-20 fw-7 mb-3">
          {{ input.questiontitle }}
        </h5>

        <div class="my-3 flex-start">
          <hc-input-default
            type="number"
            rules="max:3|required"
            :name="input.target"
            :styles="{
              maxWidth: calcHeight('6rem'),
              height: calcHeight('3.5rem'),
            }"
            class="black"
            :inputClass="
              data[input.target]
                ? 'border-green rounded-10 p-3 fs-20'
                : 'border-black rounded-10 p-3 fs-20'
            "
            v-model="data[input.target]"
            @change="$emit('input', getValueObj)"
          >
          </hc-input-default>
          <span class="ml-3 fs-20">{{ input.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getYears, getDates } from "@/utils/dayjs";
export default {
  data() {
    return {
      data: {
        birthYear: "",
        birthMonth: "",
        birthDay: "",
        height: "",
        weight: "",
      },
      selects: [
        {
          label: "년",
          items: [...getYears()],
          target: "birthYear",
        },
        {
          label: "월",
          items: Array.from({ length: 12 }, (v, i) => i).map((val, i) => ({
            text: `${i + 1}`,
            value: `${i + 1}`,
          })),
          target: "birthMonth",
        },
        {
          label: "일",
          items: [...getDates()],
          target: "birthDay",
        },
      ],
      inputs: [
        {
          questiontitle: "나의 신장은?",
          label: "cm",
          target: "height",
        },
        {
          questiontitle: "나의 체중은?",
          label: "kg",
          target: "weight",
        },
      ],
    };
  },
  computed: {
    getValueObj() {
      return {
        ...this.data,
        birthYear: this.data.birthYear.value,
        birthMonth: this.data.birthMonth.value,
        birthDay: this.data.birthDay.value,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
