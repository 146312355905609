import policy from "@/lib/policy";
const type = "newkyobohealthcare";
export default {
  color: ["#5d5d5d", "#3667cd", "#5d5d5d1a"],
  // logo
  logo: "교보New헬스케어서비스",
  call: "1588-7524",
  // gnb
  gnb: [
    {
      id: "service",
      text: "서비스 안내/신청",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "check",
      text: "건강체크",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "info",
      text: "건강정보",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "consult",
      text: "전문가 상담실",
      isMenu: true,
      isHeaderTab: true,
      isChildren: true,
    },
    {
      id: "ask",
      text: "자주찾는 질문",
      name: "ask",
      key: "ask",
      isMenu: true,
      isHeaderTab: true,
      type,
    },
    {
      id: "mypage",
      text: "내 서비스 내역",
      isHeaderTab: true,
      isChildren: true,
    },
  ],
  // headerTabs
  headerTabs: {
    service: [
      {
        text: "서비스 소개",
        name: "service",
        key: "info",
        type,
      },
      {
        text: "의료진 건강상담",
        name: "service",
        key: "consultation",
        type,
      },
      {
        text: "병원진료예약",
        name: "service",
        key: "medical-appointment",
        type,
      },
      {
        text: "건강검진 예약대행",
        name: "service",
        key: "book-agent",
        type,
      },
      {
        text: "헬스플래너 방문",
        name: "service",
        key: "visit",
        type,
      },
      {
        text: "심리전화상담 & 제휴심리상담센터(우대)",
        name: "service",
        key: "psychological",
        type,
      },
      {
        text: "New헬스케어서비스 프리미어",
        name: "service",
        key: "premium",
        type,
      },
      // {
      //   text: "New헬스케어서비스 플러스",
      //   name: "service",
      //   key: "service-plus",
      //   type,
      // },
      {
        text: "New헬스케어서비스 프레스티지",
        name: "service",
        key: "prestige",
        type,
      },
    ],
    check: [
      {
        text: "건강위험도체크",
        name: "check",
        key: "risk-check",
        type,
      },
      {
        text: "심리 자가체크",
        name: "check",
        key: "self-check",
        type,
      },
      {
        text: "힐링테라피",
        name: "check",
        key: "therapy",
        type,
      },
    ],
    info: [
      {
        text: "생활",
        name: "info",
        key: "lifecare",
        type,
        query: { tab: "KB0351" },
      },
      {
        text: "치아",
        name: "info",
        key: "toothcare",
        query: { tab: "KB0801" },
        type,
      },
      {
        text: "응급",
        name: "info",
        key: "emergencycare",
        query: { tab: "KB0601" },
        type,
      },
      {
        text: "기획기사",
        name: "info-news",
        key: "health-news",
        type,
      },
      {
        text: "최신뉴스",
        name: "info-news",
        key: "recent-news",
        type,
      },
    ],
    ask: [
      {
        text: "자주찾는 질문",
        name: "ask",
        key: "ask",
        type,
      },
    ],
    consult: [
      {
        text: "온라인 상담실",
        name: "consult",
        key: "online",
        type,
      },
      {
        text: "Best 건강상담",
        name: "consult",
        key: "best",
        type,
      },
    ],
    mypage: [
      {
        text: "이용내역",
        name: "mypage",
        key: "use",
        query: { tab: "mycall" },
        type,
      },
      {
        text: "건강체크 내역",
        name: "mypage",
        key: "healthcheck",
        fields: [
          { label: "이용일자", key: "usedate" },
          { label: "건강체크 구분", key: "codename" },
          { label: "자세히보기", key: "detail" },
        ],
        type,
      },
      {
        text: "건강증진 프로그램",
        name: "mypage",
        key: "healthprogram",
        fields: [
          { label: "회차", key: "number" },
          { label: "신청일자", key: "start_web" },
          { label: "종료일", key: "end_web" },
          { label: "참여프로그램", key: "health_program" },
        ],
        type,
      },
    ],
  },

  // subTab
  subTabs: [
    {
      scroll: true,
      key: "info",
      items: ["평상시 건강관리", "가족 건강관리", "일반 치료지원", "중대질환 치료지원"],
    },
    {
      scroll: true,
      key: "premium",
      items: ["건강증진 프로그램", "차량 에스코트", "방문 심리상담", "해외 의료지원"],
    },
    {
      scroll: false,
      small: true,
      key: "lifecare",
      items: [
        { text: "흡연", value: "KB0351" },
        { text: "음주", value: "KB0352" },
        { text: "비만", value: "KB0353" },
        { text: "스트레스", value: "KB0354" },
        { text: "성기능장애", value: "KB0355" },
        { text: "직업병", value: "KB0356" },
        { text: "임신", value: "KB0357" },
        { text: "성병", value: "KB0358" },
        { text: "어린이건강", value: "KB0359" },
      ],
    },
    {
      scroll: false,
      key: "toothcare",
      items: [
        { text: "평상 시 치아관리", value: "KB0801" },
        { text: "주요 치아질환", value: "KB0802" },
        { text: "주요 치과치료", value: "KB0803" },
      ],
    },
    {
      scroll: false,
      key: "emergencycare",
      items: [
        { text: "응급처치의 개요", value: "KB0601" },
        {
          text: "증상별 응급처치",
          value: "KB0602",
          childItems: [
            { text: "아플 때", value: "KB0602001" },
            { text: "다쳤을 때", value: "KB0602002" },
            { text: "토할 때", value: "KB0602003" },
            { text: "피가나올 때", value: "KB0602004" },
            { text: "쓰러졌을 때", value: "KB0602005" },
            { text: "숨이 찰 때", value: "KB0602006" },
            { text: "이물질", value: "KB0602007" },
            { text: "화상/동상", value: "KB0602008" },
            { text: "물리거나 쏘였을 때", value: "KB0602009" },
            { text: "중독", value: "KB0602010" },
            { text: "피부이상", value: "KB0602011" },
            { text: "마비", value: "KB0602012" },
            { text: "부러지거나 베었을 때", value: "KB0602013" },
            { text: "찔렸을 떄", value: "KB0602014" },
          ],
        },
        {
          text: "신체별 응급처치",
          value: "KB0603",
          childItems: [
            { text: "머리/얼굴", value: "KB0603001" },
            { text: "눈", value: "KB0603002" },
            { text: "귀", value: "KB0603003" },
            { text: "목", value: "KB0603004" },
            { text: "가슴", value: "KB0603005" },
            { text: "피부", value: "KB0603006" },
            { text: "배", value: "KB0603007" },
            { text: "손/발/다리", value: "KB0603008" },
            { text: "생식기", value: "KB0603009" },
            { text: "출혈", value: "KB0603010" },
            { text: "실신", value: "KB0603011" },
            { text: "환경", value: "KB0603012" },
            { text: "정신", value: "KB0603013" },
            { text: "신경", value: "KB0603014" },
            { text: "소아", value: "KB0603015" },
          ],
        },
      ],
    },
    {
      scroll: false,
      key: "use",
      items: [
        {
          text: "전화상담",
          value: "mycall",
          fields: [
            { label: "회차", key: "number" },
            { label: "제목", key: "title" },
            { label: "작성날짜", key: "regdate" },
            { label: "진행상황", key: "progress" },
            { label: "자세히보기", key: "detail" },
          ],
        },
        {
          text: "방문간호",
          value: "ngnursing",
          fields: [
            { label: "회차", key: "number" },
            { label: "신청일자", key: "regdate" },
            { label: "내용", key: "Reason" },
            { label: "담당자", key: "Charge" },
            { label: "진행여부", key: "State" },
          ],
        },
        {
          text: "진료예약",
          value: "examinSchedule",
          fields: [
            { label: "회차", key: "number" },
            { label: "신청일자", key: "regdate" },
            { label: "서비스상태", key: "statename" },
            { label: "예약일자", key: "resrv_day" },
            { label: "예약병원", key: "name" },
            { label: "진료의(과)", key: "doctor" },
          ],
        },
      ],
    },
    {
      scroll: true,
      small: true,
      key: "music",
      items: ["스트레스", "기분전환", "우울", "불면증", "행복"],
    },
    {
      scroll: true,
      small: true,
      key: "asmr",
      items: ["숙면", "숲속", "일상 속", "비 오는 날", "바다"],
    },
  ],

  // 내 서비스 내역
  myServices: [
    {
      title: "이용내역",
      name: "mypage",
      params: { key: "use", type },
      query: { tab: "mycall" },
    },
    {
      title: "건강체크 내역",
      name: "mypage",
      params: { key: "healthcheck", type },
    },
    {
      title: "건강증진 프로그램",
      name: "mypage",
      params: { key: "healthprogram", type },
    },
  ],

  // --- home
  visual: [
    {
      title: "평상시 건강관리",
      subTitle: ["건강은 건강할 때 관리해야 한다고 합니다.", "건강을 잃지 않도록 도와드립니다."],
      to: "service",
      key: "info",
    },
    {
      title: "가족 건강관리",
      subTitle: ["가족이 건강해야 고객님이 행복합니다.", "가정의 건강설계를 지원합니다."],
      to: "service",
      key: "info",
      hash: "#sub-tab-1",
    },
    {
      title: "일반 치료지원",
      subTitle: ["중대질환 이전 단계 질환은 관리가 중요합니다.", "고객님의 빠른 회복을 도와드립니다."],
      to: "service",
      key: "info",
      hash: "#sub-tab-2",
    },
    {
      title: "중대질환 치료지원",
      subTitle: ["진단부터 치료하는 전 과정에서", "최적의 진료를 받을 수 있도록 지원합니다."],
      to: "service",
      key: "info",
      hash: "#sub-tab-3",
    },
    {
      title: "New헬스케어서비스 프리미어",
      subTitle: ["고객님의 건강상태를 정확히 파악한 후 체계적이고", "효율적인 건강 프로그램을 제공해 드립니다."],
      to: "service",
      key: "premium",
    },
    {
      title: "New헬스케어서비스 프레스티지",
      subTitle: ["특별한 고객님과 가족에게 제공하는", "교보생명만의 차별화된 서비스입니다."],
      to: "service",
      key: "prestige",
    },
  ],
  healthCheck: {
    title: "건강체크",
    subTitle: "온라인 건강체크로 내 건강상태를 체크해 보세요.",
    items: [
      {
        text: "건강위험도체크",
        name: "check",
        color: "#cfe2f6",
        key: "risk-check",
      },
      {
        text: "심리 자가체크",
        color: "#c2f1d5",

        name: "check",
        key: "self-check",
      },
      { text: "힐링테라피", color: "#ddf1c2", name: "check", key: "therapy" },
    ],
  },
  healthInfo: {
    title: "건강정보",
    subTitle: "건강정보를 확인하고 건강한 삶을 지켜보세요.",
    items: [
      {
        text: "생활",
        name: "info",
        key: "lifecare",
        query: { tab: "KB0351" },
      },
      {
        text: "치아",
        name: "info",
        key: "toothcare",
        query: { tab: "KB0801" },
      },
      {
        text: "응급",
        name: "info",
        key: "emergencycare",
        query: { tab: "KB0601" },
      },
      {
        text: "기획기사",
        name: "info-news",
        key: "health-news",
      },
      {
        text: "최신뉴스",
        name: "info-news",
        key: "recent-news",
      },
    ],
  },

  rules: {
    breast: [
      {
        current: 2,
        parent: 1,
        // defaultRule
      },
      {
        current: 4,
        parent: 3,
        // defaultRule
      },
      {
        current: 6,
        parent: 5,
        // defaultRule
      },
      {
        current: 13,
        parent: 12,
        // defaultRule
      },
    ],
    ovarian: [
      {
        current: 3,
        parent: [1, 2],
        rules: [(parent = []) => parent.find((val) => {})],
      },
    ],
  },
  // --- 서비스 안내
  bookAgent: [
    {
      title: "건강검진 예약대행",
      subTitle: "전국에 제휴된 병원 및 검진센터를 통한 특별한 우대 혜택을 드립니다.",
      button: {
        text: "전국 협력병원",
        modalId: "modal-hospitals-compare",
      },
      contents: [
        {
          texts: [
            "교보생명 New헬스케어서비스에서는 전국에 제휴 된 대학병원이나 전문 검진센터를 통하여 다양한 검진 프로그램을 제공하고 우대 혜택을 통한 저렴한 검진비용으로 고객님과 가족분들께서 주기적으로 종합검진을 받으실 수 있도록 도와드리고 있습니다.",
            "※ 전국 병원 및 전문 검진센터의 선별된 건강검진을 특별 우대가에 제공",
            "※ 온라인 병원조회 및 헬스콜센터(1588-7524)를 통한 전화예약 대행으로 편리한 고객 지원",
          ],
        },
        {
          title: "주의사항",
          texts: [
            "비용 결제는 검진 당일 해당 병원에서 직접 결제해주시기 바랍니다.",
            "검진물품은 일부 검사에만 해당됩니다. (예. 대장내시경)",
            "검진결과 통보에 소요되는 기간은 해당 병원 또는 검진센터마다 차이가 있을 수 있습니다.",
            "본 건강검진 예약대행은 검진센터에서 진행하는 자체 우대서비스와 중복적용 되지 않습니다. 아울러 제휴병원은 서비스 퀄리티 향상을 위해 추가 또는 변경 될 수 있습니다.",
          ],
        },
      ],
    },
  ],
  consultation: [
    {
      title: "의료진 건강상담",
      subTitle: "건강이나 질환 또는 진료 및 치료방법 등 궁금하신 점은 무엇이든 물어보세요.",
      contents: [
        {
          texts: [
            "몸이 아프거나 건강에 이상이 생겼을 때, 또는 각종 증상 관련 궁금하신 부분에 대해 13개 진료과목 전문의 및 임상 경험이 풍부한 간호사가 1:1 건강상담을 제공해 드립니다.",
            "헬스 콜센터(1588-7524)를 통한 전화 건강상담과 홈페이지를 통한 온라인 건강상담으로 서비스가 이루어집니다.",
          ],
        },
        {
          table: {
            caption: "진료과별 상담 내용안내",
            columns: [{ text: "진료과" }, { text: "내용" }],
            cols: {
              th: [3, 9],
              td: [3, 9],
            },
            class: {
              td: ["flex-center", ""],
            },
            rows: [
              [
                { text: "가정의학과" },
                {
                  text: "질환이나 증상의 유무 및 연령, 성별과 관계없이 환자나 환자 가족을 대상으로 하는 전반적인 의료 상담",
                },
              ],
              [{ text: "내과" }, { text: "위장, 간질환, 성인병, 암, 류마티스 관절염 등" }],
              [{ text: "외과" }, { text: "외상 및 수술적 치료, 대장항문 등에 대한 상담 등" }],
              [{ text: "정형외과" }, { text: "팔, 다리, 척추, 근육, 관절 관련 질환" }],
              [
                { text: "신경과" },

                {
                  text: "중추신경, 말초신경, 뇌혈관 장애, 뇌졸중, 뇌출혈, 두통 등",
                },
              ],
              [
                { text: "신경외과" },

                {
                  text: "뇌, 척수, 신경계에 생기는 여러 질환에 대한 수술적 치료 관련 상담",
                },
              ],
              [{ text: "치과" }, { text: "평상시 치아관리, 충치, 치주염 등" }],
              [
                { text: "산부인과" },

                {
                  text: "여성의 생식기능, 임신 및 출산, 자궁경부암, 부인병 등의 여성 질환",
                },
              ],
              [{ text: "소아청소년과" }, { text: "신생아, 어린이, 청소년 건강에 대한 상담" }],
              [
                { text: "신경정신과" },

                {
                  text: "정신질환, 행동이상, 조울병, 우울증, 공황 장애, 스트레스 등에 관한 상담",
                },
              ],
              [{ text: "안과" }, { text: "눈에 관련된 질환" }],
              [{ text: "이비인후과" }, { text: "귀, 코, 목, 비염, 구강 질환, 갑상선 질환 등" }],
              [{ text: "피부과" }, { text: "피부 전반, 두피, 탈모, 아토피 피부염 등" }],
              [{ text: "비뇨기과" }, { text: "신장, 방광등의 요로계 및 생식기관, 성기능 장애" }],
            ],
          },
        },
      ],
    },
  ],
  medicalAppointment: [
    {
      title: "병원진료예약",
      subTitle: "빠른 진료예약 대행 및 질환별 진료과와 명의를 안내해드립니다.",
      button: {
        text: "전국 협력병원",
        modalId: "modal-hospitals",
      },
      contents: [
        {
          texts: [
            "서울 소재 5대 대형병원 및 전국 유명병원 예약편의를 도와드리며, 증상 및 질환에 따른 진료과와 명의를 안내해드립니다. 진료예약은 헬스콜센터 및 홈페이지를 통해 신청하실 수 있으며, 증상과 질환, 희망일을 알려주시면 상담 후 최종 확정된 진료일을 알려드립니다.",
          ],
        },
        {
          title: "서비스 이용 절차",
          texts: [
            "01. 전화 예약 신청(1588-7524)  02. 희망진료일 및 진료과목 선택  03. 진료예약 일정 안내",
            "※ 적합한 진료과 및 수월한 예약을 위해 간호사 상담시 증상 및 질환에 대하여 질문을 드릴 수 있습니다.",
            "※ 다른 병원에서 진료를 받으신 경우, 진료의뢰서를 요청 드릴 수 있습니다.",
          ],
        },
      ],
    },
  ],
  premium: [
    {
      title: "건강증진 프로그램",
      subTitle: "현재 고객님의 건강상태를 정확히 파악한 후 체계적이고 효율적인 건강 프로그램을 제공해 드립니다.",
      contents: [
        {
          title: "관리 프로그램 (연 1회한)",
          texts: ["- 피부관리 · 금연 · 금주 · 스트레스 · 갱년기 · 혈압 · 혈당 · 고지혈 · 간기능 · 비만 · 빈혈 · 산후관리"],
        },
      ],
    },
    {
      title: "차량 에스코트",
      subTitle: "전담간호사의 편안한 에스코트 및 병원 간 이송지원으로 고객님과 가족의 걱정을 해소해 드립니다.",
      contents: [
        {
          title: "입, 퇴원 병원동반",
          texts: ["중대질환 수술을 위한 입, 퇴원 시 전문 간호사 및 차량 지원", "※ 입 , 퇴원 1일 전 신청 필요 (서울권 이동 한정)"],
        },
        {
          title: "병원 간 이송",
          texts: [
            "중대질환 입원치료를 위한 타 의료기관 이송 시 차량 지원",
            "※ 응급 상황, 도서 지역 서비스 이용 불가 / 이송 2일 전 신청 (1인 연 1회한)",
          ],
        },
      ],
    },
    {
      title: "방문 심리상담",
      subTitle: "심리상담전문가 방문을 통해 중대질환의 심리적 회복까지 도와드립니다.",
      contents: [
        {
          checkedTtiles: ["부정적 감정 감소 및 정서 안정을 위한 심리상담", "고객 치료 병원 또는 제휴 심리상담센터에서 진행"],
          texts: ["※ 병원 / 심리상담센터 방문 1일 전 신청", "※ 중대질환 발생 고객 본인 / 고객 직계 가족 1인 중 선택(연 1회)"],
        },
      ],
    },
    {
      title: "해외 의료지원",
      subTitle: "해외에서 상해나 질병으로 위급한 상황 발생 시 신속하면서 편안하게 대처할 수 있도록 도와드립니다.",
      contents: [
        {
          checkedTtiles: [
            "국가별 예방접종 및 유의사항 상담",
            "현지 병원 안내 및 예약",
            "365일 24시간 Hot Line 운영 및 의료통역 지원",
            "간호사 파견 및 긴급이송 지원(고객 실비부담)",
          ],
        },
      ],
    },
  ],
  info: [
    {
      title: "평상시 건강관리",
      subTitle: "건강은 건강할 때 관리해야 한다고 합니다. 건강을 잃지 않도록 도와드립니다.",
      contents: [
        {
          title: "건강상담",
          texts: ["헬스콜센터(1588-7524) 통해 간호사 상담 접수 후 내과, 외과, 산부인과 등 13개과 진료과목 전문의 전화상담 제공"],
        },
        {
          title: "건강정보 제공",
          texts: ["인터넷(www.kyobowomancare.com), 문자메시지, E-mail을 통한 다양하고 깊이 있는 건강정보 제공"],
        },
        {
          title: "모바일 APP",
          texts: ["전용 어플리케이션을 통한 건강상담, 건강정보 제공"],
        },
        {
          title: "주요 질환 예방 프로그램",
          texts: [
            "주요 질환 생활습관 개선 콘텐츠 제공(4주)",
            "※ 주요 질환 범위 : 대사증후군, 통증수반질환, 심뇌혈관 질환 중 선택",
            "※ 심뇌혈관 질환 신청 시 심화 예방 프로그램 추가 제공(4주)",
          ],
        },
        {
          title: "간호사복약상담",
          texts: ["약학정보원 등 디지털 라이브러리 기반의 간호사 1:1 복약 지도 및 상담 제공", "복약 정보, 복약 시 주의사항 제공"],
        },
        {
          title: "고혈압관리/비만·영양 관리 프로그램",
          texts: ["고혈압 요인 및 합병증 예방 가이드, 운동·영양 가이드 제공", "만성질환 예방 관리를 위한 비만 치료/영양 가이드 등 제공"],
        },
        {
          title: "안과/치과 주요질환정보 프로그램",
          texts: ["주요 안과/치과 질환 안내, 노인성 안과/치과 질환 예방 및 관리를 위한 가이드 제공"],
        },
      ],
    },
    {
      title: "가족 건강관리",
      subTitle: "가족이 건강해야 고객님이 행복합니다. 가정의 건강설계를 지원합니다.",
      contents: [
        {
          title: "미숙아 방문간호 컨설팅",
          texts: [
            "미숙아 출생 시 신생아 전담간호사 방문상담 및 교육 제공(퇴원후 한달내 신청)",
            "※ 미숙아 : 임신주수 37주 미만 & 출생체중 2.5kg 이하",
          ],
        },
        {
          title: "건강검진 안내(우대)",
          texts: ["가족 건강상담 및 제휴협력을 통한 건강검진 우대(고객 실비부담)", "맞춤형 건강검진 설계 및 검진항목 안내"],
        },
        {
          title: "암 정보 제공",
          texts: ["암 진단 시 가족 건강 및 지원을 위해 특화된 암 정보 및 식이, 운동법 등 관련 정보 제공"],
        },
      ],
    },
    {
      title: "일반 치료지원",
      subTitle: "중대질환 이전 단계 질환은 관리가 중요합니다. 고객님의 빠른 회복을 도와드립니다.",
      contents: [
        {
          title: "병원·의료진 안내 및 예약대행",
          texts: ["질병 진료 및 치료를 위한 병원·의료진 안내 및 진료 예약대행"],
        },
        {
          title: "1 : 1 코칭콜",
          texts: [
            "- 전문간호사 1 : 1 질병 코칭콜 제공",
            "- 질환관리, 운동, 영양, 복약알림 등 맞춤교육 제공",
            "※ 1:1 코칭콜 해당범위 : CI추가보장관련특약 [암/뇌/심장 및 장기] 및 합병증보장특약의 해당질환",
          ],
        },
        {
          title: "간병인 매칭",
          texts: ["간병인 필요시 제휴업체를 통한 간병인 안내", "(간병인 이용료는 고객 부담)"],
        },
        {
          title: "토닥토닥 마음케어",
          texts: [
            `<span class="fs-18 fw-7">STEP1. 평상 시</span>`,
            "심리전문칼럼",
            "심리자가진단",
            "힐링테라피",
            "<br/>",
            `<span class="fs-18 fw-7">STEP2. 필요 시</span>`,
            "토닥토닥 심리프로그램(4주)",
            "전화 심리상담 제공",
            "제휴 심리상담센터(대면/화상) 안내 및 우대(고객부담)",
            ,
          ],
        },
        {
          title: "당뇨 집중관리 14주 프로그램",
          texts: ["- 간호사 1:1 당뇨관리 상담 제공", "- 생활습관 체크, 당뇨 위험요인 스크리닝", "- 혈당관리, 합병증 예방 관련 콘텐츠 제공"],
        },
        {
          title: "인공관절수술 자가 재활 프로그램",
          texts: [
            "인공관절치환수술 후 자가 재활관리 및 운동처방 맞춤 프로그램 제공",
            "<br/>",
            "자가재활관리(4주)",
            "자가 재활관리 가이드",
            "수술 후 유의사항 및 관리수칙 안내",
            "수술 부위별 재활 운동 영상 제공",
            "<br/>",
            "운동처방 맞춤 컨설팅(4주)",
            "운동처방사 심층 상담 & 맞춤 컨설팅",
            "신화 운동처방 미션 수행 및 확인 관리",
          ],
        },
      ],
    },
    {
      title: "중대질환 치료지원",
      subTitle: "진단부터 치료하는 전 과정에서 최적의 진료를 받을 수 있도록 지원합니다.",
      contents: [
        {
          title: "간호사 병원동반 / 질병관리교육",
          texts: [
            "- 중대질환 치료(수술, 입 · 퇴원 등) 시 전문간호사 병원동반",
            "- 중대질환 전문간호사 방문교육 서비스",
            "- 중대질환별 건강상담, 건강정보, 심리상담 제공",
            "※ 중대질환 범위 : 암, 심장 . 뇌혈관 질환, 고혈압성 질환, 당뇨합병증, 원발성폐동맥고혈압, 다발경화증, 중증세균성수막염(뇌, 척수수막염), 루게릭병 (근위축성측삭경화증)",
          ],
        },
        {
          title: "암 재발 예방",
          texts: ["암 치료 후 지속적 관리 제공", "- 전담간호사 정기적 안부 / 상담 콜", "- 병원 방문 시 간호사 동반 방문서비스 제공(필요시)"],
        },
      ],
    },
  ],
  visit: [
    {
      title: "헬스플래너 방문",
      subTitle: "전담간호사가 직접 방문하여 중대질환자의 치료 및 수술, 관리가 잘 이루어질 수 있도록 도와드립니다.",
      contents: [
        {
          title: "서비스 이용 절차",
          texts: [
            `01. 전화 예약 신청(1588-7524)  02. 임상 경험이 풍부한 전담 간호사 배정 및 방문 일정 조율  03. 병원 내원시 함께 동행하여 서비스 제공`,
            "※ 본 서비스는 헬스콜센터(1588-7524)를 통해 상담이 이루어진 후 제공됩니다.",
            "※ 암, 심장질환, 뇌혈관질환에 한하여 제공됩니다.",
            "※ 상담 전에 아래 제공되는 서비스 내용을 먼저 확인해주시기 바랍니다.",
          ],
        },
        {
          title: "서비스 제공 내용",
          lists: [
            [
              "- 불안감 해소, 긴장감 완화 등 정서적 안정 지원",
              "- 진료 시 의료진과의 원활한 의사소통 도움",
              "- 진료 동행 및 입,퇴원 수속 도움",
              "- 관련 질병 책자 무료 제공",
              "- 가족의 간호지도와 정서적인 지지",
            ],
            ["- 질병정보 교육자료 제공", "- 질병 치료법 및 예후 설명", "- 항암 및 방사선 치료 시 교육", "- 복용 약물 교육", "- 식이요법 관리"],
          ],
        },
      ],
    },
  ],
  psychological: [
    {
      title: "심리전화상담 & 제휴심리상담센터(우대)",
      contents: [
        {
          title: "심리전화상담",
          texts: ["도움이 필요할 땐 언제든지 연락주세요. 전문 간호사와 심리상담사가 마음건강 회복을 지원합니다."],
        },
        {
          title: "제휴심리상담센터(우대)",
          texts: [
            "혼자서 힘들어하지 마세요. 마음이 아프고, 힘들때는 심리전문가와 함께 마음건강을 지키는 것이 중요합니다.",
            "- 전국 어디서나, 제휴 심리센터 네트워크 구축(제주도 포함)",
            "- 제휴 네트워크를 통해 심리서비스 이용 시 우대 혜택 제공",
            "<br/>",
            `<span class="fs-20 fw-7">주요 프로그램</span>`,
            "- 영유아 상담, 발달검사, 놀이평가, 소아우울증, ADHD, 놀이치료, 미술치료 등",
            "- 개인, 부부, 가족 상담 및 치료",
            "- 암 환자 심리상담, 외상 후 스트레스, 중독 상담 등",
            "- 성인 개인, 심리안정, 우울증, 분노조절, 감정코칭 프로그램 제공",
            "<br/>",
            "※ 센터마다 제공되는 프로그램이 다를 수 있습니다.",
            "※ 교보헬스콜센터(1588-7524)를 통해서 신청이 가능합니다.",
          ],
        },
      ],
    },
  ],
  // servicePlus: [
  //   {
  //     title: "New헬스케어서비스 플러스",
  //     subTitle:
  //       "기존 교보New헬스케어서비스 중대질환 치료지원 서비스 중 중대질환 수술을 위한 입퇴원 시 중대질환별* 횟수 한도 내 선택 서비스(간호사 병원동행 및 방문교육 또는 홈클리닝 서비스) 제공",
  //     contents: [
  //       {
  //         texts: [
  //           "※ 홈클리닝 서비스(최대 3회) 또는 간호사 병원동행 및 방문교육 중 택 1",
  //           "<br/>",
  //           "※ 해당질환 :  암/심장질환/뇌혈관질환/고혈압성질환/당뇨병으로 인한 합병증 ,원발성 폐동맥 고혈압, 다발경화증, 중증 세균성 수막염(뇌수막염, 척수수막염), 루게릭병(근위축성측삭경화증)",
  //         ],
  //       },
  //       {
  //         title: "간호사 병원동행 및 방문교육",
  //         texts: [
  //           `서비스 이용절차 : 01. 교보생명 헬스콜센터(1588-7523) <i class="icon-right"></i> 02. 상담 <i class="icon-right"></i> 03. 입퇴원 시 동행 및 교육 진행 <i class="icon-right"></i> 04. 퇴원 후 건강 정보 제공`,
  //           "<br/>",
  //           "- 진료 동행 및 입퇴원 수속 에스코트",
  //           "- 질병치료법 및 예후 설명",
  //           "- 진료 시 의료진과의 원활한 의사소통 도움",
  //           "- 치료 시(항암 및 방사선 등) 교육",
  //           "- 복용 약물 교육",
  //           "- 식이요법 관리 ",
  //           "- 질병 교육자료 무료 제공",
  //           "- 정서적 안정 지원 ",
  //         ],
  //       },
  //       {
  //         title: "헬스홈케어서비스",
  //         texts: [
  //           `서비스 이용절차 : 01. 교보생명 헬스콜센터(1588-7523) <i class="icon-right"></i> 02. 상담 <i class="icon-right"></i> 03. 프로터치 해피콜 <i class="icon-right"></i> 04. 청소 진행 및 고객 검수`,
  //           "<br/>",
  //           "- 청소, 공간 방역, 매트리스 살균 등 주거환경 클리닝(선택 시 최대 3회 한도로 이용 가능)",
  //         ],
  //       },
  //       {
  //         title: "식습관 데이터 기반 영양관리서비스",
  //         texts: [
  //           "※ 오픈이노베이션 「UT인프라」 제휴",
  //           "- 질환별 식습관 분석",
  //           "- 건강관리를 위한 식습관 컨설팅",
  //           " &nbsp;&nbsp;&nbsp;⇒ 제휴 APP을 통해 제공"
  //         ]
  //       },
  //       {
  //         title: "케어식단 제공 및 영양 컨설팅",
  //         texts: [
  //           "- 중대질환으로 입원 후 퇴원 시 건강회복을 위한 건강식 자택 배달서비스 제공",
  //           "- 케어식단 영양 컨설팅"
  //         ]
  //       }
  //     ],
  //   },
  // ],
  prestige: [
    {
      title: "New헬스케어서비스 프레스티지",
      subTitle: " ",
      contents: [
        {
          texts: [
            "※ 가족 올케어 건강관리의 가족 범위 : 주피보험자(회원)의 부, 모, 배우자, 자녀",
            "※ 중대질환 범위 : 암, 심장 · 뇌혈관질환, 고혈압성 질환, 당뇨합병증, 원발성 폐동맥 고혈압, 다발경화증, 중증세균성수막염(뇌 · 척수수막염), 루게릭병(근위축성측삭경화증)",
          ],
        },
        {
          title: "차량 에스코트",
          texts: [
            "기존 프리미어고객 대상 중대질환 진단 받았을 경우, 대형병원 입퇴원 시 제공되던 차량 에스코트를 가족의 건강검진 우대예약 시 병원 왕복 이동을 차량 에스코트 해드리는 서비스로 확대 제공해 드립니다.",
            "<br/>",
            "- 본인 및 가족(부모, 배우자, 자녀에 한함)의 건강검진 우대예약 시 검진일 왕복 차량 에스코트 무상 지원",
            "- 주피보험자 1인당 서비스기간 내 최초 1회 (탑승자 수는 최대 3명)",
            "- 지방의 경우, 검진병원으로부터 50km 반경의 이동을 지원",
          ],
        },
        {
          title: "영양 컨설팅",
          texts: [
            "중대질환 치료 후 면역강화가 필요한 고객을 위해 영양 컨설팅과 맞춤 건기식을 제공해 드립니다.",
            "<br/>",
            `<span class="fs-18 fw-7">영양 컨설팅</span>`,
            "성별, 나이, 건강상태(질환 유무, 과거력)를 고려한 면역 강화 목적에 따라 필요한 영양소가 무엇이고, 그 영양소를 많이 함유하고 있는 음식과 건강기능식품 추천",
            "<br/>",
            `<span class="fs-18 fw-7">건기식 제공</span>`,
            "영양 컨설팅에서 추천하는 건강기능식품 배송 (단, 주치의의 건강기능식품 섭취 가능하다는 소견이 있을 시 제공 가능)",
          ],
        },
        {
          title: "중대질환 치료지원",
          texts: [
            "기존 프리미어고객 대상 중대질환 진단 받았을 경우, 대형병원 입퇴원 시 제공되던 차량 에스코트를 가족의 건강검진 우대예약 시 병원 왕복 이동을 차량 에스코트 해드리는 서비스로 확대 제공해 드립니다.",
            "<br/>",
            "- 가족의 중대질환 진단 시에도 간호사 방문상담 신청 이용 가능",
            "- 본인 외 가족(부모,배우자,자녀에 한함)의 이용 시 주피보험자 1인당 서비스기간 내 최대 5회",
            "- 전문 간호사가 가족의 중대질환 진료에도 대형병원에 동행하여 질병관리 교육과 관련 서비스를 제공",
            "&nbsp;&nbsp;(단, 의료진과의 직접 진료 시 동행하며, 단독 검사와 항암 치료는 해당하지 않음)"
          ],
        },
      ],
    },
  ],

  // --- 건강체크
  riskCheck: [
    {
      id: "healthcheck",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "자가체크",
      title: "건강위험도 체크",
      text: "음주력, 흡연력, 식습관, 운동습관, 체질량지수를 바탕으로 고객님의 건강위험도를 알아보세요.",
      to: "check-agree",
    },
    {
      id: "skin",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "자가체크",
      title: "피부 노화도 체크",
      text: "피부 노화도 체크를 통해 고객님의 피부 상태를 알아보고 관리해 보세요.",
    },
    {
      id: "healthStress",
      backgroundColor: "#ddf1c2",
      btnColor: "#a8c482",
      category: "자가체크",
      title: "스트레스 지수 체크",
      text: "신체적, 행동적, 심리적 반응에 대한 평가로 고객님의 스트레스 정도를 알아보세요.",
    },
  ],
  selfCheck: [
    {
      id: "personaltype",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "자가체크",
      title: "성격유형",
      textCenter: true,
    },
    {
      id: "stress",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "자가체크",
      title: "스트레스",
      textCenter: true,
    },
    {
      id: "depression",
      backgroundColor: "#ddf1c2",
      btnColor: "#a8c482",
      category: "자가체크",
      title: "우울감",
      textCenter: true,
    },
    {
      id: "anxiety",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "자가체크",
      title: "불안",
      textCenter: true,
    },
    {
      id: "anger",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "자가체크",
      title: "분노조절장애",
      textCenter: true,
    },
    {
      id: "panic",
      backgroundColor: "#ddf1c2",
      btnColor: "#a8c482",
      category: "자가체크",
      title: "공황",
      textCenter: true,
    },
  ],
  therapy: [
    {
      id: "music",
      backgroundColor: "#cfe2f6",
      btnColor: "#92c7ff",
      category: "음악감상",
      title: "마음건강에 도움이 되는<br/>음악",
      btnText: "바로가기",
      textCenter: true,
      to: "check-therapy",
    },
    {
      id: "asmr",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "음악감상",
      title: "심리적 안정을 유도하는<br/>ASMR",
      btnText: "바로가기",
      textCenter: true,
      to: "check-therapy",
    },
  ],

  // 건강검진 결과 콘텐츠
  resultContents: [
    {
      title: "식생활",
      content:
        "저지방식 특히 포화 지방과 트랜스 지방의 섭취를 억제함으로써 동맥 노화를 예방할 수 있다는 것이 수많은 연구에 의해 증명되고 있습니다. 또한 포화 지방과 트랜스 지방 섭취를 줄이면 노화의 위험도 줄일 수 있습니다. 되도록 튀김 요리는 삼가고, 기름을 많이 사용하는 패스트푸드도 먹지 않는 것이 좋습니다. 포화 지방이나 트랜스 지방 등은 대부분의 식품 속에 ‘숨겨진’ 상태로 포함되어 있으므로 제품 포장의 성분표시를 꼼꼼히 체크하는 습관을 들이는 것이 좋습니다. 그 밖에 저지방식과 더불어 특히 우리나라 사람에게 중요한 것은 싱겁게 먹는 것인데, 되도록 짜거나 맵지 않게 먹는 것이 중요합니다. 생선과 녹황색 채소를 많이 먹고, 특히 조금 모자란 듯이 먹는 소식이 장수의 비결임을 잊지 마세요. ",
    },
    {
      title: "운동",
      content:
        "운동은 신체의 구석구석에까지 영향을 줍니다.운동은 근육을 단련시킬 뿐만 아니라 신체 전반에 걸쳐 노화 속도를 늦춰줍니다.가장 바람직한 건강 운동 계획은 다음의 세가지를 잘 조합하는 것입니다.첫째, 신체 활동량을 늘리도록 합니다.일상생활 속에서 신체를 적극적으로 움직여야 한다는 것을 명심하시고 엘리베이터 대신 계단을 이용하거나, TV를 보면서 자전거 운동기구를 하는 방법 등 평소의 생활습관을 조금씩 바꾸어 신체 활동량을 늘려보세요.둘째, 체력을 키웁니다.에어로빅, 수영, 조깅, 테니스 등 땀을 흘려 심장 박동수가 상승되는 활발한 운동을 통해 건강나이를 낮출 수 있습니다.셋째, 근력과 유연성을 기릅니다.근력과 신체의 부드러움을 유지하는 것이 노화를 예방하는 방법으로 스트레칭이나 아령 들기, 요가 등으로 젊음을 유지하도록 하세요",
    },
    {
      title: "흡연",
      content:
        "담배가 건강을 해치고 암이나 폐질환의 원인이라는 건 누구나 알고 있지만 여전히 많은 사람들이 이런 담배의 해악성에도 불구하고 담배를 끊지 못하고 있습니다.담배는 신체에 치명적인 위험을 주기 전부터 노화를 촉진시키는데, 담배를 피움으로써 건강나이는 약 8세 많아집니다.담배 피우는 사람 옆에서 연기를 마시는 간접 흡연도 몸에 해롭습니다.어떤 방법으로 담배 연기를 마시든지 담배는 심장과 폐의 질병 발생률을 높이고 면역계를 약화시킵니다.담배를 끊으면 흡연에 의해서 빼앗겼던 건강나이 8년 중, 7년을 되찾을 수 있을 뿐 아니라 금연에 의한 효과가 즉시 나타나, 담배를 끊은 지 12시간 이내에 신체가 젊음을 되찾기 시작합니다.따라서 금연에 여러 번 실패했더라도 좌절하지 말고, 의사와 상담 후 계획을 세워 다시 한번 시도해 보세요.",
    },
    {
      title: "음주",
      content:
        "술이 인체에 좋은지 나쁜지는 사실 일률적으로 결론을 내릴 수가 없습니다.좋은 점이나 나쁜 점이 모두 있기 때문입니다.적당량의 술을 즐기는 습관을 가지고 있으면 동맥 노화나 심근경색증을 예방할 수 있지만, 과도한 음주는 알코올 중독이나 간 질환을 초래하며 암 발생가능성을 높입니다.하루에 여성은 0.5~1잔, 남성은 1~2잔의 알코올이 적당하다고 하지만 가족 중에 알코올과 관련된 질병이 많은 사람, 음주에 의해 질환에 걸릴 위험도가 높은 사람은 전적으로 술을 피하는 것이 좋습니다.음주와 관련된 문제가 있을 경우에는 의사와 상담하고, 술을 끊는 방법에 대해 상의해야 합니다.",
    },
    {
      title: "비만",
      content:
        "비만은 여러 가지 노화현상을 일으킵니다.혈압이 상승하고 운동량이 적어지며, 그 결과로 당뇨병과 같은 만성 질환이 발생합니다.대개 비만에 의해 건강나이는 10세정도 많아집니다.하지만 충동적인 다이어트를 하는 것은 별 효과가 없을뿐더러, 몸에 무리가 가는 다이어트를 할 바에는 처음부터 체중 조절을 하지 않는 것이 훨씬 낫습니다.다이어트로 젊음을 되찾으려면 식사량을 줄이고 운동량을 늘리는 것이 최선의 방법입니다.중요한 것은 홀쭉해지는 것이 아니라 자신의 신장과 골격에 알맞은 적정한 체중을 만들고 유지하는 것입니다.",
    },
    {
      title: "스트레스",
      content:
        "스트레스는 면역계통의 기능을 저하시키기 때문에, 질병에 감염되기 쉬우며 더욱 심각한 병을 유발할 위험성도 높아집니다.다시 말하면, 스트레스는 우리에게 급격한 노화를 재촉하는 온갖 원인이 된다는 것입니다.지난 1년 동안 가족의 죽음, 이혼, 실직, 경제적 궁핍, 재판 등 인생에서의 큰 사건을 경험한 경우 건강나이를 5세 정도 높이고, 두 개의 사건이 동시에 일어나면 16세쯤, 세 개가 겹치는 경우는 약 32세까지 노화된다고 합니다.그렇지만 평생을 살면서 이런 큰 사건을 한번도 겪지 않는 사람은 거의 없습니다.결국 문제는 스트레스 요인들을 경험하지 않는 것이 아니라 그것을 어떻게 대처해 나가느냐에 달려있습니다.쌓여가는 스트레스를 해소하는 자신만의 방법을 몸에 익히거나, 스트레스에 대해 같이 의논할 수 있는 인간관계를 맺는 것이 좋습니다.스트레스를 잘 조절하기만 해도 빼앗긴 30년을 되찾을 수 있습니다.",
    },
    {
      title: "수면",
      content:
        "수면은 적당히, 규칙적으로 하는 것이 젊음을 유지하는 비결입니다.여성은 7시간, 남성은 8시간 자는 것이 적당합니다.꿀잠을 자기 위해서는 주변 환경도 중요하므로 마음이 안정되도록 어두운 방에서 자도록 합니다.또한 평소 잠들기 어려운 사람이라면 잠자리에 들기 전에 미지근한 물로 샤워를 하고 우유 한잔이나 바나나 등 수면을 돕는 식품을 먹는 것도 좋습니다.편안한 수면을 위해서는 평소와 같이 일을 하고, 자연스러운 생활 리듬에 맞추어 생활하는 것이 중요함을 잊지 말아야 합니다.",
    },
    {
      title: "건강검진",
      content:
        "질병은 초기에 발견할수록 치료도 쉬운 법입니다.신체에 작은 이상이 생기면 많은 사람들이 대수롭지 않게 생각하고 그저 증상이 저절로 사라지길 바라며 병원에 가지 않는 경우가 있습니다.하지만 질병이란 아무런 증상이 없다가 갑자기 심각해져 치료가 어려운 경우도 종종 발생하게 됩니다.일반적으로 건강검진 통해 알게 되는 콜레스테롤을 정상수치로 유지하는 것만으로도 3.7세 젊어질 수 있으며, 양질의 의료 서비스를 받을 수 있는 병원을 알아두고 몸 상태를 변화에 주의를 기울이는 것만으로도 약 12세가 젊어집니다.건강할 때부터 정기적인 건강검진을 통해 관리하시길 바랍니다.",
    },
  ],
  // 프로그램 신청
  programItems: [
    {
      id: "I",
      backgroundColor: "#c2f1d5",
      btnColor: "#79d19c",
      category: "프로그램 신청",
      title: "피부 프로그램",
      text: "건강한 피부를 가질 수 있도록 도와 드립니다.",
    },
    {
      id: "K",
      backgroundColor: "#ddf1c2",
      btnColor: "#a8c482",
      category: "프로그램 신청",
      title: "스트레스 프로그램",
      text: "스트레스 관리를 도와 드립니다.",
    },
  ],

  consent: {
    healthcheck: {
      sensitive: [
        {
          purpose: "건강위험도 체크",
          item: "생년월일, 신장, 체중, 위험도체크 결과",
          period: "결과 확인 후<br/> 즉시 파기",
        },
      ],
    },
  },
  // 개인정보 처리방침
  policy: {
    "depth-0": {
      p: policy.depth0.p("교보생명 New헬스케어서비스"),
      row: [
        {
          title: policy.depth0.title_1.title,
          contents: [
            {
              text: policy.depth0.title_1.text_1,
            },
            {
              table: {
                columns: [
                  { text: "구분" },
                  { text: "수집 · 이용 목적" },
                  { style: { minWidth: "600px" }, text: "개인정보 수집 · 이용 항목" },
                  { text: "보유기간" },
                ],
                row: [
                  [
                    { text: "공통(콜센터)", rowspan: "8", class: "bg-green-ligth" },
                    { text: "건강상담", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 생년월일, 성별, 전화번호 또는 휴대전화번호, 상담정보, 가족관계", class: "text-left" },
                    { text: "위탁계약 종료시 또는 실효후 5년경과시", rowspan: "5" },
                  ],
                  [
                    { text: "진료예약대행 및 사후관리", class: "bg-green-ligth" },
                    {
                      text: "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 진료의뢰서 또는 기타 의무기록 사본, 진단명, 소견내용",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "진료동행서비스", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 상병명, 병기 및 질병진행정도, 치료방법 및 종류, 치료 부작용 여부, 치료 경과(재발 및 전이여부)",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약대행 및 관리", class: "bg-green-ligth" },
                    {
                      text:
                        "[필수] (회원) 이름, 생년월일, 성별, 휴대폰번호, 검진희망일, 검진희망기관, 주소, 관심질환, 병력, 가족력, 수술이력여부, 복용약",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "검진예약 만족도 조사", class: "bg-green-ligth" },
                    {
                      text: "[필수] 휴대전화번호, 검진받은 기관, 조사내용",
                      class: "text-left",
                    },
                  ],
                  [
                    { text: "건강위험도 체크", class: "bg-green-ligth" },
                    {
                      text: "[필수] 생년월일, 신장, 체중, 위험도체크결과",
                      class: "text-left",
                    },
                    { text: "위탁종료시 또는 실효후 5년경과시", rowspan: "2" },
                  ],
                  [
                    { text: "건강나이측정하기", class: "bg-green-ligth" },
                    { text: "[필수] 생년월일, 평소 식생활, 운동횟수, 흡연여부, 음주량, 건강검진횟수, B형간염여부 [선택] 없음", class: "text-left" },
                  ],
                  [
                    { text: "온라인전문가 상담 및 답변", class: "bg-green-ligth" },
                    { text: "[필수] 이름, 이메일, 상담내용 [선택] 없음", class: "text-left" },
                    { text: "1년" },
                  ],
                ],
              },
            },
            { text: policy.depth0.title_1.text_2 },
            { text: policy.depth0.title_1.text_3 },
            { text: policy.depth0.title_1.text_4 },
            { text: policy.depth0.title_1.text_5 },
          ],
        },
        {
          title: policy.depth0.title_2.title,
          contents: [
            { text: policy.depth0.title_2.text_1 },
            { text: policy.depth0.title_2.text_2 },
            { text: policy.depth0.title_2.text_3 },
            { text: policy.depth0.title_2.text_4 },
            { text: policy.depth0.title_2.text_5 },
          ],
        },
        {
          title: policy.depth0.title_3.title,
          contents: [
            { text: policy.depth0.title_3.text_1 },
            { text: policy.depth0.title_3.text_2 },
            { text: policy.depth0.title_3.text_3 },
            { text: policy.depth0.title_3.text_4 },
            {
              table: {
                columns: [
                  { style: { width: "180px" }, text: "제공받는 자", class: "bg-green-ligth" },
                  { style: { width: "180px" }, text: "제공받는 목적", class: "bg-green-ligth" },
                  { style: { width: "400px" }, text: "제공하는 개인정보의 항목", class: "bg-green-ligth" },
                  { style: { width: "180px" }, text: "보유 및 이용기간", class: "bg-green-ligth" },
                ],
                row: [
                  [
                    { text: "희망 검진예약 기관", button: true },
                    { text: "건강검진 예약 서비스 이용, 검진 전 약품 배송" },
                    { text: "가입기업명, 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 예약일, 검진과목, 주소, 가족관계, 이메일, 검진요청사항	" },
                    { text: "이용 목적 달성 또는 보유기간 경과 시" },
                  ],
                ],
              },
            },
          ],
        },
        {
          title: policy.depth0.title_4.title,
          contents: [
            { text: policy.depth0.title_4.text_1 },
            { text: policy.depth0.title_4.text_2 },
            { table: policy.depth0.title_4.table },
            { text: policy.depth0.title_4.text_3 },
          ],
        },
        {
          title: policy.depth0.title_5.title,
          contents: [
            { text: policy.depth0.title_5.text_1 },
            { text: policy.depth0.title_5.text_2 },
            { text: policy.depth0.title_5.text_3 },
            { text: policy.depth0.title_5.text_4 },
            { text: policy.depth0.title_5.text_5 },
          ],
        },
        {
          title: policy.depth0.title_6.title,
          contents: [{ text: policy.depth0.title_6.text_1 }],
        },
        {
          title: policy.depth0.title_7.title,
          contents: [
            { text: policy.depth0.title_7.text_1 },
            { text: policy.depth0.title_7.text_2 },
            { text: policy.depth0.title_7.text_3 },
            { text: policy.depth0.title_7.text_4 },
          ],
        },
        {
          title: policy.depth0.title_8.title,
          contents: [{ text: policy.depth0.title_8.text_1 }],
        },
        {
          title: policy.depth0.title_9.title,
          contents: [
            { text: policy.depth0.title_9.text_1 },
            { table: policy.depth0.title_9.table },
            { text: policy.depth0.title_9.text_2 },
            { text: policy.depth0.title_9.text_3 },
            { text: policy.depth0.title_9.text_4 },
            { text: policy.depth0.title_9.text_5 },
          ],
        },
        {
          title: policy.depth0.title_10.title,
          contents: [{ text: policy.depth0.title_10.text_1 }, { text: policy.depth0.title_10.text_1 }],
        },
        {
          title: policy.depth0.title_11.title,
          contents: [{ text: policy.depth0.title_11.text_1 }],
        },
        {
          title: policy.depth0.title_12.title,
          contents: [{ text: policy.depth0.title_12.text_1 }, { text: policy.depth0.title_12.text_2 }],
        },
      ],
    },
    "depth-1": {
      p: policy.depth0.p("교보생명 New헬스케어서비스"),
      row: [
        {
          title: policy.depth0.title_1.title,
          contents: [
            { text: policy.depth0.title_1.text_1 },
            { table: policy.depth1.title_1.table },
            { text: policy.depth0.title_1.text_2 },
            { text: policy.depth0.title_1.text_3 },
            { text: policy.depth0.title_1.text_4 },
            { text: policy.depth0.title_1.text_5 },
          ],
        },
        {
          title: policy.depth0.title_2.title,
          contents: [
            { text: policy.depth0.title_2.text_1 },
            { text: policy.depth0.title_2.text_2 },
            { text: policy.depth0.title_2.text_3 },
            { text: policy.depth0.title_2.text_4 },
          ],
        },
        {
          title: policy.depth0.title_3.title,
          contents: [
            { text: policy.depth0.title_3.text_1 },
            { text: policy.depth0.title_3.text_2 },
            { text: policy.depth0.title_3.text_3 },
            { text: policy.depth0.title_3.text_4 },
            {
              table: policy.depth1.title_3.table,
            },
          ],
        },
        {
          title: policy.depth0.title_4.title,
          contents: [
            { text: policy.depth0.title_4.text_1 },
            { text: policy.depth0.title_4.text_2 },
            {
              table: policy.depth0.title_4.table,
            },
            { text: policy.depth0.title_4.text_3 },
          ],
        },
        {
          title: policy.depth0.title_5.title,
          contents: [
            { text: policy.depth1.title_5.text_1 },
            { text: policy.depth1.title_5.text_2 },
            { text: policy.depth1.title_5.text_3 },
            { text: policy.depth1.title_5.text_4 },
            { text: policy.depth1.title_5.text_5 },
            { text: policy.depth1.title_5.text_6 },
          ],
        },
        {
          title: policy.depth0.title_6.title,
          contents: [{ text: policy.depth0.title_6.text_1 }],
        },
        {
          title: policy.depth0.title_7.title,
          contents: [
            { text: policy.depth0.title_7.text_1 },
            { text: policy.depth0.title_7.text_2 },
            { text: policy.depth0.title_7.text_3 },
            { text: policy.depth0.title_7.text_4 },
          ],
        },
        {
          title: policy.depth0.title_8.title,
          contents: [
            { text: policy.depth0.title_8.text_1 },
            { text: policy.depth0.title_8.text_2 },
            { text: policy.depth0.title_8.text_3 },
            { text: policy.depth0.title_8.text_4 },
          ],
        },
        {
          title: policy.depth0.title_9.title,
          contents: [{ text: policy.depth0.title_9.text_1 }, { table: policy.depth0.title_9.table }],
        },
        {
          title: policy.depth1.title_10.title,
          contents: [{ text: policy.depth1.title_10.text_1 }, { table: policy.depth1.title_10.table }],
        },
        {
          title: policy.depth1.title_11.title,
          contents: [{ text: policy.depth1.title_11.text_1 }, { table: policy.depth1.title_11.table }],
        },
        {
          title: policy.depth1.title_12.title,
          contents: [{ text: policy.depth0.title_10.text_1 }, { text: policy.depth0.title_10.text_2 }],
        },
        {
          title: policy.depth1.title_13.title,
          contents: [{ text: policy.depth0.title_11.text_1 }],
        },
        {
          title: policy.depth1.title_13.title,
          contents: [
            { text: policy.depth0.title_11.text_1 },
            { text: policy.depth0.title_11.text_2 },
            { text: policy.depth0.title_11.text_3 },
            { text: policy.depth0.title_11.text_4 },
            { text: policy.depth0.title_11.text_5 },
            { text: policy.depth0.title_11.text_6 },
          ],
        },
      ],
    },
    "depth-2": {
      p: policy.depth1.p("New헬스케어서비스", "2019년 1월 30일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth2.title_4.title,
          contents: [
            { text: policy.depth2.title_4.text_1 },
            { text: policy.depth2.title_4.text_2 },
            { text: policy.depth2.title_4.text_3 },
            { text: policy.depth2.title_4.text_4 },
            { text: policy.depth2.title_4.text_5 },
            { text: policy.depth2.title_4.text_6 },
            { text: policy.depth2.title_4.text_7 },
            { text: policy.depth2.title_4.text_8 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth2.title_8.title,
          contents: [
            { text: policy.depth2.title_8.text_1 },
            { text: policy.depth2.title_8.text_2 },
            { text: policy.depth2.title_8.text_3 },
            { text: policy.depth2.title_8.text_4 },
            { text: policy.depth2.title_8.text_5 },
            { text: policy.depth2.title_8.text_6 },
            { text: policy.depth2.title_8.text_7 },
            { text: policy.depth2.title_8.text_8 },
            { text: policy.depth2.title_8.text_9 },
            { text: policy.depth2.title_8.text_10 },
            { text: policy.depth2.title_8.text_11 },
            { text: policy.depth2.title_8.text_12 },
            { text: policy.depth2.title_8.text_13 },
            { text: policy.depth2.title_8.text_14 },
            { text: policy.depth2.title_8.text_15 },
            { text: policy.depth2.title_8.text_16 },
            { text: policy.depth2.title_8.text_17 },
            { text: policy.depth2.title_8.text_18 },
            { text: policy.depth2.title_8.text_19 },
            { text: policy.depth2.title_8.text_20 },
            { text: policy.depth2.title_8.text_21 },
            { text: policy.depth2.title_8.text_22 },
            { text: policy.depth2.title_8.text_23 },
            { text: policy.depth2.title_8.text_24 },
          ],
        },
      ],
    },
    "depth-3": {
      p: policy.depth3.p("교보New헬스케어", "2017년 8월 25일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth2.title_4.title,
          contents: [
            { text: policy.depth2.title_4.text_1 },
            { text: policy.depth2.title_4.text_2 },
            { text: policy.depth2.title_4.text_3 },
            { text: policy.depth2.title_4.text_4 },
            { text: policy.depth2.title_4.text_5 },
            { text: policy.depth2.title_4.text_6 },
            { text: policy.depth2.title_4.text_7 },
            { text: policy.depth2.title_4.text_8 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth2.title_8.title,
          contents: [
            { text: policy.depth2.title_8.text_1 },
            { text: policy.depth2.title_8.text_2 },
            { text: policy.depth2.title_8.text_3 },
            { text: policy.depth2.title_8.text_4 },
            { text: policy.depth2.title_8.text_5 },
            { text: policy.depth2.title_8.text_6 },
            { text: policy.depth2.title_8.text_7 },
            { text: policy.depth2.title_8.text_8 },
            { text: policy.depth2.title_8.text_9 },
            { text: policy.depth2.title_8.text_10 },
            { text: policy.depth2.title_8.text_11 },
            { text: policy.depth2.title_8.text_12 },
            { text: policy.depth2.title_8.text_13 },
            { text: policy.depth2.title_8.text_14 },
            { text: policy.depth2.title_8.text_15 },
            { text: policy.depth2.title_8.text_16 },
            { text: policy.depth2.title_8.text_17 },
            { text: policy.depth2.title_8.text_18 },
            { text: policy.depth2.title_8.text_19 },
            { text: policy.depth2.title_8.text_20 },
            { text: policy.depth2.title_8.text_21 },
            { text: policy.depth2.title_8.text_22 },
            { text: policy.depth2.title_8.text_23 },
            { text: policy.depth2.title_8.text_24 },
          ],
        },
      ],
    },
    "depth-4": {
      p: policy.depth1.p("New헬스케어서비스", "2017년 12월 4일"),
      row: [
        {
          title: policy.depth2.title_1.title,
          contents: [{ text: policy.depth2.title_1.text_1 }, { text: policy.depth2.title_1.text_2 }],
        },
        {
          title: policy.depth2.title_2.title,
          contents: [{ text: policy.depth2.title_2.text_1 }],
        },
        {
          title: policy.depth2.title_3.title,
          contents: [
            { text: policy.depth2.title_3.text_1 },
            { text: policy.depth2.title_3.text_2 },
            { text: policy.depth2.title_3.text_3 },
            { text: policy.depth2.title_3.text_4 },
          ],
        },
        {
          title: policy.depth4.title_4.title,
          contents: [
            { text: policy.depth4.title_4.text_1 },
            { text: policy.depth4.title_4.text_2 },
            { text: policy.depth4.title_4.text_3 },
            { text: policy.depth4.title_4.text_4 },
            { text: policy.depth4.title_4.text_5 },
            { text: policy.depth4.title_4.text_6 },
            { text: policy.depth4.title_4.text_7 },
            { text: policy.depth4.title_4.text_8 },
            { text: policy.depth4.title_4.text_9 },
            { text: policy.depth4.title_4.text_10 },
            { text: policy.depth4.title_4.text_11 },
            { text: policy.depth4.title_4.text_12 },
            { text: policy.depth4.title_4.text_13 },
            { text: policy.depth4.title_4.text_14 },
            { text: policy.depth4.title_4.text_15 },
            { text: policy.depth4.title_4.text_16 },
          ],
        },
        {
          title: policy.depth2.title_5.title,
          contents: [{ text: policy.depth2.title_5.text_1 }],
        },
        {
          title: policy.depth2.title_6.title,
          contents: [{ text: policy.depth2.title_6.text_1 }],
        },
        {
          title: policy.depth2.title_7.title,
          contents: [
            { text: policy.depth2.title_7.text_1 },
            { text: policy.depth2.title_7.text_2 },
            { text: policy.depth2.title_7.text_3 },
            { text: policy.depth2.title_7.text_4 },
            { text: policy.depth2.title_7.text_5 },
          ],
        },
        {
          title: policy.depth4.title_8.title,
          contents: [
            { text: policy.depth4.title_8.text_1 },
            { text: policy.depth4.title_8.text_2 },
            { text: policy.depth4.title_8.text_3 },
            { text: policy.depth4.title_8.text_4 },
            { text: policy.depth4.title_8.text_5 },
            { text: policy.depth4.title_8.text_6 },
            { text: policy.depth4.title_8.text_7 },
            { text: policy.depth4.title_8.text_8 },
            { text: policy.depth4.title_8.text_9 },
            { text: policy.depth4.title_8.text_10 },
            { text: policy.depth4.title_8.text_11 },
            { text: policy.depth4.title_8.text_12 },
            { text: policy.depth4.title_8.text_13 },
            { text: policy.depth4.title_8.text_14 },
            { text: policy.depth4.title_8.text_15 },
            { text: policy.depth4.title_8.text_16 },
          ],
        },
      ],
    },
  },
};
