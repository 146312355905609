<template>
  <b-container class="hc-container">
    <div class="border-bottom border-top border-green">
      <template v-if="type === 'newkyobohealthcare'">
        <hc-collapse
          v-for="(row, i) in newHealthLists"
          :title="row.type"
          :content="row.items"
          :idx="i"
          :key="i"
          :class="[newHealthLists.length === i + 1 ? '' : 'border-bottom border-gray-5']"
        ></hc-collapse>
      </template>
      <template v-else>
        <hc-collapse
          v-for="(row, i) in lists"
          :title="row.type"
          :content="row.items"
          :idx="i"
          :key="i"
          :class="[lists.length === i + 1 ? '' : 'border-bottom border-gray-5']"
        ></hc-collapse>
      </template>
    </div>
  </b-container>
</template>

<script>
import HcCollapse from "@/components/common/HcCollapse";
import common from "@/mixins/common.js";

export default {
  mixins: [common],
  components: {
    HcCollapse,
  },
  data() {
    return {
      newHealthLists: [
        {
          type: "교보New헬스케어서비스는 무엇인가요?",
          items: `
          <span class="d-block mb-2">교보New헬스케어서비스는 교보생명 해당 고객을 위해 제공되는 건강관리, 치료지원/질병관리 등을 제공하는 고객님들을 위한 토탈 헬스케어서비스입니다.</span>
          <span class="d-block mb-2">※ 교보생명 FP 및 직원은 건강강좌, 질병예방 프로그램 등 온라인 컨텐츠만을 이용하실 수 있습니다.</span>
          `,
        },
        {
          type: "교보New헬스케어서비스는 어떻게 신청하면 될까요?",
          items: `
            <span class="d-block mb-2">회원가입은 교보생명의 상기 1항 대상 상품별 주계약 가입기준 이상에 가입하신후 교보New헬스케어서비스 회원가입신청서를 FP를 통해 제출 하시면 회원가입이 이루어지게 됩니다.</span>
            <span class="d-block mb-2">단, 처음 방문하실 때에는 아이디,패스워드가 없으므로 서비스대상자 여부를 조회 하신후 로그인 하시면 됩니다. 그리고 처음 로그인 후에는 아이디와 패스워드를 재설정 한 후 이용해 주시면 됩니다.</span>
            `,
        },
        {
          type: "교보New헬스케어서비스 콜센터는 무엇인가요?",
          items: `
            <span class="d-block mb-2">교보New헬스케어에서 제공하는 헬스콜센터는 고객 여러분께서 전화상으로 건강상담을 문의하실 때 일차로 간호사의 상담접수 후, 14개과 전문의사에게 연결되는 일련의 전화상담시스템을 의미합니다.</span>
            <span class="d-block mb-2">고객님께서 헬스콜센터를 통해 의학과 관련된 질문을 받게 되면 1차는 간호사가 상담에 대한 답변을, 심도있는 질문을 할 경우에는 14개과의 전문의사에게 연결되어 답변을 해드리게 됩니다.</span>
            <span class="d-block mb-2">의료진과의 상담이 끝나게 되면 처음 접수했던 담당 간호사가 고객님께서 질문하셨던 상담내용과, 그와 관련된 건강정보를 메일로 발송해 드립니다.</span>
            <span class="d-block mb-4">상담후 상담내용은 사이트 상단메뉴의 “내 서비스 내역 > 이용내역 > 전화상담”을 통해 확인 하실 수 있습니다.</span>
            <span class="fw-7">헬스콜센터 : 1588-7524(치료의사)</span>
          `,
        },
        {
          type: "교보New헬스케어서비스 헬스플래너는 어떻게 신청하나요?",
          items: `
            <span class="d-block mb-2">헬스플래너는 고객 여러분의 건강을 관리하고 증진시켜 드리기 위해 조직된 임상경력 3년 이상의 숙련된 간호사를 의미합니다.</span>
            <span class="d-block mb-2">헬스플래너의 이용을 원하시는 고객분은 헬스콜센터(1588-7524)를 통해 신청할 수 있습니다.</span>
            <span class="d-block mb-2">신청에 대한 접수를 받게 되면 24시간 이내 확인 후 고객님과 연락 후 담당 간호사가 방문을 하게 됩니다.</span>
          `,
        },
        {
          type: "교보New헬스케어서비스 탈퇴가 가능한가요?",
          items: `<sapn></sapn>교보New헬스케어서비스는 서비스대상 해당보험의 주계약 가입기준 이상(단, 보험유지건에 한함)에 가입된 고객님께 제공해 드리는 서비스로 보험이 실효, 해약, 해지등에 해당 되면 자동적으로 탈퇴가 이루어지게 됩니다."</span>`,
        },
      ],
      lists: [
        {
          type: "교보헬스케어서비스란 무엇입니까?",
          items: `
          <span class="d-block mb-2">교보헬스케어서비스는 교보생명 해당 고객을 위해 제공되는 건강관리, 치료지원/질병관리 등을 제공하는 고객님들을 위한 토탈 헬스케어서비스입니다.</span>
          <span class="d-block mb-2">※ 교보생명 FP 및 직원은 건강강좌, 질병예방 프로그램 등 온라인 컨텐츠만을 이용하실 수 있습니다.</span>
          `,
        },
        {
          type: "교보헬스케어서비스 신청방법",
          items: `
            <span class="d-block mb-2">회원가입은 교보생명의 상기 1항 대상 상품별 주계약 가입기준 이상에 가입하신후 교보헬스케어서비스 회원가입신청서를 FP를 통해 제출 하시면 회원가입이 이루어지게 됩니다.</span>
            <span class="d-block mb-2">단, 처음 방문하실 때에는 아이디,패스워드가 없으므로 서비스대상자 여부를 조회 하신후 로그인 하시면 됩니다. 그리고 처음 로그인 후에는 아이디와 패스워드를 재설정 한 후 이용해 주시면 됩니다.</span>
            `,
        },
        {
          type: "헬스콜센터는 무엇인가요?",
          items: `
            <span class="d-block mb-2">교보헬스케어에서 제공하는 헬스콜센터는 고객 여러분께서 전화상으로 건강상담을 문의하실 때 일차로 간호사의 상담접수 후, 13개과 전문의사에게 연결되는 일련의 전화상담시스템을 의미합니다.</span>
            <span class="d-block mb-2">고객님께서 헬스콜센터를 통해 의학과 관련된 질문을 받게 되면 1차는 간호사가 상담에 대한 답변을, 심도있는 질문을 할 경우에는 13개과의 전문의사에게 연결되어 답변을 해드리게 됩니다.</span>
            <span class="d-block mb-2">의료진과의 상담이 끝나게 되면 처음 접수했던 담당 간호사가 고객님께서 질문하셨던 상담내용과, 그와 관련된 건강정보를 메일로 발송해 드립니다.</span>
            <span class="d-block mb-4">상담후 상담내용은 사이트 상단메뉴의 “내 서비스 내역 >이용내역 >전화상담”을 통해 확인 하실 수 있습니다.</span>
            <span class="fw-7">헬스콜센터 : 1588-7524(치료의사)</span>
          `,
        },
        {
          type: "헬스플래너는 어떻게 신청하나요?",
          items: `
            <span class="d-block mb-2">헬스플래너는 고객 여러분의 건강을 관리하고 증진시켜 드리기 위해 조직된 임상경력 3년 이상의 숙련된 간호사를 의미합니다.</span>
            <span class="d-block mb-2">헬스플래너의 이용을 원하시는 고객분은 헬스콜센터(1588-7524)를 통해 신청할 수 있습니다.</span>
            <span class="d-block mb-2">신청에 대한 접수를 받게 되면 24시간 이내 확인 후 고객님과 연락 후 담당 간호사가 방문을 하게 됩니다.</span>
          `,
        },
        {
          type: "플라스틱 회원카드는 무엇인가요?",
          items:
            "플라스틱 회원카드는 교보헬스케어서비스를 제공할 때 회원님을 식별할 수 있는 수단이였습니다. 하지만 분실 위험, 서비스 안내 부족 등 문제점이 많았기에 플라스틱 회원카드 발급을 중단하고, 교보헬스케어서비스 홈페이지 내 모바일 맴버십카드로 대체하였습니다.",
        },
        {
          type: "플라스틱 회원카드는 분실했어요. 다시 받을 수 있을까요?",
          items:
            "플라스틱 회원카드 발급은 중지되었습니다. 교보헬스케어서비스 홈패이지 내 모바일 맴버십카드로 회원인증을 하실 수 있습니다.",
        },
        {
          type: "교보헬스케어서비스 탈퇴가 가능한가요?",
          items: `<sapn></sapn>교보헬스케어서비스는 서비스대상 해당보험의 주계약 가입기준 이상(단, 보험유지건에 한함)에 가입된 고객님께 제공해 드리는 서비스로 보험이 실효, 해약, 해지등에 해당 되면 자동적으로 탈퇴가 이루어지게 됩니다."</span>`,
        },
      ],
    };
  },
  mounted() {
    this.pageViewTrack(this.title);
  }
};
</script>

<style lang="scss" scoped></style>
