var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('b-btn',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`accordion-${_vm.idx}`),expression:"`accordion-${idx}`"}],staticClass:"flex-between w-100 gray fw-7 py-3 px-2 text-left fs-20 fs-md-18",attrs:{"variant":"link","role":"tab"}},[_vm._v(_vm._s(_vm.title)+" "),_c('span',[_c('i',{class:[
            _vm.isShow ? 'icon-minus' : 'icon-plus',
            'theme-color-1 fs-30',
          ]})])])],1),(!_vm.isVideo)?_c('b-collapse',{staticClass:"border-top border-gray-5",attrs:{"id":`accordion-${_vm.idx}`,"accordion":`my-accordion`,"visible":_vm.setVisible,"role":"tabpanel"},on:{"input":_vm.input}},[_c('div',{staticClass:"p-4"},[(_vm.title !== '예방접종 스케줄(표)')?_c('p',{class:_vm.contentClass,domProps:{"innerHTML":_vm._s(_vm.manufactureContent)}}):_c('schedule-table',{attrs:{"isExplanation":true}})],1)]):_c('b-collapse',{attrs:{"id":`accordion-${_vm.idx}`,"accordion":`my-accordion`,"visible":_vm.setVisible,"role":"tabpanel"},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"default",fn:function({ visible }){return [_c('div',{staticClass:"position-relative",style:({
        height: '40vw',
        maxHeight: '500px',
      })},[_c('b-overlay',{staticClass:"w-100 h-100",attrs:{"show":_vm.loading,"rounded":"sm"}},[(visible)?_c('iframe',{staticClass:"position-absolute py-2",style:({
            left: 0,
            right: 0,
          }),attrs:{"src":_vm.movieLink,"frameborder":"0","width":"100%","height":"100%","allowfullscreen":"allowfullscreen"},on:{"load":_vm.test}}):_vm._e()])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }