<template>
  <div ref="map" class="w-100"></div>
</template>

<script>
export default {
  props: {
    x: {
      type: String,
      default: "",
    },
    y: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    window.kakao && window.kakao.maps
      ? this.initMap()
      : this.addKakaoMapScript();
  },
  methods: {
    // 카카오 맵 추가
    addKakaoMapScript() {
      const script = document.createElement("script");
      script.onload = () => kakao.maps.load(this.initMap);
      script.src = `//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${process.env.VUE_APP_KAKAO_MAP_KEY}&libraries=services`;
      document.head.appendChild(script);
    },
    initMap() {
      const mapContainer = this.$refs["map"]; // 지도를 표시할 div
      const mapOption = {
        center: new kakao.maps.LatLng(this.y, this.x), // 지도의 중심좌표
        level: 3, // 지도의 확대 레벨
      };
      this.map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다

      // // 커스텀 오버레이
      this.customOverlay();
    },
    customOverlay() {
      // 커스텀 오버레이에 표시할 내용입니다
      // HTML 문자열 또는 Dom Element 입니다
      const content = `
        <div class="customoverlay">
          <div>
            <span class="title">${this.name}</span>
            <span class="text">${this.address}</span>
          </div>
        </div>`;

      // 커스텀 오버레이가 표시될 위치입니다
      const position = new kakao.maps.LatLng(this.y, this.x);

      // 커스텀 오버레이를 생성합니다
      const customOverlay = new kakao.maps.CustomOverlay({
        position: position,
        content: content,
        xAnchor: 0.5,
        yAnchor: 0.3,
      });

      // 커스텀 오버레이를 지도에 표시합니다
      customOverlay.setMap(this.map);
    },
  },
  beforeDestroy() {
    this.map = null;
  },
};
</script>
<style>
.customoverlay {
  position: relative;
  bottom: 85px;
  border-radius: 6px;
  background: #fff;
  float: left;
  padding: 1rem 1.4rem;
}
.customoverlay:nth-of-type(n) {
  border: 0;
  box-shadow: 0px 1px 2px #888;
}
.customoverlay a {
  display: block;
  text-decoration: none;
  color: #000;
  overflow: hidden;
}
.customoverlay .title {
  display: block;
  font-size: 1rem;
  font-weight: bold;
}
.customoverlay .text {
  display: block;
  color: #5d5d5d;
  font-size: 0.8rem;
}
/* 말풍선 꼬리 이미지(그림자효과 포함) (임시 이미지) */
.customoverlay:after {
  content: "";
  position: absolute;
  margin-left: -12px;
  left: 50%;
  bottom: -12px;
  width: 22px;
  height: 12px;
  background: url("https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png");
}
</style>
