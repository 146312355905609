var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"sub-tab-wrap bg-white"},[(_vm.scroll)?[_c('b-list-group',{directives:[{name:"b-scrollspy",rawName:"v-b-scrollspy",value:({ offset: _vm.offset }),expression:"{ offset }"}],staticClass:"px-0 d-block position-relative"},[_c('tab-swiper',{ref:"sub-tab-swiper",staticClass:"tab",attrs:{"items":_vm.items,"isNaviHide":true,"options":{
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          spaceBetween: 20,
          navigation: {
            nextEl: '.sub-tab-next',
            prevEl: '.sub-tab-prev',
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function({ tab, idx }){return [_c('b-nav-item',{key:idx,class:[_vm.tagetTab === idx ? 'active' : '', 'sub-tab-item w-100 gray-4 text-center rounded-pill bg-gray-6 fs-20 fs-md-18'],attrs:{"href":`#sub-tab-${idx}`},on:{"click":function($event){$event.preventDefault();return _vm.tabBtnClickHandle(`#sub-tab-${idx}`)}}},[_vm._v(" "+_vm._s(tab)+" ")])]}}],null,false,745318498)})],1)]:[_c('b-nav',{staticClass:"px-0"},[_c('tab-swiper',{ref:"sub-tab-swiper",staticClass:"tab",attrs:{"items":_vm.items,"isNaviHide":true,"options":{
          spaceBetween: 10,
          navigation: {
            nextEl: '.sub-tab-next',
            prevEl: '.sub-tab-prev',
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function({ tab, idx }){return [_c('b-nav-item',{key:idx,class:[
            _vm.tagetTab === tab.value ? 'active' : '',
            'sub-tab-item w-100 gray-4 text-center rounded-pill bg-gray-6 fs-20 fs-md-18',
          ],attrs:{"to":{ ..._vm.$route, query: { tab: tab.value } }}},[_vm._v(" "+_vm._s(typeof tab == "object" ? tab.text : tab)+" ")])]}}])})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }