export default {
  p: (
    title,
    date = "2017년 12월 4일"
  ) => `<(주)녹십자헬스케어>는 '${title}('https://www.kyobohealthcare.com')'를 운영함에 있어 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)및 개인정보보호법에 따라 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
  회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
  </br>
  </br>
  본 방침은 ${date}부터 시행됩니다.
  </br>
  </br>
  (주)녹십자헬스케어는 "개인정보보호 관련 법령" 에 따라 아래와 같이 개인정보보호지침을 준수하고 있습니다.</br>
  - 개인정보의 수집 및 이용 목적</br>
  - 수집하려는 개인정보의 항목</br>
  - 개인정보의 보유 및 이용기간</br>
  - 개인정보의 제3자 제공에 관한 사항</br>
  - 기술적, 관리적 대책</br>
  - 정보주체의 권리·의무 및 그 행사방법에 관한 사항</br>
  - 개인 아이디와 비밀번호 관리</br>
  - 회원의 개인정보 관리 책임자</br>
  </br>`,

  title_1: {
    table: {
      columns: [
        { style: { minWidth: "180px" }, text: "제공받는 자" },
        { style: { minWidth: "180px" }, text: "제공받는 목적" },
        { text: "제공하는 개인정보의 항목" },
        { style: { minWidth: "180px" }, text: "보유 및 이용기간" },
      ],
      row: [
        [
          { text: "공통(콜센터)", rowspan: "5", class: "bg-green-ligth" },
          { text: "건강상담", class: "bg-green-ligth" },
          { text: "[필수] 이름, 생년월일, 성별, 전화번호 또는 휴대전화번호, 상담정보, 가족관계<br>[선택] 없음", class: "text-left" },
          { text: "위탁계약 종료시 또는 실효후 5년경과시", rowspan: "5" },
        ],
        [
          { text: "진료예약대행 및 사후관리", class: "bg-green-ligth" },
          {
            text:
              "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 진료의뢰서 또는 기타 의무기록 사본, 진단명, 소견내용<br>[선택] 없음",
            class: "text-left",
          },
        ],
        [
          { text: "진료동행서비스", class: "bg-green-ligth" },
          {
            text:
              "[필수] 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호, 주소, 상병명, 병기 및 질병진행정도, 치료방법 및 종류, 치료 부작용 여부, 치료 경과(재발 및 전이여부)<br>[선택] 없음",
            class: "text-left",
          },
        ],
        [
          { text: "검진예약대행 및 관리", class: "bg-green-ligth" },
          {
            text:
              "[필수] (회원) 이름, 생년월일, 성별, 휴대폰번호, 검진희망일, 검진희망기관, 주소, 관심질환, 병력, 가족력, 수술이력여부, 복용약 [선택]없음",
            class: "text-left",
          },
        ],
        [
          { text: "검진예약 만족도 조사", class: "bg-green-ligth" },
          {
            text: "[필수] 휴대전화번호, 검진받은 기관, 조사내용 [선택] 없음",
            class: "text-left",
          },
        ],
        [
          { text: "WEB서비스", rowspan: "4", class: "bg-green-ligth" },
          { text: "본인 확인", class: "bg-green-ligth" },
          { text: "[필수] 이름, 생년월일, 성별 , 내외국인여부, 휴대폰번호 [선택] 없음", class: "text-left" },
          { text: "확인즉시파기" },
        ],
        [
          { text: "회원가입 및 관리", class: "bg-green-ligth" },
          { text: "[필수] 아이디, 비밀번호, 이름, 생년월일, 성별, 내외국인여부 [선택] 없음", class: "text-left" },
          { text: "회원탈퇴 시 또는 법정 의무보유기간까지", rowspan: "2" },
        ],
        [
          { text: "건강나이측정하기", class: "bg-green-ligth" },
          { text: "[필수] 생년월일, 평소 식생활, 운동횟수, 흡연여부, 음주량, 건강검진횟수, B형간염여부 [선택] 없음", class: "text-left" },
        ],
        [
          { text: "온라인전문가 상담 및 답변", class: "bg-green-ligth" },
          { text: "[필수] 이름, 이메일, 상담내용 [선택] 없음", class: "text-left" },
          { text: "1년" },
        ],
        [
          { text: "APP서비스", rowspan: "3", class: "bg-green-ligth" },
          { text: "본인확인", class: "bg-green-ligth" },
          { text: "[필수] 이름, 생년월일, 성별 ,내외국인여부, 휴대폰번호 [선택] 없음", class: "text-left" },
          { text: "확인즉시파기" },
        ],
        [
          { text: "회원가입 및 관리", class: "bg-green-ligth" },
          { text: "[필수] 이름, 생년월일, 성별, 내외국인여부, 휴대폰번호 [선택] 없음", class: "text-left" },
          { text: "회원탈퇴 시 또는 법정 의무보유기간까지" },
        ],
        [
          { text: "복용약 알림설정", class: "bg-green-ligth" },
          { text: "[필수] 복용약 [선택] 없음", class: "text-left" },
          { text: "본인알림 설정 삭제시" },
        ],
      ],
    },
  },

  title_3: {
    table: {
      columns: [
        { style: { minWidth: "180px" }, text: "제공받는 자", class: "bg-green-ligth" },
        { style: { minWidth: "180px" }, text: "제공받는 목적", class: "bg-green-ligth" },
        { text: "제공하는 개인정보의 항목", class: "bg-green-ligth" },
        { style: { minWidth: "180px" }, text: "보유 및 이용기간", class: "bg-green-ligth" },
      ],
      row: [
        [
          { text: "희망 검진예약 기관", rowspan: "2" },
          { text: "건강검진 예약 서비스 이용" },
          { text: "가입기업명, 이름, 생년월일, 성별, 휴대전화번호 또는 전화번호" },
          { text: "이용 목적 달성 또는 보유기간 경과 시", rowspan: "2" },
        ],
        [{ text: "검진 전 약품 배송" }, { text: "예약일, 검진과목, 주소, 가족관계, 이메일, 검진요청사항	" }],
      ],
    },
  },

  title_5: {
    text_1: "① 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.",
    text_2: "② 이용자는 상담전화 1588-7524에서 이용자의 권리와 행사방법과 관련한 문의를 하실 수 있습니다.",
    text_3: "③ 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.",
    text_4:
      "④ 이용자는 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 부정확한 정보 입력으로 말미암아 발생하는 사고의 책임은 이용자에게 있으며 타인의 정보를 무단으로 사용하는 등 허위정보를 입력하면 회원자격이 상실될 수 있습니다.",
    text_5:
      "⑤ 이용자는 개인정보를 보호받을 권리와 함께 자신을 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 지니고 있습니다. 비밀번호(Password)를 포함한 이용자의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보를 훼손할 때에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등에 의해 처벌받을 수 있습니다.",
    text_6: "⑥ 이용자는 “개인정보 보호법” 등 기타 정보보호 및 개인정보 관련 법률을 준수하여야 합니다.",
  },
  title_10: {
    title: "10. 개인정보 열람∙정정∙처리정지∙삭제 청구",
    text_1:
      "이용자는 언제든지 등록된 귀하의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보는 홈페이지에서 직접 열람하거나 정정할 수 있으며 개인정보보호책임자 또는 담당자 에게 전화, 이메일(E-mail)로 열람∙정정∙처리정지∙삭제를 요청하시면 곧바로 조치하겠습니다.",
    table: {
      columns: [
        {
          text: "개인정보 열람∙정정∙처리정지∙삭제 청구 접수∙처리",
          class: "bg-green-ligth",
          colspan: "2",
          style: { width: "700px" },
        },
      ],
      row: [
        [{ text: "대표전화	1588-1001" }, { text: "인터넷홈페이지	www.kyobo.co.kr" }],
        [{ text: "인터넷홈페이지" }, { text: "www.kyobo.co.kr" }],
      ],
    },
  },
  title_11: {
    title: "11. 앱 접근권한",
    text_1:
      "회사는 모바일APP 사용 시 아래의 이동통신단말기 기능에 접근합니다. 접근권한 중 선택항목의 경우, 거부하신다 하여도 서비스 이용이 가능하며, APP 내 설정을 통해 변경하실 수 있습니다.",
    table: {
      columns: [
        { text: "접근권한", class: "bg-green-ligth", style: { width: "230px" } },
        { text: "구분", class: "bg-green-ligth", style: { width: "230px" } },
        { text: "필요 사유", class: "bg-green-ligth", style: { width: "230px" } },
      ],
      row: [[{ text: "없음", colspan: "3" }]],
    },
  },
  title_12: {
    title: "12. 링크 사이트",
  },
  title_13: {
    title: "13. 권익침해 구제방법",
  },
  title_14: {
    title: "14. 개인정보 처리방침 변경",
  },
};
