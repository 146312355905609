import { camelCase } from "lodash";
export default {
  computed: {
    meta() {
      return this.$route.meta;
    },
    params() {
      return this.$route.params;
    },
    query() {
      return this.$route.query;
    },
    id() {
      return this.$route.params.id;
    },
    type() {
      return this.$route.params.type;
    },
    cameledType() {
      return camelCase(this.type);
    },
    key() {
      return this.params.key;
    },
    cameledKey() {
      return camelCase(this.params.key);
    },
    gnb() {
      return Array.isArray(this.$t("gnb")) ? this.$t("gnb") : null;
    },
    sideMenu() {
      return this.gnb?.filter((el) => el.isMenu);
    },
    headerTabs() {
      return this.$t("headerTabs");
    },
    targetId() {
      return this.$route.name.split("-")[0];
    },
    targetRouteInfo() {
      const routeInfo = this.gnb?.find((el) => el.id === this.targetId);
      return !this.headerTab ? routeInfo : this.headerTab.find((el) => el.key === this.key);
    },
    headerTab() {
      return this.headerTabs[this.targetId];
    },
    title() {
      if (this.meta.main) return null;
      return this.targetRouteInfo?.text;
    },
    subTab() {
      if (!this.key) return null;
      let compareVar = ["therapy"].includes(this.key) ? this.id : this.key;
      if (typeof this.$t("subTabs") !== "object") return null;
      return this.$t("subTabs").find((el) => el.key === compareVar);
    },
  },
  methods: {
    getAppName(type) {
      switch(type) {
        case "kyoboservices":
          return "상품부가서비스";
        case "kyobohealthcare":
          return "교보헬스케어서비스";
        case "newkyobohealthcare":
            return "교보New헬스케어서비스";
        case "kyobowomancare":
          return "교보우면헬스케어서비스";
        case "kyobokidscare":
          return "교보어린이헬스케어서비스";
        case "kyobojuniorcare":
          return "교보주니어헬스케어서비스";
        case "kyoboseniorcare":
          return "교보치매케어서비스";
        default:
          return "상품부가서비스";
      }
    },
    pageViewTrack(title) {
      const appName = this.getAppName(this.type);
      this.$gtag.pageview({
        page_title: `${appName} | ${title}`,
      })
    },
    clickTrack(title, fnc) {
      const appName = this.getAppName(this.type);
      this.$gtag.event("clickButton", {
        "event_category": `${appName} | ${fnc}`,
        "event_label": title.replace("<br/>", ""),
      });
    }
  }
};
