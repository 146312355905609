<template>
  <b-modal
    id="modal-my-page"
    size="xl"
    :hide-footer="true"
    body-class="p-0  vh-100"
    header-class="p-0 d-block border-bottom-0"
    scrollable
    @shown="showCb"
    @hide="hideCb"
    centered
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-4 px-md-5 py-3 flex-end">
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <div class="px-4 px-md-5">
      <template v-if="itemObj">
        <!-- 전화 상담  -->
        <template v-if="['mycall'].includes(targetApiKey)">
          <p class="gray-5 fs-20 mb-3">{{ date }}</p>
          <p v-html="replaceBr(itemObj.ask)" class="fs-20 mb-5"></p>
          <div :style="{ backgroundColor: '#fafafa' }" class="p-4 mb-2 fs-20">
            <span class="fw-7">콜센터</span>
            <p v-html="replaceBr(itemObj.answer)"></p>
          </div>
        </template>
        <!-- // 전화상담 -->

        <!-- 교보헬스케어 -->
        <template
          v-else-if="['healthcheck', 'psycheck'].includes(targetApiKey)"
        >
          <!-- head -->
          <survey-result-head v-bind="{ ...itemObj }"></survey-result-head>
          <!-- // head -->

          <!-- accordion -->
          <div
            v-if="['healthcheck'].includes(key)"
            class="border-top border-green"
            :style="{ marginBottom: '6rem' }"
          >
            <hc-collapse
              v-for="(row, i) in itemObj.checkAgeResult"
              v-bind="{
                ...row,
                idx: i,
              }"
              :key="i"
              class="border-bottom border-gray-5"
            ></hc-collapse>
          </div>
          <!-- // accordion -->
        </template>
        <!-- // 교보헬스케어 -->
      </template>
      <skeleton-survey-result-head v-else></skeleton-survey-result-head>
    </div>
    <!-- // 모달 바디 -->
  </b-modal>
</template>

<script>
import SurveyResultHead from "@/components/common/SurveyResultHead.vue";
import HcCollapse from "@/components/common/HcCollapse";
import SkeletonList from "@/components/common/SkeletonList";
import SkeletonSurveyResultHead from "@/components/common/SkeletonSurveyResultHead";

export default {
  components: {
    SkeletonList,
    SurveyResultHead,
    SkeletonSurveyResultHead,
    HcCollapse,
  },
  props: {
    body: {
      default: null,
    },
    date: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      itemObj: null,
    };
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    tab() {
      return this.$route.query.tab;
    },
    targetApiKey() {
      return this.key === "use" ? this.tab : this.key;
    },
  },
  methods: {
    async getDetail() {
      this.itemObj = null;
      const { kyoboApi } = this.getApis();

      if (["mycall"].includes(this.targetApiKey)) {
        if (["kyobohealthcare"].includes(this.$i18n.locale)) {
          const { data } = await kyoboApi.myPage[this.tab].detail(
            this.body.seq
          );
          this.itemObj = {
            ...data.report,
          };
          return;
        }
        const { data } = await kyoboApi.myPage[this.key].detail(this.body);
        this.itemObj = {
          ask: data.rptList[0].ASK,
          answer: data.rptList[0].ANSWER,
        };
        return;
      }
      if (["healthcheck"].includes(this.targetApiKey)) {
        const { data } = await kyoboApi.myPage[this.key].detail(
          this.body.hra_sn
        );
        this.itemObj = {
          ...data,
          content: data.hraresult,
        };
      }
      if (["psycheck"].includes(this.targetApiKey)) {
        const newBody = { ...this.body, seq: this.body.seq + "" };
        const { data } = await kyoboApi.myPage[this.key].detail(newBody);
        this.itemObj = {
          title: data.dataResult[0].G_TITLE,
          content: this.replaceBr(data.dataResult[0].G_COMMENT),
        };
      }
    },
    setFormat(obj, objKey = { title: "title", content: "content" }) {
      const newObj = Object.keys(objKey).reduce(
        (newObj, item) => ({ ...newObj, [item]: obj[objKey[item]] }),
        {}
      );
      return { ...obj, ...newObj };
    },
    setMycallBody(obj) {
      let newBody;
      switch (this.$i18n.locale) {
        case "kyobohealthcare":
          newBody = obj.seq;
          break;

        case "kyobowomancare":
          newBody = obj;
          break;
        default:
          break;
      }
      return newBody;
    },
    showCb() {
      this.getDetail();
    },
    hideCb() {
      this.itemObj = null;
    },
  },
};
</script>
