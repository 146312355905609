<template>
  <div>
    <!-- pc -->
    <div v-for="i in 5" :key="i" class="border-bottom border-gray-5">
      <div
        class="d-none d-md-flex align-items-center px-2 row"
        :style="{ height: '6.25rem' }"
      >
        <div class="col-10">
          <b-skeleton
            :animation="null"
            width="70%"
            height="2.5rem"
          ></b-skeleton>
        </div>
        <div class="col-2">
          <b-skeleton
            :animation="null"
            width="100%"
            height="2.5rem"
          ></b-skeleton>
        </div>
      </div>

      <!-- mobile -->
      <div class="d-flex d-md-none w-100 justify-content-start py-2">
        <div
          :style="{
            minWidth: '7rem',
          }"
          class="mr-4"
        >
          <b-skeleton :animation="null" width="100%" height="100%"></b-skeleton>
        </div>
        <div
          class="d-flex align-items-start justify-content-center flex-column w-100"
        >
          <b-skeleton :animation="null" width="70%" height="2rem"></b-skeleton>

          <b-skeleton :animation="null" width="50%" height="2rem"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
