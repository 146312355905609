<template>
  <b-container :style="{ marginBottom: '10rem', marginTop: '5rem' }">
    <b-skeleton
      :animation="null"
      width="40%"
      height="2rem"
      class="mb-4"
    ></b-skeleton>
    <b-skeleton
      :animation="null"
      width="60%"
      height="3rem"
      class="mb-5"
    ></b-skeleton>
    <b-skeleton
      :animation="null"
      width="80%"
      height="2rem"
      class="mb-3"
    ></b-skeleton>
    <b-row class="mx-n4">
      <b-col v-for="i in 4" :key="i" cols="12" md="6">
        <b-skeleton
          class="px-4"
          :animation="null"
          width="100%"
          height="3.5rem"
        ></b-skeleton>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
