<template>
  <b-container class="hc-container">
    <!-- 신청하기 -->
    <!-- 텍스트 박스 -->

    <p class="border rounded-10 p-3 fs-20 fs-md-18 mb-5 gray">
      우리아이 성장차트는 대한소아과학회에서 발표한 ‘2017 소아·청소년 표준
      성장도표’를 바탕으로 자녀의 키, 몸무게의 성장 정보를 또래들의 평균 성장
      정도와 비교해 볼 수 있습니다.
    </p>
    <!-- // 텍스트 박스 -->
    <!-- 사용자 정보 -->
    <hash-title :title="'사용자 정보'" :isHash="false"></hash-title>
    <w-form @submit="submit" v-slot="{ invalid }">
      <b-row
        v-if="key === 'growth-obesity'"
        class="mx-0 border-bottom border-gray-5 py-3 fs-20"
      >
        <div class="col-4">
          자녀 성별
        </div>
        <div class="col-8">
          <hc-select-default
            v-model="selectedSex"
            rules="required"
            inputClass="fs-20"
            :items="sex"
          ></hc-select-default>
        </div>
      </b-row>

      <b-row v-else class="mx-0 border-bottom border-gray-5 py-3 fs-20">
        <!-- 자녀 이름 -->
        <div class="col-4">자녀 이름</div>
        <div class="col-8">
          <template v-if="childs">
            <div v-if="!childs.length" class="red">
              ※ 아이 성장 체크 서비스는 출산 자녀가 있는 경우 이용하실 수
              있습니다.
            </div>
            <div v-else-if="childs.length === 1">
              {{ childs[0].text }}
            </div>
            <hc-select-default
              v-else
              v-model="selectedChild"
              rules="required"
              inputClass="fs-20"
              :items="childs"
            ></hc-select-default>
          </template>
          <b-skeleton
            v-else
            :animation="null"
            width="7rem"
            height="1.5rem"
          ></b-skeleton>
        </div>
        <!-- 자녀 이름 -->
      </b-row>

      <!-- 자녀키 -->
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20">
        <div class="col-4">
          자녀 키
        </div>
        <div class="col-8">
          <hc-input-default
            v-model="input.height"
            rules="max_value:210|min_value:20|required"
            type="number"
            name="키"
            inputClass="fs-20 rounded"
            :style="{ width: '11rem' }"
            :errPosLeft="true"
          >
            <template #prepend>
              <span class="px-2 py-1">cm</span>
            </template>
          </hc-input-default>
        </div>
      </b-row>
      <!--//  자녀키 -->

      <!-- 자녀 몸무게 -->
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20">
        <div class="col-4">
          자녀 몸무게
        </div>
        <div class="col-8">
          <div class="flex-start">
            <hc-input-default
              v-model="input.weight"
              type="number"
              rules="max_value:130|min_value:1|required"
              inputClass="fs-20 rounded"
              :style="{ width: '11rem' }"
              name="몸무게"
              :errPosLeft="true"
            >
              <template #prepend>
                <span class="px-2 py-1">kg</span>
              </template>
            </hc-input-default>
          </div>
        </div>
      </b-row>
      <!-- 자녀 몸무게 -->

      <!-- // 사용자 정보 -->
      <!-- 생년월일 -->
      <b-row
        v-if="key === 'growth-obesity'"
        class="mx-0 border-bottom border-gray-5 py-3 fs-20"
      >
        <div class="col-4">생년월일</div>
        <div class="col-8">
          <b-btn
            variant="link"
            class="border-0"
            @click="$bvModal.show('modal-birthday-input')"
          >
            <hc-input-default
              v-model="input.birthday"
              inputGroupClass="bg-white border rounded  px-2 black"
              inputClass="border-0 fs-20"
              rules="required"
              name="date"
              readonly
              :style="{
                maxWidth: '10rem',
              }"
              :errPosLeft="true"
            >
              <template #append>
                <div class="flex-center">
                  <i class="icon-calendar"></i>
                </div>
              </template>
            </hc-input-default>
          </b-btn>
          <modal-date-picker
            :id="'modal-birthday-input'"
            v-model="input.birthday"
          ></modal-date-picker>
        </div>
      </b-row>
      <!-- // 생년월일 -->

      <!-- 측정일 -->
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20">
        <div class="col-4">측정일</div>
        <div class="col-8">
          <b-btn
            variant="link"
            class="border-0"
            @click="$bvModal.show('modal-date-input')"
          >
            <hc-input-default
              v-model="input.regdate"
              inputGroupClass="bg-white border rounded  px-2 black"
              inputClass="border-0 fs-20"
              rules="required"
              name="date"
              readonly
              :style="{
                maxWidth: '10rem',
              }"
              :errPosLeft="true"
            >
              <template #append>
                <div class="flex-center">
                  <i class="icon-calendar"></i>
                </div>
              </template>
            </hc-input-default>
          </b-btn>
          <modal-date-picker
            :id="'modal-date-input'"
            v-model="input.regdate"
          ></modal-date-picker>
        </div>
      </b-row>
      <!-- // 측정일 -->
      <!-- 동의하기 -->
      <div class="flex-center py-4">
        <w-checkbox
          :label="'개인정보 수집 및 이용동의 (필수)'"
          :name="'개인정보 수집 및 이용동의 (필수)'"
          v-model="privacy"
          class="py-1 mr-2"
        >
          <a class="fs-20 fs-md-18">개인정보 수집 및 이용동의 (필수)</a>
        </w-checkbox>
        <b-btn variant="link" @click="$bvModal.show('modal-term')">
          <div
            class="bg-img"
            :style="{
              width: calcHeight('3rem'),
              paddingBottom: calcHeight('3rem'),
              backgroundImage: `url(${require('@/assets/img/common/ic-agree-zoom.svg')})`,
            }"
          ></div>
        </b-btn>
      </div>
      <!-- // 동의하기 -->
      <!-- 버튼 -->
      <div class="flex-center pt-2 pt-md-5">
        <b-btn
          variant="link blue-submit-btn fs-20 fs-md-18 px-5"
          @click="submit"
          :disabled="invalid"
          >성장평가</b-btn
        >
      </div>
    </w-form>
    <!-- // 버튼 -->
    <!-- // 신청하기 -->
    <modal-term
      @agree="privacy = true"
      v-bind="{ ...modalTermProps }"
    ></modal-term>
  </b-container>
</template>

<script>
import ModalTerm from "@/components/common/modal/ModalTerm";
import common from "@/mixins/common";

export default {
  mixins: [common],
  components: {
    ModalTerm,
  },
  data() {
    return {
      sex: [
        { value: 1, text: "남" },
        { value: 2, text: "여" },
      ],
      modalTermProps: {
        isPrivacy: true,
        consentKey: "grow",
      },
      privacy: false,

      selectedChild: null,
      info: null,
      selectedSex: null,
      input: {
        birthday: "",
        regdate: "",
        height: "",
        weight: "",
      },
    };
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    // key 값 변경 후 출산전 아이 제거
    childs() {
      const key = this.$i18n.locale === "kyobokidscare" ? "childs" : "children";
      return this.info?.[key]
        .map((val) => ({
          ...val,
          value: val.joinserial,
          text: val.contactname || "-",
        }))
        .filter((item) => item.bithday);
    },
  },
  methods: {
    async submit() {
      if (!this.privacy)
        return this.$root.$emit(
          "showModalAlert",
          "개인정보 수집 및 이용에 동의해 주세요."
        );
      const body = this.getBody();

      this.$router.push({
        ...this.$route,
        name: "check-grow-insert",
        query: { ...body },
      });
    },
    getBody() {
      let body = null;
      if (this.$i18n.locale === "kyobojuniorcare") {
        body = {
          ...this.input,
          sex: this.selectedSex.value,
        };
        return body;
      }
      if (this.$i18n.locale === "kyobokidscare") {
        body = {
          ...this.input,
          joinserial:
            this.childs.length === 1
              ? this.childs[0].value
              : this.selectedChild.value,
        };
        delete body.birthday;

        return body;
      }
    },
    reset() {
      this.privacy = false;
      this.$router.push({ ...this.$route, query: {} }).catch((e) => {});
    },
    async getInfo() {
      if (this.$i18n.locale === "kyobojuniorcare") return;
      const { kyoboApi } = this.getApis();
      const {
        data: { info },
      } = await kyoboApi.program.common.info();
      this.info = info;
    },
  },
  mounted() {
    this.pageViewTrack(this.title);
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped></style>
