<template>
  <div>
    <b-skeleton
      :animation="null"
      width="10%"
      height="1.5rem"
      class="mb-4"
    ></b-skeleton>
    <b-skeleton
      :animation="null"
      width="40%"
      height="2rem"
      class="mb-4"
    ></b-skeleton>
    <b-skeleton
      :animation="null"
      width="100%"
      height="10rem"
      class="mb-4"
    ></b-skeleton>
    <b-skeleton
      :animation="null"
      width="50%"
      height="1.5rem"
      class="mb-5"
    ></b-skeleton>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
