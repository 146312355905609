<template>
  <div>
    <!-- table caption -->
    <h6 v-if="tableObj.caption" class="fs-20 fs-md-18 fw-7 py-4">
      {{ tableObj.caption }}
    </h6>
    <!-- // table caption -->
    <div class="position-relative">
      <table-swiper
        ref="table-swiper"
        :options="{
          slidesPerView: 1,
          allowTouchMove: false,
          breakpoints: {
            400: {
              allowTouchMove: true,
              slidesPerView: 0.5,
            },
            720: {
              allowTouchMove: true,
              slidesPerView: 0.6,
            },
          },
          navigation: {
            nextEl: '.table-next',
            prevEl: '.table-prev',
          },
        }"
        isPopover
      >
        <template v-slot:popover></template>
        <template v-slot:content>
          <div class="mb-4 border-bottom border-top border-blue border-2 px-0 fs-16 w-100">
            <!-- table head -->
            <b-row class="fs-20 fs-md-18 fw-7 border-bottom border-blue border-2 mx-0">
              <b-col
                v-for="(th, idx) in tableObj.columns"
                :key="th.text"
                class="text-center flex-center flex-column p-2 p-md-3"
                :cols="tableObj.cols.th[idx]"
                v-html="th.text"
              >
              </b-col>
            </b-row>
            <!-- // table head -->

            <!-- table body -->
            <b-row
              v-for="(list, idx) in tableObj.rows"
              :key="`${list.title}-${idx}`"
              class="list mx-0 fs-20 fs-md-16"
              :style="{
                borderBottom: '1px solid  rgba(0, 40, 101, 0.1)',
              }"
            >
              <b-col
                v-for="(col, _idx) in list"
                :key="col.text"
                :cols="tableObj.cols.td[_idx]"
                :class="[
                  {
                    'bg-green-ligth': !_idx,
                  },

                  `p-2 p-md-3 ${tableObj.class.td[_idx]}`,
                ]"
              >
                {{ col.text }}
              </b-col>
            </b-row>
            <!-- // table body -->
          </div>
        </template>
      </table-swiper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableObj: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
