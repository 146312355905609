<template>
  <b-container class="hc-container">
    <template v-if="report">
      <!-- 질문 -->
      <div class="mb-2">
        <h4 class="fs-20 fs-md-16 gray-9" :style="{ lineHeight: '1.7' }">
          {{ formatedDate(report.regdate) }}
        </h4>
      </div>

      <div class="mb-5">
        <p
          class="gray fs-20 fs-md-18"
          v-html="setUrlLink(replaceBr(report.content))"
        ></p>
      </div>
      <!-- // 질문 -->

      <!-- 답변 -->
      <div
        :style="{ backgroundColor: '#fafafa', lineHeight: '1.7' }"
        class="p-4 p-md-5 mb-2"
      >
        <template v-if="replayReport">
          <h4 class="mb-2">
            <span class="fs-24 fs-md-20 fw-7 mr-4">{{
              replayReport.name || "운영자"
            }}</span
            ><span class="fs-20 fs-md-16 gray-9">{{
              formatedDate(replayReport.regdate)
            }}</span>
          </h4>
          <p
            class="gray fs-20 fs-md-18"
            v-html="setUrlLink(replaceBr(replayReport.content))"
          ></p>
        </template>
        <!-- 답변 -->

        <!-- no 답변 -->
        <template v-else>
          <div class="flex-center flex-column" :style="{ padding: '5rem 0' }">
            <span class="mb-4">
              <img
                src="~@/assets/img/common/ic-answer-empty-img.svg"
                alt="empty img"
                :style="{ width: '5rem' }"
              />
            </span>
            <p class="fs-20">답변 대기 중 입니다</p>
          </div>
        </template>
        <!-- // no answer -->
      </div>
      <div class="flex-center my-5">
        <b-btn
          variant="link"
          @click="$router.push({ name: listsName, params: { key } })"
          class="blue-submit-btn px-5 fs-20 fs-md-16"
          >목록보기</b-btn
        >
      </div>

      <!-- 이전글, 다음글 -->
      <prev-next v-if="key === 'online'" :btnObj="btnObj"></prev-next>
      <!-- // 이전글, 다음글 -->
    </template>
    <skeleton-list-detail v-else></skeleton-list-detail>
  </b-container>
</template>

<script>
import SkeletonListDetail from "@/components/common/SkeletonListDetail";
import PrevNext from "@/components/common/PrevNext";
import { formatedDate } from "@/utils/dayjs";

export default {
  components: {
    SkeletonListDetail,
    PrevNext,
  },
  data() {
    return {
      report: null,
      replayReport: null,
      btnObj: {
        nextReport: null,
        preReport: null,
      },
    };
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    listsName() {
      const arr = this.$route.name.split("-");
      const filtered = arr.filter((el) => el !== "detail");
      return filtered.join("-");
    },
  },
  methods: {
    async getDetail() {
      this.report = null;
      const { kyoboApi } = this.getApis();

      if (this.key === "online") {
        const { data } = await kyoboApi.onlineCounselling[this.key].detail({
          ...this.$route.query,
        });
        this.report = data.counreport;
        this.btnObj.nextReport = data.nextReport;
        this.btnObj.preReport = data.preReport;
        this.replayReport = !Object.keys(data.replayreport).length
          ? null
          : data.replayreport;
        return;
      }
      if (this.key === "best") {
        const {
          data: { report },
        } = await kyoboApi.onlineCounselling[this.key].detail(
          this.$route.query.cn_sn
        );
        this.report = { content: report.qestn };
        this.replayReport = { content: report.answer };
        return;
      }
    },
    setUrlLink(str) {
      const regex = /((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?)<br*\s?\/>/gim;
      const isUrl = str.match(regex);
      if (!isUrl) return str;
      const url = isUrl[0].replace("<br />", "");
      return str.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
    },
    formatedDate,
  },
  watch: {
    $route() {
      this.getDetail();
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped></style>
