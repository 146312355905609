<template>
  <div>
    <!-- 타이틀 -->
    <div class="border-bottom border-3 border-gray-5 pt-0 py-3">
      <b-skeleton :animation="null" width="8rem" height="1.8rem"></b-skeleton>
    </div>
    <!-- // 타이틀 -->
    <b-row
      class="mx-0 py-4 border-bottom border-gray-5"
      v-for="i in 10"
      :key="i"
    >
      <b-col class="px-0">
        <b-skeleton
          class="mb-2"
          :animation="null"
          width="60%"
          :height="calcHeight('1.8rem')"
        ></b-skeleton>
        <b-skeleton
          :animation="null"
          width="40%"
          :height="calcHeight('1.1rem')"
        ></b-skeleton>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped></style>
