<template>
  <div>
    <validation-provider
      ref="provider"
      :name="name"
      rules="required"
      v-if="['checkbox'].includes(answersType)"
    >
      <input v-show="0" type="text" :value="value" />
      <!-- 다중선택 -->
      <b-row align-v="stretch" class="mx-n2 mx-md-n3">
        <b-col
          class="px-2 px-md-3 py-2"
          cols="12"
          sm="6"
          v-for="(item, i) in answers"
          :key="i"
        >
          <b-btn
            variant="link"
            :class="[
              value[i]
                ? 'border-theme-color-1 theme-color-1'
                : 'border-black black',
              type === 'newkyobohealthcare' ? 'border-gray-3 gray-3' : ''
            ]"
            class="border rounded-10 p-3 flex-between w-100 h-100"
            @click="handleCheckBoxClick(item.value, i)"
          >
            <span class="text-left fs-20">{{ item.answertitle }}</span>
            <i class="icon-ok fs-20"></i>
          </b-btn>
        </b-col>
      </b-row>
      <!--// 다중선택 -->
    </validation-provider>
    <validation-provider
      ref="provider"
      :name="name"
      rules="required"
      v-else-if="['radio'].includes(answersType)"
    >
      <input v-show="0" type="text" :value="value" />
      <!-- 단일선택 -->
      <b-row class="mx-n2 mx-md-n3">
        <b-col
          class="px-2 px-md-3 py-2"
          cols="12"
          sm="6"
          v-for="(item, i) in answers"
          :key="i"
        >
          <b-btn
            variant="link"
            :class="[
              value[i] === item.value
                ? 'border-theme-color-1 theme-color-1'
                : 'border-black black',
              type === 'newkyobohealthcare' ? 'border-gray-3 gray-3' : ''
            ]"
            class="border rounded-10 p-3 flex-between w-100"
            @click="handleRadioClick(item.value, i)"
          >
            <span class="text-left fs-20">{{
              item.answertitle || `${i + 1} 번`
            }}</span>
            <i class="icon-ok fs-20"></i>
          </b-btn>
        </b-col>
      </b-row>
      <!--// 단일선택 -->
    </validation-provider>
    <!-- select box  -->
    <div v-else-if="answersType === 'selectbox'">
      <hc-select-default
        :items="selectBoxAnwsers"
        :value="value && value[0]"
        :styles="{ minWidth: '8rem' }"
        rules="required"
        class="w-100"
        :dropdownClass="
          value && value[0]
            ? 'border border-theme-color-1  rounded-10 p-3 w-100'
            : 'border border-black rounded-10 p-3 w-100'
        "
        @change="
          handleChange($event);
          $emit('changeSlide');
        "
      ></hc-select-default>
    </div>
    <!-- select box -->

    <!-- inputs -->
    <template v-else-if="answersType === 'input'">
      <b-row class="mx-n2 mx-md-n3">
        <b-col
          v-for="(answer, i) in answers"
          :key="`answer-input-${i}`"
          cols="6"
          sm="4"
          class="px-2 px-md-3  py-2"
        >
          <h5 class="gray fs-20 fw-7 mb-3">{{ answer.answertitle }}</h5>
          <div v-if="answer.inputType === 'number'" class="flex-start">
            <hc-input-default
              :type="answer.inputType"
              rules="max:3|required"
              :name="answer.name"
              :styles="{
                minWidth: calcHeight('8rem'),
                height: calcHeight('3.5rem'),
              }"
              :inputClass="
                value[i]
                  ? 'border-theme-color-1  rounded-10 p-3 fs-20'
                  : 'border-black rounded-10 p-3 fs-20'
              "
              :value="value[i]"
              @change="handleInputChange($event, i)"
            >
            </hc-input-default>
            <span class="ml-3">{{ answer.text }}</span>
          </div>
          <div v-else-if="answer.inputType === 'date'">
            <b-btn
              variant="link border-0"
              @click="$bvModal.show('modal-date-input')"
            >
              <hc-input-default
                class="border rounded-10 p-2 black"
                :class="value[i] ? 'border-theme-color-1 ' : 'border-black'"
                inputClass="bg-white border-0 fs-20"
                rules="required"
                :isErrorMessage="false"
                :name="answer.name"
                :value="value[i]"
                :style="{
                  minWidth: '10rem',
                }"
                readonly
              >
                <template #append>
                  <div class="flex-center">
                    <i class="icon-calendar"></i>
                  </div>
                </template>
              </hc-input-default>
            </b-btn>
            <modal-date-picker
              :id="'modal-date-input'"
              @change="handleInputChange($event, i)"
            ></modal-date-picker>
          </div>
        </b-col>
      </b-row>
    </template>
    <!-- inputs -->
  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  mixins: [common],
  props: {
    name: { tpye: String, default: "" },
    rules: { type: [String, Object] },
    value: { type: Array, default: () => [] },
    answersType: { type: String },
    questiontitle: { type: String },
    examples: { type: Array },
    answers: { type: Array },
    appendTitle: { type: String, default: "" },
  },
  computed: {
    title() {
      return this.appendTitle
        ? `(${this.appendTitle})${this.questiontitle}`
        : this.questiontitle;
    },
    selectBoxAnwsers() {
      return ["selectbox"].includes(this.answersType)
        ? this.answers.map((val) => ({
            value: val.value,
            text: val.answertitle,
          }))
        : null;
    },
  },
  methods: {
    handleCheckBoxClick(value, index) {
      let newValue = [];
      // 모름 선택 시 다른 선택지 제거
      if (value === -1) {
        newValue[index] = value;
        return this.$emit("input", newValue);
      }
      newValue = [...this.value];
      newValue[index] = newValue[index] ? null : value;

      // 모름 선택지 제거
      let filtedNewValue = newValue.includes(-1)
        ? newValue.filter((val) => val !== -1)
        : newValue;
      return this.$emit("input", filtedNewValue);
    },
    handleRadioClick(value, index) {
      let newValue = [];
      newValue[index] = value;

      this.$emit("input", newValue);
      this.$emit("changeSlide");
    },
    handleInputChange(val, i) {
      let newValue = [...this.value];
      newValue[i] = val;
      this.handleChange(newValue);
    },
    handleChange(val) {
      if (["input", "checkbox"].includes(this.answersType)) {
        this.$emit("input", val);
        return;
      }
      return this.$emit("input", [val]);
    },
  },
};
</script>
