<template>
  <b-modal
    :id="id"
    modal-class="modal-date"
    hide-footer
    centered
    header-class="p-0 d-block border-bottom-0"
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="mx-4 pt-3 flex-end">
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <div class="pb-4">
      <v-date-picker
        v-model="date"
        is-expanded
        :model-config="modelConfig"
        @dayclick="handleClick"
      />
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "modal-date-picker",
    },
  },
  data() {
    return {
      date: new Date(),
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },
  methods: {
    handleClick(event) {
      this.date = event.id;
      this.$emit("input", event.id);
      this.$emit("change", event.id);
      this.$bvModal.hide(this.id);
    },
    hideCb() {
      this.date = new Date();
    },
  },
};
</script>

<style lang="scss">
.modal-date {
  .modal-dialog {
    max-width: 400px;
  }
}
.vc-container {
  border: 0 !important;
}
</style>
