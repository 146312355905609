<template>
  <div class="position-relative">
    <validation-provider
      class="flex-grow-1"
      ref="provider"
      :name="name"
      :rules="rules"
      v-slot="validationContext"
    >
      <div
        class=""
        :class="{
          'border-danger':
            rules && getValidationState(validationContext) === false,
        }"
      >
        <label class="flex-grow-1 mb-3" v-if="label">
          <span
            :class="{
              'text-danger':
                rules && getValidationState(validationContext) === false,
            }"
            v-text="label"
          />
        </label>
        <b-input-group class="align-items-center">
          <b-input
            class="px-0"
            v-bind="{
              ...$props,
              class: [
                inputClass,
                {
                  'hide-icon': hideStateIcon,
                },
              ],
              state: rules && getValidationState(validationContext),
            }"
            @input="inputListener"
            :value="value"
          >
          </b-input>

          <template #[slot] v-for="(_, slot) in $slots">
            <slot
              :name="slot"
              v-bind="{
                state: rules && getValidationState(validationContext),
              }"
            />
          </template>
          <template #[slot] v-for="(_, slot) in $scopedSlots">
            <slot
              :name="slot"
              v-bind="{
                state: rules && getValidationState(validationContext),
              }"
            />
          </template>
          <b-form-invalid-feedback
            v-if="!hideError"
            class="position-absolute"
            :style="{ top: 'calc(100% + 3px)' }"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-input-group>
      </div>
    </validation-provider>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, default: "text" },
    value: { type: String, default: null },
    inputClass: { default: () => "", type: String },
    placeholder: { type: String },
    maxlength: { type: String },
    name: { type: String },
    label: { type: String },
    rules: { type: [String, Object] },
    serverCheck: { default: false },
    readonly: { default: undefined, type: String },
    disabled: { default: false },
    hideError: { default: false },
    hideStateIcon: { default: true },
  },
  data() {
    return {
      serverState: null,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    inputListener(v) {
      let val = v;
      switch (this.type) {
        case "number":
          console.log(v);
          val = +v;
          break;

        default:
          break;
      }
      this.$emit("input", val);
    },
  },
};
</script>

<style>
.form-control.hide-icon {
  background-image: none !important;
}
</style>
