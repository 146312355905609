import request from "@/utils/request";

export default {
  check: {
    growth: {
      // 성장 비만도 평가 입력
      insert: (data) =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/growth/chart/insert",
          data,
        }),
    },
    psy: {
      // 아동청소년기 심리체크 질문 - 대인관계, 상황심리, 자아정체감, 소아우울증
      list: (m_code) =>
        request({
          method: "get",
          url: `/kyoboJuniorCare/services/psy/question/${m_code}`,
        }),
      // 아동청소년기 심리체크 결과 보기 - 대인관계, 상황심리, 자아정체감, 소아우울증
      result: (data) =>
        request({
          method: "post",
          url: "/kyoboJuniorCare/services/psy/result",
          data,
          encryptionData: true,
        }),
      // 아동청소년기 심리체크 결과 보기 - 상황심리
      situationResult: (data) =>
        request({
          method: "post",
          url: "/kyoboJuniorCare/services/psy/situation/result",
          data,
          encryptionData: true,
        }),
    },
  },
  hospital: {
    getLists: () =>
    // 전국 협력병원 리스트
    request({
      method: "get",
      url: "/kyobohealthcare/services/hospital/list",
    }),
    // 헬스케어 - 전국 협력 병원(검진 우대 병원) 리스트
    getList: (sido_num) =>
      request({
        method: "get",
        url: `/kyobojuniorcare/services/hospital/list/${sido_num}`,
      }),
    // 헬스케어 - 병원(검진 우대 병원)정보 보기
    getInfo: (hsptl_sn) =>
      request({
        method: "get",
        url: `/kyobojuniorcare/services/hospital/info/${hsptl_sn}`,
      }),
    // 헬스케어 - 검진항목보기(검진 우대 병원
    getItem: (hpt_code) =>
      request({
        method: "get",
        url: `/kyobojuniorcare/services/hospital/info/item/${hpt_code}`,
      }),
    // 헬스케어 - 검진항목 비교(검진 우대 병원
    compare: (data) =>
      request({
        method: "post",
        url: "/kyobojuniorcare/services/hospital/info/compare",
        data,
      }),
  },
  myPage: {
    // 내 서비스 내역 - 전화상담
    mycall: {
      list: (data) =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/mypage/call/list",
          data,
          encryptionData: true,
        }),
    },
    application: {
      // 내 서비스 내역 - 프로그램 신청 내역
      list: (data) =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/mypage/application/list",
          data,
          encryptionData: true,
        }),
      // 내 서비스 내역 - 프로그램 신청 해지
      delete: (data) =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/mypage/application/stop",
          data,
          encryptionData: true,
        }),
    },
    psycheck: {
      // 내 서비스 내역 - 아동청소년 심리, 학습 및 뇌건강 이력
      list: (data) =>
        request({
          method: "post",
          url: "/kyoboJuniorCare/services/mypage/psycheck/list",
          data,
          encryptionData: true,
        }),
      detail: (data) =>
        request({
          method: "post",
          url: "/kyoboJuniorCare/services/mypage/psycheck/details",
          data,
          encryptionData: true,
        }),
    },
  },
  news: {
    news: {
      // 기획기사 리스트 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/column/list",
          data,
        }),
      detail: (report_key) =>
        // 기획기사 상세 불러오기
        request({
          method: "get",
          url: `/kyobojuniorcare/services/column/view/${report_key}`,
        }),
    },
    recentNews: {
      // 최신뉴스 리스트 불러오기
      list: (data) =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/recentnews/list",
          data,
        }),
      // 최신뉴스 상세 불러오기

      detail: (news_key) =>
        request({
          method: "get",
          url: `/kyobojuniorcare/services/recentnews/view/${news_key}`,
        }),
    },
  },
  program: {
    common: {
      // 신청자 정보 및 아이 정보 - 스탭케어
      info: () =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/common/info",
          encryptionData: true,
        }),
    },
    management: {
      // 스텝케어 신청하기
      apply: (data) =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/management/apply",
          encryptionData: true,
          data,
        }),
      // 스텝케어 문진 저장
      save: (data) =>
        request({
          method: "post",
          url: "/kyobojuniorcare/services/munjin/save",
          encryptionData: true,
          data,
        }),
    },
    counseling: {
      // 상담치료지원 - 방문희망병원 정보
      hospitalInfo: () =>
        request({
          method: "get",
          url: "/kyobojuniorcare/services/helpmom/hospitalinfo",
        }),
      // 방문치료 - 진단명 카테고리1
      diseaseCategory1: () =>
        request({
          method: "get",
          url: "/kyobojuniorcare/services/helpmom/category/disease",
        }),
      // 방문치료 - 진단명 카테고리2
      diseaseCategory2: (cate1key) =>
        request({
          method: "get",
          url: `/kyobojuniorcare/services/helpmom/category/disease/${cate1key}`,
        }),
      // 방문치료지원 신청
      visitApply: (data) =>
        request({
          method: "post",
          url: `/kyobojuniorcare/services/helpmomvisit/apply`,
          data,
          encryptionData: true,
        }),
    },
  },
};
