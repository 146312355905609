<template>
  <!--  -->
  <b-modal
    :id="id"
    size="xl"
    :hide-footer="true"
    @hide="hideCb"
    @show="showCb"
    body-class="p-0"
    header-class="p-0 d-block border-bottom-0"
    scrollable
    centered
  >
    <!-- 모달 헤더 -->
    <template #modal-header="{ close }">
      <div class="px-4 px-md-5 py-4 flex-between">
        <!-- 모달 헤더 타이틀 -->
        <div class="flex-start">
          <h3 class="fs-24 fw-7 mt-1">
            건강 알리미&태담 서비스 신청
          </h3>
        </div>
        <!-- // 모달 헤더 타이틀 -->
        <!-- 모달 닫기 버튼 -->
        <b-btn
          variant="link"
          @click="close()"
          :style="{ marginRight: '-15px' }"
        >
          <div
            class="bg-img"
            :style="{
              backgroundImage: `url(${require('@/assets/img/common/ic-close.svg')})`,
              width: '3rem',
              paddingBottom: '3rem',
            }"
          ></div>
        </b-btn>
        <!-- // 모달 닫기 버튼 -->
      </div>
    </template>
    <!-- // 모달 헤더 -->
    <!--  모달 바디 -->
    <w-form v-if="info" class="px-4 px-md-5" v-slot="{ invalid }">
      <!-- 신청자 정보 리스트 -->
      <hash-title :title="'신청자 정보'" :isHash="false"></hash-title>
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18">
        <b-col cols="4">자녀 이름</b-col>
        <b-col cols="8">
          <div v-if="!childs">
            -
          </div>
          <div v-else-if="childs && childs.length === 1">
            {{ childs[0].text }}
          </div>
          <hc-select-default
            v-else
            v-model="selectedChild"
            class="fs-20 fs-md-18"
            rules="required"
            inputClass="fs-20 fs-md-18"
            :items="childs"
          ></hc-select-default>
        </b-col>
      </b-row>
      <div
        v-for="row in lists"
        :key="row.title"
        class="row mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18"
      >
        <b-col cols="4">{{ row.text }}</b-col>
        <b-col cols="8">
          {{ row.value }}
        </b-col>
      </div>
      <!-- 특이사항 -->
      <b-row class="mx-0 border-bottom border-gray-5 py-3 fs-20 fs-md-18">
        <b-col cols="12" md="4">비고(특이사항)</b-col>
        <b-col cols="12" md="8">
          <w-textarea
            no-resize
            v-model="textarea"
            placeholder="내용을 입력하세요."
            inputClass="fs-20 fs-md-18"
            class="mt-3 mt-md-0"
            :styles="{
              minHeight: '200px',
            }"
          ></w-textarea>
        </b-col>
      </b-row>
      <p class="red pt-3 text-center fs-18">
        ※ 홈페이지 및 어린이헬스콜센터 (1588-4733)를 통해서 신청이 가능합니다.
      </p>
      <!-- 동의하기 -->
      <div class="flex-center py-4">
        <w-checkbox
          :label="'개인정보 수집 및 이용동의 (필수)'"
          :name="'개인정보 수집 및 이용동의 (필수)'"
          v-model="privacy"
          class="py-1 mr-2"
        >
          <a class="fs-20 fs-md-18 fs-md-18"
            >개인정보 수집 및 이용동의 (필수)</a
          >
        </w-checkbox>
        <b-btn variant="link" @click="handleModalTerm">
          <div
            class="bg-img"
            :style="{
              width: calcHeight('3rem'),
              paddingBottom: calcHeight('3rem'),
              backgroundImage: `url(${require('@/assets/img/common/ic-agree-zoom.svg')})`,
            }"
          ></div>
        </b-btn>
      </div>
      <!-- 신청하기 -->
      <div class="flex-center pt-3 pb-5">
        <b-btn
          variant="link blue-submit-btn fs-20 fs-md-18 fs-md-18 px-5"
          @click="submit"
          :disabled="invalid"
          >신청하기</b-btn
        >
      </div>
    </w-form>

    <skeleton-form v-else class="px-4 px-md-5"></skeleton-form>
    <!-- // 모달 바디 -->
    <modal-term
      @agree="privacy = true"
      v-bind="{ ...modalTermProps }"
    ></modal-term>
  </b-modal>
</template>

<script>
import ModalTerm from "@/components/common/modal/ModalTerm";
import { showToast } from "@/plugins/custom-toast";
import { formatedDate } from "@/utils/dayjs";
import SkeletonForm from "@/components/common/SkeletonForm";

export default {
  components: {
    ModalTerm,
    SkeletonForm,
  },
  data() {
    return {
      id: "modal-health-reminder",
      selectedChild: null,
      info: null,
      textarea: "",
      privacy: false,
      modalTermProps: {
        isPrivacy: true,
        consentKey: "library",
      },
    };
  },
  computed: {
    lists() {
      if (!this.childs) return null;
      const idx =
        this.childs.length === 1
          ? this.childs[0]?.value
          : this.selectedChild?.value;

      const getChilds = this.info?.childs?.find(
        (val) => val.joinserial === idx
      );
      const getDay = getChilds?.bithday ? formatedDate(getChilds.bithday) : "-";

      return [
        { text: "회원이름", value: this.info?.memname ?? "-" },
        { text: "휴대전화번호", value: this.info?.memhp ?? "-" },
        { text: "서비스 신청 선택", value: getChilds?.llms ?? "-" },
        { text: "자녀 생일(출산 예정일)", value: getDay },
      ];
    },
    childs() {
      return this.info?.childs.map((val) => ({
        value: val.joinserial,
        text: val.contactname || "-",
      }));
    },
    apis() {
      const {
        kyoboApi: { program },
      } = this.getApis();
      return program;
    },
  },
  methods: {
    handleModalTerm() {
      this.$bvModal.show("modal-term");
    },
    async getInfo() {
      const { data } = await this.apis.common.info();
      this.info = data.info;
    },
    async submit() {
      if (!this.privacy)
        return this.$root.$emit(
          "showModalAlert",
          "개인정보 수집 및 이용에 동의해 주세요."
        );
      const body = {
        joinserial:
          this.childs.length === 1
            ? this.childs[0].text
            : this.selectedChild.text,
        txtEtc: this.textarea,
      };
      const {
        data: { message },
      } = await this.apis.library.apply(body);

      showToast({ title: message });

      this.$bvModal.hide(this.id); // bootstrap modal hide
    },
    calcByte(str) {
      const btye = str
        ?.split("")
        .map((s) => s.charCodeAt(0))
        .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
      return btye > 401;
    },
    reset() {
      this.selectedChild = null;
      this.privacy = false;
      this.textarea = "";
    },
    hideCb() {
      this.reset();
    },
    showCb() {
      this.getInfo();
    },
  },
};
</script>
